/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import UberEats from '../../../assets/images/uberEats.png';
import DoorDash from '../../../assets/images/doorDash.png';
import Postmates from '../../../assets/images/postmates.png';
import Grubhub from '../../../assets/images/grubhub.png';

export class MapDeliveryModal extends Component {
  handleOpenLink = (link) => {
    window.open(link, '_blank'); // to open new page
  };

  render() {
    const { selectedWarehouse } = this.props;
    return (
      <Row>
        <Col span={24} style={{ marginBottom: '20px' }}>
          The Store support those delivery service, please click buttons to open
          website.
        </Col>
        <Col
          span={24}
          css={{
            textAlign: 'center',
            '& .thirdPartyIcon': {
              height: '64px',
              width: '64px',
              cursor: 'pointer',
            },
          }}
        >
          {selectedWarehouse.thirdParty ? (
            <React.Fragment>
              {selectedWarehouse.thirdParty.UberEats.enabled ? (
                <span style={{ marginRight: '15px' }}>
                  <img
                    className="thirdPartyIcon"
                    src={UberEats}
                    alt="UberEats"
                    role="presentation"
                    onClick={() => {
                      this.handleOpenLink(
                        selectedWarehouse.thirdParty.UberEats.link
                      );
                    }}
                  />
                </span>
              ) : null}
              {selectedWarehouse.thirdParty.DoorDash.enabled ? (
                <span style={{ marginRight: '15px' }}>
                  <img
                    className="thirdPartyIcon"
                    src={DoorDash}
                    alt="DoorDash"
                    role="presentation"
                    onClick={() => {
                      this.handleOpenLink(
                        selectedWarehouse.thirdParty.DoorDash.link
                      );
                    }}
                  />
                </span>
              ) : null}
              {selectedWarehouse.thirdParty.Postmates.enabled ? (
                <span style={{ marginRight: '15px' }}>
                  <img
                    className="thirdPartyIcon"
                    src={Postmates}
                    alt="Postmates"
                    role="presentation"
                    onClick={() => {
                      this.handleOpenLink(
                        selectedWarehouse.thirdParty.Postmates.link
                      );
                    }}
                  />
                </span>
              ) : null}
              {selectedWarehouse.thirdParty.Grubhub &&
              selectedWarehouse.thirdParty.Grubhub.enabled ? (
                <span style={{ marginRight: '15px' }}>
                  <img
                    className="thirdPartyIcon"
                    src={Grubhub}
                    alt="Grubhub"
                    role="presentation"
                    onClick={() => {
                      this.handleOpenLink(
                        selectedWarehouse.thirdParty.Grubhub.link
                      );
                    }}
                  />
                </span>
              ) : null}
            </React.Fragment>
          ) : null}
        </Col>
      </Row>
    );
  }
}

MapDeliveryModal.defaultProps = {
  selectedWarehouse: null,
};

MapDeliveryModal.propTypes = {
  selectedWarehouse: PropTypes.shape({
    thirdParty: PropTypes.shape({
      UberEats: PropTypes.shape({
        enabled: PropTypes.bool,
        link: PropTypes.string,
      }),
      DoorDash: PropTypes.shape({
        enabled: PropTypes.bool,
        link: PropTypes.string,
      }),
      Postmates: PropTypes.shape({
        enabled: PropTypes.bool,
        link: PropTypes.string,
      }),
      Grubhub: PropTypes.shape({
        enabled: PropTypes.bool,
        link: PropTypes.string,
      }),
    }),
  }),
};

export default MapDeliveryModal;
