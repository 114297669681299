import React from 'react';
import './UserPoint.css';

function UserPoint() {
  return (
    <div className="userPoint">
      <div className="dot" />
      <div className="pulse" />
    </div>
  );
}

export default UserPoint;
