/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// import React from 'react';
import { Carousel, Button } from 'antd';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Icon from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { ReactComponent as muteIcon } from '../../assets/images/icons/mute.svg';
import { ReactComponent as unmuteIcon } from '../../assets/images/icons/unmute.svg';

const Arrow = ({ type, style, className, onClick }) => (
  <div
    style={{
      ...style,
      transform: type === 'right' ? 'rotate(-45deg)' : 'rotate(135deg)',
      border: 'solid rgba(0, 0, 0, 0.65)',
      borderWidth: '0 3px 3px 0',
      display: 'inline-block',
      padding: '3px',
    }}
    role="presentation"
    className={className}
    onClick={onClick}
  />
);
Arrow.defaultProps = { style: null, className: null, onClick: null };
Arrow.propTypes = {
  type: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function VideoBanner(props) {
  const { videos, containerWidth } = props;

  const language = useSelector((state) => state.language.lang);

  const [playlist, setPlaylist] = useState([]);
  const [currentPlayIndex, setCurrentPlayIndex] = useState(0);
  const [videoPlayerArray, setVideoPlayerArray] = useState([]);
  const [muteList, setMuteList] = useState([]);

  const { innerWidth } = window;
  useEffect(() => {
    if (videos && videos.length > 0) {
      const muteListArray = videos.map(() => {
        return { isMuted: true };
      });
      setMuteList(muteListArray);
      setPlaylist(videos);
    }
  }, [videos]);

  const handleMuteOrUnmuteVolume = (index) => {
    const foundIndex = videoPlayerArray.findIndex(
      (videoPlayer) => videoPlayer.index === index
    );
    const muteListCopy = JSON.parse(JSON.stringify(muteList));
    if (muteList[index].isMuted === true) {
      videoPlayerArray[foundIndex].player.unMute();
      muteListCopy[index].isMuted = false;
    }
    if (muteList[index].isMuted === false) {
      videoPlayerArray[foundIndex].player.mute();
      muteListCopy[index].isMuted = true;
    }
    setMuteList(muteListCopy);
  };

  const handleAfterChange = (current) => {
    setCurrentPlayIndex(current);
    videoPlayerArray.forEach((player) => {
      if (player.index === current) {
        player.player.playVideo();
      } else {
        player.player.pauseVideo();
      }
    });
  };

  return (
    <div>
      <div
        css={
          isMobile
            ? { width: '100%', margin: 'auto' }
            : { width: containerWidth || '578px', margin: 'auto' }
        }
      >
        <Carousel
          arrows
          prevArrow={<Arrow type="left" />}
          nextArrow={<Arrow type="right" />}
          afterChange={handleAfterChange}
        >
          {playlist.map((video, index) => (
            <div
              key={video._id}
              css={{
                height: isMobile ? 330 * (innerWidth / 578) : '320px',
                width: isMobile
                  ? containerWidth || innerWidth
                  : containerWidth || '578px',
                overflow: 'hidden',
                borderRadius: '4px',
              }}
            >
              <div
                css={{
                  textAlign: 'center',
                  height: isMobile ? 310 * (innerWidth / 578) : '318px',
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Button
                  css={{
                    position: 'absolute',
                    zIndex: '100000',
                    top: '10px',
                    right: '10px',
                  }}
                  onClick={() => {
                    handleMuteOrUnmuteVolume(index);
                  }}
                  shape="circle"
                  icon={
                    muteList[index].isMuted ? (
                      <Icon component={muteIcon} />
                    ) : (
                      <Icon component={unmuteIcon} />
                    )
                  }
                />
                <YouTube
                  videoId={
                    language === 'zh'
                      ? video.videoIds.filter(
                          (element) => element.language === 'zh'
                        )[0].videoId
                      : video.videoIds.filter(
                          (element) => element.language === 'en'
                        )[0].videoId
                  }
                  opts={{
                    host: 'https://www.youtube-nocookie.com',
                    height: isMobile ? 600 * (innerWidth / 578) : 600,
                    width: isMobile ? innerWidth : '578',
                    playerVars: {
                      // https://developers.google.com/youtube/player_parameters
                      controls: 0,
                      modestbranding: 1,
                      iv_load_policy: 3,
                      rel: 0,
                      playsinline: 1,
                    },
                  }}
                  onReady={(event) => {
                    event.target.mute();
                    if (index === currentPlayIndex) {
                      event.target.playVideo();
                    }
                    videoPlayerArray.push({
                      player: event.target,
                      index,
                    });
                    setVideoPlayerArray(videoPlayerArray);
                  }}
                  onPause={(event) => {
                    if (index === currentPlayIndex) {
                      event.target.playVideo();
                    }
                  }}
                  onEnd={(event) => {
                    if (index === currentPlayIndex) {
                      event.target.playVideo();
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

VideoBanner.defaultProps = { videos: [], containerWidth: null };

VideoBanner.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})),
  containerWidth: PropTypes.string,
};

export default VideoBanner;
