/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import { Component } from 'react';
import { Modal, message, Button } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
// import * as R from 'ramda';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
// import { convertRawToHTML } from 'braft-convert';
// import BraftEditor from 'braft-editor';
import { isMobile } from 'react-device-detect';
import { apolloClient, getCustomerPath } from '../../../util';
import { CURRENT_USER_COUPON } from '../../../api/discount';
import * as actions from '../../../store/actions';
import { getGlobalDisableDate } from '../../../helper/PureFunction/SmallFunction';
// import Loading from '../../../components/Others/Loading/Loading';
import Shipping from './Shipping/Shipping';
import Payment from './Payment/Payment';
import Order from './Order/Order';
import {
  WAREHOUSE_FIND_ONE,
  WAREHOUSE_FIND_MANY,
  WAREHOUSE_BY_ID,
} from '../../../api/warehouse';
// import Announcement from '../DeliveryMenu/Announcement/Announcement';

export class Checkout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      shoppingList: [],
      // discountAmount: 0,
      loading: true,
      personalDiscount: [],
      onlyAllowedDate: null,
      onlyAllowedDateWithTime: null,
      onlyAllowedWeekDay: null,
      disableDate: [],
      warehouseDeliveryMode: false,
      // storeAnnouncementModalVisible: false,
      // currentWarehouseObj: null,
    };
  }

  componentDidMount = async () => {
    // make sure no mask for body
    // const bodyTag = document.getElementsByTagName('body');
    // bodyTag.style = {};
    console.log('props **** props', this.props);
    await this.setState({ loading: true });
    // const { updateOneUserAttribute } = this.props;
    // check login
    await this.userLoginChecker();
    await this.processShoppingList();
    await this.fetchDisableDate();
    await this.fetchPersonalDiscount();
    // await this.getCurrentStep();
    // await updateOneUserAttribute(null, 'overWriteAddress');
    // if (preselectPickupInfo) {
    //   await this.handleSelectAddress(preselectPickupInfo);
    // }
    // clear discount
    const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    console.log('shoppingCart in check out', shoppingCart);
    shoppingCart.discountCode = null;
    await updateWarehouseCart(shoppingCart);
    console.log('loading shipping');
    await this.setState({ loading: false });
    // await this.setState({ redirectChecker: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  componentWillUnmount = async () => {
    clearInterval(this.timer);
  };

  fetchWarehouseData = async (Id) => {
    try {
      const {
        data: { warehouseById },
      } = await apolloClient.query({
        query: WAREHOUSE_BY_ID,
        variables: { Id },
        fetchPolicy: 'network-only',
      });
      return warehouseById;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  fetchDisableDate = async () => {
    const { currentShoppingMode, getCurrentWarehouseCart, intl, language } =
      this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    if (currentShoppingMode === 'delivery') {
      const disableDate = await getGlobalDisableDate();
      this.setState({ disableDate });
    }
    // if (currentShoppingMode === 'pickup') {
    const { lastWarehousePath } = this.props;
    const findIndex = _.findLastIndex(lastWarehousePath, (e) => e === '=');
    if (findIndex > -1) {
      const warehouseId = lastWarehousePath.slice(
        findIndex + 1,
        lastWarehousePath.length
      );
      console.log(warehouseId);
      if (
        warehouseId !== getCustomerPath('delivery-menu') &&
        warehouseId !== getCustomerPath('supermarket')
      ) {
        const warehouseObj = await this.fetchWarehouseData(warehouseId);
        console.log('Obj', warehouseObj);
        if (
          currentShoppingMode === 'delivery' &&
          warehouseObj &&
          !warehouseObj.isParent &&
          warehouseObj.childrenWarehouses.length === 0
        ) {
          await this.setState({ warehouseDeliveryMode: true });
        }
      }

      // if (
      //   warehouseObj &&
      //   ((language === 'en' &&
      //     BraftEditor.createEditorState(
      //       convertRawToHTML(JSON.parse(warehouseObj.announcement.en || '{}'))
      //     ).toText()) ||
      //     (language === 'zh' &&
      //       BraftEditor.createEditorState(
      //         convertRawToHTML(
      //           JSON.parse(warehouseObj.announcement.zh || '{}')
      //         )
      //       ).toText()))
      // ) {
      //   this.setState({
      //     storeAnnouncementModalVisible: true,
      //     currentWarehouseObj: warehouseObj,
      //   });
      // }
    } else {
      if (currentShoppingMode === 'eShop') {
        return;
      }
      console.error('history path not available, invalid warehouseId');
    }
    // }
    let onlyAllowedDate = null;
    const variantNameNote = [];
    const { boxList, productList } = shoppingCart;
    productList.forEach((variant) => {
      if (variant.allowedShippingDate.length > 0) {
        // onlyAllowedDate = _.concat(
        //   onlyAllowedDate,
        //   variant.allowedShippingDate
        // );
        if (onlyAllowedDate === null) {
          onlyAllowedDate = variant.allowedShippingDate;
        } else {
          onlyAllowedDate = _.intersection(
            onlyAllowedDate,
            variant.allowedShippingDate
          );
        }
        variantNameNote.push(variant.name[language]);
      }
    });
    boxList.forEach((variant) => {
      variant.boxContent.forEach((subVariant) => {
        if (subVariant.allowedShippingDate.length > 0) {
          // onlyAllowedDate = _.concat(
          //   onlyAllowedDate,
          //   subVariant.allowedShippingDate
          // );
          if (onlyAllowedDate === null) {
            onlyAllowedDate = subVariant.allowedShippingDate;
          } else {
            onlyAllowedDate = _.intersection(
              onlyAllowedDate,
              subVariant.allowedShippingDate
            );
          }
          variantNameNote.push(subVariant.name[language]);
        }
      });
    });

    console.log('onlyAllowedDate', onlyAllowedDate);

    if (onlyAllowedDate !== null) {
      if (onlyAllowedDate.length === 0) {
        Modal.success({
          content: intl.formatMessage(
            { id: 'noShippingDateAvailable' },
            { variantName: variantNameNote }
          ),
          onOk: async () => {
            Modal.destroyAll();
          },
          icon: <InfoCircleTwoTone />,
        });
      } else {
        Modal.confirm({
          content: intl.formatMessage(
            { id: 'limitedShippingDate' },
            {
              shippingDates: onlyAllowedDate
                .slice(0, 3)
                .filter((date) => {
                  const today = moment();
                  return (
                    today.diff(moment(date), 'day') <= 0 &&
                    today.isSame(moment(date.trim()), 'day') === false
                  );
                })
                .map((date) => {
                  if (language === 'zh') {
                    return moment(date)
                      .locale('zh-cn')
                      .format('MMM Do[ (]dddd[)]');
                  }
                  return moment(date).format('MMM Do[ (]dddd[)]');
                })
                .join(','),
              variantName: variantNameNote.join(','),
            }
          ),
          cancelText: intl.formatMessage({ id: 'backToShoppingCartEditing' }),
          onCancel: async () => {
            Modal.destroyAll();
            const { history } = this.props;
            console.log(lastWarehousePath);
            if (lastWarehousePath) {
              history.replace(lastWarehousePath);
            } else {
              history.replace(getCustomerPath('supermarket?cart=true'));
            }
          },
          onOk: async () => {
            Modal.destroyAll();
          },
          icon: <InfoCircleTwoTone />,
        });
      }
    }

    // fetch warehouse hours for warehouse
    // first check is schedule delivery
    const { isScheduledDelivery } = this.props;
    console.log('isScheduledDelivery', isScheduledDelivery);
    if (isScheduledDelivery === true) {
      // const { lastWarehousePath } = this.props;
      const warehousePath = lastWarehousePath;
      const warehouseId = warehousePath
        .match(/warehouseId=.{24}/g)[0]
        .replace('warehouseId=', '');
      console.log('warehouseId', warehouseId);
      // fetch warehouse hours
      const { data } = await apolloClient.query({
        query: WAREHOUSE_FIND_ONE,
        variables: { filter: { _id: warehouseId } },
        fetchPolicy: 'network-only',
      });
      // check shopping cart variants
      // get all of variants' warehouseId
      // const shoppingList = _.concat(
      //   shoppingCart.productList,
      //   shoppingCart.boxList
      // );
      const shoppingList = shoppingCart.productList;
      console.log(shoppingList);
      const warehouseIds = new Set();
      shoppingList.forEach((variant) => {
        if (variant.warehouseId) {
          warehouseIds.add(variant.warehouseId);
        }
      });
      console.log('warehouseIds', [...warehouseIds]);
      const warehouseFindManyResponse = await apolloClient.query({
        query: WAREHOUSE_FIND_MANY,
        variables: {
          filter: {
            OR: [...warehouseIds].map((id) => {
              return { _id: id };
            }),
          },
        },
        fetchPolicy: 'network-only',
      });
      const {
        data: { warehouseMany },
      } = warehouseFindManyResponse;
      let maxCutoffTime = 0;
      if (warehouseMany.length > 0) {
        // sort the warehouses
        const sortedWarehouses = _.orderBy(
          warehouseMany,
          ['cutOffTime'],
          ['desc']
        );
        maxCutoffTime = sortedWarehouses[0].cutOffTime;
      }
      if (data.warehouseOne && data.warehouseOne.hours.length > 0) {
        // check hours
        console.log(data.warehouseOne.hours);
        const days = [];
        console.log('warehouse cut off time', data.warehouseOne.cutOffTime);
        if (data.warehouseOne.cutOffTime > maxCutoffTime) {
          maxCutoffTime = data.warehouseOne.cutOffTime;
        }
        console.log(maxCutoffTime);
        data.warehouseOne.hours.forEach((hour) => {
          const nextDay = moment().day(hour.day);
          if (
            moment() >
            moment()
              .day(hour.day)
              .startOf('days')
              .subtract(maxCutoffTime, 'hours')
          ) {
            console.log('check');
            const nextDayString = moment()
              .day(hour.day + 7)
              .format('YYYY-MM-DD');
            const nextNextDayString = moment()
              .day(hour.day + 7 + 7)
              .format('YYYY-MM-DD');
            days.push(nextDayString);
            days.push(nextNextDayString);
          } else {
            const nextDayString = nextDay.format('YYYY-MM-DD');
            const nextNextDayString = nextDay
              .add(7, 'days')
              .format('YYYY-MM-DD');
            days.push(nextDayString);
            days.push(nextNextDayString);
          }
        });
        onlyAllowedDate = _.sortBy(days);
      }
    }

    const { warehouseDeliveryMode } = this.state;

    if (warehouseDeliveryMode) {
      console.log('add only allowed date');
      const warehousePath = lastWarehousePath;
      const warehouseId = warehousePath
        .match(/warehouseId=.{24}/g)[0]
        .replace('warehouseId=', '');
      console.log('warehouseId', warehouseId);
      const { data } = await apolloClient.query({
        query: WAREHOUSE_FIND_ONE,
        variables: { filter: { _id: warehouseId } },
        fetchPolicy: 'network-only',
      });
      if (
        data.warehouseOne &&
        data.warehouseOne.scheduledDeliveryHours.length > 0
      ) {
        // check hours
        const days = [];
        const daysWithTime = [];
        data.warehouseOne.scheduledDeliveryHours.forEach((hour) => {
          if (
            moment().valueOf() > hour.allowedDateRange.startDate &&
            moment().valueOf() < hour.allowedDateRange.endDate
          ) {
            hour.days.forEach((day) => {
              const nextDay = moment().day(day);
              if (
                moment() >
                moment()
                  .day(day)
                  .hours(hour.startTime.hours)
                  .minutes(hour.startTime.minutes)
                  .subtract(hour.cutOffTime, 'hours')
              ) {
                const nextDayString = moment()
                  .day(day + 7)
                  .format('YYYY-MM-DD');
                const nextNextDayString = moment()
                  .day(day + 7 + 7)
                  .format('YYYY-MM-DD');
                days.push(nextDayString);
                days.push(nextNextDayString);
                daysWithTime.push({
                  date: nextDayString,
                  startTime: moment()
                    .day(day + 7)
                    .hours(hour.startTime.hours)
                    .minutes(hour.startTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                  endTime: moment()
                    .day(day + 7)
                    .hours(hour.endTime.hours)
                    .minutes(hour.endTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                });
                daysWithTime.push({
                  date: nextNextDayString,
                  startTime: moment()
                    .day(day + 7 + 7)
                    .hours(hour.startTime.hours)
                    .minutes(hour.startTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                  endTime: moment()
                    .day(day + 7 + 7)
                    .hours(hour.endTime.hours)
                    .minutes(hour.endTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                });
              } else {
                const nextDayString = nextDay.format('YYYY-MM-DD');
                const nextNextDayString = nextDay
                  .add(7, 'days')
                  .format('YYYY-MM-DD');
                days.push(nextDayString);
                days.push(nextNextDayString);
                daysWithTime.push({
                  date: nextDayString,
                  startTime: nextDay
                    .hours(hour.startTime.hours)
                    .minutes(hour.startTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                  endTime: nextDay
                    .hours(hour.endTime.hours)
                    .minutes(hour.endTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                });
                daysWithTime.push({
                  date: nextNextDayString,
                  startTime: nextDay
                    .add(7, 'days')
                    .hours(hour.startTime.hours)
                    .minutes(hour.startTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                  endTime: nextDay
                    .add(7, 'days')
                    .hours(hour.endTime.hours)
                    .minutes(hour.endTime.minutes)
                    .seconds('00')
                    .format('HH:mm'),
                });
              }
            });
          }
        });
        onlyAllowedDate = _.sortBy(days);
        this.setState({ onlyAllowedDateWithTime: daysWithTime });
      }
    }

    this.setState({ onlyAllowedDate });
  };

  fetchPersonalDiscount = async () => {
    console.log('fetchPersonalDiscount');
    try {
      const {
        data: { userCurrent },
      } = await apolloClient.query({
        query: CURRENT_USER_COUPON,
        variables: {},
        fetchPolicy: 'network-only',
      });
      if (userCurrent.personalDiscount.length > 0) {
        await this.setState({ personalDiscount: userCurrent.personalDiscount });
        console.log('personalDiscount', userCurrent.personalDiscount);
      }
    } catch (error) {
      message.error('error fetching personal coupon', 5);
    }
  };

  // process
  userLoginChecker = async () => {
    console.log('user login check');
    const { userId, saveLinkForLogin, history } = this.props;
    // TODO: add token check
    if (!userId) {
      console.log('user login jump');
      await saveLinkForLogin(getCustomerPath('checkout'));
      history.push(getCustomerPath('login'));
    } else {
      // this.setState({ redirectChecker: true });
    }
  };

  processShoppingList = async () => {
    const { history } = this.props;
    const { getCurrentWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    const shoppingList = _.concat(
      shoppingCart.productList,
      shoppingCart.boxList
    );
    if (shoppingList.length === 0) {
      history.push(getCustomerPath(''));
      console.log('error empty shopping list error!!!!');
    }
    await this.setState({ shoppingList });
  };

  render() {
    const {
      shoppingList,
      // discountAmount,
      personalDiscount,
      loading,
      onlyAllowedDate,
      onlyAllowedWeekDay,
      disableDate,
      warehouseDeliveryMode,
      onlyAllowedDateWithTime,
      // storeAnnouncementModalVisible,
      // currentWarehouseObj,
    } = this.state;
    const { match, history, currentShoppingMode } = this.props;

    const { innerHeight: height } = window;
    // if (isMobile) {
    //   height = Screen.height;
    // }

    return (
      <div
        css={{
          backgroundColor: '#f0f2f5',
          minHeight: isMobile
            ? `calc( ${height}px - 45px )`
            : `calc( ${height}px - 100px )`,
          paddingBottom: '40px',
          // '& .am-calendar .content': {
          //   top: '45px',
          //   height: `calc(${height}px - 45px )`,
          // },
        }}
      >
        {/* <Modal
          footer={null}
          title={<div style={{ textAlign: 'center' }}>Announcement</div>}
          visible={storeAnnouncementModalVisible}
          onCancel={() => {
            this.setState({ storeAnnouncementModalVisible: false });
          }}
        >
          <Announcement warehouse={currentWarehouseObj} />
        </Modal> */}
        <div
          css={
            isMobile
              ? {
                  width: '98%',
                  // backgroundColor: 'white',
                  margin: 'auto',
                  padding: '10px',
                }
              : {
                  width: '90%',
                  backgroundColor: 'white',
                  margin: '0 auto',
                  padding: '20px',
                }
          }
        >
          {loading === false && (
            <div>
              <Button
                onClick={() => {
                  const { lastWarehousePath } = this.props;
                  if (lastWarehousePath) {
                    history.replace(lastWarehousePath);
                  } else {
                    history.replace(getCustomerPath('delivery-menu'));
                  }
                }}
              >
                <FormattedMessage
                  id={
                    currentShoppingMode !== 'donation'
                      ? 'payment.backToMenu'
                      : 'payment.backToDonation'
                  }
                />
              </Button>
            </div>
          )}
          {loading === false ? (
            <Switch>
              <Route
                exact
                path={`${match.url}/shipping`}
                render={() => (
                  <Shipping
                    onlyAllowedDate={onlyAllowedDate}
                    warehouseDeliveryMode={warehouseDeliveryMode}
                  />
                )}
              />
              <Route
                exact
                path={`${match.url}/payment`}
                render={() => (
                  <Payment
                    warehouseDeliveryMode={warehouseDeliveryMode}
                    shoppingList={shoppingList}
                    onlyAllowedDate={onlyAllowedDate}
                    onlyAllowedWeekDay={onlyAllowedWeekDay}
                    onlyAllowedDateWithTime={onlyAllowedDateWithTime}
                    disableDate={disableDate}
                    personalDiscount={personalDiscount}
                  />
                )}
              />
              <Route
                exact
                path={`${match.url}/order`}
                render={() => <Order />}
              />
              <Redirect from={match.url} to={`${match.url}/shipping`} />
            </Switch>
          ) : null}
        </div>
      </div>
    );
  }
}

Checkout.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  // taxRate: PropTypes.number.isRequired,
  userId: PropTypes.string,
  currentShoppingMode: PropTypes.string.isRequired,
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deliveryTime: PropTypes.number,
    discountCode: PropTypes.string,
  }),
  currentWarehouseCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  saveLinkForLogin: PropTypes.func.isRequired,
  // updateOneUserAttribute: PropTypes.func.isRequired,
  groupBuyInfo: PropTypes.shape({}),
  preselectPickupInfo: PropTypes.shape({}),
  match: ReactRouterPropTypes.match.isRequired,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  lastWarehousePath: PropTypes.string,
  isScheduledDelivery: PropTypes.bool.isRequired,
};

Checkout.defaultProps = {
  shoppingCart: {
    productList: [],
    boxList: [],
    deliveryTime: null,
  },
  currentWarehouseCart: {
    productList: [],
  },
  groupBuyInfo: null,
  preselectPickupInfo: null,
  userId: null,
  lastWarehousePath: null,
};

const mapStateToProps = (state) => ({
  shoppingCart: state.cart.shoppingCart,
  currentWarehouseCart: state.cart.currentWarehouseCart,
  items: state.cart.items,
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  currentAddressType: state.user.currentAddressType,
  groupBuyInfo: state.user.groupBuyInfo,
  preselectPickupInfo: state.user.preselectPickupInfo,
  userId: state.user.userId,
  lastWarehousePath: state.warehouse.lastWarehousePath,
  isScheduledDelivery: state.warehouse.isScheduledDelivery,
  // taxRate: state.warehouse.taxRate,
});

const mapDispatchToProps = (dispatch) => ({
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
  addItem: (item) => dispatch(actions.addItem(item)),
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
  overWriteCallBack: (callback) =>
    dispatch(actions.overWriteCallBack(callback)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Checkout));
