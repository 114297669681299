/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { AlipayOutlined } from '@ant-design/icons';
import { Button, Modal, Alert } from 'antd';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as R from 'ramda';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import * as actions from '../../../../store/actions';
import { apolloClient, getCustomerPath } from '../../../../util';
import { WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS } from '../../../../api/warehouse';
import {
  customerOrderFormatter,
  pseudoTypeFormatter,
} from '../../../../helper/Formatter/Formatter';
import {
  CUSTOMER_ORDER_CREATE,
  UPDATE_CUSTOMER_ORDER_BY_ID,
} from '../../../../api/customerOrder';
import {
  CREATE_CAMPAIGN_INDIVIDUAL,
  UPDATE_CAMPAIGN_INDIVIDUAL_ORDER,
  FETCH_MY_CAMPAIGN_INDIVIDUAL,
} from '../../../../api/campaign';

function AlipayPayment(props) {
  const { stripe, disableAlipayPayButton } = props;

  const createErrorPop = (error) => {
    const { currentShoppingMode, intl } = props;
    const translatedError = intl.formatMessage({ id: error });
    const msg = intl
      .formatMessage({ id: 'errorForPayment' })
      .replace('[xx.mode]', currentShoppingMode)
      .replace('[xx.error]', translatedError);
    Modal.warning({
      title: 'error',
      content: msg,
      onOk: () => {
        Modal.destroyAll();
      },
    });
  };

  const participateCampaignContent = async (orderID) => {
    const {
      currentCampaignContentId,
      currentShoppingMode,
      currentAddressType,
      userId,
    } = props;
    if (
      currentCampaignContentId &&
      currentAddressType === 'pickup' &&
      currentShoppingMode === 'delivery'
    ) {
      console.log('add order to participating', orderID);
      const input = {
        campaignContentId: currentCampaignContentId,
      };
      // if ((currentShoppingMode === 'delivery' &&currentAddressType === 'pickup' ) ||currentShoppingMode === 'groupBuy') {
      input.oneOrderId = orderID;
      // }
      try {
        // for create group buy
        const getIndividual = R.pathOr(null, [
          'data',
          'campaignIndividualFindMany',
          0,
          '_id',
        ]);
        const getOrderStatus = R.pathOr(null, [
          'data',
          'campaignIndividualFindMany',
          0,
          'oneOrder',
          'paymentStatus',
        ]);
        // if (currentAddressType === 'delivery') {
        // console.log('groupBuy join activity');
        const myIndividual = await apolloClient.query({
          query: FETCH_MY_CAMPAIGN_INDIVIDUAL,
          variables: { contentId: currentCampaignContentId, userId },
          fetchPolicy: 'network-only',
        });
        const individualId = getIndividual(myIndividual);
        const orderStatus = getOrderStatus(myIndividual);
        if (individualId) {
          // update for failed order
          if (orderStatus === 'draft' || orderStatus === null) {
            console.log('update failed individual');
            const { data } = await apolloClient.mutate({
              mutation: UPDATE_CAMPAIGN_INDIVIDUAL_ORDER,
              variables: { Id: individualId, orderId: orderID },
            });
            if (data.campaignIndividualUpdate.record) {
              return true;
            }
            return false;
          }
          if (
            currentAddressType === 'pickup' &&
            currentShoppingMode === 'delivery'
          ) {
            console.log('create new individual for pickup');
            // create one if not exists
            const { data } = await apolloClient.mutate({
              mutation: CREATE_CAMPAIGN_INDIVIDUAL,
              variables: { input },
            });
            if (data.campaignIndividualParticipate.record._id) {
              return true;
            }
            return false;
          }
          return false;
        }
        console.log('create new individual');
        // create one if not exists
        const { data } = await apolloClient.mutate({
          mutation: CREATE_CAMPAIGN_INDIVIDUAL,
          variables: { input },
        });
        if (data.campaignIndividualParticipate.record._id) {
          return true;
        }
        return false;
        // }
        // =======for pickup and join group buy only
        // if (currentShoppingMode === 'delivery' || currentShoppingMode === 'groupBuy'&&currentShoppingMode==='pickup' ) {
      } catch (error) {
        // this.createErrorPop(error.graphQLErrors[0].message);
        // console.log(error.graphQLErrors[0].message);
        return false;
      }
    } else {
      return true;
    }
  };

  const handleCreateNewOrder = async () => {
    const {
      selectedAddress,
      customerNote,
      currentWarehouseId,
      currentWarehouseCart: { productList },
      groupBuyInfo,
      currentShoppingMode,
      currentAddressType,
      // shoppingCart,
      getCurrentWarehouseCart,
      overWriteAddress,
      language,
      intl,
      group,
      userEmail,
      doesUseBalance,
    } = props;
    // double check inventory
    const variantsNeedInventoryCheck = [];
    const idToObj = {};
    const shoppingCart = await getCurrentWarehouseCart();
    shoppingCart.productList.forEach((item) => {
      if (item.allowedInventoryCheck === true) {
        variantsNeedInventoryCheck.push(item._id);
        idToObj[item._id] = item;
      }
    });

    if (variantsNeedInventoryCheck.length > 0) {
      try {
        const {
          data: { inventoryCurrentWarehouseWithVariantIds },
        } = await apolloClient.query({
          query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
          variables: {
            warehouseId: currentWarehouseId,
            variantIds: variantsNeedInventoryCheck,
          },
          fetchPolicy: 'network-only',
        });
        if (inventoryCurrentWarehouseWithVariantIds) {
          for (
            let i = 0;
            i < inventoryCurrentWarehouseWithVariantIds.length;
            i += 1
          ) {
            const item =
              idToObj[inventoryCurrentWarehouseWithVariantIds[i]._id];
            if (
              inventoryCurrentWarehouseWithVariantIds[i].totalQuantity <
              item.count
            ) {
              Modal.warning({
                content: intl
                  .formatMessage({ id: 'errorInsufficientStock' })
                  .replace('xx.xx', item.name[language]),
                onOk: () => {
                  window.location.replace(getCustomerPath('delivery-menu'));
                },
              });
              return null;
            }
          }
        } else {
          return null;
        }
      } catch (error) {
        console.error(error);
        createErrorPop('inventory error');
        return null;
      }
    }
    // ==============
    // console.log("shoppingCart",shoppingCart)
    // return null
    console.log('selectedAddress in stripe payment', selectedAddress);
    let selectAddressID = selectedAddress;
    const notes = customerNote;
    if (
      currentShoppingMode === 'groupBuy' &&
      groupBuyInfo &&
      groupBuyInfo.selectedAddress
    ) {
      selectAddressID = groupBuyInfo.selectedAddress;
    }
    let orderInput;
    if (currentShoppingMode === 'delivery') {
      const shoppingList = _.concat(
        shoppingCart.productList,
        shoppingCart.boxList
      );
      orderInput = {
        variantInput: customerOrderFormatter(shoppingList),
        realMode: true,
        doesUseBalance,
      };
      console.log('shoppingCart', shoppingCart);
      if (shoppingCart.deliveryTime) {
        orderInput.deliveryTime = shoppingCart.deliveryTime;
      } else {
        createErrorPop('No Delivery Time');
        return null;
      }
      if (shoppingCart.discountCode) {
        orderInput.discountCode = shoppingCart.discountCode;
      }
      // overwrite pickup
      if (overWriteAddress) {
        const newAddress = {
          lastName: overWriteAddress.lastName,
          firstName: overWriteAddress.firstName,
          phoneNumber: overWriteAddress.phoneNumber,
        };
        orderInput.overWriteAddress = newAddress;
      }
      const referralCode = window.sessionStorage.getItem('referralCode');
      const referralOrderId = window.sessionStorage.getItem('referralOrderId');
      if (referralCode && referralOrderId) {
        orderInput.referralCode = referralCode;
        orderInput.referralOrderId = referralOrderId;
      }
    } else if (currentShoppingMode === 'pickup') {
      orderInput = {
        variantInput: customerOrderFormatter(productList),
        realMode: true,
      };
    } else if (currentShoppingMode === 'groupBuy') {
      orderInput = {
        variantInput: customerOrderFormatter(productList),
        realMode: true,
      };
    }

    if (group && group.name === 'guest') {
      orderInput.guestUserEmail = userEmail;
    }
    orderInput.address = selectAddressID;
    orderInput.warehouseId = currentWarehouseId;
    orderInput.notes = notes;
    orderInput.pseudoOrderType = pseudoTypeFormatter(
      currentShoppingMode,
      currentAddressType
    );
    if (selectAddressID && notes !== null) {
      try {
        // const orderInput = {
        //   variantInput,
        //   address: selectAddressID,
        //   warehouseId: currentWarehouseId,
        //   notes,
        // };
        if (groupBuyInfo && groupBuyInfo.deliveryTime) {
          orderInput.deliveryTime = groupBuyInfo.deliveryTime;
        }
        const { data } = await apolloClient.mutate({
          mutation: CUSTOMER_ORDER_CREATE,
          variables: orderInput,
        });
        // console.log(data);
        return data.orderCreateCustomerOrder;
      } catch (error) {
        createErrorPop(error.graphQLErrors[0].message);
        console.error(error);
        return null;
      }
    }
    // console.log('test h>>>');
    // console.log('notes is',notes)
    return null;
  };

  const handleClickPayButton = async () => {
    const {
      handleDisableTabs,
      handleShowAlipayModal,
      handleSetOrderId,
      handleEnableTabs,
      handleHideAlipayModal,
      handleDisableAlipayPayButton,
      handleEnableAlipayPayButton,
    } = props;
    try {
      // fist create a new order
      const order = await handleCreateNewOrder();
      console.log('create draft order is ', order);
      if (order && (await participateCampaignContent(order._id))) {
        handleDisableAlipayPayButton();
        handleDisableTabs();
        handleShowAlipayModal();
        handleSetOrderId(order._id);
        let returnUrl = `https://bunbao.com/checkout/order?paymentType=alipay&orderId=${order._id}`;
        if (
          process.env.NODE_ENV === 'development' ||
          process.env.REACT_APP_BUILD_DEV === 'true'
        ) {
          returnUrl = `https://test.bunbao.com/checkout/order?paymentType=alipay&orderId=${order._id}`;
        }
        if (process.env.REACT_APP_ALIPAY_RETURN_URL) {
          returnUrl = process.env.REACT_APP_ALIPAY_RETURN_URL + order._id;
        }
        console.log(returnUrl);
        console.log(order.discountAmount);
        console.log('tax rate: ', order.taxRate);
        console.log(
          Number(order.subtotal) * 100 - Number(order.discountAmount) * 100
        );
        const response = await stripe.createSource({
          type: 'alipay',
          amount: (Number(order.total) * 100).toFixed(0),
          currency: 'usd',
          redirect: {
            return_url: returnUrl,
          },
        });
        console.log(response);
        const { source } = response;

        console.log(source);
        const clientSecret = source.client_secret;
        console.log(clientSecret);
        // should update order client secret
        const updatedOrder = await apolloClient.mutate({
          mutation: UPDATE_CUSTOMER_ORDER_BY_ID,
          variables: { input: { _id: order._id, clientSecret } },
        });
        console.log(updatedOrder);
        if (isMobile) {
          window.location.assign(source.redirect.url);
        } else {
          window.open(source.redirect.url, '_blank');
        }
        // console.log(response);
      }
    } catch (error) {
      handleEnableTabs();
      handleHideAlipayModal();
      handleEnableAlipayPayButton();
    }
  };

  return (
    <div>
      <div css={{ margin: '10px 0px 20px 0px' }}>
        <Alert
          message={<FormattedMessage id="payment.redirectNoticeAlipay" />}
          type="warning"
          showIcon
        />
      </div>
      <Button
        type="primary"
        css={{ width: '100%' }}
        size="large"
        disabled={disableAlipayPayButton}
        onClick={() => {
          handleClickPayButton();
        }}
      >
        <AlipayOutlined /> <FormattedMessage id="payment.payWithAlipay" />
      </Button>
    </div>
  );
}

AlipayPayment.defaultProps = {
  selectedAddress: null,
  // groupBuyShippingTime: null,
  saleorOrderInput: {},
  currentWarehouseCart: {
    productList: [],
  },
  groupBuyInfo: null,
  shoppingCart: {
    productList: [],
    boxList: [],
  },
  overWriteAddress: null,
  currentCampaignContentId: null,
  customerNote: '',
  userEmail: null,
};

AlipayPayment.propTypes = {
  language: PropTypes.string.isRequired,
  customerNote: PropTypes.string,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  currentWarehouseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  currentAddressType: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired,
    createSource: PropTypes.func,
  }).isRequired,
  selectedAddress: PropTypes.string,
  currentShoppingMode: PropTypes.string.isRequired,
  saleorOrderInput: PropTypes.shape({
    input: PropTypes.shape({}),
    code: PropTypes.string,
  }),
  currentWarehouseCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  groupBuyInfo: PropTypes.shape({
    deliveryTime: PropTypes.number,
    selectedAddress: PropTypes.shape({}),
  }),
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    discountCode: PropTypes.string,
    deliveryTime: PropTypes.number,
  }),
  overWriteAddress: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }),
  handleDisableTabs: PropTypes.func.isRequired,
  handleShowAlipayModal: PropTypes.func.isRequired,
  handleSetOrderId: PropTypes.func.isRequired,
  handleEnableTabs: PropTypes.func.isRequired,
  handleHideAlipayModal: PropTypes.func.isRequired,
  disableAlipayPayButton: PropTypes.bool.isRequired,
  handleEnableAlipayPayButton: PropTypes.func.isRequired,
  handleDisableAlipayPayButton: PropTypes.func.isRequired,
  currentCampaignContentId: PropTypes.string,
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  userEmail: PropTypes.string,
  doesUseBalance: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  currentShoppingMode: state.user.currentShoppingMode,
  currentAddressType: state.user.currentAddressType,
  currentWarehouseId: state.warehouse.warehouseId,
  saleorId: state.auth.saleorId,
  currentCampaignContentId: state.user.currentCampaignContentId,
  userId: state.user.userId,
  currentWarehouseCart: state.cart.currentWarehouseCart,
  groupBuyInfo: state.user.groupBuyInfo,
  overWriteAddress: state.user.overWriteAddress,
  shoppingCart: state.cart.shoppingCart,
  history: ReactRouterPropTypes.history.isRequired,
  language: state.language.lang,
  group: state.user.group,
});

const mapDispatchToProps = (dispatch) => ({
  updateShoppingCart: (shoppingCart) =>
    dispatch(actions.updateShoppingCart(shoppingCart)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  // setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
  // setUserAddressType: (mode) => dispatch(actions.setUserAddressType(mode)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),

  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  // updateWarehouseCart: (shoppingCart) =>
  //   dispatch(actions.updateWarehouseCart(shoppingCart)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AlipayPayment));
