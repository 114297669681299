export default {
  en: {
    aboutUs: 'About Us',
    ourSlogan: 'Handcrafted Quality | Delivered Fresh',
    aboutUsParagraphSectionOne:
      "BunBao's goal is to deliver fresh, quality, wholesome food that's handcrafted from scratch.",
    aboutUsParagraphSectionTwo:
      'We aim to make the best baos in the US. We create bao flavors with both local and exotic ingredients, traditional and Michelin starred influences.',
    aboutUsParagraphSectionThree:
      "It's been almost a year since we delivered our first order. During the past year, we have expanded our service from pre-order only delivery to on-demand delivery and catering. Our central kitchen is located in Fremont. Welcome to drop by and watch bao masters making the food through our glass walls.",
  },
  zh: {
    aboutUs: '关于我们',
    ourSlogan: '我们的经营理念是：棒！包，送到家',
    aboutUsParagraphSectionOne:
      ' 依科学理论，用实验室的精度，从营养、品质、味道到包装，制作出精细化发面面点：棒!包！',
    aboutUsParagraphSectionTwo:
      '汉堡夹一切，披萨铺万象，包子包天下。棒!包将中国八大菜系美味佳肴，收纳于包子中，迎合天南地北之众口。并为美国华人融入西方餐饮元素，让包子脱离人们固有印象，做高逼格的棒!包。',
    aboutUsParagraphSectionThree:
      '棒!包解决用户关键痛点！再好的产品，如果让用户为得到它而纠结、操心、费力，一切都将归零。棒!包，送到家！',
  },
};
