/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  InfoOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { WechatAPI } from 'react-wechat-api';
import {
  Avatar,
  List,
  message,
  Result,
  Button,
  Pagination,
  Tabs,
  Modal,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
// import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import // DELIVERY_ORDER_BY_USER_LAST,
// // DELIVERY_ORDER_BY_USER_OVERVIEW,
// // ORDER_BY_ID,
// '../../../../apiCanary/order';
import {
  CUSTOMER_ORDER_CURRENT_USER_PAGINATION,
  CUSTOMER_ORDER_CURRENT_USER_BY_ID,
  // CUSTOMER_ORDER_SHARE_START_TIME,
} from '../../../../api/customerOrder';
import {
  // saleorClient,
  apolloClient,
  getCustomerPath,
  // extractInfo,
  // processOrderDetail,
  // checkWeChatUserAgent,
} from '../../../../util';
import DeliveryOrderDetail from '../DeliveryOrderDetail/DeliveryOrderDetail';
import Loading from '../../../../components/Others/Loading/Loading';
import OrderDetails from '../OrderDetails/OrderDetails';
import copy from '../../../../assets/images/icons/copy.svg';
import tipPicture from '../../../../assets/images/shareTip.png';
import buttonPicture from '../../../../assets/images/shareButton.png';

// import { injectIntl, intlShape } from 'react-intl';
const steps = (step) => {
  switch (step) {
    case 'CHARGED':
      return (
        <Avatar
          css={{ backgroundColor: '#3fc5f0' }}
          icon={<ClockCircleOutlined />}
        />
      );
    case 'CAPTURED':
      return (
        <Avatar css={{ backgroundColor: '#a7e9af' }} icon={<CheckOutlined />} />
      );
    case 'paid':
      return (
        <Avatar css={{ backgroundColor: '#a7e9af' }} icon={<CheckOutlined />} />
      );
    case 'FULLY_REFUNDED':
      return (
        <Avatar css={{ backgroundColor: '#f2eee5' }} icon={<InfoOutlined />} />
      );
    case 'complete':
      return (
        <Avatar css={{ backgroundColor: '#a7e9af' }} icon={<CheckOutlined />} />
      );
    case 'partiallyRefunded':
      return (
        <Avatar css={{ backgroundColor: '#a7e9af' }} icon={<CheckOutlined />} />
      );
    default:
      return (
        <Avatar css={{ backgroundColor: '#ed9a73' }} icon={<CloseOutlined />} />
      );
  }
};

export class DeliveryOrders extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      deliveryOrdersCount: 0,
      cloudStoreOrdersPageIndex: 1,
      cloudStoreOrdersPageSize: 10,
      cloudStoreOrdersCount: 0,
      orders: [],
      recentOrder: null,
      saleorOrders: [],
      saleorRecentOrder: null,
      // cloudStoreOrders: [],
      // cloudStoreOrdersRecentOrder: null,
      loading: true,
      orderId: '',
      showModal: false,
      showTipModal: false,
      wechatShareData: {
        title: '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
        link: () => window.location.href,
      },
    };
  }

  componentDidMount = async () => {
    // await this.fetchCustomerDeliveryOrderLast();
    await this.handleFetchCustomerDeliveryOrders();
    // await this.handleSaleorOrders();
    // await this.handleFetchCloudStoreOrder();
    await this.setState({ loading: false });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleFetchCustomerDeliveryOrders = async () => {
    const { pageIndex, pageSize } = this.state;
    let orders = [];
    try {
      const {
        data: { customerOrderCurrentUserPagination },
      } = await apolloClient.query({
        query: CUSTOMER_ORDER_CURRENT_USER_PAGINATION,
        variables: {
          page: pageIndex,
          perPage: pageSize,
          filter: {
            OR: [
              { bunbaoType: 'scheduledDelivery' },
              { bunbaoType: 'normalDelivery' },
              { bunbaoType: 'groupBuy' },
              { bunbaoType: 'fixLocation' },
              { bunbaoType: 'donation' },
              { bunbaoType: 'warehouse' },
              { bunbaoType: 'warehouseScheduledDelivery' },
              { bunbaoType: 'eShop' },
            ],
          },
          sort: 'CREATEDAT_ASC',
        },
        fetchPolicy: 'network-only',
      });
      // console.log(customerOrderCurrentUserPagination);
      orders = customerOrderCurrentUserPagination.items;
      this.setState({
        deliveryOrdersCount: customerOrderCurrentUserPagination.count,
      });
    } catch (error) {
      console.error(error);
      message.error('error happened during fetching delivery orders', 15);
    }
    if (orders.length > 0 && pageIndex === 1) {
      // console.log(orders[0]._id);
      try {
        const {
          data: { customerOrderById },
        } = await apolloClient.query({
          query: CUSTOMER_ORDER_CURRENT_USER_BY_ID,
          variables: {
            id: orders[0]._id,
          },
          fetchPolicy: 'network-only',
        });
        // console.log('order: ', customerOrderById);
        // console.log('orders', orders);
        await this.setState({ orders: orders.slice(1) });
        await this.setState({ recentOrder: customerOrderById });
      } catch (error) {
        console.error(error);
        message.error('error fetching order', 15);
      }
    } else {
      await this.setState({ orders });
    }
  };

  // handleFetchCloudStoreOrder = async () => {
  //   const { cloudStoreOrdersPageIndex, cloudStoreOrdersPageSize } = this.state;
  //   const { data } = await apolloClient.query({
  //     query: CUSTOMER_ORDER_CURRENT_USER_PAGINATION,
  //     variables: {
  //       page: cloudStoreOrdersPageIndex,
  //       perPage: cloudStoreOrdersPageSize,
  //       // filter: { bunbaoType: 'warehouse' },
  //       filter: {
  //         OR: [
  //           { bunbaoType: 'warehouse' },
  //           { bunbaoType: 'warehouseScheduledDelivery' },
  //         ],
  //       },
  //     },
  //     fetchPolicy: 'network-only',
  //   });
  //   console.log(data);
  //   if (data) {
  //     if (
  //       cloudStoreOrdersPageIndex === 1 &&
  //       data.customerOrderCurrentUserPagination.count > 0
  //     ) {
  //       this.setState({
  //         cloudStoreOrdersRecentOrder:
  //           data.customerOrderCurrentUserPagination.items[0],
  //         cloudStoreOrders: data.customerOrderCurrentUserPagination.items.slice(
  //           1
  //         ),
  //         cloudStoreOrdersCount: data.customerOrderCurrentUserPagination.count,
  //       });
  //     } else {
  //       this.setState({
  //         cloudStoreOrdersRecentOrder: null,
  //         cloudStoreOrders: data.customerOrderCurrentUserPagination.items,
  //         cloudStoreOrdersCount: data.customerOrderCurrentUserPagination.count,
  //       });
  //     }
  //   }
  // };

  renderPickupDeliveryTime = (order) => {
    const { lang } = this.props;
    let formedMessage = 'orderDetailDelivery';
    if (order.bunbaoType === 'donation' || order.bunbaoType === 'eShop') {
      return null;
    }
    if (order.bunbaoType) {
      if (order.bunbaoType === 'fixLocation') {
        formedMessage = 'orderDetailPickup';
      }
    }
    const renderBoxDeliveryTime = () => {
      if (order.bunbaoType === 'normalDelivery' || order.bunbaoType === null) {
        return `${moment(order.deliveryTime).format('YYYY-MM-DD')} 3-7pm`;
      }
      if (order.bunbaoType === 'groupBuy') {
        return moment(order.deliveryTime).format('YYYY-MM-DD HH:mm');
      }
      return moment(order.deliveryTime).format('YYYY-MM-DD');
    };
    return (
      <span>
        <FormattedMessage id={formedMessage} />{' '}
        {order.bunbaoType === 'fixLocation' && (
          <span>
            <span>{order?.campaignContent?.warehouse?.title[lang]} </span>
            <span>{lang === 'en' ? 'pick up' : '自取'}</span>
          </span>
        )}{' '}
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
          {`${renderBoxDeliveryTime()} `}
          {order?.campaignContent?.pickupTimeSlotFormatted.length > 0 &&
            order?.campaignContent?.pickupTimeSlotFormatted[0]}
        </span>
      </span>
    );
  };

  // handleSaleorOrders = async () => {
  //   // saleor part
  //   let orders = [];
  //   try {
  //     const { email } = this.props;
  //     const { data } = await saleorClient.query({
  //       query: DELIVERY_ORDER_BY_USER_OVERVIEW,
  //       variables: { email },
  //       fetchPolicy: 'network-only',
  //     });
  //     const newData = extractInfo(data.orderByUser);
  //     newData.forEach((e, index) => {
  //       newData[index] = processOrderDetail(newData[index]);
  //     });
  //     console.log('newData', newData);
  //     orders = _.concat(orders, newData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   if (orders.length > 0) {
  //     try {
  //       const {
  //         data: { order },
  //       } = await saleorClient.query({
  //         query: ORDER_BY_ID,
  //         variables: { id: orders[0].id },
  //         fetchPolicy: 'network-only',
  //       });
  //       if (order) {
  //         await this.setState({ saleorOrders: orders.slice(1) });
  //         await this.setState({ saleorRecentOrder: processOrderDetail(order) });
  //       } else {
  //         message.error('error fetching order', 15);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       message.error('error fetching order', 15);
  //     }
  //   } else {
  //     this.setState({ saleorOrders: orders });
  //   }
  // };

  processOrder = (order) => {
    const newOrder = order;
    if (!newOrder.address) {
      newOrder.address = {};
    }
    const list = ['firstName', 'lastName', 'phoneNumber'];
    list.forEach((e) => {
      if (!newOrder.address[e]) {
        newOrder.address[e] = ' ';
      }
    });
    return newOrder;
  };

  handleChangePageIndex = async (pageIndex) => {
    await this.setState({ pageIndex });
    await this.handleFetchCustomerDeliveryOrders();
  };

  handleChangeCloudStorePageIndex = async (pageIndex) => {
    await this.setState({ cloudStoreOrdersPageIndex: pageIndex });
    await this.handleFetchCustomerDeliveryOrders();
  };

  render() {
    const {
      orders,
      pageIndex,
      recentOrder,
      saleorRecentOrder,
      saleorOrders,
      loading,
      pageSize,
      deliveryOrdersCount,
      // cloudStoreOrders,
      // cloudStoreOrdersRecentOrder,
      cloudStoreOrdersCount,
      cloudStoreOrdersPageIndex,
      cloudStoreOrdersPageSize,
      orderId,
      showModal,
      wechatShareData,
      showTipModal,
    } = this.state;
    const { lang, referralCode, intl } = this.props;
    const couldModeArray = ['warehouse', 'warehouseScheduledDelivery'];
    const orderListRender = (showOrders, showRecentOrder, orderType) => (
      <div
        css={{
          minHeight: '100vh',
          padding: '10px 20px 10px 20px',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {showTipModal && (
          <div
            css={{
              position: 'fixed',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: '18888',
              backgroundColor: '#000000',
              opacity: '0.7',
            }}
          >
            <div css={{ position: 'absolute', right: '0', top: '0' }}>
              <img
                src={tipPicture}
                alt="Tip"
                css={{ width: '70%', float: 'right' }}
              />
              <img
                src={buttonPicture}
                alt="Button"
                css={{ width: '70%', float: 'right' }}
                onClick={() => {
                  this.setState({ showTipModal: false });
                }}
                role="presentation"
              />
            </div>
          </div>
        )}
        <WechatAPI shareData={wechatShareData}>
          <div />
        </WechatAPI>
        {showRecentOrder ? (
          <div
            css={{
              width: isMobile ? '100%' : '50%',
            }}
          >
            <div css={{ fontSize: '25px', fontWeight: 'bold' }}>
              <FormattedMessage id="recentOrder" />
            </div>
            {/* {console.log(showRecentOrder)} */}
            {!couldModeArray.includes(showRecentOrder.bunbaoType) &&
            (orderType === 'normal' || orderType === 'saleor') ? (
              <DeliveryOrderDetail order={showRecentOrder} />
            ) : (
              <OrderDetails order={showRecentOrder} />
            )}
          </div>
        ) : (
          <div css={{ width: '100%', textAlign: 'center' }}>
            <Result
              status="404"
              title={<FormattedMessage id="noOrders" />}
              extra={
                <Link to={getCustomerPath('delivery-menu')}>
                  <Button type="primary">
                    <FormattedMessage id="placeAnOrderNow" />
                  </Button>
                </Link>
              }
            />
          </div>
        )}
        <div
          css={{
            width: !isMobile && showRecentOrder ? '50%' : '100%',
            padding: '0px 10px',
          }}
        >
          <div
            css={{
              fontSize: '25px',
              fontWeight: 'bold',
              marginTop: isMobile ? '20px' : '0px',
            }}
          >
            <FormattedMessage id="previousOrder" />
          </div>
          <List
            itemLayout="horizontal"
            dataSource={showOrders}
            renderItem={(order) => (
              <List.Item>
                {/* order.bunbaoType === 'cloudStore' */}
                {couldModeArray.includes(order.bunbaoType) ? (
                  <Link
                    css={{ width: '100%' }}
                    to={`${getCustomerPath('account/orders/order')}/${
                      order._id
                    }`}
                  >
                    <List.Item.Meta
                      avatar={steps(order.warehouseStatus)}
                      title={<div>{order.warehouse.name}</div>}
                      description={
                        <div>
                          {' '}
                          {this.renderPickupDeliveryTime(order)}
                          {` -  ${order.variants.length}`}{' '}
                          <FormattedMessage id="items" />
                          {`  -  $${
                            order.total > 0
                              ? order.total.toFixed(2)
                              : order.subtotal.toFixed(2)
                          }`}
                        </div>
                      }
                    />
                  </Link>
                ) : (
                  <React.Fragment>
                    <List.Item.Meta
                      avatar={steps(order.paymentStatus)}
                      title={
                        order.paymentStatus ? (
                          <div>
                            <FormattedMessage id={order.paymentStatus} />{' '}
                            {/* {moment().diff(moment(order.createdAt), 'h') <=
                              24 && (
                              <Button
                                size="small"
                                type="primary"
                                onClick={async () => {
                                  if (referralCode) {
                                    // get url with referral code
                                    try {
                                      await apolloClient.mutate({
                                        mutation:
                                          CUSTOMER_ORDER_SHARE_START_TIME,
                                        variables: {
                                          orderId: order._id,
                                        },
                                      });
                                    } catch (error) {
                                      console.error(error);
                                    }
                                    let content = '';
                                    await this.setState({
                                      wechatShareData: {
                                        title:
                                          '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
                                        link: `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${order._id}`,
                                      },
                                    });
                                    const originalUrl = `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${order._id}`;
                                    if (lang === 'zh') {
                                      content = `送您一张【BunBao 优惠券】点击链接享受全单5%折扣！${originalUrl}`;
                                    } else {
                                      content = `Friends buy friends save. Click link to enjoy 5% discount. ${originalUrl}`;
                                    }
                                    if (!navigator.clipboard) {
                                      const textField =
                                        document.createElement('textarea');
                                      textField.innerText = content;
                                      document.body.appendChild(textField);
                                      textField.select();
                                      document.execCommand('copy');
                                      textField.remove();
                                    } else {
                                      console.log(navigator.clipboard);
                                      try {
                                        await navigator.clipboard.writeText(
                                          content
                                        );
                                      } catch (error) {
                                        const textField =
                                          document.createElement('textarea');
                                        textField.innerText = content;
                                        document.body.appendChild(textField);
                                        textField.select();
                                        document.execCommand('copy');
                                        textField.remove();
                                        console.error(error);
                                      }
                                    }
                                    if (navigator.share) {
                                      if (lang === 'zh') {
                                        navigator
                                          .share({
                                            title: content,
                                            text: content,
                                            url: originalUrl,
                                          })
                                          .then(() =>
                                            console.log('Successful share')
                                          )
                                          .catch((error) =>
                                            console.log('Error sharing', error)
                                          );
                                      } else {
                                        navigator
                                          .share({
                                            title: content,
                                            text: content,
                                            url: originalUrl,
                                          })
                                          .then(() =>
                                            console.log('Successful share')
                                          )
                                          .catch((error) =>
                                            console.log('Error sharing', error)
                                          );
                                      }
                                    }
                                    this.setState({ showModal: true });
                                  }
                                  if (checkWeChatUserAgent()) {
                                    await this.setState({ showTipModal: true });
                                  }
                                  await this.setState({
                                    showModal: true,
                                    orderId: order._id,
                                  });
                                }}
                              >
                                {lang === 'zh' ? '现在分享' : 'Share Order'}
                              </Button>
                            )} */}
                          </div>
                        ) : (
                          'error'
                        )
                      }
                      description={
                        <div>
                          {' '}
                          {/* {order.bunbaoType ? (
                            <FormattedMessage id={order.bunbaoType} />
                          ) : null}{' '} */}
                          {/* {`${moment(order.deliveryTime).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )} `} */}
                          {this.renderPickupDeliveryTime(order)}
                          {` -  ${order.variants.length}`}{' '}
                          <FormattedMessage id="items" />
                          {`  -  $${
                            order.total > 0
                              ? order.total.toFixed(2)
                              : order.subtotal.toFixed(2)
                          }`}
                        </div>
                      }
                    />
                    <div>
                      <Link
                        css={{ width: '100%' }}
                        to={`${getCustomerPath(
                          'account/orders/delivery-order'
                        )}/${order._id}`}
                      >
                        <RightOutlined style={{ fontSize: '25px' }} />
                      </Link>
                    </div>
                  </React.Fragment>
                )}
              </List.Item>
            )}
          />
          {orderType === 'normal' && (
            <div css={{ textAlign: 'center' }}>
              <Pagination
                simple
                defaultCurrent={pageIndex}
                total={deliveryOrdersCount}
                pageSize={pageSize}
                onChange={this.handleChangePageIndex}
              />
            </div>
          )}
          {orderType === 'cloudStore' && (
            <div css={{ textAlign: 'center' }}>
              <Pagination
                simple
                defaultCurrent={cloudStoreOrdersPageIndex}
                total={cloudStoreOrdersCount}
                pageSize={cloudStoreOrdersPageSize}
                onChange={this.handleChangeCloudStorePageIndex}
              />
            </div>
          )}
        </div>
      </div>
    );
    if (loading) {
      return <Loading />;
    }
    return (
      <div css={isMobile ? {} : { padding: '10px 30px' }}>
        <Modal
          visible={showModal}
          onCancel={() => {
            this.setState({ showModal: false });
          }}
          footer={null}
        >
          <CopyToClipboard
            text={
              lang === 'zh'
                ? `送您一张【BunBao 优惠券】点击链接享受全单5%折扣！${window.location.host}/share-order?referralCode=${referralCode}&orderId=${orderId}`
                : `Friends buy friends save. Click link to enjoy 5% discount. ${window.location.host}/share-order?referralCode=${referralCode}&orderId=${orderId}`
            }
            onCopy={() => {
              Modal.success({
                content: intl.formatMessage({ id: 'copied' }),
              });
            }}
          >
            <div style={{ cursor: 'pointer' }}>
              <div style={{ padding: '10px' }}>
                <img style={{ width: '32px' }} src={copy} alt="Copy Icon" />
              </div>
              <div style={{ padding: '10px' }}>
                {lang === 'zh'
                  ? '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！'
                  : 'Friends buy friends save. Click link to enjoy 5% discount.'}
                <br />
                {window.location.host}/share-order?referralCode=
                {referralCode}
                &orderId=
                {orderId}
              </div>
            </div>
          </CopyToClipboard>
        </Modal>
        <Tabs defaultActiveKey="order" onChange={this.handleChangeTabIndex}>
          <Tabs.TabPane
            tab={<FormattedMessage id="account.orders" />}
            key="order"
          >
            {orderListRender(orders, recentOrder, 'normal')}
          </Tabs.TabPane>
          {/* <Tabs.TabPane
            tab={<FormattedMessage id="account.cloudStoreOrders" />}
            key="cloudStoreOrder"
          >
            {orderListRender(
              cloudStoreOrders,
              cloudStoreOrdersRecentOrder,
              'cloudStore'
            )}
          </Tabs.TabPane> */}
          <Tabs.TabPane
            key="carStickerOrders"
            tab={<FormattedMessage id="account.carStickerOrders" />}
          >
            {orderListRender(saleorOrders, saleorRecentOrder, 'saleor')}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

DeliveryOrders.propTypes = {
  // email: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  referralCode: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

DeliveryOrders.defaultProps = {
  referralCode: null,
};

const mapStateToProps = (state) => ({
  lang: state.language.lang,
  email: state.user.email,
  referralCode: state.user.referralCode,
});

export default connect(mapStateToProps, null)(injectIntl(DeliveryOrders));
