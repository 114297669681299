import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export class CateringOrderAdminForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Form>
        <Form.Item />
      </Form>
    );
  }
}

export default Form.create({ name: 'catering_order_admin_form' })(
  CateringOrderAdminForm
);
