/* eslint-disable no-unused-vars */
import { getMainDefinition } from 'apollo-utilities';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  defaultDataIdFromObject,
} from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import BraftEditor from 'braft-editor';
// import { convertRawToHTML } from 'braft-convert';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_URL_SALEOR, WEBSOCKET_API_URL } from './config';
import { VARIANTS_QUERY_IDS } from './api/variant';
import { middleware } from './middleware';

const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

const waitFunction = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

const firstLetterToUpperCase = (value) =>
  value && value[0].toUpperCase() + value.slice(1).toLowerCase();

const everyWordFirstLetterToUpperCase = (value) =>
  value
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

const getCustomerPath = (path = '') => {
  if (
    (process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_BUILD_DEV === 'true') &&
    window.location.host !== 'test.bunbao.com'
  ) {
    return `/customer/${path}`;
  }
  return `/${path}`;
};

const getPointPath = (path = '') => {
  if (
    (process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_BUILD_DEV === 'true') &&
    window.location.host !== 'test.bunbao.com'
  ) {
    return `/point/${path}`;
  }
  return `/${path}`;
};

const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  let pattern;

  switch (rules) {
    case 'required':
      isValid = value.trim() !== '' && isValid;
      break;
    case 'minLength':
      isValid = value.length >= rules.minLength && isValid;
      break;
    case 'maxLength':
      isValid = value.length <= rules.maxLength && isValid;
      break;
    case 'isEmail':
      pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
      break;
    case 'isNumeric':
      pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
      break;
    case 'isTips':
      pattern = /^[+]?[0-9]{1,9}(?:\.[0-9]{1,9})?$/;
      isValid = pattern.test(value) && isValid;
      break;
    default:
      isValid = false;
      break;
  }
  return isValid;
};

export const formatUserEmail = (email) => {
  try {
    // lower case email
    let formattedEmail = email.toLowerCase();
    // remove dot from email
    const emailArray = formattedEmail.split('@');
    formattedEmail = `${emailArray[0].replace(/\./g, '')}@${emailArray[1]}`;
    return formattedEmail;
  } catch (error) {
    return email;
  }
};

const displayDescription = (str) => {
  if (!str) {
    return BraftEditor.createEditorState('<p></p>').toRAW();
  }
  if (str.startsWith('{"block')) {
    return str;
  }
  const htmlStr = `<p>${str}</p>`;
  return BraftEditor.createEditorState(htmlStr).toRAW();
};

// Color Generator for color tags
const colorGenerator = (word) => {
  // for special tags return fixed color
  switch (word) {
    case 'Vegetarian':
      return '#97e868';
    case 'Gluten-Free':
      return '#d09c3d';
    case 'Spicy': // pink red
      return '#eb4242';
    case 'Popular':
      return '#FFA500';
    case 'Dairy-Free':
      return '#62B8D9';
    case 'Vegan':
      return '#3C9453';
    case 'Stone':
      return '#b4b8bf';
    case 'Open':
      return '#34a550';
    default:
      break;
  }
  let hash = 0;
  if (word.length === 0) {
    return hash;
  }
  for (let i = 0; i < word.length; i += 1) {
    /* eslint no-bitwise: ["error", { "allow": ["<<", "&=", ">>", "&"] }] */
    hash = word.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

const mosaicEmail = (email) => {
  const res = email.split('@');
  if (res.length >= 2) {
    return `${email.substring(0, 3)}***@${res[1]}`;
  }
  return `${email.substring(0, 3)}***`;
};

// get color for some words
const getColor = (input) => {
  switch (input) {
    case 'notConfirmed':
      return colorGenerator('Spicy');
    case 'confirmed':
      return colorGenerator('Popular');
    case 'readyForPickup':
      return colorGenerator('Dairy-Free');
    case 'complete':
      return colorGenerator('Vegan');
    default:
      return 'black';
  }
};

const getColorTwo = (input) => {
  switch (input) {
    case 'notConfirmed':
      return colorGenerator('Spicy');
    case 'confirmed':
      return colorGenerator('Popular');
    case 'readyForPickup':
      return colorGenerator('Dairy-Free');
    case 'complete':
      return colorGenerator('Vegetarian');
    default:
      return 'grey';
  }
};

export const processOrderForSaleor = (
  shoppingList,
  couponCode = null,
  saleorId,
  shippingDate = null,
  customerNote = '',
  selectedAddressDetail = null
) => {
  // clean address
  const shippingAddress = {
    firstName: '',
    lastName: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    postalCode: '',
    phone: '',
    gateCode: '',
    country: 'US',
    countryArea: 'CA',
  };
  if (selectedAddressDetail) {
    shippingAddress.firstName = selectedAddressDetail.firstName;
    shippingAddress.lastName = selectedAddressDetail.lastName;
    shippingAddress.streetAddress1 = selectedAddressDetail.addressOne;
    shippingAddress.streetAddress2 = selectedAddressDetail.addressTwo;
    shippingAddress.city = selectedAddressDetail.city;
    shippingAddress.postalCode = selectedAddressDetail.zipCode;
    shippingAddress.phone = selectedAddressDetail.phoneNumber;
    shippingAddress.gateCode = selectedAddressDetail.gateCode;
  }
  const boxs = [];
  let finalBox;
  shoppingList.forEach((e) => {
    const lines = [];
    if (e.boxContent) {
      e.boxContent.forEach((element) => {
        lines.push({ quantity: element.count, variantId: element.id });
      });
      finalBox = {
        collectionId: e.currentCollection.name,
        quantity: e.count,
        lines,
      };
      if (shippingDate) {
        finalBox = { ...finalBox, shippingDate };
      }
      boxs.push(finalBox);
    } else {
      lines.push({ quantity: e.count, variantId: e.id });
      finalBox = { collectionId: '', quantity: 1, lines };
      if (shippingDate) {
        finalBox = { ...finalBox, shippingDate };
      }
      boxs.push(finalBox);
    }
  });
  let input = { boxs, user: saleorId, customerNote };
  if (selectedAddressDetail) {
    input = { ...input, shippingAddress };
  }
  console.log('final shipping', shippingAddress);
  return { input, code: couponCode, groupbuy: false };
};

export const extractInfo = (obj) => {
  const temp = [];
  try {
    obj.edges.forEach((e) => {
      temp.push(e.node);
    });
    return temp;
  } catch (err) {
    return [];
  }
};

const checkAlipayUserAgent = () => {
  return /AlipayClient/.test(window.navigator.userAgent);
};

const checkWeChatUserAgent = () => {
  return /MicroMessenger/.test(window.navigator.userAgent);
};

// const getNameByCollection = (name) => {
//   switch (name) {
//     case '三拼组合': {
//       return { zh: '三拼组合', en: '3-Flavor Box' };
//     }
//     case '单品组合': {
//       return { zh: '单品组合', en: '1-Flavor Box' };
//     }
//     case '自由组合': {
//       return { zh: '自由组合', en: 'Mix&Match' };
//     }
//     default: {
//       return null;
//     }
//   }
// };

export const temporaryZipCodeRestriction = (zipCode) => {
  const zipCodes =
    '94022 94023 94024 94025 94026 94027 94039 94040 94041 94042 94043 94061 94063 94064 94085 94086 94087 94089 94301 94302 94303 94304 94305 94306 94506 94526 94536 94537 94538 94539 94550 94551 94555 94560 94566 94568 94582 94583 94587 94588 95002 95008 95009 95011 95014 95015 95030 95032 95035 95036 95050 95051 95052 95053 95054 95055 95056 95070 95071 95111 95112 95116 95117 95118 95121 95122 95123 95124 95125 95126 95127 95128 95129 95130 95131 95132 95133 95134 95148 94403 94404 94070 94061 94063 94064 94065 94070 94002 94403 94001 94002 94003 94004 94497';
  const zipCodesArray = _.split(zipCodes, ' ');
  if (_.find(zipCodesArray, (o) => o === zipCode.toString()) !== undefined) {
    return true;
  }
  return false;
};

export const processOrderDetail = (order) => {
  const newOrder = JSON.parse(JSON.stringify(order));
  // discountAmount
  if (order.discountAmount) {
    newOrder.discount = order.discountAmount.amount;
  }
  newOrder.createdAt = newOrder.created;
  try {
    newOrder.subtotal = newOrder.total.net.amount;
  } catch (error) {
    newOrder.subtotal = 0;
  }
  // initialize orders
  newOrder.address = {};

  // const list = ['firstName', 'lastName', 'phoneNumber','addressOne','addressTwo','city','state','zipCode'];
  // list.forEach((e) => { newOrder.address[e] = ' '});
  if (order.shippingAddress) {
    newOrder.address.firstName = order.shippingAddress.firstName || '';
    newOrder.address.lastName = order.shippingAddress.lastName || '';
    newOrder.address.addressOne = order.shippingAddress.streetAddress1 || '';
    newOrder.address.addressTwo = order.shippingAddress.streetAddress2 || '';
    //   `${order.shippingAddress.streetAddress1},` || '';
    // if (
    //   order.shippingAddress.streetAddress2 &&
    //   order.shippingAddress.streetAddress2.length > 0
    // ) {
    //   newOrder.address.addressTwo = `${order.shippingAddress.streetAddress2},`;
    // } else {
    //   newOrder.address.addressTwo = '';
    // }
    newOrder.address.city = order.shippingAddress.city || '';
    newOrder.address.zipCode = order.shippingAddress.postalCode || '';
    newOrder.address.phoneNumber = order.shippingAddress.phone || '';
    newOrder.address.gateCode = order.shippingAddress.gateCode || '';
  }

  // break boxs
  const variants = newOrder.boxs || [];
  let shippingDate;
  variants.forEach((e, index) => {
    shippingDate = e.shippingDate;
    const { name } = e;
    const boxLines = e.boxsLines || [];
    if (name === null) {
      if (boxLines.length > 0) {
        variants[index].name = {
          zh: boxLines[0].productName,
          en: boxLines[0].translatedProductName,
        };
        variants[index].quantity = boxLines[0].quantity;
      } else {
        variants[index].name = { zh: '', en: '' };
      }
    } else {
      boxLines.forEach((lines) => {
        lines.name = { zh: lines.productName, en: lines.translatedProductName };
      });
      variants[index].name = name;
      variants[index].subVariants = boxLines;
    }
  });
  newOrder.variants = variants;
  newOrder.shippingDate = shippingDate;
  if (newOrder.shippingDate) {
    newOrder.deliveryTime = moment(
      shippingDate,
      'YYYY-MM-DDThh:mm:ssTZD'
    ).valueOf();
  }
  newOrder._id = newOrder.id;
  return newOrder;
};

export const processSquareOrderDetail = (
  squareOrder,
  fmLocations,
  warehouses
) => {
  console.log('processSquareOrderDetail', squareOrder);
  const newOrder = squareOrder;
  newOrder._id = newOrder.id;
  newOrder.displayId = newOrder.id;
  newOrder.createdAt = newOrder.created_at;
  newOrder.paymentStatus = 'paid';

  newOrder.deliveryTime =
    newOrder.fulfillments[0].shipment_details.expected_shipped_at;

  newOrder.user = {
    id: newOrder.fulfillments[0].uid,
  };
  newOrder.bunbaoType = 'normalDelivery';

  newOrder.subtotal = newOrder.total_money.amount / 100;
  newOrder.discount = newOrder.total_discount_money.amount / 100;

  if (newOrder.location_id === 'LEDABDYNACX9M') {
    newOrder.bunbaoType = 'fixLocation';

    // search location
    const location = _.find(
      fmLocations,
      (o) =>
        newOrder.fulfillments[0].shipment_details.recipient.address
          .address_line_3 &&
        o.id ===
          newOrder.fulfillments[0].shipment_details.recipient.address
            .address_line_3
    );

    if (location) {
      const switchToNumber = (str) => {
        switch (str) {
          case 'SUN':
            return 0;
          case 'MON':
            return 1;
          case 'TUE':
            return 2;
          case 'WED':
            return 3;
          case 'THU':
            return 4;
          case 'FRI':
            return 5;
          case 'SAT':
            return 6;
          default:
            return 0;
        }
      };

      newOrder.campaignContent = {
        defaultAddress: {
          firstName: location.name,
          lastName: '',
          addressOne:
            newOrder.fulfillments[0].shipment_details.recipient.address
              .address_line_1 || '',
          addressTwo:
            newOrder.fulfillments[0].shipment_details.recipient.address
              .address_line_2 || '',
        },
        pickupWindow: location.business_hours.periods.map((e) => {
          return {
            day: switchToNumber(e.day_of_week),
            start: e.start_local_time,
            end: e.end_local_time,
          };
        }),
        pickupTimeSlotFormatted: location.business_hours.periods.map((e) => {
          return `${firstLetterToUpperCase(e.day_of_week)} ${
            e.start_local_time
          } - ${e.end_local_time}`;
        }),
      };
    } else {
      newOrder.campaignContent = {
        defaultAddress: {
          firstName: '',
          lastName: '',
          addressOne: '',
          addressTwo: '',
        },
        pickupWindow: [],
        pickupTimeSlotFormatted: [],
      };
    }
  }
  newOrder.address = {
    firstName:
      newOrder.fulfillments[0].shipment_details.recipient.display_name || '',
    lastName: '',
    addressOne:
      newOrder.fulfillments[0].shipment_details.recipient.address
        .address_line_1 || '',
    addressTwo:
      newOrder.fulfillments[0].shipment_details.recipient.address
        .address_line_2 || '',
    city:
      newOrder.fulfillments[0].shipment_details.recipient.address.locality ||
      '',
    state:
      newOrder.fulfillments[0].shipment_details.recipient.address.region || '',
    zipCode:
      newOrder.fulfillments[0].shipment_details.recipient.address.postal_code ||
      '',
    phoneNumber:
      newOrder.fulfillments[0].shipment_details.recipient.phone_number || '',
  };

  const variants = [];
  squareOrder.line_items.forEach((element) => {
    let variant = {};

    if (element.modifiers && element.modifiers.length > 0) {
      if (element.modifiers.length === 2) {
        // 2 pcs and 2 flavors
        variant = {
          variantId: null,
          name: {
            zh: '2-Bun Box: Two flavors',
            en: '',
          },
          quantity: 1 * element.quantity,
          subVariants: element.modifiers.map((e) => {
            return {
              name: {
                zh: e.name,
                en: '',
              },
              quantity: 2,
            };
          }),
        };
      }
      if (element.modifiers.length === 3) {
        // 3 pcs and 3 flavors
        variant = {
          variantId: null,
          name: {
            zh: '9-Bun Box: Three flavors',
            en: '',
          },
          quantity: 1 * element.quantity,
          subVariants: element.modifiers.map((e) => {
            return {
              name: {
                zh: e.name,
                en: '',
              },
              quantity: 3,
            };
          }),
        };
      }
      if (element.modifiers.length === 9) {
        // 9 pcs and 9 flavors
        variant = {
          variantId: null,
          name: {
            zh: '9-Bun Box: Nine flavors',
            en: '',
          },
          quantity: 1 * element.quantity,
          subVariants: element.modifiers.map((e) => {
            return {
              name: {
                zh: e.name,
                en: '',
              },
              quantity: 1,
            };
          }),
        };
      }
    } else if (element.variation_name === '4-pc-box') {
      variant = {
        variantId: null,
        name: {
          zh: `${element.name} ${element.variation_name}`,
          en: '',
        },
        quantity: 1 * element.quantity,
        subVariants: Array(4).fill({
          name: {
            zh: element.name,
            en: '',
          },
          quantity: 1,
        }),
      };
    } else if (element.variation_name === '9-pc-box') {
      variant = {
        variantId: null,
        name: {
          zh: `${element.name} ${element.variation_name}`,
          en: '',
        },
        quantity: 1 * element.quantity,
        subVariants: Array(9).fill({
          name: {
            zh: element.name,
            en: '',
          },
          quantity: 1,
        }),
      };
    } else {
      variant = {
        name: {
          zh: `${element.name} ${element.variation_name}`,
          en: ``,
        },
        quantity: parseInt(element.quantity, 10),
        subVariants: null,
      };
    }
    // console.log('variant', variant);
    variants.push(variant);
  });

  newOrder.variants = variants;

  return newOrder;
};

export const translateWords = (originalWord, words) => {
  const wordsArray = words.map((word) => {
    const wordArray = word.split(',');
    return {
      english: wordArray[0],
      chinese: wordArray[1],
    };
  });
  const foundWord = wordsArray.find(
    (word) => word.english === originalWord.toLowerCase()
  );
  if (foundWord) {
    return foundWord.chinese;
  }
  return originalWord;
};

// Apollo Client
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    return object._id || object.id || defaultDataIdFromObject(object);
  },
});

const httpLink = new HttpLink({
  uri: API_URL(),
});

const wsLink = new WebSocketLink({
  uri: WEBSOCKET_API_URL(),
  options: {
    reconnect: true,
    // connectionParams: () => ({
    //   authorization: middleware.token ? `Bearer ${middleware.token}` : '',
    // }),
  },
});

const subscriptionMiddleware = {
  applyMiddleware(options, next) {
    options.authorization = middleware.token
      ? `Bearer ${middleware.token}`
      : '';
    next();
  },
};

wsLink.subscriptionClient.use([subscriptionMiddleware]);

const authLink = setContext((_input, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: middleware.token ? `Bearer ${middleware.token}` : '',
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const apolloClient = new ApolloClient({
  link,
  cache,
});

// Saleor Client
const cacheSaleor = new InMemoryCache();
const httpLinkSaleor = new HttpLink({
  uri: API_URL_SALEOR,
  credentials: 'include',
});

const authLinkSaleor = setContext((_input, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: middleware.token ? `JWT ${middleware.token}` : '',
    },
  };
});

const saleorClient = new ApolloClient({
  link: authLinkSaleor.concat(httpLinkSaleor),
  cache: cacheSaleor,
});

const updateVariantPrice = async (
  shoppingCart, // shoppingCart from redux
  updateWarehouseCart, // updateWarehouseCary from redux
  collectionsList = []
) => {
  console.log('update *** fire');
  console.log(shoppingCart);
  // update price
  const variantIds = [];
  shoppingCart.productList.forEach((product) => {
    variantIds.push(product._id);
  });
  shoppingCart.boxList.forEach((box) => {
    box.boxContent.forEach((product) => {
      variantIds.push(product._id);
    });
  });
  const variantsResponse = await apolloClient.query({
    query: VARIANTS_QUERY_IDS,
    variables: { input: variantIds },
    fetchPolicy: 'network-only',
  });

  const variants = variantsResponse.data.variantByIds;
  console.log(variants);
  let isPriceSame = true;
  // update shoppingCart
  const newShoppingCart = {
    ...shoppingCart,
    productList: shoppingCart.productList.map((product) => {
      const newShoppingListItem = { ...product };
      const foundVariant = variants.find(
        (variant) => variant._id === newShoppingListItem._id
      );
      console.log('found variant', foundVariant);
      if (foundVariant) {
        // update status
        const properties = [
          'currentGroupOrders',
          'excludeFromFreeShipping',
          'groupBuyEndDate',
          'groupBuyStartDate',
          'isGroupBuy',
          'allowedShippingDate',
          'allowedInventoryCheck',
          'groupBuyRules',
          'minimumAddQuantity',
        ];
        properties.forEach((key) => {
          newShoppingListItem[key] = foundVariant[key];
        });
        // check date and change list price
        const today = moment().valueOf();
        if (
          foundVariant.isChangeListPriceByDates &&
          today <= foundVariant.changeListPriceDates.endDate &&
          today >= foundVariant.changeListPriceDates.startDate
        ) {
          isPriceSame = isPriceSame
            ? newShoppingListItem.listPrice === foundVariant.listPrice
            : false;
          newShoppingListItem.listPrice = foundVariant.listPrice;
        } else if (
          foundVariant.isChangeListPriceByDates &&
          (today > foundVariant.changeListPriceDates.endDate ||
            today < foundVariant.changeListPriceDates.startDate)
        ) {
          isPriceSame = isPriceSame
            ? newShoppingListItem.listPrice === foundVariant.originalPrice
            : false;
          newShoppingListItem.listPrice = foundVariant.originalPrice;
        } else {
          isPriceSame = isPriceSame
            ? newShoppingListItem.listPrice === foundVariant.listPrice
            : false;
          newShoppingListItem.listPrice = foundVariant.listPrice;
        }
      }
      return newShoppingListItem;
    }),
    boxList: shoppingCart.boxList.map((box) => {
      const newShoppingListItem = { ...box };

      newShoppingListItem.boxContent = box.boxContent.map((item) => {
        // TODO: check collection
        const count = box.boxContent.reduce(
          (sum, element) => sum + element.count,
          0
        );
        console.log(count);

        let discountPercentage = 1;
        if (
          collectionsList.find(
            (element) => element.container.capacity === count
          )
        ) {
          discountPercentage = collectionsList.find(
            (element) => element.container.capacity === count
          ).container.discountPercentage;
        }
        console.log(discountPercentage);
        const newItem = { ...item };
        const foundVariant = variants.find(
          (variant) => variant._id === newItem._id
        );
        console.log(foundVariant);
        console.log('new', newItem);
        if (foundVariant) {
          // check date and change list price
          const today = moment().valueOf();
          if (discountPercentage > 1) {
            if (
              foundVariant.isChangeListPriceByDates &&
              today <= foundVariant.changeListPriceDates.endDate &&
              today >= foundVariant.changeListPriceDates.startDate &&
              newItem.listPrice < foundVariant.listPrice * discountPercentage
            ) {
              newItem.listPrice *= discountPercentage;
            } else if (
              foundVariant.isChangeListPriceByDates &&
              (today > foundVariant.changeListPriceDates.endDate ||
                today < foundVariant.changeListPriceDates.startDate) &&
              newItem.listPrice <
                foundVariant.originalPrice * discountPercentage
            ) {
              newItem.listPrice *= discountPercentage;
            } else if (
              !foundVariant.isChangeListPriceByDates &&
              newItem.listPrice < foundVariant.listPrice * discountPercentage
            ) {
              newItem.listPrice *= discountPercentage;
            } else {
              console.log(
                'do nothing , no need to multiple discountPercentage'
              );
              console.log(
                newItem.listPrice,
                foundVariant.listPrice * discountPercentage,
                foundVariant.originalPrice * discountPercentage
              );
            }
          }
          // newItem.listPrice *= discountPercentage;
          console.log(
            newItem.listPrice,
            foundVariant.listPrice,
            foundVariant.originalPrice
          );
          if (
            foundVariant.isChangeListPriceByDates &&
            today <= foundVariant.changeListPriceDates.endDate &&
            today >= foundVariant.changeListPriceDates.startDate
          ) {
            isPriceSame =
              newItem.listPrice === foundVariant.listPrice * discountPercentage;
            newItem.listPrice = isPriceSame
              ? newItem.listPrice
              : foundVariant.listPrice * discountPercentage;
          } else if (
            foundVariant.isChangeListPriceByDates &&
            (today > foundVariant.changeListPriceDates.endDate ||
              today < foundVariant.changeListPriceDates.startDate)
          ) {
            isPriceSame =
              newItem.listPrice ===
              foundVariant.originalPrice * discountPercentage;
            newItem.listPrice = isPriceSame
              ? newItem.listPrice
              : foundVariant.originalPrice * discountPercentage;
          } else {
            isPriceSame =
              newItem.listPrice === foundVariant.listPrice * discountPercentage;
            newItem.listPrice = isPriceSame
              ? newItem.listPrice
              : foundVariant.listPrice * discountPercentage;
          }
        }
        return newItem;
      });
      let totalPrice = 0;
      newShoppingListItem.boxContent.forEach((item) => {
        totalPrice += item.listPrice * item.count;
      });
      newShoppingListItem.listPrice = totalPrice;
      return newShoppingListItem;
    }),
  };

  // delete invalid groupBuy variants
  let isVariantsSame = true;
  const isGroupBuyEnable = (variant) => {
    const today = new Date().valueOf();
    if (variant.isGroupBuy) {
      if (isVariantsSame) {
        isVariantsSame =
          variant.groupBuyEndDate > today && variant.groupBuyStartDate < today;
      }
      return (
        variant.groupBuyEndDate > today && variant.groupBuyStartDate < today
      );
    }
    return true;
  };
  newShoppingCart.productList =
    newShoppingCart?.productList?.filter(
      (variant) => isGroupBuyEnable(variant) && !variant.markAsSoldOut
    ) || [];
  await updateWarehouseCart(newShoppingCart);
  console.log(isPriceSame, isVariantsSame);
  return isPriceSame && isVariantsSame;
};

const fetchSquareCustomerOrders = async (
  orderType,
  pageIndex,
  pageSize,
  currentTime,
  startAt,
  endAt
) => {
  const round2 = (value) => {
    if (typeof value === 'undefined') {
      return 0;
    }
    return Number(Number(value / 100).toFixed(2));
  };
  const formatLineItem = (item) => {
    return {
      uid: item.uid,
      name: item.name,
      quantity: Number(item.quantity),
      catalogObjectId: item.catalog_object_id,
      variationName: item.variation_name,
      itemType: item.item_type,
      basePrice: round2(item.base_price_money?.amount),
      variationTotalPrice: round2(item.variation_total_price_money?.amount),
      grossSales: round2(item.gross_sales_money?.amount),
      totalTax: round2(item.total_tax_money?.amount),
      totalDiscount: round2(item.total_discount_money?.amount),
      orderTotal: round2(item.total_money?.amount),
    };
  };

  const params = {
    accessToken: 'bunbao_test_token',
    orderType,
    pageIndex,
    pageSize,
    currentTime: moment(currentTime).utc().toISOString(),
  };

  if (startAt && endAt) {
    params.startAt = moment(startAt).startOf('day').utc().toISOString();
    params.endAt = moment(endAt).endOf('day').utc().toISOString();
  }

  const response = await axios({
    url: 'https://w3aoayqmb1.execute-api.us-west-2.amazonaws.com/Prod/website/orderPagination',
    params,
  });

  const formattedOrders = { count: 0, orders: [] };

  if (response.data?.status === 'success') {
    formattedOrders.count = response.data?.count;
    response.data.orders.forEach((order) => {
      const lineItems = [];
      order.square_order_json?.line_items.forEach((item) => {
        lineItems.push(formatLineItem(item));
      });

      const returnLineItems = [];
      let totalReturn = 0;
      let totalReturnTax = 0;
      let totalReturnDiscount = 0;
      let totalReturnTip = 0;
      let totalReturnServiceCharge = 0;

      order.return_details?.forEach((returnOrder) => {
        totalReturn += round2(
          returnOrder.square_order_json?.return_amounts?.total_money?.amount
        );
        totalReturnTax += round2(
          returnOrder.square_order_json?.return_amounts?.tax_money?.amount
        );
        totalReturnDiscount += round2(
          returnOrder.square_order_json?.return_amounts?.discount_money?.amount
        );
        totalReturnTip += round2(
          returnOrder.square_order_json?.return_amounts?.tip_money?.amount
        );
        totalReturnServiceCharge += round2(
          returnOrder.square_order_json?.return_amounts?.service_charge_money
            ?.amount
        );
        returnOrder.square_order_json?.returns?.forEach((returns) => {
          returns.return_line_items?.forEach((item) => {
            returnLineItems.push(formatLineItem(item));
          });
        });
      });

      formattedOrders.orders.push({
        _id: order.square_order_id,
        customerId: order.customer_id,
        createdAt: order.created_at,
        orderType:
          order.location_id === 'LEDABDYNACX9M' ? 'pickup' : 'delivery',
        isRefunded: order.return_details.length > 0,
        total: round2(order.square_order_json?.total_money?.amount),
        netAmount: round2(
          order.square_order_json?.net_amounts?.total_money?.amount
        ),
        totalTax: round2(order.square_order_json?.total_tax_money?.amount),
        totalDiscount: round2(
          order.square_order_json?.total_discount_money?.amount
        ),
        totalTip: round2(order.square_order_json?.total_tip_money?.amount),
        totalServiceCharge: round2(
          order.square_order_json?.total_service_charge_money?.amount
        ),
        totalReturn,
        totalReturnTax,
        totalReturnDiscount,
        totalReturnTip,
        totalReturnServiceCharge,
        lineItems,
        returnLineItems,
        fulfillments: order.square_order_json?.fulfillments,
      });
    });
  }

  return formattedOrders;
};

export {
  apolloClient,
  saleorClient,
  colorGenerator,
  everyWordFirstLetterToUpperCase,
  firstLetterToUpperCase,
  getColor,
  updateObject,
  checkValidity,
  getColorTwo,
  getCustomerPath,
  getPointPath,
  checkAlipayUserAgent,
  checkWeChatUserAgent,
  waitFunction,
  mosaicEmail,
  updateVariantPrice,
  displayDescription,
  fetchSquareCustomerOrders,
};
