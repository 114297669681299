import { isMobile } from 'react-device-detect';

export const blockCssObjectTypeOne = {
  width: '100%',
  fontWeight: '700',
  padding: '0px 15px',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: isMobile ? '14px' : '16px',
  borderRadius: '5px',
  borderRight: '3px solid white',
  border: '1px solid #DF242F',
  backgroundColor: '#DF242F',
  color: 'white',
  marginRight: '5px',
};

export const borderCssObjectTypeOne = {
  padding: '10px',
  borderRadius: '5px',
  border: '1px solid #F2F2F2',
  marginRight: '5px',
};
