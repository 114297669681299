/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import * as QueryString from 'query-string';
import PropTypes from 'prop-types';
import { Tabs, Table, Spin, Modal, Tooltip, message } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import L from 'lodash';
import BraftEditor from 'braft-editor';
import { convertRawToHTML } from 'braft-convert';
import convert from 'htmr';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, injectIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { apolloClient, getCustomerPath } from '../../../../util';
import { CONFIGURATION_FIND_ONE } from '../../../../api/configuration';
import {
  BALANCE_BY_USER,
  BALANCE_LOG_PAGINATION,
} from '../../../../api/balance';
import { BANNER_FIND_MANY } from '../../../../api/banner';
import { VARIANT_FETCH_ALL_WITH_FILTER } from '../../../../api/variant';
import * as actions from '../../../../store/actions';
import './Balance.less';
import shoppingBag from '../../../../assets/images/icons/shopping-bag-balance.svg';
import giftCard from '../../../../assets/images/icons/gift-card.svg';
import redeemGiftCard from '../../../../assets/images/icons/gift-card-redeem.svg';
import RedeemGiftCardForm from '../../../../components/Forms/RedeemGiftCardForm/RedeemGiftCardForm';
import MenuBanner from '../../../../components/MenuBanner/MenuBanner';

const { TabPane } = Tabs;
const PAGE_SIZE = 10;
class Balance extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      myBalance: 0,
      balanceDetails: {
        referralTotal: 0,
        refundTotal: 0,
        promotionTotal: 0,
        topUpTotal: 0,
      },
      tableData: [],
      extraFilter: {},
      loading: false,
      pagination: {
        current: 1,
        pageSize: PAGE_SIZE,
      },
      // isTurnOn: null,
      referralCodeModal: false,
      referralUserDetail: null,
      globalReferralCoefficient: null,
      globalReferralLevel: null,
      topUpModalVisible: false,
      topUpCardList: [],
      redeemModalVisible: false,
      cardNumber: null,
      cardPin: null,
      slides: [],
      selectedIsClickableBanner: null,
    };
    console.log(props);
  }

  async componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { redeem, cardNumber, cardPin, rules } = QueryString.parse(search);
    if (redeem === 'true') {
      await this.setState({
        redeemModalVisible: true,
        cardNumber,
        cardPin,
      });
    }
    if (rules === 'true') {
      await this.setState({ referralCodeModal: true });
    }
    const { pagination } = this.state;
    this.fetchUserBalance();
    this.fetchBalanceLog(pagination);
    await this.fetchTopUpCards();
    await this.fetchBanner();
  }

  fetchTopUpCards = async () => {
    const filter = { isTopUpCard: true, deleted: false };
    try {
      const {
        data: { variantMany },
      } = await apolloClient.query({
        query: VARIANT_FETCH_ALL_WITH_FILTER,
        variables: { filter },
      });
      console.log(variantMany);
      this.setState({ topUpCardList: variantMany });
    } catch (err) {
      console.log(err);
    }
  };

  fetchUserBalance = async () => {
    const {
      user: { userId },
    } = this.props;
    try {
      const {
        data: { BalanceOne },
      } = await apolloClient.query({
        query: BALANCE_BY_USER,
        variables: { filter: { userId } },
        fetchPolicy: 'network-only',
      });

      if (
        BalanceOne.user &&
        BalanceOne.user.referralLevel &&
        BalanceOne.user.referralLevel.length === 0
      ) {
        try {
          const {
            data: { configurationOne },
          } = await apolloClient.query({
            query: CONFIGURATION_FIND_ONE,
            variables: { filter: { isReferConfiguration: true } },
          });
          console.log(configurationOne);
          if (configurationOne.referConfiguration) {
            this.setState({
              isTurnOn: configurationOne.referConfiguration.isTurnOn,
              globalReferralCoefficient: configurationOne.referConfiguration
                .isTurnOn
                ? configurationOne.referConfiguration.referRatePerOrder
                : null,
              globalReferralLevel:
                configurationOne.referConfiguration.referralLevel || [],
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
      this.setState({
        myBalance: BalanceOne.total,
        balanceDetails: {
          referralTotal: BalanceOne.referralTotal,
          refundTotal: BalanceOne.refundTotal,
          topUpTotal: BalanceOne.topUpTotal,
          promotionTotal: BalanceOne.promotionTotal,
        },
        referralUserDetail: BalanceOne.user,
      });
    } catch (error) {
      console.debug(error);
    }
  };

  fetchBanner = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'balance' } },
      });
      const rawData = bannerFindMany;
      const slides = L.orderBy(rawData, ['ranking'], ['asc']);
      this.setState({ slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  handleIsClickableClicked = (banner) => {
    const { lang } = this.props;
    this.setState({
      referralCodeModal: !!BraftEditor.createEditorState(
        convertRawToHTML(JSON.parse(banner.bannerContent[lang] || '{}'))
      ).toText(),
      selectedIsClickableBanner: banner,
    });
  };

  fetchBalanceLog = async (pagination, input = {}, loadMore = false) => {
    const { tableData } = this.state;
    this.setState({ loading: true });
    const {
      user: { userId },
    } = this.props;
    const filter = { userId };
    if (Object.keys(input).length) {
      Object.assign(filter, input);
    }
    console.log(filter);
    try {
      const {
        data: { BalanceLogPagination },
      } = await apolloClient.query({
        query: BALANCE_LOG_PAGINATION,
        variables: {
          filter,
          page: pagination.current,
          perPage: pagination.pageSize,
          sort: 'CREATEDAT_DESC',
        },
        fetchPolicy: 'network-only',
      });
      console.log(BalanceLogPagination);
      if (loadMore) {
        await this.setState({
          loading: false,
          tableData: [...tableData, ...BalanceLogPagination.items],
          pagination: {
            ...pagination,
            total: BalanceLogPagination.count,
          },
        });
      } else {
        await this.setState({
          loading: false,
          tableData: (BalanceLogPagination && BalanceLogPagination.items) || [],
          pagination: {
            ...pagination,
            total: BalanceLogPagination.count,
          },
        });
      }
    } catch (error) {
      console.debug(error);
    }
  };

  onTabChange = async (value) => {
    const pagination = {
      current: 1,
      pageSize: PAGE_SIZE,
    };
    switch (value) {
      case 'all': {
        await this.setState({
          extraFilter: {},
          pagination,
        });
        await this.fetchBalanceLog(pagination, {});
        break;
      }
      case 'add': {
        await this.setState({
          extraFilter: {
            _operators: {
              total: {
                gte: 0,
              },
            },
          },
          pagination,
        });
        const filter = {
          _operators: {
            total: {
              gte: 0,
            },
          },
        };
        await this.fetchBalanceLog(pagination, filter);
        break;
      }
      case 'used': {
        await this.setState({
          extraFilter: {
            _operators: {
              total: {
                lt: 0,
              },
            },
          },
          pagination,
        });
        const filter = {
          _operators: {
            total: {
              lt: 0,
            },
          },
        };
        await this.fetchBalanceLog(pagination, filter);
        break;
      }
      default:
        break;
    }
  };

  handleCloseRedeemModal = async () => {
    const { pagination } = this.state;
    const { history } = this.props;
    this.fetchUserBalance();
    this.fetchBalanceLog(pagination);
    await this.fetchTopUpCards();
    history.push(getCustomerPath('account/balance'));
    await this.setState({
      redeemModalVisible: false,
      cardPin: null,
      cardNumber: null,
    });
  };

  handleOpenReferCode = () => {
    this.setState({
      referralCodeModal: true,
    });
  };

  handleSelectedTopUpCard = async (variant) => {
    const { setUserShoppingMode, updateWarehouseCart, setWarehouse, history } =
      this.props;
    const newVariant = { ...variant, count: 1 };
    const shoppingCart = {
      productList: [newVariant],
      boxList: [],
    };
    await setUserShoppingMode('eShop');
    await setWarehouse(variant.warehouse._id);
    await updateWarehouseCart(shoppingCart);
    history.push(getCustomerPath('checkout/payment'));
  };

  render() {
    const { lang, intl } = this.props;
    console.debug(lang);
    const {
      myBalance,
      tableData,
      pagination,
      loading,
      extraFilter,
      referralCodeModal,
      referralUserDetail,
      globalReferralCoefficient,
      globalReferralLevel,
      // isTurnOn,
      topUpModalVisible,
      topUpCardList,
      balanceDetails: {
        referralTotal,
        refundTotal,
        promotionTotal,
        topUpTotal,
      },
      redeemModalVisible,
      cardNumber,
      cardPin,
      slides,
      selectedIsClickableBanner,
    } = this.state;

    const operationRender = (type, total, status = null) => {
      const statusRender = (balanceStatus, contentNode) => {
        switch (balanceStatus) {
          case 'refunded':
            return (
              <div>
                <FormattedMessage id="balance.refundedBalance" />
              </div>
            );
          case 'pending':
            return (
              <div>
                {contentNode}({balanceStatus})
              </div>
            );
          case 'inValid':
            return (
              <div>
                <FormattedMessage id="balance.invalid" />
              </div>
            );
          default:
            return contentNode;
        }
      };
      switch (type) {
        case 'referral': {
          return (
            <React.Fragment>
              {Number(total).toFixed(2) > 0 ? (
                statusRender(
                  status,
                  <FormattedMessage id="balance.referralExplain" />
                )
              ) : (
                <FormattedMessage id="balance.balanceUsed" />
              )}
            </React.Fragment>
          );
        }
        case 'topUp': {
          return (
            <React.Fragment>
              {Number(total).toFixed(2) > 0 ? (
                statusRender(
                  status,
                  <FormattedMessage id="balance.topUpExplain" />
                )
              ) : (
                <FormattedMessage id="balance.balanceUsed" />
              )}
            </React.Fragment>
          );
        }
        case 'placeOrder': {
          return (
            <React.Fragment>
              {Number(total).toFixed(2) > 0 ? (
                statusRender(
                  status,
                  <FormattedMessage id="balance.placeOrder" />
                )
              ) : (
                <FormattedMessage id="balance.balanceUsed" />
              )}
            </React.Fragment>
          );
        }
        case 'promotion': {
          return (
            <React.Fragment>
              {Number(total).toFixed(2) > 0 ? (
                statusRender(
                  status,
                  <FormattedMessage id="balance.promotion" />
                )
              ) : (
                <FormattedMessage id="balance.balanceUsed" />
              )}
            </React.Fragment>
          );
        }
        case 'refund': {
          return (
            <React.Fragment>
              {Number(total).toFixed(2) > 0 ? (
                statusRender(
                  status,
                  <FormattedMessage id="balance.refundedBalance" />
                )
              ) : (
                <FormattedMessage id="balance.balanceUsed" />
              )}
            </React.Fragment>
          );
        }
        default:
          return <span>{type}</span>;
      }
    };

    const columns = [
      {
        title: intl.formatMessage({ id: 'balance.amount' }),
        dataIndex: 'total',
        key: 'total',
        render: (_, record) => <div>${Number(record.total).toFixed(2)}</div>,
      },
      {
        title: intl.formatMessage({
          id: 'balance.operation',
        }),
        dataIndex: 'type',
        render: (_, record) => {
          if (record.status === 'paid') {
            return <FormattedMessage id="balance.placeOrder" />;
          }
          return operationRender(record.type, record.total, record.status);
        },
      },
      {
        title: intl.formatMessage({ id: 'balance.detail' }),
        dataIndex: ['customerOrder', 'total'],
        key: 'customerOrder.total',
        render: (_, record) => {
          if (!record.customerOrder) {
            return (
              <div>
                <FormattedMessage id="balance.adminOperation" />
              </div>
            );
          }
          if (record.status === 'paid') {
            return (
              <FormattedMessage
                id="balance.placeOrderExplain"
                values={{
                  amount: `$${Number(record.customerOrder.total).toFixed(2)}`,
                  orderId: record.customerOrder.displayId,
                }}
              />
            );
          }
          if (record.status === 'refunded') {
            return (
              <FormattedMessage
                id="balance.refundedBalanceDetail"
                values={{
                  amount: `$${Number(record.total).toFixed(2)}`,
                  orderId: record.customerOrder.displayId,
                }}
              />
            );
          }

          switch (record.type) {
            case 'referral':
              return (
                <div>
                  {Number(record.total).toFixed(2) > 0 ? (
                    <FormattedMessage
                      id="balance.referralDetail"
                      values={{
                        amount: `$${Number(record.customerOrder.total).toFixed(
                          2
                        )}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="balance.balanceUsedDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  )}
                </div>
              );
            case 'topUp':
              return (
                <div>
                  {Number(record.total).toFixed(2) > 0 ? (
                    <FormattedMessage
                      id="balance.topUpDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="balance.balanceUsedDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  )}
                </div>
              );
            case 'promotion':
              return (
                <div>
                  {Number(record.total).toFixed(2) > 0 ? (
                    <FormattedMessage
                      id="balance.promotionDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="balance.balanceUsedDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  )}
                </div>
              );
            case 'placeOrder':
              return (
                <div>
                  {Number(record.total).toFixed(2) > 0 ? (
                    <FormattedMessage
                      id="balance.placeOrderExplain"
                      values={{
                        amount: `$${Number(record.customerOrder.total).toFixed(
                          2
                        )}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="balance.balanceUsedDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  )}
                </div>
              );
            case 'refund': {
              return (
                <div>
                  {Number(record.total).toFixed(2) > 0 ? (
                    <FormattedMessage
                      id="balance.refundedBalanceDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="balance.balanceUsedDetail"
                      values={{
                        amount: `$${Number(record.total).toFixed(2)}`,
                        orderId: record.customerOrder.displayId,
                      }}
                    />
                  )}
                </div>
              );
            }
            default:
              return <div />;
          }
        },
      },
      // {
      //   title: intl.formatMessage({ id: 'point.status' }),
      //   dataIndex: 'status',
      //   key: 'status',
      //   render: (_, record) => <div>{record.status}</div>,
      // },
      {
        title: intl.formatMessage({ id: 'balance.date' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => (
          <div>{moment(record.createdAt).format('MM/DD/YYYY')}</div>
        ),
      },
    ];

    const desktop = (
      <div className="balance-desktop">
        <div
          style={{ padding: '10px 30px', maxWidth: '1024px', margin: 'auto' }}
        >
          <div>
            <span className="common-bold-title">
              <FormattedMessage id="balance.myBalance" />
            </span>
            {/* <span style={{ paddingLeft: '20px', textDecoration: 'underline' }}>
              <FormattedMessage id="balance.balanceRule" />
            </span> */}
          </div>
          <div
            className="common-margin-top-lg my-balance-banner"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div className="my-balance">
              <div style={{ fontWeight: '500', fontSize: '25px' }}>
                <FormattedMessage id="balance.myBalance" />
              </div>{' '}
              <div className="common-bold-title">
                ${Number(myBalance).toFixed(2)}
                <span css={{ marginLeft: '5px' }}>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div>
                        <div>
                          Referral Total: {Number(referralTotal).toFixed(2)}
                        </div>
                        <div>
                          Refunded Total: {Number(refundTotal).toFixed(2)}
                        </div>
                        <div>Top-up Total: {Number(topUpTotal).toFixed(2)}</div>
                        <div>
                          Promotion Total: {Number(promotionTotal).toFixed(2)}
                        </div>
                      </div>
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              </div>
              {/* TODO: uncomment when unlock gift card */}
              {/* <div>
                <Link
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '16px',
                  }}
                  to={getCustomerPath('eshop')}
                >
                  <FormattedMessage id="balance.buyGiftCard" />
                </Link>
              </div>
              <div>
                <span
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                  role="presentation"
                  onClick={() => {
                    this.setState({ redeemModalVisible: true });
                  }}
                >
                  <FormattedMessage id="balance.redeemGiftCard" />
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                  role="presentation"
                  onClick={() => {
                    this.setState({ topUpModalVisible: true });
                  }}
                >
                  <FormattedMessage id="balance.topUp" />
                </span>
              </div><div>
                <Link
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '16px',
                  }}
                  to={getCustomerPath('eshop')}
                >
                  <FormattedMessage id="balance.buyGiftCard" />
                </Link>
              </div>
              <div>
                <span
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                  role="presentation"
                  onClick={() => {
                    this.setState({ redeemModalVisible: true });
                  }}
                >
                  <FormattedMessage id="balance.redeemGiftCard" />
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                  role="presentation"
                  onClick={() => {
                    this.setState({ topUpModalVisible: true });
                  }}
                >
                  <FormattedMessage id="balance.topUp" />
                </span>
              </div> */}
            </div>
            <div
              className="flex-center go-shopping"
              style={{ marginRight: '10%' }}
            >
              <Link
                to={getCustomerPath('supermarket')}
                style={{ color: 'white' }}
              >
                <img
                  style={{ height: '23px', marginRight: '20px' }}
                  src={shoppingBag}
                  alt="shoppingBag"
                />
                <FormattedMessage id="balance.goShopping" />
              </Link>
            </div>
          </div>

          <div className="common-margin-top-sm balance-function">
            <div
              className="flex-center"
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
                fontSize: '19px',
              }}
              // role="presentation"
              // onClick={() => {
              //   this.handleOpenReferCode();
              // }}
            >
              <Link
                style={{
                  color: 'black',
                }}
                to={getCustomerPath('eshop')}
              >
                <img style={{ height: '30px' }} src={giftCard} alt="giftCard" />

                <span css={{ marginLeft: '5px' }}>
                  <FormattedMessage id="balance.buyGiftCard" />
                </span>
              </Link>
            </div>

            <div
              className="flex-center"
              style={{
                fontWeight: 'bold',
                fontSize: '19px',
                color: 'black',
                cursor: 'pointer',
              }}
              role="presentation"
              onClick={() => {
                this.setState({ redeemModalVisible: true });
              }}
            >
              <img
                style={{ height: '30px' }}
                src={redeemGiftCard}
                alt="giftCard"
              />
              <span css={{ marginLeft: '5px' }}>
                <FormattedMessage id="balance.redeemGiftCard" />
              </span>
            </div>
          </div>

          <div className="common-margin-top-sm">
            <MenuBanner
              slides={slides}
              language={lang}
              handleIsClickableClicked={this.handleIsClickableClicked}
              handleBannerClick={() => {}}
            />
          </div>

          <div className="common-margin-top-lg">
            <div className="common-bold-title">
              <FormattedMessage id="balance.balanceTransactions" />
            </div>
            <div className="common-margin-top-sm">
              <Tabs
                className="ant-tab-line-remove"
                defaultActiveKey="1"
                onChange={this.onTabChange}
                tabBarStyle={{ color: 'black' }}
              >
                <TabPane
                  tab={intl.formatMessage({ id: 'balance.all' })}
                  key="all"
                />
                <TabPane
                  tab={intl.formatMessage({ id: 'balance.add' })}
                  key="add"
                />
                <TabPane
                  tab={intl.formatMessage({ id: 'balance.used' })}
                  key="used"
                />
              </Tabs>
            </div>
            <div
              className="common-margin-top-sm antd-table"
              style={{
                borderRadius: '25px 25px 0px',
                overflow: 'hidden',
              }}
            >
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey="_id"
                onChange={async (params) => {
                  await this.fetchBalanceLog(params, extraFilter);
                }}
                loading={loading}
                pagination={pagination}
              />
            </div>
          </div>
        </div>
      </div>
    );

    const mobile = (
      <div style={{ padding: '0px 10px' }} className="balance-mobile">
        <div className="banner">
          <div className="my-balance">
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '25px' }}>
                <div>
                  <FormattedMessage id="balance.myBalance" />
                </div>
                ${Number(myBalance).toFixed(2)}{' '}
                <Tooltip
                  placement="topLeft"
                  title={
                    <div>
                      <div>
                        Referral Total: {Number(referralTotal).toFixed(2)}
                      </div>
                      <div>
                        Refunded Total: {Number(refundTotal).toFixed(2)}
                      </div>
                    </div>
                  }
                >
                  {` `}
                  <span css={{ marginLeft: '5px' }}>
                    <QuestionCircleOutlined />
                  </span>
                </Tooltip>
              </span>{' '}
              {/* <FormattedMessage id="balance.dollar" /> */}
            </div>
            <div className="flex-center" style={{ flexDirection: 'column' }}>
              <div>
                <Link
                  to={getCustomerPath('supermarket')}
                  style={{ color: 'white' }}
                  className="flex-center"
                >
                  <img
                    style={{ height: '18px', marginRight: '10px' }}
                    src={shoppingBag}
                    alt="shoppingBag"
                  />
                  <FormattedMessage id="balance.goShopping" />
                </Link>
              </div>
              {/* <div className="balance-rules">
                <FormattedMessage id="balance.balanceRule" />
              </div> */}
              {/* TODO: uncomment unlock balance gift card */}
              {/* <div style={{ marginTop: '3px' }}>
                <Link
                  style={{
                    color: 'white',
                  }}
                  to={getCustomerPath('eshop')}
                >
                  <span className="balance-rules">
                    <FormattedMessage id="balance.buyGiftCard" />
                  </span>
                </Link>
              </div>
              <div
                style={{ marginTop: '3px' }}
                role="presentation"
                onClick={() => {
                  this.setState({ redeemModalVisible: true });
                }}
              >
                <span className="balance-rules">
                  <FormattedMessage id="balance.redeemGiftCard" />
                </span>
              </div>
              <div
                style={{ marginTop: '3px' }}
                role="presentation"
                onClick={() => {
                  this.setState({ topUpModalVisible: true });
                }}
              >
                <span className="balance-rules">
                  <FormattedMessage id="balance.topUp" />
                </span>
              </div> */}
            </div>
          </div>
          <div className="balance-functions">
            <div
              className="flex-center"
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              <Link
                className="flex-center"
                style={{
                  color: 'black',
                }}
                to={getCustomerPath('eshop')}
              >
                <img style={{ height: '23px' }} src={giftCard} alt="giftCard" />

                <span css={{ marginLeft: '5px' }}>
                  <FormattedMessage id="balance.buyGiftCard" />
                </span>
              </Link>
            </div>
            <div
              className="flex-center"
              style={{ fontWeight: 'bold' }}
              role="presentation"
              onClick={() => {
                this.setState({ redeemModalVisible: true });
              }}
            >
              <img
                style={{ height: '23px' }}
                src={redeemGiftCard}
                alt="giftCard"
              />
              <span css={{ marginLeft: '5px' }}>
                <FormattedMessage id="balance.redeemGiftCard" />
              </span>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '35px' }}>
          <MenuBanner
            slides={slides}
            language={lang}
            handleIsClickableClicked={this.handleIsClickableClicked}
            handleBannerClick={() => {}}
          />
        </div>

        <div>
          <Tabs
            className="ant-tab-line-remove"
            defaultActiveKey="1"
            onChange={this.onTabChange}
            tabBarStyle={{ color: 'black' }}
          >
            <TabPane
              tab={intl.formatMessage({ id: 'balance.all' })}
              key="all"
            />
            <TabPane
              tab={intl.formatMessage({ id: 'balance.add' })}
              key="add"
            />
            <TabPane
              tab={intl.formatMessage({ id: 'balance.used' })}
              key="used"
            />
          </Tabs>
        </div>

        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={tableData.length}
          next={async () => {
            const nextPagination = {
              ...pagination,
              ...{ current: pagination.current + 1 },
            };
            await this.setState({ pagination: nextPagination });
            await this.fetchBalanceLog(nextPagination, extraFilter, true);
          }}
          hasMore={pagination.current * PAGE_SIZE < pagination.total}
          loader={
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <Spin />
            </div>
          }
        >
          <div className="common-margin-top">
            {tableData.length ? (
              tableData.map((item) => (
                <div key={item._id} className="balance-item">
                  <div className="dash-line-container flex-center">
                    <div className="line-dot" />
                    <div className="line-dashed" />
                  </div>
                  <div className="total">
                    <div> {Number(item.total).toFixed(2)}</div>
                    <div style={{ color: '#717171', fontWeight: 'normal' }}>
                      {moment(item.createdAt).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="padding-top order-id">
                    <FormattedMessage id="balance.operation" />:{' '}
                    {operationRender(item.type, item.total, item.status)}
                  </div>
                  {!item.customerOrder && (
                    <div className="padding-top order-id">
                      <FormattedMessage id="balance.detail" />:{' '}
                      <FormattedMessage id="balance.adminOperation" />
                    </div>
                  )}

                  {item.type !== 'referral' && item.customerOrder && (
                    <div className="padding-top order-id">
                      <FormattedMessage id="balance.orderDisplayId" />:{' '}
                      {item.customerOrder.displayId}
                    </div>
                  )}
                  {item.customerOrder && (
                    <div className="padding-top order-id">
                      <FormattedMessage id="balance.orderTotal" />:{' '}
                      {item.customerOrder.total.toFixed(2)}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>No Data</div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
    // if (isTurnOn === false) {
    //   return (
    //     <div
    //       style={{
    //         minHeight: '100vh',
    //         fontSize: '20px',
    //         textAlign: 'center',
    //         width: '100%',
    //       }}
    //     >
    //       {lang === 'zh' ? '敬请期待' : 'Coming Soon'}
    //     </div>
    //   );
    // }
    return (
      <div>
        <Modal
          footer={null}
          visible={referralCodeModal}
          destroyOnClose
          onCancel={() => {
            this.setState({
              referralCodeModal: false,
            });
            const { history } = this.props;
            history.replace(getCustomerPath('account/balance'));
          }}
        >
          {referralUserDetail && (
            <div>
              {selectedIsClickableBanner && (
                <div>
                  {lang === 'zh' ? (
                    <div>
                      {convert(
                        convertRawToHTML(
                          JSON.parse(selectedIsClickableBanner.bannerContent.zh)
                        )
                      )}
                    </div>
                  ) : (
                    <div>
                      {convert(
                        convertRawToHTML(
                          JSON.parse(selectedIsClickableBanner.bannerContent.en)
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* <div>
                {lang === 'zh' ? (
                  <div>
                    <div>
                      <b>“种草返利”活动规则</b>
                    </div>
                    <div>
                      把你感兴趣的商品推荐给周围的朋友，朋友下单后你可以立即获得3%返利，快邀请小伙伴们一起来分享赚钱吧！
                    </div>

                    <div>
                      <b>如何分享？</b>
                    </div>
                    <div>
                      成为棒!包的注册用户，在棒!包[美食超市]，点击产品图片右上角分享按钮，形成链接，即可分享。链接有你的专属”邀请码小尾巴“，且“小尾巴”不会过期哦～
                    </div>

                    <div>
                      <b>如何获得返利？</b>
                    </div>
                    <div>
                      当其他人通过你的链接下单并完成支付，你将会在[我的账户]-[余额]里看到该订单，以及该订单带来的3%返利，并即刻可以使用。
                    </div>

                    <div>
                      <b>如何使用返利？</b>
                    </div>
                    <div>
                      已经获得了返利？现在去棒!包[美食超市]逛逛吧，结账时勾选使用余额即可用掉返利哦～
                    </div>

                    <div>
                      “种草返利”收益比例将由Bunbao.com.,Inc.根据业务情况保留随时调整的权利。
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <b>Share and Earn Cash Back</b>
                    </div>
                    <div>
                      Earn cash back every time you share a product. Get 3% cash
                      rewards once someone place an order with you personal
                      referral link.
                    </div>

                    <div>
                      <b>How to Share?</b>
                    </div>
                    <div>
                      Once you sign in on BunBao.com, go to [BunBao Market] and
                      click on the share button of any product. You&apos;ll get
                      a personal referral link. This link contains a referral
                      code that is linked to your account only and it won’t
                      expire.
                      <div>***Please always remember to sign in first.</div>
                    </div>

                    <div>
                      <b>How to Earn?</b>
                    </div>
                    <div>
                      Share this referral link. When someone place an order
                      using this link, your will immediately get 3% cash back on
                      this order. You can also check earning details at [My
                      Account] —&gt; [Balance].
                    </div>

                    <div>
                      <b>How to Use the reward?</b>
                    </div>
                    <div>
                      Congratulations! Now simply go to [BunBao Market] and
                      place an order. When you check out, select [Pay with
                      balance]. The more you share, the more you earn.
                    </div>

                    <div>BunBao.com, Inc. All Rights Reserved.</div>
                  </div>
                )}
                      </div> */}

              {/* <div>
                <FormattedMessage id="balance.referralCode" />
              </div>
              <div>{referralUserDetail.referralCode}</div>
              <div>
                <CopyToClipboard
                  text={`${
                    window.location.host + getCustomerPath('supermarket')
                  }?referralCode=${referralUserDetail.referralCode}`}
                  onCopy={() => {
                    Modal.success({
                      content: intl.formatMessage({ id: 'copied' }),
                    });
                  }}
                >
                  <div>
                    {`${
                      window.location.host + getCustomerPath('supermarket')
                    }?referralCode=${referralUserDetail.referralCode}`}
                  </div>
                </CopyToClipboard>
              </div> */}

              {referralUserDetail.referralLevel &&
                referralUserDetail.referralLevel.length === 0 && (
                  <div>
                    {globalReferralLevel && globalReferralLevel.length > 0 ? (
                      <div>
                        {globalReferralLevel.map((item) => {
                          return (
                            <div
                              key={item._id}
                              style={{
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                padding: '8px',
                              }}
                            >
                              <div>
                                <FormattedMessage id="balance.minSales" /> :
                                {item.minSales}
                              </div>
                              <div>
                                <FormattedMessage id="balance.maxSales" /> :
                                {item.maxSales}
                              </div>
                              <div>
                                <FormattedMessage id="balance.referralCoefficient" />
                                :{item.referralCoefficient}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <FormattedMessage id="balance.referralCoefficient" /> :
                        {globalReferralCoefficient}
                      </div>
                    )}
                  </div>
                )}
              {referralUserDetail.referralLevel &&
                referralUserDetail.referralLevel.length > 0 && (
                  <div>
                    {referralUserDetail.referralLevel.map((item) => {
                      return (
                        <div
                          key={item._id}
                          style={{
                            border: '1px solid #ccc',
                            marginBottom: '8px',
                            padding: '8px',
                          }}
                        >
                          <div>
                            <FormattedMessage id="balance.minSales" /> :
                            {item.minSales}
                          </div>
                          <div>
                            <FormattedMessage id="balance.maxSales" /> :
                            {item.maxSales}
                          </div>
                          <div>
                            <FormattedMessage id="balance.referralCoefficient" />
                            :{item.referralCoefficient}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          )}
        </Modal>
        <Modal
          visible={topUpModalVisible}
          title={null}
          footer={null}
          onCancel={() => {
            this.setState({ topUpModalVisible: false });
          }}
          destroyOnClose
        >
          <div>
            <div style={{ display: 'flex' }} className="balance-modal">
              {topUpCardList.map((variant) => (
                <div
                  key={variant._id}
                  className="balance-top-up-card"
                  role="presentation"
                  onClick={() => {
                    this.handleSelectedTopUpCard(variant);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  ${variant.listPrice}
                </div>
              ))}
            </div>
          </div>
        </Modal>
        <Modal
          visible={redeemModalVisible}
          footer={null}
          onCancel={this.handleCloseRedeemModal}
        >
          <RedeemGiftCardForm
            handleCloseRedeemModal={this.handleCloseRedeemModal}
            cardNumber={cardNumber}
            cardPin={cardPin}
          />
        </Modal>
        <div>{isMobile ? mobile : desktop}</div>
      </div>
    );
  }
}

Balance.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    userId: PropTypes.string,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.language.lang,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUserShoppingMode: (input) => dispatch(actions.setUserShoppingMode(input)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  setWarehouse: (warehouseId) => dispatch(actions.setWarehouse(warehouseId)),
  // getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  // setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  // overWriteCallBack: (callback) =>
  //   dispatch(actions.overWriteCallBack(callback)),
  // updateOneWarehouseAttribute: (input, attributeName) =>
  //   dispatch(actions.updateOneWarehouseAttribute(input, attributeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Balance));
