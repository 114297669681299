/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Button, Col, Row, message } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colorGenerator, getCustomerPath } from '../../../util';
import { variantList } from '../../SmallComponents/SmallComponents';

export const OnSiteMessageModal = (props) => {
  const { language, inputMessage } = props;

  // console.log(inputMessage.customerOrder.variants);
  switch (props.inputMessage.type) {
    case 'customerOrder': {
      const { customerOrder } = inputMessage;
      return (
        <React.Fragment>
          <Row css={{ marginBottom: '19px' }}>
            <div css={{ textAlign: 'center' }}>
              <span>
                <FormattedMessage id="yourOrder" />
              </span>
              <span
                css={{
                  fontWeight: 'bold',
                  color: colorGenerator('Spicy'),
                }}
              >
                #{customerOrder.displayId}
              </span>
            </div>
            <div css={{ textAlign: 'center' }}>
              <span css={{ textAlign: 'center' }}>
                <FormattedMessage id="atAddress" />
              </span>
              <span
                css={{
                  textAlign: 'center',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                {` ${
                  customerOrder.warehouse.partnerName
                    ? customerOrder.warehouse.partnerName
                    : customerOrder.warehouse.name
                }`}
              </span>
            </div>
            <div css={{ textAlign: 'center' }}>
              {`${customerOrder.warehouse.addressOne}, ${customerOrder.warehouse.city}`}
              <CopyToClipboard
                css={{ marginLeft: '10px', fontWeight: '600' }}
                text={`${customerOrder.warehouse.addressOne}, ${customerOrder.warehouse.city}`}
                onCopy={() => {
                  message.success('Copied!');
                }}
              >
                <Button>
                  <FormattedMessage id="copy" />
                </Button>
              </CopyToClipboard>
            </div>
            <div css={{ textAlign: 'center' }}>
              <FormattedMessage id="isis" />
              {` ${customerOrder.warehouseStatus}!`}
            </div>
          </Row>
          <Row>
            <Col css={{ textAlign: 'center' }}>
              <Button
                css={{ marginRight: '5px' }}
                onClick={() => {
                  props.updateMessage(
                    props.inputMessage._id,
                    getCustomerPath('account')
                  );
                }}
                type="primary"
              >
                <FormattedMessage id="checkItOut" />
              </Button>
              <Button
                onClick={() => {
                  props.updateMessage(
                    props.inputMessage._id,
                    `https://www.google.com/maps/search/?api=1&query=${customerOrder.warehouse.location.coordinates[1]},${customerOrder.warehouse.location.coordinates[0]}`
                  );
                }}
                type="primary"
              >
                <FormattedMessage id="direction" />
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
    case 'newOrderNotice':
      return (
        <React.Fragment>
          <Row css={{ marginBottom: '19px' }}>
            <div css={{ textAlign: 'center', fontSize: '20px' }}>
              <FormattedMessage id="newOrder" />
            </div>
          </Row>
          <Row>
            {inputMessage.customerOrder && inputMessage.customerOrder.variants
              ? variantList(inputMessage.customerOrder.variants, language)
              : null}
          </Row>
        </React.Fragment>
      );
    default:
      return <div>error</div>;
  }
};

OnSiteMessageModal.defaultProps = {
  inputMessage: null,
};

OnSiteMessageModal.propTypes = {
  inputMessage: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    customerOrder: PropTypes.shape({
      warehouse: PropTypes.shape({
        fullAddress: PropTypes.string.isRequired,
        partnerName: PropTypes.string,
        name: PropTypes.string.isRequired,
        addressOne: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        location: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        }),
      }).isRequired,
      warehouseStatus: PropTypes.string.isRequired,
      variants: PropTypes.arrayOf(PropTypes.shape({})),
      displayId: PropTypes.number.isRequired,
    }).isRequired,
  }),
  updateMessage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default OnSiteMessageModal;
