import { message } from 'antd';
import * as actionTypes from './actionTypes';
import { apolloClient } from '../../util';
import {
  USER_CREATE_TOKEN,
  USER_REFRESH_TOKEN,
  USER_CREATE_TOKEN_OAUTH2_GOOGLE,
  USER_CREATE_GUEST_TOKEN,
  USER_CREATE_TOKEN_OAUTH2_FACEBOOK,
} from '../../api/user';

export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

const authSuccess = (token, refreshToken, saleorId = null) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    refreshToken,
    saleorId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const logout = () => {
  console.log('logout!');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const getAccessToken = (email, password) => async (dispatch) => {
  try {
    const response = await apolloClient.mutate({
      mutation: USER_CREATE_TOKEN,
      variables: { email, password },
    });
    const { token, refreshToken } = response.data.tokenCreate;
    await dispatch(authSuccess(token, refreshToken));
  } catch (error) {
    message.error(error.graphQLErrors[0].message);
    console.error(error);
    await dispatch(authFail(error));
    throw error;
  }
};

export const getGuestAccessToken = () => async (dispatch) => {
  try {
    const response = await apolloClient.mutate({
      mutation: USER_CREATE_GUEST_TOKEN,
    });
    const { token, refreshToken } = response.data.createGuestToken;
    await dispatch(authSuccess(token, refreshToken));
  } catch (error) {
    message.error(error.graphQLErrors && error.graphQLErrors[0].message);
    console.error(error);
    await dispatch(authFail(error));
    throw error;
  }
};

export const oauth2 =
  (type, email, accessToken, invitationCode = null) =>
  async (dispatch) => {
    try {
      if (type === 'google') {
        const response = await apolloClient.mutate({
          mutation: USER_CREATE_TOKEN_OAUTH2_GOOGLE,
          variables: { email, tokenId: accessToken, invitationCode },
        });
        const { token, refreshToken } = response.data.tokenCreateOauth2Google;
        await dispatch(authSuccess(token, refreshToken));
      } else if (type === 'facebook') {
        const response = await apolloClient.mutate({
          mutation: USER_CREATE_TOKEN_OAUTH2_FACEBOOK,
          variables: { email, token: accessToken, invitationCode },
        });
        const { token, refreshToken } = response.data.tokenCreateOauth2Facebook;
        await dispatch(authSuccess(token, refreshToken));
      }
    } catch (error) {
      message.error(error.graphQLErrors[0].message);
      console.error(error);
      await dispatch(authFail(error));
      throw error;
    }
  };

export const refreshToken = (oldRefreshToken) => async (dispatch) => {
  try {
    const response = await apolloClient.mutate({
      mutation: USER_REFRESH_TOKEN,
      variables: { refreshToken: oldRefreshToken },
    });
    const { token, refreshToken: newRefreshToken } = response.data.tokenRefresh;
    return await dispatch(authSuccess(token, newRefreshToken));
  } catch (error) {
    await dispatch(authFail(error));
    throw error;
  }
};

export const saveLinkForLogin = (link) => async (dispatch) => {
  console.log('link', link);
  await dispatch({
    type: actionTypes.AUTH_SAVE_LINK,
    loginRedirectPage: link,
  });
};

export const auth =
  (email, password) =>
  async (
    dispatch
    // getState
  ) => {
    await dispatch(authStart());
    try {
      await dispatch(getAccessToken(email, password));
    } catch (error) {
      console.error(error);
      console.table(error.response.data);
      await dispatch(authFail(error));
      throw error;
    }
  };

export const guestAuth =
  () =>
  async (
    dispatch
    // getState
  ) => {
    await dispatch(authStart());
    try {
      await dispatch(getGuestAccessToken());
    } catch (error) {
      console.error(error);
      console.table(error.response.data);
      await dispatch(authFail(error));
      throw error;
    }
  };
