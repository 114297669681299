export default {
  en: {
    customerCateringOrderButton: 'Get Custom Meals!',
    customerCateringOrder: 'Wedding Catering',
    customerCatering: {
      titleOne: 'Wedding Catering',
      descriptionOne:
        'We provide customized options according to your budget. Every menu we create is customized to your event and your tastes, inspired by what is special about you, your event or your family recipe memories.',
      titleTwo: 'Party Catering',
      descriptionTwo:
        'Birthday parties, afternoon tea parties, corporate events, and theme parties, etc. Our professional team will make sure your event or party is memorable.',
    },
    startOrderCatering: 'Get Employee Meals!',
    getStartedNow: 'Get Started Now',
    cateringBunBaoTitle: 'BunBao Catering',
    cateringWhatWeFaceAndResolveLineOne: 'If you faced these problems when',
    cateringWhatWeFaceAndResolveLineTwo: 'choosing a employee meal:',
    cateringHowItWorks:
      'Bunbao Could help you to solve all the problems above!',
    cateringStepOneTitle: 'Best Chinese Food in Bay Area',
    cateringStepOneDescription:
      'The authentic dishes are carefully prepared by the chef, will allow you to enjoy endless satisfaction after busy work!',
    cateringStepOneBottom: {
      signUp: 'Sign Up',
      order: 'Order',
      pay: 'Pay',
      eat: 'Eat',
    },
    customerCateringLogo: {
      paragraphOne: 'These companies are enjoying Bunbao Catering',
      paragraphTwo:
        'Whether you are enjoying after work or a banquet on your wedding day',
      paragraphThree: 'Bunbao could always meet your needs',
      buttonOne: 'Employee Meal',
      buttonTwo: 'Custom Meal',
    },
    cateringStepTwoTitle: 'Clear reimbursement control',
    cateringStepTwoDescription:
      'BunBao can intelligently help you calculate the price of the dishes ordered by each employee, so you don’t need to worry about it anymore!',
    cateringStepTwoBottom: 'AVAILABLE: MON - FRI, 9:00AM - 6:30PM.',
    cateringStepThreeTitle: 'Always keep your food safe',
    cateringStepThreeDescription:
      'The professional food team of Baobao strictly controls the quality and safety of the food for you to ensure that you can enjoy the delicious food with confidence!',
    exploreMenu: 'Catering Menu',
    groupBuyingButton: 'Group Buy',
    cateringButtonExplain:
      'Order for your company, party or meeting. Food can be delivered at your chosen time window.',
    groupBuyingButtonExplain:
      'Invite friends and coworkers to join your order. Check out individually and delivered together.',
    addressSubmit: 'Submit',
    addressCancel: 'Cancel',
    deliveryDetailSubmit: 'Submit',
    deliveryDetailCancel: 'Cancel',
    deliveryDetailNoteClear: 'Cancel',
    deliveryDetailNoteSubmit: 'Submit',
    cateringDeliveryTime: {
      placeholder: 'Select delivery time',
      title: 'Delivery Time',
      scheduleDate: 'Schedule Date',
      scheduleTime: 'Schedule Time',
    },
    cateringAddress: {
      placeholder: 'Enter your address',
      title: 'Address',
    },
    caterDeliveryDetail: {
      placeholder: 'Options',
      numberOfPeople: 'Number of People',
      deliveryNote: 'Delivery Notes',
      deliveryNotePlaceholder: 'Notes for driver',
      note: 'Notes',
      notePlaceholder: 'Food notes',
      title: 'Delivery Details',
    },
    confirmOrderButton: 'Confirm Order',
    minimumForDelivery: 'minimum for delivery',
    cateringContactUs: {
      title: 'Contact Us',
      subTitle: 'Leave us message',
      email: 'Email',
      msg: 'Content',
      submit: 'Submit',
    },
    cateringProductModal: {
      addCart: 'Add Cart',
      cancel: 'Cancel',
      ingredients: 'Ingredients',
      selectSize: 'Select Size',
      selectQuantity: 'Select Quantity',
      allergen: ' Allergen Information',
      minimumQuantity: 'Minimum Quantity',
    },
    cateringPayment: {
      subtotal: 'Subtotal',
      tax: 'Tax',
      deliveryFee: 'Delivery Fee',
      tip: 'Tip',
      total: 'Total',
      otherAmount: 'Other Amount',
      pricePerHead: 'Price Per Head',
      orderConfirmed: 'Order Confirmed!',
      orderInfo: 'Order Info',
    },
    customerCateringWedding: {
      titleOne: 'Wedding Catering',
      descriptionOne:
        'We provide customized options according to your budget. Every menu we create is customized to your event and your tastes, inspired by what is special about you, your event or your family recipe memories.',
      titleTwo: 'Party Catering',
      descriptionTwo:
        'Birthday parties, afternoon tea parties, corporate events, and theme parties, etc. Our professional team will make sure your event or party is memorable.',
    },
    customerCateringHome: {
      getStarted: 'Get Started',
      titleOne: 'How Event Catering Works?',
      paragraphOne:
        'Whether you’re throwing a happy hour for 20, a holiday party for 200, or a marketing event for 2,000+, we have your catering needs covered. We provide the most innovative menus from our central kitchen to make catered events effortless. When you work with BunBao, you get a dedicated food curation associate who builds a menu that fits your vision, tastes, and budget.',
      titleTwo: '1. Tell us what you need',
      paragraphTwo:
        "Tell us your event details, headcount, tastes, and budget and we'll handle the rest.",
      titleThree: '2. Food Assistant contact you',
      paragraphThree:
        'Our food assistant customized menus tailored to your event. Our team ensures that everything goes flawlessly, from menu creation to cleanup.',
      titleFour: '3. Everyone Enjoys!',
      paragraphFour:
        'On the day of delivery, we will remind you of the delivery process by message. Then, rest easy and enjoy your event!',
      companyName: 'Company Name',
      name: 'Name',
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      zip: 'Zip',
      email: 'Email',
      phone: 'Phone',
      date: 'Date',
      time: 'Time',
      headCount: 'headcount',
      notes: 'Notes',
      submit: 'Submit',
    },
  },
  zh: {
    customerCateringOrderButton: '开始订制您的专属菜单！',
    customerCateringOrder: '婚宴定制',
    startOrderCatering: '开始订购公司餐！',
    getStartedNow: '开始订购',
    cateringBunBaoTitle: '棒包 公司餐',
    cateringHowItWorks: ' 棒!包企业餐可以为您解决上述一切！',
    cateringWhatWeFaceAndResolveLineOne: '如果您在选择企业餐的时候',
    cateringWhatWeFaceAndResolveLineTwo: '也面对了这些难题：',
    cateringStepOneTitle: '湾区最好的中式团餐',
    cateringStepOneDescription:
      '由一流的棒！包厨师为您精心烹制的地道料理，让您在繁忙的工作之余享受无穷的舌尖满足！',
    cateringStepOneBottom: {
      signUp: '注册',
      order: '点餐',
      pay: '支付',
      eat: '吃',
    },
    customerCateringWedding: {
      titleOne: '婚宴定制',
      descriptionOne:
        '根据您的预算提供定制选择。棒！包定制餐还可依照您的家常菜谱为您烹制舒心美食，增添个人特色。',
      titleTwo: 'Party定制',
      descriptionTwo:
        '生日会，下午茶，主题活动等棒！包定制餐的专业运营团队提供从主题定制到现场管理的全流程服务',
    },
    customerCateringLogo: {
      paragraphOne: '这些企业正在通过棒!包企业餐享受新福利',
      paragraphTwo:
        '无论您是工作之余的享受，还是新婚之日的宴席棒!包都能满足您的需求',
      paragraphThree: '棒!包都能满足您的需求',

      buttonOne: '选择公司餐',
      buttonTwo: '定制菜单',
    },
    cateringStepTwoTitle: '清晰的报销管控',
    cateringStepTwoDescription:
      '棒！包企业餐可以智能地帮助您计算好每位员工所点菜品的价格，无需您再烦心！',
    cateringStepTwoBottom: '送餐时间：周一到周五，9:00AM - 6:30PM',
    cateringStepThreeTitle: '保障您的食品安全',
    cateringStepThreeDescription:
      '由棒！包专业的食品团队为您严格把控餐品的质量和安全性，保证您能够放心享用美味！',
    exploreMenu: '公司订购',
    groupBuyingButton: '个人团购',
    cateringButtonExplain: '为您的公司、派对或其它活动订餐，统一支付。',
    groupBuyingButtonExplain: '邀请朋友同事参与团购，各自结账，共同配送。',
    addressSubmit: '提交地址',
    addressCancel: '取消填写',
    deliveryDetailSubmit: '确认时间',
    deliveryDetailCancel: '取消填写',
    deliveryDetailNoteClear: '取消填写',
    deliveryDetailNoteSubmit: '确定',
    cateringDeliveryTime: {
      placeholder: '请输入您期望的到达时间',
      title: '配送时间',
      scheduleDate: '配送日期',
      scheduleTime: '配送时间',
    },
    cateringAddress: {
      placeholder: '请输入您的地址',
      title: '地址',
    },
    caterDeliveryDetail: {
      placeholder: '(下列选项为选填)',
      numberOfPeople: '人数',
      deliveryNote: '送餐指引',
      deliveryNotePlaceholder: '（告诉司机如何快速为您送达）',
      note: '备注',
      notePlaceholder: '（您对食物有何特殊需求）',
      title: '配送要求',
    },
    confirmOrderButton: '确认订单',
    minimumForDelivery: '最小订购金额',
    cateringContactUs: {
      title: '联系我们',
      subTitle: '给我们留言',
      email: '你的邮箱地址',
      msg: '留言内容',
      submit: '提交地址',
    },
    cateringProductModal: {
      addCart: '添加至购物车',
      cancel: '取消选择',
      ingredients: '配料',
      selectSize: '选择规格',
      allergen: '过敏信息',
      selectQuantity: '选择数量',
      minimumQuantity: '默认最小下单量',
    },
    cateringPayment: {
      subtotal: '菜品总价',
      tax: '税费',
      deliveryFee: '运送费',
      tip: '小费',
      total: '总价',
      otherAmount: '其他金额',
      pricePerHead: '每人费用',
      orderConfirmed: '确认订单',
      orderInfo: '订单详情',
    },
    customerCateringHome: {
      getStarted: '开始预订',
      titleOne: '专属定制如何运作？',
      paragraphOne:
        '无论是20人的欢乐时光，200人的节日派对，还是2000多人的营销活动，我们都能满足您的餐饮需求。我们提供最创新的菜单从我们的中央厨房，使餐饮活动毫不费力。当你和BunBao一起工作时，你会得到一个专门的食物管理助理，他会根据你的愿景、口味和预算制作菜单。',
      titleTwo: '1，简单告诉我们您的需求',
      paragraphTwo:
        '告诉我们你的活动场景，人数，口味和预算，剩下的我们来处理。',
      titleThree: '2，专人服务',
      paragraphThree:
        '我们的美食专人助理为您的活动定制菜单。再由我们强大的生产团队确保一切都做到完美无缺，从菜单创建到清理。',
      titleFour: '3，享受用餐',
      paragraphFour:
        '送餐当天我们会短息提醒送餐过程，送餐到达后，请轻松地享受你的活动吧!',
      companyName: '公司名字',
      name: '您的姓名',
      address1: '地址 1',
      address2: '地址 2',
      city: '城市',
      zip: '邮编',
      email: '邮箱',
      phone: '电话',
      date: '日期',
      time: '时间',
      headCount: '人数',
      notes: '内容',
      submit: '提交',
    },
  },
};
