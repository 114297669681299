import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment USER_FRAGMENT on User {
    _id
    email
    guestUserEmail
    isVip
    firstName
    lastName
    phoneNumber
    code
    activated
    group {
      name
      groupPermissions {
        _id
        name
        view
      }
      pathPermissions {
        _id
        name
        view
      }
      id
      _id
    }
    groupId
    addresses {
      firstName
      lastName
      phoneNumber
      companyName
      addressOne
      addressTwo
      city
      state
      zipCode
      gateCode
      deleted
      _id
      id
      fullAddress
      type
    }
    language
    customerId
    invitationCode
    isEnableInvitationCodeForFarmersMarket
    invitationCodeForFarmersMarket
    lastLoginAt
    lastIP
    lastIPLookup {
      city
      zip
      country
      state
    }
    createdAt
    customerOrderCount
    referralCode
    referralLevel {
      _id
      minSales
      maxSales
      referralCoefficient
    }
    foodTruckLocationIds
    foodTruckNames
    isFarmerMarketNewUser
    isFarmerMarketUser
    isWebsiteUser
  }
`;

export const CUSTOMER_USER_CREATE_TOKEN = gql`
  mutation ($email: String, $password: String) {
    customerTokenCreate(email: $email, password: $password) {
      token
      refreshToken
      saleorId
    }
  }
`;

export const USER_CREATE_GUEST_TOKEN = gql`
  mutation {
    createGuestToken {
      token
      refreshToken
    }
  }
`;

export const USER_CREATE_TOKEN = gql`
  mutation Login($email: String, $password: String) {
    tokenCreate(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`;

export const USER_CREATE_TOKEN_OAUTH2_GOOGLE = gql`
  mutation ($email: String, $tokenId: String, $invitationCode: String) {
    tokenCreateOauth2Google(
      email: $email
      tokenId: $tokenId
      invitationCode: $invitationCode
    ) {
      token
      refreshToken
    }
  }
`;

export const USER_CREATE_TOKEN_OAUTH2_FACEBOOK = gql`
  mutation ($email: String, $token: String, $invitationCode: String) {
    tokenCreateOauth2Facebook(
      email: $email
      token: $token
      invitationCode: $invitationCode
    ) {
      token
      refreshToken
    }
  }
`;

export const USER_FM_REDEEM = gql`
  mutation {
    userRedeemFM {
      _id
    }
  }
`;

export const USER_ADD_INVITATION_CODE = gql`
  mutation ($email: String!, $invitationCode: String!) {
    userAddInvitationCode(email: $email, invitationCode: $invitationCode) {
      email
      isFarmerMarketNewUser
      personalDiscountIds
    }
  }
`;

export const USER_REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    tokenRefresh(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const USER_CURRENT = gql`
  query {
    userCurrent {
      _id
      email
      isVip
      isNewUser
      firstName
      lastName
      phoneNumber
      code
      activated
      group {
        name
        groupPermissions {
          _id
          name
          view
        }
        pathPermissions {
          _id
          name
          view
        }
        _id
      }
      language
      customerId
      invitationCode
      createdAt
      referralCode
      customerOrderCount
      isFarmerMarketNewUser
      isFarmerMarketUser
    }
  }
`;

export const USER_CURRENT_COUPON = gql`
  query {
    userCurrent {
      _id
      invitees {
        _id
        isNewUser
      }
      personalDiscount {
        code
        _id
        createdAt
      }
    }
  }
`;

export const USER_SEARCH = gql`
  query ($search: String!) {
    userMany(filter: { search: $search }) {
      _id
      email
      lastName
      firstName
    }
  }
`;

export const USER_CREATE = gql`
  mutation (
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
    $groupType: String!
    $invitationCode: String
    $fromWebsite: Boolean
  ) {
    userCreateOne(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      groupType: $groupType
      invitationCode: $invitationCode
      fromWebsite: $fromWebsite
    ) {
      _id
      email
      personalDiscountIds
    }
  }
`;

export const GUEST_SIGN_UP = gql`
  mutation (
    $userId: MongoID!
    $password: String!
    $invitationCode: String
    $userEmail: String
  ) {
    guestConvertToCustomer(
      password: $password
      invitationCode: $invitationCode
      userId: $userId
      userEmail: $userEmail
    ) {
      _id
      email
      formattedEmail
    }
  }
`;

export const USER_UPDATE_CURRENT_INFORMATION = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $language: String!
  ) {
    userUpdateCurrentInformation(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      language: $language
    ) {
      _id
      firstName
      lastName
      phoneNumber
      language
    }
  }
`;

export const USER_UPDATE_CURRENT_CUSTOMER_INFORMATION = gql`
  mutation ($firstName: String!, $lastName: String!) {
    userUpdateCurrentCustomerInformation(
      firstName: $firstName
      lastName: $lastName
    ) {
      _id
      firstName
      lastName
    }
  }
`;

export const USER_UPDATE_CURRENT_PASSWORD = gql`
  mutation ($oldPassword: String!, $newPassword: String!) {
    userUpdateCurrentPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      _id
      firstName
      lastName
    }
  }
`;

export const USER_CREATE_FROM_DASHBOARD = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $groupId: String!
  ) {
    userCreateFromDashboard(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      groupId: $groupId
    ) {
      _id
      email
    }
  }
`;

export const USER_UPDATE_ADMIN = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $groupId: String!
    $userId: String!
    $isEnableInvitationCodeForFarmersMarket: Boolean
    $invitationCodeForFarmersMarket: String
    $referralLevel: [ReferralLevelInput]
    $foodTruckNames: [String]
  ) {
    userUpdateAdmin(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      groupId: $groupId
      userId: $userId
      isEnableInvitationCodeForFarmersMarket: $isEnableInvitationCodeForFarmersMarket
      invitationCodeForFarmersMarket: $invitationCodeForFarmersMarket
      referralLevel: $referralLevel
      foodTruckNames: $foodTruckNames
    ) {
      _id
      email
    }
  }
`;

export const USER_ACTIVATE = gql`
  mutation ($email: String!, $password: String!, $code: String!) {
    userActivate(email: $email, password: $password, code: $code) {
      _id
      email
      firstName
      lastName
      activated
    }
  }
`;

export const USER_PAGINATION_QUERY = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyUserInput
    $sort: SortFindManyUserInput
  ) {
    userPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        ...USER_FRAGMENT
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_MANY = gql`
  query ($filter: FilterFindManyUserInput, $limit: Int, $skip: Int) {
    userMany(filter: $filter, limit: $limit, skip: $skip) {
      ...USER_FRAGMENT
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_FIND_BY_ID = gql`
  query ($id: MongoID!) {
    userById(_id: $id) {
      _id
      email
      firstName
      lastName
      phoneNumber
      code
      group {
        _id
      }
      groupId
      activated
      isBunbao
      isEnableInvitationCodeForFarmersMarket
      invitationCodeForFarmersMarket
      referralCode
      guestUserEmail
      foodTruckLocationIds
      foodTruckNames
    }
  }
`;

export const USER_FIND_ONE = gql`
  query ($filter: FilterFindOneUserInput) {
    userOne(filter: $filter) {
      _id
      email
      firstName
      lastName
      phoneNumber
      code
      group {
        _id
      }
      groupId
      activated
      isBunbao
    }
  }
`;

export const USER_FIND_BY_FILTER = gql`
  query ($filter: FilterFindOneUserInput) {
    userOne(filter: $filter) {
      ...USER_FRAGMENT
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_CURRENT_USER_UPDATE_PASS_CODE = gql`
  mutation {
    userCurrentUpdatePassCode {
      _id
      code
    }
  }
`;

export const USER_LANGUAGE = gql`
  query {
    userCurrent {
      _id
      language
    }
  }
`;

export const USER_CURRENT_UPDATE = gql`
  mutation ($input: RelayUpdateByIdUserInput!) {
    userCurrentUpdate(input: $input) {
      recordId
    }
  }
`;

export const USER_RESET_PASS_CODE_AND_SEND_EMAIL = gql`
  mutation ($email: String!) {
    userResetPassCode(email: $email) {
      _id
    }
  }
`;

export const USER_RESET_PASSWORD_BY_PASS_CODE_AND_USER_ID = gql`
  mutation ($code: String!, $userId: String!, $newPassword: String!) {
    userUpdatePasswordByPassCode(
      code: $code
      userId: $userId
      newPassword: $newPassword
    ) {
      email
    }
  }
`;

export const USER_VERIFY_PASS_CODE_AND_USER_ID = gql`
  query ($code: String!, $userId: String!) {
    userVerifyPassCodeAndUserId(code: $code, userId: $userId) {
      email
    }
  }
`;

export const USER_GET_PASS_CODE_BY_ADMIN = gql`
  query ($userId: MongoID!) {
    userGetPassCodeByAdmin(userId: $userId) {
      id
      code
    }
  }
`;
