/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  CreditCardOutlined,
  SmileOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Steps, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { Steps as MobileSteps } from 'antd-mobile';
import { isMobile } from 'react-device-detect';
import { withRouter, Link } from 'react-router-dom';
import { getCustomerPath } from '../../../../util';

const { Step } = Steps;

// just change some for merge

function StepBar(props) {
  const { intl, currentStep } = props;
  return (
    <div css={{ width: '100%', margin: '10px auto' }}>
      {isMobile ? (
        <MobileSteps direction="horizontal" size="small" current={currentStep}>
          {currentStep >= 0 && currentStep !== 2 ? (
            <MobileSteps.Step
              title={
                <Link
                  to={{
                    pathname: getCustomerPath('checkout/shipping'),
                    state: { edit: true },
                  }}
                  replace
                >
                  <Button css={{ fontWeight: 'bold' }} size="small">
                    {intl.formatMessage({ id: 'information' })}
                  </Button>
                </Link>
              }
              icon={<SolutionOutlined />}
            />
          ) : (
            <MobileSteps.Step
              title={intl.formatMessage({ id: 'information' })}
              icon={<SolutionOutlined />}
            />
          )}
          {currentStep >= 1 && currentStep !== 2 ? (
            <MobileSteps.Step
              title={
                <Link
                  to={{
                    pathname: getCustomerPath('checkout/payment'),
                    state: { edit: true },
                  }}
                  replace
                >
                  <Button css={{ fontWeight: 'bold' }} size="small">
                    {intl.formatMessage({ id: 'pay' })}
                  </Button>
                </Link>
              }
              icon={<CreditCardOutlined />}
            />
          ) : (
            <MobileSteps.Step
              title={intl.formatMessage({ id: 'pay' })}
              icon={<CreditCardOutlined />}
            />
          )}
          <MobileSteps.Step
            title={intl.formatMessage({ id: 'done' })}
            icon={<SmileOutlined />}
          />
        </MobileSteps>
      ) : (
        <Steps current={currentStep}>
          {currentStep >= 0 && currentStep !== 2 ? (
            <Step
              title={
                <Link
                  to={{
                    pathname: getCustomerPath('checkout/shipping'),
                    state: { edit: true },
                  }}
                  css={{ textDecoration: 'underline' }}
                >
                  <Button css={{ fontWeight: 'bold' }}>
                    {intl.formatMessage({ id: 'information' })}
                  </Button>
                </Link>
              }
              icon={<SolutionOutlined />}
            />
          ) : (
            <Step
              title={intl.formatMessage({ id: 'information' })}
              icon={<SolutionOutlined />}
            />
          )}
          {currentStep >= 1 && currentStep !== 2 ? (
            <Step
              title={
                <Link
                  to={{
                    pathname: getCustomerPath('checkout/payment'),
                    state: { edit: true },
                  }}
                  css={{ textDecoration: 'underline' }}
                >
                  <Button css={{ fontWeight: 'bold' }}>
                    {intl.formatMessage({ id: 'pay' })}
                  </Button>
                </Link>
              }
              icon={<CreditCardOutlined />}
            />
          ) : (
            <Step
              title={intl.formatMessage({ id: 'pay' })}
              icon={<CreditCardOutlined />}
            />
          )}
          <Step
            title={intl.formatMessage({ id: 'done' })}
            icon={<SmileOutlined />}
          />
        </Steps>
      )}
    </div>
  );
}

StepBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(injectIntl(StepBar));
