/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getCustomerPath } from '../../../util';
import { BUCKET_CDN_URL } from '../../../config';

function NewUserAdsModal(props) {
  const { newUserPosts, oldUserPosts } = props;
  const language = useSelector((state) => state.language.lang);
  const customerOrderCount = useSelector(
    (state) => state.user.customerOrderCount
  );
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [postUrl, setPostUrl] = useState(null);

  const handleClickButton = () => {
    const postObj = newUserPosts || oldUserPosts;
    if (postObj?.isLink) {
      sessionStorage.setItem('neverShowNewUserAdsModal', true);
      setShowModal(false);
      history.push(getCustomerPath(postObj?.link));
    }
  };

  const handleCloseModal = () => {
    sessionStorage.setItem('neverShowNewUserAdsModal', true);
    setShowModal(false);
  };

  useEffect(() => {
    const neverShowNewUserAdsModal = sessionStorage.getItem(
      'neverShowNewUserAdsModal'
    );
    const processPostArray = (array) => {
      return array
        .filter((item) =>
          item.language === language && isMobile
            ? item.device === 'mobile'
            : item.device === 'desktop'
        )
        .map((item) => {
          return `${BUCKET_CDN_URL}${item?.picture?.key}`;
        })
        .pop();
    };

    if (
      customerOrderCount > 0 &&
      !neverShowNewUserAdsModal &&
      oldUserPosts?.banners?.length > 0
    ) {
      setPostUrl(processPostArray(oldUserPosts.banners) || null);
      setShowModal(true);
    }

    if (
      customerOrderCount === 0 &&
      !neverShowNewUserAdsModal &&
      newUserPosts?.banners?.length > 0
    ) {
      setPostUrl(processPostArray(newUserPosts.banners) || null);
      setShowModal(true);
    }
  }, [customerOrderCount, newUserPosts, oldUserPosts]);

  // console.log(location);
  const checkPath = () => {
    return (
      location.pathname !== getCustomerPath('vote-campaign') &&
      location.pathname !== getCustomerPath('vote-post')
    );
  };
  if (!newUserPosts && !oldUserPosts) {
    return null;
  }

  return (
    <Modal
      visible={showModal && checkPath()}
      footer={null}
      header={null}
      onCancel={() => {
        handleCloseModal();
      }}
      maskClosable
    >
      <div style={{ paddingTop: '10px' }}>
        <img
          role="presentation"
          onClick={() => {
            handleClickButton();
          }}
          css={{ width: '100%' }}
          src={postUrl}
          alt="new user ads"
        />
        <div
          css={
            isMobile
              ? {
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: language === 'zh' ? '10px' : '8px',
                  left: '50%',
                }
              : {
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: language === 'zh' ? '22px' : '10px',
                  left: '50%',
                }
          }
        >
          {/* <Button
            type="primary"
            size={isMobile ? 'default' : 'large'}
            css={{ transform: 'translate(-50%, -50%)' }}
            onClick={() => {
              handleClickButton();
            }}
          >
            {language === 'zh' ? '去逛逛' : 'Shop Now'}
          </Button> */}
        </div>
      </div>
    </Modal>
  );
}

NewUserAdsModal.defaultProps = {
  newUserPosts: null,
  oldUserPosts: null,
};

NewUserAdsModal.propTypes = {
  newUserPosts: PropTypes.shape({
    banners: PropTypes.arrayOf(PropTypes.shape({})),
    isLink: PropTypes.bool,
    link: PropTypes.string,
  }),
  oldUserPosts: PropTypes.shape({
    banners: PropTypes.arrayOf(PropTypes.shape({})),
    isLink: PropTypes.bool,
    link: PropTypes.string,
  }),
};

export default NewUserAdsModal;
