import { connect } from 'react-redux';
import React, { Component } from 'react';
import { CarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Col, Divider, Row, Button, Popover, Modal } from 'antd';
// import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import { nanoid } from 'nanoid';
import * as R from 'ramda';
import { WechatAPI } from 'react-wechat-api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { ORDER_BY_ID } from '../../../../apiCanary/order';
import { FETCH_CAMPAIGN_INDIVIDUAL } from '../../../../api/campaign';
import {
  CUSTOMER_ORDER_CURRENT_USER_BY_ID,
  CUSTOMER_ORDER_SHARE_START_TIME,
} from '../../../../api/customerOrder';
import {
  // saleorClient,
  // processOrderDetail,
  apolloClient,
  getCustomerPath,
  checkWeChatUserAgent,
} from '../../../../util';
import {
  addressFormatterOnlyAddress,
  addressFormatterOnlyName,
} from '../../../../helper/Formatter/Formatter';
import { BUCKET_CDN_URL } from '../../../../config';
import copy from '../../../../assets/images/icons/copy.svg';
import tipPicture from '../../../../assets/images/shareTip.png';
import buttonPicture from '../../../../assets/images/shareButton.png';
// const { Step } = Steps;
const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export class DeliveryOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      order: null,
      pickupWindow: null,
      showModal: false,
      showTipModal: false,
      wechatShareData: {
        title: '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
        link: () => window.location.href,
      },
    };
  }

  componentDidMount = async () => {
    const { order } = this.props;
    if (order) {
      if (order.discountAmount || order.discountAmount === 0) {
        order.discount = order.discountAmount;
      }
      this.setState({ order });
      await this.checkOrderInCampaign(order._id);
    } else {
      await this.fetchCustomerOrders();
      await this.checkOrderInCampaign();
    }
    console.log('****', order);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchCustomerOrders = async () => {
    // fetch the last order
    // ===saleor
    const { match } = this.props;

    // const { data } = await saleorClient.query({
    //   query: ORDER_BY_ID,
    //   variables: { id: match.params.orderId },
    //   fetchPolicy: 'network-only',
    // });
    // // console.log(processOrderDetail(data.order));
    // // return
    // if (data && data.order) {
    //   this.setState({
    //     order: processOrderDetail(data.order),
    //   });
    // }
    try {
      const {
        data: { customerOrderById },
      } = await apolloClient.query({
        query: CUSTOMER_ORDER_CURRENT_USER_BY_ID,
        variables: { id: match.params.orderId },
        fetchPolicy: 'network-only',
      });

      if (customerOrderById) {
        if (
          customerOrderById.discountAmount ||
          customerOrderById.discountAmount === 0
        ) {
          customerOrderById.discount = customerOrderById.discountAmount;
        }
        this.setState({
          order: customerOrderById,
        });
      }
    } catch (error) {
      console.error(error);
      // try {
      //   const { data } = await saleorClient.query({
      //     query: ORDER_BY_ID,
      //     variables: { id: match.params.orderId },
      //     fetchPolicy: 'network-only',
      //   });
      //   // console.log(processOrderDetail(data.order));
      //   // return
      //   if (data && data.order) {
      //     this.setState({
      //       order: processOrderDetail(data.order),
      //     });
      //   }
      // } catch (error2) {
      //   console.error(error);
      //   console.log(error2);
      //   message.error('error happened during fetch order', 15);
      // }
    }
  };

  checkOrderInCampaign = async (Id = null) => {
    let filter;
    try {
      if (!Id) {
        const { match } = this.props;
        filter = { oneOrderId: match.params.orderId };
      } else {
        filter = { oneOrderId: Id };
      }
      const { data } = await apolloClient.query({
        query: FETCH_CAMPAIGN_INDIVIDUAL,
        variables: { filter },
        fetchPolicy: 'network-only',
      });
      console.log('campaign data', data);
      this.setState({
        pickupWindow:
          data.campaignIndividualFindMany[0].campaignContent
            .pickupTimeSlotFormatted,
      });
    } catch (err) {
      console.log('');
    }
  };

  getStep = (step) => {
    switch (step) {
      case 'placed':
        return 0;
      case 'out for delivery':
        return 1;
      case 'delivered':
        return 2;
      default:
        return 0;
    }
  };

  findDay = (day, window) => {
    console.log('day', day);
    let res = null;
    window.forEach((element) => {
      if (element.substring(0, 3) === weekdays[day]) {
        res = element;
      }
    });
    return res;
  };

  handleOpenGoogleMap = (lat, lng) => {
    const link = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(link, '_blank'); // to open new page
  };

  refundVariantQuantity = (id) => {
    const { order } = this.props;
    if (order && order.refundVariants && order.refundVariants.length > 0) {
      const index = order.refundVariants.findIndex(
        (item) => item.variantId === id
      );
      if (index > -1) {
        return order.refundVariants[index].quantity;
      }
    }
    return 0;
  };

  isCheckPriceSame = (variant) => {
    if (variant.commissionFee === 0 && variant.warehouseEarning === 0) {
      return true;
    }
    if (variant.price > -1) {
      const sumPrice = (
        variant.commissionFee + variant.warehouseEarning
      ).toFixed(2);
      return (
        sumPrice === variant.price.toFixed(2) ||
        variant.price.toFixed(2) < sumPrice
      );
    }
    return true;
  };

  render() {
    const { order, pickupWindow, showModal, wechatShareData, showTipModal } =
      this.state;
    const { intl, language, referralCode } = this.props;
    const getCoordinates = R.pathOr(null, [
      'campaignContent',
      'location',
      'coordinates',
    ]);
    const coordinates = getCoordinates(order);
    const renderBoxDeliveryTime = () => {
      if (order.bunbaoType === 'normalDelivery' || order.bunbaoType === null) {
        return `${moment(order.deliveryTime).format('YYYY-MM-DD')} 3-7pm`;
      }
      if (order.bunbaoType === 'groupBuy') {
        return moment(order.deliveryTime).format('YYYY-MM-DD HH:mm');
      }
      return moment(order.deliveryTime).format('YYYY-MM-DD');
    };

    const renderPickupDeliveryTime = () => {
      let formedMessage = 'shippingDate';
      if (order.bunbaoType === 'donation' || order.bunbaoType === 'eShop') {
        return null;
      }
      if (order.bunbaoType) {
        if (order.bunbaoType === 'fixLocation') {
          formedMessage = 'pickupTimeWindow';
        }
      }
      return (
        <Col>
          {`${intl.formatMessage({ id: formedMessage })}: `}
          <span
            style={{ fontSize: '16px', fontWeight: 'bold' }}
          >{`${renderBoxDeliveryTime()} `}</span>
          {pickupWindow
            ? this.findDay(moment(order.deliveryTime).day(), pickupWindow)
            : null}
        </Col>
      );
    };

    return (
      <div>
        {order ? (
          <div
            style={{
              padding: '20px 25px 30px 25px',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
          >
            {showTipModal && (
              <div
                css={{
                  position: 'fixed',
                  left: '0',
                  top: '0',
                  bottom: '0',
                  right: '0',
                  zIndex: '18888',
                  backgroundColor: '#000000',
                  opacity: '0.7',
                }}
              >
                <div css={{ position: 'absolute', right: '0', top: '0' }}>
                  <img
                    src={tipPicture}
                    alt="Tip"
                    css={{ width: '70%', float: 'right' }}
                  />
                  <img
                    src={buttonPicture}
                    alt="Button"
                    css={{ width: '70%', float: 'right' }}
                    onClick={() => {
                      this.setState({ showTipModal: false });
                    }}
                    role="presentation"
                  />
                </div>
              </div>
            )}
            <WechatAPI shareData={wechatShareData}>
              <div />
            </WechatAPI>
            <Modal
              visible={showModal}
              onCancel={() => {
                this.setState({ showModal: false });
              }}
              footer={null}
            >
              <CopyToClipboard
                text={
                  language === 'zh'
                    ? `送您一张【BunBao 优惠券】点击链接享受全单5%折扣！${window.location.host}/share-order?referralCode=${referralCode}&orderId=${order._id}`
                    : `Friends buy friends save. Click link to enjoy 5% discount. ${window.location.host}/share-order?referralCode=${referralCode}&orderId=${order._id}`
                }
                onCopy={() => {
                  Modal.success({
                    content: intl.formatMessage({ id: 'copied' }),
                  });
                }}
              >
                <div style={{ cursor: 'pointer' }}>
                  <div style={{ padding: '10px' }}>
                    <img style={{ width: '32px' }} src={copy} alt="Copy Icon" />
                  </div>
                  <div style={{ padding: '10px' }}>
                    {language === 'zh'
                      ? '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！'
                      : 'Friends buy friends save. Click link to enjoy 5% discount.'}
                    <br />
                    {window.location.host}/share-order?referralCode=
                    {referralCode}
                    &orderId=
                    {order._id}
                  </div>
                </div>
              </CopyToClipboard>
            </Modal>
            <Row
              style={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              <FormattedMessage id="orderType" />:
              {intl.formatMessage({ id: order.bunbaoType || 'normalDelivery' })}{' '}
              <span style={{ marginLeft: '10px' }}>
                {moment().diff(moment(order.createdAt), 'h') < 24 && (
                  <Button
                    size="small"
                    type="primary"
                    onClick={async () => {
                      if (referralCode) {
                        // get url with referral code
                        try {
                          await apolloClient.mutate({
                            mutation: CUSTOMER_ORDER_SHARE_START_TIME,
                            variables: {
                              orderId: order._id,
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                        let content = '';
                        const originalUrl = `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${order._id}`;
                        if (language === 'zh') {
                          content = `送您一张【BunBao 优惠券】点击链接享受全单5%折扣！${originalUrl}`;
                        } else {
                          content = `Friends buy friends save. Click link to enjoy 5% discount. ${originalUrl}`;
                        }
                        await this.setState({
                          wechatShareData: {
                            title:
                              '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
                            link: `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${order._id}`,
                          },
                        });
                        if (!navigator.clipboard) {
                          const textField = document.createElement('textarea');
                          textField.innerText = content;
                          document.body.appendChild(textField);
                          textField.select();
                          document.execCommand('copy');
                          textField.remove();
                        } else {
                          console.log(navigator.clipboard);
                          try {
                            await navigator.clipboard.writeText(content);
                          } catch (error) {
                            const textField =
                              document.createElement('textarea');
                            textField.innerText = content;
                            document.body.appendChild(textField);
                            textField.select();
                            document.execCommand('copy');
                            textField.remove();
                            console.error(error);
                          }
                        }
                        if (navigator.share) {
                          if (language === 'zh') {
                            navigator
                              .share({
                                title: content,
                                text: content,
                                url: originalUrl,
                              })
                              .then(() => console.log('Successful share'))
                              .catch((error) =>
                                console.log('Error sharing', error)
                              );
                          } else {
                            navigator
                              .share({
                                title: content,
                                text: content,
                                url: originalUrl,
                              })
                              .then(() => console.log('Successful share'))
                              .catch((error) =>
                                console.log('Error sharing', error)
                              );
                          }
                        }
                        if (checkWeChatUserAgent()) {
                          this.setState({ showTipModal: true });
                        }
                        this.setState({ showModal: true });
                      }
                    }}
                  >
                    {language === 'zh' ? '现在分享' : 'Share Order'}
                  </Button>
                )}
              </span>
            </Row>
            <div style={{ fontSize: '14px' }}>
              <div>
                <Divider
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  orientation="left"
                >
                  <FormattedMessage id="orderDetails" />
                </Divider>
              </div>
              <div>{`ID : ${order.displayId}`}</div>
              <div>
                {`${intl.formatMessage({ id: 'paymentStatus' })}
                : ${intl.formatMessage({ id: order.paymentStatus })}`}
              </div>
              {order.customerNotes ? (
                <div>
                  {`${intl.formatMessage({ id: 'note' })}
                : ${order.customerNotes}`}
                </div>
              ) : null}
              {renderPickupDeliveryTime()}
              <div>
                {`${intl.formatMessage({ id: 'orderCreated' })}
                : ${moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
              </div>

              <div>
                <FormattedMessage id="deliveryFee" />:{' '}
                {order.deliveryFee && order.deliveryFee.fee > 0
                  ? `$${order.deliveryFee.fee}`
                  : 0}
              </div>

              <div>
                <FormattedMessage id="tax" />:{' '}
                {order.tax > 0 ? `$${order.tax}` : 0}
              </div>

              <div>
                <FormattedMessage id="totalPrice" />
                <span
                  style={
                    order.discount > 0.01
                      ? null
                      : { fontSize: '16px', fontWeight: 'bold' }
                  }
                >
                  {order.discount > 0 && Number(order.total) > 0 && (
                    <>
                      <del>{` $ ${
                        order.subtotal + order.tax + order.deliveryFee.fee
                      } `}</del>
                      <span
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >{` $ ${order.total.toFixed(2)} `}</span>
                    </>
                  )}
                  {order.discount <= 0 &&
                    Number(order.total) > 0 &&
                    ` $ ${order.total.toFixed(2)} `}

                  {order.discount > 0 &&
                    order.discount.amount <= 0 &&
                    Number.isNaN(parseFloat(order.total)) &&
                    `$ ${order.subtotal.toFixed(2)}`}

                  {order.discount > 0.01 && order.total < 0 && (
                    <>
                      <del>{` $ ${order.subtotal.toFixed(2)} `}</del>
                      <span
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >{` $ ${(order.subtotal - order.discount).toFixed(
                        2
                      )} `}</span>
                    </>
                  )}
                  {order.discount < 0.01 &&
                    order.total <= 0 &&
                    ` $ ${order.subtotal.toFixed(2)} `}
                </span>
              </div>
              {order.discount > 0.01 ? (
                <div style={{ color: 'red' }}>
                  <FormattedMessage id="discountAmount" />
                  {` $ ${order.discount.toFixed(2)} `}
                </div>
              ) : null}
              {order.appliedCode ? (
                <div>
                  <FormattedMessage id="appliedCode" />
                  {` : ${order.appliedCode} `}
                </div>
              ) : null}
              {order.balance &&
              (order.bunbaoType === 'fixLocation' ||
                order.bunbaoType === 'normalDelivery') ? (
                <div>
                  <div>
                    <FormattedMessage id="balance" />
                    {` : - $ ${order.balance.toFixed(2)} `}
                  </div>
                  <div>
                    <FormattedMessage id="actualPaid" />
                    {` : $ ${(order.total - order.balance).toFixed(2)} `}
                  </div>
                </div>
              ) : null}
              <div style={{ fontSize: '14px' }}>
                <FormattedMessage id="totalItem" />
                {` ${order.variants.reduce(
                  (sum, variant) => sum + variant.quantity,
                  0
                )} `}{' '}
                <FormattedMessage id="items" />
              </div>

              <div style={{ fontSize: '14px' }}>
                {order.variants.map((variant) => {
                  return (
                    <div key={nanoid(5)}>
                      <div>
                        {variant.quantity} x {variant.name[language]}
                        {variant.price > -1 ? (
                          <span>
                            {this.isCheckPriceSame(variant) ? (
                              <span>{` - ${(
                                variant.quantity * variant.price
                              ).toFixed(2)}`}</span>
                            ) : (
                              <span>
                                {` - `}
                                <span
                                  style={{
                                    textDecoration: 'line-through',
                                    color: 'red',
                                  }}
                                >
                                  {(variant.quantity * variant.price).toFixed(
                                    2
                                  )}
                                </span>{' '}
                                <span>
                                  {(
                                    (variant.commissionFee +
                                      variant.warehouseEarning) *
                                    variant.quantity
                                  ).toFixed(2)}
                                </span>
                              </span>
                            )}
                          </span>
                        ) : null}
                      </div>
                      {this.refundVariantQuantity(variant._id) > 0 && (
                        <div>
                          {'    '}Refunded x{' '}
                          {this.refundVariantQuantity(variant._id)}
                        </div>
                      )}

                      {variant.subVariants
                        ? variant.subVariants.map((element) => {
                            return (
                              <div key={nanoid(5)}>
                                <div>
                                  <span> -{element.quantity}</span>x{' '}
                                  {element.name[language]}
                                  {element.price > -1 ? (
                                    <span>
                                      {this.isCheckPriceSame(element) ? (
                                        <span>
                                          {' '}
                                          {` - ${(
                                            element.quantity * element.price
                                          ).toFixed(2)}`}
                                        </span>
                                      ) : (
                                        <span>
                                          {` - `}
                                          <span
                                            style={{
                                              textDecoration: 'line-through',
                                              color: 'red',
                                            }}
                                          >
                                            $
                                            {(
                                              element.quantity * element.price
                                            ).toFixed(2)}
                                          </span>{' '}
                                          <span>
                                            {(
                                              (element.commissionFee +
                                                element.warehouseEarning) *
                                              element.quantity
                                            ).toFixed(2)}
                                          </span>
                                        </span>
                                      )}
                                    </span>
                                  ) : null}
                                </div>
                                {this.refundVariantQuantity(element._id) >
                                  0 && (
                                  <div>
                                    {'    '}Refunded x{' '}
                                    {this.refundVariantQuantity(element._id)}
                                  </div>
                                )}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  );
                })}
              </div>
            </div>
            {order.bunbaoType !== 'donation' ? (
              <Row>
                <Col span={24}>
                  <Divider
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    orientation="left"
                  >
                    <FormattedMessage
                      id={
                        order.bunbaoType === 'fixLocation'
                          ? 'pickupAddressAndInfo'
                          : 'deliveryAddress'
                      }
                    />
                  </Divider>
                </Col>
                <Col span={24} style={{ fontSize: '14px' }}>
                  <p>{`${addressFormatterOnlyName(order.address)}`}</p>
                  <p>{`${addressFormatterOnlyAddress(order.address)}`}</p>
                  {/* <p>{` ${order.address.firstName} ${order.address.lastName} ${order.address.phoneNumber}`}</p>
                <p>{` ${order.address.addressOne} ${order.address.addressTwo} ${order.address.city} ${order.address.zipCode}`}</p> */}
                  {order.warehouseStatus === 'delivered' && (
                    <div>
                      <FormattedMessage id="delivered" />{' '}
                      {order.proofPictures.length > 0 && (
                        <Popover
                          content={
                            <div>
                              {order.proofPictures.map((picture) => (
                                <img
                                  key={picture._id}
                                  src={BUCKET_CDN_URL + picture.key}
                                  alt={picture._id}
                                />
                              ))}
                            </div>
                          }
                        >
                          <Button type="primary" size="small">
                            <FormattedMessage id="proofOfDelivery" />
                          </Button>
                        </Popover>
                      )}
                    </div>
                  )}
                </Col>
                <Col span={24}>
                  {coordinates && coordinates.length >= 2 ? (
                    <div>
                      <Button
                        style={{ width: '140px' }}
                        type="primary"
                        onClick={() => {
                          this.handleOpenGoogleMap(
                            coordinates[0],
                            coordinates[1]
                          );
                        }}
                      >
                        <CarOutlined style={{ fontSize: '16px' }} />{' '}
                        <FormattedMessage id="direction" />
                      </Button>
                    </div>
                  ) : null}
                  {order.campaignContent &&
                  order.bunbaoType === 'fixLocation' &&
                  order.campaignContent.defaultAddress ? (
                    <div>
                      <FormattedMessage id="storeName" />:{' '}
                      {`${order.campaignContent.defaultAddress.firstName} ${order.campaignContent.defaultAddress.lastName}`}
                    </div>
                  ) : null}
                </Col>
              </Row>
            ) : (
              <div>
                <Link to={getCustomerPath('donation')}>
                  <Button style={{ width: '140px' }} type="primary">
                    <FormattedMessage id="viewMyDonation" />
                  </Button>
                </Link>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

DeliveryOrderDetail.defaultProps = {
  match: null,
  order: null,
  referralCode: null,
};

DeliveryOrderDetail.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  match: ReactRouterPropTypes.match,
  order: PropTypes.shape({
    _id: PropTypes.string,
    discount: PropTypes.number,
    refundVariants: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        variantId: PropTypes.string,
      })
    ),
    discountAmount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({}),
    ]),
  }),
  referralCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  group: state.user.group,
  referralCode: state.user.referralCode,
});

export default connect(mapStateToProps, null)(injectIntl(DeliveryOrderDetail));
