/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
  CloseCircleFilled,
  EditOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import {
  Button,
  Card,
  Col,
  InputNumber,
  Modal,
  Radio,
  Row,
  Tag,
  Input,
  Select,
  message,
  Divider,
  Form,
} from 'antd';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import lodash from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  apolloClient,
  colorGenerator,
  everyWordFirstLetterToUpperCase,
  getCustomerPath,
  displayDescription,
  translateWords,
} from '../../../util';
import {
  ORDER_FINALIZE,
  ORDER_CONFIRM,
  ORDER_FETCH_DELIVERY_FEE,
  ORDER_CREATE,
  CATERING_EMAIL_REQUEST,
} from '../../../api/order';
import { BUCKET_CDN_URL, BUCKET_URL } from '../../../config';
import { TAX_RATE_FETCH } from '../../../api/taxRate';
import * as actions from '../../../store/actions';
import CateringProductModal from '../../../components/Modals/CateringProductModal/CateringProductModal';
import { WAREHOUSE_FIND_MANY } from '../../../api/warehouse';
import { ADDRESS_QUERY } from '../../../api/address';
import CateringAddressForm from '../../../components/Forms/CateringAddressForm/CateringAddressForm';
import CateringOrderCheckoutForm from '../../../components/Forms/CateringOrderCheckoutForm/CateringOrderCheckoutForm';
import { CONFIGURATION_FIND_ONE } from '../../../api/configuration';
import MenuBanner from '../../../components/MenuBanner/MenuBanner';
import { BANNER_FIND_MANY } from '../../../api/banner';
import './CateringMenu.less';
import Loading from '../../../components/Others/Loading/Loading';
import CouponInput from './CouponInput';

const { Option } = Select;
const { TextArea } = Input;

export class CateringMenu extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: true,
      products: [],
      categories: [],
      cart: [],
      addresses: [],
      taxRate: 0,
      selectedProduct: null,
      selectedProductQuantity: 1,
      selectedProductVariant: {},
      productModalVisible: false,
      paymentModalVisible: false,
      subtotal: 0,
      minimum: 100,
      stripeLink: null,
      finalizeLoading: false,
      order: null,
      orderId: null,
      estHeadcount: 0,
      headcount: 0,
      distance: 0,
      duration: 0,
      fee: 0,
      tipAmountModalVisible: false,
      tipPercentage: 0,
      tipAmount: 0,
      tipSelected: 'none',
      scheduleDate: moment(),
      scheduleTime: moment(),
      selectedAddress: 'newAddress',
      selectedAddressDetail: null,
      deliveryFeePreMile: 0,
      selectedCategory: 'All',
      deliveryFeePreMinute: 0,
      banners: [],
      expandedControl: {
        expandedAddress: false,
        expandedDeliveryTime: false,
        expandedDeliveryDetail: false,
      },
      deliveryDetailDate: null,
      deliveryDetailTime: null,
      deliveryNotes: null,
      notes: null,
      checkoutPending: false,
      contractUsPopUp: false,
      words: [],
      coupon: { effect: 0, code: '' },
    };
    this.EXPANDED_CONTROL_INITIAL = {
      expandedAddress: false,
      expandedDeliveryTime: false,
      expandedDeliveryDetail: false,
    };
  }

  async componentDidMount() {
    const { items, token } = this.props;
    try {
      await this.handleFetchCateringWarehouseVariants();
      await this.handleFetchCateringConfiguration();
      await this.handleFetchCateringBanners();
      await this.handleFetchWords();
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
    if (token) {
      await this.handleFetchAddresses();
    }

    // update subtotal
    const { tipAmount, tipSelected, tipPercentage } = this.props;
    // console.log(tipSelected);
    this.setState({ tipAmount, tipSelected, tipPercentage });
    let estHeadcount = 0;
    let subtotal = 0;
    // console.log('items', items);
    items.forEach((item) => {
      if (item.isVariant) {
        const variant = this.findVariant(item.product._id, item.variantId);
        subtotal += item.quantity * variant.listPrice;
      } else {
        subtotal += item.product.listPrice * item.quantity;
      }
      estHeadcount += item.servesNumber;
    });
    this.handleFetchTaxRate();
    await this.setState({
      cart: items,
      subtotal,
      estHeadcount,
    });
    this.handleFetchDeliveryFee();
  }

  handleFetchCateringBanners = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'catering' } },
      });
      // sort banner
      const rawData = bannerFindMany;
      const slides = lodash.orderBy(
        rawData,
        ['ranking', 'createdAt'],
        ['asc', 'asc']
      );
      this.setState({ banners: slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  handleFetchCateringConfiguration = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { name: 'catering' },
      },
      fetchPolicy: 'network-only',
    });
    if (configurationOne) {
      const { cateringConfiguration } = configurationOne;
      this.setState({
        deliveryFeePreMile: cateringConfiguration.deliveryFee.mile,
        deliveryFeePreMinute: cateringConfiguration.deliveryFee.minute,
      });
    }
  };

  handleFetchCateringWarehouseVariants = async () => {
    const {
      data: { warehouseMany },
    } = await apolloClient.query({
      query: WAREHOUSE_FIND_MANY,
      variables: { filter: { warehouseType: 'catering' } },
      fetchPolicy: 'network-only',
    });
    const cateringVariants = warehouseMany[0].allowedVariants;
    const categories = new Set();
    const products = [];
    cateringVariants.forEach((variant) => {
      if (variant.product.cateringCategoryId) {
        categories.add(variant.product.cateringCategory);
      } else {
        categories.add(variant.product.category);
      }
      const found = products.findIndex((product) => {
        return product._id === variant.product._id;
      });
      if (found !== -1) {
        const existProduct = products[found];
        existProduct.variants.add(variant);
        products[found] = existProduct;
      } else {
        const newProduct = {
          ...variant.product,
          variants: new Set(),
        };
        newProduct.variants.add(variant);
        products.push(newProduct);
      }
    });
    products.map((product) => {
      const newProduct = product;
      newProduct.variants = lodash.orderBy(
        [...newProduct.variants],
        ['listPrice'],
        ['asc']
      );
      if (product.cateringCategoryId) {
        // replace category with catering category
        newProduct.category = product.cateringCategory;
      }
      return newProduct;
    });
    const sortedCategories = lodash.orderBy(
      [...categories],
      ['ranking', 'name'],
      ['asc', 'asc']
    );
    if (sortedCategories.length > 0) {
      this.setState({
        selectedCategory: sortedCategories[0].name,
      });
    }
    const sortedProduct = lodash.orderBy(
      products,
      ['ranking', 'createdAt', 'name'],
      ['asc', 'desc', 'asc']
    );
    // console.log('categories', sortedCategories);
    // console.log('catering products', sortedProduct);
    this.setState({ categories: sortedCategories, products: sortedProduct });
  };

  handleFetchAddresses = async (selectedAddress = null) => {
    const {
      data: { userCurrent },
    } = await apolloClient.query({
      query: ADDRESS_QUERY,
      fetchPolicy: 'network-only',
    });
    // console.log(userCurrent);
    const filterAddresses = userCurrent.addresses.filter(
      (address) => address.type === 'company'
    );
    await this.setState({ addresses: filterAddresses });
    if (filterAddresses.length > 0) {
      if (selectedAddress === null) {
        const { addresses } = this.state;
        const selectedAddressDetail = addresses.find(
          (address) => address._id === filterAddresses[0]._id
        );
        this.setState({
          selectedAddress: filterAddresses[0]._id,
          selectedAddressDetail,
        });
      } else {
        const { addresses } = this.state;
        let selectedAddressDetail = addresses.find(
          (address) => address._id === selectedAddress
        );
        if (selectedAddressDetail) {
          this.setState({
            selectedAddress: filterAddresses[0]._id,
            selectedAddressDetail,
          });
        } else {
          selectedAddressDetail = addresses.find(
            (address) => address._id === filterAddresses[0]._id
          );
          this.setState({
            selectedAddress: filterAddresses[0]._id,
            selectedAddressDetail,
          });
        }
      }
    }
  };

  handleChangeAddressSelect = (value) => {
    // selected address
    console.log(value);
    if (value === 'newAddress') {
      // show new address form
      this.setState({
        selectedAddress: 'newAddress',
        selectedAddressDetail: null,
      });
    } else {
      const { addresses } = this.state;
      const selectedAddressDetail = addresses.find(
        (address) => address._id === value
      );

      this.setState({
        selectedAddress: value,
        selectedAddressDetail,
      });
      this.handleFetchDeliveryFee();
      // show address information
    }
  };

  handleSelectProductQuantityChange = (value) => {
    this.setState({ selectedProductQuantity: value });
  };

  handleFetchDeliveryFee = async () => {
    const { scheduleDate, scheduleTime } = this.state;
    const { selectedAddressDetail } = this.state;
    if (selectedAddressDetail) {
      const schedule = moment(
        `${scheduleDate.format('YYYY-MM-DD')} ${scheduleTime.format('HH:mm')}`
      )
        .unix()
        .toString();
      const response = await apolloClient.mutate({
        mutation: ORDER_FETCH_DELIVERY_FEE,
        variables: {
          addressOne: selectedAddressDetail.addressOne,
          addressTwo: selectedAddressDetail.addressTwo,
          city: selectedAddressDetail.city,
          zip: selectedAddressDetail.zipCode,
          state: selectedAddressDetail.state
            ? selectedAddressDetail.state
            : 'CA',
          arrivalTime: schedule,
        },
      });
      const { orderFetchDeliveryFee } = response.data;
      this.setState({
        duration: orderFetchDeliveryFee.duration,
        distance: orderFetchDeliveryFee.distance,
        fee: orderFetchDeliveryFee.fee,
      });
    } else {
      this.setState({
        duration: 0,
        distance: 0,
        fee: 0,
      });
    }
  };

  handleOpenProductModal = async () => {
    const { selectedProduct } = this.state;
    // console.log(selectedProduct.variants);
    // console.log(selectedProduct.variants[0]);
    if (selectedProduct.variants.length > 0) {
      await this.setState({
        selectedProductVariant: { ...selectedProduct.variants[0] },
      });
      if (
        selectedProduct.variants[0].minimumQuantity &&
        selectedProduct.variants[0].minimumQuantity > 1
      ) {
        await this.setState({
          selectedProductQuantity: selectedProduct.variants[0].minimumQuantity,
        });
      }
    }
    await this.setState({
      productModalVisible: true,
    });
  };

  calculateSubtotal = (products) => {
    let subtotal = 0;
    products.forEach((product) => {
      subtotal += product.price * product.quantity;
    });
    return subtotal;
  };

  calculateTip = (products) => {
    let subtotal = 0;
    if (products) {
      products.forEach((product) => {
        subtotal += product.price * product.quantity;
      });
    }
    const { subtotal: stateSubtotal } = this.state;
    subtotal = stateSubtotal;
    const { tipSelected, tipAmount, tipPercentage } = this.state;
    if (tipSelected === 'other') {
      return tipAmount;
    }
    if (tipSelected !== 'none') {
      return tipPercentage * subtotal;
    }
    return 0;
  };

  findVariant = (productId, variantId) => {
    const { products } = this.state;
    // first search variant id
    const foundProduct = products.find((product) => product._id === productId);
    console.log(foundProduct);
    if (foundProduct) {
      const foundVariant = foundProduct.variants.find(
        (variant) => variant._id === variantId
      );
      return foundVariant;
    }
    // clear shopping cart
    const { clearItems, updateTip } = this.props;
    clearItems();
    updateTip({ tipSelected: 'none', tipPercentage: 0, tipAmount: 0 });
    return null;
  };

  handleCloseProductModal = () => {
    this.setState({
      productModalVisible: false,
      selectedProduct: null,
      selectedProductQuantity: 1,
    });
  };

  handleAddProductToCart = async () => {
    const { addItem } = this.props;
    const {
      selectedProduct,
      selectedProductQuantity,
      subtotal,
      selectedProductVariant,
      estHeadcount,
    } = this.state;
    console.log('selectedProductQuantity', selectedProductQuantity);
    let newHeadcount = estHeadcount;
    const newItem = {
      id: nanoid(),
      picture: BUCKET_CDN_URL + selectedProduct.picture.key,
      product: selectedProduct,
      quantity: selectedProductQuantity,
      isVariant: true,
      variantId: selectedProductVariant._id,
      minimumQuantity: selectedProductVariant.minimumQuantity,
      servesNumberUnit: this.findVariant(
        selectedProduct._id,
        selectedProductVariant._id
      ).servesNumber,
      servesNumber:
        selectedProductQuantity *
        this.findVariant(selectedProduct._id, selectedProductVariant._id)
          .servesNumber,
      note: '',
      listPrice: selectedProductVariant.listPrice,
    };
    await addItem(newItem);
    const { items } = this.props;
    newHeadcount +=
      selectedProductQuantity *
      this.findVariant(selectedProduct._id, selectedProductVariant._id)
        .servesNumber;
    await this.setState({
      subtotal:
        subtotal + selectedProductVariant.listPrice * selectedProductQuantity,
    });
    await this.setState({ estHeadcount: newHeadcount, cart: items });
    this.handleCloseProductModal();
  };

  handleRemoveProductFromCart = async (item) => {
    const { removeItem } = this.props;
    await removeItem(item);
    let { subtotal } = this.state;
    const { estHeadcount } = this.state;
    const { items } = this.props;
    const variant = this.findVariant(item.product._id, item.variantId);
    subtotal -= item.quantity * variant.listPrice;
    const newHeadcount = estHeadcount - item.servesNumber * item.quantity;
    this.setState({ cart: items, subtotal, estHeadcount: newHeadcount });
  };

  handleSuccessCreateOrder = async (response) => {
    this.setState({
      paymentModalVisible: true,
      orderId: response.data.orderCreateOne._id,
      order: response.data.orderCreateOne,
    });
    // clear items
    const { clearItems, updateTip } = this.props;
    clearItems();
    updateTip({ tipSelected: 'none', tipPercentage: 0, tipAmount: 0 });
  };

  handlePayOrder = async () => {
    const { orderId } = this.state;
    await this.setState({ finalizeLoading: true });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_CONFIRM,
        variables: { orderId },
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_FINALIZE,
        variables: { orderId },
      });
      console.log(response);
      this.setState({ stripeLink: response.data.orderFinalize.invoiceUrl });
      // directly open new tab for payment
      window.open(response.data.orderFinalize.invoiceUrl, '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  handleFetchTaxRate = async () => {
    const { selectedAddressDetail } = this.state;
    let zipCode = '';
    if (!selectedAddressDetail) {
      zipCode = '94538';
    } else {
      zipCode = selectedAddressDetail.zipCode;
    }
    try {
      const { data } = await apolloClient.mutate({
        mutation: TAX_RATE_FETCH,
        variables: {
          zipCode: zipCode.toString(),
        },
      });
      const { taxRateFetch } = data;
      this.setState({ taxRate: taxRateFetch.percentage / 100 });
    } catch (error) {
      console.log(error);
    }
  };

  handleCheckMinimum = () => {
    const { minimum, subtotal } = this.state;
    if (subtotal > minimum) {
      return false;
    }
    return true;
  };

  handleChangeVariant = (value) => {
    console.log(value);
    const { selectedProduct } = this.state;
    // first search variant id
    const foundVariant = selectedProduct.variants.find(
      (variant) => value === variant._id
    );
    console.log(foundVariant);
    this.setState({
      selectedProductVariant: foundVariant,
      selectedProductQuantity: foundVariant.minimumQuantity || 1,
    });
  };

  handleFetchWords = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isProductConfiguration: true },
      },
    });
    this.setState({ words: configurationOne.words });
  };

  translation = (word) => {
    const { words } = this.state;
    return translateWords(word, words);
  };

  handleRenderIngredients = () => {
    const { selectedProduct } = this.state;
    const { language } = this.props;
    if (selectedProduct) {
      const ingredientsLength = selectedProduct.ingredients.split('\n').length;
      return selectedProduct.ingredients
        .split('\n')
        .map((ingredient, index) => {
          if (index !== ingredientsLength - 1) {
            return language === 'zh'
              ? `${this.translation(`${ingredient}`.toLowerCase())} `
              : `${`${ingredient}`.toLowerCase()}, `;
          }
          return language === 'zh'
            ? `${this.translation(`${ingredient}`.toLowerCase())} `
            : `${`${ingredient}`.toLowerCase()}, `;
        });
    }
    return <React.Fragment />;
  };

  handleOpenTipModal = () => {
    // check subtotal
    const { subtotal, tipSelected } = this.state;
    if (tipSelected === '0.1') {
      this.setState({ tipAmount: Number((subtotal * 0.1).toFixed(2)) });
    }
    if (tipSelected === '0.15') {
      this.setState({ tipAmount: Number((subtotal * 0.15).toFixed(2)) });
    }
    if (tipSelected === '0.2') {
      this.setState({ tipAmount: Number((subtotal * 0.2).toFixed(2)) });
    }
    this.setState({ tipAmountModalVisible: true });
  };

  handleTipSelected = (e) => {
    console.log(e.target.value);
    const { value } = e.target;
    if (e.target.value === 'other') {
      // if other, pop modal to choose
      this.handleOpenTipModal();
    } else {
      // if percentage, just set up
      this.setState({ tipSelected: value, tipPercentage: Number(value) });
      const { updateTip } = this.props;
      updateTip({
        tipSelected: value,
        tipPercentage: Number(value),
        tipAmount: 0,
      });
    }
  };

  handleSaveTip = () => {
    const { tipAmount } = this.state;
    console.log(tipAmount);
    if (tipAmount > 0) {
      this.setState({
        tipSelected: 'other',
      });
      // const { tipAmount } = this.state;
      const { updateTip } = this.props;
      updateTip({
        tipSelected: 'other',
        tipPercentage: Number(0),
        tipAmount: Number(tipAmount),
      });
    } else {
      this.setState({
        tipSelected: 'none',
      });
      const { updateTip } = this.props;
      updateTip({
        tipSelected: 'none',
        tipPercentage: Number(0),
        tipAmount: 0,
      });
    }
    this.setState({ tipAmountModalVisible: false });
  };

  handleChangeInputNumber = (value) => {
    // console.log(value);
    this.setState({ tipAmount: value });
  };

  handleScrollToCategory = (category) => {
    this.setState({ selectedCategory: category.name });
  };

  handleSetHeadcount = (count) => {
    this.setState({ headcount: count });
  };

  handleAddressSubmit = async () => {
    await this.setState({
      expandedControl: {
        ...this.EXPANDED_CONTROL_INITIAL,
        expandedAddress: false,
      },
    });
    await this.handleFetchDeliveryFee();
  };

  handleAddressCancel = () => {
    this.setState({
      expandedControl: {
        ...this.EXPANDED_CONTROL_INITIAL,
        expandedAddress: false,
      },
    });
  };

  handleIncrement = async (item) => {
    const { addItem } = this.props;
    await addItem({
      ...item,
      ...{ quantity: 1, servesNumber: item.servesNumberUnit },
    });
    const { items } = this.props;
    await this.setState({ cart: items });
    await this.calculateFees();
  };

  calculateFees = async () => {
    const { items } = this.props;
    await this.setState({
      estHeadcount: items.reduce((sum, cur) => sum + cur.servesNumber, 0),
      subtotal: items.reduce(
        (sum, cur) => sum + cur.listPrice * cur.quantity,
        0
      ),
    });
  };

  handleDecrement = async (item) => {
    const { addItem } = this.props;
    console.log(item);
    await addItem({
      ...item,
      ...{ quantity: -1, servesNumber: -item.servesNumberUnit },
    });
    const { items } = this.props;
    await this.setState({ cart: items });
    await this.calculateFees();
  };

  handleCheckoutButtonDisable = () => {
    const {
      selectedAddressDetail,
      deliveryDetailDate,
      deliveryDetailTime,
      subtotal,
      taxRate,
      fee,
    } = this.state;

    return !(
      selectedAddressDetail &&
      deliveryDetailDate &&
      deliveryDetailTime &&
      Number.parseFloat(
        subtotal + subtotal * taxRate + fee + this.calculateTip() || 0
      ) >= 100
    );
  };

  handleSubmitOrder = async () => {
    await this.setState({
      checkoutPending: true,
    });
    const {
      deliveryDetailTime,
      deliveryDetailDate,
      headcount,
      deliveryNotes,
      cart,
      selectedAddressDetail,
      tipAmount,
      tipPercentage,
      tipSelected,
      notes,
      coupon,
    } = this.state;

    const schedule = moment(
      `${deliveryDetailDate?.format('YYYY-MM-DD')} ${deliveryDetailTime?.format(
        'HH:mm'
      )}`
    )?.valueOf();
    const itemsArray = [];
    let headcountNumber = 0;
    if (headcount) {
      headcountNumber = Number(headcount);
    }
    // pass cart item to array
    cart.forEach((item) => {
      itemsArray.push({
        productId: item.product._id,
        quantity: item.quantity,
        isVariant: item.isVariant,
        variantId: item.variantId,
      });
    });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_CREATE,
        variables: {
          products: itemsArray,
          scheduleDate: Number(schedule),
          headcount: headcountNumber,
          contactName: `${selectedAddressDetail.firstName} ${selectedAddressDetail.lastName}`,
          contactPhoneNumber: selectedAddressDetail.phoneNumber,
          companyName: selectedAddressDetail.companyName,
          addressOne: selectedAddressDetail.addressOne,
          addressTwo: selectedAddressDetail.addressTwo,
          city: selectedAddressDetail.city,
          state: selectedAddressDetail.state
            ? selectedAddressDetail.state
            : 'CA',
          zip: selectedAddressDetail.zipCode.toString(),
          deliveryNotes,
          notes,
          tipSelected,
          tipPercentage: Number(tipPercentage),
          tipAmount: Number(tipAmount),
          couponCode: coupon.code,
        },
      });
      console.log(response);
      await this.handleSuccessCreateOrder(response);
      await this.setState({
        checkoutPending: false,
      });
      // }
    } catch (error) {
      console.error(error);
      await this.setState({
        checkoutPending: false,
      });
    }
  };

  handleContractUsFromSubmit = async (values) => {
    console.log(values);
    const { email, notes } = values;
    try {
      const {
        data: { cateringCreateInformationEmail },
      } = await apolloClient.mutate({
        mutation: CATERING_EMAIL_REQUEST,
        variables: {
          email,
          notes,
          secret: 'BunBao',
        },
      });
      console.log(
        'cateringCreateInformationEmail',
        cateringCreateInformationEmail
      );
      message.success('发送成功,感谢你的来信');
      this.setState({
        contractUsPopUp: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        contractUsPopUp: false,
      });
    }
  };

  render() {
    const {
      loading,
      categories,
      products,
      productModalVisible,
      paymentModalVisible,
      selectedProduct,
      selectedProductQuantity,
      selectedProductVariant,
      cart,
      subtotal,
      taxRate,
      stripeLink,
      finalizeLoading,
      order,
      fee,
      distance,
      duration,
      headcount,
      estHeadcount,
      tipSelected,
      tipAmount,
      tipPercentage,
      tipAmountModalVisible,
      addresses,
      selectedAddress,
      selectedAddressDetail,
      deliveryFeePreMile,
      deliveryFeePreMinute,
      selectedCategory,
      banners,
      expandedControl,
      deliveryDetailDate,
      deliveryDetailTime,
      deliveryNotes,
      checkoutPending,
      contractUsPopUp,
      notes,
      coupon,
    } = this.state;
    const { language, token } = this.props;
    const itemStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '8px',
    };

    return (
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#FFF',
          position: 'relative',
        }}
      >
        <Modal
          visible={tipAmountModalVisible}
          width={300}
          maskClosable={false}
          footer={null}
          onCancel={() => {
            this.setState({ tipAmountModalVisible: false });
          }}
        >
          <Row>
            <Col style={{ padding: '2px 0' }} span={24}>
              Tip Amount
            </Col>
            <Col style={{ padding: '2px 0' }} span={24}>
              <div style={{ width: '100%' }}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  value={tipAmount}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={this.handleChangeInputNumber}
                />
              </div>
            </Col>
            <Col style={{ padding: '2px 0' }} span={24}>
              <Button
                onClick={() => {
                  this.handleSaveTip();
                }}
                type="primary"
                style={{ width: '100%' }}
              >
                Save Tip
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          width={700}
          visible={paymentModalVisible}
          destroyOnClose
          footer={null}
          closable={false}
          style={{}}
        >
          <React.Fragment>
            <div css={{ margin: '30px' }}>
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img
                  style={{ height: '250px' }}
                  src={`${BUCKET_URL}images/catering/order-confirmation.svg`}
                  alt=""
                />
                <div
                  style={{
                    color: '#F51D2C',
                    fontWeight: 'bold',
                    fontSize: '24px',
                  }}
                >
                  <FormattedMessage id="cateringPayment.orderConfirmed" />
                </div>
              </div>
              <div
                style={{
                  color: '#F51D2C',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {' '}
                <FormattedMessage id="cateringPayment.orderInfo" />:
              </div>
              {order &&
                order.products.map((product) => (
                  <div key={product._id} style={itemStyle}>
                    <div>{`${product.quantity} X ${product.name}`}</div>
                    <div>{`$${(product.price * product.quantity).toFixed(
                      2
                    )}`}</div>
                  </div>
                ))}
              {order && (
                <div>
                  <div style={itemStyle}>
                    <div>
                      <FormattedMessage id="cateringPayment.subtotal" />
                    </div>
                    <div>
                      {`$${this.calculateSubtotal(order.products).toFixed(2)}`}
                    </div>
                  </div>

                  <div style={itemStyle}>
                    <div>
                      <FormattedMessage id="cateringPayment.tax" />
                    </div>
                    <div>
                      {`$${(
                        ((this.calculateSubtotal(order.products) +
                          coupon.effect) *
                          order.taxRate) /
                        100
                      ).toFixed(2)}`}
                    </div>
                  </div>

                  <div style={itemStyle}>
                    <div>
                      <FormattedMessage id="cateringPayment.deliveryFee" />
                    </div>
                    <div>{`$${Number(order.deliveryFee.fee).toFixed(2)}`}</div>
                  </div>

                  <div style={itemStyle}>
                    <div>
                      <FormattedMessage id="cateringPayment.tip" />
                    </div>
                    <div>
                      {' '}
                      {`$${this.calculateTip(order.products).toFixed(2)}`}
                    </div>
                  </div>
                  <div style={itemStyle}>
                    <div>Coupon</div>
                    <div>{`-$${-1 * coupon.effect}`}</div>
                  </div>
                  <div
                    style={{
                      ...itemStyle,
                      ...{ fontSize: '20px', fontWeight: 'bold' },
                    }}
                  >
                    <div>
                      <FormattedMessage id="cateringPayment.total" />
                    </div>
                    <div style={{ color: '#F51D2C' }}>
                      {' '}
                      {`$${(
                        Number(order.deliveryFee.fee) +
                        ((this.calculateSubtotal(order.products) +
                          coupon.effect) *
                          order.taxRate) /
                          100 +
                        this.calculateSubtotal(order.products) +
                        this.calculateTip(order.products) +
                        coupon.effect
                      ).toFixed(2)}`}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div
                  css={{
                    width: '50%',
                    margin: 'auto',
                    button: {
                      backgroundColor: '#F51D2C',
                      boxShadow: '0px 4px 12px rgba(245, 29, 44, 0.25)',
                      borderRadius: '25px',
                    },
                  }}
                >
                  {!stripeLink && !finalizeLoading && (
                    <Button
                      style={{ width: '100%' }}
                      key="pay"
                      type="primary"
                      size="large"
                      onClick={() => {
                        this.handlePayOrder();
                      }}
                    >
                      Pay
                    </Button>
                  )}
                  {!stripeLink && finalizeLoading ? (
                    <Button
                      key="loading"
                      type="primary"
                      size="large"
                      style={{ width: '100%' }}
                      disabled
                    >
                      <LoadingOutlined />
                      Create Payment Page
                    </Button>
                  ) : null}
                  {stripeLink ? (
                    <Button
                      key="payLink"
                      type="primary"
                      size="large"
                      style={{ width: '100%' }}
                      onClick={() => window.open(stripeLink, '_blank')}
                    >
                      Open Payment Page
                    </Button>
                  ) : null}
                </div>
                <div
                  style={{
                    width: '50%',
                    margin: '20px auto 0px auto',
                  }}
                >
                  <Link to={getCustomerPath('account/catering-orders')}>
                    <Button
                      key="orders"
                      style={{ borderRadius: '25px', width: '100%' }}
                    >
                      Back to your catering orders
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Modal
          visible={productModalVisible}
          onCancel={this.handleCloseProductModal}
          footer={null}
          destroyOnClose
          width={600}
        >
          <CateringProductModal
            selectedProduct={selectedProduct}
            selectedProductVariant={selectedProductVariant}
            selectedProductQuantity={selectedProductQuantity}
            handleRenderIngredients={this.handleRenderIngredients}
            handleChangeVariant={this.handleChangeVariant}
            handleSelectProductQuantityChange={
              this.handleSelectProductQuantityChange
            }
            handleAddProductToCart={this.handleAddProductToCart}
            handleCloseProductModal={this.handleCloseProductModal}
          />
        </Modal>
        <div className="catering-page">
          <div
            css={{
              position: 'absolute',
              right: '0px',
              zIndex: 10,
              bottom: '8%',
            }}
            style={
              contractUsPopUp
                ? {
                    backgroundColor: '#fff',
                    padding: '15px',
                    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                    borderRadius: '25px',
                    minWidth: '300px',
                  }
                : {
                    backgroundColor: '#F51D2C',
                    padding: '20px 5px',
                    boxShadow: '0px 4px 12px rgb(245 29 44 / 25%)',
                    borderRadius: '5px',
                  }
            }
            role="presentation"
            onClick={() => {
              if (contractUsPopUp) {
                return;
              }
              this.setState({
                contractUsPopUp: true,
              });
            }}
          >
            {!contractUsPopUp && (
              <span style={{ color: 'white', writingMode: 'vertical-lr' }}>
                <FormattedMessage id="cateringContactUs.title" />
              </span>
            )}
            {contractUsPopUp && (
              <div style={{ width: '100%', position: 'relative' }}>
                <h2>
                  {' '}
                  <FormattedMessage id="cateringContactUs.subTitle" /> :
                </h2>
                <Form
                  name="contractUs"
                  layout="vertical"
                  onFinish={this.handleContractUsFromSubmit}
                  css={{
                    input: {
                      borderColor: '#40A9FF',
                      borderRadius: '25px',
                    },
                    textarea: {
                      borderRadius: '25px',
                      borderColor: '#40A9FF',
                    },
                  }}
                >
                  <Form.Item
                    label={<FormattedMessage id="cateringContactUs.email" />}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                    ]}
                  >
                    <Input
                      placeholder={
                        language === 'zh'
                          ? 'Place enter your email'
                          : '请输入您的邮箱，以便我们回复您'
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label={<FormattedMessage id="cateringContactUs.msg" />}
                    name="notes"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your message!',
                      },
                    ]}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4 }}
                      placeholder={
                        language === 'zh'
                          ? 'Please leave message here ...'
                          : '请输入您想和我们说的话....'
                      }
                    />
                  </Form.Item>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      style={{ borderRadius: '25px' }}
                      type="primary"
                      htmlType="submit"
                    >
                      <FormattedMessage id="cateringContactUs.submit" />
                    </Button>
                  </div>
                </Form>
                <div>
                  <CloseCircleFilled
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        contractUsPopUp: false,
                      });
                    }}
                    style={{
                      fontSize: '20px',
                      color: '#F51D2C',
                      position: 'absolute',
                      top: '-18px',
                      right: '-15px',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {loading ? (
            <Col span={24}>
              <Loading />
            </Col>
          ) : (
            <Row gutter={30}>
              <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16}>
                <Row gutter={20} type="flex">
                  {banners.length > 0 ? (
                    <Col span={24}>
                      <div style={{ borderRadius: '25px', overflow: 'hidden' }}>
                        <MenuBanner
                          slides={banners}
                          language={language}
                          hasArrow={false}
                          handleIsClickableClicked={
                            this.handleIsClickableClicked
                          }
                          handleBannerClick={this.onOpenProductDetailModal}
                          handleClickCategoryIcon={this.handleClickCategoryIcon}
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col span={24}>
                    <div css={{ marginTop: '20px', marginBottom: '36px' }}>
                      {categories.map((category) => {
                        return (
                          <div
                            key={category.id}
                            onClick={() => {
                              this.handleScrollToCategory(category);
                            }}
                            css={{
                              display: 'inline-block',
                              userSelect: 'none',
                              borderBottom: '1px solid',
                              borderColor:
                                selectedCategory === category.name
                                  ? '#FF4D4F'
                                  : '#1890FF',
                              marginRight: '35px',
                              color:
                                selectedCategory === category.name
                                  ? '#FF4D4F'
                                  : '#1890FF',
                              padding: '10px',
                              fontWeight: '600',
                              cursor: 'pointer',
                              ':hover': {
                                // backgroundColor: '#ff8364',
                                color: '#FF4D4F',
                              },
                            }}
                            role="presentation"
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {category?.pictures.length > 0 ? (
                                <img
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '12px',
                                  }}
                                  src={
                                    BUCKET_CDN_URL + category.pictures[0]?.key
                                  }
                                  alt="icon"
                                />
                              ) : (
                                <div
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '12px',
                                  }}
                                />
                              )}
                              {language === 'zh'
                                ? category.title.zh
                                : category.title.en}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col span={24}>
                    {categories.map((category) => (
                      <Col key={category._id} span={24}>
                        <Row
                          gutter={20}
                          type="flex"
                          css={{
                            display:
                              selectedCategory === category.name ? '' : 'none',
                          }}
                        >
                          {products.map((product) => {
                            if (product.category._id === category._id) {
                              return (
                                <div
                                  key={product._id}
                                  style={{
                                    marginBottom: '30px',
                                    width: '240px',
                                    marginRight: '60px',
                                  }}
                                  css={{ position: 'relative' }}
                                >
                                  <Card
                                    css={{
                                      boxShadow:
                                        '0px 4px 12px rgba(0, 0, 0, 0.25)',
                                      borderRadius: '15px',
                                      overflow: 'hidden',
                                      '.ant-card-body': {
                                        padding: '0px',
                                      },
                                    }}
                                    style={{ height: '100%' }}
                                    size="small"
                                    hoverable
                                    onClick={async () => {
                                      await this.setState({
                                        selectedProduct: product,
                                      });
                                      await this.handleOpenProductModal();
                                    }}
                                  >
                                    <Row type="flex">
                                      <Col>
                                        <Row type="flex">
                                          <Col>
                                            {product.picture !== null ? (
                                              <img
                                                style={{
                                                  width: '100%',
                                                }}
                                                src={
                                                  BUCKET_CDN_URL +
                                                  product.picture.key
                                                }
                                                alt="Bao Product"
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  width: '100%',
                                                }}
                                                src={`${BUCKET_CDN_URL}media/products/tasting_%E7%A4%BC%E7%9B%92.jpg`}
                                                alt="Bao Product"
                                              />
                                            )}
                                          </Col>
                                        </Row>
                                        <div
                                          style={{
                                            marginTop: '5px',
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            lineHeight: '21px',
                                            color: '#3e90d6',
                                            padding: '0px 8px',
                                          }}
                                          css={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <div>
                                            {product.name[language].replace(
                                              /\(.+\)/,
                                              ''
                                            )}
                                          </div>
                                          <div style={{ marginLeft: '10px' }}>
                                            {product.variants.length > 0 ? (
                                              <div>{`$${product.variants[0].listPrice}`}</div>
                                            ) : (
                                              <div>{`$${product.listPrice}`}</div>
                                            )}
                                          </div>
                                        </div>

                                        <Row style={{ padding: '0px 8px' }}>
                                          <Col style={{ minHeight: '25px' }}>
                                            {product.hashtags.map((hashtag) => (
                                              <Tag
                                                style={{ marginTop: '5px' }}
                                                key={nanoid(6)}
                                                color={colorGenerator(hashtag)}
                                              >
                                                {hashtag}
                                              </Tag>
                                            ))}
                                          </Col>
                                        </Row>
                                        <Row
                                          style={{
                                            marginTop: '5px',
                                            padding: '0px 10px 10px 10px',
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: '65px',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            {convert(
                                              convertRawToHTML(
                                                JSON.parse(
                                                  displayDescription(
                                                    product.description[
                                                      language
                                                    ]
                                                  )
                                                )
                                              )
                                            )}
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Card>
                                  <div>
                                    <Button
                                      onClick={async () => {
                                        await this.setState({
                                          selectedProduct: product,
                                        });
                                        await this.handleOpenProductModal();
                                      }}
                                      type="primary"
                                      css={{
                                        position: 'absolute',
                                        bottom: '-8px',
                                        right: '-10px',
                                        padding: '0px',
                                        height: '32px',
                                        width: '32px',
                                        borderRadius: '50%',
                                        boxShadow:
                                          '0px 4px 12px 0px rgba(245, 29, 44, 0.25)',
                                      }}
                                    >
                                      <PlusOutlined />
                                    </Button>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </Row>
                      </Col>
                    ))}
                  </Col>
                </Row>
              </Col>
              <div
                // xs={12}
                // sm={12}
                // md={8}
                // lg={8}
                // xl={8}
                // xxl={8}
                style={{
                  backgroundColor: '#fff9d9',
                  padding: '30px',
                  paddingRight: '45px',
                  maxWidth: '33.3%',
                  flex: 1,
                  // margin: '0px auto',
                }}
              >
                <Row>
                  <h2>{language === 'zh' ? '购物车' : 'Cart'}</h2>
                </Row>
                <div
                  style={{
                    paddingTop: '20px',
                    // padding: '20px',
                    // backgroundColor: '#fff9d9',
                    // border: '1px solid #cccfd5',
                    // borderRadius: '3px',
                  }}
                >
                  {token ? (
                    <React.Fragment>
                      <div className="common-selection-margin-b ">
                        <h2>
                          <FormattedMessage id="cateringAddress.title" />
                        </h2>
                        <div className="common-selection-outer-container ">
                          <div
                            className={
                              expandedControl.expandedAddress
                                ? 'common-selection-container float'
                                : 'common-selection-container'
                            }
                          >
                            <div
                              className="common-selection-inner-container"
                              role="presentation"
                              onClick={() => {
                                this.setState({
                                  expandedControl: {
                                    ...this.EXPANDED_CONTROL_INITIAL,
                                    expandedAddress:
                                      !expandedControl.expandedAddress,
                                  },
                                });
                              }}
                            >
                              {selectedAddressDetail ? (
                                <span
                                  style={{ color: 'black', cursor: 'pointer' }}
                                >
                                  {selectedAddressDetail.addressOne}{' '}
                                  {selectedAddressDetail.addressTwo},{' '}
                                  {selectedAddressDetail.city}
                                </span>
                              ) : (
                                <FormattedMessage id="cateringAddress.placeholder" />
                              )}
                              <EditOutlined
                                style={{ color: 'black', paddingLeft: '8px' }}
                              />
                            </div>
                            {expandedControl.expandedAddress && (
                              <div
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '1.5',
                                  backgroundColor: '#FFFFFF',
                                  // borderRadius: '25px',
                                  // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
                                  width: '100%',
                                  padding: '15px',
                                  // position: 'absolute',
                                  // top: '40px',
                                  // left: '0px',
                                  // zIndex: 1,
                                }}
                                css={{
                                  '.ant-select-selector': {
                                    border: '0px !important',
                                    padding: '0px !important',
                                  },
                                }}
                              >
                                <Select
                                  value={selectedAddress}
                                  style={{
                                    width: '100%',
                                    marginBottom: '30px',
                                  }}
                                  onChange={this.handleChangeAddressSelect}
                                >
                                  {addresses.map((address) => (
                                    <Option
                                      key={address._id}
                                      value={address._id}
                                    >
                                      {address.fullAddress}
                                    </Option>
                                  ))}
                                  <Option value="newAddress">
                                    <PlusOutlined /> Add New Address
                                  </Option>
                                </Select>
                                {addresses.length > 0 &&
                                selectedAddress !== 'newAddress' ? (
                                  <div style={{ width: '100%' }}>
                                    <CateringAddressForm
                                      admin={false}
                                      address={selectedAddressDetail}
                                      selectedAddress={selectedAddress}
                                      handleFetchAddresses={
                                        this.handleFetchAddresses
                                      }
                                      handleSubmit={() => {
                                        this.handleAddressSubmit();
                                      }}
                                      handleCancel={() => {
                                        this.handleAddressCancel();
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {addresses.length === 0 ||
                                selectedAddress === 'newAddress' ? (
                                  <div style={{ width: '100%' }}>
                                    <CateringAddressForm
                                      admin={false}
                                      selectedAddress={selectedAddress}
                                      handleFetchAddresses={
                                        this.handleFetchAddresses
                                      }
                                      handleSubmit={() => {
                                        this.handleAddressSubmit();
                                      }}
                                      handleCancel={() => {
                                        this.handleAddressCancel();
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {cart.length > 0 && (
                        <React.Fragment>
                          <div
                            style={{ marginTop: '15px' }}
                            className="common-selection-margin-b"
                          >
                            {cart.map((item) => {
                              let variant;
                              if (item.isVariant) {
                                variant = this.findVariant(
                                  item.product._id,
                                  item.variantId
                                );
                              }
                              return item.isVariant ? (
                                <div
                                  key={item.id}
                                  className="shopping-cart-item-container"
                                >
                                  {/* <div>
                                    <div>{item.quantity}</div>
                                  </div> */}
                                  <div style={{ flex: 1 }}>
                                    <img
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '15px',
                                      }}
                                      src={item.picture}
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    style={{ flex: 2, padding: '0px 10px' }}
                                    css={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      position: 'relative',
                                    }}
                                  >
                                    <CloseCircleFilled
                                      onClick={() => {
                                        this.handleRemoveProductFromCart(item);
                                      }}
                                      style={{
                                        position: 'absolute',
                                        right: '-15px',
                                        top: '-15px',
                                        color: '#F51D2C',
                                        fontSize: '25px',
                                        borderRadius: '50%',
                                        boxShadow:
                                          '0px 4px 12px 0px rgba(245, 29, 44, 0.25)',
                                      }}
                                    />

                                    <div
                                      style={{
                                        color: '#3e90d6',
                                        fontWeight: '700',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {`${
                                        item.product.name[language]
                                      } - ${everyWordFirstLetterToUpperCase(
                                        variant.shape
                                      )}`}
                                    </div>

                                    <div
                                      css={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <MinusCircleFilled
                                        onClick={() => {
                                          this.handleDecrement(item);
                                        }}
                                        style={{
                                          fontSize: '20px',
                                          color: '#F51D2C',
                                          borderRadius: '50%',
                                          boxShadow:
                                            '0px 4px 12px 0px rgba(245, 29, 44, 0.25)',
                                        }}
                                      />
                                      <span
                                        style={{
                                          fontSize: '20px',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {item.quantity}
                                      </span>

                                      <PlusCircleFilled
                                        onClick={() => {
                                          this.handleIncrement(item);
                                        }}
                                        style={{
                                          fontSize: '20px',
                                          color: '#F51D2C',
                                          borderRadius: '50%',
                                          boxShadow:
                                            '0px 4px 12px 0px rgba(245, 29, 44, 0.25)',
                                        }}
                                      />
                                    </div>

                                    {/* <div
                                      style={{
                                        color: '#999',
                                        fontSize: '15px',
                                      }}
                                    >
                                      {`serves ${item.servesNumber}`}
                                    </div> */}
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <div style={{ fontSize: '12px' }}>{`${
                                        item.quantity * variant.numberOfUnits
                                      }${item?.product?.unitShape}, ${
                                        item?.product?.unitNumber
                                          ? item?.product?.unitNumber *
                                            variant.numberOfUnits *
                                            item.quantity
                                          : null
                                      }${item?.product?.unit}, ${
                                        language === 'en' ? ' serve ' : '可提供'
                                      }${item.servesNumber}${
                                        language === 'en' ? ' people ' : '人'
                                      }`}</div>
                                      <div
                                        style={{
                                          textAlign: 'right',
                                          color: '#1890FF',
                                          fontSize: '20px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {`$${Number.parseFloat(
                                          item.quantity * variant.listPrice
                                        ).toFixed(2)}`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <Row
                                  type="flex"
                                  key={item.id}
                                  style={{
                                    margin: '5px 0px 5px 0px',
                                    fontSize: '15px',
                                    borderBottom: '1px solid #e6e0c3',
                                    padding: '10px 0',
                                  }}
                                >
                                  <Col span={2}>
                                    <div>{item.quantity}</div>
                                  </Col>
                                  <Col span={10}>
                                    <div
                                      style={{
                                        color: '#3e90d6',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {item.product.name.en}
                                    </div>
                                    <div
                                      style={{
                                        color: '#999',
                                        fontSize: '15px',
                                      }}
                                    >
                                      {`serves ${item.servesNumber}`}
                                    </div>
                                  </Col>
                                  <Col span={10} style={{ textAlign: 'right' }}>
                                    {`$${Number.parseFloat(
                                      item.quantity * item.product.listPrice
                                    ).toFixed(2)}`}
                                  </Col>
                                  <Col span={2} style={{ textAlign: 'right' }}>
                                    <CloseOutlined
                                      onClick={() => {
                                        this.handleRemoveProductFromCart(item);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      <Row
                        style={{ marginTop: '10px' }}
                        className="common-selection-margin-b"
                      >
                        <h2>
                          <FormattedMessage id="cateringDeliveryTime.title" />
                        </h2>
                        <div className="common-selection-outer-container ">
                          <div
                            className={
                              expandedControl.expandedDeliveryTime
                                ? 'common-selection-container float'
                                : 'common-selection-container'
                            }
                          >
                            <div
                              className="common-selection-inner-container"
                              role="presentation"
                              onClick={() => {
                                this.setState({
                                  expandedControl: {
                                    ...this.EXPANDED_CONTROL_INITIAL,
                                    expandedDeliveryTime:
                                      !expandedControl.expandedDeliveryTime,
                                  },
                                });
                              }}
                            >
                              {deliveryDetailDate && deliveryDetailTime ? (
                                <span
                                  style={{ color: 'black', cursor: 'pointer' }}
                                >
                                  {`${moment(deliveryDetailDate).format(
                                    'MM/DD/YYYY'
                                  )}, ${moment(deliveryDetailTime).format(
                                    'HH:mm'
                                  )}`}
                                </span>
                              ) : (
                                <FormattedMessage id="cateringDeliveryTime.placeholder" />
                              )}
                              <EditOutlined
                                style={{ color: 'black', paddingLeft: '8px' }}
                              />
                            </div>
                            {expandedControl.expandedDeliveryTime && (
                              <CateringOrderCheckoutForm
                                admin={false}
                                address={selectedAddressDetail}
                                handleCheckMinimum={this.handleCheckMinimum}
                                fee={fee}
                                tip={{ tipAmount, tipPercentage, tipSelected }}
                                taxRate={taxRate}
                                subtotal={subtotal}
                                estHeadcount={estHeadcount}
                                calculateTip={this.calculateTip}
                                cart={cart}
                                handleSetHeadcount={this.handleSetHeadcount}
                                handleSuccessCreateOrder={
                                  this.handleSuccessCreateOrder
                                }
                                handleSubmit={(values) => {
                                  this.setState({
                                    deliveryDetailDate: values.scheduleDate,
                                    deliveryDetailTime: values.scheduleTime,
                                  });
                                  this.setState({
                                    expandedControl: {
                                      ...this.EXPANDED_CONTROL_INITIAL,
                                      expandedDeliveryTime:
                                        !expandedControl.expandedDeliveryTime,
                                    },
                                  });
                                  this.handleFetchDeliveryFee();
                                }}
                                handleCancel={() => {
                                  this.setState({
                                    expandedControl: {
                                      ...this.EXPANDED_CONTROL_INITIAL,
                                      expandedDeliveryTime:
                                        !expandedControl.expandedDeliveryTime,
                                    },
                                  });
                                }}
                                deliveryDetailDate={deliveryDetailDate}
                                deliveryDetailTime={deliveryDetailTime}
                              />
                            )}
                          </div>
                        </div>
                      </Row>
                    </React.Fragment>
                  ) : (
                    <Row>
                      <div
                        css={{
                          marginTop: '30px',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        Please <Link to={getCustomerPath('login')}>Login</Link>{' '}
                        for Order Catering
                      </div>
                    </Row>
                  )}
                  <Row
                    style={{ marginTop: '10px' }}
                    className="common-selection-margin-b"
                  >
                    <h2>
                      <FormattedMessage id="caterDeliveryDetail.title" />
                    </h2>
                    <div className="common-selection-outer-container ">
                      <div
                        className={
                          expandedControl.expandedDeliveryDetail
                            ? 'common-selection-container float'
                            : 'common-selection-container'
                        }
                      >
                        <div
                          className="common-selection-inner-container"
                          role="presentation"
                          onClick={() => {
                            this.setState({
                              expandedControl: {
                                ...this.EXPANDED_CONTROL_INITIAL,
                                expandedDeliveryDetail:
                                  !expandedControl.expandedDeliveryDetail,
                              },
                            });
                          }}
                        >
                          {deliveryNotes || headcount > 0 || notes ? (
                            <span
                              css={{
                                color: 'black',
                                maxHeight: '43px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                cursor: 'pointer',
                              }}
                            >
                              {headcount > 0 && (
                                <span> {`${headcount}人, `}</span>
                              )}
                              {deliveryNotes && (
                                <span>{`${deliveryNotes}, `}</span>
                              )}
                              {notes && <span>{`${notes}`}</span>}
                            </span>
                          ) : (
                            <FormattedMessage id="caterDeliveryDetail.placeholder" />
                          )}
                          <EditOutlined
                            style={{ color: 'black', paddingLeft: '8px' }}
                          />
                        </div>
                        {expandedControl.expandedDeliveryDetail && (
                          <div style={{ paddingBottom: '15px' }}>
                            <div style={{ paddingBottom: '8px' }}>
                              <div style={{ paddingBottom: '8px' }}>
                                {' '}
                                <FormattedMessage id="caterDeliveryDetail.numberOfPeople" />
                                :
                              </div>
                              <Input
                                type="number"
                                placeholder={
                                  language === 'en'
                                    ? 'Please enter number of people'
                                    : '请输入用餐人数'
                                }
                                value={headcount}
                                onChange={(e) => {
                                  this.setState({
                                    headcount: e.nativeEvent.target.value,
                                  });
                                }}
                                suffix={
                                  <div>
                                    {language === 'en'
                                      ? 'Price Per Head'
                                      : '每人'}
                                    :{' $'}
                                    {subtotal !== 0 &&
                                      headcount > 0 &&
                                      (
                                        (subtotal +
                                          (subtotal + coupon.effect) * taxRate +
                                          this.calculateTip() +
                                          coupon.effect) /
                                        headcount
                                      ).toFixed(2)}
                                    {subtotal !== 0 &&
                                      headcount <= 0 &&
                                      (
                                        (subtotal +
                                          (subtotal + coupon.effect) * taxRate +
                                          this.calculateTip() +
                                          coupon.effect) /
                                        estHeadcount
                                      ).toFixed(2)}
                                    {/* {subtotal === 0 && 0} */}
                                  </div>
                                }
                              />
                            </div>
                            <div style={{ paddingBottom: '8px' }}>
                              <div style={{ paddingBottom: '8px' }}>
                                <FormattedMessage id="caterDeliveryDetail.deliveryNote" />
                                :
                              </div>
                              <TextArea
                                placeholder={
                                  language === 'en'
                                    ? 'Notes for driver'
                                    : '（告诉司机如何快速为您送达）'
                                }
                                autoSize={{ minRows: 2 }}
                                value={deliveryNotes}
                                onChange={(e) => {
                                  this.setState({
                                    deliveryNotes: e.nativeEvent.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div style={{ paddingBottom: '8px' }}>
                              <div style={{ paddingBottom: '8px' }}>
                                {' '}
                                <FormattedMessage id="caterDeliveryDetail.note" />
                                :
                              </div>
                              <TextArea
                                placeholder={
                                  language === 'en'
                                    ? 'Food notes'
                                    : '（您对食物有何特殊需求）'
                                }
                                autoSize={{ minRows: 4 }}
                                value={notes}
                                onChange={(e) => {
                                  this.setState({
                                    notes: e.nativeEvent.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div
                              css={{
                                button: {
                                  borderRadius: '25px',
                                  width: '100%',
                                  maxWidth: '200px',
                                },
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '15px',
                              }}
                            >
                              <Button
                                type="primary"
                                onClick={() => {
                                  this.setState({
                                    expandedControl: {
                                      ...this.EXPANDED_CONTROL_INITIAL,
                                      expandedDeliveryDetail:
                                        !expandedControl.expandedDeliveryDetail,
                                    },
                                  });
                                }}
                              >
                                <FormattedMessage id="deliveryDetailNoteSubmit" />
                              </Button>
                              <Button
                                css={{ marginLeft: '15px' }}
                                onClick={() => {
                                  this.setState({
                                    expandedControl: {
                                      ...this.EXPANDED_CONTROL_INITIAL,
                                      expandedDeliveryDetail:
                                        !expandedControl.expandedDeliveryDetail,
                                    },
                                  });
                                }}
                              >
                                <FormattedMessage id="deliveryDetailNoteClear" />
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                  <Divider
                    style={{ borderTop: '2px solid rgba(0, 0, 0, 0.06)' }}
                  />
                  {cart.length > 0 && (
                    <div
                      css={{
                        '.ant-row': {
                          marginBottom: '8px !important',
                        },
                      }}
                    >
                      <Row
                        style={{
                          fontSize: '15px',
                          lineHeight: '1.5',
                          fontWeight: '400',
                          padding: '0 20px',
                        }}
                      >
                        <Col span={12}>
                          <FormattedMessage id="cateringPayment.subtotal" />
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={12}>
                          {`$${Number.parseFloat(subtotal).toFixed(2)}`}
                        </Col>
                      </Row>
                      {/* <Row
                        style={{
                          fontSize: '15px',
                          lineHeight: '1.5',
                          fontWeight: '400',
                          padding: '0 20px',
                        }}
                      >
                        <Col span={12}>
                          {' '}
                          <FormattedMessage id="cateringPayment.tax" />
                          {` (${taxRate * 100}%)`}
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={12}>
                          {`$${Number.parseFloat(
                            (subtotal + coupon.effect) * taxRate
                          ).toFixed(2)}`}
                        </Col>
                      </Row> */}
                      <Row
                        style={{
                          fontSize: '15px',
                          lineHeight: '1.5',
                          fontWeight: '400',
                          padding: '0 20px 10px 20px',
                          borderBottom: '1px solid #e6e0c3',
                        }}
                      >
                        <Col span={24}>
                          <CouponInput
                            orderSubTotal={subtotal}
                            setCoupon={(v) => this.setState({ coupon: v })}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{
                          fontSize: '15px',
                          lineHeight: '1.5',
                          fontWeight: '400',
                          padding: '0 20px 10px 20px',
                          borderBottom: '1px solid #e6e0c3',
                        }}
                      >
                        <Col span={12}>
                          {' '}
                          <FormattedMessage id="cateringPayment.tip" />
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={12}>
                          {tipSelected === 'none' ? '$0' : null}
                          {tipSelected === 'other'
                            ? `$${tipAmount.toFixed(2)}`
                            : null}
                          {tipSelected !== 'other' && tipSelected !== 'none'
                            ? `$${(tipPercentage * subtotal).toFixed(2)}`
                            : null}
                        </Col>
                        <Col
                          span={24}
                          style={{ textAlign: 'center', padding: '5px 0' }}
                        >
                          <Radio.Group
                            style={{
                              borderRadius: '25px',
                              overflow: 'hidden',
                              border: '1px solid #d9d9d9',
                              minWidth: '291px',
                            }}
                            css={{
                              '.ant-radio-button-wrapper': {
                                border: '0px',
                              },
                            }}
                            onChange={this.handleTipSelected}
                            defaultValue="a"
                            value={tipSelected}
                            buttonStyle="solid"
                          >
                            <Radio.Button value="0.1">10%</Radio.Button>
                            <Radio.Button value="0.15">15%</Radio.Button>
                            <Radio.Button value="0.2">20%</Radio.Button>
                            <Radio.Button
                              onClick={() => {
                                this.handleOpenTipModal();
                              }}
                              value="other"
                            >
                              <FormattedMessage id="cateringPayment.otherAmount" />
                            </Radio.Button>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          fontSize: '20px',
                          lineHeight: '1.5',
                          fontWeight: '700',
                          padding: '0 20px',
                        }}
                      >
                        <Col span={12}>
                          <FormattedMessage id="cateringPayment.total" />
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={12}>
                          {`$${Number.parseFloat(
                            subtotal + this.calculateTip() + coupon.effect
                          ).toFixed(2)}`}
                        </Col>
                      </Row>
                      <Row
                        style={{
                          lineHeight: '1.5',
                          padding: '0 20px',
                        }}
                      >
                        *
                        {language === 'zh'
                          ? '税费与运送费会在结账页面计算'
                          : 'Tax and delivery fee will be calculated in the next step'}
                      </Row>
                      <Row
                        style={{
                          visibility: headcount > 0 ? 'visible' : 'hidden',
                          fontSize: '14px',
                          lineHeight: '1.5',
                          fontWeight: '400',
                          padding: '0 20px',
                        }}
                      >
                        <Col span={12}>
                          <FormattedMessage id="cateringPayment.pricePerHead" />
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={12}>
                          {headcount > 0 ? (
                            <React.Fragment>
                              {`$${Number.parseFloat(
                                (subtotal +
                                  (subtotal + coupon.effect) * taxRate +
                                  fee +
                                  this.calculateTip() +
                                  coupon.effect) /
                                  headcount || 0
                              ).toFixed(2)}`}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {`$${Number.parseFloat(
                                (subtotal +
                                  (subtotal + coupon.effect) * taxRate +
                                  fee +
                                  this.calculateTip() +
                                  coupon.effect) /
                                  estHeadcount || 0
                              ).toFixed(2)}`}
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}

                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                      disabled={
                        this.handleCheckoutButtonDisable() || checkoutPending
                      }
                      onClick={async () => {
                        await this.handleSubmitOrder();
                      }}
                      type="primary"
                      css={{
                        width: '80%',
                        maxWidth: '200px',
                        borderRadius: '25px',
                        height: '40px',
                      }}
                    >
                      <FormattedMessage id="confirmOrderButton" />
                    </Button>
                  </div>

                  <div
                    style={{
                      marginTop: '8px',
                      textAlign: 'center',
                      color: '#F51D2C',
                      fontSize: '12px',
                    }}
                  >
                    <div>
                      $100.00 <FormattedMessage id="minimumForDelivery" />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

CateringMenu.defaultProps = {
  token: null,
};

CateringMenu.propTypes = {
  updateTip: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  clearItems: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tipAmount: PropTypes.number.isRequired,
  tipSelected: PropTypes.string.isRequired,
  tipPercentage: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  items: state.cart.items,
  tipAmount: state.cart.tipAmount,
  tipSelected: state.cart.tipSelected,
  tipPercentage: state.cart.tipPercentage,
  language: state.language.lang,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  updateTip: (tip) => dispatch(actions.updateTip(tip)),
  removeItem: (item) => dispatch(actions.removeItem(item)),
  clearItems: () => dispatch(actions.clearItems()),
  addItem: (item) => dispatch(actions.addItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CateringMenu);
