import gql from 'graphql-tag';
import { VARIANT_DASHBOARD_FRAGMENT } from './variant';

const DISCOUNT_FRAGMENT = gql`
  fragment discountFragment on Discount {
    _id
    bunbaoCategory
    valueType
    appliedType
    minimumAmountSpent
    maximumAmountSpent
    minimumBoxesRequired
    maximumBoxesRequired
    applyNumberOfBoxes
    repeatNumberBoxes
    amount
    usageLimit
    used
    appliedOrderIds
    freeVariantId
    freeVariant {
      ...VARIANT_DASHBOARD_FRAGMENT
    }
    code
    name {
      _id
      zh
      en
    }
    orderTypeLimitation
    startDate
    endDate
    ownerId
    appliedOrderIds
    appliedOrders {
      _id
      displayId
    }
    appliedUserIds
    allowedVariantIds
    excludeVariantIds
    allowedRemarks
    allowedVariants {
      _id
      name {
        _id
        zh
        en
      }
      remark
      remarkName {
        zh
        en
      }
      discountRemark
      remarkSelections
    }
    excludeVariants {
      _id
      name {
        _id
        zh
        en
      }
      remark
      remarkName {
        zh
        en
      }
      discountRemark
      remarkSelections
    }
    appliedTimePerUser
    newUserOnly
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

const DISCOUNT_DASHBOARD_FRAGMENT = gql`
  fragment discountDashboardFragment on Discount {
    _id
    bunbaoCategory
    valueType
    appliedType
    minimumAmountSpent
    maximumAmountSpent
    minimumBoxesRequired
    maximumBoxesRequired
    applyNumberOfBoxes
    repeatNumberBoxes
    amount
    usageLimit
    used
    freeVariantId
    freeVariant {
      ...VARIANT_DASHBOARD_FRAGMENT
    }
    appliedOrderIds
    allowedWarehouseIds
    allowedWarehouses {
      _id
      name
    }
    code
    name {
      _id
      zh
      en
    }
    orderTypeLimitation
    startDate
    endDate
    ownerId
    owner {
      _id
      email
    }
    appliedOrderIds
    appliedOrders {
      _id
      createdAt
      displayId
      subtotal
      tax
      deliveryFee
      total
      userEmail
      discountAmount
      address {
        _id
        addressOne
        city
        firstName
        lastName
      }
    }
    appliedUserIds
    allowedVariantIds
    excludeVariantIds
    allowedRemarks
    allowedVariants {
      _id
      name {
        _id
        zh
        en
      }
      remark
      remarkName {
        zh
        en
      }
      discountRemark
      remarkSelections
    }
    excludeVariants {
      _id
      name {
        _id
        zh
        en
      }
      remark
      remarkName {
        zh
        en
      }
      discountRemark
      remarkSelections
    }
    appliedTimePerUser
    newUserOnly
    remark
    record
    discountRemarks
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

export const FETCH_DISCOUNT = gql`
  query ($filter: FilterFindManyDiscountInput) {
    discountFindMany(filter: $filter) {
      ...discountDashboardFragment
    }
  }
  ${DISCOUNT_DASHBOARD_FRAGMENT}
`;

export const CURRENT_USER_BEST_COUPON = gql`
  query {
    currentUserBestDiscount {
      ...discountDashboardFragment
    }
  }
  ${DISCOUNT_DASHBOARD_FRAGMENT}
`;

export const FETCH_DISCOUNT_DOWNLOAD_CSV = gql`
  query ($filter: FilterFindManyDiscountInput) {
    discountFindMany(filter: $filter) {
      _id
      code
      usageLimit
      used
      appliedOrders {
        _id
        createdAt
        displayId
        subtotal
        tax
        deliveryFee
        total
        userEmail
        discountAmount
        address {
          _id
          addressOne
          city
          firstName
          lastName
        }
      }
    }
  }
`;

export const FETCH_DISCOUNT_PAGINATION = gql`
  query ($page: Int!, $perPage: Int!, $filter: FilterFindManyDiscountInput) {
    discountPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: CREATEDAT_DESC
    ) {
      items {
        ...discountDashboardFragment
      }
      count
    }
  }
  ${DISCOUNT_DASHBOARD_FRAGMENT}
`;

export const FETCH_DISCOUNT_PAGINATION_NO_COUNT = gql`
  query ($page: Int!, $perPage: Int!, $filter: FilterFindManyDiscountInput) {
    discountPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...discountDashboardFragment
      }
    }
  }
  ${DISCOUNT_DASHBOARD_FRAGMENT}
`;

export const FETCH_DISCOUNT_ONE = gql`
  query ($filter: FilterFindOneDiscountInput) {
    discountFindOne(filter: $filter) {
      ...discountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const CREATE_DISCOUNT = gql`
  mutation ($input: CreateOneDiscountInput!) {
    discountCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_DISCOUNT = gql`
  mutation ($input: UpdateOneDiscountInput!, $_id: MongoID) {
    discountUpdateOne(input: { record: $input, filter: { _id: $_id } }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_DISCOUNT_MANY = gql`
  mutation (
    $filter: FilterUpdateManyDiscountInput!
    $allowedVariantIds: [MongoID]
    $allowedRemarks: [String]
    $newUserOnly: Boolean
  ) {
    discountUpdateMany(
      input: {
        filter: $filter
        record: {
          allowedVariantIds: $allowedVariantIds
          allowedRemarks: $allowedRemarks
          newUserOnly: $newUserOnly
        }
      }
    ) {
      numAffected
      nodeId
    }
  }
`;

export const CURRENT_USER_COUPON = gql`
  query {
    userCurrent {
      _id
      personalDiscount {
        _id
        name {
          _id
          zh
          en
        }
        code
        startDate
        endDate
        usageLimit
        valueType
        amount
        used
        usageLimit
        appliedOrderIds
        allowedRemarks
        minimumAmountSpent
        bunbaoCategory
      }
    }
  }
`;

export const CURRENT_USER_DISCOUNTS = gql`
  query {
    currentUserDiscounts {
      _id
      name {
        _id
        zh
        en
      }
      code
      startDate
      endDate
      usageLimit
      valueType
      amount
      used
      usageLimit
      appliedOrderIds
      allowedRemarks
      minimumAmountSpent
      bunbaoCategory
    }
  }
`;

export const DISCOUNT_DUPLICATE = gql`
  mutation ($discountId: MongoID!, $totalNumber: Int!) {
    discountDuplicate(discountId: $discountId, totalNumber: $totalNumber) {
      ...discountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;
