import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  columnsStateMaps: {},
};

export const columnsStateMapUpdate = (state, action) => {
  const { tableName } = action;

  const updateState = {
    columnsStateMaps: {
      ...state.columnsStateMaps,
      [tableName]: action.columnsStateMap,
    },
  };
  return updateObject(state, updateState);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TABLE_COLUMNS_STATE_UPDATE:
      return columnsStateMapUpdate(state, action);
    default:
      return state;
  }
};
