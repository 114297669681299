import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
// import PropTypes from 'prop-types';

export class TermsOfService extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <div
        style={
          isMobile
            ? { padding: '10px' }
            : { padding: '30px 50px', minHeight: '100vh' }
        }
      >
        <h2>BUNBAO.COM Terms of Service</h2>
        <p>
          These Terms of Service govern your use of the website located at{' '}
          <a href="https://bunbao.com">https://bunbao.com</a> and any related
          services provided by BUNBAO.COM.
        </p>
        <p>
          By accessing <a href="https://bunbao.com">https://bunbao.com</a>, you
          agree to abide by these Terms of Service and to comply with all
          applicable laws and regulations. If you do not agree with these Terms
          of Service, you are prohibited from using or accessing this website or
          using any other services provided by BUNBAO.COM.
        </p>
        <p>
          We, BUNBAO.COM, reserve the right to review and amend any of these
          Terms of Service at our sole discretion. Upon doing so, we will update
          this page. Any changes to these Terms of Service will take effect
          immediately from the date of publication.
        </p>
        <p>These Terms of Service were last updated on 24 May 2021.</p>
        <h3>Limitations of Use</h3>
        <p>
          By using this website, you warrant on behalf of yourself, your users,
          and other parties you represent that you will not:
        </p>
        <ol>
          <li>
            modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </li>
          <li>
            remove any copyright or other proprietary notations from any
            materials and software on this website;
          </li>
          <li>
            transfer the materials to another person or “mirror” the materials
            on any other server;
          </li>
          <li>
            knowingly or negligently use this website or any of its associated
            services in a way that abuses or disrupts our networks or any other
            service BUNBAO.COM provides;
          </li>
          <li>
            use this website or its associated services to transmit or publish
            any harassing, indecent, obscene, fraudulent, or unlawful material;
          </li>
          <li>
            use this website or its associated services in violation of any
            applicable laws or regulations;
          </li>
          <li>
            use this website in conjunction with sending unauthorized
            advertising or spam;
          </li>
          <li>
            harvest, collect, or gather user data without the user’s consent; or
          </li>
          <li>
            use this website or its associated services in such a way that may
            infringe the privacy, intellectual property rights, or other rights
            of third parties.
          </li>
        </ol>
        <h3>Intellectual Property</h3>
        <p>
          The intellectual property in the materials contained in this website
          are owned by or licensed to BUNBAO.COM and are protected by applicable
          copyright and trademark law. We grant our users permission to download
          one copy of the materials for personal, non-commercial transitory use.
        </p>
        <p>
          This constitutes the grant of a license, not a transfer of title. This
          license shall automatically terminate if you violate any of these
          restrictions or the Terms of Service, and may be terminated by
          BUNBAO.COM at any time.
        </p>
        <h3>User-Generated Content</h3>
        <p>
          You retain your intellectual property ownership rights over content
          you submit to us for publication on our website. We will never claim
          ownership of your content, but we do require a license from you in
          order to use it.
        </p>
        <p>
          When you use our website or its associated services to post, upload,
          share, or otherwise transmit content covered by intellectual property
          rights, you grant to us a non-exclusive, royalty-free, transferable,
          sub-licensable, worldwide license to use, distribute, modify, run,
          copy, publicly display, translate, or otherwise create derivative
          works of your content in a manner that is consistent with your privacy
          preferences and our Privacy Policy.
        </p>
        <p>
          The license you grant us can be terminated at any time by deleting
          your content or account. However, to the extent that we (or our
          partners) have used your content in connection with commercial or
          sponsored content, the license will continue until the relevant
          commercial or post has been discontinued by us.
        </p>
        <p>
          You give us permission to use your username and other identifying
          information associated with your account in a manner that is
          consistent with your privacy preferences, and our Privacy Policy.
        </p>
        <h3>Liability</h3>
        <p>
          Our website and the materials on our website are provided on an
          &lsquo;as is&lsquo; basis. To the extent permitted by law, BUNBAO.COM
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties including, without limitation, implied
          warranties or conditions of merchantability, fitness for a particular
          purpose, or non-infringement of intellectual property, or other
          violation of rights.
        </p>
        <p>
          In no event shall BUNBAO.COM or its suppliers be liable for any
          consequential loss suffered or incurred by you or any third party
          arising from the use or inability to use this website or the materials
          on this website, even if BUNBAO.COM or an authorized representative
          has been notified, orally or in writing, of the possibility of such
          damage.
        </p>
        <p>
          In the context of this agreement, &ldquo;consequential loss&rdquo;
          includes any consequential loss, indirect loss, real or anticipated
          loss of profit, loss of benefit, loss of revenue, loss of business,
          loss of goodwill, loss of opportunity, loss of savings, loss of
          reputation, loss of use and/or loss or corruption of data, whether
          under statute, contract, equity, tort (including negligence),
          indemnity, or otherwise.
        </p>
        <p>
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
        <h3>Accuracy of Materials</h3>
        <p>
          The materials appearing on our website are not comprehensive and are
          for general information purposes only. BUNBAO.COM does not warrant or
          make any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on this website, or otherwise
          relating to such materials or on any resources linked to this website.
        </p>
        <h3>Links</h3>
        <p>
          BUNBAO.COM has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement, approval, or control
          by BUNBAO.COM of the site. Use of any such linked site is at your own
          risk and we strongly advise you make your own investigations with
          respect to the suitability of those sites.
        </p>
        <h3>Right to Terminate</h3>
        <p>
          We may suspend or terminate your right to use our website and
          terminate these Terms of Service immediately upon written notice to
          you for any breach of these Terms of Service.
        </p>
        <h3>Severance</h3>
        <p>
          Any term of these Terms of Service which is wholly or partially void
          or unenforceable is severed to the extent that it is void or
          unenforceable. The validity of the remainder of these Terms of Service
          is not affected.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms of Service are governed by and construed in accordance
          with the laws of California. You irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </p>

        <h2>BUNBAO.COM Acceptable Use Policy</h2>
        <p>
          This acceptable use policy covers the products, services, and
          technologies (collectively referred to as the &ldquo;Products&rdquo;)
          provided by BUNBAO.COM under any ongoing agreement. It’s designed to
          protect us, our customers, and the general Internet community from
          unethical, irresponsible, and illegal activity.
        </p>
        <p>
          BUNBAO.COM customers found engaging in activities prohibited by this
          acceptable use policy can be liable for service suspension and account
          termination. In extreme cases, we may be legally obliged to report
          such customers to the relevant authorities.
        </p>
        <p>This policy was last reviewed on 24 May 2021.</p>
        <h3>Fair use</h3>
        <p>
          We provide our facilities with the assumption your use will be
          &ldquo;business as usual&rdquo;, as per our offer schedule. If your
          use is considered to be excessive, then additional fees may be
          charged, or capacity may be restricted.
        </p>
        <p>
          We are opposed to all forms of abuse, discrimination, rights
          infringement, and/or any action that harms or disadvantages any group,
          individual, or resource. We expect our customers and, where
          applicable, their users (&ldquo;end-users&rdquo;) to likewise engage
          our Products with similar intent.
        </p>
        <h3>Customer accountability</h3>
        <p>
          We regard our customers as being responsible for their own actions as
          well as for the actions of anyone using our Products with the
          customer’s permission. This responsibility also applies to anyone
          using our Products on an unauthorized basis as a result of the
          customer’s failure to put in place reasonable security measures.
        </p>
        <p>
          By accepting Products from us, our customers agree to ensure adherence
          to this policy on behalf of anyone using the Products as their end
          users. Complaints regarding the actions of customers or their
          end-users will be forwarded to the nominated contact for the account
          in question.
        </p>
        <p>
          If a customer — or their end-user or anyone using our Products as a
          result of the customer — violates our acceptable use policy, we
          reserve the right to terminate any Products associated with the
          offending account or the account itself or take any remedial or
          preventative action we deem appropriate, without notice. To the extent
          permitted by law, no credit will be available for interruptions of
          service resulting from any violation of our acceptable use policy.
        </p>
        <h3>Prohibited activity</h3>
        <h4>Copyright infringement and access to unauthorized material</h4>
        <p>
          Our Products must not be used to transmit, distribute or store any
          material in violation of any applicable law. This includes but isn’t
          limited to:
        </p>
        <ol type="i">
          <li>
            any material protected by copyright, trademark, trade secret, or
            other intellectual property right used without proper authorization,
            and
          </li>
          <li>
            any material that is obscene, defamatory, constitutes an illegal
            threat or violates export control laws.
          </li>
        </ol>
        <p>
          The customer is solely responsible for all material they input,
          upload, disseminate, transmit, create or publish through or on our
          Products, and for obtaining legal permission to use any works included
          in such material.
        </p>
        <h4>SPAM and unauthorized message activity</h4>
        <p>
          Our Products must not be used for the purpose of sending unsolicited
          bulk or commercial messages in violation of the laws and regulations
          applicable to your jurisdiction (“spam”). This includes but isn’t
          limited to sending spam, soliciting customers from spam sent from
          other service providers, and collecting replies to spam sent from
          other service providers.
        </p>
        <p>
          Our Products must not be used for the purpose of running unconfirmed
          mailing lists or telephone number lists (&ldquo;messaging
          lists&rdquo;). This includes but isn’t limited to subscribing email
          addresses or telephone numbers to any messaging list without the
          permission of the email address or telephone number owner, and storing
          any email addresses or telephone numbers subscribed in this way. All
          messaging lists run on or hosted by our Products must be
          &ldquo;confirmed opt-in&rdquo;. Verification of the address or
          telephone number owner’s express permission must be available for the
          lifespan of the messaging list.
        </p>
        <p>
          We prohibit the use of email lists, telephone number lists or
          databases purchased from third parties intended for spam or
          unconfirmed messaging list purposes on our Products.
        </p>
        <p>
          This spam and unauthorized message activity policy applies to messages
          sent using our Products, or to messages sent from any network by the
          customer or any person on the customer’s behalf, that directly or
          indirectly refer the recipient to a site hosted via our Products.
        </p>
        <h4>Unethical, exploitative, and malicious activity</h4>
        <p>
          Our Products must not be used for the purpose of advertising,
          transmitting, or otherwise making available any software, program,
          product, or service designed to violate this acceptable use policy, or
          the acceptable use policy of other service providers. This includes
          but isn’t limited to facilitating the means to send spam and the
          initiation of network sniffing, pinging, packet spoofing, flooding,
          mail-bombing, and denial-of-service attacks.
        </p>
        <p>
          Our Products must not be used to access any account or electronic
          resource where the group or individual attempting to gain access does
          not own or is not authorized to access the resource (e.g.
          &ldquo;hacking&rdquo;, &ldquo;cracking&rdquo;,
          &ldquo;phreaking&rdquo;, etc.).
        </p>
        <p>
          Our Products must not be used for the purpose of intentionally or
          recklessly introducing viruses or malicious code into our Products and
          systems.
        </p>
        <p>
          Our Products must not be used for purposely engaging in activities
          designed to harass another group or individual. Our definition of
          harassment includes but is not limited to denial-of-service attacks,
          hate-speech, advocacy of racial or ethnic intolerance, and any
          activity intended to threaten, abuse, infringe upon the rights of, or
          discriminate against any group or individual.
        </p>
        <p>
          Other activities considered unethical, exploitative, and malicious
          include:
        </p>
        <ol>
          <li>
            Obtaining (or attempting to obtain) services from us with the intent
            to avoid payment;
          </li>
          <li>
            Using our facilities to obtain (or attempt to obtain) services from
            another provider with the intent to avoid payment;
          </li>
          <li>
            The unauthorized access, alteration, or destruction (or any attempt
            thereof) of any information about our customers or end-users, by any
            means or device;
          </li>
          <li>
            Using our facilities to interfere with the use of our facilities and
            network by other customers or authorized individuals;
          </li>
          <li>
            Publishing or transmitting any content of links that incite
            violence, depict a violent act, depict child pornography, or
            threaten anyone’s health and safety;
          </li>
          <li>
            Any act or omission in violation of consumer protection laws and
            regulations;
          </li>
          <li>Any violation of a person’s privacy.</li>
        </ol>
        <p>
          Our Products may not be used by any person or entity, which is
          involved with or suspected of involvement in activities or causes
          relating to illegal gambling; terrorism; narcotics trafficking; arms
          trafficking or the proliferation, development, design, manufacture,
          production, stockpiling, or use of nuclear, chemical or biological
          weapons, weapons of mass destruction, or missiles; in each case
          including any affiliation with others whatsoever who support the above
          such activities or causes.
        </p>
        <h4>Unauthorized use of BUNBAO.COM property</h4>
        <p>
          We prohibit the impersonation of BUNBAO.COM, the representation of a
          significant business relationship with BUNBAO.COM, or ownership of any
          BUNBAO.COM property (including our Products and brand) for the purpose
          of fraudulently gaining service, custom, patronage, or user trust.
        </p>
        <h3>About this policy</h3>
        <p>
          This policy outlines a non-exclusive list of activities and intent we
          deem unacceptable and incompatible with our brand.
        </p>
        <p>
          We reserve the right to modify this policy at any time by publishing
          the revised version on our website. The revised version will be
          effective from the earlier of:
        </p>
        <ul>
          <li>
            the date the customer uses our Products after we publish the revised
            version on our website; or
          </li>
          <li>30 days after we publish the revised version on our website.</li>
        </ul>
      </div>
    );
  }
}

export default TermsOfService;
