import gql from 'graphql-tag';

export const GET_USER_ID_BY_EMAIL = gql`
  query ($input: String!) {
    userByEmail(email: $input) {
      id
    }
  }
`;

export const GET_USER_INFO_BY_ID = gql`
  query ($id: ID!) {
    user(id: $id) {
      email
      isVip
    }
  }
`;

export const UPDATE_USER_BY_ID = gql`
  mutation ($id: String!) {
    userUpdateBunBaoOneUser(id: $id) {
      id
    }
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    customerRegister(input: { email: $email, password: $password }) {
      user {
        token
      }
      errors {
        message
      }
    }
  }
`;

export const DELETE_CARD = gql`
  mutation ($input: String!) {
    paymentDeleteCard(cardId: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($input: BunbaoPasswordResetInput!) {
    bunbaoPasswordReset(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

export const GET_RESET_LINK = gql`
  mutation ($input: CustomerPasswordResetInput!) {
    customerPasswordReset(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;
