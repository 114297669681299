import moment from 'moment';

export const addressFormatter = (address) => {
  if (!address) {
    return '';
  }
  let finalAddress = '';
  try {
    if (address.firstName && address.lastName) {
      finalAddress += `${address.firstName} ${address.lastName}`;
    }
    if (address.addressOne) {
      if (address.firstName && address.lastName) {
        finalAddress += ', ';
      }
      finalAddress += address.addressOne;
    }
    if (address.addressTwo) {
      if (address.addressOne) {
        finalAddress += ', ';
      }
      finalAddress += address.addressTwo;
    }
    if (address.city) {
      finalAddress += `, ${address.city}`;
    }
    if (address.zipCode) {
      finalAddress += ` ${address.zipCode}`;
    }
    if (address.phoneNumber) {
      finalAddress += ` ${address.phoneNumber}`;
    }
  } catch (e) {
    console.error(e);
  }
  return finalAddress;
};

export const addressFormatterOnlyAddress = (address) => {
  if (!address) {
    return '';
  }
  let finalAddress = '';
  if (address.addressOne) {
    finalAddress += address.addressOne;
  }
  if (address.addressTwo) {
    if (address.addressOne) {
      finalAddress += ', ';
    }
    finalAddress += address.addressTwo;
  }
  if (address.city) {
    finalAddress += `, ${address.city}`;
  }
  if (address.zipCode) {
    finalAddress += ` ${address.zipCode}`;
  }
  return finalAddress;
};

export const addressFormatterOnlyName = (address) => {
  if (!address) {
    return '';
  }
  let finalAddress = '';
  if (address.firstName && address.lastName) {
    finalAddress += `${address.firstName} ${address.lastName}`;
  }
  if (address.phoneNumber) {
    finalAddress += ` ${address.phoneNumber}`;
  }
  return finalAddress;
};

export const hoursWindowsFormatter = (openHours) => {
  const hours = [];
  if (openHours) {
    openHours.forEach((e) => {
      hours.push({
        day: e.date.key || e.date,
        start: e.startTime.format('h:mma'),
        end: e.endTime.format('h:mma'),
      });
    });
  }
  return hours;
};

export const hoursWindowsReverseFormatter = (openHours) => {
  const hours = [];
  if (openHours) {
    openHours.forEach((e) => {
      hours.push({
        day: e.day,
        startTime: e.start,
        endTime: e.end,
      });
    });
  }
  return hours;
};

export const customerOrderFormatter = (input) => {
  const newInput = [];
  input.forEach((variant) => {
    const newVariant = {};
    if (variant.boxContent) {
      const newSubVariant = [];
      variant.boxContent.forEach((subVariant) => {
        newSubVariant.push({
          quantity: subVariant.count,
          variantId: subVariant._id,
        });
      });
      newVariant.subVariants = newSubVariant;
      newVariant.quantity = variant.count;
      newVariant.name = variant.name;
    } else {
      newVariant.variantId = variant._id;
      newVariant.quantity = variant.count;
    }
    newInput.push(newVariant);
  });
  return newInput;
};

export const discountErrorFormatter = (discount, errorMsg, intl) => {
  switch (errorMsg) {
    case 'errorDiscountBeforeDate': {
      return (
        intl.formatMessage({ id: 'errorDiscountBeforeDate' }) +
        moment(discount.startDate).format('YYYY-MM-DD')
      );
    }
    case 'errorDiscountAfterDate': {
      return (
        intl.formatMessage({ id: 'errorDiscountAfterDate' }) +
        moment(discount.endDate).format('YYYY-MM-DD')
      );
    }
    case 'errorDiscountLtMin': {
      return intl
        .formatMessage({ id: 'errorDiscountLtMin' })
        .replace('[$xx.xx]', `$${discount.minimumAmountSpent}`)
        .replace('[xxx]', discount.code);
    }
    case 'errorDiscountGtMax': {
      return intl
        .formatMessage({ id: 'errorDiscountGtMax' })
        .replace('[$xx.xx]', `$${discount.maximumAmountSpent}`)
        .replace('[xxx]', discount.code);
    }
    case 'errorDiscountLtBox': {
      return intl
        .formatMessage({ id: 'errorDiscountLtBox' })
        .replace('[$xx.xx]', discount.minimumBoxesRequired)
        .replace('[xxx]', discount.code);
    }
    case 'errorDiscountGtBox': {
      return intl
        .formatMessage({ id: 'errorDiscountGtBox' })
        .replace('[$xx.xx]', discount.maximumBoxesRequired)
        .replace('[xxx]', discount.code);
    }
    case 'errorDiscountGtLimit': {
      return intl
        .formatMessage({ id: 'errorDiscountGtLimit' })
        .replace('[xxx]', discount.code);
    }
    default: {
      return intl.formatMessage({ id: errorMsg });
    }
  }
};

export const pseudoTypeFormatter = (
  currentShoppingMode,
  currentAddressType
) => {
  if (currentShoppingMode === 'groupBuy') {
    return 'groupBuy';
  }
  if (currentShoppingMode === 'delivery') {
    if (currentAddressType === 'delivery') {
      return 'normalDelivery';
    }
    if (currentAddressType === 'pickup') {
      return 'fixLocation';
    }
  }
  if (currentShoppingMode === 'pickup') {
    return 'warehouse';
  }
  if (currentShoppingMode === 'eShop') {
    return 'eShop';
  }
  return 'unknown';
};

export const pickupTimeSlotFormatter = (time, window) => {
  const day = time.day();
  let res = time.format('MM/DD');
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  window.forEach((element) => {
    if (element.substring(0, 3) === weekdays[day]) {
      res += element;
    }
  });
  return res;
};

export const epochToLocalTimeString = (epoch) => {
  if (typeof epoch !== 'number') {
    return 'Invalid Time Format';
  }
  try {
    const result = new Date(epoch).toLocaleString();
    return result;
  } catch (error) {
    return 'Invalid Time Format';
  }
};
