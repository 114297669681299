import gql from 'graphql-tag';

const BANNER_FIND_MANY = gql`
  query ($filter: FilterFindManyBannerInput) {
    bannerFindMany(filter: $filter) {
      _id
      name
      description {
        _id
        zh
        en
      }
      videoIds {
        _id
        videoId
        language
      }
      isProduct
      banners {
        _id
        picture {
          _id
          key
        }
        device
        language
      }
      category
      productId
      isLink
      link
      deleted
      ranking
      createdAt
      isClickable
      isCategory
      categoryId
      bannerContent {
        _id
        en
        zh
      }
      color
    }
  }
`;

const BANNER_FIND_ONE_POST = gql`
  query ($filter: FilterFindOneBannerInput) {
    bannerFindOne(filter: $filter) {
      _id
      isOldUserPost
      isNewUserPost
      isLink
      link
      banners {
        _id
        picture {
          _id
          key
          category
        }
        device
        language
      }
    }
  }
`;

const BANNER_PAGINATION = gql`
  query ($page: Int!, $perPage: Int!, $filter: FilterFindManyBannerInput) {
    bannerPagination(
      page: $page
      perPage: $perPage
      sort: CREATEDAT_DESC
      filter: $filter
    ) {
      count
      items {
        _id
        name
        isClickable
        description {
          _id
          zh
          en
        }
        videoIds {
          _id
          videoId
          language
        }
        banners {
          _id
          picture {
            _id
            key
            category
          }
          device
          language
        }
        category
        ranking
        isProduct
        productId
        isLink
        link
        deleted
        isOldUserPost
        isNewUserPost
        isCategory
        categoryId
        bannerContent {
          _id
          en
          zh
        }
        color
      }
    }
  }
`;

const BANNER_CREATE_ONE = gql`
  mutation (
    $name: String!
    $pictures: [BannerPicturesInput]
    $videoIds: [VideoIdsInput]
    $category: String!
    $ranking: Int!
    $isProduct: Boolean!
    $productId: String
    $isLink: Boolean!
    $link: String
    $descriptionEn: String
    $descriptionZh: String
    $isClickable: Boolean
    $bannerContent: BannerContentInput
    $isNewUserPost: Boolean
    $isOldUserPost: Boolean
    $isCategory: Boolean
    $categoryId: String
    $color: String
  ) {
    bannerCreateOne(
      name: $name
      pictures: $pictures
      videoIds: $videoIds
      category: $category
      ranking: $ranking
      isProduct: $isProduct
      productId: $productId
      isLink: $isLink
      link: $link
      descriptionEn: $descriptionEn
      descriptionZh: $descriptionZh
      isClickable: $isClickable
      bannerContent: $bannerContent
      isNewUserPost: $isNewUserPost
      isOldUserPost: $isOldUserPost
      isCategory: $isCategory
      categoryId: $categoryId
      color: $color
    ) {
      _id
      banners {
        picture {
          _id
          key
          category
        }
        pictureId
        device
        language
      }
      deleted
    }
  }
`;

const BANNER_UPDATE_BY_ID = gql`
  mutation (
    $bannerId: MongoID!
    $name: String!
    $pictures: [BannerPicturesInput]
    $videoIds: [VideoIdsInput]
    $category: String!
    $ranking: Int!
    $isProduct: Boolean!
    $productId: String
    $isLink: Boolean!
    $link: String
    $descriptionEn: String
    $descriptionZh: String
    $isClickable: Boolean
    $bannerContent: BannerContentInput
    $isNewUserPost: Boolean
    $isOldUserPost: Boolean
    $isCategory: Boolean
    $categoryId: String
    $color: String
  ) {
    bannerUpdateById(
      bannerId: $bannerId
      name: $name
      pictures: $pictures
      videoIds: $videoIds
      category: $category
      ranking: $ranking
      isProduct: $isProduct
      productId: $productId
      isLink: $isLink
      link: $link
      descriptionEn: $descriptionEn
      descriptionZh: $descriptionZh
      isClickable: $isClickable
      bannerContent: $bannerContent
      isNewUserPost: $isNewUserPost
      isOldUserPost: $isOldUserPost
      isCategory: $isCategory
      categoryId: $categoryId
      color: $color
    ) {
      _id
      banners {
        picture {
          _id
          key
          category
        }
        pictureId
        device
        language
      }
      videoIds {
        _id
        videoId
        language
      }
      deleted
    }
  }
`;

const BANNER_DELETE_OR_RESTORE = gql`
  mutation ($id: MongoID!) {
    bannerDeleteOrRestoreOne(_id: $id) {
      _id
    }
  }
`;

export {
  BANNER_FIND_MANY,
  BANNER_PAGINATION,
  BANNER_CREATE_ONE,
  BANNER_UPDATE_BY_ID,
  BANNER_DELETE_OR_RESTORE,
  BANNER_FIND_ONE_POST,
};
