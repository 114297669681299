import React, { useState, useEffect } from 'react';
import { Button, Input, Row, Col, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FETCH_COUPON_BY_CODE } from '../../../api/coupon';
import { apolloClient } from '../../../util';
import translations from '../../../translation/couponInput';

const fetchCouponByCode = (code) => {
  if (!code || typeof code !== 'string') {
    throw new Error('Invalid Coupon Code');
  }

  return apolloClient.query({
    query: FETCH_COUPON_BY_CODE,
    variables: {
      code,
    },
    fetchPolicy: 'network-only',
  });
};

const getCouponEffect = (subtotal, type, value) => {
  console.log(subtotal);

  if (type === 'percentage') {
    return (-1 * (subtotal * value)) / 100;
  }
  return -1 * value;
};

const getConponEffectText = (effect) => {
  console.log(effect.toFixed(2));

  if (effect <= 0) {
    return `-$${(-1 * effect).toFixed(2)}`;
  }
  return `$${effect.toFixed(2)}`;
};
export default function CouponInput({ orderSubTotal, setCoupon }) {
  const [addingCoupon, setAddingCoupon] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [couponApplied, setCouponApplied] = useState(false);
  const lang = useSelector((state) => state.language.lang);
  const [constants, setConstants] = useState(translations.en);

  useEffect(() => {
    setConstants(translations[lang]);
  }, [lang]);
  //   const [coupon, setCoupon] = useState({ code: 'WELCOME', couponType: '' });
  const [resultCoupon, setResultCoupon] = useState({
    code: '',
    couponType: '',
    couponValue: 0,
  });
  const handleApplyCoupon = async () => {
    setLoading(true);
    // await new Promise((r) => setTimeout(r, 2000));

    try {
      const { data } = await fetchCouponByCode(couponInput);

      const result = data?.couponFindByCode;
      if (result) {
        if (!result.isCateringOrder) {
          throw new Error(constants.cateringError);
        }
        if (result.deleted) {
          throw new Error(constants.deletedError);
        }

        const effect = getCouponEffect(
          orderSubTotal,
          result.couponType,
          result.couponValue
        );
        setResultCoupon(result);
        setCouponApplied(true);
        setError('');
        setCoupon({ effect, code: result.code });
        setAddingCoupon(false);
      } else {
        setError(constants.invalidError);
      }
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
    setLoading(false);
  };
  const handleCancelAdding = () => {
    setError('');
    setCouponInput('');
    setCouponApplied(false);
    setAddingCoupon(false);
  };

  const handleRemoveCoupon = () => {
    setCouponApplied(false);
    setCoupon({ effect: 0, code: '' });
  };

  return orderSubTotal > 0 ? (
    <Spin spinning={loading} style={{ width: '100%' }}>
      {/* Initial state, only add coupon button */}
      {!addingCoupon && !couponApplied && (
        <Button
          type="link"
          onClick={() => {
            setAddingCoupon(true);
          }}
          style={{ padding: '0px', fontSize: '15px' }}
        >
          {constants.addCoupon}
        </Button>
      )}

      {/* adding coupon, display input */}
      {addingCoupon && !couponApplied && (
        <>
          <Row style={{ width: '100%' }} gutter={8}>
            <Col flex={1}>
              <Input
                onChange={(e) => setCouponInput(e.target.value)}
                value={couponInput}
              />
            </Col>
            {couponInput && (
              <Col>
                <Button type="primary" onClick={handleApplyCoupon}>
                  {constants.apply}
                </Button>
              </Col>
            )}
            <Button type="link" onClick={handleCancelAdding}>
              {constants.cancel}
            </Button>
          </Row>
          {error && <Text type="danger">{error}</Text>}
        </>
      )}

      {/* coupon applied */}
      {!addingCoupon && couponApplied && (
        <Row align="middle" justify="space-between">
          <Col>
            <Text underline>{resultCoupon.code}</Text>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={handleRemoveCoupon}
            />
          </Col>
          <Col>
            <Text>
              {getConponEffectText(
                getCouponEffect(
                  orderSubTotal,
                  resultCoupon.couponType,
                  resultCoupon.couponValue
                )
              )}
            </Text>
          </Col>
        </Row>
      )}
    </Spin>
  ) : null;
}
CouponInput.propTypes = {
  orderSubTotal: PropTypes.number.isRequired,
  setCoupon: PropTypes.func.isRequired,
};
