/* global google */
import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import powerByGoogle from '../../../assets/images/icons/powerByGoogle.png';
import './AddressSearchInput.css';

export class AddressSearchInput extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      address: '',
      props: {},
      autoComplete: 'new-password',
      // placeholder: '',
    };
  }

  componentDidMount() {
    const { autoComplete } = this.props;
    if (autoComplete) {
      this.setState({ autoComplete });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { props } = prevState;
    // console.log(nextProps.address, props.address, prevState.address);
    if (
      nextProps.address !== props.address ||
      nextProps.selected !== props.selected
    ) {
      // console.log(nextProps.address);
      return {
        props: {
          address: nextProps.address,
          selected: nextProps.selected,
        },
        address: nextProps.address,
      };
    }

    return null;
  }

  handleAddressChange = (address) => {
    const { handleAddressTyping } = this.props;
    this.setState({ address });
    handleAddressTyping(address);
  };

  render() {
    const { address, autoComplete } = this.state;
    const { onAddressSelect } = this.props;
    const { placeholder } = this.props;

    const searchOptions = {
      location: new google.maps.LatLng(37, -122),
      radius: 100000,
      types: ['address'],
    };

    return (
      <PlacesAutocomplete
        onChange={this.handleAddressChange}
        onSelect={onAddressSelect}
        value={address}
        searchOptions={searchOptions}
        // googleCallbackName="GoogleMapAPIFunc"
        // shouldFetchSuggestions={address.length > 3}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <>
            {/* eslint-disable */}
            <Input
              {...getInputProps({
                autoComplete,
                id: 'address-input',
                placeholder,
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                      padding: 25,
                    }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };

                const spread = {
                  ...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  }),
                };

                return (
                  <div {...spread} key={suggestion.placeId}>
                    <div>{suggestion.description}</div>
                  </div>
                );
              })}
              {(suggestions.length > 0 || loading) && (
                <div key="google-logo">
                  <img src={powerByGoogle} alt="Google"></img>
                </div>
              )}
            </div>
            {/* eslint-enable */}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

AddressSearchInput.defaultProps = {
  placeholder: '',
  nextProps: null,
  address: '',
  autoComplete: undefined,
  selected: false,
  handleAddressTyping: () => null,
};

AddressSearchInput.propTypes = {
  onAddressSelect: PropTypes.func.isRequired,
  handleAddressTyping: PropTypes.func,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  nextProps: PropTypes.shape({
    address: PropTypes.string,
    selected: PropTypes.shape({}),
  }),
  address: PropTypes.string,
  selected: PropTypes.bool,
};

export default AddressSearchInput;
