/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row, message, Radio, Modal } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
// import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import moment from 'moment';
import { getDistance, convertDistance } from 'geolib';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as actions from '../../../../store/actions';
import {
  ADDRESS_QUERY,
  CREATE_ADDRESS,
  USER_SET_ADDRESS,
} from '../../../../api/address';
import { CONFIGURATION_FIND_ONE } from '../../../../api/configuration';
import { FETCH_FIX_LOCATION_CAMPAIGN } from '../../../../api/campaign';
import { apolloClient } from '../../../../util';
import AddressCard from '../../../../components/Others/AddressCard/AddressCard';
import AddressEditCreateForm from '../../../../components/Forms/AddressEditCreateForm/AddressEditCreateForm';
import {
  checkAddressIsValid,
  checkAddressWithinWarehouseDeliveryArea,
} from '../../../../helper/PureFunction/SmallFunction';
import Loading from '../../../../components/Others/Loading/Loading';
import { addressFormatterOnlyAddress } from '../../../../helper/Formatter/Formatter';
import { WAREHOUSE_BY_ID_LOCATION } from '../../../../api/warehouse';
import AddressSearchInput from '../../../../components/Inputs/AddressSearchInput/AddressSearchInput';

const dateString = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export class AddressSelection extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: true,
      editedAddressId: null,
      showAddAddressForm: false,
      selectedRadio: 'pickup', // "pickup or delivery address type"
      addresses: [],
      campaignAddresses: [],
      locationInfo: false, // true for pickup
      allowedZipCode: [],
      locationRecommendationVisible: false,
      maxDistance: 7,
      isTurnOnMaxDistanceForAllOfWarehouse: false,
      location: null,
      pickupAddress: '',
      selectedPickupAddress: null,
    };
  }

  componentDidMount = async () => {
    await this.handleGetCurrentLocation();
    await this.validShoppingMode();
    await this.handleFetchScheduledDeliveryConfiguration();
    await this.fetchMyAddress();
    await this.fetchCampaignContent();
    await this.fetchZipCodes();
    await this.setState({ loading: false });
  };

  handleFetchScheduledDeliveryConfiguration = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isScheduledDeliveryConfiguration: true },
      },
      fetchPolicy: 'network-only',
    });

    if (configurationOne) {
      const { scheduledDeliveryConfiguration } = configurationOne;
      console.log(scheduledDeliveryConfiguration);
      await this.setState({
        maxDistance: scheduledDeliveryConfiguration.maxDistance,
        isTurnOnMaxDistanceForAllOfWarehouse:
          scheduledDeliveryConfiguration.isTurnOnMaxDistanceForAllOfWarehouse,
      });
    }
  };

  validShoppingMode = async () => {
    let pass = true;
    const {
      currentShoppingMode,
      currentAddressType,
      setUserAddressType,
      warehouseDeliveryMode,
      // intl,
    } = this.props;
    // console.log('currentShoppingMode',currentShoppingMode);
    // console.log('currentAddressType',currentAddressType);
    if (
      currentShoppingMode === 'delivery' ||
      currentShoppingMode === 'pickup' ||
      currentShoppingMode === 'groupBuy'
    ) {
      if (currentShoppingMode === 'delivery') {
        if (currentAddressType === 'pickup' && !warehouseDeliveryMode) {
          this.setState({ selectedRadio: 'pickup' });
          await this.setState({ locationInfo: true });
        } else {
          this.setState({ selectedRadio: 'delivery' });
          await setUserAddressType('delivery');
        }
      }
    } else {
      pass = false;
    }
    if (currentAddressType === 'delivery' || currentAddressType === 'pickup') {
      // console.log('passed');
    } else {
      pass = false;
    }
    console.log('pass shopping address mode', pass);
    // if (pass === false) {
    //   message.warning(intl.formatMessage({ id: 'errorShoppingMode' }), 6);
    // }
  };

  onChangeRadioButton = async (event) => {
    this.setState({ selectedRadio: event.target.value });
    const { setUserAddressType, currentShoppingMode } = this.props;
    if (currentShoppingMode === 'pickup') {
      await this.setState({ locationInfo: false });
    } else {
      // console.log("should not call here");
      await setUserAddressType(event.target.value);
      await this.setState({ locationInfo: true });
    }
  };

  handleCheckZipCode = async (zipCode) => {
    const { allowedZipCode } = this.state;
    // console.log('allowedZipCode',allowedZipCode);
    const foundZip = _.find(allowedZipCode, (element) => {
      return element === zipCode;
    });
    if (!foundZip) {
      this.setState({ locationRecommendationVisible: true });
      return false;
    }
    return true;
  };

  handleSelectEditAddress = (_id = null) => {
    console.log(_id);
    this.setState({ editedAddressId: _id });
    // console.log('editedAddressId', _id);
  };

  handleSelectPickupAddress = async (id) => {
    this.setState({ selectedPickupAddress: id });
  };

  handleSelectAddressInCard = async (node) => {
    const { maxDistance, isTurnOnMaxDistanceForAllOfWarehouse } = this.state;
    const {
      currentShoppingMode,
      intl,
      currentAddressType,
      setUserAddressType,
      handleSelectAddress,
      language,
      isScheduledDelivery,
      currentWarehouseId,
      warehouseDeliveryMode,
      // history,
    } = this.props;
    console.log('currentWarehouseId', currentWarehouseId);
    if (isScheduledDelivery === true) {
      // message.error(addressFormatterOnlyAddress(node))
      console.log(
        '================================================================ Delivery Distance Check'
      );
      const result = await checkAddressWithinWarehouseDeliveryArea(
        addressFormatterOnlyAddress(node),
        currentWarehouseId,
        maxDistance,
        isTurnOnMaxDistanceForAllOfWarehouse
      );
      if (result === true) {
        await handleSelectAddress(node);
      } else if (!isTurnOnMaxDistanceForAllOfWarehouse) {
        const {
          data: { warehouseById },
        } = await apolloClient.query({
          query: WAREHOUSE_BY_ID_LOCATION,
          variables: {
            input: currentWarehouseId,
          },
          fetchPolicy: 'network-only',
        });
        Modal.warning({
          content: (
            <div>
              {`${intl.formatMessage(
                {
                  id: 'outForScheduledDeliveryArea',
                },
                { miles: warehouseById.deliveryMaxDistance }
              )}`}
            </div>
          ),
          onOk: async () => {
            Modal.destroyAll();
          },
        });
      } else {
        Modal.warning({
          content: (
            <div>
              {`${intl.formatMessage(
                {
                  id: 'outForScheduledDeliveryArea',
                },
                { miles: maxDistance }
              )}`}
            </div>
          ),
          onOk: async () => {
            Modal.destroyAll();
          },
        });
      }
      return;
    }
    if (warehouseDeliveryMode === true) {
      // message.error(addressFormatterOnlyAddress(node))
      console.log(
        '================================================================ Warehouse Delivery Distance Check'
      );
      const {
        data: { warehouseById },
      } = await apolloClient.query({
        query: WAREHOUSE_BY_ID_LOCATION,
        variables: {
          input: currentWarehouseId,
        },
        fetchPolicy: 'network-only',
      });
      const result = await checkAddressWithinWarehouseDeliveryArea(
        addressFormatterOnlyAddress(node),
        currentWarehouseId,
        warehouseById.warehouseScheduleDeliveryMaxDistance,
        false
      );
      if (result === true) {
        await handleSelectAddress(node);
      } else {
        Modal.warning({
          content: (
            <div>
              {`${intl.formatMessage(
                {
                  id: 'outForScheduledDeliveryArea',
                },
                { miles: warehouseById.warehouseScheduleDeliveryMaxDistance }
              )}`}
            </div>
          ),
          onOk: async () => {
            Modal.destroyAll();
          },
        });
      }
      return;
    }
    // console.log(node,currentShoppingMode,currentAddressType);
    const valid = await checkAddressIsValid(
      node,
      currentShoppingMode,
      currentAddressType
    );
    // console.log('valid',valid);
    if (valid[0] === false) {
      const pickUpButton = (
        <Button
          type="primary"
          onClick={async () => {
            if (currentShoppingMode === 'pickup') {
              await this.setState({ locationInfo: false });
            } else {
              // console.log("should not call here");
              await setUserAddressType('pickup');
              await this.setState({
                locationInfo: true,
                selectedRadio: 'pickup',
              });
              Modal.destroyAll();
            }
          }}
        >
          {`${intl.formatMessage({ id: 'pickup' })}`}
        </Button>
      );
      const pickUpButton2 = (
        <Button
          onClick={async () => {
            if (currentShoppingMode === 'pickup') {
              await this.setState({ locationInfo: false });
            } else {
              // console.log("should not call here");
              await setUserAddressType('pickup');
              await this.setState({
                locationInfo: true,
                selectedRadio: 'pickup',
              });
              Modal.destroyAll();
            }
          }}
        >
          {`${intl.formatMessage({ id: 'pickup' })}`}
        </Button>
      );
      if (valid[1] === null) {
        Modal.warning({
          content: (
            <div>
              {`${intl.formatMessage({ id: 'outForDeliveryArea' })}`}
              {pickUpButton}
              {/* {`${intl.formatMessage({ id: 'outForDeliveryArea2' })}`}
              <Button
                type="primary"
                onClick={() => {
                  history.push(getCustomerPath('group-buying'));
                }}
              >
                {`${intl.formatMessage({ id: 'groupBuying' })}`}
              </Button> */}
            </div>
          ),
        });
      } else {
        let date = '';
        if (valid[1].day.length > 0) {
          const temp = [];
          valid[1].day.forEach((day) => {
            temp.push(dateString[day]);
          });
          date = temp.join(',');
        }
        const styleObj = {
          color: '#ffd868',
          fontSize: '19px',
          fontWeight: 'bold',
        };
        let msg;
        if (language === 'zh') {
          msg = (
            <div>
              您所在的区域: <span style={styleObj}>{valid[1].areaName}</span>{' '}
              目前仅在: <span style={styleObj}>{date}</span>{' '}
              配送.送货时间是当天3PM-7PM.您也可以查看到店自取的地址.
              {pickUpButton2}
            </div>
          );
        } else {
          msg = (
            <div>
              Currently, we only deliver to this area:{' '}
              <span style={styleObj}>{valid[1].areaName}</span> on{' '}
              <span style={styleObj}>{date}</span>{' '}
              {`. If it does not work for you, you can always check out pick up options`}
              {pickUpButton2}
            </div>
          );
        }
        Modal.confirm({
          content: <div>{msg}</div>,
          onOk: async () => {
            /* eslint-disable-next-line */
            node.onlyAllowedWeekDay = valid[1];
            await handleSelectAddress(node);
          },
        });
      }
    } else if (valid[0] === true) {
      await handleSelectAddress(node);
    }
  };

  handleCancelForm = () => {
    this.setState({ showAddAddressForm: false });
  };

  handleGuestEditAddress = (address) => {
    const {
      group: { name },
    } = this.props;
    const { addresses } = this.state;
    if (address && name === 'guest') {
      // console.log(address);
      const index = addresses.findIndex((item) => item._id === address._id);
      if (index > -1) {
        addresses[index] = address;
      }
      this.setState({
        addresses,
      });
    }
  };

  handleGuestCreateAddress = (address) => {
    const { group } = this.props;
    const { addresses } = this.state;
    if (address && group && group.name === 'guest') {
      this.setState({
        addresses: [...addresses, address],
      });
    }
  };

  handleSubmitForm = () => {
    const {
      form,
      group: { name },
    } = this.props;
    const { addresses } = this.state;
    form.validateFields(async (err, values) => {
      // console.log(values);
      if (err) {
        message.error('error in form');
      } else {
        const { newAddress } = values;
        let newAddressId;
        // =====create address =====
        if (newAddress) {
          const copyNewAddress = JSON.parse(JSON.stringify(newAddress));
          try {
            const { data } = await apolloClient.mutate({
              mutation: CREATE_ADDRESS,
              variables: { input: copyNewAddress },
            });
            const { _id } = data.addressCreateOne.record;
            if (_id && name !== 'guest') {
              newAddressId = _id;
              const response = await apolloClient.mutate({
                mutation: USER_SET_ADDRESS,
                variables: { id: newAddressId },
              });
              if (response.data) {
                this.setState({ showAddAddressForm: false });
                await this.fetchMyAddress();
              }
            } else if (_id && name === 'guest') {
              this.setState({
                addresses: [...addresses, data.addressCreateOne.record],
                showAddAddressForm: false,
              });
            } else {
              message.error('error2 creating address');
              return;
            }
          } catch (error) {
            console.error(error);
            message.error('error1 creating address');
          }
        }
      }
    });
  };

  fetchZipCodes = async () => {
    try {
      const { data } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: {
          filter: { isZipCode: true },
        },
        fetchPolicy: 'network-only',
      });
      const allowedZipCode = data.configurationOne.deliveryZipCodes;
      if (allowedZipCode) {
        this.setState({ allowedZipCode });
        // console.log("allowedZipCode",allowedZipCode);
      }
    } catch (error) {
      console.error(error);
      message.error('fetching zipCode error');
    }
  };

  fetchCampaignContent = async () => {
    const { location } = this.state;
    try {
      const filter = { type: 'fixLocation', deleted: false };
      const {
        data: { campaignFindMany },
      } = await apolloClient.query({
        query: FETCH_FIX_LOCATION_CAMPAIGN,
        variables: { filter },
        fetchPolicy: 'network-only',
      });
      const addresses = [];
      campaignFindMany.forEach((campaign) => {
        campaign.campaignContents.forEach((campaignContent) => {
          // console.log(campaignContent);

          if (
            campaignContent.official === true &&
            campaignContent.deleted === false &&
            campaignContent.status === 'active'
          ) {
            const newAddress = campaignContent.defaultAddress;
            newAddress.pickupWindow = campaignContent.pickupWindow;
            newAddress.pickupTimeSlotFormatted =
              campaignContent.pickupTimeSlotFormatted;
            newAddress.prepareDay = campaignContent.prepareDay;
            newAddress.endDate = campaignContent.endDate;
            newAddress.campaignContentId = campaignContent._id;
            newAddress.title =
              (campaignContent.warehouse && campaignContent.warehouse.title) ||
              {};

            if (campaignContent.defaultAddress.type === 'personal') {
              newAddress.title = {
                en: campaignContent.defaultAddress.firstName,
                zh: campaignContent.defaultAddress.lastName,
              };
            }
            newAddress.location =
              (campaignContent.location &&
                campaignContent.location.coordinates) ||
              [];
            newAddress.region = campaignContent?.warehouse?.region;
            addresses.push(newAddress);
          }
        });
      });

      if (location && location.latitude && location.longitude) {
        const { latitude, longitude } = location;
        const isValid = addresses.every((address) => {
          return address.location && address.location.length === 2;
        });
        if (isValid) {
          addresses.forEach((address) => {
            if (address.location && address.location.length === 2) {
              address.distance = convertDistance(
                getDistance(
                  { latitude, longitude },
                  {
                    latitude: address.location[1],
                    longitude: address.location[0],
                  }
                ),
                'mi'
              );
            }
          });
          addresses.sort((a, b) => {
            return a.distance - b.distance;
          });
        }
      }
      const { onlyAllowedDate } = this.props;
      // console.log('onlyAllowedDate', onlyAllowedDate);
      // console.log('pickupAddresses', addresses);
      if (onlyAllowedDate && onlyAllowedDate.length > 0) {
        // check days
        const days = new Set();
        onlyAllowedDate.forEach((day) => {
          // console.log(moment(day).days());
          days.add(moment(day).days());
        });
        const newAddress = addresses.filter((address) => {
          // check pickup window
          // console.log(address);
          if (
            _.findIndex(address.pickupWindow, (pickupAddress) => {
              return [...days].includes(pickupAddress.day);
            }) !== -1
          ) {
            return true;
          }
          return false;
        });
        console.log(newAddress);
        this.setState({ campaignAddresses: newAddress });
      } else {
        this.setState({ campaignAddresses: addresses });
      }
    } catch (error) {
      console.error(error);
      message.warn('Error happened while fetching campaign content');
    }
  };

  handleGetCurrentLocation = async () => {
    const { language } = this.props;
    if ('geolocation' in navigator) {
      // check if geolocation is supported/enabled on current browser
      await navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          this.setState({
            location: { latitude, longitude },
          });
        },
        (error) => {
          console.error(error);
          message.warning(
            language === 'zh'
              ? '无法获取您的位置，请检查浏览器permission.您会看到一个默认列表'
              : 'Cannot get location, check your network or location permission. You will see a default list.'
          );
        },
        { maximumAge: 600000, timeout: 5000, enableHighAccuracy: false }
      );
    }
  };

  handleAddressSelect = (address) => {
    console.log('search address', address);
    geocodeByAddress(address)
      .then(async (results) => {
        const place = results[0];
        console.log(place);
        let addressString = '';
        for (let i = 0; i < place.address_components.length; i += 1) {
          const addressType = place.address_components[i].types[0];
          if (addressType === 'street_number') {
            addressString += `${place.address_components[i].short_name} `;
          }
          if (addressType === 'route') {
            addressString += place.address_components[i].long_name;
          }
        }
        const { selected } = this.state;
        this.setState({
          pickupAddress: addressString,
          selected: !selected,
        });
        return getLatLng(results[0]);
      })
      .then(async (latLng) => {
        // Do something with latLng
        console.log(latLng);
        await this.setState({
          location: {
            latitude: latLng.lat,
            longitude: latLng.lng,
          },
        });
        const { location, campaignAddresses } = this.state;
        if (location && location.latitude && location.longitude) {
          const addresses = campaignAddresses;
          const { latitude, longitude } = location;
          const isValid = addresses.every((singleAddress) => {
            return (
              singleAddress.location && singleAddress.location.length === 2
            );
          });
          if (isValid) {
            addresses.forEach((singleAddress) => {
              if (
                singleAddress.location &&
                singleAddress.location.length === 2
              ) {
                singleAddress.distance = convertDistance(
                  getDistance(
                    { latitude, longitude },
                    {
                      latitude: singleAddress.location[1],
                      longitude: singleAddress.location[0],
                    }
                  ),
                  'mi'
                );
              }
            });
            addresses.sort((a, b) => {
              return a.distance - b.distance;
            });
          }
          await this.setState({ campaignAddresses: addresses });
        }
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  fetchMyAddress = async () => {
    try {
      const { data } = await apolloClient.query({
        query: ADDRESS_QUERY,
        fetchPolicy: 'network-only',
      });
      if (data.userCurrent.addresses) {
        this.setState({ addresses: data.userCurrent.addresses });
      }
    } catch (error) {
      console.error(error);
      message.warn('Error happened, can not fetch addresses');
    }
  };

  render() {
    const {
      loading,
      selectedRadio,
      editedAddressId,
      addresses,
      campaignAddresses,
      locationInfo,
      locationRecommendationVisible,
      pickupAddress,
      selectedPickupAddress,
    } = this.state;
    const {
      intl,
      currentShoppingMode,
      // handleSelectAddress,
      language,
      form,
      setUserAddressType,
      onlyAllowedDate,
      // checkRadioDelivery,
      // isScheduledDelivery,
      // warehouseDeliveryMode,
      isZipCodeValid,
    } = this.props;
    // console.log('onlyAllowedDate in selection', onlyAllowedDate);
    let renderAddress = addresses;
    if (currentShoppingMode === 'delivery' && selectedRadio === 'pickup') {
      // renderAddress = campaignAddresses;
      // if (addresses.length > 0) {
      renderAddress = campaignAddresses;
      // } else {
      // force user to create information first
      // renderAddress = [];
      // }
    }

    const renderRadioGroup = () => {
      let buttons = null;
      // if (currentShoppingMode === 'pickup') {
      buttons = (
        <Radio.Button
          value="pickup"
          style={{ fontSize: '15px', fontWeight: '600' }}
        >
          <FormattedMessage id="pickup" />
        </Radio.Button>
      );
      // } else if (currentShoppingMode === 'delivery') {
      //   if (checkRadioDelivery === 'both' || !checkRadioDelivery) {
      //     if (isScheduledDelivery || warehouseDeliveryMode) {
      //       buttons = (
      //         <React.Fragment>
      //           <Radio
      //             value="delivery"
      //             style={{ fontSize: '15px', fontWeight: '600' }}
      //           >
      //             <FormattedMessage id="deliveryAddress" />
      //           </Radio>
      //         </React.Fragment>
      //       );
      //     } else {
      //       buttons = (
      //         <React.Fragment>
      //           {/* <Radio
      //             value="delivery"
      //             style={{ fontSize: '15px', fontWeight: '600' }}
      //           >
      //             <FormattedMessage id="deliveryAddress" />
      //           </Radio> */}
      //           <Radio
      //             value="pickup"
      //             style={{ fontSize: '15px', fontWeight: '600' }}
      //           >
      //             <FormattedMessage id="pickup" />
      //           </Radio>
      //         </React.Fragment>
      //       );
      //     }
      //   } else if (checkRadioDelivery === 'pickup') {
      //     buttons = (
      //       <Radio.Button
      //         value="pickup"
      //         style={{ fontSize: '15px', fontWeight: '600' }}
      //       >
      //         <FormattedMessage id="pickup" />
      //       </Radio.Button>
      //     );
      //   } else if (checkRadioDelivery === 'delivery') {
      //     buttons = (
      //       <Radio.Button
      //         value="delivery"
      //         style={{ fontSize: '15px', fontWeight: '600' }}
      //       >
      //         <FormattedMessage id="deliveryAddress" />
      //       </Radio.Button>
      //     );
      //   }
      // }
      return buttons;
    };

    const renderAddressButton = () => {
      const { showAddAddressForm } = this.state;
      const { currentAddressType } = this.props;
      if (
        currentShoppingMode === 'delivery' &&
        selectedRadio === 'pickup' &&
        renderAddress.length === 0 &&
        addresses.length > 0
      ) {
        return (
          <div>
            <div>Sorry, No Pickup Address.</div>
            <div>Coming Soon</div>
          </div>
        );
      }
      if (showAddAddressForm === true) {
        return (
          <Form
            layout="inline"
            css={{
              '& .ant-legacy-form-item': {
                marginRight: '0',
              },
              '& .has-feedback': { width: '100%' },
            }}
          >
            <AddressEditCreateForm
              form={form}
              handleSubmitForm={this.handleSubmitForm}
              handleCancelForm={this.handleCancelForm}
            />
          </Form>
        );
      }
      if (currentAddressType === 'pickup' && currentShoppingMode !== 'pickup') {
        return null;
      }
      if (isZipCodeValid === false && !addresses.length) {
        console.log('12312');
        return (
          <div>
            <FormattedMessage id="pickUpTip" />
          </div>
        );
      }
      if (isZipCodeValid === false) {
        return null;
      }
      return (
        <Button
          onClick={() => {
            this.setState({ showAddAddressForm: true });
          }}
          css={{
            height: '100%',
            width: '100%',
            minHeight: isMobile ? '50px' : '220px',
          }}
        >
          <PlusOutlined />
          {currentShoppingMode === 'delivery' && selectedRadio === 'delivery'
            ? intl.formatMessage({ id: 'addConDelivery' })
            : intl.formatMessage({ id: 'addConPickup' })}
        </Button>
      );
    };

    const renderAddressSearchInput = () => {
      if (selectedRadio === 'pickup') {
        return (
          <div
            css={
              isMobile
                ? { width: '100%' }
                : { width: '500px', margin: '0 auto' }
            }
          >
            <AddressSearchInput
              placeholder={
                language === 'zh'
                  ? '搜索距离你最近的自取地址'
                  : 'Search a pickup address nearby'
              }
              autoComplete="off"
              // selected={selected}
              address={pickupAddress}
              clearAddress={this.clearAddress}
              onChange={this.handleAddressChange}
              onAddressSelect={this.handleAddressSelect}
            />
          </div>
        );
      }
      return null;
    };

    // if (loading === true) {
    //   return (
    //     <React.Fragment>
    //       <Loading />
    //     </React.Fragment>
    //   );
    // }

    return (
      <React.Fragment>
        {loading === true ? (
          <Loading />
        ) : (
          <div>
            <Modal
              visible={locationRecommendationVisible}
              title={null}
              footer={null}
              onCancel={() => {
                this.setState({ locationRecommendationVisible: false });
              }}
            >
              <div>
                <FormattedMessage id="outForDeliveryArea" />
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={async () => {
                    await setUserAddressType('pickup');
                    await this.setState({
                      selectedRadio: 'pickup',
                      locationInfo: true,
                    });
                    this.setState({ locationRecommendationVisible: false });
                  }}
                >
                  <FormattedMessage id="checkItOut" />
                </Button>
              </div>
            </Modal>
            {currentShoppingMode !== 'groupBuy' ? (
              <div css={{ textAlign: 'center', margin: '20px auto' }}>
                <Radio.Group
                  onChange={this.onChangeRadioButton}
                  defaultValue={selectedRadio}
                  value={selectedRadio}
                  buttonStyle="solid"
                >
                  {renderRadioGroup()}
                </Radio.Group>
              </div>
            ) : null}
            {/* TODO: add search input */}
            {renderAddressSearchInput()}
            <Row type="flex" justify="center" gutter={16}>
              {isMobile ? (
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={24}
                  xs={24}
                  sm={24}
                  css={{ padding: '10px' }}
                >
                  {renderAddressButton()}
                </Col>
              ) : null}
              {renderAddress.map((node) => {
                return (
                  <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={24}
                    xs={24}
                    sm={24}
                    key={node._id}
                    css={{
                      padding: '10px',
                    }}
                  >
                    <AddressCard
                      node={node}
                      handleSelectPickupAddress={this.handleSelectPickupAddress}
                      selectedPickupAddress={selectedPickupAddress}
                      editedAddressId={editedAddressId}
                      fetchMyAddress={this.fetchMyAddress}
                      handleSelectAddress={this.handleSelectAddressInCard}
                      handleSelectEditAddress={this.handleSelectEditAddress}
                      locationInfo={locationInfo}
                      addresses={addresses}
                      handleCheckZipCode={this.handleCheckZipCode}
                      onlyAllowedDate={onlyAllowedDate}
                      handleGuestEditAddress={this.handleGuestEditAddress}
                      handleGuestCreateAddress={this.handleGuestCreateAddress}
                    />
                  </Col>
                );
              })}
              {isMobile ? null : (
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={24}
                  xs={24}
                  sm={24}
                  css={{ padding: '10px' }}
                >
                  {renderAddressButton()}
                </Col>
              )}
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}

AddressSelection.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  currentShoppingMode: PropTypes.string.isRequired,
  handleSelectAddress: PropTypes.func.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  currentAddressType: PropTypes.string.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  // history: ReactRouterPropTypes.history.isRequired,
  onlyAllowedDate: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.string.isRequired,
  // checkRadioDelivery: PropTypes.string,
  isScheduledDelivery: PropTypes.bool.isRequired,
  currentWarehouseId: PropTypes.string.isRequired,
  warehouseDeliveryMode: PropTypes.bool,
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isZipCodeValid: PropTypes.bool,
};

AddressSelection.defaultProps = {
  onlyAllowedDate: null,
  // checkRadioDelivery: null,
  warehouseDeliveryMode: null,
  isZipCodeValid: null,
};

const mapStateToProps = (state) => ({
  currentAddressType: state.user.currentAddressType,
  currentShoppingMode: state.user.currentShoppingMode,
  language: state.language.lang,
  isScheduledDelivery: state.warehouse.isScheduledDelivery,
  currentWarehouseId: state.warehouse.warehouseId,
  group: state.user.group,
});

const mapDispatchToProps = (dispatch) => ({
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
});

export default Form.create({ name: 'customer_address_from_address_selection' })(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddressSelection))
);
