import moment from 'moment';
import * as R from 'ramda';
import _ from 'lodash';
import axios from 'axios';
import { message } from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getDistance, convertDistance } from 'geolib';
import { CONFIGURATION_FIND_ONE } from '../../api/configuration';
import { WAREHOUSE_BY_ID_LOCATION } from '../../api/warehouse';
import { apolloClient } from '../../util';

export const nextAvailableDate = (dayINeed) => {
  const today = moment().day();
  if (today <= dayINeed) {
    return moment().day(dayINeed);
  }
  return moment().add(1, 'weeks').day(dayINeed);
};

export const getGlobalDisableDate = async () => {
  try {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isZipCode: true },
      },
      fetchPolicy: 'network-only',
    });
    if (configurationOne._id) {
      const disableShippingDate = configurationOne.disableShippingDate || [];
      return disableShippingDate;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const getBoxLabel = async (startDate) => {
  console.log('startDate', startDate);
  const key = '27d0b5c0-8b84-43de-950a-5b177b04b8ef';
  const terrorId = '97c223bf-3682-4956-99c5-9dd647b7ace4';
  const date = startDate;
  if (startDate.length !== 8) {
    return {};
  }
  const routeUrl = `https://wwrm.workwave.com/api/v2/territories/${terrorId}/approved/routes`;
  const orderUrl = `https://wwrm.workwave.com/api/v1/territories/${terrorId}/orders`;
  const orderIdToLabel = {};
  const boxIdToLabel = {};
  try {
    const response = await axios.get(routeUrl, {
      params: {
        key,
        date,
      },
    });
    const { approvedRoutes } = response.data;
    approvedRoutes.forEach((element) => {
      const { steps } = element.route;
      steps.forEach((step) => {
        if (step.orderId) {
          orderIdToLabel[step.orderId] = step.displayLabel;
        }
      });
    });
    console.log('orderIdToLabel', orderIdToLabel);
    const response2 = await axios.get(orderUrl, {
      params: {
        key,
        assignedOn: date,
      },
    });
    const { orders } = response2.data;
    console.log('response2', response2);
    const getBoxId = R.pathOr(null, ['delivery', 'customFields', 'box number']);
    _.forEach(orders, (value, key2) => {
      const boxId = getBoxId(value);
      if (boxId) {
        boxIdToLabel[boxId] = orderIdToLabel[key2];
      }
    });
    console.log('final', boxIdToLabel);
  } catch (error) {
    console.error(error);
  }
  return boxIdToLabel;
};

export const combineShippingTime = (date, time) => {
  const timeStamp = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').valueOf();
  return timeStamp;
  // console.log("timeStamp",timeStamp)
};

export const checkAddressIsValid = async (
  // 1 is not valid; 2 is valid for far area, 3 is valid
  address,
  currentShoppingMode,
  currentAddressType
) => {
  const res = [false, null];
  if (currentShoppingMode === 'delivery' && currentAddressType === 'delivery') {
    const { zipCode } = address;
    if (!zipCode) {
      message.error('zipCode is null', 15);
      return res;
    }
    try {
      const { data } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: {
          filter: { isZipCode: true },
        },
        fetchPolicy: 'network-only',
      });
      const allowedZipCode = data.configurationOne.deliveryZipCodes;
      const { externalZipCodes } = data.configurationOne;
      if (allowedZipCode) {
        const foundZip = _.find(allowedZipCode, (element) => {
          return element === zipCode;
        });
        if (!foundZip) {
          // check for external area
          const foundExternalZip = _.find(externalZipCodes, (element) => {
            return element.zipCode === zipCode;
          });
          // ===== if found external
          if (foundExternalZip) {
            return [false, foundExternalZip];
          }
          // ===== if found nothing
          return [false, null];
        }
        // ===== if found delivery
        return [true, null];
      }
      message.error('fetching zipCode error', 15);
      return res;
    } catch (error) {
      console.error(error);
      message.error('fetching zipCode error', 15);
      return res;
    }
  }
  return [true, null];
};

export const checkAddressWithinWarehouseDeliveryArea = async (
  address,
  warehouseId,
  maxDistance = 7,
  isTurnOnMaxDistanceForAllOfWarehouse = false
) => {
  console.log('calculate address', address);
  try {
    const {
      data: { warehouseById },
    } = await apolloClient.query({
      query: WAREHOUSE_BY_ID_LOCATION,
      variables: {
        input: warehouseId,
      },
      fetchPolicy: 'network-only',
    });
    if (warehouseById._id) {
      const { coordinates } = warehouseById.location;
      console.log('coordinates', coordinates);
      if (coordinates.length === 2) {
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        const latitude = latLng.lat;
        const longitude = latLng.lng;
        const distance = convertDistance(
          getDistance(
            { latitude, longitude },
            {
              latitude: coordinates[1],
              longitude: coordinates[0],
            }
          ),
          'mi'
        );
        console.log('final distance', distance);
        if (isTurnOnMaxDistanceForAllOfWarehouse) {
          if (distance < maxDistance) {
            console.log('ok for delivery');
            return true;
          }
        } else if (distance < warehouseById.deliveryMaxDistance) {
          console.log('ok for delivery');
          return true;
        }
        return false;
      }
    }
  } catch (error) {
    console.error(error);
    return false;
  }
  return false;
};
