import gql from 'graphql-tag';

export const CATEGORY_PAGINATION = gql`
  query ($page: Int!, $perPage: Int!, $filter: FilterFindManyCategoryInput) {
    categoryPagination(
      page: $page
      perPage: $perPage
      sort: CREATEDAT_DESC
      filter: $filter
    ) {
      count
      items {
        _id
        id
        name
        type
        title {
          _id
          en
          zh
        }
        pictureId
        picture {
          _id
          key
          category
        }
        pictureIds
        pictures {
          _id
          key
          category
        }
        warehouseId
        productIds
        ranking
        deleted
        hashtags
        color
        containerId
      }
    }
  }
`;

export const ALLOWED_CATEGORY = gql`
  query {
    allowedVariantsCategory {
      _id
      id
      deleted
      pictures {
        _id
        key
      }
      name
      ranking
      hashtags
      title {
        _id
        zh
        en
      }
    }
  }
`;

export const QUERY_CATEGORY_DEFAULT_ID = gql`
  query ($filter: FilterFindOneCategoryInput) {
    categoryOne(filter: $filter) {
      _id
      name
    }
  }
`;

export const CATEGORY_QUERY_NAME_ONLY = gql`
  query ($filter: FilterFindManyCategoryInput) {
    categoryMany(filter: $filter) {
      _id
      name
    }
  }
`;

export const CATEGORY_QUERY = gql`
  query ($filter: FilterFindManyCategoryInput) {
    categoryMany(filter: $filter) {
      _id
      name
      title {
        _id
        zh
        en
      }
      pictureId
      picture {
        _id
        key
        category
      }
      pictureIds
      pictures {
        _id
        key
        category
      }
      productIds
      ranking
      deleted
      type
      hashtags
      warehouseId
      color
      products {
        _id
        name {
          _id
          zh
          en
        }
        ingredients
        allergen
        hashtags
        productVariants {
          _id
          name {
            _id
            zh
            en
          }
          listPrice
          shape
        }
      }
      container {
        _id
        capacity
        minimumAddQuantity
        discountPercentage
      }
    }
  }
`;

export const CATEGORY_CREATE = gql`
  mutation (
    $name: String!
    $ranking: Float!
    $titleChinese: String!
    $titleEnglish: String!
    $type: String!
    $hashtags: [String]
    $pictureId: String
    $warehouseId: MongoID
    $color: String
    $containerId: MongoID
  ) {
    categoryCreateOne(
      name: $name
      ranking: $ranking
      titleChinese: $titleChinese
      titleEnglish: $titleEnglish
      type: $type
      hashtags: $hashtags
      pictureId: $pictureId
      warehouseId: $warehouseId
      color: $color
      containerId: $containerId
    ) {
      _id
      name
    }
  }
`;

export const CATEGORY_UPDATE = gql`
  mutation (
    $categoryId: MongoID!
    $name: String!
    $ranking: Float!
    $titleChinese: String!
    $titleEnglish: String!
    $type: String!
    $hashtags: [String]
    $pictureIds: [String]
    $color: String
    $containerId: MongoID
  ) {
    categoryUpdate(
      categoryId: $categoryId
      name: $name
      ranking: $ranking
      titleChinese: $titleChinese
      titleEnglish: $titleEnglish
      type: $type
      hashtags: $hashtags
      pictureIds: $pictureIds
      color: $color
      containerId: $containerId
    ) {
      _id
      name
    }
  }
`;

export const CATEGORY_RESET_RANKING = gql`
  mutation ($secret: String!) {
    categoryResetRanking(secret: $secret) {
      _id
    }
  }
`;

export const CATEGORY_ADD_PRODUCT = gql`
  mutation ($categoryId: MongoID!, $productId: MongoID!) {
    categoryAddProduct(categoryId: $categoryId, productId: $productId) {
      _id
    }
  }
`;

export const CATEGORY_DELETE_OR_RESTORE = gql`
  mutation ($id: MongoID!) {
    categoryDeleteOrRestoreOne(_id: $id) {
      _id
    }
  }
`;

export const CATEGORY_REMOVE_PRODUCT = gql`
  mutation ($categoryId: MongoID!, $productId: MongoID!) {
    categoryRemoveProduct(categoryId: $categoryId, productId: $productId) {
      _id
    }
  }
`;
