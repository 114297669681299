/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Row } from 'antd';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BUCKET_URL, BUCKET_CDN_URL } from '../../../config';
import { WAREHOUSE_PRODUCTS_QUERY_STORE } from '../../../api/warehouse';
import { apolloClient } from '../../../util';

function GroupBuyingMenu(props) {
  const { warehouse, language } = props;
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const image = `${BUCKET_CDN_URL}media/products/%E9%85%B8%E8%8F%9C%E7%8C%AA%E8%82%89.jpeg`;

  useEffect(() => {
    async function fetchWarehouseProducts() {
      const { data } = await apolloClient.query({
        query: WAREHOUSE_PRODUCTS_QUERY_STORE,
        variables: { input: warehouse._id },
        fetchPolicy: 'network-only',
      });
      if (data && data.warehouseById) {
        const productList = data.warehouseById.allowedStoreVariants;
        const categoryMap = new Map();
        productList.forEach((element) => {
          const newElement = element;
          newElement.count = 0;
          newElement.stock = 0;
          newElement.inSufficient = false;
          newElement.inSufficientStock = 0;
          if (element.product.category) {
            newElement.category = element.product.category;
          }
          categoryMap.set(element.product.category.name, {
            name: element.product.category.name,
            title: element.product.category.title,
            ranking: element.product.category.ranking,
            _id: element.product.category._id,
          });
          return newElement;
        });
        const categoriesArray = _.orderBy(
          Array.from(categoryMap.values()),
          ['ranking'],
          ['desc']
        );
        setCategories(categoriesArray);
        setProducts(productList);
      }
    }
    if (warehouse && categories.length === 0) {
      fetchWarehouseProducts();
    }
  }, [warehouse, categories]);

  const renderProduct = (e) => {
    return (
      <Col
        css={{ padding: '13px 0' }}
        xxl={6}
        xl={6}
        lg={8}
        md={8}
        sm={12}
        xs={12}
        key={e._id}
      >
        <Card
          bodyStyle={
            isMobile
              ? { padding: '5px', fontSize: '13px' }
              : { padding: '10px', fontSize: '18px' }
          }
          cover={
            <img
              alt="dish"
              src={
                e.product.picture ? BUCKET_URL + e.product.picture.key : image
              }
            />
          }
          // actions={this.getCardArray(e)}
        >
          <div>
            <div
              css={
                isMobile
                  ? { height: '55px', marginBottom: '20px' }
                  : { height: '55px' }
              }
            >
              {e.name[language]}
            </div>
            <div>{`$${e.listPrice}`}</div>
          </div>
        </Card>
      </Col>
    );
  };

  const renderStoreMenu = (
    <Row
      type="flex"
      gutter={15}
      style={{ padding: '0px 10px', backgroundColor: 'white' }}
    >
      {categories.map((category) => {
        return (
          <div key={category._id}>
            <div
              id={category.name}
              css={{
                width: '100%',
                fontSize: '20px',
                fontWeight: 'bold',
                lineHeight: '1.4',
              }}
            >
              {language === 'zh' ? category.title.zh : category.title.en}
            </div>
            {products.map((e) => {
              if (category.name === e.category.name) {
                return renderProduct(e);
              }
              return null;
            })}
          </div>
        );
      })}
    </Row>
  );

  return (
    <div>
      {warehouse && (
        <div>
          <div css={{ maxWidth: '800px', margin: '20px auto' }}>
            {renderStoreMenu}
          </div>
        </div>
      )}
    </div>
  );
}

GroupBuyingMenu.defaultProps = {
  warehouse: null,
};

GroupBuyingMenu.propTypes = {
  warehouse: PropTypes.shape({
    _id: PropTypes.string,
  }),
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(GroupBuyingMenu);
