/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  SoundOutlined,
  GiftOutlined,
  TagsOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Form, Button, Modal, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import FormBuilder from 'antd-form-builder';
import { GUEST_SIGN_UP, USER_FIND_BY_ID } from '../../../api/user';
import { SUBSCRIBER_CREATE, SUBSCRIBER_TAG } from '../../../api/subscriber';
import { apolloClient } from '../../../util';
import * as actions from '../../../store/actions';

const EmailSignUp = (props) => {
  const {
    intl,
    location: { search },
    history,
  } = props;
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.lang);
  const loginRedirectPage = useSelector(
    (state) => state.auth.loginRedirectPage
  );
  const auth = (email, password) => dispatch(actions.auth(email, password));
  const getCurrentUser = () => dispatch(actions.getCurrentUser());
  const saveLinkForLogin = (input) => dispatch(actions.saveLinkForLogin(input));
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchUser = async (id) => {
      try {
        const {
          data: { userById },
        } = await apolloClient.query({
          query: USER_FIND_BY_ID,
          variables: {
            id,
          },
        });
        console.log(userById);
        setEmail(userById.guestUserEmail);
      } catch (err) {
        console.log(err);
      }
    };
    if (search && search.includes('userId=')) {
      const id = search.replace('?userId=', '');
      setUserId(id);
      fetchUser(id);
    }
  }, [search]);

  const handleClickSignUpButton = async (values) => {
    setPending(true);
    const { password, invitationCode } = values;
    console.log(values);
    try {
      const {
        data: { guestConvertToCustomer },
      } = await apolloClient.mutate({
        mutation: GUEST_SIGN_UP,
        variables: {
          userId,
          password,
          invitationCode:
            invitationCode && invitationCode.length === 24
              ? null
              : invitationCode,
        },
      });
      console.log(guestConvertToCustomer);
      try {
        await apolloClient.mutate({
          mutation: SUBSCRIBER_CREATE,
          variables: { email },
        });
        if (invitationCode && invitationCode.length !== 24) {
          // tag it
          await apolloClient.mutate({
            mutation: SUBSCRIBER_TAG,
            variables: {
              email,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
      await auth(email, password);
      await getCurrentUser();
      if (copyLink.length > 2) {
        // jump to last path
        history.push(copyLink);
        // remove last path
        saveLinkForLogin('');
      }
      setPending(false);
    } catch (error) {
      console.log(error);
      setPending(false);
      if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].message === 'fakeEmailError'
      ) {
        Modal.warning({
          content: intl.formatMessage({ id: 'fakeEmailError' }),
        });
      } else if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].message === 'invitationCodeInvalid'
      ) {
        Modal.warning({
          content:
            language === 'zh' ? '邀请码不正确' : 'Invitation Code Invalid',
        });
      } else if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].message === 'Registered'
      ) {
        Modal.warning({
          content:
            language === 'zh'
              ? '邮件已注册，请直接登录'
              : 'Email has already registered, please log in directly.',
        });
      } else {
        message.error('Can not sign up, please try again');
      }
    }
    // const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
    // let showModal = false;
    // create new user
    // try {
    //   const {
    //     data: { userCreateOne },
    //   } = await apolloClient.mutate({
    //     mutation: USER_CREATE,
    //     variables: {
    //       email,
    //       password,
    //       groupType: 'customer',
    //       invitationCode:
    //         invitationCode && invitationCode.length === 24
    //           ? null
    //           : invitationCode,
    //     },
    //   });
    //   console.log(userCreateOne);
    //   try {
    //     await apolloClient.mutate({
    //       mutation: SUBSCRIBER_CREATE,
    //       variables: { email },
    //     });
    //     if (invitationCode && invitationCode.length !== 24) {
    //       // tag it
    //       await apolloClient.mutate({
    //         mutation: SUBSCRIBER_TAG,
    //         variables: {
    //           email,
    //         },
    //       });
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   if (invitationCode && invitationCode.length === 24) {
    //     await this.addInvitationCodeAndShowProp(email);
    //   }
    //   await auth(email, password);
    //   await getCurrentUser();
    //   if (copyLink.length > 2) {
    //     // jump to last path
    //     history.push(copyLink);
    //     const { saveLinkForLogin } = this.props;
    //     // remove last path
    //     saveLinkForLogin('');
    //   }
    // } catch (error) {
    //   console.log(error);
    //   this.setState({ pending: false });
    //   const { language } = this.props;

    //   if (
    //     error.graphQLErrors &&
    //     error.graphQLErrors.length > 0 &&
    //     error.graphQLErrors[0].message === 'fakeEmailError'
    //   ) {
    //     Modal.warning({
    //       content: intl.formatMessage({ id: 'fakeEmailError' }),
    //     });
    //   } else if (
    //     error.graphQLErrors &&
    //     error.graphQLErrors.length > 0 &&
    //     error.graphQLErrors[0].message === 'invitationCodeInvalid'
    //   ) {
    //     Modal.warning({
    //       content:
    //         language === 'zh' ? '邀请码不正确' : 'Invitation Code Invalid',
    //     });
    //   } else {
    //     message.error('Can not sign up, please try again');
    //   }
    // }
  };
  const meta = {
    fields: [
      {
        key: 'email',
        render: () => {
          return (
            <div
              css={{
                border: '1px solid #ccc',
                lineHeight: '1.5715',
                padding: '4px 11px',
                marginBottom: '24px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <MailOutlined
                style={{ color: 'rgba(0,0,0,.25)', marginRight: '4px' }}
              />
              {email}
            </div>
          );
        },
      },
      {
        key: 'password',
        widget: 'password',
        onChange: () => {
          if (form.isFieldTouched('confirmPassword')) {
            form.validateFields(['confirmPassword']);
          }
        },
        widgetProps: {
          prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
        },
        placeholder: intl.formatMessage({ id: 'password' }),
        rules: [
          // This is equivalent with "required: true"
          {
            required: true,
            min: 6,
            message: intl.formatMessage({ id: 'plsPassword' }),
          },
          {
            required: true,
            message: 'Password is required',
          },
        ],
      },
      {
        key: 'confirmPassword',
        widget: 'password',
        required: true,
        widgetProps: {
          prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
        },
        placeholder: intl.formatMessage({ id: 'confirmPassword' }),
        rules: [
          {
            validator: (rule, value, callback) => {
              if (value !== form.getFieldValue('password')) {
                callback(new Error('Two passwords are inconsistent.'));
              } else {
                callback();
              }
            },
          },
        ],
      },
      {
        key: 'invitationCode',
        widgetProps: {
          prefix: <SoundOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
        },
        placeholder: intl.formatMessage({ id: 'invitationCode' }),
      },
    ],
  };
  return (
    <div
      style={
        isMobile
          ? { width: '90%', margin: '50% auto' }
          : { maxWidth: '300px', margin: 'auto', minHeight: '100vh' }
      }
    >
      <div
        css={{
          margin: '20px 0px 5px 0px',
          fontSize: '2em',
          fontWeight: '600',
        }}
      >
        <FormattedMessage id="welcomeSignUp" /> :P
      </div>
      <div css={{ margin: '0px 0px 10px 0px' }}>
        <div>{language === 'zh' ? '注册即享' : 'Sign Up Benefits'}</div>
        <div>
          <GiftOutlined />{' '}
          {language === 'zh'
            ? '享受专属优惠折扣'
            : 'Receive exclusive coupons and rewards'}
        </div>
        <div>
          <TagsOutlined />{' '}
          {language === 'zh'
            ? '随时随地管理您的历史订单'
            : 'Manage your past and present orders'}
        </div>
        <div>
          <WalletOutlined />{' '}
          {language === 'zh'
            ? '安全保存您的地址和支付方式，更快更便捷'
            : 'Easier checkout with your saved payment method and shipping address'}
        </div>
      </div>
      <Form form={form} onFinish={handleClickSignUpButton}>
        <FormBuilder meta={meta} form={form} />
        <Form.Item>
          <Button
            css={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            disabled={pending}
          >
            {pending ? (
              <React.Fragment>
                <LoadingOutlined />{' '}
              </React.Fragment>
            ) : null}
            <FormattedMessage id="signUp" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

EmailSignUp.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default injectIntl(EmailSignUp);
