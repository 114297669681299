export default {
  en: {
    becomeGroupBuyingLeader: 'Become Leader',
    unlockedDiscount: 'Unlocked Discount',
    unlockedMaxDiscount: 'This is Maximum Discount',
    nextUnlockedDiscount: 'Next Discount',
    nextUnlockedDiscountHeadcount:
      'Next Discount Needs {newLine} {participators} {count}',
    chargeTime: 'End Time',
    createGroupBuying: 'Create a Group',
    createGroupBuyingPlaceOrder: 'Place an order now to active your group',
    createNewGroupBuying: 'Create New Group Buying',
    cancelAndRestart: 'Cancel & Restart',
    deliveryTime: 'Delivery Time',
    finishedRefundGroup: 'The group is finished or refunded.',
    groupBuying: 'Group Buying',
    groupBuyingDescription: 'Group Buying Description',
    groupCode: 'Group Code',
    groupJoinAndPlaceOrder: 'Place an order to join the group',
    placeOrder: 'Go to Place Order',
    groupLeader: 'Group Leader',
    groupName: 'Group Name',
    groupNote: 'Note for group member(optional)',
    groupNote2: 'Note for group member',
    groupParticipatorAmount: 'Participators',
    groupStatus: 'Group Status',
    joinGroupBuying: 'Join Group Buying',
    haveJoinedGroup: 'You have joined this group!',
    nextStep: 'Next',
    openingPublicGroupBuying: 'Now Opening Public Group Buying',
    openingGroupBuying: 'Now Opening',
    pleaseTypeGroupBuyingCode: 'Type Group Buying Code',
    pleaseChooseDate: 'Please Choose Date',
    search: 'Search',
    selectYouDeliveryTime: 'Select You Delivery Time',
    groupOfficialOrNot: 'Friend Only',
    refundedGroup:
      "Closed and Refunded(Sum of orders' prices belows minimum order)",
    activeGroup: 'Open',
    completedGroup: 'Completed',
    meetMinimumGroup: "Sum of orders' prices satisfies minimum order",
    notMeetMinimumGroup: "Sum of orders' prices belows minimum order",
    groupBuyingEnd: 'Left',
    oneClickShare: 'Share It!',
    oneClickShared: 'Copied!',
    join: 'Join',
    groupBuyingInfo: 'Delivery Information',
    groupBuyingOrderInfo: 'Order Information',
    currentDiscount: 'Current Discount',
    myOpeningGroupBuying: 'Opening Group Buying',
    myEndedGroupBuying: 'Ended Group Buying',
    successfullyCompleteGroupBuying: 'Group Buying Successfully',
    failedGroupBuying: 'Group Buying Failed, Refunded',
    canNotFoundGroupBuying: 'Can Not Find Group Buying',
    chooseAddressOrCreate: 'Please choose or create a contact',
    checkGuide: 'Check Group Buying Guide',
    doNotShowAgain: 'Do Not Show Again',
  },
  zh: {
    becomeGroupBuyingLeader: '成为团长',
    unlockedDiscount: '已解锁折扣',
    unlockedMaxDiscount: '已达到最大折扣',
    nextUnlockedDiscount: '下一级折扣',
    nextUnlockedDiscountHeadcount:
      '下一级折扣一共需要 {newLine} {participators} {count}',
    chargeTime: '截单时间',
    createGroupBuying: '开团',
    createGroupBuyingPlaceOrder: '下一单来激活您的团购',
    createNewGroupBuying: '开新团',
    cancelAndRestart: '取消并重新开始',
    deliveryTime: '配送时间',
    finishedRefundGroup: '该团购已退款或者已结束',
    groupBuying: '团购',
    groupBuyingDescription: '团购说明',
    groupCode: '参团码',
    groupJoinAndPlaceOrder: '前去下单并且参团',
    placeOrder: '前去下单',
    groupLeader: '团长',
    groupName: '团名',
    groupNote: '团长留言(选填)',
    groupNote2: '团长留言',
    groupParticipatorAmount: '当前人数',
    groupStatus: '当前状态',
    joinGroupBuying: '参加团',
    haveJoinedGroup: '您已经在该团中',
    nextStep: '下一步',
    openingGroupBuying: '正在开团',
    openingPublicGroupBuying: '团购广场',
    pleaseTypeGroupBuyingCode: '请输入邀请码',
    pleaseChooseDate: '请选择日期',
    search: '搜索',
    selectYouDeliveryTime: '选择您的配送时间',
    groupOfficialOrNot: '私密团购',
    refundedGroup: '未成团已退款(订单不能满足起送价)',
    activeGroup: '开团中',
    completedGroup: '已截团',
    meetMinimumGroup: '订单总价满足起送价',
    notMeetMinimumGroup: '订单总价不能满足起送价',
    groupBuyingEnd: '后结束',
    oneClickShare: '一键分享',
    oneClickShared: '已复制',
    join: '加入',
    groupBuyingInfo: '团购详情',
    groupBuyingOrderInfo: '订单详情',
    currentDiscount: '当前减免',
    myOpeningGroupBuying: '正在进行的团购',
    myEndedGroupBuying: '已经结束的团购',
    successfullyCompleteGroupBuying: '团购成功',
    failedGroupBuying: '团购不成功，已经退款',
    canNotFoundGroupBuying: '此团购不存在，或者团购已经结束',
    chooseAddressOrCreate: '请您添加或者选择一个联系方式',
    checkGuide: '查看团购的游戏规则',
    doNotShowAgain: '下次不再显示',
  },
};
