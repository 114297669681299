import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const checkLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage;
  if (userLang.indexOf('zh') !== -1) {
    return 'zh';
  }
  return 'en';
};

const initialState = {
  lang: checkLanguage(),
};

export const updateLang = (state, action) => {
  const updatedState = {
    lang: action.lang,
  };
  return updateObject(state, updatedState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE_SET:
      return updateLang(state, action);
    default:
      return state;
  }
};

export default reducer;
