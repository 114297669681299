/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import {
//   CreditCardOutlined,
//   QuestionCircleOutlined,
//   ShoppingOutlined,
//   SmileOutlined,
//   UserOutlined,
// } from '@ant-design/icons';

import { Carousel, Button, Row, Col, message } from 'antd';
// import { Steps as MobileSteps } from 'antd-mobile';
// import Typed from 'react-typed';
// import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import lodash from 'lodash';
import { BANNER_FIND_MANY } from '../../../api/banner';
import { apolloClient, getCustomerPath } from '../../../util';
import { BUCKET_CDN_URL, BUCKET_URL } from '../../../config';
import GoogleLogo from '../../../assets/images/icons/google.png';
import AppleLogo from '../../../assets/images/icons/apple.png';
import FacebookLogo from '../../../assets/images/icons/facebook.png';
import TeslaLogo from '../../../assets/images/icons/tesla.png';
import whatWeFaceZh from '../../../assets/images/whatWeFaceZh.png';
import whatWeFaceEn from '../../../assets/images/whatWeFaceEn.png';
import './Catering.less';

export class Catering extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      slides: [],
    };
  }

  componentDidMount = async () => {
    console.log('Catering page just run once');
    await this.handleFetchCateringBanners();
  };

  handleFetchCateringBanners = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'cateringHome' } },
      });
      // sort banner
      const rawData = bannerFindMany;
      const slides = lodash.orderBy(
        rawData,
        ['ranking', 'createdAt'],
        ['asc', 'asc']
      );
      this.setState({ slides });
      console.log(slides);
    } catch (error) {
      console.log(error);
      message.error('Can not fetch banner');
    }
  };

  render() {
    const { language } = this.props;
    const { slides } = this.state;
    // const { innerHeight: height } = window;
    return (
      <div className="catering-page">
        <div style={{ position: 'relative' }} className="selection-margin-b">
          <Carousel
            autoplay
            dotPosition="bottom"
            className="slide-container"
            style={{
              borderRadius: '25px',
              overflow: 'hidden',
            }}
          >
            {language === 'en' ? (
              <div className="slide-container">
                <Link to={getCustomerPath('catering-menu')}>
                  <img
                    className="slide-image"
                    src={`${BUCKET_URL}images/catering/catering-en.png`}
                    alt="catering"
                  />
                </Link>
              </div>
            ) : (
              <div className="slide-container">
                <Link to={getCustomerPath('catering-menu')}>
                  <img
                    className="slide-image"
                    src={`${BUCKET_URL}images/catering/catering-zh.png`}
                    alt="catering"
                  />
                </Link>
              </div>
            )}
            {slides.map((slide) => {
              return slide.banners.map((banner) => {
                console.log(banner);
                if (
                  banner.language === language &&
                  banner.device === 'desktop'
                ) {
                  return (
                    <div className="slide-container">
                      <Link to={getCustomerPath('catering-menu')}>
                        <img
                          className="slide-image"
                          src={`${BUCKET_URL}${banner.picture.key}`}
                          alt="catering"
                        />
                      </Link>
                    </div>
                  );
                }
                return null;
              });
            })}
          </Carousel>
          {/* <Button type="primary" className="started-button common-button">
            <FormattedMessage id="getStartedNow" />
          </Button> */}
        </div>
        <div className="catering-what-we-face-and-resolve">
          <div className="we-face-and-resolve-title">
            <div>
              <FormattedMessage id="cateringWhatWeFaceAndResolveLineOne" />
            </div>
            <div>
              <FormattedMessage id="cateringWhatWeFaceAndResolveLineTwo" />
            </div>
          </div>
          <div className="we-face-and-resolve-picture">
            {language === 'zh' ? (
              <img src={whatWeFaceZh} alt="what we face" />
            ) : (
              <img src={whatWeFaceEn} alt="what we face" />
            )}
          </div>
        </div>
        <div className="common-title selection-margin-b">
          <FormattedMessage id="cateringHowItWorks" />
        </div>
        <div className="how-it-work-container">
          <div className="step-container">
            <div className="step-container-inner">
              <div className="step-image">
                <img
                  src={`${BUCKET_URL}images/catering/step1.svg`}
                  alt="Step One"
                />
              </div>

              <div className="step-title">
                <FormattedMessage id="cateringStepOneTitle" />
              </div>
              <div>
                <FormattedMessage id="cateringStepOneDescription" />
              </div>
            </div>
          </div>
          <div className="step-container selection-margin-b">
            <div className="step-container-inner">
              <div className="step-image">
                <img
                  src={`${BUCKET_URL}images/catering/step2.svg`}
                  alt="Step One"
                />
              </div>

              <div className="step-title">
                <FormattedMessage id="cateringStepTwoTitle" />
              </div>
              <div>
                <FormattedMessage id="cateringStepTwoDescription" />
              </div>
            </div>
          </div>
          <div className="step-container">
            <div className="step-container-inner">
              <div className="step-image">
                <img
                  src={`${BUCKET_URL}images/catering/step3.svg`}
                  alt="Step One"
                />
              </div>

              <div className="step-title">
                <FormattedMessage id="cateringStepThreeTitle" />
              </div>
              <div>
                <FormattedMessage id="cateringStepThreeDescription" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }} className="selection-margin-b">
          <Link to={getCustomerPath('catering-menu')}>
            <Button type="primary" className="common-button">
              <FormattedMessage id="startOrderCatering" />
            </Button>
          </Link>
        </div>

        <div className="common-title selection-margin-b">
          <FormattedMessage id="customerCateringOrder" />
        </div>

        <div className="how-it-work-container  selection-margin-b">
          <div className="step-container">
            <div className="step-container-inner">
              <div className="step-image">
                <img
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  src={`${BUCKET_CDN_URL}images/catering/wedding1.jpg`}
                  alt="Step One"
                />
              </div>

              <div className="step-title">
                <FormattedMessage id="customerCateringWedding.titleOne" />
              </div>
              <div>
                <FormattedMessage id="customerCateringWedding.descriptionOne" />
              </div>
            </div>
          </div>
          <div className="step-container selection-margin-b">
            <div className="step-container-inner">
              <div className="step-image">
                <img
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  src={`${BUCKET_CDN_URL}images/catering/wedding2.jpg`}
                  alt="Step One"
                />
              </div>

              <div className="step-title">
                <FormattedMessage id="customerCateringWedding.titleTwo" />
              </div>
              <div>
                <FormattedMessage id="customerCateringWedding.descriptionTwo" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Link to={getCustomerPath('catering-customize')}>
            <Button
              type="primary"
              className="common-button selection-margin-b"
              style={{ minWidth: '250px' }}
            >
              <FormattedMessage id="customerCateringOrderButton" />
            </Button>
          </Link>
        </div>
        <div className="company-section">
          <div className="company-slogan">
            <FormattedMessage id="customerCateringLogo.paragraphOne" />
          </div>
          <div className="company-logo">
            <img src={AppleLogo} alt="Apple" />
            <img src={GoogleLogo} alt="Google" />
            <img src={FacebookLogo} alt="Facebook" />
            <img src={TeslaLogo} alt="Tesla" />
          </div>
          <div className="company-description">
            <div>
              <FormattedMessage id="customerCateringLogo.paragraphTwo" />
            </div>
            <div>
              <FormattedMessage id="customerCateringLogo.paragraphThree" />
            </div>
          </div>
          <div>
            <Row justify="space-around" align="middle">
              <Col span={12} style={{ textAlign: 'center' }}>
                <Link to={getCustomerPath('catering-menu')}>
                  <Button
                    css={{
                      fontSize: '24px',
                      textAlign: 'center',
                      height: '69px',
                      width: '230px',
                      borderRadius: '25px',
                    }}
                    type="primary"
                  >
                    <FormattedMessage id="customerCateringLogo.buttonOne" />
                  </Button>
                </Link>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <Link to={getCustomerPath('catering-customize')}>
                  <Button
                    css={{
                      fontSize: '24px',
                      textAlign: 'center',
                      height: '69px',
                      width: '320px',
                      borderRadius: '25px',
                    }}
                    type="primary"
                  >
                    <FormattedMessage id="customerCateringLogo.buttonTwo" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div
    //     css={{
    //       minHeight: isMobile
    //         ? `calc(${height}px - 100px )`
    //         : 'calc( 100vh - 100px)',
    //     }}
    //   >
    //     <div css={{ height: '50vh' }}>
    //       <div
    //         css={{
    //           position: 'absolute',
    //           top: '9vh',
    //           zIndex: '1',
    //           textAlign: 'center',
    //           fontWeight: '800',
    //           fontSize: '38px',
    //           fontFamily:
    //             language === 'zh' ? 'li-xia' : 'Kalam, Futura, "Georgia"',
    //           width: '100%',
    //           color: 'white',
    //           // WebkitTextStroke: '1px black',
    //           // textShadow:
    //           //   '3px 3px 0 #000,-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000',
    //           textShadow: 'rgb(0, 0, 0) 0px 0px 12px',
    //         }}
    //       >
    //         <FormattedMessage id="cateringBunBaoTitle" />
    //       </div>
    //       <div
    //         css={{
    //           position: 'absolute',
    //           top: '16vh',
    //           zIndex: '1',
    //           textAlign: 'center',
    //           fontWeight: '700',
    //           fontSize: '24px',
    //           width: '100%',
    //           color: 'white',
    //           // WebkitTextStroke: '1px black',
    //           // textShadow:
    //           //   '1px 1px 0 #000,-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000',
    //           textShadow: 'rgb(0, 0, 0) 0px 0px 12px',
    //         }}
    //       >
    //         <Typed
    //           strings={
    //             language === 'zh'
    //               ? [
    //                   '丰富的餐品选择',
    //                   '专业的送餐团队',
    //                   '品质保证，吃的放心',
    //                   '告诉我们您的需求',
    //                   '让您更加高效地专注于核心工作',
    //                 ]
    //               : [
    //                   'Taste meet excellence.',
    //                   'Taste something fresh.',
    //                   'Making food special.',
    //                   'Taste the delicious.',
    //                   'Taste the new.',
    //                   'Taste the Tasted.',
    //                   'We can create these remarkable moments.',
    //                   'Where food celebrates life.',
    //                   'Catering for all.',
    //                 ]
    //           }
    //           typeSpeed={20}
    //           backSpeed={20}
    //           loop
    //           smartBackspace={false}
    //         />
    //       </div>
    //       <div
    //         css={{
    //           position: 'absolute',
    //           top: '23vh',
    //           zIndex: '1',
    //           width: '100%',
    //           textAlign: 'center',
    //         }}
    //       >
    //         <div
    //           css={{
    //             display: 'inline-block',
    //             padding: '5px',
    //             margin: '5px',
    //           }}
    //         >
    //           <Link to={getCustomerPath('catering-menu')}>
    //             <div
    //               css={{
    //                 width: '100%',
    //                 fontSize: '20px',
    //                 fontWeight: '700',
    //                 color: 'white',
    //                 backgroundColor: '#DF242F',
    //                 padding: '10px',
    //                 borderRadius: '5px',
    //               }}
    //             >
    //               <div>
    //                 <FormattedMessage id="exploreMenu" />
    //                 {!isMobile && (
    //                   <div css={{ fontSize: '15px' }}>
    //                     <FormattedMessage id="cateringButtonExplain" />
    //                   </div>
    //                 )}
    //               </div>
    //             </div>
    //           </Link>
    //         </div>
    //         <div
    //           css={{
    //             display: 'inline-block',
    //             padding: '5px',
    //             margin: '5px',
    //           }}
    //         >
    //           <Link to={getCustomerPath('group-buying')}>
    //             <Tooltip
    //               placement="bottom"
    //               title={() => {
    //                 return (
    //                   <div>
    //                     <div>
    //                       1.
    //                       <FormattedMessage id="groupBuyDescriptionOne" />{' '}
    //                     </div>
    //                     <div>
    //                       2. <FormattedMessage id="groupBuyDescriptionTwo" />
    //                     </div>
    //                     <div>
    //                       3.
    //                       <FormattedMessage id="groupBuyDescriptionThree" />
    //                     </div>
    //                   </div>
    //                 );
    //               }}
    //               arrowPointAtCenter
    //             >
    //               <div
    //                 css={{
    //                   width: '100%',
    //                   fontSize: '20px',
    //                   fontWeight: '700',
    //                   background: '#DF242F',
    //                   color: 'white',
    //                   padding: '10px',
    //                   borderRadius: '5px',
    //                 }}
    //               >
    //                 <div>
    //                   <FormattedMessage id="groupBuying" />{' '}
    //                   <QuestionCircleOutlined />
    //                   {!isMobile && (
    //                     <div css={{ fontSize: '15px' }}>
    //                       <FormattedMessage id="groupBuyingButtonExplain" />
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //             </Tooltip>
    //           </Link>
    //         </div>
    //       </div>
    //       <Carousel autoplay dotPosition="left">
    //         <div css={{ width: '100%' }}>
    //           <img
    //             css={{
    //               minHeight: '50vh',
    //               height: '50vh',
    //               width: '100%',
    //               objectFit: 'cover',
    //             }}
    //             src={`${BUCKET_CDN_URL}slides/catering/picture_one.png`}
    //             alt="catering"
    //           />
    //         </div>
    //         <div css={{ width: '100%' }}>
    //           <img
    //             css={{
    //               minHeight: '50vh',
    //               height: '50vh',
    //               width: '100%',
    //               objectFit: 'cover',
    //             }}
    //             src={`${BUCKET_CDN_URL}slides/catering/picture_two.png`}
    //             alt="catering"
    //           />
    //         </div>
    //         <div css={{ width: '100%' }}>
    //           <img
    //             css={{
    //               minHeight: '50vh',
    //               height: '50vh',
    //               width: '100%',
    //               objectFit: 'cover',
    //             }}
    //             src={`${BUCKET_CDN_URL}slides/catering/picture_three.png`}
    //             alt="catering"
    //           />
    //         </div>
    //         <div css={{ width: '100%' }}>
    //           <img
    //             css={{
    //               minHeight: '50vh',
    //               height: '50vh',
    //               width: '100%',
    //               objectFit: 'cover',
    //             }}
    //             src={`${BUCKET_CDN_URL}slides/catering/picture_four.png`}
    //             alt="catering"
    //           />
    //         </div>
    //       </Carousel>
    //     </div>
    //     <Row>
    //       <Row>
    //         <Col>
    //           <div
    //             css={{
    //               fontSize: '30px',
    //               fontWeight: '500',
    //               margin: '30px 10px 0px 10px',
    //               textAlign: 'center',
    //             }}
    //           >
    //             <FormattedMessage id="cateringHowItWorks" />
    //           </div>
    //         </Col>
    //       </Row>
    //       <Row
    //         css={{
    //           margin: '0px 0 40px 0',
    //         }}
    //       >
    //         <Col
    //           xs={24}
    //           sm={24}
    //           md={8}
    //           lg={8}
    //           xl={8}
    //           css={
    //             isMobile
    //               ? { padding: '0px 5px 10px 5px' }
    //               : { padding: '0px 60px 10px 60px' }
    //           }
    //         >
    //           <Row>
    //             <Col>
    //               <img
    //                 css={{
    //                   maxWidth: '500px',
    //                   margin: 'auto',
    //                   display: 'block',
    //                 }}
    //                 width="100%"
    //                 src={`${BUCKET_CDN_URL}images/catering/step1.svg`}
    //                 alt="Step One"
    //               />
    //             </Col>
    //             <Col
    //               css={{
    //                 textAlign: 'center',
    //                 color: '#DF242F',
    //                 fontWeight: '500',
    //                 fontSize: '20px',
    //                 lineHeight: '1.5',
    //               }}
    //             >
    //               <FormattedMessage id="cateringStepOneTitle" />
    //             </Col>
    //             <Col>
    //               <FormattedMessage id="cateringStepOneDescription" />
    //             </Col>
    //             <Col css={{ marginTop: '10px 0' }}>
    //               {isMobile ? (
    //                 <MobileSteps
    //                   direction="horizontal"
    //                   size="small"
    //                   css={{ fontSize: '10px' }}
    //                 >
    //                   <MobileSteps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.signUp" />
    //                     }
    //                     icon={<UserOutlined />}
    //                   />
    //                   <MobileSteps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.order" />
    //                     }
    //                     icon={<ShoppingOutlined />}
    //                   />
    //                   <MobileSteps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.pay" />
    //                     }
    //                     icon={<CreditCardOutlined />}
    //                   />
    //                   <MobileSteps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.eat" />
    //                     }
    //                     icon={<SmileOutlined />}
    //                   />
    //                 </MobileSteps>
    //               ) : (
    //                 <Steps>
    //                   <Steps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.signUp" />
    //                     }
    //                     icon={<UserOutlined />}
    //                   />
    //                   <Steps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.order" />
    //                     }
    //                     icon={<ShoppingOutlined />}
    //                   />
    //                   <Steps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.pay" />
    //                     }
    //                     icon={<CreditCardOutlined />}
    //                   />
    //                   <Steps.Step
    //                     status="finish"
    //                     title={
    //                       <FormattedMessage id="cateringStepOneBottom.eat" />
    //                     }
    //                     icon={<SmileOutlined />}
    //                   />
    //                 </Steps>
    //               )}
    //             </Col>
    //           </Row>
    //         </Col>
    //         <Col
    //           xs={24}
    //           sm={24}
    //           md={8}
    //           lg={8}
    //           xl={8}
    //           css={{ padding: '0px 60px 10px 60px' }}
    //         >
    //           <Row>
    //             <Col>
    //               <img
    //                 css={{
    //                   maxWidth: '500px',
    //                   margin: 'auto',
    //                   display: 'block',
    //                 }}
    //                 width="100%"
    //                 src={`${BUCKET_CDN_URL}images/catering/step2.svg`}
    //                 alt="Step Two"
    //               />
    //             </Col>
    //             <Col
    //               css={{
    //                 textAlign: 'center',
    //                 color: '#DF242F',
    //                 fontWeight: '500',
    //                 fontSize: '20px',
    //                 lineHeight: '1.5',
    //               }}
    //             >
    //               <FormattedMessage id="cateringStepTwoTitle" />
    //             </Col>
    //             <Col>
    //               <FormattedMessage id="cateringStepTwoDescription" />
    //             </Col>
    //             <Col css={{ textAlign: 'center', marginTop: '20px' }}>
    //               <Tag size="large" color="#DF242F">
    //                 <FormattedMessage id="cateringStepTwoBottom" />
    //               </Tag>
    //             </Col>
    //           </Row>
    //         </Col>
    //         <Col
    //           xs={24}
    //           sm={24}
    //           md={8}
    //           lg={8}
    //           xl={8}
    //           css={{ padding: '0px 60px 10px 60px' }}
    //         >
    //           <Row>
    //             <Col>
    //               <img
    //                 css={{
    //                   maxWidth: '500px',
    //                   margin: 'auto',
    //                   display: 'block',
    //                 }}
    //                 width="100%"
    //                 src={`${BUCKET_CDN_URL}images/catering/step3.svg`}
    //                 alt="Step Three"
    //               />
    //             </Col>
    //             <Col
    //               css={{
    //                 textAlign: 'center',
    //                 color: '#DF242F',
    //                 fontWeight: '500',
    //                 fontSize: '20px',
    //                 lineHeight: '1.5',
    //               }}
    //             >
    //               <FormattedMessage id="cateringStepThreeTitle" />
    //             </Col>
    //             <Col>
    //               <FormattedMessage id="cateringStepThreeDescription" />
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     </Row>
    //   </div>
    // );
  }
}

Catering.defaultProps = {
  // token: null,
};

Catering.propTypes = {
  // token: PropTypes.string,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  // token: state.auth.token,
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(Catering);
