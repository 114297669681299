/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  EditOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
// import Countdown from 'react-countdown';
import { Button, Progress, Collapse, Statistic } from 'antd';
import _ from 'lodash';
import * as actions from '../../../store/actions';
import { getCustomerPath } from '../../../util';
import { successIcon, failedIcon } from '../../SmallComponents/SmallComponents';
import redUser from '../../../assets/images/icons/userRed.svg';
// import grayUser from '../../../assets/images/icons/userGray.svg';
import {
  addressFormatterOnlyAddress,
  addressFormatterOnlyName,
} from '../../../helper/Formatter/Formatter';

const { Panel } = Collapse;
const { Countdown } = Statistic;

const getIntlIdForGroup = (status) => {
  switch (status) {
    case 'active':
      return 'activeGroup';
    case 'refunded':
      return 'refundedGroup';
    case 'finished':
      return 'completedGroup';
    default:
      return 'undefined';
  }
};

const getPercentage = (participatorsLength, discountRule) => {
  // console.log('participatorsLength',participatorsLength);
  let singleRank;
  if (participatorsLength !== null && participatorsLength >= 0) {
    for (let i = 0; i < discountRule.length; i += 1) {
      singleRank = discountRule[i];
      if (participatorsLength < singleRank.participatorAmount) {
        // console.log('next',singleRank)
        return {
          color: '#c40202',
          discount:
            i === 0 ? 0 : (discountRule[i - 1].discount.amount * 1.0) / 100,
          nextDiscount: (singleRank.discount.amount * 1.0) / 100,
          nextHeadcount: singleRank.participatorAmount,
          percentage:
            (participatorsLength /
              discountRule[discountRule.length - 1].participatorAmount) *
            100,
        };
        // return {
        //   color: '#c40202',
        //   discount: (singleRank.discount.amount * 1.0) / 100,
        //   nextDiscount:
        //     i < discountRule.length - 1
        //       ? (discountRule[i + 1].discount.amount * 1.0) / 100
        //       : -1,
        //   nextHeadcount:
        //     i < discountRule.length - 1
        //       ? discountRule[i + 1].participatorAmount
        //       : -1,
        //   percentage:
        //     (singleRank.participatorAmount /
        //       discountRule[discountRule.length - 1].participatorAmount) *
        //     100,
        //   // percentage: 10
        // };
      }
    }
  }
  return {
    color: '#c40202',
    discount: (singleRank.discount.amount * 1.0) / 100,
    nextDiscount: -1,
    nextHeadcount: -1,
    percentage: 100,
    // percentage: 10
  };
};

const customPanelStyle = {
  background: '#ffffff',
  borderRadius: 4,
  border: 0,
  overflow: 'hidden',
  textAlign: 'left',
};

function GroupInfoBox(props) {
  const {
    element,
    joinInGroup,
    language,
    discountRule,
    userId,
    intl,
    // saveLinkForLogin,
  } = props;
  // console.log('element', element);
  // console.log('discountRule',discountRule);
  const history = useHistory();
  const [copied, setCopied] = useState(false);
  const [link, setLink] = useState('');

  let myOrderId;
  let myOrderAmount;
  let myOrderDiscount;
  let rawDiscount = 0;
  let displayDiscount = 0;
  if (myOrderDiscount > 0) {
    displayDiscount = myOrderDiscount;
  }
  if (userId) {
    element.participators.forEach((participator) => {
      if (participator.oneOrder && participator.userId === userId) {
        myOrderId = participator.oneOrder._id;
        myOrderAmount = participator.oneOrder.subtotal;
        myOrderDiscount = participator.oneOrder.discountAmount;
      }
    });
  }
  useEffect(() => {
    let shareLink = '';
    if (
      process.env.REACT_APP_BUILD_DEV === 'true' ||
      process.env.NODE_ENV === 'development'
    ) {
      shareLink = `https://test.bunbao.com/group-buying?code=${element.displayId}`;
    } else {
      shareLink = `https://bunbao.com/group-buying?code=${element.displayId}`;
    }
    setLink(shareLink);
  }, [element.displayId]);

  const shareMenu = (displayId) => {
    if (navigator.share) {
      if (language === 'zh') {
        navigator
          .share({
            title: '快来加入我的棒!包团购吧！参团人数越多，优惠幅度越大！',
            text: `快来加入我的棒!包团购吧！参团人数越多，优惠幅度越大！参团码：${displayId}；点链接一键参与：${link}`,
            url: link,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        navigator
          .share({
            title: `Join BunBao Group Buy! Group up with your friends to unlock more discount and receive free shipping!`,
            text: `Join BunBao Group Buy! Group up with your friends to unlock more discount and receive free shipping! Group Buy ID: ${displayId}; Click on link to join now: ${link}`,
            url: link,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    }
  };

  const renderDeliveryFee = () => {
    if (element.minimumDeliveryFee) {
      const total = _.sumBy(element.participators, 'oneOrder.subtotal');
      if (total > 0) {
        if (total >= element.minimumDeliveryFee) {
          return (
            <div>
              <FormattedMessage id="meetMinimumGroup" />
            </div>
          );
        }
        if (element.status === 'active') {
          return (
            <div style={{ color: 'red', fontSize: '18px', fontWeight: 'bold' }}>
              <FormattedMessage id="notMeetMinimumGroup" />
              {`$${total.toFixed(2)}/$${element.minimumDeliveryFee}`}
              <Progress
                strokeColor={{
                  from: '#FEE17F',
                  to: '#c40202',
                }}
                percent={(total / element.minimumDeliveryFee) * 100}
                status="active"
                showInfo={false}
              />
            </div>
          );
        }
        return (
          <div>
            <FormattedMessage id="notMeetMinimumGroup" />
            {`$${total.toFixed(2)}/$${element.minimumDeliveryFee}`}
          </div>
        );
      }
    }
    return null;
  };

  const renderProgressBar = () => {
    const data = getPercentage(element.participators.length, discountRule);
    // console.log(discountRule);
    // const { color, discount, nextDiscount, nextHeadcount, percentage } = data;
    const { discount, nextDiscount, nextHeadcount } = data;
    rawDiscount = discount;
    displayDiscount = parseFloat((rawDiscount * myOrderAmount).toFixed(2));
    return (
      <div>
        <div>{renderDeliveryFee()}</div>
        <div css={{ display: 'flex', padding: '0 10px' }}>
          <div css={{ width: '50%', textAlign: 'left' }}>
            <div>
              <FormattedMessage id="groupParticipatorAmount" /> <br />
              {element.participators.length}{' '}
              <img css={{ width: '24px' }} src={redUser} alt="user" />
            </div>
            <div>
              {nextDiscount >= 0 ? (
                <FormattedMessage id="unlockedDiscount" />
              ) : (
                <FormattedMessage id="unlockedMaxDiscount" />
              )}
              <br /> {(discount * 100).toFixed(0)}% off
            </div>
          </div>
          <div css={{ width: '50%', textAlign: 'right' }}>
            {element.status === 'finished' && (
              <React.Fragment>
                {displayDiscount > 0 ? (
                  <div>
                    <FormattedMessage id="subtotal" />
                    <br />
                    <del>{`$${myOrderAmount} `}</del>
                    <span>${(myOrderAmount - displayDiscount).toFixed(2)}</span>
                  </div>
                ) : (
                  <div>
                    <FormattedMessage id="subtotal" />
                    <br />${myOrderAmount}
                  </div>
                )}
                <div>
                  <FormattedMessage id="currentDiscount" />
                  <br />
                  {`$${displayDiscount}`}
                </div>
              </React.Fragment>
            )}
            {element.status === 'active' && (
              <React.Fragment>
                {nextDiscount >= 0 ? (
                  <div>
                    <FormattedMessage id="nextUnlockedDiscount" />
                    <br />
                    {(nextDiscount * 100).toFixed(0)}% off
                  </div>
                ) : null}
                {nextHeadcount >= 0 ? (
                  <div>
                    <FormattedMessage
                      id="nextUnlockedDiscountHeadcount"
                      values={{
                        newLine: <br />,
                        participators: nextHeadcount,
                        count: (
                          <img
                            css={{ width: '24px', display: 'inline-block' }}
                            src={redUser}
                            alt="user"
                          />
                        ),
                      }}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      css={{
        width: isMobile ? '100%' : '30%',
        background: 'white',
        padding: '20px 10px',
      }}
      key={nanoid(5)}
    >
      <div
        css={{
          // height: '100%',
          // border: '1px solid #DF242F',
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          padding: '10px',
          borderRadius: '5px',
          lineHeight: '2',
          display: 'flex',
          flexDirection: 'column',
          '& .group-buy-name': {
            fontSize: '20px',
            textAlign: 'center',
            fontWeight: '600',
          },
          '& .group-buy-code': {
            fontSize: '18px',
            textAlign: 'center',
          },
          '& .group-buy-delivery-time-and-charge-time': {
            marginTop: '2px',
            flexGrow: '1',
            display: 'flex',
            flexDirection: 'column-reverse',
            '& .group-buy-delivery-time': {
              fontSize: '15px',
              textAlign: 'center',
              borderRadius: '4px',
              padding: '2px',
              width: '100%',
              border: '1px solid #DF242F',
            },
            '& .group-buy-charge-time': {
              fontSize: '15px',
              textAlign: 'center',
              borderRadius: '4px',
              padding: '2px',
              marginTop: '2px',
              width: '100%',
              border: '1px solid #DF242F',
            },
          },
        }}
      >
        {(element.status === 'finished' || element.status === 'refunded') && (
          <div
            css={{
              height: '40px',
              width: '100%',
              backgroundColor:
                element.status === 'finished' ? '#6decb9' : '#ed7575',
              color: '#FFF',
              borderRadius: '4px',
              textAlign: 'center',
              fontWeight: '600',
              fontSize: '19px',
            }}
          >
            {element.status === 'finished' && (
              <FormattedMessage id="successfullyCompleteGroupBuying" />
            )}
            {element.status === 'refunded' && (
              <FormattedMessage id="failedGroupBuying" />
            )}
          </div>
        )}
        <div>
          <div className="group-buy-name">{element.name}</div>
        </div>
        <div>
          <div>
            {renderProgressBar()}
            {/* {element.participators.map(() => {
              return (
                <img
                  key={nanoid(5)}
                  css={{ width: '50px' }}
                  src={redUser}
                  alt="user icon"
                />
              );
            })}
            {Array(1)
              .fill(null)
              .map(() => {
                return (
                  <img
                    key={nanoid(5)}
                    css={{ width: '50px' }}
                    src={grayUser}
                    alt="user icon"
                  />
                );
              })} */}
          </div>
        </div>
        {element.status === 'active' && (
          <div className="group-buy-code">
            {joinInGroup ? (
              <div>
                <Button
                  shape="round"
                  size="large"
                  type="primary"
                  onClick={() => {
                    joinInGroup(element);
                  }}
                >
                  <FormattedMessage id="join" />
                </Button>
              </div>
            ) : null}
            {!joinInGroup ? (
              <CopyToClipboard
                text={
                  language === 'zh'
                    ? `快来加入我的棒!包团购吧！参团人数越多，优惠幅度越大！参团码：${element.displayId}；点链接一键参与：${link}`
                    : `Join BunBao Group Buy! Group up with your friends to unlock more discount and receive free shipping! Group Buy ID: ${element.displayId}; Click on link to join now: ${link}`
                }
                onCopy={() => {
                  setCopied(true);
                  shareMenu(element.displayId);
                }}
              >
                {copied ? (
                  <Button shape="round" size="large" type="primary">
                    {isMobile ? (
                      <FormattedMessage id="oneClickShared" />
                    ) : (
                      <FormattedMessage id="copiedAndShare" />
                    )}
                  </Button>
                ) : (
                  <Button shape="round" size="large" type="primary">
                    {isMobile ? (
                      <FormattedMessage id="oneClickShare" />
                    ) : (
                      <FormattedMessage id="copyAndShare" />
                    )}
                  </Button>
                )}
              </CopyToClipboard>
            ) : null}
          </div>
        )}
        <div css={{ textAlign: 'center ', marginTop: '10px' }}>
          {myOrderId ? (
            <div
              role="presentation"
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={async () => {
                // await processRedirect();
                history.push(
                  `${getCustomerPath(
                    'account/orders/delivery-order'
                  )}/${myOrderId}`
                );
              }}
            >
              <FormattedMessage id="clickCheckOrder" />
            </div>
          ) : null}
          {element.status === 'active' ? (
            <div
              style={{
                color: 'red',
                fontSize: '18px',
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              <FormattedMessage id={getIntlIdForGroup(element.status)} />
              {` `}
              {/* <Countdown
                css={{ color: '#red' }}
                date={moment(
                  element.owner.oneOrder.deliveryTime - 129600000
                ).toDate()}
                renderer={({ hours, minutes, seconds }) => {
                  return (
                    <span>
                      {hours} <FormattedMessage id="hours" /> {minutes}{' '}
                      <FormattedMessage id="minutes" /> {seconds}{' '}
                      <FormattedMessage id="seconds" />{' '}
                      <FormattedMessage id="groupBuyingEnd" />
                    </span>
                  );
                }}
              /> */}
              <Countdown
                css={{ color: '#red' }}
                title=""
                value={moment(
                  element.owner.oneOrder.deliveryTime - 129600000
                ).toDate()}
                format={`H ${intl.formatMessage({
                  id: 'hours',
                })} m ${intl.formatMessage({
                  id: 'minutes',
                })} s ${intl.formatMessage({
                  id: 'seconds',
                })} ${intl.formatMessage({ id: 'groupBuyingEnd' })}`}
              />
            </div>
          ) : (
            <span>
              <FormattedMessage id={getIntlIdForGroup(element.status)} />{' '}
              {element.status === 'refunded' ? failedIcon : successIcon}
            </span>
          )}
        </div>

        <div>
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={joinInGroup ? 1 : 2}
          >
            {!joinInGroup &&
            element.status !== 'finished' &&
            element.status !== 'refunded' ? (
              <Panel
                header={<FormattedMessage id="groupBuyingOrderInfo" />}
                key="2"
                style={customPanelStyle}
              >
                <div>
                  {myOrderId ? (
                    <div>
                      <div
                        role="presentation"
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={async () => {
                          // await processRedirect();
                          history.push(
                            `${getCustomerPath(
                              'account/orders/delivery-order'
                            )}/${myOrderId}`
                          );
                        }}
                      >
                        <FormattedMessage id="clickCheckOrder" />
                      </div>
                      {displayDiscount > 0 ? (
                        <div>
                          <FormattedMessage id="subtotal" />:
                          <del>{`$${myOrderAmount} `}</del>
                          <span>
                            ${(myOrderAmount - displayDiscount).toFixed(2)}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <FormattedMessage id="subtotal" />
                          :${myOrderAmount}
                        </div>
                      )}

                      <div>
                        <FormattedMessage id="currentDiscount" />
                        {`:$${displayDiscount}`}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Panel>
            ) : null}
            <Panel
              header={<FormattedMessage id="groupBuyingInfo" />}
              key="1"
              style={customPanelStyle}
            >
              <div>
                <div css={{ margin: '2px' }}>
                  <EnvironmentOutlined />{' '}
                  {addressFormatterOnlyAddress(element.owner.oneOrder.address)}
                </div>
                <div css={{ margin: '2px' }}>
                  <IdcardOutlined />{' '}
                  {addressFormatterOnlyName(element.owner.oneOrder.address)}
                </div>
                <div css={{ margin: '2px' }}>
                  <EditOutlined /> {element.note}
                </div>
                <div className="group-buy-delivery-time-and-charge-time">
                  <div className="group-buy-charge-time">
                    <FormattedMessage id="chargeTime" /> :
                    {element.owner.oneOrder.deliveryTime
                      ? moment(
                          element.owner.oneOrder.deliveryTime - 129600000
                        ).format('MM/DD HH:mm')
                      : null}
                  </div>
                  <div className="group-buy-delivery-time">
                    <FormattedMessage id="deliveryTime" />:{' '}
                    {element.owner.oneOrder.deliveryTime
                      ? moment(element.owner.oneOrder.deliveryTime).format(
                          'MM/DD HH:mm'
                        )
                      : null}
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

GroupInfoBox.defaultProps = {
  joinInGroup: null,
};

GroupInfoBox.propTypes = {
  element: PropTypes.shape({
    minimumDeliveryFee: PropTypes.number,
    displayId: PropTypes.number,
    name: PropTypes.string,
    note: PropTypes.string,
    status: PropTypes.string,
    participators: PropTypes.arrayOf(PropTypes.shape({})),
    owner: PropTypes.shape({
      oneOrder: PropTypes.shape({
        address: PropTypes.shape({}),
        deliveryTime: PropTypes.number,
      }),
    }),
  }).isRequired,
  discountRule: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  joinInGroup: PropTypes.func,
  language: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  // saveLinkForLogin: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  language: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupInfoBox);
