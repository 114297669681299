import gql from 'graphql-tag';

export const CREATE_BOX = gql`
  mutation ($input: CreateOneContainerInput!) {
    containerCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_BOX = gql`
  mutation ($input: UpdateOneContainerInput!, $_id: MongoID) {
    containerUpdateOne(input: { record: $input, filter: { _id: $_id } }) {
      record {
        _id
      }
    }
  }
`;
export const FETCH_BOX = gql`
  {
    containerMany {
      _id
      name {
        _id
        zh
        en
      }
      allowedVariantIds
      notAllowedVariantIds
      allowedVariants {
        _id
        name {
          _id
          zh
          en
        }
      }
      notAllowedVariants {
        _id
        name {
          _id
          zh
          en
        }
      }
      capacity
      minimumAddQuantity
      discountPercentage
    }
  }
`;
