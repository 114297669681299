/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Tag } from 'antd';
import { List } from 'antd-mobile';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import gravatar from 'gravatar-api';
import { getCustomerPath, apolloClient } from '../../../../util';
import userAvatar from '../../../../assets/images/account/user.png';
// import flame from '../../../../assets/images/flame.webp';
import { USER_CURRENT } from '../../../../api/user';
import { BUCKET_CDN_URL } from '../../../../config';

const CustomButton = styled(Button)`
  margin-left: 10px;
`;

export class Overview extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      invitationCode: null,
      createdAt: null,
      avatar: userAvatar,
    };
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const { email } = this.props;
    const options = {
      email,
      parameters: {
        size: '200',
        d: `${BUCKET_CDN_URL}default/user.png`,
      },
      secure: true,
    };
    const avatar = gravatar.imageUrl(options);
    this.setState({ avatar });
    const {
      data: { userCurrent },
    } = await apolloClient.query({
      query: USER_CURRENT,
      variables: {},
      fetchPolicy: 'network-only',
    });
    console.log(userCurrent);
    if (userCurrent.invitationCode) {
      this.setState({
        invitationCode: userCurrent.invitationCode,
        createdAt: userCurrent.createdAt,
      });
    }
  };

  componentWillUnmount() {}

  render() {
    const { firstName, email, intl } = this.props;
    const { invitationCode, createdAt, avatar } = this.state;
    return (
      <div
        css={{
          height: isMobile ? 'auto' : '100vh',
          minHeight: isMobile ? 'auto' : '100vh',
          overflow: 'scroll',
          padding: isMobile ? '20px 10px 10px 10px' : '20px',
          backgroundColor: 'white',
        }}
      >
        <Row>
          <Col span={24}>
            <div>
              <img
                css={{
                  height: '128px',
                  borderRadius: '50%',
                  margin: 'auto',
                  display: 'block',
                }}
                src={avatar}
                alt="user avatar"
              />
            </div>
            {invitationCode && moment(createdAt).add(7, 'days') > moment() && (
              <div css={{ textAlign: 'center' }}>
                <Tag color="#108ee9">{invitationCode}</Tag>{' '}
                {moment(createdAt).format('MM-DD')}
              </div>
            )}
            <div
              css={{
                textAlign: 'center',
                height: '30px',
                fontSize: '19px',
                fontWeight: 'bold',
                marginBottom: '20px',
                marginTop: '20px',
              }}
            >
              Hi, {firstName !== '[Customer]' ? firstName : email}{' '}
              <Link
                to={{
                  pathname: '/logout',
                  state: { from: 'customer' },
                }}
              >
                <CustomButton size="small" type="primary">
                  <FormattedMessage id="logout" />
                </CustomButton>
              </Link>
            </div>
          </Col>
        </Row>
        {isMobile ? (
          <List
            renderHeader={() => intl.formatMessage({ id: 'account.settings' })}
          >
            <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/orders')}>
                <div>
                  <FormattedMessage id="order" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.orders" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item>
            <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/balance')}>
                <div>
                  <FormattedMessage id="account.balanceGiftCard" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.balanceTransaction" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item>
            {/* <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/invitation')}>
                <div>
                  <FormattedMessage id="myInvitation" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.viewInvitation" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item> */}
            {/* <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/personal-coupon')}>
                <div>
                  <FormattedMessage id="myCoupon" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.viewCouponWallet" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item> */}
            <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/payment-management')}>
                <div>
                  <FormattedMessage id="payment" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.managePayment" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item>
            <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/campaign')}>
                <div>
                  <FormattedMessage id="myGroupBuy" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.accountViewGroupBuy" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item>
            {/* <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('games')}>
                <div>
                  <FormattedMessage id="game" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.gamesAndPromotions" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item> */}
            <List.Item arrow="horizontal" multipleLine onClick={() => {}}>
              <Link to={getCustomerPath('account/setting')}>
                <div>
                  <FormattedMessage id="account" />
                  <List.Item.Brief>
                    <FormattedMessage id="account.accountInfo" />
                  </List.Item.Brief>
                </div>
              </Link>
            </List.Item>
          </List>
        ) : (
          <Row
            gutter={30}
            css={{
              '& .block-style': {
                height: '60px',
                width: '100%',
                fontStretch: 'wider',
                color: 'black',
                fontWeight: '700',
                verticalAlign: 'middle',
                textAlign: 'center',
                padding: '15px 15px',
                fontSize: '20px',
                borderRadius: '2px',
                marginBottom: '16px',
                boxShadow: '10px 10px 25px #8a8a8a, -10px -10px 25px #ffffff',
                ':hover': {
                  background: 'linear-gradient(190deg, #e6e6e6, #ffffff)',
                  boxShadow:
                    'inset 10px 10px 25px #8a8a8a, inset -10px -10px 25px #ffffff',
                },
              },
            }}
          >
            <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/orders')}>
                <div className="block-style">
                  <FormattedMessage id="order" />
                </div>
              </Link>
            </Col>
            <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/balance')}>
                <div className="block-style">
                  <FormattedMessage id="account.balanceGiftCard" />
                </div>
              </Link>
            </Col>
            {/* <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/invitation')}>
                <div className="block-style">
                  <FormattedMessage id="myInvitation" />
                </div>
              </Link>
            </Col> */}
            {/* <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/personal-coupon')}>
                <div className="block-style">
                  <FormattedMessage id="myCoupon" />
                </div>
              </Link>
            </Col> */}
            <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/catering-orders')}>
                <div className="block-style">
                  <FormattedMessage id="cateringOrders" />
                </div>
              </Link>
            </Col>
            <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/campaign')}>
                <div className="block-style">
                  <FormattedMessage id="myGroupBuy" />
                </div>
              </Link>
            </Col>
            <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/payment-management')}>
                <div className="block-style">
                  <FormattedMessage id="payment" />
                </div>
              </Link>
            </Col>
            {/* <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('games')}>
                <div className="block-style">
                  <FormattedMessage id="game" />
                </div>
              </Link>
            </Col> */}
            <Col
              xxl={4}
              xl={8}
              sm={24}
              lg={12}
              xs={24}
              style={{ marginBottom: '10px' }}
            >
              <Link to={getCustomerPath('account/setting')}>
                <div className="block-style">
                  <FormattedMessage id="account" />
                </div>
              </Link>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

Overview.propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
};
Overview.defaultProps = {
  firstName: 'Customer',
};

const mapStateToProps = (state) => ({
  firstName: state.user.firstName,
  email: state.user.email,
  group: state.user.group,
});

export default connect(mapStateToProps, null)(injectIntl(Overview));
