/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import deliveryCar from '../../../assets/images/icons/deliveryCar.svg';

function Loading(props) {
  const { replaceMsg } = props;

  // const spinAnimation = keyframes`
  // 0% { transform: rotate(0deg) }
  // 50% { transform: rotate(180deg) }
  // 100% { transform: rotate(360deg) }
  // `;

  const marquee = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
  `;

  return (
    <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
      {/* <Spin
        indicator={
          <img
            css={{
              height: '64px',
              width: '64px',
              animation: `${spinAnimation} 1s linear infinite`,
            }}
            src={deliveryCar}
            alt="bao loading"
          />
        }
      /> */}
      <div
        css={{
          width: '200px',
          margin: '0 auto',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          boxSizing: 'border-box',
          '& img': {
            display: 'inline-block',
            paddingLeft: '100%',
            willChange: 'transform',
            animation: `${marquee} 1.55s linear infinite`,
          },
        }}
      >
        <img src={deliveryCar} alt="loading icon" height="64px" />
      </div>
      <div>
        {replaceMsg ? (
          <FormattedMessage id={replaceMsg} />
        ) : (
          <FormattedMessage id="loading" />
        )}
      </div>
    </div>
  );
}

Loading.defaultProps = {
  replaceMsg: null,
};

Loading.propTypes = {
  replaceMsg: PropTypes.string,
};

export default Loading;
