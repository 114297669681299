/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { message, Button, Form } from 'antd';
import queryString from 'query-string';
// import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FormBuilder from 'antd-form-builder';
import { CUSTOMER_ORDER_BY_DISPLAY_ID } from '../../../api/customerOrder';
import { apolloClient } from '../../../util';
import OrderDetails from '../Account/OrderDetails/OrderDetails';
import DeliveryOrderDetail from '../Account/DeliveryOrderDetail/DeliveryOrderDetail';

export class QueryOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      order: null,
      orderId: null,
      displayId: null,
      firstName: null,
      lastName: null,
      email: null,
      isNoData: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount = async () => {
    const { location } = this.props;
    const { displayId, firstName, lastName, email, orderId } =
      queryString.parse(location.search);
    await this.setState({
      displayId: Number.parseInt(displayId, 10),
      firstName,
      lastName,
      email,
      orderId,
    });

    await this.fetchCustomerOrders();
  };

  fetchCustomerOrders = async () => {
    const { orderId, displayId, firstName, lastName, email } = this.state;
    if (!orderId && (!displayId || !((firstName && lastName) || email))) {
      return;
    }
    const filter = {};
    if (orderId) {
      filter._id = orderId;
    } else {
      filter.displayId = displayId;
    }
    try {
      const {
        data: { customerOrderFindOne },
      } = await apolloClient.query({
        query: CUSTOMER_ORDER_BY_DISPLAY_ID,
        variables: { filter },
        fetchPolicy: 'network-only',
      });
      console.log(customerOrderFindOne);

      if (customerOrderFindOne) {
        // if (
        //   customerOrderFindOne.discountAmount ||
        //   customerOrderFindOne.discountAmount === 0
        // ) {
        //   customerOrderFindOne.discount = customerOrderFindOne.discountAmount;
        // }
        this.setState({
          order: null,
        });
        if (displayId) {
          const { user, address } = customerOrderFindOne;
          if (
            email &&
            firstName &&
            lastName &&
            user.email !== email &&
            user.guestUserEmail !== email &&
            address.firstName !== firstName &&
            address.lastName !== lastName
          ) {
            this.setState({
              isNoData: true,
            });
            message.error('info does not match');
            return;
          }
          if (email && user.email !== email && user.guestUserEmail !== email) {
            message.error('info does not match');
            this.setState({
              isNoData: true,
            });
            return;
          }
          if (firstName && address.firstName !== firstName) {
            message.error('info does not match');
            this.setState({
              isNoData: true,
            });
            return;
          }
          if (firstName && address.lastName !== lastName) {
            message.error('info does not match');
            this.setState({
              isNoData: true,
            });
            return;
          }
        }

        this.setState({
          order: customerOrderFindOne,
          isNoData: false,
        });
      } else {
        this.setState({
          isNoData: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  onSearchSubmit = async (values) => {
    const { displayId, email, firstName, lastName } = values;
    await this.setState({
      displayId: Number.parseInt(displayId, 10),
      email,
      firstName,
      lastName,
    });
    await this.fetchCustomerOrders();
  };

  render() {
    const { order, isNoData } = this.state;
    const meta = {
      columns: 1,
      formItemLayout: [4, 18],
      fields: [
        {
          key: 'displayId',
          label: 'Display ID',
          required: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (rule, value, callback) => {
                const lastName = this.formRef.current.getFieldValue('lastName');
                const firstName =
                  this.formRef.current.getFieldValue('firstName');
                const email = this.formRef.current.getFieldValue('email');
                if (!((lastName && firstName) || email)) {
                  callback(new Error('Please enter email or name'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
        {
          key: 'email',
          label: 'Email',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (rule, value, callback) => {
                const pattern = new RegExp(
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (value && !pattern.test(value)) {
                  callback(new Error('invalid email'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
        {
          key: 'firstName',
          label: 'First Name',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value && !this.formRef.current.getFieldValue('lastName')) {
                  callback(new Error('Last name is required'));
                } else if (
                  !value &&
                  this.formRef.current.getFieldValue('lastName')
                ) {
                  callback(new Error('First name is required'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
        {
          key: 'lastName',
          label: 'Last Name',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value && !this.formRef.current.getFieldValue('firstName')) {
                  callback(new Error('First name is required'));
                } else if (
                  !value &&
                  this.formRef.current.getFieldValue('firstName')
                ) {
                  callback(new Error('Last name is required'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
      ],
    };
    const couldModeArray = ['warehouse', 'warehouseScheduledDelivery'];

    return (
      <div
        css={{
          padding: '0px 10px',
          paddingBottom: '40px',
          minHeight: '100vh',
        }}
      >
        <div css={{ maxWidth: '900px', margin: '0 auto' }}>
          <div>
            <div
              css={{
                margin: '20px 0px',
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              Search Order
            </div>
            <Form ref={this.formRef} onFinish={this.onSearchSubmit}>
              <FormBuilder meta={meta} form={this.formRef} />
              <Form.Item style={{ textAlign: 'right' }} shouldUpdate>
                {() => (
                  <Button type="primary" htmlType="submit">
                    <FormattedMessage id="query" />
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>

          {isNoData && !order && (
            <div style={{ textAlign: 'center' }}>No data</div>
          )}

          {order && (
            <div>
              {!couldModeArray.includes(order.bunbaoType) ? (
                <DeliveryOrderDetail order={order} />
              ) : (
                <OrderDetails order={order} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

QueryOrderDetail.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  group: state.user.group,
});

export default connect(mapStateToProps, null)(injectIntl(QueryOrderDetail));
