import React, { Component } from 'react';
// import { Widget } from 'react-chat-widget';
// import 'react-chat-widget/lib/styles.css';

export class MessageSystem extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    return (
      <div>
        aaaa
        {/* <Widget /> */}
      </div>
    );
  }
}
MessageSystem.propTypes = {};
export default MessageSystem;
