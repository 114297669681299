import gql from 'graphql-tag';

export const TAX_RATE_FETCH = gql`
  mutation ($zipCode: String!) {
    taxRateFetch(zipCode: $zipCode) {
      _id
      percentage
    }
  }
`;

export const TAX_RATE_BY_ID = gql`
  query {
    taxRateById {
      _id
    }
  }
`;
