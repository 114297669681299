import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { apolloClient } from '../../../util';
import { USER_UPDATE_CURRENT_CUSTOMER_INFORMATION } from '../../../api/user';

export class CustomerSettingForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  handleUpdateUserInformation = async (e) => {
    e.preventDefault();
    const { form, getCurrentUser, intl } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        const { firstName, lastName } = values;
        try {
          const { data } = await apolloClient.mutate({
            mutation: USER_UPDATE_CURRENT_CUSTOMER_INFORMATION,
            variables: {
              firstName,
              lastName,
            },
          });
          console.log(data);
          message.success(intl.formatMessage({ id: 'updateSuccess' }));
          getCurrentUser();
        } catch (error) {
          message.error(intl.formatMessage({ id: 'updateFailure' }));
        }
      }
    });
  };

  render() {
    const { firstName, lastName, form, horizontal, intl } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 21 },
      },
    };

    const { getFieldDecorator } = form;
    return (
      <div>
        <Form
          labelCol={horizontal ? formItemLayout.labelCol : {}}
          wrapperCol={horizontal ? formItemLayout.wrapperCol : {}}
          layout={horizontal ? 'horizontal' : 'vertical'}
          onSubmit={this.handleUpdateUserInformation}
        >
          <Form.Item label={intl.formatMessage({ id: 'firstName' })}>
            {getFieldDecorator('firstName', {
              initialValue: firstName,
              rules: [{ required: true, message: 'Your first name' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'lastName' })}>
            {getFieldDecorator('lastName', {
              initialValue: lastName,
              rules: [{ required: true, message: 'Your last name' }],
            })(<Input />)}
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="update" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

CustomerSettingForm.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  horizontal: PropTypes.bool.isRequired,
};

export default Form.create({ name: 'customer_setting_from' })(
  injectIntl(CustomerSettingForm)
);
