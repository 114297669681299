import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Card, Col, Row, message } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { apolloClient, getCustomerPath } from '../../../util';
import {
  WAREHOUSE_PRODUCTS_QUERY_STORE,
  WAREHOUSES_NET_INVENTORY,
} from '../../../api/warehouse';
import * as actions from '../../../store/actions';
import { BUCKET_URL, BUCKET_CDN_URL } from '../../../config';
// export const

export class OrderDineMenu extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      productList: [],
      // shoppingList: [],
      // modal: false,
      // modal2: false,
      // sum: 0,
      // open: false,
      loading: true,
      // taxRate: 0,
      warehouseId: '5dc237485c932e2c30a32128',
      isPickup: false,
    };
  }

  componentDidMount = async () => {
    const { match, setWarehouse } = this.props;
    const { warehouseId } = match.params;
    await setWarehouse(warehouseId);
    const currentWarehouse = warehouseId;
    const { data } = await apolloClient.query({
      query: WAREHOUSE_PRODUCTS_QUERY_STORE,
      variables: { input: currentWarehouse },
      fetchPolicy: 'network-only',
    });
    try {
      if (data && data.warehouseById) {
        // await this.getTaxRate(data.warehouseById.zip);
        let productList = data.warehouseById.allowedStoreVariants;
        // console.log(data);
        productList.forEach((element) => {
          const newElement = element;
          newElement.count = 0;
          newElement.stock = 0;
          newElement.inSufficient = false;
          newElement.inSufficientStock = 0;
          return newElement;
        });
        let oldProductList = localStorage.getItem('tempCart');
        if (oldProductList) {
          oldProductList = JSON.parse(oldProductList);
          // console.log(oldProductList);
          // console.log(productList);
          productList = _.map(productList, (obj) => {
            const newObject = obj;
            const findOne = _.find(oldProductList, ['sku', newObject.sku]);
            if (findOne) {
              newObject.count += findOne.count;
            }
            return newObject;
          });
        }

        const response = await apolloClient.query({
          query: WAREHOUSES_NET_INVENTORY,
          variables: { input: currentWarehouse },
          fetchPolicy: 'network-only',
        });
        // console.log(response);
        response.data.inventoryCurrentWarehouse.forEach((e) => {
          const found = productList.findIndex(
            (element) => element._id === e._id
          );
          if (found >= 0) {
            productList[found].stock = e.totalQuantity;
          }
        });
        this.setState({
          productList,
          loading: false,
          warehouseId,
          isPickup: data.warehouseById.pickup,
        });
      }
    } catch (error) {
      message.error('error');
      console.error(error);
    }
  };

  render() {
    const { productList, loading, warehouseId, isPickup } = this.state;
    const { intl, language } = this.props;
    const image = `${BUCKET_CDN_URL}media/products/%E9%85%B8%E8%8F%9C%E7%8C%AA%E8%82%89.jpeg`;
    const allProducts = [];
    productList.forEach((e) => {
      allProducts.push(
        <Col
          xxl={6}
          xl={6}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          key={e._id}
          style={{ margin: '13px 0px' }}
        >
          <Card
            cover={
              <img
                alt="example"
                src={
                  e.product.picture ? BUCKET_URL + e.product.picture.key : image
                }
              />
            }
          >
            <Row type="flex" justify="space-between">
              <Col>{e.name[language]}</Col>
              <Col>{`$${e.listPrice}`}</Col>
            </Row>
            <Row>
              <Col style={{ fontSize: 20, fontWeight: 'bold' }}>
                {e.stock > 0 ? (
                  `${e.stock} ${intl.formatMessage({ id: 'left' })}`
                ) : (
                  <FormattedMessage id="outOfStock" />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      );
    });

    const actualStoreContent = (
      <Row type="flex" justify="center" style={{}} gutter={10}>
        <Col xxl={21} xl={20} lg={20} md={20} style={{}}>
          <Row
            type="flex"
            justify="center"
            gutter={15}
            style={{ padding: '20px 0px', backgroundColor: 'white' }}
          >
            {!loading && allProducts.length > 0 ? allProducts : null}
            {!loading && allProducts.length <= 0 ? (
              <div style={{ fontSize: '30px' }}>
                <FormattedMessage id="empty" />
              </div>
            ) : null}
          </Row>
        </Col>
      </Row>
    );

    return (
      <div className="web" style={{ minHeight: '100vh' }}>
        <Row
          type="flex"
          justify="start"
          style={{ marginTop: '5px' }}
          gutter={10}
        >
          <Col span={10} style={{ margin: '5px 0px 0px 5px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={getCustomerPath('map')}>
                  <FormattedMessage id="map" />
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <FormattedMessage id="store" />
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ fontSize: 20, fontWeight: 'bold', marginTop: '5px' }}
        >
          {!isPickup ? (
            <FormattedMessage id="dineRule2" />
          ) : (
            <>
              <FormattedMessage id="dineRule" />
              <Link to={`${getCustomerPath('store')}/${warehouseId}`}>
                <Button style={{ marginLeft: ' 5px' }} type="primary">
                  <FormattedMessage id="pickup" />
                </Button>
              </Link>
            </>
          )}
        </Row>
        {actualStoreContent}
      </div>
    );
  }
}

OrderDineMenu.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  // history: ReactRouterPropTypes.history.isRequired,
  setWarehouse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  items: state.cart.items,
  currentWarehouseId: state.warehouse.warehouseId,
  language: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setWarehouse: (warehouseId) => dispatch(actions.setWarehouse(warehouseId)),
  clearItems: () => dispatch(actions.clearItems()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderDineMenu));
