/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useRef, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import Icon, {
  EnvironmentOutlined,
  RollbackOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { Button, Carousel, Dropdown, Menu } from 'antd';
import moment from 'moment';

import { useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import Distributor from '../../assets/images/distributor.png';
import Central from '../../assets/images/central.png';
import FarmersMarket from '../../assets/images/farmersMarket.png';
import limitIcon from '../../assets/images/limit.png';
import { BUCKET_CDN_URL } from '../../config';
import { ReactComponent as muteIcon } from '../../assets/images/icons/mute.svg';
import { ReactComponent as unmuteIcon } from '../../assets/images/icons/unmute.svg';

const renderTypeIcon = (type) => {
  switch (type) {
    case 'central':
      return <img src={Central} alt="Central" />;
    case 'distributor':
      return <img src={Distributor} alt="Distributor" />;
    case 'farmersMarket':
      return <img src={FarmersMarket} alt="Framers Market" />;
    default:
      return <img src={Distributor} alt="Distributor" />;
  }
};

const WarehouseHeader = ({
  warehouse,
  history,
  warehouseScheduledDeliveryMode,
}) => {
  const outerContainer = useRef(null);
  const language = useSelector((state) => {
    return state.language.lang;
  });
  const currentShoppingMode = useSelector((state) => {
    return state.user.currentShoppingMode;
  });
  const [isMuted, setMute] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [bannerFullWidth, setBannerFullWidth] = useState(null);
  // const [selectedVisitedMarket, setSelectedVisitedMarket] = useState('');
  useEffect(() => {
    if (outerContainer.current) {
      setBannerFullWidth(outerContainer.current.clientWidth - 20);
    }
  }, [bannerFullWidth, warehouse]);
  if (!warehouse) {
    return null;
  }
  let selectedVisitedMarket = '';
  if (warehouse.formattedOpenTimeSlot.length) {
    const today = moment(new Date()).format('ddd');
    selectedVisitedMarket =
      warehouse.formattedOpenTimeSlot
        .filter((item) => item.includes(today))
        .pop() || warehouse.formattedOpenTimeSlot[0];
  }
  const slideChangeHandler = () => {
    if (videoPlayer) {
      setMute(true);
      videoPlayer.mute();
    }
  };

  const dayMaps = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const timeFormate = (num) => {
    const suffix = num > 12 ? 'pm' : 'am';
    const time = num > 12 ? num - 12 : num;
    return `${time}:00${suffix}`;
  };
  const dropDownOptionsData = warehouse.formattedOpenTimeSlot.filter(
    (item) => item !== selectedVisitedMarket
  );
  const dropDownOption = (
    <Menu>
      {warehouse.formattedOpenTimeSlot
        .filter((item) => item !== selectedVisitedMarket)
        .map((element) => (
          <Menu.Item className="warehouse-times" key={element}>
            {element}
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <div
      ref={outerContainer}
      css={{
        // marginBottom: '20px',
        padding: '10px',
        // border: '1px solid #e8e8e8',
        borderRadius: '5px',
        minHeight: '190px',
        // backgroundImage:
        //   warehouse.backgroundImages &&
        //   warehouse.backgroundImages.length > 0 &&
        //   !isMobile
        //     ? `url(${BUCKET_URL}${warehouse.backgroundImages[0].key})`
        //     : 'none',
        textShadow:
          '0px 0px 2em rgba(255,255,255), 0px 0px 2em rgba(255,255,255) , 0px 0px 2em rgba(255,255,255)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .warehouse-name': {
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: '800',
          '& img': {
            height: '40px',
            width: '40px',
          },
        },
        '& .warehouse-is-open': {
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: '600',
        },
        '& .warehouse-times': {
          textAlign: 'center',
          fontSize: '13px',
          margin: '10px auto',
          fontWeight: '500',
        },
        '& .warehouse-address': {
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: '600',
        },
      }}
    >
      {isMobile ? null : (
        <div
          css={{
            marginBottom: '20px',
            position: 'absolute',
            top: '0',
            left: '0',
          }}
        >
          {/* <Link to={getCustomerPath('map')}> */}
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            {/* <FormattedMessage id="backToMap" /> */}
            <RollbackOutlined />
          </Button>
          {/* </Link> */}
        </div>
      )}
      <div
        css={{
          width: '100%',
          marginBottom: isMobile ? '10px' : '30px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div css={isMobile ? { width: '100%' } : { width: '50%' }}>
          <div className="warehouse-name">
            {renderTypeIcon(warehouse.warehouseType)}
            {warehouse.name}
          </div>
          <div className="warehouse-address">
            <div>
              <span>
                <EnvironmentOutlined />
              </span>{' '}
              {warehouse.addressOne}
              {warehouse.addressTwo ? ` ${warehouse.addressTwo} ` : null}
              {`, ${warehouse.city}, ${warehouse.zip}`}
            </div>
          </div>
        </div>
        <div css={isMobile ? { width: '100%' } : { width: '50%' }}>
          {warehouseScheduledDeliveryMode === false &&
            currentShoppingMode !== 'pickup' &&
            currentShoppingMode !== 'eShop' && (
              <div className="warehouse-is-open">
                {warehouse.isOpen ? (
                  <React.Fragment>
                    <FormattedMessage id="pickupRule" /> 15~20
                    <FormattedMessage id="mins" />
                    <img
                      src={limitIcon}
                      style={{
                        height: '16px',
                        width: '16px',
                        marginTop: '0px',
                        marginLeft: '4px',
                      }}
                      alt="limit time product"
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormattedMessage id="storeCloseNow" />
                  </React.Fragment>
                )}
              </div>
            )}
          {warehouseScheduledDeliveryMode === true && (
            <div className="warehouse-is-open" style={{ textAlign: 'right' }}>
              <FormattedMessage id="deliveryHours" /> {'  '}
              <span className="warehouse-times">
                {warehouse.scheduledDeliveryTimeRange.start === 0 &&
                warehouse.scheduledDeliveryTimeRange.end === 0
                  ? `${
                      warehouse.hours.length && dayMaps[warehouse.hours[0].day]
                    } 1:00pm~8:00pm`
                  : `${
                      warehouse.hours.length && dayMaps[warehouse.hours[0].day]
                    } ${timeFormate(
                      warehouse.scheduledDeliveryTimeRange.start
                    )}~${timeFormate(
                      warehouse.scheduledDeliveryTimeRange.end
                    )}`}
              </span>
            </div>
          )}
          {currentShoppingMode !== 'eShop' && (
            <div className="warehouse-is-open" style={{ textAlign: 'right' }}>
              <FormattedMessage id="operationHours" />
              {'  '}
              <Dropdown overlay={dropDownOption}>
                <span className="warehouse-times">
                  {selectedVisitedMarket}{' '}
                  {dropDownOptionsData.length > 1 && (
                    <DownOutlined style={{ color: '#919191' }} />
                  )}
                </span>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <Carousel
          adaptiveHeight
          infinite={false}
          beforeChange={slideChangeHandler}
          css={{
            textAlign: 'center',
          }}
        >
          {bannerFullWidth > 0 && warehouse.videoIds.length && (
            <div
              css={{
                height: isMobile ? '200px' : '300px',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    position: 'absolute',
                    height: '100%',
                    width: isMobile
                      ? bannerFullWidth
                      : Math.max(bannerFullWidth / 2, 532),
                  }}
                >
                  <Button
                    css={{
                      position: 'absolute',
                      zIndex: '10',
                      top: '10px',
                      right: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() => {
                      setMute(!isMuted);
                      if (videoPlayer.isMuted()) {
                        videoPlayer.unMute();
                      } else {
                        videoPlayer.mute();
                      }
                    }}
                    shape="circle"
                    icon={
                      isMuted ? (
                        <Icon component={muteIcon} />
                      ) : (
                        <Icon component={unmuteIcon} />
                      )
                    }
                  />
                </div>
                <YouTube
                  // style={{ position: 'absolute' }}
                  id={nanoid(5)}
                  opts={{
                    host: 'https://www.youtube-nocookie.com',
                    height: isMobile ? 200 : 300,
                    width: isMobile
                      ? bannerFullWidth
                      : Math.max(bannerFullWidth / 2, 532),
                    playerVars: {
                      // https://developers.google.com/youtube/player_parameters
                      controls: 0,
                      modestbranding: 1,
                      iv_load_policy: 3,
                      rel: 0,
                      playsinline: 1,
                    },
                  }}
                  videoId={
                    language === 'zh'
                      ? warehouse.videoIds.filter(
                          (element) => element.language === 'zh'
                        )[0].videoId
                      : warehouse.videoIds.filter(
                          (element) => element.language === 'en'
                        )[0].videoId
                  }
                  onReady={(event) => {
                    console.log(event);
                    setVideoPlayer(event.target);
                    event.target.mute();
                    event.target.playVideo();
                  }}
                  onStateChange={(event) => {
                    console.log('Youtube state change: ', event);
                    setVideoPlayer(event.target);
                    event.target.mute();
                    event.target.playVideo();
                  }}
                  onPause={(event) => {
                    event.target.playVideo();
                  }}
                  onEnd={(event) => {
                    event.target.playVideo();
                  }}
                />
              </div>
            </div>
          )}
          {warehouse.backgroundImages.length > 0 &&
            warehouse.backgroundImages.map((image) => {
              return (
                <div role="presentation" key={image.key}>
                  <img
                    src={`${BUCKET_CDN_URL}${image.key}`}
                    alt={image.key}
                    role="presentation"
                    css={{
                      width: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              );
            })}
        </Carousel>
      </div>
    </div>
  );
};
WarehouseHeader.defaultProps = {
  warehouse: null,
  warehouseScheduledDeliveryMode: false,
};

WarehouseHeader.propTypes = {
  warehouse: PropTypes.shape({
    hours: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        start: PropTypes.string,
        end: PropTypes.string,
      })
    ),
    scheduledDeliveryTimeRange: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    allowedVariants: PropTypes.arrayOf(PropTypes.shape({})),
    backgroundImages: PropTypes.arrayOf(PropTypes.shape({})),
    city: PropTypes.string,
    childrenWarehouses: PropTypes.arrayOf(PropTypes.shape({})),
    zip: PropTypes.string,
    formattedOpenTimeSlot: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    isOpen: PropTypes.bool,
    warehouseType: PropTypes.string,
    videoIds: PropTypes.arrayOf(
      PropTypes.shape({
        videoId: PropTypes.string,
        language: PropTypes.string,
      })
    ),
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  warehouseScheduledDeliveryMode: PropTypes.bool,
};

export default WarehouseHeader;
