/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { message, List, Divider, Tag, Pagination, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import {
  CUSTOMER_ORDER_DONATION,
  CUSTOMER_ORDER_ALL_DONATIONS,
} from '../../api/customerOrder';
import { CONFIGURATION_FIND_ONE } from '../../api/configuration';
import { apolloClient, getCustomerPath } from '../../util';

const mosaic = (email) => {
  const res = email.split('@');
  if (res.length >= 2) {
    return `${email.substring(0, 3)}***@${res[1]}`;
  }
  return `${email.substring(0, 3)}***`;
};

function DonationHeader() {
  const language = useSelector((state) => state.language.lang);
  const myEmail = useSelector((state) => state.user.email);
  const [showReportDonations, setShowReportDonations] = useState([]);
  const [reportDonations, setReportDonations] = useState([]);
  const [totalReportDonationsCount, setTotalReportDonationsCount] = useState(0);
  const [totalReportAmount, setTotalReportAmount] = useState(0);
  const [reportPageIndex, setReportPageIndex] = useState(1);
  const reportPageSize = 5;

  const [allDonationOrders, setAllDonationOrders] = useState([]);
  const [donationOrders, setDonationOrders] = useState([]);
  const [donationOrdersCount, setDonationOrdersCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 5;

  const [donationCategoryLink, setDonationCategoryLink] = useState('stories');

  useEffect(() => {
    const handleFetchDonationsConfigurations = async () => {
      const {
        data: { configurationOne },
      } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: {
          filter: { name: 'donations' },
        },
        fetchPolicy: 'network-only',
      });
      if (configurationOne && configurationOne._id) {
        const { donations, donationCategoryLink: link } = configurationOne;
        // console.log(donations);
        setDonationCategoryLink(link);
        const newArray = lodash.orderBy(donations, ['date'], ['desc']);
        setReportDonations(newArray);
        setTotalReportDonationsCount(donations.length);
        let amount = 0;
        donations.forEach((donation) => {
          amount += donation.amount;
        });
        setTotalReportAmount(amount);
      }
    };
    handleFetchDonationsConfigurations();
  }, []);

  useEffect(() => {
    setShowReportDonations(
      reportDonations.slice(
        (reportPageIndex - 1) * reportPageSize,
        reportPageIndex * reportPageSize
      )
    );
  }, [reportDonations, reportPageIndex]);

  useEffect(() => {
    const handleFetchAllDonations = async () => {
      try {
        const {
          data: { customerOrderManyAdmin },
        } = await apolloClient.query({
          query: CUSTOMER_ORDER_ALL_DONATIONS,
          variables: {
            filter: {
              AND: [{ bunbaoType: 'donation' }, { paymentStatus: 'paid' }],
            },
          },
          fetchPolicy: 'network-only',
        });
        if (customerOrderManyAdmin) {
          // const countAmount = 0;
          let number = 1;

          let copyCustomerOrder = lodash.orderBy(
            customerOrderManyAdmin,
            ['createdAt'],
            ['asc']
          );

          // Set up Numbers
          copyCustomerOrder = copyCustomerOrder.map((order) => {
            const newElement = { ...order, number };
            number += 1;
            return newElement;
          });

          const copyReportDonations = lodash.orderBy(
            reportDonations,
            ['date'],
            ['asc']
          );

          // console.log('copyReportDonations', copyReportDonations);
          // report donations loop
          copyReportDonations.forEach((reportDonation) => {
            // console.log(reportDonation);
            let reportDonationAmount = reportDonation.amount;

            // console.log('copyReportDonationAmount', reportDonationAmount);

            for (let i = 0; i < copyCustomerOrder.length; i += 1) {
              if (reportDonationAmount <= 0) {
                break;
              }
              // console.log('donation', copyCustomerOrder[i]);

              if (
                !copyCustomerOrder[i].used ||
                copyCustomerOrder[i].used === false
              ) {
                // console.log('first used');
                const lastReportDonationAmount = reportDonationAmount;
                reportDonationAmount -= copyCustomerOrder[i].subtotal;
                copyCustomerOrder[i].used = true;
                copyCustomerOrder[i].articles = [];
                copyCustomerOrder[i].partiallyLeftAmount = 0;
                if (reportDonationAmount < 0) {
                  // set partially used
                  // console.log(
                  //   'first partially used, report donation amount is negative'
                  // );
                  copyCustomerOrder[i].partiallyUsed = true;
                  copyCustomerOrder[i].articles.push({
                    link: reportDonation.link,
                    partiallyAmount: lastReportDonationAmount,
                  });
                  // console.log(
                  //   `partiallyLeftAmount:${Math.abs(reportDonationAmount)}`
                  // );
                  copyCustomerOrder[i].partiallyLeftAmount =
                    Math.abs(reportDonationAmount);
                  break;
                } else {
                  copyCustomerOrder[i].articles.push({
                    link: reportDonation.link,
                    partiallyAmount: copyCustomerOrder[i].subtotal,
                  });
                }
              } else if (
                copyCustomerOrder[i].used &&
                copyCustomerOrder[i].partiallyUsed &&
                copyCustomerOrder[i].partiallyLeftAmount > 0
              ) {
                // console.log('second partially used');
                // console.log(`${i} => ${reportDonationAmount}`);
                const lastReportDonationAmount = reportDonationAmount;
                reportDonationAmount -=
                  copyCustomerOrder[i].partiallyLeftAmount;
                if (reportDonationAmount < 0) {
                  // set partially used
                  // console.log(
                  //   'second partially used, report donation amount is negative'
                  // );
                  copyCustomerOrder[i].articles.push({
                    link: reportDonation.link,
                    partiallyAmount: lastReportDonationAmount,
                  });
                  copyCustomerOrder[i].partiallyLeftAmount =
                    Math.abs(reportDonationAmount);
                  break;
                } else {
                  copyCustomerOrder[i].partiallyUsed = false;
                  copyCustomerOrder[i].articles.push({
                    link: reportDonation.link,
                    partiallyAmount:
                      lastReportDonationAmount - Math.abs(reportDonationAmount),
                  });
                  copyCustomerOrder[i].partiallyLeftAmount = 0;
                }
              } else {
                // console.log('do nothing');
              }
            }
          });

          setAllDonationOrders(copyCustomerOrder);

          let amount = 0;
          customerOrderManyAdmin.forEach((donation) => {
            amount += donation.subtotal;
          });
          setTotalAmount(amount);
        }
      } catch (error) {
        message.error('Error happened, can not fetch donation orders');
      }
    };
    handleFetchAllDonations();
  }, [totalReportAmount, reportDonations]);

  useEffect(() => {
    const handleFetchDonations = async () => {
      try {
        const {
          data: { customerOrderPagination },
        } = await apolloClient.query({
          query: CUSTOMER_ORDER_DONATION,
          variables: {
            page: pageIndex,
            perPage: pageSize,
            filter: {
              AND: [{ bunbaoType: 'donation' }, { paymentStatus: 'paid' }],
            },
          },
          fetchPolicy: 'network-only',
        });
        if (customerOrderPagination) {
          const newArray = customerOrderPagination.items.map((item) => {
            // check
            const foundDonation = allDonationOrders.find((donation) => {
              return item._id === donation._id;
            });
            if (foundDonation) {
              return { ...foundDonation };
            }
            return {
              ...item,
              used: false,
              partiallyUsed: false,
              partiallyLeftAmount: 0,
              articles: [],
            };
          });
          setDonationOrders(newArray);
          setDonationOrdersCount(customerOrderPagination.count);
        }
      } catch (error) {
        message.error('Error happened, can not fetch donation orders');
      }
    };
    handleFetchDonations();
  }, [pageIndex, allDonationOrders]);

  const handleChangePage = (current) => {
    setPageIndex(current);
  };

  const handleChangeReportPage = (current) => {
    setReportPageIndex(current);
  };

  return (
    <div
      css={
        isMobile
          ? { padding: '4px' }
          : {
              padding: '20px',
              width: '80%',
              margin: 'auto',
              border: '1px solid #f0f0f0',
              borderRadius: '4px',
            }
      }
    >
      <div css={{ textAlign: 'center' }}>
        <Link to={getCustomerPath(donationCategoryLink)}>
          <Button type="primary">
            <FormattedMessage id="donation.pleaseCheckout" />
          </Button>
        </Link>
      </div>
      <div
        css={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
      >
        <div css={isMobile ? { width: '100%' } : { width: '50%' }}>
          <List
            size="small"
            header={
              <div css={{ textAlign: 'center', fontSize: '16px' }}>
                <div>
                  <FormattedMessage id="donation.totalDonations" /> $
                  {totalAmount}
                </div>
                <div>
                  <FormattedMessage id="donation.donationList" />{' '}
                  <Tag color="volcano">
                    <FormattedMessage id="donation.yourDonation" />
                  </Tag>
                </div>
              </div>
            }
            dataSource={donationOrders}
            renderItem={(order) => (
              <List.Item>
                <div css={{ width: '100%' }}>
                  {myEmail === order.userEmail ? (
                    <div>
                      <Tag>{`NO.${order.number} `}</Tag>
                      <Tag color="volcano">
                        {`${mosaic(order.userEmail)}`}{' '}
                        <FormattedMessage id="donation.in" />{' '}
                        {moment(order.createdAt).format('M-DD HH:mm')}
                        <FormattedMessage id="donation.donated" />
                        {`$${order.subtotal}`}{' '}
                      </Tag>
                    </div>
                  ) : (
                    <div>
                      <Tag>{`NO.${order.number} `}</Tag>
                      {`${mosaic(order.userEmail)} `}
                      <FormattedMessage id="donation.in" />{' '}
                      {moment(order.createdAt).format('M-DD HH:mm')}
                      <FormattedMessage id="donation.donated" />{' '}
                      {`$${order.subtotal}`}
                    </div>
                  )}
                  <div css={{ display: 'flex' }}>
                    <div css={{ width: '80%' }}>
                      {order.customerNotes !== '' && (
                        <div css={{ margin: '4px 3px' }}>
                          &quot;{order.customerNotes}&quot;
                        </div>
                      )}
                      {order.used && (
                        <div css={{ margin: '4px 3px' }}>
                          <FormattedMessage id="donation.details" />
                        </div>
                      )}
                      {order.used &&
                        order.articles &&
                        order.articles.map((article) => {
                          return (
                            <div key={nanoid(6)} css={{ margin: '4px 3px' }}>
                              <FormattedMessage id="donation.used" /> $
                              {article.partiallyAmount}{' '}
                              <Link to={getCustomerPath(article.link)}>
                                <Button type="primary" size="small">
                                  <FormattedMessage id="donation.moreDetails" />
                                </Button>
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                    <div css={{ width: '20%', textAlign: 'right' }}>
                      <div css={{}}>
                        {order.used && !order.partiallyUsed && (
                          <span>
                            <CheckOutlined />{' '}
                            <FormattedMessage id="donation.used" />
                          </span>
                        )}
                        {order.used && order.partiallyUsed && (
                          <span>
                            <CheckSquareOutlined />{' '}
                            <FormattedMessage id="donation.partiallyUsed" />
                          </span>
                        )}
                        {!order.used && (
                          <span>
                            <ClockCircleOutlined />{' '}
                            <FormattedMessage id="donation.notUsed" />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </List.Item>
            )}
            footer={
              <div css={{ margin: 'auto', textAlign: 'center' }}>
                <Pagination
                  current={pageIndex}
                  onChange={handleChangePage}
                  total={donationOrdersCount}
                  size="small"
                  pageSize={pageSize}
                />
              </div>
            }
          />
        </div>
        <Divider type="vertical" />
        <div css={isMobile ? { width: '100%' } : { width: '50%' }}>
          <List
            size="small"
            header={
              <div css={{ textAlign: 'center', fontSize: '16px' }}>
                <div>
                  <FormattedMessage id="donation.totalWeSpend" /> $
                  {totalReportAmount}
                </div>
                <div>
                  <FormattedMessage id="donation.currentOurActivity" />
                </div>
              </div>
            }
            dataSource={showReportDonations}
            renderItem={(reportDonation) => (
              <List.Item>
                <div>
                  {moment(reportDonation.date).format('MM-DD')}{' '}
                  {language === 'zh'
                    ? reportDonation.description.zh
                    : reportDonation.description.en}{' '}
                  <FormattedMessage id="donation.totalAmountDelivered" /> $
                  {reportDonation.amount}
                </div>
                <div>
                  <Link to={getCustomerPath(reportDonation.link)}>
                    <Button type="primary" size="small">
                      <FormattedMessage id="donation.moreDetails" />
                    </Button>
                  </Link>
                </div>
              </List.Item>
            )}
            footer={
              <div css={{ margin: 'auto', textAlign: 'center' }}>
                <Pagination
                  current={reportPageIndex}
                  onChange={handleChangeReportPage}
                  total={totalReportDonationsCount}
                  size="small"
                  pageSize={reportPageSize}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

DonationHeader.defaultProps = {};
DonationHeader.propTypes = {};

export default DonationHeader;
