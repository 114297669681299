/* global google */
import React, { Component } from 'react';
import * as QueryString from 'query-string';
import { message, Modal } from 'antd';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import Empty from '../../../assets/images/homepage/background.png';
import { apolloClient } from '../../../util';
import { UPDATE_CAMPAIGN_CONTENT } from '../../../api/campaign';

export class ExternalUrl extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      msg: 'This page is for staff only',
    };
  }

  componentDidMount = async () => {
    const {
      location: { search },
    } = this.props;
    const { type, token, identifier } = QueryString.parse(search);
    console.log('QueryString.parse(search)', QueryString.parse(search));
    console.log('type:', type, 'token', token, 'identifier', identifier);
    if (token && type && identifier) {
      if (this.validateToken(token) === true) {
        if (type === 'coordinator') {
          Modal.confirm({
            content: 'Are sure to update location?',
            onOk: () => {
              this.handleGetCurrentLocation(identifier);
            },
          });
        }
      } else {
        this.setState({ msg: 'Invade Token' });
      }
    }
  };

  validateToken = (token) => {
    const start = moment().startOf('day').valueOf();
    const end = moment().endOf('day').valueOf();
    if (token > start && token < end) {
      return true;
    }
    return false;
  };

  handleGetCurrentLocation = (identifier) => {
    this.setState({ msg: 'updating your position...' });
    if ('geolocation' in navigator) {
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new google.maps.Geocoder();
          const location = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          geocoder.geocode({ latLng: location }, async (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                const coordinates = [
                  position.coords.latitude,
                  position.coords.longitude,
                ];
                console.log('coordinates', coordinates);
                const input = {};
                if (coordinates.length === 2) {
                  input.location = {
                    type: 'Point',
                    coordinates,
                  };
                  try {
                    const { data } = await apolloClient.mutate({
                      mutation: UPDATE_CAMPAIGN_CONTENT,
                      variables: { input, Id: identifier.toString() },
                    });
                    if (data.campaignContentUpdateOne.record._id) {
                      this.setState({ msg: 'successfully updated!' });
                      return;
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }
              }
            }
          });
        },
        () => {
          message.error(
            'Cannot get location, check your network or location permission',
            5
          );
        }
      );
    }
    this.setState({
      msg: 'Cannot get location, check your network or location permission',
    });
  };

  render() {
    const { msg } = this.state;
    return (
      <div
        style={{
          padding: '40vh 30vw 40vh 30vw',
          fontSize: '25px',
          fontWeight: 'bold',
          textAlign: 'center',
          backgroundImage: `url(${Empty}) `,
          backgroundSize: '500px',
        }}
      >
        <div
          style={{
            padding: '10px',
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          {msg}
        </div>
      </div>
    );
  }
}
ExternalUrl.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};
export default ExternalUrl;
