import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
// import moment from 'moment';

import {
  CheckOutlined,
  CloseOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import { convertRawToHTML } from 'braft-convert';
import BraftEditor from 'braft-editor';
import { Button, Radio, message, Modal, Switch, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { CardElement } from '@stripe/react-stripe-js';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  apolloClient,
  getCustomerPath,
  updateVariantPrice,
} from '../../../../util';
import {
  customerOrderFormatter,
  pseudoTypeFormatter,
} from '../../../../helper/Formatter/Formatter';
import { FETCH_USER_CARD, SUBMIT_ORDER } from '../../../../api/payment';
import {
  CUSTOMER_ORDER_CREATE,
  // CUSTOMER_ORDER_PRICE_CHECK,
} from '../../../../api/customerOrder';
// import { VARIANTS_QUERY_IDS } from '../../../../api/variant';
import {
  WAREHOUSE_QUERY_ID,
  WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
} from '../../../../api/warehouse';
import * as actions from '../../../../store/actions';
import {
  CREATE_CAMPAIGN_INDIVIDUAL,
  FETCH_MY_CAMPAIGN_INDIVIDUAL,
  UPDATE_CAMPAIGN_INDIVIDUAL_ORDER,
} from '../../../../api/campaign';

import americanExpress from '../../../../assets/images/cards/americanExpress.png';
import jcb from '../../../../assets/images/cards/jcb.png';
import dinersClub from '../../../../assets/images/cards/dinersClub.png';
import discover from '../../../../assets/images/cards/discover.png';
import unionPay from '../../../../assets/images/cards/unionPay.png';
import masterCard from '../../../../assets/images/cards/masterCard.png';
import visa from '../../../../assets/images/cards/visa.png';
import './OrderPaymentStripeForm.less';
import Loading from '../../../Others/Loading/Loading';
import { CATEGORY_QUERY } from '../../../../api/category';

const RadioGroup = Radio.Group;

class OrderPaymentStripeForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // errorMsg: '',
      listCards: [],
      cardId: 'newCard',
      loading: true,
      payClicked: false,
      saveCardOrNot: true,
      collectionsList: [],
      groupBuyRules: null,
      groupBuyRuleVisible: false,
      doesGroupBuyRulesCheck: false,
      hasGroupBuy: false,
    };
  }

  componentDidMount = async () => {
    // await this.updateUserFromSaleor();
    const { group, getCurrentWarehouseCart } = this.props;
    if (group && group.name === 'guest') {
      this.setState({
        saveCardOrNot: false,
      });
    }
    console.log(await getCurrentWarehouseCart());
    const shoppingCart = await getCurrentWarehouseCart();
    shoppingCart?.productList?.forEach((item) => {
      const today = new Date().valueOf();
      if (
        item?.isGroupBuy &&
        today < item?.groupBuyEndDate &&
        today > item?.groupBuyStartDate
      ) {
        this.setState({
          hasGroupBuy: true,
          groupBuyRules: item?.groupBuyRules,
        });
      }
    });
    await this.fetchCollectionData();
    await this.fetchUserCards();
    this.setState({ loading: false });
  };

  handleUserChooseChange = (e) => {
    // console.log(e.target.value);
    this.setState({
      cardId: e.target.value,
    });
  };

  getBrandImg = (brand) => {
    switch (brand) {
      case 'American Express':
        return americanExpress;
      case 'Diners Club':
        return dinersClub;
      case 'Discover':
        return discover;
      case 'JCB':
        return jcb;
      case 'MasterCard':
        return masterCard;
      case 'UnionPay':
        return unionPay;
      case 'Visa':
        return visa;
      default:
        return visa;
    }
  };

  fakeSuccess = async () => {
    const {
      // currentShoppingMode,
      // updateShoppingCart,
      updateWarehouseCart,
      // setUserShoppingMode,
      // setUserAddressType,
      updateOneUserAttribute,
    } = this.props;
    const empty = {
      productList: [],
      boxList: [],
    };
    // if (currentShoppingMode === 'delivery') {
    //   updateShoppingCart({
    //     productList: [],
    //     boxList: [],
    //   });
    // } else {
    //   updateWarehouseCart({ productList: [] });
    // }
    const { handlePaymentSuccess } = this.props;
    handlePaymentSuccess();
    await updateOneUserAttribute(null, 'currentCampaignContentId');
    await updateOneUserAttribute(null, 'groupBuyInfo');
    await updateOneUserAttribute(null, 'overWriteAddress');
    await updateOneUserAttribute(null, 'preselectPickupInfo');
    await updateWarehouseCart(empty);
  };

  fetchCollectionData = async () => {
    const {
      data: { categoryMany },
    } = await apolloClient.query({
      query: CATEGORY_QUERY,
      variables: {
        filter: {
          type: 'collection',
          warehouseId: null,
        },
      },
      fetchPolicy: 'network-only',
    });

    if (categoryMany) {
      const collectionsList =
        (categoryMany.length &&
          categoryMany.map((item) => {
            return {
              _id: item._id,
              id: item._id,
              title: item.name,
              name: { zh: item.title.zh, en: item.title.en },
              container: item.container,
            };
          })) ||
        [];

      await this.setState({ collectionsList });
    } else {
      message.warn('error happened during fetching collections');
    }
  };

  fetchAndProcessWarehouseOrder = async () => {
    const {
      currentWarehouseCart: { productList },
      currentWarehouseId,
    } = this.props;
    if (productList.length === 0 || !currentWarehouseId) {
      message.error('empty cart error! will be redirected in 4 seconds');
    }
  };

  fetchUserCards = async () => {
    // return ;
    const { data } = await apolloClient.query({
      query: FETCH_USER_CARD,
      variables: {},
      fetchPolicy: 'network-only',
    });
    if (data && data.userCurrentUserCard) {
      const temp = data.userCurrentUserCard;
      if (temp.length > 0) {
        this.setState({ listCards: temp, cardId: temp[0].id });
      }
    }
  };

  createOptions = (fontSize, padding) => ({
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, Segoe UI, PingFang SC, Microsoft YaHei',
        '::placeholder': {
          color: '#aab7c4',
        },
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  });

  createErrorPop = (error) => {
    const { currentShoppingMode, intl } = this.props;
    const translatedError = intl.formatMessage({ id: error });
    const msg = intl
      .formatMessage({ id: 'errorForPayment' })
      .replace('[xx.mode]', currentShoppingMode)
      .replace('[xx.error]', translatedError);
    Modal.warning({
      title: 'error',
      content: msg,
      onOk: () => {
        Modal.destroyAll();
      },
    });
  };

  getOrderIdCrane = async () => {
    const {
      selectedAddress,
      customerNote,
      currentWarehouseId,
      // currentWarehouseCart: { productList },
      groupBuyInfo,
      currentShoppingMode,
      currentAddressType,
      // shoppingCart,
      getCurrentWarehouseCart,
      overWriteAddress,
      language,
      intl,
      userEmail,
      group,
      doesUseBalance,
      lastWarehousePath,
    } = this.props;
    // ===============double check inventory
    const shoppingCart = await getCurrentWarehouseCart();
    const variantsNeedInventoryCheck = [];
    const idToObj = {};
    shoppingCart.productList.forEach((item) => {
      if (item.allowedInventoryCheck === true) {
        variantsNeedInventoryCheck.push(item._id);
        idToObj[item._id] = item;
      }
    });
    if (variantsNeedInventoryCheck.length > 0) {
      try {
        const {
          data: { inventoryCurrentWarehouseWithVariantIds },
        } = await apolloClient.query({
          query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
          variables: {
            warehouseId: currentWarehouseId,
            variantIds: variantsNeedInventoryCheck,
          },
          fetchPolicy: 'network-only',
        });
        if (inventoryCurrentWarehouseWithVariantIds) {
          for (
            let i = 0;
            i < inventoryCurrentWarehouseWithVariantIds.length;
            i += 1
          ) {
            const item =
              idToObj[inventoryCurrentWarehouseWithVariantIds[i]._id];
            if (
              inventoryCurrentWarehouseWithVariantIds[i].totalQuantity <
              item.count
            ) {
              Modal.warning({
                content: intl
                  .formatMessage({ id: 'errorInsufficientStock' })
                  .replace('xx.xx', item.name[language]),
                onOk: () => {
                  window.location.replace(getCustomerPath('delivery-menu'));
                },
              });
              return null;
            }
          }
          // for (const variant of inventoryCurrentWarehouseWithVariantIds) {
          // }
        } else {
          return null;
        }
      } catch (error) {
        console.error(error);
        this.createErrorPop('inventory error');
        return null;
      }
    }
    // ==============
    // console.log("shoppingCart",shoppingCart)
    // return null
    console.log('selectedAddress in stripe payment', selectedAddress);
    let selectAddressID = selectedAddress;
    const notes = customerNote;
    if (
      currentShoppingMode === 'groupBuy' &&
      groupBuyInfo &&
      groupBuyInfo.selectedAddress
    ) {
      selectAddressID = groupBuyInfo.selectedAddress;
    }
    let orderInput;
    const shoppingList = _.concat(
      shoppingCart.productList,
      shoppingCart.boxList
    );
    if (currentShoppingMode === 'delivery') {
      orderInput = {
        variantInput: customerOrderFormatter(shoppingList),
        realMode: true,
        doesUseBalance,
      };
      console.log('shoppingCart', shoppingCart);
      if (shoppingCart.deliveryTime) {
        orderInput.deliveryTime = shoppingCart.deliveryTime;
      } else {
        this.createErrorPop('No Delivery Time');
        return null;
      }
      if (shoppingCart.discountCode) {
        orderInput.discountCode = shoppingCart.discountCode;
      }
      // ===overwrite pickup
      if (overWriteAddress) {
        const newAddress = {
          lastName: overWriteAddress.lastName,
          firstName: overWriteAddress.firstName,
          phoneNumber: overWriteAddress.phoneNumber,
        };
        orderInput.overWriteAddress = newAddress;
      }
      const referralCode = window.sessionStorage.getItem('referralCode');
      const referralOrderId = window.sessionStorage.getItem('referralOrderId');
      if (referralCode && referralOrderId) {
        orderInput.referralCode = referralCode;
        orderInput.referralOrderId = referralOrderId;
      }
    } else if (currentShoppingMode === 'pickup') {
      orderInput = {
        variantInput: customerOrderFormatter(shoppingList),
        realMode: true,
      };
      if (shoppingCart.pickupDate) {
        orderInput.pickupDate = shoppingCart.pickupDate;
      } else {
        this.createErrorPop('No Delivery Time');
        return null;
      }
    } else if (
      currentShoppingMode === 'groupBuy' ||
      currentShoppingMode === 'donation'
    ) {
      orderInput = {
        variantInput: customerOrderFormatter(shoppingList),
        realMode: true,
      };
    } else if (currentShoppingMode === 'eShop') {
      orderInput = {
        variantInput: customerOrderFormatter(shoppingList),
        realMode: true,
        isPhysicalCard: false, // TODO: if the card is physical card.default is false
      };
    }

    if (group && group.name === 'guest') {
      orderInput.guestUserEmail = userEmail;
    }

    orderInput.address = selectAddressID;
    orderInput.warehouseId = currentWarehouseId;
    orderInput.notes = notes;
    if (lastWarehousePath.split('?')[0] === getCustomerPath('bao')) {
      orderInput.notes = `===Special Order===${notes}`;
    }
    orderInput.pseudoOrderType = pseudoTypeFormatter(
      currentShoppingMode,
      currentAddressType
    );
    console.log(orderInput);
    if (notes !== null) {
      try {
        // const orderInput = {
        //   variantInput,
        //   address: selectAddressID,
        //   warehouseId: currentWarehouseId,
        //   notes,
        // };
        if (groupBuyInfo && groupBuyInfo.deliveryTime) {
          orderInput.deliveryTime = groupBuyInfo.deliveryTime;
        }
        const { data } = await apolloClient.mutate({
          mutation: CUSTOMER_ORDER_CREATE,
          variables: orderInput,
        });
        // console.log(data);
        return data.orderCreateCustomerOrder;
      } catch (error) {
        this.createErrorPop(error.graphQLErrors[0].message);
        console.error(error);
        return null;
      }
    }
    console.log('test h>>>');
    // console.log('notes is',notes)
    return null;
  };

  checkOpenTime = async () => {
    const { currentWarehouseId } = this.props;
    if (currentWarehouseId) {
      try {
        const { data } = await apolloClient.query({
          query: WAREHOUSE_QUERY_ID,
          variables: { input: currentWarehouseId },
          fetchPolicy: 'network-only',
        });
        return data.warehouseById.isOpen;
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  participateCampaignContent = async (orderID) => {
    const {
      currentCampaignContentId,
      currentShoppingMode,
      currentAddressType,
      userId,
    } = this.props;
    if (
      currentCampaignContentId &&
      ((currentAddressType === 'pickup' &&
        currentShoppingMode === 'delivery') ||
        currentShoppingMode === 'groupBuy')
    ) {
      console.log('add order to participating', orderID);
      const input = {
        campaignContentId: currentCampaignContentId,
      };
      // if ((currentShoppingMode === 'delivery' &&currentAddressType === 'pickup' ) ||currentShoppingMode === 'groupBuy') {
      input.oneOrderId = orderID;
      // }
      try {
        // for create group buy
        const getIndividual = R.pathOr(null, [
          'data',
          'campaignIndividualFindMany',
          0,
          '_id',
        ]);
        const getOrderStatus = R.pathOr(null, [
          'data',
          'campaignIndividualFindMany',
          0,
          'oneOrder',
          'paymentStatus',
        ]);
        // if (currentAddressType === 'delivery') {
        // console.log('groupBuy join activity');
        const myIndividual = await apolloClient.query({
          query: FETCH_MY_CAMPAIGN_INDIVIDUAL,
          variables: { contentId: currentCampaignContentId, userId },
          fetchPolicy: 'network-only',
        });
        const individualId = getIndividual(myIndividual);
        const orderStatus = getOrderStatus(myIndividual);
        if (individualId) {
          // update for failed order
          if (orderStatus === 'draft' || orderStatus === null) {
            console.log('update failed individual');
            const { data } = await apolloClient.mutate({
              mutation: UPDATE_CAMPAIGN_INDIVIDUAL_ORDER,
              variables: { Id: individualId, orderId: orderID },
            });
            if (data.campaignIndividualUpdate.record) {
              return true;
            }
            return false;
          }
          if (
            currentAddressType === 'pickup' &&
            currentShoppingMode === 'delivery'
          ) {
            console.log('create new individual for pickup');
            // create one if not exists
            const { data } = await apolloClient.mutate({
              mutation: CREATE_CAMPAIGN_INDIVIDUAL,
              variables: { input },
            });
            if (data.campaignIndividualParticipate.record._id) {
              return true;
            }
            return false;
          }
          return false;
        }
        console.log('create new individual');
        // create one if not exists
        const { data } = await apolloClient.mutate({
          mutation: CREATE_CAMPAIGN_INDIVIDUAL,
          variables: { input },
        });
        if (data.campaignIndividualParticipate.record._id) {
          return true;
        }
        return false;
        // }
        // =======for pickup and join group buy only
        // if (currentShoppingMode === 'delivery' || currentShoppingMode === 'groupBuy'&&currentShoppingMode==='pickup' ) {
      } catch (error) {
        this.createErrorPop(error.graphQLErrors[0].message);
        // console.log(error.graphQLErrors[0].message);
        return false;
      }
    } else {
      return true;
    }
  };

  // updateVariantPrice = async () => {
  //   const { updateWarehouseCart, getCurrentWarehouseCart } = this.props;
  //   const { collectionsList } = this.state;
  //   const shoppingCart = await getCurrentWarehouseCart();
  //   console.log('update *** fire');
  //   console.log(shoppingCart);
  //   // update price
  //   const variantIds = [];
  //   shoppingCart.productList.forEach((product) => {
  //     variantIds.push(product._id);
  //   });
  //   shoppingCart.boxList.forEach((box) => {
  //     box.boxContent.forEach((product) => {
  //       variantIds.push(product._id);
  //     });
  //   });
  //   const variantsResponse = await apolloClient.query({
  //     query: VARIANTS_QUERY_IDS,
  //     variables: { input: variantIds },
  //     fetchPolicy: 'network-only',
  //   });

  //   const variants = variantsResponse.data.variantByIds;
  //   console.log(variants);
  //   let isPriceSame = true;
  //   // update shoppingCart
  //   const newShoppingCart = {
  //     ...shoppingCart,
  //     productList: shoppingCart.productList.map((product) => {
  //       const newShoppingListItem = { ...product };
  //       const foundVariant = variants.find(
  //         (variant) => variant._id === newShoppingListItem._id
  //       );
  //       console.log('found variant', foundVariant);
  //       if (foundVariant) {
  //         // check date and change list price
  //         const today = moment().valueOf();
  //         if (
  //           foundVariant.isChangeListPriceByDates &&
  //           today <= foundVariant.changeListPriceDates.endDate &&
  //           today >= foundVariant.changeListPriceDates.startDate
  //         ) {
  //           isPriceSame = isPriceSame
  //             ? newShoppingListItem.listPrice === foundVariant.listPrice
  //             : false;
  //           newShoppingListItem.listPrice = foundVariant.listPrice;
  //         } else if (
  //           foundVariant.isChangeListPriceByDates &&
  //           (today > foundVariant.changeListPriceDates.endDate ||
  //             today < foundVariant.changeListPriceDates.startDate)
  //         ) {
  //           isPriceSame = isPriceSame
  //             ? newShoppingListItem.listPrice === foundVariant.originalPrice
  //             : false;
  //           newShoppingListItem.listPrice = foundVariant.originalPrice;
  //         } else {
  //           isPriceSame = isPriceSame
  //             ? newShoppingListItem.listPrice === foundVariant.listPrice
  //             : false;
  //           newShoppingListItem.listPrice = foundVariant.listPrice;
  //         }
  //       }
  //       return newShoppingListItem;
  //     }),
  //     boxList: shoppingCart.boxList.map((box) => {
  //       const newShoppingListItem = { ...box };

  //       newShoppingListItem.boxContent = box.boxContent.map((item) => {
  //         // TODO: check collection
  //         const count = box.boxContent.reduce(
  //           (sum, element) => sum + element.count,
  //           0
  //         );
  //         console.log(count);

  //         let discountPercentage = 1;
  //         if (
  //           collectionsList.find(
  //             (element) => element.container.capacity === count
  //           )
  //         ) {
  //           discountPercentage = collectionsList.find(
  //             (element) => element.container.capacity === count
  //           ).container.discountPercentage;
  //         }
  //         console.log(discountPercentage);
  //         const newItem = { ...item };
  //         const foundVariant = variants.find(
  //           (variant) => variant._id === newItem._id
  //         );
  //         console.log(foundVariant);
  //         console.log('new', newItem);
  //         if (foundVariant) {
  //           // check date and change list price
  //           const today = moment().valueOf();
  //           newItem.listPrice *= discountPercentage;
  //           console.log(
  //             newItem.listPrice,
  //             foundVariant.listPrice,
  //             foundVariant.originalPrice
  //           );
  //           if (
  //             foundVariant.isChangeListPriceByDates &&
  //             today <= foundVariant.changeListPriceDates.endDate &&
  //             today >= foundVariant.changeListPriceDates.startDate
  //           ) {
  //             isPriceSame =
  //               newItem.listPrice ===
  //               foundVariant.listPrice * discountPercentage;
  //             newItem.listPrice = foundVariant.listPrice * discountPercentage;
  //           } else if (
  //             foundVariant.isChangeListPriceByDates &&
  //             (today > foundVariant.changeListPriceDates.endDate ||
  //               today < foundVariant.changeListPriceDates.startDate)
  //           ) {
  //             isPriceSame =
  //               newItem.listPrice ===
  //               foundVariant.originalPrice * discountPercentage;
  //             newItem.listPrice =
  //               foundVariant.originalPrice * discountPercentage;
  //           } else {
  //             isPriceSame =
  //               newItem.listPrice ===
  //               foundVariant.listPrice * discountPercentage;

  //             newItem.listPrice = foundVariant.listPrice * discountPercentage;
  //           }
  //         }
  //         return newItem;
  //       });
  //       let totalPrice = 0;
  //       newShoppingListItem.boxContent.forEach((item) => {
  //         totalPrice += item.listPrice * item.count;
  //       });
  //       newShoppingListItem.listPrice = totalPrice;
  //       return newShoppingListItem;
  //     }),
  //   };
  //   await updateWarehouseCart(newShoppingCart);
  //   return isPriceSame;
  // };

  handleSubmitToPay = async (ev) => {
    const {
      checkPriceUpdate,
      handleSetOrderId,
      updateWarehouseCart,
      getCurrentWarehouseCart,
    } = this.props;
    const { collectionsList } = this.state;
    ev.preventDefault();
    const { handleDisableTabs, handleEnableTabs } = this.props;
    await this.setState({ payClicked: true });
    const shoppingCart = await getCurrentWarehouseCart();
    const isPriceSame = await updateVariantPrice(
      shoppingCart,
      updateWarehouseCart,
      collectionsList
    );
    console.log(691, isPriceSame);
    if (!isPriceSame) {
      console.log('needs update');
      checkPriceUpdate();
      await this.setState({ payClicked: false });
      return;
    }
    handleDisableTabs();
    const { currentShoppingMode } = this.props;
    let order;
    // ================= validation
    if (
      currentShoppingMode === 'delivery' ||
      currentShoppingMode === 'pickup'
    ) {
      // order pickup only
      if (currentShoppingMode === 'pickup') {
        // check pick up store whether is it closed
        // const openOrNot = await this.checkOpenTime();
        // if (openOrNot === false) {
        //   this.createErrorPop('errorStoreClose');
        //   await this.setState({ payClicked: false });
        //   handleEnableTabs();
        //   return;
        // }
      }
      order = await this.getOrderIdCrane();
      if (!order) {
        await this.setState({ payClicked: false });
        handleEnableTabs();
        return;
      }
    } else if (
      currentShoppingMode === 'groupBuy' ||
      currentShoppingMode === 'donation' ||
      currentShoppingMode === 'eShop'
    ) {
      order = await this.getOrderIdCrane();
      if (!order) {
        await this.setState({ payClicked: false });
        handleEnableTabs();
        return;
      }
    } else {
      await this.setState({ payClicked: false });
      handleEnableTabs();
      this.createErrorPop('Incorrect Shopping Mode');
      return;
    }
    // join in campaign
    if ((await this.participateCampaignContent(order._id)) === false) {
      await this.setState({ payClicked: false });
      handleEnableTabs();
      // this.createErrorPop('Error Happened during participating activity!');
      return;
    }
    // pass validation
    const { stripe, elements, balanceTotal, doesUseBalance } = this.props;
    const { cardId, saveCardOrNot } = this.state;
    let finalInputForFoundation;
    // return
    try {
      if (cardId === 'newCard') {
        // ============new card method
        if (stripe) {
          const cardElement = elements.getElement(CardElement);
          const { error, token } = await stripe.createToken(cardElement);
          // const stripeResponse = await stripe.createPaymentMethod({
          //   type: 'card',
          //   card: cardElement,
          // });
          // console.log(stripeResponse);
          if (error) {
            if (doesUseBalance && order.total < balanceTotal) {
              console.log('balance enough');
            } else {
              handleEnableTabs();
              await this.setState({ payClicked: false });
              this.createErrorPop('Invalid Card');
              return;
            }
          }
          const tokenId = token?.id;
          finalInputForFoundation = {
            newToken: tokenId,
            orderID: order._id,
            saveCardOrNot,
          };
          // ====== check if user want to save card
        } else {
          handleEnableTabs();
          await this.setState({ payClicked: false });
          this.createErrorPop("Stripe hasn't loaded yet.");
          return;
        }
      } else {
        // ============== save card method
        finalInputForFoundation = { card: cardId, orderID: order._id };
      }

      // acutal payment part to foundation
      const { data } = await apolloClient.mutate({
        mutation: SUBMIT_ORDER,
        variables: finalInputForFoundation,
      });
      if (data && data.orderCreateCustomerOrderPayment) {
        message.success('success!');
        handleSetOrderId(order._id);
        this.fakeSuccess();
        return;
      }
      this.createErrorPop('Code 401.');
      await this.setState({ payClicked: false });
      return;
    } catch (error) {
      console.error(error);
      handleEnableTabs();
      await this.setState({ payClicked: false });
      this.createErrorPop(error.graphQLErrors[0].message);
    }
    // await this.setState({ payClicked: false });
  };

  checkGroupBuyRulesLanguage = () => {
    const { groupBuyRules } = this.state;
    const { language } = this.props;
    return (
      groupBuyRules?.ruleDetailDescription &&
      groupBuyRules?.ruleDetailDescription[language] &&
      BraftEditor.createEditorState(
        convertRawToHTML(
          JSON.parse(groupBuyRules?.ruleDetailDescription[language] || '{}')
        )
      ).toText()
    );
  };

  onCheckBoxChange = ({ target }) => {
    this.setState({
      doesGroupBuyRulesCheck: target.checked,
    });
  };

  render() {
    const {
      listCards,
      cardId,
      loading,
      payClicked,
      groupBuyRuleVisible,
      groupBuyRules,
      doesGroupBuyRulesCheck,
      hasGroupBuy,
      // saveCardOrNot,
    } = this.state;
    const {
      group: { name },
      payDisabled,
      language,
    } = this.props;
    // console.log(saveCardOrNot);

    const isGroupBuyDisabled = () => {
      if (!hasGroupBuy) {
        return false;
      }
      return !(groupBuyRules && doesGroupBuyRulesCheck);
    };
    return (
      <div>
        <Modal visible={payClicked} footer={null} closable={false}>
          <Loading replaceMsg="processingOrder" />
        </Modal>
        <Modal
          visible={this.checkGroupBuyRulesLanguage() && groupBuyRuleVisible}
          footer={null}
          header={<FormattedMessage id="groupBuyRules" />}
          onCancel={() => {
            this.setState({
              groupBuyRuleVisible: false,
            });
          }}
          destroyOnClose
        >
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {groupBuyRules?.ruleDetailDescription[language] &&
              BraftEditor.createEditorState(
                convertRawToHTML(
                  JSON.parse(
                    groupBuyRules?.ruleDetailDescription[language] || '{}'
                  )
                )
              ).toText()}
          </div>
        </Modal>
        <div style={{ marginTop: '20px' }}>
          <FormattedMessage id="cardDetail" />
        </div>
        <RadioGroup
          className="savedCards"
          onChange={this.handleUserChooseChange}
          value={cardId}
        >
          {listCards.map((card) => (
            <Radio className="option" key={card.id} value={card.id}>
              <span className="creditCard">
                <img src={this.getBrandImg(card.brand)} alt="card logo" />
                {`**** **** **** ${card.last4}`}
              </span>
            </Radio>
          ))}
          {loading === false ? (
            <>
              <Radio
                className="option newcard"
                value="newCard"
                style={{ marginBottom: '10px' }}
              >
                <FormattedMessage id="addNewCard" />
              </Radio>
            </>
          ) : null}
          <br />
        </RadioGroup>
        {loading === false && cardId === 'newCard' ? (
          <>
            <FormattedMessage id="saveCard" />
            <Switch
              style={{ marginLeft: '10px' }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={name !== 'guest'}
              onChange={(check) => {
                this.setState({ saveCardOrNot: check });
              }}
              disabled={name === 'guest'}
            />
            <form onSubmit={this.handleSubmitToPay}>
              <div>
                <div>
                  <CardElement style={{ base: { fontSize: '18px' } }} />
                </div>
              </div>
              {hasGroupBuy && (
                <div
                  className={`payment-check-box ${
                    doesGroupBuyRulesCheck ? 'payment-check-box-checked' : ''
                  }`}
                >
                  <Checkbox
                    onChange={this.onCheckBoxChange}
                    checked={doesGroupBuyRulesCheck}
                  >
                    <FormattedMessage id="groupBuyConfirmation" />{' '}
                  </Checkbox>
                  <span
                    style={{ textDecoration: 'underline' }}
                    role="presentation"
                    onClick={() => {
                      this.setState({
                        groupBuyRuleVisible: true,
                      });
                    }}
                  >
                    <FormattedMessage id="learnMore" />
                  </span>
                </div>
              )}
              {cardId === 'newCard' && payClicked === false ? (
                <button
                  disabled={payDisabled || payClicked || isGroupBuyDisabled()}
                  className="stripe-payment-button"
                  type="submit"
                  style={{ marginTop: '20px' }}
                >
                  <CreditCardOutlined /> <FormattedMessage id="payWithCard" />
                </button>
              ) : null}
            </form>
          </>
        ) : null}

        {cardId !== 'newCard' && payClicked === false ? (
          <>
            {hasGroupBuy && (
              <div
                className={`payment-check-box ${
                  doesGroupBuyRulesCheck ? 'payment-check-box-checked' : ''
                }`}
              >
                <Checkbox
                  onChange={this.onCheckBoxChange}
                  checked={doesGroupBuyRulesCheck}
                >
                  <FormattedMessage id="groupBuyConfirmation" />{' '}
                </Checkbox>
                <span
                  style={{ textDecoration: 'underline' }}
                  role="presentation"
                  onClick={() => {
                    this.setState({
                      groupBuyRuleVisible: true,
                    });
                  }}
                >
                  <FormattedMessage id="learnMore" />
                </span>
              </div>
            )}
            <Button
              className="stripe-payment-button"
              onClick={this.handleSubmitToPay}
              style={{ marginTop: '20px' }}
              disabled={payClicked || payDisabled || isGroupBuyDisabled()}
            >
              <CreditCardOutlined /> <FormattedMessage id="payWithCard" />
            </Button>
          </>
        ) : null}
        {payClicked === true ? (
          <Button className="stripe-payment-button">
            <FormattedMessage id="processingOrder" />
          </Button>
        ) : null}
      </div>
    );
  }
}

OrderPaymentStripeForm.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  stripe: PropTypes.shape({ createToken: PropTypes.func.isRequired })
    .isRequired,
  elements: PropTypes.shape({ getElement: PropTypes.func.isRequired })
    .isRequired,
  handlePaymentSuccess: PropTypes.func.isRequired,
  currentAddressType: PropTypes.string.isRequired,
  // updateShoppingCart: PropTypes.func.isRequired,
  selectedAddress: PropTypes.string,
  customerNote: PropTypes.string.isRequired,
  currentWarehouseId: PropTypes.string,
  currentShoppingMode: PropTypes.string.isRequired,
  saleorOrderInput: PropTypes.shape({
    input: PropTypes.shape({}),
    code: PropTypes.string,
  }),
  updateWarehouseCart: PropTypes.func.isRequired,
  currentCampaignContentId: PropTypes.string,
  currentWarehouseCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  userId: PropTypes.string.isRequired,
  // groupBuyShippingTime: PropTypes.number,
  // setUserShoppingMode: PropTypes.func.isRequired,
  // setUserAddressType: PropTypes.func.isRequired,
  updateOneUserAttribute: PropTypes.func.isRequired,
  groupBuyInfo: PropTypes.shape({
    deliveryTime: PropTypes.number,
    selectedAddress: PropTypes.shape({}),
  }),
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    discountCode: PropTypes.string,
    deliveryTime: PropTypes.number,
  }),
  overWriteAddress: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }),
  language: PropTypes.string.isRequired,
  handleDisableTabs: PropTypes.func.isRequired,
  handleEnableTabs: PropTypes.func.isRequired,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  checkPriceUpdate: PropTypes.func,
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  payDisabled: PropTypes.bool,
  userEmail: PropTypes.string,
  handleSetOrderId: PropTypes.func.isRequired,
  doesUseBalance: PropTypes.bool.isRequired,
  balanceTotal: PropTypes.number.isRequired,
  lastWarehousePath: PropTypes.string,
};

OrderPaymentStripeForm.defaultProps = {
  selectedAddress: null,
  // groupBuyShippingTime: null,
  currentWarehouseId: null,
  saleorOrderInput: {},
  currentCampaignContentId: null,
  currentWarehouseCart: {
    productList: [],
  },
  groupBuyInfo: null,
  shoppingCart: {
    productList: [],
    boxList: [],
  },
  overWriteAddress: null,
  checkPriceUpdate: () => {},
  payDisabled: false,
  userEmail: null,
  lastWarehousePath: null,
};

const mapStateToProps = (state) => ({
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  currentShoppingMode: state.user.currentShoppingMode,
  currentAddressType: state.user.currentAddressType,
  currentWarehouseId: state.warehouse.warehouseId,
  saleorId: state.auth.saleorId,
  currentCampaignContentId: state.user.currentCampaignContentId,
  userId: state.user.userId,
  currentWarehouseCart: state.cart.currentWarehouseCart,
  groupBuyInfo: state.user.groupBuyInfo,
  overWriteAddress: state.user.overWriteAddress,
  shoppingCart: state.cart.shoppingCart,
  history: ReactRouterPropTypes.history.isRequired,
  language: state.language.lang,
  lastWarehousePath: state.warehouse.lastWarehousePath,
  group: state.user.group,
});

const mapDispatchToProps = (dispatch) => ({
  updateShoppingCart: (shoppingCart) =>
    dispatch(actions.updateShoppingCart(shoppingCart)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  // setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
  // setUserAddressType: (mode) => dispatch(actions.setUserAddressType(mode)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderPaymentStripeForm));
