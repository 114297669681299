export default {
  en: {
    addMore: 'Add More',
    soldOut: 'Sold Out',
    endActivity: 'Ended',
    comingSoon: 'Soon',
    viewBox: 'View Box',
    limitedProductsDate: 'Special Shipping Date',
    menu: {
      cutOffTime: 'Cut Off Time',
      canNotOrderForThisWeek:
        'Store not available this week. Order for next week.',
      orderBeforeTheDay: 'Order before {date} for this week’s delivery',
      excludeFromFreeShipping: 'Exclude from free shipping',
      deliveryFeeByRange: 'Delivery fee is {deliveryFee} for {range} miles',
      deliveryFeeByAmount:
        'Delivery fee is {deliveryFee} under {amount} Purchase',
      freeShippingScheduledDelivery: 'Free Shipping With {amount} Purchase',
    },
  },
  zh: {
    addMore: '继续购买',
    soldOut: '售罄',
    endActivity: '已结束',
    comingSoon: '未开始',
    viewBox: '查看盒子',
    limitedProductsDate: '限时配送日期',
    menu: {
      cutOffTime: '截单时间',
      canNotOrderForThisWeek: '预定下周配送',
      orderBeforeTheDay: '在{date}前，您可以预定这周配送',
      excludeFromFreeShipping: '不参与免费配送',
      deliveryFeeByRange: '在{range}迈区间内运费{deliveryFee}',
      deliveryFeeByAmount: '运费{deliveryFee}当订单总额不满{amount}',
      freeShippingScheduledDelivery: '购物满 {amount} 包邮',
    },
  },
};
