/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import { Carousel, Button } from 'antd';
import { nanoid } from 'nanoid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Lightbox from 'react-image-lightbox';
import groupBuyingShippingFeeEnglish from '../../../assets/images/groupBuyingShippingFeeEnglish.png';
import groupBuyingShippingFeeChinese from '../../../assets/images/groupBuyingShippingFeeChinese.png';
import groupBuyingDiscountEnglish from '../../../assets/images/groupBuyingDiscountEnglish.png';
import groupBuyingDiscountChinese from '../../../assets/images/groupBuyingDiscountChinese.png';

function GroupBuyingGuide(props) {
  const { language } = props;

  const [isOpenLightBox, setOpenLightBox] = useState(false);
  const [lightBoxMainImage, setLightBoxMainImage] = useState(null);

  const Arrow = ({ type, style, className, onClick }) => (
    <div
      style={{
        ...style,
        transform: type === 'right' ? 'rotate(-45deg)' : 'rotate(135deg)',
        border: 'solid black',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
      }}
      role="presentation"
      className={className}
      onClick={onClick}
    />
  );
  Arrow.defaultProps = { style: null, className: null, onClick: null };
  Arrow.propTypes = {
    type: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  const handleOpenLightBox = (image) => {
    setOpenLightBox(true);
    setLightBoxMainImage(image);
  };

  return (
    <div>
      <div
        css={{
          fontSize: '15px',
          margin: 'auto',
          width: 'auto',
          // border: '1px solid #f2f2f2',
          padding: '10px',
          // borderRadius: '5px',
        }}
      >
        {isOpenLightBox ? (
          <Lightbox
            mainSrc={lightBoxMainImage}
            onCloseRequest={() => {
              setOpenLightBox(false);
            }}
          />
        ) : null}
        <Carousel
          dotPosition="right"
          arrows
          prevArrow={<Arrow type="left" />}
          nextArrow={<Arrow type="right" />}
          css={{
            margin: 'auto',
            '& .text-description': { marginBottom: '5px' },
          }}
        >
          {[
            {
              english: groupBuyingDiscountEnglish,
              chinese: groupBuyingDiscountChinese,
            },
            {
              english: groupBuyingShippingFeeEnglish,
              chinese: groupBuyingShippingFeeChinese,
            },
            {
              english: groupBuyingShippingFeeEnglish,
              chinese: groupBuyingShippingFeeChinese,
            },
          ].map((item, index) => {
            return (
              <div key={nanoid(5)}>
                {language === 'zh' ? (
                  <div>
                    <img
                      src={item.chinese}
                      css={{
                        width: '100%',
                        margin: 'auto',
                        display: 'block',
                      }}
                      alt="discount"
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={item.english}
                      css={{
                        width: '100%',
                        margin: 'auto',
                        display: 'block',
                      }}
                      alt="discount"
                    />
                  </div>
                )}
                <div className="text-description">
                  {index === 0 && <RightOutlined />} 1.{' '}
                  <FormattedMessage id="groupBuyDescriptionOne" />{' '}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      if (language === 'zh') {
                        handleOpenLightBox(groupBuyingDiscountChinese);
                      } else {
                        handleOpenLightBox(groupBuyingDiscountEnglish);
                      }
                    }}
                  >
                    <FormattedMessage id="view" />
                  </Button>
                </div>
                <div className="text-description">
                  {index === 1 && <RightOutlined />} 2.{' '}
                  <FormattedMessage id="groupBuyDescriptionTwo" />{' '}
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      if (language === 'zh') {
                        handleOpenLightBox(groupBuyingShippingFeeChinese);
                      } else {
                        handleOpenLightBox(groupBuyingShippingFeeEnglish);
                      }
                    }}
                  >
                    <FormattedMessage id="view" />
                  </Button>
                </div>
                <div className="text-description">
                  {index === 2 && <RightOutlined />} 3.{' '}
                  <FormattedMessage id="groupBuyDescriptionThree" />{' '}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

GroupBuyingGuide.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(GroupBuyingGuide);
