import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { apolloClient } from '../../../../util';
import { POINT_BY_USER, POINTLOG_PAGINATION } from '../../../../api/point';
import './Points.less';

const { TabPane } = Tabs;
class Points extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      myPoints: 0,
      tableData: [],
    };
    console.log(props);
  }

  componentDidMount() {
    this.fetchUserPoint();
    this.fetchPointLog();
  }

  fetchUserPoint = async () => {
    const {
      user: { userId },
    } = this.props;
    try {
      const {
        data: { PointOne },
      } = await apolloClient.query({
        query: POINT_BY_USER,
        variables: { filter: { userId } },
      });
      console.log(PointOne.current);
      this.setState({
        myPoints: PointOne.current,
      });
    } catch (error) {
      console.debug(error);
    }
  };

  fetchPointLog = async (input = {}) => {
    const {
      user: { userId },
    } = this.props;
    const filter = { userId };
    if (Object.keys(input).length) {
      Object.assign(filter, input);
    }
    console.log(filter);
    try {
      const {
        data: { PointLogPagination },
      } = await apolloClient.query({
        query: POINTLOG_PAGINATION,
        variables: { filter },
      });
      console.log(PointLogPagination);
      this.setState({
        tableData: (PointLogPagination && PointLogPagination.items) || [],
      });
    } catch (error) {
      console.debug(error);
    }
  };

  onTabChange = (value) => {
    switch (value) {
      case 'all':
        this.fetchPointLog();
        break;
      case 'add': {
        const filter = {
          _operators: {
            total: {
              gte: 0,
            },
          },
        };
        this.fetchPointLog(filter);
        break;
      }
      case 'used': {
        const filter = {
          _operators: {
            total: {
              lt: 0,
            },
          },
        };
        this.fetchPointLog(filter);
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { lang, intl } = this.props;
    console.debug(lang);
    const { myPoints, tableData } = this.state;
    const columns = [
      {
        title: intl.formatMessage({ id: 'point.point' }),
        dataIndex: 'total',
        key: 'total',
        render: (_, record) => <div>{record.total}</div>,
      },
      {
        title: intl.formatMessage({ id: 'point.detail' }),
        dataIndex: '_id',
        key: '_id',
        render: (_, record) => <div>{record._id}</div>,
      },
      // {
      //   title: intl.formatMessage({ id: 'point.status' }),
      //   dataIndex: 'status',
      //   key: 'status',
      //   render: (_, record) => <div>{record.status}</div>,
      // },
      {
        title: intl.formatMessage({ id: 'point.date' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => (
          <div>{moment(record.createdAt).format('MM/DD/YYYY')}</div>
        ),
      },
    ];

    const desktop = (
      <div className="point-desktop">
        <div
          style={{ padding: '10px 30px', maxWidth: '1024px', margin: 'auto' }}
        >
          <div>
            <span className="common-bold-title">
              <FormattedMessage id="point.myPoints" />
            </span>
            <span style={{ paddingLeft: '20px', textDecoration: 'underline' }}>
              <FormattedMessage id="point.pointRule" />
            </span>
          </div>
          <div className="common-margin-top-lg my-point-banner">
            <div className="my-point">
              <div style={{ fontWeight: '500' }}>
                <FormattedMessage id="point.myPoints" />
              </div>
              <div className="common-bold-title">{myPoints}</div>
            </div>
          </div>
          <div className="common-margin-top-sm point-function">
            <div />
            <div />
          </div>
          <div className="common-margin-top-lg">
            <div className="common-bold-title">
              <FormattedMessage id="point.transactions" />
            </div>
            <div className="common-margin-top-sm">
              <Tabs
                className="ant-tab-line-remove"
                defaultActiveKey="1"
                onChange={this.onTabChange}
                tabBarStyle={{ color: 'black' }}
              >
                <TabPane
                  tab={intl.formatMessage({ id: 'point.all' })}
                  key="all"
                />
                <TabPane
                  tab={intl.formatMessage({ id: 'point.add' })}
                  key="add"
                />
                <TabPane
                  tab={intl.formatMessage({ id: 'point.used' })}
                  key="used"
                />
              </Tabs>
            </div>
            <div className="common-margin-top-sm antd-table">
              <Table columns={columns} dataSource={tableData} rowKey="_id" />
            </div>
          </div>
        </div>
      </div>
    );

    const mobile = (
      <div style={{ padding: '0px 10px' }} className="point-mobile">
        <div className="banner">
          <div className="my-point">
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '30px' }}>{myPoints}</span>{' '}
              <FormattedMessage id="point.point" />
            </div>
            <div className="flex-center">
              <div className="point-rules">
                <FormattedMessage id="point.pointRule" />
              </div>
            </div>
          </div>
          <div className="point-functions">
            <div>empty now</div>
            <div>empty now</div>
          </div>
        </div>
        <div>
          <div style={{ marginTop: '35px' }}>
            <Tabs
              className="ant-tab-line-remove"
              defaultActiveKey="1"
              onChange={this.onTabChange}
              tabBarStyle={{ color: 'black' }}
            >
              <TabPane
                tab={intl.formatMessage({ id: 'point.all' })}
                key="all"
              />
              <TabPane
                tab={intl.formatMessage({ id: 'point.add' })}
                key="add"
              />
              <TabPane
                tab={intl.formatMessage({ id: 'point.used' })}
                key="used"
              />
            </Tabs>
          </div>
          <div className="common-margin-top">
            {tableData.length ? (
              tableData.map((item) => (
                <div key={item._id} className="point-item">
                  <div className="dash-line-container flex-center">
                    <div className="line-dot" />
                    <div className="line-dashed" />
                  </div>
                  <div className="total">
                    <div> {item.total}</div>
                    <div style={{ color: '#717171', fontWeight: 'normal' }}>
                      {moment(item.createdAt).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="padding-top order-id">ID: {item._id}</div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>No Data</div>
            )}
          </div>
        </div>
      </div>
    );
    return isMobile ? mobile : desktop;
  }
}

Points.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    userId: PropTypes.string,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.language.lang,
  user: state.user,
});
export default connect(mapStateToProps, null)(injectIntl(Points));
