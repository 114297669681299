/**
 * @param ballWrapper Father Container
 * @param origin Start DOM
 * @param target Target DOM
 * @param time Total Time
 * @param a A
 * @param offset Animation Size
 * @param callback Callback
 */

export default (config) => {
  const {
    ballWrapper,
    origin,
    target,
    time = 2000,
    a = 0.004,
    callback,
    finish,
    offset = 0,
  } = config || {};
  const ballWrapperDimension = ballWrapper.getBoundingClientRect();
  const originDimension = origin.getBoundingClientRect();
  const targetDimension = target.getBoundingClientRect();
  const x1 = originDimension.left + 0.5 * originDimension.width;
  const y1 = originDimension.top + 0.5 * originDimension.height;
  const x2 = targetDimension.left + 0.5 * targetDimension.width;
  const y2 = targetDimension.top + 0.5 * targetDimension.height;
  const diffX = x2 - x1;
  const diffY = y2 - y1;
  const speedX = diffX / time;
  const b = (diffY - a * diffX * diffX) / diffX;

  const refPointX = x1 - ballWrapperDimension.left - 0.5 * offset;
  const refPointY = y1 - ballWrapperDimension.top - 0.5 * offset;

  const start = Date.now();
  const timer = setInterval(() => {
    if (Date.now() - start > time) {
      finish();
      clearInterval(timer);
      return;
    }

    const x = speedX * (Date.now() - start);
    const y = a * x * x + b * x;
    if (callback) {
      callback(refPointX + x, refPointY + y);
    }
  }, 0.01);
};
