export default {
  en: {
    // dashboard
    balance: 'Balance Amount',
    moreInfo: 'More',
    appliedCode: 'Discount Code',
    appliedCode2: 'Code',
    orderNow: 'Order Now',
    twentyFourHours: '24h Delivery',
    carSticker: 'Car Sticker',
    production: 'Warehouse Products Sold In Bunbao Delivery Value',
    selfProductInWarehouse: 'Warehouse Products Sold In Warehouse Value',
    bunbaoProductInWarehouse: 'Bunbao Products Sold In Warehouse Value',
    'Distribution Overview': 'Distribution Overview',
    warehouseInfo: 'Warehouse Info',
    warehouseSettings: 'Warehouse Settings',
    businessSettings: 'Business Settings',
    productManagement: 'Product Management',
    warehouseOrders: 'Warehouse Orders',
    warehouseRuleSettings: 'Rules Settings',
    warehouseCategory: 'Categories',
    productList: 'Add To Menu',
    newUser: 'New Users',
    customerOrdersCreated: 'customerOrders (Created)',
    customerOrdersDelivered: 'customerOrders (Delivered)',
    cateringOrders: 'Catering Orders',
    refundOrderTotal: 'Refund Total',
    partiallyRefundTotal: 'Partially Refund Total',
    giftCardSaleTotal: 'Gift Card Sale Total',
    giftCardActualSaleTotal: 'Gift Card Actual Sale Total(Before)',
    giftCardDiscountRate: 'Gift Card Discount Rate',
    salesInfo: 'Customer Referral',
    allSalesInvitation: 'All Sales',
    salesCustomerInfo: 'Referred Customers',
    salesShiftInfo: 'Shift Record',
    // ==========sign up and log in
    inviter: 'Inviter',
    signUp: 'Sign Up',
    email: 'Email',
    plsEmail: 'Please input your email',
    invalidEmail: 'Invalid email address',
    plsPassword: 'Please input your password at least 6 digits',
    password: 'Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    welcomeBack: 'Welcome Back',
    welcomeSignUp: 'Hello, New Friend!',
    haveAccount: 'Have an account?',
    dotNotHaveAccount: "Don't have an account?",
    isConsistent: 'Two passwords that you enter is inconsistent!',
    emailDoesNotExist: 'Sorry, email does not exist, please {signUp} first.',
    emailExists: 'Sorry, the email already exists.',
    sendEmailToFindPassword: 'Reset Password By Email',
    sendEmail: 'Send Link to Your Email',
    successAndCheck: 'Success！ Check Your Email Now.',
    forgetPassword: 'Forget Your Password?',
    click: 'Click',
    "User with this email doesn't exist": "User with this email doesn't exist",
    putNewPassword: 'Type Your New Password',
    setPasswordRedirect: 'Success and Redirect',
    resetMyPassword: 'Reset My Password',
    resetPassword: 'Reset Password',
    registerToGetBenefit:
      'Send Link, Sign Up By Link, Get Coupons and Benefits',
    // ======customer nav,
    home: 'Home',
    map: 'Map',
    account: 'Account',
    orders: 'Orders',
    points: 'Points',
    balanceManagement: 'Balance Management',
    giftCardManagement: 'Gift Card Management',
    giftCard: 'Gift Card',
    store: 'Current Store',
    cloudStore: "Farmer's Market",
    // ======shopping cart
    shoppingCart: 'Shopping Cart',
    productName: 'Name',
    price: 'Price',
    quantity: 'Quantity',
    subtotal: 'Subtotal',
    totalPrice: 'Total',
    discountAmount: 'Discount Amount',
    actualPaid: 'Actual Paid',
    tax: 'Taxes and Fees',
    taxFee: 'Tax',
    deliveryFee: 'Delivery Fee',
    totalPriceIs: 'Total is',
    image: 'Image',
    closeButton: 'Back To Menu',
    onlyLeft: 'Insufficient Stock ',
    storeCloseNow: 'Store is closed',
    deliveryRule: 'Delivery Rule',
    loginRedirecting: 'Please Login Before Check out',
    loginRedirectingContinue: 'Please Login First',
    invalidVariants:
      'Some products are not available. They will be removed from shopping cart.',
    invalidVariant: 'The product is not available.',
    groupBuyLimitOneOrder:
      'Only one GroupBuy item is allowed in your cart. If you want to purchase another GroupBuy item, please complete your current order and start another one.',
    moreFreeShippingTip: '* spend {price} more, get free shipping',
    // ======order check out
    back: 'Back',
    information: 'Information',
    pay: 'Pay',
    done: 'Done',
    note: 'Note',
    noteForOrder:
      'Instructions for driver (e.g. gate code, building color, etc)',
    cardDetail: 'Card Details',
    addNewCard: 'Add New Card',
    payWithCard: 'Pay With Card',
    clickCheckOrder: 'Click to Check Your Order',
    clickCheckOrderGroup: 'Click to Share Your Group Buy',
    shippingDate: 'Shipping Date',
    pleaseSelectShippingDate: 'Please select a shipping date',
    pleaseSelectPickupDate: 'Please select a pickup date',
    pleaseSelectPickupOption: 'Please select a pickup option',
    coupon: 'Coupon',
    handInputCoupon: 'Type Coupon Code',
    apply: 'Apply',
    use: 'Use',
    notActive: 'Not Active',
    haveCoupon: 'Have a coupon?',
    codeSuccess: 'Applied Code Successfully',
    currentCoupon: 'Code Applied',
    remove: 'Remove',
    processingOrder: 'Processing Order...',
    checkingOrder: 'Checking Order...',
    saveCard: 'Save Payment Method',
    groupBuyAddress: 'GroupBuy Address',
    pickupLocation: 'Pick Up Location',
    pickupTimeWindow: 'Pick Up Time',
    orderDetailDelivery: 'Delivery Shipping Date',
    orderDetailPickup: 'Pick Up @',
    pickupOptions: 'Pick Up Options',
    selectYouInformation: 'Select Contact Information',
    selectYouPickupDate: 'Select Pick Up Date',
    checkItOut: 'Check It Out',
    backToShoppingCart: 'Back to Shopping Cart',
    inPerson: 'We Are Using Contactless Delivery',
    selectFromWallet: 'Coupon Wallet',
    yourPreSelectedAddress: 'This was Your Selected Address',
    collapse: 'Collapse',
    bunbaoBooth: 'BunBao Booth',
    // ======address
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    create: 'Create',
    select: 'Confirm',
    choose: 'Choose',
    addConPickup: 'Add New Contact Information',
    noConInfo: 'No Contact Info?',
    addCon: 'Add Contact Info',
    addConDelivery: 'Add Delivery Info',
    contactInfo: 'Contact Info',
    deliveryAddress: 'Delivery Address',
    delivered: 'Delivered',
    proofOfDelivery: 'Proof of Delivery',
    edit: 'Edit',
    delete: 'Delete',
    completeIt: 'To Complete',
    addressOne: 'Address',
    addressTwo: 'APT/UNIT(Optional)',
    city: 'City',
    zipCode: 'Zip Code',
    gateCode: 'Instructions for driver (e.g. gate code, building color, etc)',
    rePurchaseRate: 'Repurchase Rate',
    userPlaceOrder: 'Users Place Order',
    userPlaceOrder2: 'Users Place Order Twice and More',
    zipCodeTip: 'Please Enter zip code to check delivery range.',
    pickUpTip: 'Delivery is not available, please select pick up!',
    // ======pick up menu
    left: 'Left',
    outOfStock: 'Out of stock',
    addSign: 'Added!',
    addFail: 'Added Failed!',
    deleteSign: 'Deleted!',
    checkOut: 'Checkout',
    viewCart: 'View Cart',
    empty: 'Coming Soon!',
    emptyCart: 'Empty Cart',
    insufficientStock: 'Insufficient Stock',
    pickupRule: 'Waiting Time',
    mins: 'mins',
    dineRule: 'This is the Dine-in Menu or go',
    dineRule2: 'This is the Dine-in Menu',
    backToMap: 'Back to Map',
    haveDonated: 'Total Donation',
    donationDescriptions: 'Donation Description',
    usedDonation: 'Used Donation',
    notUsedDonation: 'Waiting for Next Usage',
    donationWord: 'Donation',
    viewMyDonation: 'View My Donation',
    // =======map
    nearMe: 'Me',
    nearest: 'By Distance',
    tenMile: '10 Miles',
    twentyMile: '20 Miles',
    thirtyMile: '30 Miles',
    clear: 'Clear',
    distributor: 'Distributor',
    central: 'Central',
    pickup: 'Pick Up',
    delivery: 'Delivery',
    dineIn: 'Dine In',
    open: 'Open',
    close: 'Close',
    openTime: 'Open Time',
    filter: 'Filter',
    partnerWith: 'Partner With',
    searchAddress: 'Search An Address',
    // =======overview
    orderCreated: 'Created Time',
    Id: 'ID',
    status: 'Status',
    content: 'Products',
    storeAddress: 'Store Address',
    showProduct: 'Show Product',
    because: 'Because',
    storeInfo: 'Store Information',
    reorder: 'Reorder',
    direction: 'Direction',
    orderDetails: 'Order Details',
    totalItem: 'Total',
    items: 'Items',
    recentOrder: 'RECENT ORDER',
    previousOrder: 'PREVIOUS ORDER',
    updateYourInfo: 'Update Your Information',
    update: 'Update',
    deliveryOrder: 'Delivery Order',
    cloudStoreOrders: 'Cloud Store Orders',
    placed: 'Order Placed',
    outForDelivery: 'Out for Delivery',
    paymentStatus: 'Payment Status',
    payment: 'Payment',
    makeSureDeleteCard: 'Do you want to delete the card?',
    myGroupBuy: 'My Group Buy',
    myCoupon: 'Coupon Wallet',
    myInvitation: 'My Invitation',
    participator: 'Participator',
    currentInviteesAmount: 'Invited',
    currentInviteesAmount2: 'Friends',
    currentObtainedCoupon: 'Obtained',
    currentObtainedCoupon2: 'Coupons',
    howToShare: 'How To Share',
    summary: 'Summary',
    shareLink: 'Copy Link',
    shareCode: 'Share Code ',
    organizer: 'Organizer',
    pickupAddressAndInfo: 'Pickup Address and Contact Information',
    orderType: 'Order Type',
    eShop: 'eShop',
    storeName: ' Store Name',
    // ======= game
    game: 'Game',
    games: 'Games',
    faq: 'FAQ',
    week: 'week',
    orderID: 'Order ID',
    orderDetail: 'Order Detail',
    deliveryDate: 'Delivery Date',
    confirm: 'Confirm',
    orderWillBe: 'Your order will be delivered on this date between 3pm-7pm.',
    uploadImage: 'Upload',
    sampleImg: 'Sample Image',
    imageNotice:
      'Notice, the image must include the car sticker and your plate.',
    finished: 'Finished',
    noSelected: 'Not Selected',
    unfinishedUpload: "Wait until previous week's image is uploaded",
    nextUploadTime: 'next upload need to be done after this date: ',
    CARTICKER: 'CAR STICKER ACTIVITY',
    // =======global
    query: 'Search',
    yourOrder: 'Your Pick Up Order',
    atAddress: 'At Address:',
    isis: 'Is',
    comingSoon: 'Coming Soon',
    copy: 'Copy',
    copyAndShare: 'Copy & Share',
    copied: 'Copied & Ready to Share ',
    copiedAndShare: 'Copied & Ready Share',
    orOr: 'OR',
    // error
    error1: ' ',
    updateSuccess: 'Update successful!',
    updateFailure: 'Sorry, can not update.',
    blankError: 'Please fill out all of required blank',
    notAllowedZip: 'Your zip code is not in the delivery area!',
    errorShoppingMode: 'Incorrect Shopping Mode',
    notValidPhone: 'Phone Number is Not Valid',
    isEmpty: ' is Empty',
    errorValidatingOrder: 'Error Happened during Validating Order. ',
    // popConfirm
    deleteConfirm: 'Are you sure delete it?',
    yes: 'Yes',
    no: 'No',
    // database words
    notConfirmed: 'Not Confirmed',
    confirmed: 'Confirmed',
    readyForPickup: 'Ready For Pick Up',
    canceled: 'Canceled',
    cancelPending: 'Cancel In Pending',
    complete: 'Complete',
    active: 'Active',
    CHARGED: 'Authorized',
    CAPTURED: 'Charged',
    NOT_CHARGED: 'Not Charged',
    FULLY_REFUNDED: 'Fully Refunded',
    All: 'All',
    Bao: 'Bao',
    'Deluxe Bao': 'Deluxe Bao',
    'Side Dish': 'Side Dish',
    'Gift Box': 'Gift Box',
    '50% DISCOUNT': 'Special Box',
    fixLocation: 'Fix Location Pick Up',
    normalDelivery: 'Normal Delivery',
    donation: 'Donation Order',
    groupBuy: 'Group Buy',
    // payment
    moreOptions: 'More Options',
    partiallyPaid: 'Partially Paid',
    partiallyRefunded: 'Partially Refunded',
    charged: 'Pending',
    paid: 'Paid',
    refunded: 'Refund',
    thankYouForPeacherAtBunBao: 'Successfully Purchased at BunBao',
    thankYouForDonation: 'Thank You for Donation',
    willRedirectToGroupBuying: 'Will redirect to group buying',
    shoppingMode: 'Shopping Mode:',
    // banner
    banner: 'Banner',
    banners: 'Banners',
    // record
    record: 'Record',
    records: 'Records',
    // device
    device: 'Device',
    devices: 'Devices',
    // messages
    newOrder: 'New Order',
    // delivery Menu
    recommendedVariants: 'Recommended Products',
    freeShopping:
      'Free Shipping With {value} Purchase, or pickup with 0 delivery fee',
    boxDetails: 'Box Details',
    continueShopping: 'Continue Shopping',
    isNotFull: 'Box not full, please add more',
    isFull: 'is Completed',
    currentBox: 'Current Combination',
    addToShopping: 'Add to Shopping Cart',
    deleteCollection: 'Clear And Reselect',
    backToView: 'Back',
    cantNotChange: 'Once added, the combination can not be changed.',
    三拼组合: '3-Flavor Box',
    单品组合: '1-Flavor Box',
    自由组合: 'Mix&Match',
    return: 'Return',
    loading: 'loading...',
    view: 'View',
    vipRule:
      'Purchase total three bao boxes or gift boxes to become a BunBao VIP!',
    bao: 'Bao',
    'side dish': 'Side Dish',
    'deluxe bao': 'Deluxe Bao',
    '50% discount': 'Gift Box',
    'gift box': 'Gift Box',
    noOrders: 'No Orders',
    noPickupOrders: 'No Pickup Orders',
    placeAnOrderNow: 'Place an order now!',
    yourOrderWillBeDeliveredAtDuringHoursBelow:
      'Your order will be delivered at during hours below',
    operationHours: 'Visit this market:',
    deliveryHours: 'Local delivery window:',
    announcement: 'Note From Seller',
    shoppingCartGroupBuyTips:
      'There is a GroupBuy item in your cart. Please note that this might not be the final price. You will only be charged at the final discount price when GroupBuy ends.',
    groupBuyConfirmation:
      'This order contains GroupBuy item, please be sure to read the rules carefully.',
    learnMore: 'Learn more',
    // Inventory
    supplierSort: 'supplierSort',
    isOnMarket: 'Is on Market',
    name: 'Name',
    pastWeekTotalSale: 'Past Week Total',
    pastMonthTotalSale: 'Past Month Total',
    forecastQuantity: 'Sale Forecast',
    currentInventory: 'Quantity',
    foodTrucks: 'Food Truck',
    foodTruckMoments: 'Food Truck Moments',
    foodTruckReport: 'Food Truck Report',
    fmMarket: 'FM Market',
    labelsManagement: 'Labels Management',
    FMInventoryManagement: 'FM Inventory Management',
    productLabel: 'Product Labels',
    fmMarketLog: 'FM Market Log',
    fmBoxesInventoryLogs: 'FM Boxs Inventory Log',
    fmSellReturnLogs: 'FM Sell/Return Log',
    farmersMarketReport: 'FM sales Report',
    fmLossLogs: 'FM Loss Logs',
    usedOrExpiredCoupons: 'Used / Expired Coupons',
    minimumAmountSpent: 'Minimum spending amount',
    hiring: 'Hiring',
    career: 'Career',
    careers: 'Careers',
    squareUsers: 'Square Users',
    squareCatalogBindVariants: 'Square Catalog Bind Variant',
  },
  zh: {
    // dashboard
    balance: '余额支付',
    moreInfo: '详情',
    appliedCode: '折扣码',
    appliedCode2: '折扣码',
    orderNow: '开始订购',
    twentyFourHours: '24小时配送',
    carSticker: '车贴',
    production: '在Bunbao配送销售的产品总价',
    selfProductInWarehouse: '分店产品在分店销售总价',
    bunbaoProductInWarehouse: 'Bunbao产品在分店销售总价',
    'Distribution Overview': '分销结算/生产单',
    warehouseInfo: '店铺信息',
    warehouseSettings: '店铺信息设置',
    businessSettings: '绑定Stripe',
    warehouseOrders: '查看订单',
    warehouseRuleSettings: '规则设置',
    warehouseCategory: '产品分类',
    productManagement: '产品添加更新',
    productList: '上架/下架产品',
    newUser: '新增用户',
    customerOrdersCreated: '订单数量 (创建时间)',
    customerOrdersDelivered: '订单数量 (运送时间)',
    cateringOrders: '公司订单',
    refundOrderTotal: '退款总额',
    partiallyRefundTotal: '部分退款总额',
    giftCardSaleTotal: '礼品卡总额(实际支付)',
    giftCardActualSaleTotal: '礼品卡总额(实际价值)',
    giftCardDiscountRate: '礼品卡折扣率',
    salesInfo: '客户引流',
    allSalesInvitation: '销售人员数据',
    salesCustomerInfo: '已邀客户',
    salesShiftInfo: '打卡记录',
    // ==========sign up and log in
    inviter: '邀请人',
    signUp: '注册',
    email: '邮箱',
    invalidEmail: '这不是正确的邮箱地址',
    plsEmail: '请输入邮箱',
    plsPassword: '请输入密码,至少6位',
    password: '密码',
    newPassword: '新密码',
    confirmPassword: '再次输入密码',
    welcomeBack: '欢迎回来',
    welcomeSignUp: '欢迎注册',
    haveAccount: '已经有账号了？',
    dotNotHaveAccount: '没有账号？',
    isConsistent: '两次密码不一致',
    emailDoesNotExist: '邮箱不存在，请您{signUp}后再登录',
    emailExists: '邮箱已存在',
    sendEmailToFindPassword: '输入您的邮箱为您找回密码',
    sendEmail: '发送链接到您邮箱',
    successAndCheck: '发送成功，请去邮箱检查',
    forgetPassword: '忘记密码?',
    click: '点击',
    "User with this email doesn't exist": '该邮箱无效',
    putNewPassword: '输入新密码',
    setPasswordRedirect: '成功，跳转中...',
    resetMyPassword: '重置我的密码',
    resetPassword: '重置密码',
    registerToGetBenefit: '发送链接到邮箱,通过链接注册,获取折扣劵和其他福利',
    // ======customer nav,
    home: '主页',
    map: '地图',
    account: '我的账户',
    orders: '订单',
    store: '当前商店',
    cloudStore: '生鲜市场',
    // ======shopping cart
    shoppingCart: '购物车',
    productName: '名字',
    price: '价格',
    quantity: '数量',
    subtotal: '小计',
    totalPrice: '总价',
    discountAmount: '减免',
    tax: '税和其他费用',
    taxFee: '税',
    deliveryFee: '配送费',
    totalPriceIs: '总价为',
    image: '图片',
    closeButton: '返回菜单',
    onlyLeft: '库存不足，只有 ',
    storeCloseNow: '商店目前不营业',
    deliveryRule: '配送规则',
    loginRedirecting: '您未登录,请登录后继续结账',
    loginRedirectingContinue: '您未登录,请登录后继续',
    invalidVariants: '有些商品已下架，将自动从购物车删除。',
    invalidVariant: '商品已下架',
    groupBuyLimitOneOrder:
      '每单只能购买一件拼团商品哦，如果还看上了别的拼团商品，请您结账后另下一单～',
    moreFreeShippingTip: '* 还差 {price}，免运费哦!',
    actualPaid: '实际支付',
    // ======order check out
    back: '返回',
    information: '信息填写',
    pay: '支付',
    done: '完成',
    note: '留言',
    noteForOrder:
      '请在这里告知我们是否需要公寓大门密码(例如1234)或其他信息，帮助我们安全送达您的包裹',
    cardDetail: '信用卡信息',
    addNewCard: '添加新卡',
    payWithCard: '用此卡支付',
    clickCheckOrder: '点击查看订单',
    clickCheckOrderGroup: '点击分享团购',
    shippingDate: '配送日期',
    pleaseSelectShippingDate: '请您选择一个配送日期',
    pleaseSelectPickupDate: '请您选择一个自取日期',
    pleaseSelectPickupOption: '请您选择一个自取选项',
    coupon: '折扣券',
    handInputCoupon: '输入折扣券',
    apply: '检查并使用',
    use: '使用',
    notActive: '未生效',
    haveCoupon: '有折扣券？输入后点击右侧按钮',
    codeSuccess: '折扣券使用成功',
    currentCoupon: '正在使用',
    remove: '移除',
    processingOrder: '处理订单中...',
    checkingOrder: '检查订单中...',
    saveCard: '保存支付信息',
    groupBuyAddress: '团购地址',
    pickupLocation: '自取地点',
    orderDetailPickup: '取货时间',
    orderDetailDelivery: '配送时间',
    pickupTimeWindow: '自取时间',
    pickupOptions: '自取选项',
    selectYouInformation: '选择联系方式',
    selectYouPickupDate: '选择自取日期',
    checkItOut: '去看看',
    backToShoppingCart: '回到购物车',
    inPerson: '预防疫情,我们采用无接触配送',
    selectFromWallet: '优惠券卡包',
    yourPreSelectedAddress: '这是您刚才选的地址',
    collapse: '收起',
    bunbaoBooth: '棒包摊位',
    // ======address
    firstName: '名',
    lastName: '姓',
    phone: '电话',
    create: '创建',
    select: '确认',
    choose: '选择',
    addConPickup: '添加新的联系方式',
    noConInfo: '没有联系方式?',
    addConDelivery: '添加配送地址',
    contactInfo: '联系方式',
    deliveryAddress: '配送地址',
    addressOne: '地址',
    addressTwo: '公寓号(选填)',
    city: '城市',
    zipCode: '邮编',
    gateCode: '例如门禁密码或其他信息',
    rePurchaseRate: '复购率',
    userPlaceOrder: '下单人数',
    userPlaceOrder2: '复购人数',
    zipCodeTip: '请输入邮编查询是否配送。',
    pickUpTip: '当前邮编不可配送，请选择自取，谢谢',
    // ======pick up menu
    left: '剩余',
    outOfStock: '售罄',
    addFail: '添加失败',
    addSign: '添加成功!',
    deleteSign: '删除成功!',
    checkOut: '结账',
    viewCart: '查看购物车',
    empty: '该店暂无商品',
    emptyCart: '购物车空空如也',
    insufficientStock: '商品库存不足！',
    pickupRule: '等待时间',
    mins: '分钟',
    dineRule: '这是堂食菜单,或前往',
    dineRule2: '这是堂食菜单',
    backToMap: '返回地图',
    haveDonated: '共捐赠',
    donationDescriptions: '捐赠说明',
    usedDonation: '已使用的捐赠',
    notUsedDonation: '未使用的捐赠',
    donationWord: '捐赠',
    viewMyDonation: '查看我的捐赠',
    // =======map
    nearMe: '定位我',
    nearest: '最近的店铺',
    tenMile: '10英里内',
    twentyMile: '20英里内',
    thirtyMile: '30英里内',
    clear: '重置',
    distributor: '店铺',
    central: '总部',
    pickup: '自取',
    delivery: '配送',
    dineIn: '堂食',
    open: '营业中',
    close: '不营业',
    openTime: '营业时间',
    partnerWith: '合作伙伴',
    filter: '筛选',
    comingSoon: '即将开业',
    searchAddress: '输入一个地址',
    edit: '编辑',
    delete: '删除',
    completeIt: '补充',
    // =======overview
    orderCreated: '下单时间',
    Id: '订单号',
    status: '状态',
    content: '购买产品',
    storeAddress: '商家地址',
    showProduct: '查看',
    because: '因为',
    storeInfo: '店家信息',
    reorder: '再次订购',
    points: '积分',
    balanceManagement: '余额管理',
    giftCardManagement: '礼品卡管理',
    giftCard: '礼品卡',
    direction: '导航',
    orderDetails: '订单详情',
    totalItem: '共计',
    items: '个商品',
    recentOrder: '最新订单',
    previousOrder: '之前订单',
    updateYourInfo: '更新您的信息',
    update: '更新',
    deliveryOrder: '订单',
    cloudStoreOrders: '云店订单',
    placed: '已下单',
    outForDelivery: '配送中',
    delivered: '配送完成',
    proofOfDelivery: '交货证明',
    paymentStatus: '支付状态',
    payment: '支付',
    makeSureDeleteCard: '您确认删除此卡吗？',
    myGroupBuy: '我的团购',
    myCoupon: '优惠券卡包',
    myInvitation: '我的邀请',
    participator: '参加者',
    currentInviteesAmount: '邀请了',
    currentInviteesAmount2: '个朋友',
    currentObtainedCoupon: '得到了',
    currentObtainedCoupon2: '个折扣券',
    howToShare: '如何分享',
    summary: '总览',
    shareLink: '分享链接',
    shareCode: '分享二维码',
    organizer: '发起者',
    pickupAddressAndInfo: '自取地址和联系方式',
    orderType: '订单种类',
    storeName: ' 店铺名字',
    eShop: '虚拟商城',
    // ======= game
    game: '游戏',
    games: '游戏',
    faq: '常见问题',
    week: '周数',
    orderID: '订单号',
    orderDetail: '订单详情',
    deliveryDate: '配送日期',
    confirm: '确定',
    orderWillBe: '您的订单会在该日期的3pm-7pm配送到您家.',
    uploadImage: '上传照片',
    sampleImg: '例图',
    imageNotice: '提示,图片中需包含后窗LOGO和车牌照',
    finished: '已完成',
    noSelected: '未选择',
    unfinishedUpload: '请等到上周的照片上传完成',
    nextUploadTime: '本星期上传通道将于此日期后开启: ',
    CARTICKER: '棒包陪你走天下',
    // =======global
    query: '查询',
    yourOrder: '您的订单',
    atAddress: '位于',
    isis: '现在是',
    copy: '复制',
    copyAndShare: '复制&待分享',
    copied: '内容已复制,快去粘贴分享吧',
    copiedAndShare: '已复制&待分享',
    orOr: '或者',
    // error
    error1: ' ',
    updateSuccess: '更新成功！',
    updateFailure: '抱歉更新失败.',
    blankError: '请填写空白部分',
    notAllowedZip: '您的地址不在配送范围！',
    errorShoppingMode: '不正确购物模式',
    notValidPhone: '电话号码不正确',
    isEmpty: '为空',
    errorValidatingOrder: '检查订单的时候错误发生. ',
    // POP Confirm
    deleteConfirm: '您确认要删除么？',
    yes: '是',
    no: '否',
    // database words
    notConfirmed: '待商家确认',
    confirmed: '商家已确认',
    readyForPickup: '可以取货',
    canceled: '已取消',
    cancelPending: '等待取消',
    complete: '已完成',
    active: '进行中',

    CHARGED: '已授权未付款',
    CAPTURED: '已支付',
    NOT_CHARGED: '未授权',
    FULLY_REFUNDED: '已退款',

    All: '全部',
    Bao: '包子',
    'Deluxe Bao': '精品包子',
    'Side Dish': '包子周边',
    'Gift Box': '卡通包',
    '50% DISCOUNT': '礼盒',

    fixLocation: '定点自取',
    normalDelivery: '24小时配送',
    groupBuy: '拼团',
    donation: '捐赠订单',

    // payment
    moreOptions: '更多选项',
    partiallyPaid: '支付部分',
    partiallyRefunded: '部分退款',
    charged: '待收款',
    paid: '已支付',
    refunded: '已退款',
    thankYouForPeacherAtBunBao: '感谢您订购棒包！',
    thankYouForDonation: '感谢您的捐赠！',
    willRedirectToGroupBuying: '即将为您跳转到团购页面',
    shoppingMode: '购物模式:',
    // banner
    banner: '横幅',
    banners: '横幅',
    // record
    record: '记录',
    records: '记录',
    // device
    device: '设备',
    devices: '设备',
    // messages
    newOrder: '新订单',
    // delivery Menu
    recommendedVariants: '推荐商品',
    freeShopping: '购物满 {value} 包邮，您也可以自取免邮费',
    boxDetails: '盒子详情',
    continueShopping: '继续购物',
    isNotFull: '盒子未满，请您继续购买',
    isFull: '已满',
    currentBox: '当前盒子',
    addToShopping: '加入购物车',
    deleteCollection: '清空重选',
    backToView: '返回',
    cantNotChange: '加入后无法再修改',
    三拼组合: '三拼组合',
    单品组合: '单品组合',
    自由组合: '自由组合',
    return: '返回',
    loading: '加载中...',
    view: '查看',
    vipRule: '累计购买包子或者礼盒共3个自动成为我们的VIP会员！',
    bao: '包子',
    'side dish': '包子周边',
    'deluxe bao': '健康精品包',
    '50% discount': '包包推荐礼盒',
    'gift box': '礼盒',
    noOrders: '没有订单哦',
    noPickupOrders: '没有云店订单哦',
    placeAnOrderNow: '快下第一单吧！',
    yourOrderWillBeDeliveredAtDuringHoursBelow: '您的订单将在以下时间送达',
    operationHours: '营业时间:',
    deliveryHours: '配送时间:',
    announcement: '进店必读',
    shoppingCartGroupBuyTips:
      '您的订单里包含一个拼团产品, 这可能不是最终价格, 最终价格以拼团成交折扣价为准.',
    groupBuyConfirmation: '此订单包含拼团产品, 请确认仔细阅读拼团规则.',
    learnMore: '了解更多',
    supplierSort: '供应商',
    isOnMarket: '上架(是/否)',
    name: '名字',
    pastWeekTotalSale: '前7天销售数',
    pastMonthTotalSale: '前30天销售数',
    forecastQuantity: '预计2天销售数',
    currentInventory: '当前库存',
    foodTrucks: '餐车',
    foodTruckMoments: '餐车动态',
    foodTruckReport: '餐车报告',
    fmMarket: '农贸市场',
    labelsManagement: '标签管理',
    FMInventoryManagement: '农贸市场库存',
    productLabel: '产品标签',
    fmMarketLog: '农贸市场记录',
    fmBoxesInventoryLogs: '市场产品库存',
    fmSellReturnLogs: '市场出/回库记录',
    farmersMarketReport: '市场销售报表',
    fmLossLogs: '市场损耗记录',
    usedOrExpiredCoupons: '使用过或过期优惠券',
    minimumAmountSpent: '最低消费金额',
    hiring: '招聘',
    career: '职业',
    careers: '职业',
    squareUsers: 'Square用户',
    squareCatalogBindVariant: 'Square商品绑定',
  },
};
