export default {
  en: {
    ingredients: 'Ingredients',
    nutritionFacts: 'Nutrition Facts',
    productDescription: 'Description',
    addNewProduct: 'Add New Product',
    resetRanking: 'Reset Ranking',
    resetCategory: 'Reset Category',
    openExpandRow: 'Open Expanded Rows',
    productInfo: 'Product Information',
    enName: 'English Name',
    zhName: 'Chinese Name',
    enDescription: 'English Description',
    zhDescription: 'Chinese Description',
    manufactureCategory: 'Manufacture Category',
    minimumAddQuantity: 'Minimum Add Quantity',
    ranking: 'Ranking',
    category: 'Category',
    hashtags: 'Hashtags',
    // ingredients: 'Ingredients',
    'Weight Per Unit': 'Weight Per Unit',
    'Unit Shape': 'Unit Shape',
    'Nutrition Facts': 'Nutrition Facts',
    'Allergen Information': 'Allergen Information',
    Unit: 'Unit',
    enSubtitle: 'English Subtitle',
    zhSubtitle: 'Chinese Subtitle',
    'Original Price': 'Original Price',
    Price: 'Price',
    Container: 'Container',
    groupBuyRules: 'How it Works',
  },
  zh: {
    ingredients: '主要成分',
    nutritionFacts: '营养成分',
    productDescription: '产品介绍',
    addNewProduct: '添加新产品',
    resetRanking: '重置排名',
    resetCategory: '重置分类',
    openExpandRow: '打开所有子项目',
    productInfo: '产品信息',
    enName: '英文名字',
    zhName: '中文名字',
    enDescription: '英文描述',
    zhDescription: '中文描述',
    manufactureCategory: '生产单分类',
    minimumAddQuantity: '最小添加数量',
    ranking: '排名',
    category: '分类',
    hashtags: '标签',
    // ingredients: '产品成分',
    Unit: '单位',
    'Weight Per Unit': '单个产品重量',
    'Unit Shape': '产品形状',
    'Nutrition Facts': '产品营养成分',
    'Allergen Information': '过敏成分',
    enSubtitle: '英文标题',
    zhSubtitle: '中文标题',
    'Original Price': '原价',
    Price: '价格',
    Container: '形状/容器',
    groupBuyRules: '拼团规则',
  },
};
