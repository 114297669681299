import gql from 'graphql-tag';

export const BALANCE_LOG_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: FilterFindManyBalanceLogInput
    $sort: SortFindManyBalanceLogInput
  ) {
    BalanceLogPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        orderId
        total
        createdAt
        customerOrder {
          _id
          displayId
          total
        }
        type
        status
      }
    }
  }
`;

export const DASHBOARD_BALANCE_LOG_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: FilterFindManyBalanceLogInput
    $sort: SortFindManyBalanceLogInput
  ) {
    BalanceLogPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        orderId
        total
        createdAt
        customerOrder {
          _id
          displayId
          total
        }
        type
        status
        user {
          _id
          email
        }
      }
    }
  }
`;

export const DASHBOARD_BALANCE_LOG_OVERALL = gql`
  query {
    BalanceLogOverall {
      referralTotal
      referralUsed
    }
  }
`;

export const DASHBOARD_BALANCE_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: FilterFindManyBalanceInput
    $sort: SortFindManyBalanceInput
  ) {
    BalancePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        userId
        total
        referralTotal
        promotionTotal
        topUpTotal
        refundTotal
        pending
        type
        createdAt
        user {
          _id
          email
        }
      }
    }
  }
`;

export const BALANCE_ALL_TOTAL = gql`
  query {
    balanceStatistic {
      refundTotal
      topUpTotal
      referralTotal
      promotionTotal
    }
  }
`;

export const BALANCE_GIFT_CARD_TOTAL = gql`
  query {
    balanceStatistic {
      topUpTotal
      promotionTotal
    }
  }
`;

export const GIFT_CARD_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int = 20
    $filter: FilterFindManyGiftCardInput
    $sort: SortFindManyGiftCardInput
  ) {
    giftCardPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        user {
          _id
          email
        }
        usedUser {
          _id
          email
        }
        createdAt
        total
        cardNumber
        status
        customerOrder {
          _id
          displayId
        }
        purpose
      }
    }
  }
`;

export const BALANCE_CREATE_ONE = gql`
  mutation ($input: RelayCreateOneBalanceLogInput!) {
    BalanceLogCreateOne(input: $input) {
      recordId
    }
  }
`;

export const GIFT_CARD_SEND = gql`
  mutation ($cardId: MongoID!, $email: String!) {
    sendGiftCard(cardId: $cardId, email: $email)
  }
`;

export const GIFT_CARD_SEND_OTP = gql`
  mutation {
    sendOneTimePassCode
  }
`;

export const GIFT_CARD_GENERATE_GIFT_CARD = gql`
  mutation (
    $code: String
    $quantity: Int
    $totalValue: Float
    $promotionValue: Float
    $purpose: String
  ) {
    generateGiftCard(
      code: $code
      quantity: $quantity
      totalValue: $totalValue
      promotionValue: $promotionValue
      purpose: $purpose
    ) {
      number
      password
    }
  }
`;

export const GIFT_CARD_BAN = gql`
  mutation ($cardId: MongoID!) {
    banGiftCard(cardId: $cardId)
  }
`;

export const BALANCE_BY_USER = gql`
  query ($filter: FilterFindOneBalanceInput) {
    BalanceOne(filter: $filter) {
      total
      referralTotal
      topUpTotal
      refundTotal
      promotionTotal
      user {
        _id
        email
        referralCode
        referralLevel {
          _id
          minSales
          maxSales
          referralCoefficient
        }
      }
    }
  }
`;
