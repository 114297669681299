import gql from 'graphql-tag';

export const POINT_BY_USER = gql`
  query ($filter: FilterFindOnePointInput) {
    PointOne(filter: $filter) {
      total
      pending
      current
    }
  }
`;

export const POINTLOG_PAGINATION = gql`
  query ($page: Int, $perPage: Int = 20, $filter: FilterFindManyPointLogInput) {
    PointLogPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      items {
        total
        status
        createdAt
        _id
      }
    }
  }
`;

export const POINT_PAGINATION_FRAGMENT = gql`
  fragment PointPaginationFragment on PointPagination {
    count
    items {
      userId
      total
      current
      pending
      type
      email
    }
  }
`;

export const POINT_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyPointInput
    $sort: SortFindManyPointInput
  ) {
    PointPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      ...PointPaginationFragment
    }
  }
  ${POINT_PAGINATION_FRAGMENT}
`;

export const POINTLOG_PAGINATION_FRAGMENT = gql`
  fragment PointLogPaginationFragment on PointLogPagination {
    count
    items {
      displayId
      total
      email
      pointId
      userId
      orderId
      total
      status
    }
  }
`;

export const POINT_LOG_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyPointLogInput
    $sort: SortFindManyPointLogInput
  ) {
    PointLogPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      ...PointLogPaginationFragment
    }
  }
  ${POINTLOG_PAGINATION_FRAGMENT}
`;

export const POINTLOG_MANY_FRAGMENT = gql`
  fragment PointLogManyFragment on PointLogMany {
    displayId
    total
    email
    pointId
    userId
    orderId
    total
    status
  }
`;

export const POINT_LOG_MANY = gql`
  query (
    $filter: FilterFindManyPointLogInput
    $sort: SortFindManyPointLogInput
  ) {
    PointLogMany(filter: $filter, sort: $sort) {
      displayId
      total
      email
      pointId
      userId
      orderId
      total
      status
    }
  }
`;
