import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  token: null,
  refreshToken: null,
  error: null,
  loading: false,
  saleorId: null,
  loginRedirectPage: '',
};

const authStart = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  const updateState = {
    token: action.token,
    refreshToken: action.refreshToken,
    saleorId: action.saleorId,
    error: null,
    loading: false,
  };
  return updateObject(state, updateState);
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    token: null,
    refreshToken: null,
  });
};

const authLogout = (state) => {
  return updateObject(state, {
    token: null,
    refreshToken: null,
    saleorId: null,
  });
};

export const authSaleorUserUpdate = (state, action) => {
  const updateState = {
    saleorId: action.saleorId,
  };
  return updateObject(state, updateState);
};

export const authRedirectPageUpdate = (state, action) => {
  // console.log('saved', action.loginRedirectPage);
  const updateState = {
    loginRedirectPage: action.loginRedirectPage,
  };
  return updateObject(state, updateState);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_SALEOR_ID:
      return authSaleorUserUpdate(state, action);
    case actionTypes.AUTH_SAVE_LINK:
      return authRedirectPageUpdate(state, action);
    default:
      return state;
  }
};
