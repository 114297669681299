import React, { Component } from 'react';
import { message } from 'antd';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { saleorClient, getCustomerPath, apolloClient } from '../../../util';
import ComingSoonEn from '../../../assets/images/account/comingSoonEn.png';
import ComingSoonZh from '../../../assets/images/account/comingSoonZh.png';
import {
  ACTIVITIES_QUERY,
  CHECK_USER_IN_ACTIVITY,
} from '../../../apiCanary/activity';
import { VOTE_CAMPAIGN_MANY } from '../../../api/vote';
// import { GET_USER_ID_BY_EMAIL } from '../../../apiCanary/user';
import { BUCKET_CDN_URL, BUCKET_URL } from '../../../config';
import './Games.less';

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      voteCampaignActivities: [],
    };
  }

  componentDidMount = async () => {
    // const { token } = this.props;
    // if (token) {

    // }
    await this.fetchActivities();
    await this.fetchVoteCampaign();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchActivities = async () => {
    // const { email } = this.props;
    // try {
    // const {
    //   data: { userByEmail },
    // } = await saleorClient.query({
    //   query: GET_USER_ID_BY_EMAIL,
    //   variables: { input: email },
    //   fetchPolicy: 'network-only',
    // });
    // if (userByEmail.id) {
    //   // const { id } = userByEmail;
    //   console.log(email);
    // if (!id) {
    //   history.push(getCustomerPath('account'));
    // }
    const { data } = await saleorClient.query({
      query: ACTIVITIES_QUERY,
      variables: {},
      fetchPolicy: 'network-only',
    });
    const res = data.activities.edges;
    this.setState({
      // userInfoLoading: false,
      activities: res,
    });
    // }
    // } catch (error) {
    //   message.warning(
    //     '该功能暂时不开放. This function is under maintenance.',
    //     3
    //   );
    // }
  };

  fetchVoteCampaign = async () => {
    try {
      const {
        data: { voteCampaignMany },
      } = await apolloClient.query({
        query: VOTE_CAMPAIGN_MANY,
      });
      console.log(voteCampaignMany);
      this.setState({
        voteCampaignActivities: voteCampaignMany,
      });
    } catch (err) {
      console.log(err);
    }
  };

  isUserInActivity = async (activityId) => {
    const { email } = this.props;
    try {
      const { data } = await saleorClient.query({
        query: CHECK_USER_IN_ACTIVITY,
        variables: { activityId, email },
        fetchPolicy: 'network-only',
      });
      if (data.contentByUser.userStatus === 'PARTICIPATOR') {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  handleButtonClick = async (node) => {
    const { lang } = this.props;
    const inActivity = await this.isUserInActivity(node.node.id);
    if (inActivity) {
      window.location.replace(
        getCustomerPath(`account/activity-detail?index=${node.node.id}`)
      );
    } else {
      message.success(
        lang === 'zh'
          ? '该游戏已经结束请等待下一轮.'
          : 'This game is closed now and wait for the next turn.'
      );
    }
  };

  handleVoteActivityRedirect = (id) => {
    const { history } = this.props;
    history.push({
      pathname: getCustomerPath('vote-campaign'),
      search: `voteCampaignId=${id}`,
    });
  };

  render() {
    const { activities, voteCampaignActivities } = this.state;
    const { lang } = this.props;
    // console.log(activities);

    const activityItemStyle = {
      height: isMobile ? '160px' : '150px',
      width: isMobile ? '100%' : '300px',
    };

    return (
      <div
        style={{
          padding: '10px 20px',
          textAlign: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="activity-outer-container">
          {voteCampaignActivities.map((voteCampaign) => (
            <div
              key={voteCampaign._id}
              style={activityItemStyle}
              className="activity-item-container"
              role="presentation"
              onClick={() => {
                this.handleVoteActivityRedirect(voteCampaign._id);
              }}
            >
              <div>
                {lang === 'zh' ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    alt="car"
                    src={`${BUCKET_URL}${voteCampaign.iconZh.key}`}
                  />
                ) : (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    alt="car"
                    src={`${BUCKET_URL}${voteCampaign.iconEn.key}`}
                  />
                )}
              </div>
              <div
                className="flex-center"
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  border: 'solid 1px #f2f2f2',
                  // borderRadius: '10px',
                }}
              >
                <div>{voteCampaign.title[lang]}</div>
                {/* <div>{voteCampaign.shortIntro[lang]}</div> */}
              </div>
            </div>
          ))}
          {activities.map((node) => (
            <div
              key={node.node.id}
              className="activity-item-container"
              style={activityItemStyle}
            >
              <div>
                {lang === 'zh' ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    alt="car"
                    role="presentation"
                    onClick={async () => {
                      await this.handleButtonClick(node);
                    }}
                    src={`${BUCKET_CDN_URL}images/campaign/carAdsSmallZh.png`}
                  />
                ) : (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    alt="car"
                    role="presentation"
                    onClick={async () => {
                      await this.handleButtonClick(node);
                    }}
                    src={`${BUCKET_CDN_URL}images/campaign/carAdsSmallEn.png`}
                  />
                )}
              </div>
              <div
                className="flex-center"
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  border: 'solid 1px #f2f2f2',
                  // borderRadius: '10px',
                }}
              >
                {/* <Link
                    to={`/customer/account/activity-detail?index=${node.node.id}`}
                  >
                    <FormattedMessage id={node.node.name} />
                  </Link> */}
                <div
                  onClick={async () => {
                    await this.handleButtonClick(node);
                  }}
                  role="presentation"
                >
                  <FormattedMessage id={node.node.name} />
                </div>
              </div>
            </div>
          ))}
          <div>
            <div
              style={{
                // border: '1px solid #f2f2f2',
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...activityItemStyle,
              }}
            >
              <div style={{ height: '100%', width: '100%' }}>
                {lang === 'zh' ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    src={ComingSoonZh}
                    alt="coming soon"
                  />
                ) : (
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    src={ComingSoonEn}
                    alt="coming soon"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Games.defaultProps = {
  email: null,
  // token: null,
};

Games.propTypes = {
  // token: PropTypes.string,
  lang: PropTypes.string.isRequired,
  email: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.language.lang,
  email: state.user.email,
});

export default connect(mapStateToProps, null)(Games);
