/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Layout, Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormattedMessage } from 'react-intl';
// import { GiftOutlined, SmileTwoTone } from '@ant-design/icons';
import * as actions from '../../../store/actions';
import logoFull from '../../../assets/images/logo.png';
import iconChinese from '../../../assets/images/icons/Chinese.png';
import iconEnglish from '../../../assets/images/icons/English.png';
import hat from '../../../assets/images/icons/hat.png';
import tree from '../../../assets/images/icons/tree.png';
// import { ReactComponent as HeartIcon } from '../../../assets/images/icons/heart.svg';
import { getCustomerPath, apolloClient } from '../../../util';
import { USER_CURRENT_UPDATE } from '../../../api/user';

// const { SubMenu } = Menu;

const { Header } = Layout;

export class CustomerNav extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedKeys: [],
    };
  }

  componentDidMount = () => {
    const { pathname } = window.location;
    const pathnameArray = pathname.split('/');
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_BUILD_DEV === 'true'
    ) {
      this.setState({ selectedKeys: [pathnameArray[2]] });
    } else {
      this.setState({ selectedKeys: [pathnameArray[1]] });
    }
  };

  updateUserLanguage = async (language) => {
    const { userId } = this.props;
    try {
      const {
        data: { userCurrentUpdate },
      } = await apolloClient.mutate({
        mutation: USER_CURRENT_UPDATE,
        variables: { input: { record: { language, _id: userId } } },
      });
      console.log(userCurrentUpdate);
    } catch (err) {
      console.log(err);
    }
  };

  handleSetLanguage = async (language) => {
    const { setLanguage, userId } = this.props;
    if (userId) {
      await this.updateUserLanguage(language);
    }
    setLanguage(language);
  };

  handleClickHome = () => {
    this.setState({ selectedKeys: [] });
  };

  handleOnSelectMenu = async ({ key }) => {
    this.setState({ selectedKeys: [key] });
    const {
      history,
      setUserAddressType,
      setUserShoppingMode,
      updateOneUserAttribute,
      isFarmerMarketUser,
    } = this.props;
    switch (key) {
      case 'cloudStorePickupAsap':
        history.push(getCustomerPath('map?mode=pickupASAP'));
        break;
      case 'cloudStoreDeliveredAsap':
        history.push(getCustomerPath('map?mode=deliveredASAP'));
        break;
      case 'scheduledPickup':
        history.push(getCustomerPath('map?mode=scheduledPickup'));
        break;
      case 'bunbaoMenu':
        await setUserAddressType('delivery');
        await setUserShoppingMode('delivery');
        await updateOneUserAttribute(null, 'preselectPickupInfo');
        await updateOneUserAttribute(null, 'currentCampaignContentId');
        // history.push(getCustomerPath('jump/delivery-menu'), {
        //   redirectTo: 'delivery-menu',
        // });
        if (isFarmerMarketUser) {
          history.push(getCustomerPath('jump/supermarket'), {
            redirectTo: 'supermarket?special=true',
          });
        } else {
          history.push(getCustomerPath('jump/supermarket'), {
            redirectTo: 'supermarket',
          });
        }
        break;
      case 'bunbaoCatering':
        history.push(getCustomerPath('catering'));
        break;
      case 'bunbaoGame':
        history.push(getCustomerPath('account/activity'));
        break;
      case 'farmersMarket':
        history.push(getCustomerPath('map?mode=scheduledDelivery'));
        break;
      default:
        console.log('do nothing');
    }
  };

  render() {
    const { language, token, group } = this.props;
    const { selectedKeys } = this.state;

    return (
      <Header
        style={{
          position: 'fixed',
          zIndex: 10,
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0 3px 16px hsla(0,0%,86.7%,.2)',
          padding: '0 0',
        }}
      >
        <Link
          to={getCustomerPath()}
          onClick={() => {
            this.handleClickHome();
          }}
        >
          <div
            style={{ marginLeft: '20px' }}
            css={{
              width: '120px',
              height: '64px',
              float: 'left',
              background: 'rgba(255, 255, 255, 0.2)',
              '& img': { height: '50px' },
            }}
            role="presentation"
          >
            <img src={logoFull} alt="BunBao Logo" />
          </div>
        </Link>
        {language === 'en' ? (
          <div
            style={{ width: '60px', float: 'right', marginLeft: '10px' }}
            onClick={() => {
              this.handleSetLanguage('zh');
            }}
            role="presentation"
          >
            <img
              src={iconChinese}
              style={{ height: '24px' }}
              alt="Chinese Icon"
              role="presentation"
            />
          </div>
        ) : (
          <div
            style={{ width: '60px', float: 'right', marginLeft: '10px' }}
            onClick={() => {
              this.handleSetLanguage('en');
            }}
            role="presentation"
          >
            <img
              src={iconEnglish}
              style={{ height: '24px' }}
              alt="English Icon"
              role="presentation"
            />
          </div>
        )}
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={selectedKeys}
          // onSelect={this.handleOnSelectMenu}
          onClick={this.handleOnSelectMenu}
          selectedKeys={selectedKeys}
          style={{ lineHeight: '62px', overflow: 'hidden' }}
        >
          {/* <SubMenu title={<FormattedMessage id="homepage.BunBao" />}> */}
          {/* <Menu.Item key="bunbaoMenu">
            <FormattedMessage id="homepage.BunBao" />
          </Menu.Item> */}
          {/* <Menu.Item key="scheduledPickup">
              <FormattedMessage id="scheduledPickup" />
            </Menu.Item> */}
          {/* </SubMenu> */}
          {/* <SubMenu title={<FormattedMessage id="homepage.farmersMarket" />}>
            <Menu.Item key="farmersMarket">
              <FormattedMessage id="scheduledDelivery" />
            </Menu.Item>
            <Menu.Item key="scheduledPickup">
              <FormattedMessage id="scheduledPickup" />
            </Menu.Item>
          </SubMenu> */}
          <Menu.Item key="scheduledPickup">
            <FormattedMessage id="homepage.farmersMarket" />
          </Menu.Item>
          {/* <SubMenu title={<FormattedMessage id="cloudStore" />}>
            <Menu.Item key="cloudStorePickupAsap">
              <FormattedMessage id="allStores" />
            </Menu.Item>
            <Menu.Item key="cloudStoreDeliveredAsap">
              <FormattedMessage id="deliveredASAP" />
            </Menu.Item>
          </SubMenu> */}
          <Menu.Item key="catering">
            <Link to={getCustomerPath('catering')}>
              <FormattedMessage id="catering" />
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="giftCard">
            <Link to={getCustomerPath('eshop')}>
              <FormattedMessage id="giftCard" /> */}
          {/* <GiftOutlined
                style={{
                  width: '20px',
                  height: '20px',
                  display: 'inline-block',
                  fontSize: '20px',
                }}
                twoToneColor="#eb2f96"
              /> */}
          {/* <img src={hat} alt="hat icon" width="30" height="30" />
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item key="games">
            <Link to={getCustomerPath('games')}>
              <FormattedMessage id="games" />
              
              <img src={tree} alt="tree icon" width="30" height="30" />
            </Link>
          </Menu.Item> */}
          <Menu.Item key="faq">
            <Link to={getCustomerPath('faq')}>
              <FormattedMessage id="faq" />
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="delivery-menu">
            <Link to={getCustomerPath('delivery-menu')}>
              <FormattedMessage id="menu" />
            </Link>
          </Menu.Item>
          <Menu.Item key="map">
            <Link to={getCustomerPath('map')}>
              <FormattedMessage id="cloudStore" />
            </Link>
          </Menu.Item>
          <Menu.Item key="catering">
            <Link to={getCustomerPath('catering')}>
              <FormattedMessage id="catering" />
            </Link>
          </Menu.Item>
          <Menu.Item key="donation">
            <Link to={getCustomerPath('donation')}>
              <FormattedMessage id="donationWord" />
              <HeartIcon
                css={{ width: '16px', height: '16px', display: 'inline-block' }}
              />
            </Link>
          </Menu.Item>
          <Menu.Item key="game">
            <Link to={getCustomerPath('account/activity')}>
              <FormattedMessage id="game" />
            </Link>
          </Menu.Item> */}
          {token && !(group && group.name === 'guest') ? (
            <Menu.Item key="account">
              <Link to={getCustomerPath('account')}>
                <FormattedMessage id="account" />
              </Link>
            </Menu.Item>
          ) : null}
          {!token || (group && group.name === 'guest') ? (
            <Menu.Item key="login">
              <Link to={getCustomerPath('login')}>
                <FormattedMessage id="loginSignUp" />
              </Link>
            </Menu.Item>
          ) : null}
        </Menu>
      </Header>
    );
  }
}

CustomerNav.defaultProps = {
  token: null,
  group: null,
  userId: null,
  isFarmerMarketUser: false,
};

CustomerNav.propTypes = {
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  token: PropTypes.string,
  updateOneUserAttribute: PropTypes.func.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  userId: PropTypes.string,
  isFarmerMarketUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  warehouseId: state.warehouse.warehouseId,
  token: state.auth.token,
  group: state.user.group,
  userId: state.user.userId,
  isFarmerMarketUser: state.user.isFarmerMarketUser,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(actions.setLanguage(language)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerNav)
);
