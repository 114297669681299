/* global google */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RetweetOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { Col, Modal, Row, message, Spin, Button, Tooltip } from 'antd';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Link } from 'react-router-dom';
import * as QueryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import { getDistance, convertDistance } from 'geolib';
import * as actions from '../../../store/actions';
import { GOOGLE_MAP_KEY } from '../../../config';
import { apolloClient, getCustomerPath } from '../../../util';
import WarehouseCard from './WarehouseCard/WarehouseCard';
import {
  WAREHOUSES_QUERY,
  WAREHOUSE_SEARCH_BY_LOCATION,
  FIX_LOCATION_PICKUP_QUERY,
} from '../../../api/warehouse';
import mapIcon from '../../../assets/images/icons/map.svg';
import listIcon from '../../../assets/images/icons/list.svg';
import WarehousePoint from './WarehousePoint/WarehousePoint';
// import Distributor from '../../../assets/images/distributor.png';
import Central from '../../../assets/images/central.png';
import FarmersMarket from '../../../assets/images/farmersMarket.png';
import ridingScooter from '../../../assets/images/homepage/ridingScooter.svg';
import openMaps from '../../../assets/images/homepage/openMaps.svg';
import navigation from '../../../assets/images/homepage/navigation.svg';
import rocketBoy from '../../../assets/images/homepage/rocket_boy.svg';
import mapStyles from './mapStyles';
import AddressSearchInput from '../../../components/Inputs/AddressSearchInput/AddressSearchInput';
import MapDeliveryModal from '../../../components/Modals/MapDeliveryModal/MapDeliveryModal';
import UserPoint from './UserPoint/UserPoint';

const { innerHeight: height } = window;

const initialState = {
  staticWarehouses: [],
  warehouses: [],
  warehouseSelectors: [],
  mapZoom: 9,
  mapCenter: { lat: 37.543396, lng: -121.971798 },
  userPoint: { lat: null, lng: null },
  selected: false,
  address: '',
  latitude: null,
  longitude: null,
  radius: 100.0,
  selectedWarehouse: null,
  deliveryModalVisible: false,
  popoverVisible: false,
  dropdownVisible: false,
  filterDistributor: true,
  filterFarmsMarket: true,
  filterOpen: false,
  filterDelivery: true,
  filterDineIn: true,
  filterPickup: true,
  filterComingSoon: false,
  searchBarVisibility: false,
  serviceChooseModalVisible: false,
  mapHeight: height * 0.6,
  serviceMode: null,
  loading: false,
  showMap: false,
};

export class StoresMap extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      staticWarehouses: [],
      warehouses: [],
      warehouseSelectors: [],
      mapZoom: 9,
      mapCenter: { lat: 37.543396, lng: -121.971798 },
      userPoint: { lat: null, lng: null },
      selected: false,
      address: '',
      latitude: null,
      longitude: null,
      radius: 100.0,
      selectedWarehouse: null,
      deliveryModalVisible: false,
      filterDistributor: true,
      filterFarmsMarket: true,
      // filterOpen: false,
      // filterDelivery: true,
      // filterDineIn: true,
      filterPickup: true,
      // filterComingSoon: false,
      // searchBarVisibility: false,
      serviceChooseModalVisible: false,
      mapHeight: height * 0.6,
      serviceMode: null,
      loading: false,
      showMap: false,
      warehouseListSelector: 'all',
      mapMode: null,
      showMapWhenMobileVersion: false,
      showExploreButton: false,
      // campaignAddresses: [],
      // /=====array of id support pickup
      fixLocationWarehouseIdArray: [],
    };
  }

  async componentDidMount() {
    await this.handleInitialCheck();
  }

  componentDidUpdate = async (prevProps) => {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      await this.handleInitialCheck();
    }
  };

  handleInitialCheck = async () => {
    this.setState({ loading: true });
    // fetch fix location campaigns

    await this.fetchCampaignContent();

    // get url query params
    const {
      location: { search },
    } = this.props;
    const { serviceMode } = this.state;

    let { mode } = QueryString.parse(search);

    if (serviceMode !== null) {
      mode = serviceMode;
    }

    // check mode for show different map
    let mapMode = 'none';
    switch (mode) {
      case 'scheduledDelivery':
        mapMode = 'scheduledDelivery';
        break;
      case 'deliveredASAP':
        mapMode = 'deliveredASAP';
        break;
      case 'scheduledPickup':
        mapMode = 'scheduledPickup';
        break;
      case 'pickupASAP':
        mapMode = 'pickupASAP';
        break;
      default:
        mapMode = 'none';
    }

    this.setState({ mapMode });

    if (mapMode === 'none') {
      this.setState({ serviceChooseModalVisible: false });
    }

    // setup user address type for checkout
    const { setUserAddressType, setUserShoppingMode } = this.props;
    await setUserShoppingMode('pickup');
    if (mode === 'scheduledPickup' || mode === 'pickupASAP') {
      await setUserAddressType('pickup');
    } else if (mode === 'deliveredASAP' || mode === 'scheduledDelivery') {
      await setUserAddressType('delivery');
    }

    // check get current location permission
    if ('geolocation' in navigator) {
      try {
        const status = await navigator.permissions.query({
          name: 'geolocation',
        });
        console.log(status);
        if (status.state === 'prompt') {
          // show button
          await this.setState({ showExploreButton: true });
          this.setState({ loading: false });
        }
        if (status.state === 'granted') {
          // fetch location
          await this.setState({ showExploreButton: false });
          await this.handleGetCurrentLocation();
        }
        if (status.state === 'denied') {
          // just show warehouse list
          await this.setState({ showExploreButton: false });
          await this.handleFetchWarehouses();
        }
      } catch (error) {
        await this.setState({ showExploreButton: false });
        await this.handleGetCurrentLocation();
      }
      // navigator.geolocation.watchPosition((position) => {
      //   console.log(position);
      // });
    } else {
      this.setState({ loading: false });
    }

    // first get the window height
    // console.log(width, height);
    // if (height < 800) {
    //   this.setState({ mapZoom: 9 });
    // }

    // this.setState({ loading: false });
    // window.scrollTo(0, 0)
  };

  fetchCampaignContent = async () => {
    // get official pick up location
    try {
      const filter = { campaignType: 'fixLocation', deleted: false };
      const {
        data: { campaignContentFindMany },
      } = await apolloClient.query({
        query: FIX_LOCATION_PICKUP_QUERY,
        variables: { filter },
        fetchPolicy: 'network-only',
      });
      if (campaignContentFindMany && campaignContentFindMany.length > 0) {
        const fixLocationWarehouseIdArray = {};
        campaignContentFindMany.forEach((campaign) => {
          if (campaign.warehouse) {
            fixLocationWarehouseIdArray[campaign.warehouse._id] = campaign;
          }
        });
        console.log('fixLocationWarehouseIdArray', fixLocationWarehouseIdArray);
        await this.setState({ fixLocationWarehouseIdArray });
      }
    } catch (error) {
      console.error(error);
      message.warn('Error happened while fetching campaign content');
    }
  };

  componentWillUnmount = async () => {};

  handleChangeMapAndList = () => {
    this.setState((prevState) => ({
      showMap: !prevState.showMap,
    }));
  };

  handleChangeWarehouseSelector = (value) => {
    this.setState({ warehouseListSelector: value });
  };

  handleReselectWarehouse = async () => {
    const { currentWarehouseId } = this.props;
    const { staticWarehouses } = this.state;
    if (currentWarehouseId) {
      const foundWarehouse = _.find(staticWarehouses, (warehouse) => {
        return warehouse._id === currentWarehouseId;
      });
      await this.handleClickWarehouse(foundWarehouse);
    }
  };

  handleFetchWarehouses = async () => {
    const response = await apolloClient.query({
      query: WAREHOUSES_QUERY,
      fetchPolicy: 'network-only',
    });
    // console.log(response);
    const { data } = response;
    // this.setState({
    //   warehouses: data.warehouseMany,
    // });
    this.sortWarehouseByDistance(data.warehouseMany);
  };

  handleSearchWarehouseByLocation = async () => {
    await this.setState({ loading: true });
    const { latitude, longitude, radius } = this.state;
    if (latitude && longitude && radius) {
      const response = await apolloClient.query({
        query: WAREHOUSE_SEARCH_BY_LOCATION,
        variables: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          radius: parseFloat(radius),
        },
        fetchPolicy: 'network-only',
      });
      const { data } = response;
      // console.table(data.warehouseByLocation);
      // this.setState({
      //   warehouses: data.warehouseByLocation,
      // });
      this.sortWarehouseByDistance(data.warehouseByLocation);
    }
    this.setState({ loading: false });
  };

  handleAddressSelect = (address) => {
    console.log('search address', address);
    geocodeByAddress(address)
      .then(async (results) => {
        const place = results[0];
        console.log(place);
        let addressString = '';
        for (let i = 0; i < place.address_components.length; i += 1) {
          const addressType = place.address_components[i].types[0];
          if (addressType === 'street_number') {
            addressString += `${place.address_components[i].short_name} `;
          }
          if (addressType === 'route') {
            addressString += place.address_components[i].long_name;
          }
        }
        const { selected } = this.state;
        this.setState({ address: addressString, selected: !selected });
        return getLatLng(results[0]);
      })
      .then(async (latLng) => {
        // Do something with latLng
        console.log(latLng);
        await this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng,
          mapCenter: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
          userPoint: { lat: latLng.lat, lng: latLng.lng },
        });
        const { radius } = this.state;
        if (radius <= 10) {
          await this.setState({ mapZoom: 14 });
        }
        if (radius > 10 && radius <= 20) {
          await this.setState({ mapZoom: 13 });
        }
        if (radius >= 30 && radius <= 40) {
          await this.setState({ mapZoom: 12 });
        }
        if (radius >= 100.0) {
          await this.setState({ mapZoom: 14 });
        }
        await this.handleSearchWarehouseByLocation();
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  handleGetCurrentLocation = async () => {
    const { language } = this.props;
    await this.setState({
      address: 'Loading...',
      loading: true,
    });

    if ('geolocation' in navigator) {
      // check if geolocation is supported/enabled on current browser
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new google.maps.Geocoder();
          const location = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          geocoder.geocode({ latLng: location }, async (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                await this.setState({
                  address: results[1].formatted_address,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  mapCenter: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                  userPoint: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                });
                const { radius } = this.state;
                if (radius <= 10) {
                  await this.setState({ mapZoom: 14 });
                }
                if (radius > 10 && radius <= 20) {
                  await this.setState({ mapZoom: 13 });
                }
                if (radius >= 30) {
                  await this.setState({ mapZoom: 12 });
                }
                this.setState({ showExploreButton: false });
                await this.handleSearchWarehouseByLocation();
              }
            }
          });
        },
        (error) => {
          console.error(error);
          message.warning(
            language === 'zh'
              ? '无法获取您的位置，请检查浏览器permission.您会看到一个默认列表'
              : 'Cannot get location, check your network or location permission. You will see a default list.'
          );
          this.setState({ loading: false });
          this.handleFetchWarehouses();
        },
        { maximumAge: 600000, timeout: 5000, enableHighAccuracy: false }
      );
    } else {
      await this.setState({
        address: 'Loading...',
        loading: false,
      });
    }
  };

  sortWarehouseByDistance = (warehouses) => {
    const { latitude, longitude } = this.state;
    warehouses.map((warehouse) => {
      const newWarehouse = warehouse;
      return newWarehouse;
    });
    warehouses.map((element) => {
      const copyElement = element;
      switch (copyElement.warehouseType) {
        case 'central':
          copyElement.ranking = 1;
          break;
        case 'distributor':
          copyElement.ranking = 2;
          break;
        case 'farmersMarket':
          copyElement.ranking = 3;
          break;
        default:
          copyElement.ranking = 99;
          break;
      }
      return element;
    });
    // console.log(warehouses);
    _.remove(warehouses, (warehouse) => warehouse.deleted === true);
    if (latitude === null || longitude === null) {
      const selectors = new Array(warehouses.length).fill(false);
      this.setState({
        warehouses: _.orderBy(warehouses, ['ranking'], ['asc']),
        warehouseSelectors: selectors,
        staticWarehouses: warehouses,
        loading: false,
      });
      console.log('no location show warehouses');
      // console.log('newWarehouses', _.orderBy(warehouses, ['ranking'], ['asc']));
    } else {
      warehouses.map((warehouse) => {
        const newWarehouse = warehouse;
        newWarehouse.distance = convertDistance(
          getDistance(
            { latitude, longitude },
            {
              latitude: warehouse.location.coordinates[1],
              longitude: warehouse.location.coordinates[0],
            }
          ),
          'mi'
        );
        return newWarehouse;
      });
      const newWarehouses = _.sortBy(
        warehouses,
        (warehouse) => warehouse.distance
      );
      const selectors = new Array(newWarehouses.length).fill(false);
      this.setState({
        warehouses: _.orderBy(newWarehouses, ['ranking'], ['asc']),
        warehouseSelectors: selectors,
        staticWarehouses: newWarehouses,
      });
      // console.log(selectors);
    }
    this.handleChangeFilter();
  };

  handleClickClear = async () => {
    await this.setState(initialState);
    await this.handleFetchWarehouses();
  };

  handleClickWarehouse = (warehouse) => {
    // change default center and zoom
    if (!isMobile) {
      const element = document.getElementById(warehouse._id);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }

    const lat = warehouse.location.coordinates[1];
    const lng = warehouse.location.coordinates[0];
    // set up different color for background
    const { warehouses } = this.state;
    const selectors = new Array(warehouses.length).fill(false);
    warehouses.forEach((item, index) => {
      if (item._id === warehouse._id) {
        item.selected = true;
        selectors[index] = true;
      } else {
        item.selected = false;
      }
    });
    this.setState({
      mapZoom: 15,
      mapCenter: { lat, lng },
      warehouses,
      selectedWarehouse: warehouse,
      warehouseSelectors: selectors,
    });
  };

  handleOpenLink = (link) => {
    window.open(link, '_blank'); // to open new page
  };

  handleOpenDeliveryModal = () => {
    this.setState({ deliveryModalVisible: true });
  };

  handleCloseDeliveryModal = () => {
    this.setState({ deliveryModalVisible: false });
  };

  handleChangeRadius = async (radius) => {
    console.log(radius);
    await this.setState({ radius: parseFloat(radius) });
    const { latitude, longitude, address } = this.state;
    if (address === '') {
      await this.handleGetCurrentLocation();
    }
    if (latitude !== null && longitude !== null) {
      await this.handleSearchWarehouseByLocation();
    }
    if (radius <= 10) {
      await this.setState({ mapZoom: 15 });
    }
    if (radius > 10 && radius <= 20) {
      await this.setState({ mapZoom: 12 });
    }
    if (radius >= 30) {
      await this.setState({ mapZoom: 10 });
    }
  };

  // onOpenServiceModal = (mode) => {
  //   // notification.close('service-notification');
  //   this.setState({ serviceChooseModalVisible: true });
  // };

  handleClickServiceModeAndCloseServiceModal = async (mode) => {
    await this.setState({
      serviceChooseModalVisible: false,
      serviceMode: mode,
    });
    this.handleInitialCheck();
  };

  handleChangeShowMapWhenMobileVersion = async () => {
    await this.setState((prevState) => ({
      showMapWhenMobileVersion: !prevState.showMapWhenMobileVersion,
    }));

    const { showMapWhenMobileVersion, selectedWarehouse } = this.state;
    if (
      showMapWhenMobileVersion === false &&
      selectedWarehouse &&
      selectedWarehouse._id
    ) {
      const element = document.getElementById(selectedWarehouse._id);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  handleChangeFilter = () => {
    const {
      staticWarehouses,
      // filterDelivery,
      // filterDineIn,
      filterPickup,
      // filterComingSoon,
      filterDistributor,
      // filterOpen,
      mapMode,
      fixLocationWarehouseIdArray,
    } = this.state;
    const filteredWarehouses = [];

    // if (mapMode === 'deliveredASAP') {
    //   this.setState({ filterFarmsMarket: false });
    // } else {
    //   this.setState({ filterFarmsMarket: true });
    // }

    const { filterFarmsMarket } = this.state;
    console.log('filterPickup', filterPickup);
    staticWarehouses.forEach((e) => {
      if (mapMode === 'scheduledPickup') {
        if (fixLocationWarehouseIdArray[e._id]) {
          const campaignContent = fixLocationWarehouseIdArray[e._id];
          e.pickupWindow = campaignContent.pickupWindow;
          e.pickupTimeSlotFormatted = campaignContent.pickupTimeSlotFormatted;
          e.prepareDay = campaignContent.prepareDay;
          e.endDate = campaignContent.endDate;
          e.campaignContentId = campaignContent._id;
          e.defaultAddressId = campaignContent.defaultAddressId;
          filteredWarehouses.push(e);
        }
        // console.log('static',e);
      } else if (mapMode === 'pickupASAP' && e.pickup === true) {
        if (e.warehouseType === 'distributor' && filterDistributor === true) {
          filteredWarehouses.push(e);
        } else if (
          e.warehouseType === 'farmersMarket' &&
          filterFarmsMarket === true
        ) {
          filteredWarehouses.push(e);
        } else if (filterDistributor === false && filterFarmsMarket === false) {
          filteredWarehouses.push(e);
        } else if (e.warehouseType === 'central') {
          filteredWarehouses.push(e);
        }
      } else if (mapMode === 'deliveredASAP' && e.delivery === true) {
        if (e.warehouseType === 'distributor' && filterDistributor === true) {
          filteredWarehouses.push(e);
        } else if (
          e.warehouseType === 'farmersMarket' &&
          filterFarmsMarket === true
        ) {
          filteredWarehouses.push(e);
        } else if (filterDistributor === false && filterFarmsMarket === false) {
          filteredWarehouses.push(e);
        } else if (e.warehouseType === 'central') {
          filteredWarehouses.push(e);
        }
      } else if (
        mapMode === 'scheduledDelivery' &&
        (e.scheduledDelivery === true || e.warehouseType === 'website')
      ) {
        filteredWarehouses.push(e);
      }
    });

    // console.log('filteredWarehouses',filteredWarehouses);
    this.setState({ warehouses: filteredWarehouses });
  };

  handleClickCollapseButton = () => {
    this.setState((prevState) => {
      if (prevState.mapHeight > height * 0.2) {
        return { mapHeight: height * 0.2 };
      }
      return { mapHeight: height * 0.6 };
    });
  };

  render() {
    const {
      warehouses,
      warehouseSelectors,
      mapCenter,
      mapZoom,
      address,
      selected,
      // radius,
      selectedWarehouse,
      deliveryModalVisible,
      userPoint,
      // searchBarVisibility,
      serviceChooseModalVisible,
      // mapHeight,
      // serviceMode,
      loading,
      warehouseListSelector,
      showMapWhenMobileVersion,
      showExploreButton,
      mapMode,
      fixLocationWarehouseIdArray,
    } = this.state;
    // var {warehouses}= this.state;
    // console.log('loading',loading,'warehouses',warehouses,);
    const { intl, language } = this.props;
    // warehouses=_.orderBy(warehouses, ['ranking'], ['asc'])
    // console.log("check check",warehouses);

    const renderSelectWarehouseType = () => {
      return (
        <div
          css={{ position: 'sticky', width: 'inherit', top: '0', zIndex: 9 }}
        >
          <div
            css={
              isMobile
                ? {
                    margin: '2px',
                    display: 'flex',
                    borderRadius: '20px',
                    backgroundColor: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }
                : {
                    margin: '5px',
                    display: 'flex',
                    borderRadius: '20px',
                    backgroundColor: 'white',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                  }
            }
          >
            <div
              onClick={async () => {
                await this.setState({
                  filterDistributor: false,
                  filterFarmsMarket: false,
                });
                this.handleChangeFilter();
                this.handleChangeWarehouseSelector('all');
              }}
              role="presentation"
              css={
                warehouseListSelector === 'all'
                  ? {
                      width: '33%',
                      padding: '10px',
                      textAlign: 'center',
                      backgroundColor: '#ff4d4d',
                      borderRadius: '20px',
                      color: 'white',
                      cursor: 'pointer',
                      border: '1px solid transparent',
                    }
                  : {
                      width: '33%',
                      padding: '10px',
                      textAlign: 'center',
                      borderRadius: '20px',
                      border: '1px solid transparent',
                      cursor: 'pointer',
                      alignItems: 'center',
                      ':hover': { border: '1px solid #ccc' },
                    }
              }
            >
              <FormattedMessage id="map.all" />
            </div>
            <div
              onClick={async () => {
                await this.setState({
                  filterDistributor: true,
                  filterFarmsMarket: false,
                });
                console.log('filterDistributor is true');
                this.handleChangeFilter();
                this.handleChangeWarehouseSelector('distributor');
              }}
              role="presentation"
              css={
                warehouseListSelector === 'distributor'
                  ? {
                      width: '33%',
                      padding: '10px',
                      textAlign: 'center',
                      backgroundColor: '#ff4d4d',
                      borderRadius: '20px',
                      color: 'white',
                      cursor: 'pointer',
                      border: '1px solid transparent',
                    }
                  : {
                      width: '33%',
                      padding: '10px',
                      textAlign: 'center',
                      borderRadius: '20px',
                      border: '1px solid transparent',
                      cursor: 'pointer',
                      alignItems: 'center',
                      ':hover': { border: '1px solid #ccc' },
                    }
              }
            >
              <FormattedMessage id="map.distributor" />
            </div>
            <div
              onClick={async () => {
                await this.setState({
                  filterDistributor: false,
                  filterFarmsMarket: true,
                });
                this.handleChangeFilter();
                this.handleChangeWarehouseSelector('farmersMarket');
              }}
              role="presentation"
              css={
                warehouseListSelector === 'farmersMarket'
                  ? {
                      width: '33%',
                      padding: '10px',
                      textAlign: 'center',
                      backgroundColor: '#ff4d4d',
                      borderRadius: '20px',
                      color: 'white',
                      cursor: 'pointer',
                      border: '1px solid transparent',
                    }
                  : {
                      width: '33%',
                      padding: '10px',
                      textAlign: 'center',
                      borderRadius: '20px',
                      border: '1px solid transparent',
                      cursor: 'pointer',
                      alignItems: 'center',
                      ':hover': { border: '1px solid #ccc' },
                    }
              }
            >
              <FormattedMessage id="map.farmersMarket" />
            </div>
          </div>
        </div>
      );
    };

    const renderWarehouseList = () => {
      return (
        <div>
          {renderSelectWarehouseType()}
          {showExploreButton && (
            <div css={{ textAlign: 'center', paddingTop: '30vh' }}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  this.handleGetCurrentLocation();
                }}
              >
                <EnvironmentOutlined />{' '}
                <FormattedMessage id="map.tapToExplore" />
              </Button>
            </div>
          )}
          {loading === true && warehouses.length === 0 && (
            <div css={{ textAlign: 'center', width: '100%' }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
              />
            </div>
          )}
          {loading === false &&
            warehouses.length > 0 &&
            warehouses.map((warehouse, index) => {
              return (
                <WarehouseCard
                  key={warehouse._id}
                  fixLocationWarehouseIdArray={fixLocationWarehouseIdArray}
                  mapMode={mapMode}
                  warehouse={warehouse}
                  index={index}
                  warehouseSelectors={warehouseSelectors}
                  handleClickWarehouse={this.handleClickWarehouse}
                  handleOpenDeliveryModal={this.handleOpenDeliveryModal}
                />
              );
            })}
        </div>
      );
    };

    const renderPopupWindow = () => {
      return (
        <React.Fragment>
          {selectedWarehouse ? (
            <div
              style={{
                left: '0',
                right: '0',
                marginRight: 'auto',
                marginLeft: 'auto',
                padding: 'auto 10px',
                position: 'absolute',
                bottom: '10px',
                width: '100%',
                // backgroundColor: 'white',
                // height: 'calc( 50vh - 100px )',
                minHeight: '130px',
                borderRadius: '10px',
                // overflow: 'scroll',
                // border: 'solid 1px #aeb5b0',
              }}
            >
              <WarehouseCard
                mapWindowPop
                mapMode={mapMode}
                warehouse={selectedWarehouse}
                index={null}
                warehouseSelectors={null}
                handleClickWarehouse={this.handleClickWarehouse}
                handleOpenDeliveryModal={this.handleOpenDeliveryModal}
              />
            </div>
          ) : null}
        </React.Fragment>
      );
    };
    const renderMap = () => {
      return (
        <div css={{ width: '100%', height: '100%', position: 'relative' }}>
          <GoogleMapReact
            options={{ styles: mapStyles }}
            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
            defaultCenter={{ lat: 37.543396, lng: -121.971798 }}
            defaultZoom={10}
            zoom={mapZoom}
            center={mapCenter}
          >
            {warehouses.map((warehouse) => (
              <WarehousePoint
                key={warehouse._id}
                lat={warehouse.location.coordinates[1]}
                lng={warehouse.location.coordinates[0]}
                warehouse={warehouse}
                warehouseType={warehouse.warehouseType}
                handleClickWarehouse={this.handleClickWarehouse}
              />
            ))}
            {userPoint.lat !== null && userPoint.lng !== null ? (
              <UserPoint lat={userPoint.lat} lng={userPoint.lng} />
            ) : null}
          </GoogleMapReact>
          {isMobile && renderPopupWindow()}
          <div
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              padding: '10px 2px',
              borderBottomRightRadius: '5px',
              backgroundColor: 'white',
            }}
          >
            <div
              css={{
                '& .pin-style': {
                  fontWeight: '600',
                  textShadow: '0px 0px 6px #FFF',
                  marginLeft: '10px',
                  marginRight: '10px',
                  fontSize: '13px',
                },
              }}
            >
              {/* <img
                src={Distributor}
                style={{
                  width: '30px',
                  height: '30px',
                }}
                alt="distributor"
              /> */}
              {/* <span className="pin-style">
                <FormattedMessage id="distributor" />
              </span> */}
              <img
                src={Central}
                style={{
                  width: '30px',
                  height: '30px',
                }}
                alt="central"
              />
              <span className="pin-style">
                <FormattedMessage id="central" />
              </span>
              <img
                src={FarmersMarket}
                style={{
                  width: '30px',
                  height: '30px',
                }}
                alt="farmersMarket"
              />
              <span className="pin-style">Farmers Market</span>
            </div>
          </div>
        </div>
      );
    };

    const renderSearchBar = () => (
      <div css={{ textAlign: 'center', margin: '10px 0' }}>
        <div
          style={
            isMobile
              ? { width: '50%', marginBottom: '5px', display: 'inline-block' }
              : {
                  minWidth: '200px',
                  maxWidth: '400px',
                  width: '50%',
                  marginBottom: '5px',
                  display: 'inline-block',
                }
          }
        >
          {!isMobile && (
            <SearchOutlined
              css={{
                display: 'inline-block',
                fontSize: '20px',
                marginRight: '10px',
              }}
            />
          )}
          <div css={{ display: 'inline-block', width: 'calc( 100% - 40px )' }}>
            <AddressSearchInput
              placeholder={intl.formatMessage({ id: 'searchAddress' })}
              autoComplete="off"
              selected={selected}
              address={address}
              clearAddress={this.clearAddress}
              onChange={this.handleAddressChange}
              onAddressSelect={this.handleAddressSelect}
            />
          </div>
        </div>
        <div css={{ display: 'inline-block' }}>
          <Tooltip
            title={
              <div>
                <InfoCircleOutlined /> <FormattedMessage id="map.clickHere" />
              </div>
            }
            defaultVisible
            placement="bottom"
          >
            <Button
              type="primary"
              onClick={() => {
                this.setState({ serviceChooseModalVisible: false });
              }}
            >
              <RetweetOutlined />{' '}
              {mapMode && <FormattedMessage id={`map.${mapMode}`} />}
            </Button>
          </Tooltip>
        </div>
      </div>
    );

    return (
      <div
        style={{
          padding: isMobile ? '0 10px' : '10px',
          backgroundColor: 'white',
          overflow: 'hidden',
          height: isMobile
            ? `calc(${height}px - 100px )`
            : 'calc( 100vh - 65px)',
        }}
      >
        <Modal
          visible={deliveryModalVisible}
          footer={null}
          onCancel={this.handleCloseDeliveryModal}
        >
          <MapDeliveryModal selectedWarehouse={selectedWarehouse} />
        </Modal>
        <Modal
          zIndex="1090"
          footer={null}
          title={null}
          width={768}
          closable={mapMode !== 'none'}
          maskClosable={mapMode !== 'none'}
          visible={serviceChooseModalVisible}
          onCancel={() => {
            this.setState({ serviceChooseModalVisible: false });
          }}
        >
          <Row>
            <Col span={24}>
              <div
                css={{
                  color: 'black',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  margin: '10px auto',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <FormattedMessage id="map.chooseDeliveryPickup" />
              </div>
            </Col>
            <Col span={24}>
              <Row
                type="flex"
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  userSelect: 'none',
                  '& .service-block-mobile': {
                    margin: '10px',
                    padding: isMobile
                      ? '5px 5px 5px 5px'
                      : '10px 10px 10px 10px',
                    boxShadow:
                      '10px 10px 25px #8a8a8a, -10px -10px 25px #ffffff',
                    borderRadius: '4px',
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    display: 'flex',
                    ':hover': {
                      background: 'linear-gradient(190deg, #e6e6e6, #ffffff)',
                      boxShadow:
                        'inset 10px 10px 25px #8a8a8a, inset -10px -10px 25px #ffffff',
                    },
                    '& img': isMobile
                      ? {
                          height: '50px',
                          width: '50px',
                        }
                      : {
                          height: '70px',
                          width: '70px',
                        },
                    '& .service-title-mobile': {
                      height: '100%',
                      margin: '0 auto',
                      textAlign: 'center',
                      padding: language === 'zh' ? '17px 0px' : '10px 0px',
                    },
                  },
                }}
              >
                <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
                  <Link
                    to={getCustomerPath('map?mode=scheduledDelivery')}
                    onClick={() => {
                      this.handleClickServiceModeAndCloseServiceModal(
                        'scheduledDelivery'
                      );
                    }}
                  >
                    <div className="service-block-mobile">
                      <img src={ridingScooter} alt="A Man Riding Scooter" />
                      <div className="service-title-mobile">
                        <FormattedMessage id="scheduledDelivery" />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
                  <Link
                    to={getCustomerPath('map?mode=scheduledPickup')}
                    onClick={() => {
                      this.handleClickServiceModeAndCloseServiceModal(
                        'scheduledPickup'
                      );
                    }}
                  >
                    <div className="service-block-mobile">
                      <img src={openMaps} alt="A Man Opens Map" />
                      <div className="service-title-mobile">
                        <FormattedMessage id="scheduledPickup" />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
                  <Link
                    to={getCustomerPath('map?mode=pickupASAP')}
                    onClick={() => {
                      this.handleClickServiceModeAndCloseServiceModal(
                        'pickupASAP'
                      );
                    }}
                  >
                    <div className="service-block-mobile">
                      <img src={navigation} alt="A Man Opens Map" />
                      <div className="service-title-mobile">
                        <FormattedMessage id="map.pickupASAP" />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
                  <Link
                    to={getCustomerPath('map?mode=deliveredASAP')}
                    onClick={() => {
                      this.handleClickServiceModeAndCloseServiceModal(
                        'deliveredASAP'
                      );
                    }}
                  >
                    <div className="service-block-mobile">
                      <img src={rocketBoy} alt="A Man With Rocket" />
                      <div className="service-title-mobile">
                        <FormattedMessage id="deliveredASAP" />
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
        {isMobile ? (
          <div css={{ height: 'calc(100%)', overflow: 'hidden' }}>
            <div
              css={{
                position: 'absolute',
                bottom: '30px',
                zIndex: '100',
                right: '-100vw',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                padding: '8px 2px 8px 8px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
              onClick={() => {
                this.handleChangeShowMapWhenMobileVersion();
              }}
              role="presentation"
            >
              {showMapWhenMobileVersion ? (
                <img src={listIcon} css={{ height: '28px' }} alt="list" />
              ) : (
                <img src={mapIcon} css={{ height: '28px' }} alt="map" />
              )}
            </div>
            {renderSearchBar()}
            {showMapWhenMobileVersion ? (
              <div css={{ height: 'calc( 100% - 60px )' }}>{renderMap()}</div>
            ) : (
              <div
                css={{
                  position: 'relative',
                  height: 'calc( 100% - 40px )',
                  overflow: 'scroll',
                  backgroundColor: '#f4f4f4',
                }}
              >
                {renderWarehouseList()}
              </div>
            )}
          </div>
        ) : (
          <React.Fragment>
            {renderSearchBar()}
            <Row
              style={{
                height: 'calc( 100vh - 130px )',
                width: '100%',
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={10}
                xl={10}
                xxl={8}
                css={{
                  height: '100%',
                  overflow: 'scroll',
                  backgroundColor: '#f4f4f4',
                }}
              >
                {renderWarehouseList()}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={14}
                xl={14}
                xxl={16}
                style={{ height: '100%' }}
              >
                {renderMap()}
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }
}

StoresMap.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  currentWarehouseId: PropTypes.string,
  location: ReactRouterPropTypes.location.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

StoresMap.defaultProps = {
  currentWarehouseId: null,
};

const mapStateToProps = (state) => ({
  currentWarehouseId: state.warehouse.warehouseId,
  language: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(StoresMap));
