import gql from 'graphql-tag';

export const VOTE_CAMPAIGN_FRAGMENT = gql`
  fragment VOTE_CAMPAIGN_FRAGMENT on VoteCampaign {
    _id
    title {
      en
      zh
    }
    description {
      en
      zh
    }
    campaignRule {
      en
      zh
    }
    voteTimesPreDay
    shareTitle {
      en
      zh
    }
    shareSubtitle {
      en
      zh
    }
    uploadWindowNotice {
      en
      zh
    }
    voteAskNotice {
      en
      zh
    }
    voteAskNoticeWithoutLogin {
      en
      zh
    }
    votedNotice {
      en
      zh
    }
    votedNoticeWithPosted {
      en
      zh
    }
    votedNoticeWithoutBonus {
      en
      zh
    }
    canNotVoteNotice {
      en
      zh
    }
    canNotVoteNoticeWithPosted {
      en
      zh
    }
    canNotVoteNoticeByDateLimit {
      en
      zh
    }
    canNotCreateNoticeByDateLimit {
      en
      zh
    }
    sendDiscountTimesPreDay
    isEnableNewUserCanPost
    collectStartDate
    collectEndDate
    campaignStartDate
    campaignEndDate
    votePostIds
    shortIntro {
      zh
      en
    }
    pictureEnId
    pictureZhId
    iconPictureEnId
    iconPictureZhId
    bannerPicZh {
      key
      _id
    }
    bannerPicEn {
      key
      _id
    }
    iconEn {
      key
      _id
    }
    iconZh {
      key
      _id
    }
    imageLimit
    isEnablePassVoteFirst
    isDiscountEnable
    discountType
    discountFixedAmount
    discountPercentage
    discountRandomMaximumAmount
    discountRandomMinimumAmount
  }
`;

export const VOTE_POST_FRAGMENT = gql`
  fragment VOTE_POST_FRAGMENT on VotePost {
    _id
    voteCampaignId
    pictures {
      _id
      key
    }
    pictureTags {
      _id
      picture {
        _id
        pictureId
        key
      }
      tags {
        _id
        label
        value
        key
        direction
        x
        y
      }
    }
    description
    status
    user {
      _id
      email
    }
    nickName
    title
    voteUsers {
      _id
      email
    }
    displayId
    userId
    voteUserIds
    totalVotes
    reason
    voteCampaign {
      ...VOTE_CAMPAIGN_FRAGMENT
    }
  }
  ${VOTE_CAMPAIGN_FRAGMENT}
`;

export const VOTE_LOG_FRAGMENT = gql`
  fragment VOTE_LOG_FRAGMENT on VoteLog {
    _id
    voteCounter
    discount {
      valueType
      amount
    }
    votePost {
      ...VOTE_POST_FRAGMENT
    }
    user {
      email
    }
  }
  ${VOTE_POST_FRAGMENT}
`;

export const VOTE_CAMPAIGN_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: FilterFindManyVoteCampaignInput
    $sort: SortFindManyVoteCampaignInput
  ) {
    voteCampaignPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        ...VOTE_CAMPAIGN_FRAGMENT
      }
    }
  }
  ${VOTE_CAMPAIGN_FRAGMENT}
`;

export const VOTE_CAMPAIGN_MANY = gql`
  query {
    voteCampaignMany {
      ...VOTE_CAMPAIGN_FRAGMENT
    }
  }
  ${VOTE_CAMPAIGN_FRAGMENT}
`;
export const VOTE_CAMPAIGN_BY_ID = gql`
  query ($id: MongoID!) {
    voteCampaignById(_id: $id) {
      ...VOTE_CAMPAIGN_FRAGMENT
    }
  }
  ${VOTE_CAMPAIGN_FRAGMENT}
`;

export const VOTE_CAMPAIGN_CREATE_ONE = gql`
  mutation ($input: RelayCreateOneVoteCampaignInput!) {
    voteCampaignCreateOne(input: $input) {
      recordId
    }
  }
`;

export const VOTE_CAMPAIGN_UPDATE_ONE = gql`
  mutation ($input: RelayUpdateOneVoteCampaignInput!) {
    voteCampaignUpdateOne(input: $input) {
      recordId
    }
  }
`;

export const VOTE_POST_BY_ID = gql`
  query ($id: MongoID!) {
    votePostById(_id: $id) {
      ...VOTE_POST_FRAGMENT
    }
  }
  ${VOTE_POST_FRAGMENT}
`;

export const VOTE_POST_FIND_ONE = gql`
  query ($filter: FilterFindOneVotePostInput) {
    votePostFindOne(filter: $filter) {
      ...VOTE_POST_FRAGMENT
    }
  }
  ${VOTE_POST_FRAGMENT}
`;

export const VOTE_POST_CREATE_ONE = gql`
  mutation ($input: RelayCreateOneVotePostInput!) {
    votePostCreateOne(input: $input) {
      recordId
    }
  }
`;

export const VOTE_POST_UPDATE_ONE = gql`
  mutation ($input: RelayUpdateByIdVotePostInput!) {
    votePostUpdateById(input: $input) {
      recordId
    }
  }
`;

export const VOTE_POST_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyVotePostInput
    $sort: SortFindManyVotePostInput
  ) {
    votePostPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      items {
        ...VOTE_POST_FRAGMENT
      }
      count
    }
  }
  ${VOTE_POST_FRAGMENT}
`;

export const VOTE_POST_UPDATE = gql`
  mutation ($votePostId: MongoID!) {
    votePostUpdate(votePostId: $votePostId) {
      _id
      votePost {
        _id
        totalVotes
      }
      discount {
        valueType
        amount
      }
      todayVotedCounter
    }
  }
`;

export const VOTE_POST_UPDATE_BY_ID = gql`
  mutation ($record: UpdateByIdVotePostInput!) {
    votePostUpdateById(input: { record: $record }) {
      record {
        _id
      }
    }
  }
`;

export const VOTE_LOG_FIND_MANY = gql`
  query (
    $filter: FilterFindManyVoteLogInput
    $limit: Int
    $sort: SortFindManyVoteLogInput
  ) {
    voteLogMany(filter: $filter, limit: $limit, sort: $sort) {
      ...VOTE_LOG_FRAGMENT
    }
  }
  ${VOTE_LOG_FRAGMENT}
`;
