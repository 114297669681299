import { REHYDRATE } from 'redux-persist/lib/constants';

const middleware = {
  token: null,
};

export const authTokenMiddleware = () => (next) => (action) => {
  if (action.type === REHYDRATE) {
    if (action.payload && action.payload.auth && action.payload.auth.token) {
      middleware.token = action.payload.auth.token;
    }
  }
  //   if (
  //     action.payload &&
  //     action.payload.language &&
  //     action.payload.language.lang
  //   ) {
  //     console.log(action.payload);
  //     middleware.language = action.payload.language.lang;
  //   }
  // }
  if (action.type === 'AUTH_SUCCESS') {
    const { token } = action;
    middleware.token = token;
  }
  // if (action.type === 'LANGUAGE_SET') {
  //   console.log(action);
  //   const { lang } = action;
  //   middleware.language = lang;
  // }
  return next(action);
};

export const cartLastUpdateMiddleware = () => (next) => (action) => {
  if (action.type === REHYDRATE) {
    // action.payload.cart.items = [];
    if (
      action.payload &&
      action.payload.cart &&
      action.payload.cart.cartLastUpdate
    ) {
      if (action.payload.cart.items.length !== 0) {
        const { cartLastUpdate } = action.payload.cart;
        if (cartLastUpdate === null || cartLastUpdate === undefined) {
          action.payload.cart.items = [];
          action.payload.cart.cartLastUpdate = new Date().getTime();
        } else {
          // check last update if too old
          const now = new Date().getTime();
          const offset = now - cartLastUpdate;
          if (offset > 7200000) {
            action.payload.cart.items = [];
            action.payload.cart.cartLastUpdate = new Date().getTime();
          } else {
            action.payload.cart.cartLastUpdate = new Date().getTime();
          }
        }
      }
    }
  }
  return next(action);
};

export { middleware };
