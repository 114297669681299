/** @jsxRuntime classic */
/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux';
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import * as QueryString from 'query-string';
import { Row, Col, Typography } from 'antd';
// import _ from 'lodash';
// import * as actions from '../../../store/actions';
// import { GET_RESET_LINK } from '../../../apiCanary/user';
import { getCustomerPath, apolloClient } from '../../../util';
import SendResetPasswordEmailForm from '../../../components/Forms/SendResetPasswordEmailForm/SendResetPasswordEmailForm';
import { USER_VERIFY_PASS_CODE_AND_USER_ID } from '../../../api/user';
import ResetPasswordForm from '../../../components/Forms/ResetPasswordForm/ResetPasswordForm';
// import { BANNER_FIND_MANY } from '../../../api/banner';

const { Title } = Typography;

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: false,
      // slides: [],
      showSendEmailFrom: false,
      showRestPasswordForm: false,
      userId: null,
      code: null,
    };
  }

  componentDidMount = async () => {
    await this.setState({ loading: true });
    // await this.handleFetchBanner();
    const {
      location: { search },
    } = this.props;
    const { code, id } = QueryString.parse(search);
    if (code && id) {
      console.log(code, id);
      // check pass code and expires date
      try {
        const verifyResponse = await apolloClient.query({
          query: USER_VERIFY_PASS_CODE_AND_USER_ID,
          variables: {
            code,
            userId: id,
          },
        });
        console.log(verifyResponse);
        this.setState({ showRestPasswordForm: true, userId: id, code });
      } catch (error) {
        this.setState({ showSendEmailFrom: true });
      }
    } else {
      await this.setState({
        showSendEmailFrom: true,
      });
    }
    await this.setState({ loading: false });
  };

  // handleFetchBanner = async () => {
  //   try {
  //     const {
  //       data: { bannerFindMany },
  //     } = await apolloClient.query({
  //       query: BANNER_FIND_MANY,
  //       variables: { filter: { deleted: false, category: 'login' } },
  //     });
  //     // console.log(bannerFindMany);
  //     // sort banner
  //     const rawData = bannerFindMany;
  //     const slides = _.orderBy(rawData, ['ranking'], ['desc']);
  //     this.setState({ slides });
  //   } catch (error) {
  //     message.error('Can not fetch banner');
  //   }
  // };

  render() {
    const { showSendEmailFrom, loading, showRestPasswordForm, userId, code } =
      this.state;
    const { language } = this.props;
    // const device = isMobile ? 'mobile' : 'desktop';

    return (
      <Row
        style={{
          minHeight: 'calc(100vh - 172px)',
        }}
        align="top"
        justify="center"
      >
        <Col>
          <Row justify="center" align="middle" style={{ padding: '16px' }}>
            <Col style={{ maxWidth: '100%', width: '420px' }}>
              {isMobile ? (
                <div>
                  {loading === false && showSendEmailFrom ? (
                    <div>
                      <Title
                        level={2}
                        style={{ fontSize: '1.32rem', textAlign: 'center' }}
                      >
                        {language === 'zh'
                          ? '重置密码'
                          : 'Reset Password By Email'}
                      </Title>
                      <SendResetPasswordEmailForm />
                      <div style={{ textAlign: 'center' }}>
                        <FormattedMessage id="dotNotHaveAccount" />{' '}
                        <Link to={getCustomerPath('signup')}>
                          <FormattedMessage id="signUp" />
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {loading === false && showRestPasswordForm ? (
                    <div>
                      <div
                        css={{
                          margin: '0px 0px 20px 0px',
                          fontSize: '2em',
                          fontWeight: '600',
                        }}
                      >
                        <FormattedMessage id="resetMyPassword" />
                      </div>
                      <ResetPasswordForm userId={userId} code={code} />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  {loading === false && showSendEmailFrom ? (
                    <div>
                      <Title
                        level={2}
                        style={{ fontSize: '1.32rem', textAlign: 'center' }}
                      >
                        {language === 'zh'
                          ? '重置密码'
                          : 'Reset Password By Email'}
                      </Title>
                      <SendResetPasswordEmailForm />

                      <div style={{ textAlign: 'center' }}>
                        <FormattedMessage id="dotNotHaveAccount" />{' '}
                        <Link to={getCustomerPath('signup')}>
                          <FormattedMessage id="signUp" />
                        </Link>
                      </div>
                    </div>
                  ) : null}
                  {loading === false && showRestPasswordForm ? (
                    <div>
                      <div
                        css={{
                          margin: '0px 0px 20px 0px',
                          fontSize: '2em',
                          fontWeight: '600',
                        }}
                      >
                        <FormattedMessage id="resetMyPassword" />
                      </div>
                      <ResetPasswordForm userId={userId} code={code} />
                    </div>
                  ) : null}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
ResetPassword.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(injectIntl(ResetPassword));
