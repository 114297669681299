import gql from 'graphql-tag';

export const GIFT_CARD_FRAGMENT = gql`
  fragment giftCardFragment on GiftCard {
    _id
    status
    purpose
  }
`;

export const GIFT_CARD_REDEEM = gql`
  mutation ($cardNumber: String!, $cardPin: String!) {
    giftCardRedeem(cardNumber: $cardNumber, cardPin: $cardPin) {
      ...giftCardFragment
    }
  }
  ${GIFT_CARD_FRAGMENT}
`;
