import React, { Component } from 'react';
import { Layout, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import * as QueryString from 'query-string';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { LastLocationProvider } from 'react-router-last-location';
import _ from 'lodash';
import moment from 'moment';
import { NotificationOutlined } from '@ant-design/icons';
import { getCustomerPath, apolloClient, mosaicEmail } from '../../util';
import * as actions from '../../store/actions';
import Account from './Account/Account';
import StoresMap from './StoresMap/StoresMap';
import OrderDineMenu from './OrderDineMenu/OrderDineMenu';
import CustomerNav from '../../components/Navigators/CustomerNav/CustomerNav';
import CustomerTabBar from '../../components/Navigators/CustomerTabBar/CustomerTabBar';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import DeliveryMenu from './DeliveryMenu/DeliveryMenu';
import MarketMenu from './DeliveryMenu/MarketMenu/MarketMenu';
import ExternalUrl from './ExternalUrl/ExternalUrl';
import Cart from './Cart/Cart';
import Home from './Home/Home';
import Logout from '../Logout/Logout';
import SignUp from './SignUp/SignUp';
import Campaigns from './Campaigns/Campaigns';
import GroupBuying from './GroupBuying/GroupBuying';
import CustomerFooter from '../../components/Footer/CustomerFooter/CustomerFooter';
import Terms from './Terms/Terms';
import Contact from './Contact/Contact';
import About from './About/About';
import Catering from './Catering/Catering';
import CateringMenu from './CateringMenu/CateringMenu';
import FAQ from './FAQ/FAQ';
import Stories from './Stories/Stories';
import Story from './Story/Story';
import Games from './Games/Games';
import Redirector from './Redirector/Redirector';
import WarehouseRedirector from './WarehouseRedirector/WarehouseRedirector';
import TermsOfService from './TermsOfService/TermsOfService';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Checkout from './Checkout/Checkout';
import Overview from './Account/Overview/Overview';
import NewUserAdsModal from '../../components/Modals/NewUserAdsModal/NewUserAdsModal';
import VoteCampaign from './VoteCampaign/VoteCampaign';
import VotePost from './VotePost/VotePost';
import EmailSignUp from './EmailSignUp/EmailSignUp';
import QueryOrderDetail from './QueryOrderDetail/QueryOrderDetail';
import { USER_LANGUAGE, USER_FIND_ONE } from '../../api/user';
import { BANNER_FIND_ONE_POST } from '../../api/banner';
import { CONTACT_OPENED_HISTORY } from '../../api/contact';
import NewUserFMAdsModal from '../../components/Modals/NewUserFMAdsModal/NewUserFMAdsModal';
import OrderShare from './OrderShare/OrderShare';
import CateringCustomize from './CateringCustomize/CateringCustomize';
import Career from './Career/Career';
import { BALANCE_BY_USER } from '../../api/balance';
import { CURRENT_USER_DISCOUNTS } from '../../api/discount';

const { Content, Footer } = Layout;

// const DeliveryMenu = lazy(() => import('./DeliveryMenu/DeliveryMenu'));
export class Customer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      newUserPosts: null,
      oldUserPosts: null,
    };
  }

  componentDidMount = async () => {
    document.title = 'BunBao 棒!包';
    // console.log('should only print once in customer.js');
    const { saleorId, getSaleorUser, customerOrderCount } = this.props;
    if (saleorId) {
      await getSaleorUser(saleorId);
    }
    const { location } = this.props;
    const { hash } = location;

    if (hash) {
      // push visited history record to backend
      await apolloClient.mutate({
        mutation: CONTACT_OPENED_HISTORY,
        variables: { hashtag: hash.replace('#', '') },
      });
    }

    await this.checkUserBalanceAndCoupon();

    await this.handleReferralCode();
    await this.fetchLanguage();
    // console.log('customerOrderCount', customerOrderCount);
    if (customerOrderCount === 0) {
      await this.fetchNewUserPost();
    } else if (customerOrderCount > 0) {
      await this.fetchOldUserPost();
    }
  };

  // check user remaining balance
  checkUserBalanceAndCoupon = async () => {
    const { userId } = this.props;

    let balance = 0;
    let notExpiredCoupons = [];

    if (userId) {
      const {
        data: { BalanceOne },
      } = await apolloClient.query({
        query: BALANCE_BY_USER,
        variables: { filter: { userId } },
        fetchPolicy: 'network-only',
      });
      if (BalanceOne) {
        const { total } = BalanceOne;
        if (total > 0) {
          balance = total;
        }
      }

      try {
        const {
          data: { currentUserDiscounts },
        } = await apolloClient.query({
          query: CURRENT_USER_DISCOUNTS,
          variables: {},
          fetchPolicy: 'network-only',
        });
        if (currentUserDiscounts) {
          console.log(currentUserDiscounts);
          notExpiredCoupons = _.orderBy(
            currentUserDiscounts.filter(
              (coupon) =>
                !moment(coupon.endDate).isBefore(moment()) &&
                !(
                  coupon.usageLimit !== null &&
                  coupon.usageLimit >= 0 &&
                  coupon.usageLimit === coupon.used
                )
            ),
            ['startDate', 'amount'],
            ['asc', 'desc']
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    console.log('balance', balance);
    console.log('notExpiredCoupons', notExpiredCoupons);

    const currentUrl = window.location.host;

    if (currentUrl === 'origin.bunbao.com') {
      notification.warning({
        message: 'BunBao New Website',
        duration: 20,
        description: (
          <div>
            You current viewing origin.bunbao.com. Please visit our new website{' '}
            <a href="https://bunbao.com" target="_blank" rel="noreferrer">
              bunbao.com
            </a>
            {balance > 0 && (
              <div>
                You have remaining balance, you should using balance in the
                legacy website.
              </div>
            )}
            {notExpiredCoupons.length > 0 && (
              <div>
                You have remaining coupons, you should using coupons in the
                legacy website.
              </div>
            )}
          </div>
        ),
        placement: 'top',
        icon: <NotificationOutlined style={{ color: '#DF242F' }} />,
      });
    }
  };

  fetchNewUserPost = async () => {
    try {
      const {
        data: { bannerFindOne },
      } = await apolloClient.query({
        query: BANNER_FIND_ONE_POST,
        variables: { filter: { isNewUserPost: true, deleted: false } },
        fetchPolicy: 'network-only',
      });
      this.setState({ newUserPosts: bannerFindOne });
    } catch (err) {
      console.error(err);
    }
  };

  fetchOldUserPost = async () => {
    try {
      const {
        data: { bannerFindOne },
      } = await apolloClient.query({
        query: BANNER_FIND_ONE_POST,
        variables: { filter: { isOldUserPost: true, deleted: false } },
        fetchPolicy: 'network-only',
      });
      this.setState({ oldUserPosts: bannerFindOne });
    } catch (err) {
      console.error(err);
    }
  };

  handleReferralCode = async () => {
    const {
      location: { search, pathname },
      history,
      language,
    } = this.props;
    // console.log(search, pathname);
    if (pathname.includes('supermarket')) {
      const paramObj = QueryString.parse(search);
      if (paramObj.referralCode) {
        // check user information
        const responseFindUser = await apolloClient.query({
          query: USER_FIND_ONE,
          variables: { filter: { referralCode: paramObj.referralCode } },
          fetchPolicy: 'network-only',
        });
        if (responseFindUser.data.userOne) {
          window.sessionStorage.setItem('referralCode', paramObj.referralCode);
          delete paramObj.referralCode;
          const newParamObj = QueryString.stringify(paramObj);
          history.push(
            getCustomerPath(
              `supermarket${newParamObj ? `?${newParamObj}` : ''}`
            )
          );
          notification.open({
            message:
              language === 'zh'
                ? `您的朋友(${mosaicEmail(
                    responseFindUser.data.userOne.email
                  )})邀请您逛超市`
                : `You friend (${mosaicEmail(
                    responseFindUser.data.userOne.email
                  )}) invited you to go shopping`,
            description:
              language === 'zh'
                ? '看看我们新鲜的蔬菜吧'
                : 'Check out our fresh supermarket',
            duration: 0,
            placement: 'bottomRight',
          });
        }
      }
    }
  };

  fetchLanguage = async () => {
    try {
      const {
        data: { userCurrent },
      } = await apolloClient.query({
        query: USER_LANGUAGE,
      });
      if (userCurrent && userCurrent.language) {
        // console.log(userCurrent.language);
        this.handleSetLanguage(userCurrent.language);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleSetLanguage = (language) => {
    const { setLanguage } = this.props;
    setLanguage(language);
  };

  render() {
    const {
      match,
      language,
      history,
      token,
      callback,
      overWriteCallBack,
      group,
    } = this.props;
    const { newUserPosts, oldUserPosts } = this.state;

    if (match.url === '/') {
      match.url = '';
    }

    let routes = (
      <Switch>
        <Route
          exact
          path={`${match.url}/search-order`}
          component={QueryOrderDetail}
        />
        <Route exact path={`${match.url}/`} component={Home} />
        <Route exact path={`${match.url}/map`} component={StoresMap} />
        <Route path={`${match.url}/catering`} component={Catering} />
        <Route
          path={`${match.url}/catering-customize`}
          component={CateringCustomize}
        />
        <Route path={`${match.url}/login`} component={Login} />
        <Route path={`${match.url}/signup`} component={SignUp} />
        <Route path={`${match.url}/reset-password`} component={ResetPassword} />
        <Route path={`${match.url}/cart`} component={Cart} />
        <Route
          exact
          path={`${match.url}/pickup-menu`}
          component={DeliveryMenu}
        />
        <Route path={`${match.url}/catering-menu`} component={CateringMenu} />
        <Route
          exact
          path={`${match.url}/delivery-menu`}
          component={DeliveryMenu}
        />
        <Route exact path={`${match.url}/supermarket`} component={MarketMenu} />
        <Route exact path={`${match.url}/bao`} component={MarketMenu} />
        <Route exact path={`${match.url}/donation`} component={DeliveryMenu} />
        <Route exact path={`${match.url}/eshop`} component={DeliveryMenu} />
        <Route
          exact
          path={`${match.url}/store-dine/:warehouseId`}
          component={OrderDineMenu}
        />
        <Route exact path={`${match.url}/campaigns`} component={Campaigns} />
        <Route path={`${match.url}/external-url`} component={ExternalUrl} />
        <Route
          exact
          path={`${match.url}/group-buying`}
          component={GroupBuying}
        />
        <Route exact path={`${match.url}/faq`} component={FAQ} />
        <Route exact path={`${match.url}/stories`} component={Stories} />
        <Route exact path={`${match.url}/story/:id`} component={Story} />
        <Route exact path={`${match.url}/terms`} component={Terms} />
        <Route
          path={`${match.url}/terms-of-service`}
          component={TermsOfService}
        />
        <Route
          exact
          path={`${match.url}/privacy-policy`}
          component={PrivacyPolicy}
        />
        <Route exact path={`${match.url}/contact`} component={Contact} />
        <Route exact path={`${match.url}/about`} component={About} />
        <Route
          exact
          path={`${match.url}/:type/:id`}
          component={WarehouseRedirector}
        />
        <Route
          exact
          path={`${match.url}/:type/:id/:name`}
          component={WarehouseRedirector}
        />
        <Route
          exact
          path={`${match.url}/vote-campaign`}
          component={VoteCampaign}
        />
        <Route exact path={`${match.url}/careers`} component={Career} />
        <Route exact path={`${match.url}/games`} component={Games} />
        <Route exact path={`${match.url}/vote-post`} component={VotePost} />
        <Route exact path={`${match.url}/share-order`} component={OrderShare} />
        {/* <Redirect
          from={`${match.url}/fm`}
          to={{
            pathname: `${match.url}/signup`,
            search: '?invitationCode=fm2020',
          }}
        /> */}
        <Route path={`${match.url}/checkout`} component={Checkout} />
        <Route path={`${match.url}/email-signup`} component={EmailSignUp} />
        <Redirect
          from={`${match.url}/account/personal-coupon`}
          to={`${match.url}/login`}
        />
        {/* <Redirect
          from={`${match.url}/bao`}
          to={`${match.url}/supermarket?special=true`}
        /> */}
        <Redirect from={`${match.url}/account`} to={`${match.url}/login`} />
        <Redirect from={match.url} to={`${match.url}/`} />
      </Switch>
    );
    if (token) {
      routes = (
        <Switch>
          {group && group.name === 'guest' && (
            <Route path={`${match.url}/login`} component={Login} />
          )}
          {group && group.name === 'guest' && (
            <Route path={`${match.url}/email-signup`} component={EmailSignUp} />
          )}
          {group && group.name === 'guest' && (
            <Route path={`${match.url}/signup`} component={SignUp} />
          )}
          <Route
            exact
            path={`${match.url}/search-order`}
            component={QueryOrderDetail}
          />
          <Route exact path={`${match.url}/`} component={Home} />
          <Route exact path={`${match.url}/map`} component={StoresMap} />
          <Route exact path={`${match.url}/catering`} component={Catering} />
          <Route
            exact
            path={`${match.url}/pickup-menu`}
            component={DeliveryMenu}
          />
          <Route path={`${match.url}/campaigns`} component={Campaigns} />
          <Route exact path={`${match.url}/bao`} component={MarketMenu} />
          <Route path={`${match.url}/group-buying`} component={GroupBuying} />
          <Route path={`${match.url}/delivery-menu`} component={DeliveryMenu} />
          <Route
            exact
            path={`${match.url}/supermarket`}
            component={MarketMenu}
          />
          <Route
            exact
            path={`${match.url}/donation`}
            component={DeliveryMenu}
          />
          <Route exact path={`${match.url}/eshop`} component={DeliveryMenu} />
          <Route path={`${match.url}/catering-menu`} component={CateringMenu} />
          <Route
            path={`${match.url}/catering-customize`}
            component={CateringCustomize}
          />
          <Route
            exact
            path={`${match.url}/store-dine/:warehouseId`}
            component={OrderDineMenu}
          />
          <Route exact path={`${match.url}/games`} component={Games} />
          <Route path={`${match.url}/external-url`} component={ExternalUrl} />
          <Route path={`${match.url}/checkout`} component={Checkout} />
          <Route exact path={`${match.url}/stories`} component={Stories} />
          <Route exact path={`${match.url}/story/:id`} component={Story} />
          <Route exact path={`${match.url}/faq`} component={FAQ} />
          <Route path={`${match.url}/terms`} component={Terms} />
          <Route
            path={`${match.url}/terms-of-service`}
            component={TermsOfService}
          />
          <Route
            exact
            path={`${match.url}/privacy-policy`}
            component={PrivacyPolicy}
          />
          <Route path={`${match.url}/contact`} component={Contact} />
          <Route path={`${match.url}/about`} component={About} />
          <Route path={`${match.url}/logout`} component={Logout} />
          <Route path={`${match.url}/account`} component={Account} />
          <Route path={`${match.url}/account-overview`} component={Overview} />
          <Route
            exact
            path={`${match.url}/:type/:id`}
            component={WarehouseRedirector}
          />
          <Route
            exact
            path={`${match.url}/share-order`}
            component={OrderShare}
          />
          <Route
            exact
            path={`${match.url}/:type/:id/:name`}
            component={WarehouseRedirector}
          />
          <Route
            exact
            path={`${match.url}/vote-campaign`}
            component={VoteCampaign}
          />
          <Route exact path={`${match.url}/vote-post`} component={VotePost} />
          <Route exact path={`${match.url}/careers`} component={Career} />
          {/* <Redirect
            from={`${match.url}/bao`}
            to={`${match.url}/supermarket?special=true`}
          /> */}
          <Redirect from={`${match.url}/story`} to={`${match.url}/stories`} />
          <Redirect from={match.url} to={`${match.url}/`} />
        </Switch>
      );
    }

    return (
      <>
        <LastLocationProvider>
          {isMobile ? (
            <CustomerTabBar
              history={history}
              language={language}
              handleSetLanguage={this.handleSetLanguage}
              callback={callback}
              overWriteCallBack={overWriteCallBack}
            >
              <div style={{ paddingTop: '45px' }}>
                {token && !(group && group.name === 'guest') && (
                  <NewUserAdsModal
                    newUserPosts={newUserPosts}
                    oldUserPosts={oldUserPosts}
                  />
                )}
                {token && !(group && group.name === 'guest') && (
                  <NewUserFMAdsModal />
                )}
                {routes}
              </div>
            </CustomerTabBar>
          ) : (
            <Layout className="layout">
              <CustomerNav />
              <Content style={{ backgroundColor: 'white', marginTop: '64px' }}>
                <Redirector />
                {token && !(group && group.name === 'guest') && (
                  <NewUserAdsModal
                    newUserPosts={newUserPosts}
                    oldUserPosts={oldUserPosts}
                  />
                )}
                {token && !(group && group.name === 'guest') && (
                  <NewUserFMAdsModal />
                )}
                <div>{routes}</div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                <CustomerFooter />
              </Footer>
            </Layout>
          )}
        </LastLocationProvider>
      </>
    );
  }
}

Customer.defaultProps = {
  token: null,
  saleorId: null,
  callback: null,
  group: null,
  customerOrderCount: null,
  userId: null,
};

Customer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  token: PropTypes.string,
  saleorId: PropTypes.string,
  getSaleorUser: PropTypes.func.isRequired,
  overWriteCallBack: PropTypes.func.isRequired,
  callback: PropTypes.func,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  location: ReactRouterPropTypes.location.isRequired,
  customerOrderCount: PropTypes.number,
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  warehouseId: state.warehouse.warehouseId,
  token: state.auth.token,
  userId: state.user.userId,
  saleorId: state.auth.saleorId,
  callback: state.user.callback,
  group: state.user.group,
  customerOrderCount: state.user.customerOrderCount,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(actions.setLanguage(language)),
  getSaleorUser: (input) => dispatch(actions.getSaleorUser(input)),
  overWriteCallBack: (callback) =>
    dispatch(actions.overWriteCallBack(callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Customer)
);
