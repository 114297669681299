import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import CateringOrderAdmin from './CateringOrderAdmin/CateringOrderAdmin';
import CateringOrderCompany from './CateringOrderCompany/CateringOrderCompany';
import { apolloClient } from '../../../util';
import { ORDER_BY_ID } from '../../../api/order';

export class CateringOrder extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirect: false,
      order: null,
    };
  }

  async componentDidMount() {
    const { group, match } = this.props;
    this.setState({
      group,
    });
    if (!match.params.orderId) {
      this.setState({ redirect: true });
    } else {
      await this.handleFetchOrder();
    }
  }

  handleFetchOrder = async () => {
    const { match } = this.props;
    try {
      const response = await apolloClient.query({
        query: ORDER_BY_ID,
        variables: {
          id: match.params.orderId,
        },
      });
      console.log(response);
      const { data } = response;
      this.setState({ order: data.orderById });
    } catch (error) {
      console.error(error);
    }
  };

  handleSwitchComponent = () => {
    const { match } = this.props;
    const { params } = match;

    const { group, order } = this.state;
    if (group && order !== null) {
      switch (group.name) {
        case 'admin':
          return <CateringOrderAdmin orderId={params.orderId} order={order} />;
        case 'staff':
          return <CateringOrderAdmin orderId={params.orderId} order={order} />;
        default:
          return (
            <CateringOrderCompany orderId={params.orderId} order={order} />
          );
      }
    }
    return null;
  };

  render() {
    const { redirect } = this.state;
    return (
      <>
        {redirect ? <Redirect to="/dashboard" /> : null}
        {this.handleSwitchComponent()}
      </>
    );
  }
}

CateringOrder.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  group: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  group: state.user.group,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CateringOrder);
