/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import { connect, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getCustomerPath } from '../../../../util';

export const CheckoutCart = (props) => {
  const {
    shoppingList,
    language,
    taxRate,
    handleViewBox,
    discountAmount,
    deliveryFee,
    deliveryMode,
    deliveryMinimumAmount,
    deliveryDistanceRange,
    deliveryDurationPreMinute,
    deliveryDistancePreMile,
    marketMinimumAmountForShippingFree,
    marketShippingFee,
    websiteSaleCode,
    // history
  } = props;

  const lastWarehousePath = useSelector(
    (state) => state.warehouse.lastWarehousePath
  );
  const currentShoppingMode = useSelector(
    (state) => state.user.currentShoppingMode
  );

  const isScheduledDelivery = useSelector(
    (state) => state.warehouse.isScheduledDelivery
  );

  const currentAddressType = useSelector(
    (state) => state.user.currentAddressType
  );

  const history = useHistory();

  const [subtotal, setSubtotal] = useState(0);
  const [excludeFromFreeShippingSubtotal, setExcludeFromFreeShippingSubtotal] =
    useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [showDeliveryFee, setShowDeliveryFee] = useState(false);

  const processProductList = (productList) => {
    let sum = 0;
    productList.forEach((product) => {
      if (product.count > 0) {
        sum += product.count * product.listPrice;
      }
    });
    return sum.toFixed(2);
  };

  const processExcludeFromFreeShippingProducts = (productList) => {
    let sum = 0;
    productList.forEach((product) => {
      if (product.count > 0 && product.excludeFromFreeShipping) {
        sum += product.count * product.listPrice;
      }
    });
    return sum.toFixed(2);
  };

  useEffect(() => {
    setSubtotal(Number(processProductList(shoppingList)));
    setExcludeFromFreeShippingSubtotal(
      Number(processExcludeFromFreeShippingProducts(shoppingList))
    );
    if (!deliveryMode) {
      // TODO: should re calculate taxable variant
      // setTax(Number((subtotal * (taxRate / 100)).toFixed(2)));
      // TODO: remove for all orders, temporarily
      setTax(0);
    }
    if (
      deliveryMode === 'minimumAmountForShippingFree' &&
      subtotal - excludeFromFreeShippingSubtotal >= deliveryMinimumAmount
    ) {
      setTotal((tax * 1 + subtotal * 1 - discountAmount).toFixed(2));
      setShowDeliveryFee(false);
    } else if (
      currentShoppingMode === 'delivery' &&
      currentAddressType !== 'pickup' &&
      !isScheduledDelivery
    ) {
      if (subtotal - discountAmount < marketMinimumAmountForShippingFree) {
        setTotal(
          (tax * 1 + subtotal * 1 - discountAmount + marketShippingFee).toFixed(
            2
          )
        );
        setShowDeliveryFee(true);
      } else {
        setShowDeliveryFee(false);
        setTotal((tax * 1 + subtotal * 1 - discountAmount).toFixed(2));
      }
    } else {
      // TODO: may have show delivery problem.
      setTotal(
        (tax * 1 + subtotal * 1 + deliveryFee - discountAmount).toFixed(2)
      );
    }
  }, [
    shoppingList,
    subtotal,
    taxRate,
    tax,
    deliveryFee,
    discountAmount,
    deliveryMode,
    deliveryMinimumAmount,
    excludeFromFreeShippingSubtotal,
    currentShoppingMode,
    isScheduledDelivery,
    marketMinimumAmountForShippingFree,
    marketShippingFee,
    showDeliveryFee,
    currentAddressType,
  ]);

  const deliveryFeeTooltip = () => {
    const sortedDistance = lodash
      .sortBy(deliveryDistanceRange, ['maxMile'])
      .map((distance) => {
        return {
          maxMile: distance.maxMile,
          minMile: distance.minMile,
          flatFee: distance.flatFee,
        };
      });
    if (
      deliveryMode === 'minimumAmountForShippingFree' ||
      deliveryMode === 'distance'
    ) {
      if (deliveryMode === 'distance') {
        return (
          <div>
            {sortedDistance.map((distance) => (
              <div key={distance.minMile + distance.maxMile}>
                {distance.minMile} - {distance.maxMile} miles $
                {distance.flatFee}
              </div>
            ))}
          </div>
        );
      }
      return (
        <div>
          <div>
            {language === 'zh' ? '免费配送当订单超过' : 'Free Shipping over'} $
            {deliveryMinimumAmount}
          </div>
          <div>
            {language === 'zh' ? '如果低于' : 'If under'} $
            {deliveryMinimumAmount}
          </div>
          {sortedDistance.map((distance) => (
            <div key={distance.minMile + distance.maxMile}>
              {distance.minMile} - {distance.maxMile} miles ${distance.flatFee}
            </div>
          ))}
        </div>
      );
    }
    if (deliveryMode === 'duration') {
      return (
        <div>
          ${deliveryDistancePreMile} x distance + ${deliveryDurationPreMinute} x
          minutes
        </div>
      );
    }
    return <div>None</div>;
  };

  return (
    <Table
      dataSource={shoppingList}
      size="small"
      bordered
      pagination={false}
      rowKey={(e) => e._id}
      title={() =>
        isMobile ? (
          <div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
              <FormattedMessage id="shoppingCart" />
            </div>
            {isMobile ? <hr className="common-divider-line" /> : null}
          </div>
        ) : (
          <Row type="flex" justify="center" align="middle">
            <FormattedMessage id="shoppingCart" />{' '}
            <Button
              css={{
                fontSize: '12px',
                padding: '0px 2px 0px 2px',
                marginLeft: '3px',
              }}
              onClick={() => {
                if (lastWarehousePath) {
                  history.replace(lastWarehousePath);
                } else {
                  history.replace(getCustomerPath('delivery-menu'));
                }
              }}
            >
              <FormattedMessage id="backToShoppingCart" />
            </Button>
          </Row>
        )
      }
      footer={() => (
        <React.Fragment>
          <Row type="flex" justify="end">
            {subtotal > 0.01 ? (
              <React.Fragment>
                <FormattedMessage id="subtotal" /> ${subtotal.toFixed(2)}
              </React.Fragment>
            ) : null}
          </Row>
          <Row type="flex" justify="end" style={{ color: 'red' }}>
            {discountAmount > 0.01 ? (
              <React.Fragment>
                <FormattedMessage id="discountAmount" /> -$
                {discountAmount.toFixed(2)}{' '}
                {websiteSaleCode && (
                  <span> ({websiteSaleCode.name[language]})</span>
                )}
              </React.Fragment>
            ) : null}
          </Row>
          {tax > 0.01 || isScheduledDelivery ? (
            <Row type="flex" justify="end">
              <FormattedMessage id="taxFee" /> ${tax.toFixed(2)}{' '}
            </Row>
          ) : null}
          {!isScheduledDelivery && showDeliveryFee && (
            <Row type="flex" justify="end">
              <FormattedMessage id="deliveryFee" /> $
              {marketShippingFee.toFixed(2)}
            </Row>
          )}
          {isScheduledDelivery &&
          ((deliveryFee > 0.01 &&
            deliveryMode !== 'minimumAmountForShippingFree') ||
            (deliveryFee > 0.01 &&
              deliveryMode === 'minimumAmountForShippingFree' &&
              subtotal - excludeFromFreeShippingSubtotal <
                deliveryMinimumAmount)) ? (
            <Row type="flex" justify="end">
              <FormattedMessage id="deliveryFee" />
              <Tooltip title={deliveryFeeTooltip()}>
                <span css={{ margin: '0px 4px' }}>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
              ${deliveryFee.toFixed(2)}
            </Row>
          ) : null}
          {isScheduledDelivery &&
          deliveryMode === 'minimumAmountForShippingFree' &&
          subtotal - excludeFromFreeShippingSubtotal >=
            deliveryMinimumAmount ? (
            <Row type="flex" justify="end">
              <FormattedMessage id="deliveryFee" />
              <Tooltip title={deliveryFeeTooltip()}>
                <span css={{ marginLeft: '4px' }}>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
              <span css={{ marginLeft: '4px' }}>
                <del>${deliveryFee.toFixed(2)}</del>
              </span>
              <span css={{ marginLeft: '4px' }}>
                <FormattedMessage id="free" />
              </span>
            </Row>
          ) : null}
          <Row type="flex" justify="end">
            <FormattedMessage id="totalPriceIs" /> ${total}
          </Row>
        </React.Fragment>
      )}
    >
      <Col
        key="1"
        title={() => <FormattedMessage id="productName" />}
        render={(text, e) => (
          <Row type="flex" justify="start" align="middle" gutter={5}>
            <Col>{e.name[language]}</Col>
            <Col>
              {e.boxContent ? (
                <Button
                  onClick={() => {
                    handleViewBox(e.boxContent);
                  }}
                >
                  {' '}
                  <FormattedMessage id="view" />
                </Button>
              ) : null}
            </Col>
          </Row>
        )}
      />
      <Col
        key="2"
        title={() => <FormattedMessage id="price" />}
        render={(text, e) => `$${Number(e.listPrice).toFixed(2)}`}
      />
      <Col
        key="3"
        title={() => <FormattedMessage id="quantity" />}
        render={(text, e, index) => (
          <Row>
            <Col key={index} span={10} style={{ textAlign: 'center' }}>
              {e.count}
            </Col>
          </Row>
        )}
      />
      <Col
        key="4"
        title={() => <FormattedMessage id="totalPrice" />}
        render={(text, e) => `$${(e.count * e.listPrice).toFixed(2)}`}
      />
    </Table>
  );
};

CheckoutCart.propTypes = {
  handleViewBox: PropTypes.func,
  discountAmount: PropTypes.number,
  language: PropTypes.string.isRequired,
  shoppingList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taxRate: PropTypes.number.isRequired,
  deliveryFee: PropTypes.number,
  // history: ReactRouterPropTypes.history.isRequired,
  deliveryMode: PropTypes.string,
  deliveryMinimumAmount: PropTypes.number,
  deliveryDistanceRange: PropTypes.arrayOf(PropTypes.shape({})),
  deliveryDurationPreMinute: PropTypes.number,
  deliveryDistancePreMile: PropTypes.number,
  marketMinimumAmountForShippingFree: PropTypes.number,
  marketShippingFee: PropTypes.number,
  websiteSaleCode: PropTypes.shape({
    name: PropTypes.shape({ zh: PropTypes.string, en: PropTypes.string }),
  }),
};
CheckoutCart.defaultProps = {
  websiteSaleCode: null,
  handleViewBox: null,
  discountAmount: 0,
  deliveryFee: 0,
  deliveryMode: null,
  deliveryMinimumAmount: 0,
  deliveryDistanceRange: [],
  deliveryDurationPreMinute: 0,
  deliveryDistancePreMile: 0,
  marketShippingFee: 0,
  marketMinimumAmountForShippingFree: 0,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(CheckoutCart);
