import { LANGUAGE_SET } from './actionTypes';

const setLanguage = (lang) => {
  return {
    type: LANGUAGE_SET,
    lang,
  };
};

/* eslint-disable-next-line */
export { setLanguage };
