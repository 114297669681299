import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Modal, message, ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { LocaleProvider } from 'antd-mobile';
// import * as localForage from 'localforage';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import mobileEnUS from 'antd-mobile/lib/locale-provider/en_US';
// import scriptLoader from 'react-async-script-loader';
import {
  UPDATE_MY_MESSAGE,
  MESSAGE_USER_NEW_MESSAGE,
  MESSAGE_USER_NEW_LAST_UNREAD_MESSAGE,
} from './api/message';
import { OnSiteMessageModal } from './components/Modals/OnSiteMessageModal/OnSiteMessageModal';
import { apolloClient } from './util';
import translation from './translation/translation';
import {
  DevelopmentRoutes,
  CustomerRoutes,
  FoundationRoutes,
  PointRoutes,
} from './routes/routes';
import * as actions from './store/actions';
import Layout from './helper/Layout/Layout';
import './App.less';
import { GOOGLE_MAP_KEY, HOTJAR_TAGS } from './config';

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: true,
      googleLoading: true,
      host: '',
      currentNotReadMessage: null,
      currentMessageModal: false,
    };
  }

  componentDidMount = async () => {
    this.loadGoogleMaps();
    // first check version
    // const version = await localForage.getItem('version');
    // if (!version || version !== LOCAL_STORAGE_VERSION) {
    //   // clean localStorage
    //   await localForage.clear();
    //   await localForage.setItem('version', LOCAL_STORAGE_VERSION);
    // }

    const currentUrl = window.location.host;
    this.setState({ host: currentUrl });
    // for this section to check current url,
    // check customer website url, for block foundation home page
    const {
      getCurrentUser,
      refreshToken,
      getRefreshToken,
      authFail,
      setWarehouse,
      setUserShoppingMode,
      // getSaleorUser,
      updateShoppingCart,
    } = this.props;
    this.setState({ loading: true });
    // check warehouse and tax
    await setWarehouse();
    // check shopping mode
    await setUserShoppingMode();
    // get Saleor user Id
    // await getSaleorUser();
    // get shopping cart Id
    await updateShoppingCart();

    // test only

    const { token } = this.props;
    // if has token to check current user
    if (token !== null) {
      try {
        await getCurrentUser();
      } catch (error) {
        // refresh token or not
        console.log('token expires, refresh token');
        if (
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].message === 'please login'
        ) {
          try {
            await getRefreshToken(refreshToken);
            await getCurrentUser();
          } catch (err) {
            console.log('refresh failed, just logout');
            authFail(err);
          }
        } else {
          authFail(error);
        }
      }
    } else {
      try {
        if (window.hj) {
          window.hj('tagRecording', [HOTJAR_TAGS.NOT_LOGGED_IN_USER]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    // fetch message
    const { group } = this.props;
    if (token && group && group.name !== 'admin' && group.name !== 'company') {
      // Just run first time
      await this.fetchOnSiteMessage();
      this.subscriptionMessage();
    }
    await this.setState({ loading: false });
  };

  loadGoogleMaps = () => {
    const existingScript = document.getElementById('googleMaps');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`;
      script.id = 'googleMaps';
      document.body.appendChild(script);

      script.onload = () => {
        this.setState({ googleLoading: false });
      };
    }
    if (existingScript) {
      this.setState({ googleLoading: false });
    }
  };

  // TODO: move to redux
  fetchOnSiteMessage = async () => {
    const { data } = await apolloClient.query({
      query: MESSAGE_USER_NEW_LAST_UNREAD_MESSAGE,
      fetchPolicy: 'network-only',
    });
    // console.log('receive message',data)
    if (data && data.messageCurrentUserNewMessage) {
      this.setState({
        currentMessageModal: true,
        currentNotReadMessage: data.messageCurrentUserNewMessage,
      });
    }
  };

  // TODO: move to redux
  subscriptionMessage = async () => {
    try {
      apolloClient
        .subscribe({
          query: MESSAGE_USER_NEW_MESSAGE,
        })
        .subscribe(({ data }) => {
          if (data && data.messageAdded) {
            this.setState({
              currentMessageModal: true,
              currentNotReadMessage: data.messageAdded,
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  // TODO: move to redux
  updateMessage = async (Id, path = null, direction = false) => {
    const input = { isRead: true };
    const { data } = await apolloClient.mutate({
      mutation: UPDATE_MY_MESSAGE,
      variables: { input, Id },
    });
    if (data && data.messageUpdateOne) {
      if (direction === true) {
        window.open(path, '_blank');
      }
      if (path) {
        window.location.replace(path);
      }
    } else {
      message.error('error happened, contact customer service!');
    }
  };

  render() {
    const {
      loading,
      googleLoading,
      host,
      currentMessageModal,
      currentNotReadMessage,
    } = this.state;
    const { token, group, language } = this.props;
    let routes;
    if (
      host === 'bunbao.com' ||
      host === 'customer.bunbao.com' ||
      host === 'demo.bunbao.com:3222' ||
      host === 'test.bunbao.com' ||
      host === 'legacy.bunbao.com' ||
      host === 'origin.bunbao.com'
    ) {
      routes = CustomerRoutes(token, group, host);
    } else if (host === 'point.bunbao.com' || host === 'robin.bunbao.com') {
      routes = PointRoutes(token, group, host);
    } else if (
      process.env.REACT_APP_BUILD_DEV === 'true' ||
      process.env.NODE_ENV === 'development'
    ) {
      // TODO: The routes for development only, please change it frequently
      routes = DevelopmentRoutes(token, group);
    } else {
      routes = FoundationRoutes(token, group, host);
    }

    if (language === 'zh') {
      moment.locale('zh-cn', {
        week: {
          dow: 0,
        },
      });
    } else {
      moment.locale('en');
    }

    const languages = [
      {
        value: 'zh',
        language: undefined,
      },
      {
        value: 'en',
        language: mobileEnUS,
      },
    ];
    const currentLocale = languages.find(
      (item) => item.value === language
    ).language;

    return (
      <IntlProvider
        locale={language}
        defaultLocale="en"
        messages={translation[language]}
      >
        <Modal
          visible={currentMessageModal}
          destroyOnClose
          footer={null}
          onCancel={() => {
            this.setState({ currentMessageModal: false });
            this.updateMessage(currentNotReadMessage._id);
          }}
        >
          <OnSiteMessageModal
            inputMessage={currentNotReadMessage}
            updateMessage={this.updateMessage}
            language={language}
          />
        </Modal>
        <LocaleProvider locale={currentLocale}>
          <ConfigProvider locale={language === 'zh' ? zhCN : enUS}>
            {loading === false && googleLoading === false ? (
              <Layout>{routes}</Layout>
            ) : null}
          </ConfigProvider>
        </LocaleProvider>
      </IntlProvider>
    );
  }
}

App.defaultProps = {
  token: null,
  refreshToken: null,
  language: 'en',
  group: null,
};

App.propTypes = {
  token: PropTypes.string,
  getCurrentUser: PropTypes.func.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  language: PropTypes.string,
  authFail: PropTypes.func.isRequired,
  refreshToken: PropTypes.string,
  getRefreshToken: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setWarehouse: PropTypes.func.isRequired,
  // getSaleorUser: PropTypes.func.isRequired,
  updateShoppingCart: PropTypes.func.isRequired,
  group: PropTypes.shape({ name: PropTypes.string.isRequired }),
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  refreshToken: state.auth.refreshToken,
  group: state.user.group,
  email: state.user.email,
  userId: state.user.userId,
  items: state.cart.items,
  language: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
  setUserShoppingMode: () => dispatch(actions.setUserShoppingMode()),
  getRefreshToken: (oldRefreshToken) =>
    dispatch(actions.refreshToken(oldRefreshToken)),
  authFail: (error) => dispatch(actions.authFail(error)),
  setWarehouse: () => dispatch(actions.setWarehouse()),
  getSaleorUser: () => dispatch(actions.getSaleorUser()),
  updateShoppingCart: () => dispatch(actions.updateShoppingCart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

// scriptLoader([
//   `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places&callback=GoogleMapAPIFunc`,
// ])
