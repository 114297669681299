import gql from 'graphql-tag';

export const PICTURE_GET_SIGNED_URL = gql`
  mutation ($fileType: String!, $category: String!) {
    pictureCreateSignedUrl(fileType: $fileType, category: $category) {
      signedUrl
      key
    }
  }
`;

export const PICTURE_CREATE = gql`
  mutation ($key: String!, $category: String!) {
    pictureCreate(key: $key, category: $category) {
      _id
      key
    }
  }
`;

export const PICTURE_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyPictureInput
    $sort: SortFindManyPictureInput
  ) {
    picturePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        key
        category
        userId
      }
    }
  }
`;

export const PICTURE_BY_ID = gql`
  query ($id: MongoID!) {
    pictureById(_id: $id) {
      _id
      key
      category
      userId
    }
  }
`;

export const PICTURE_UPDATE_BY_ID = gql`
  mutation ($input: RelayUpdateByIdPictureInput!) {
    pictureUpdateOne(input: $input) {
      recordId
    }
  }
`;
