import gql from 'graphql-tag';
import { ADDRESS_FRAGMENT } from './address';

export const WAREHOUSE_FRAGMENT = gql`
  fragment WAREHOUSE_FRAGMENT on Warehouse {
    _id
    name
    title {
      zh
      en
    }
    alias
    addressOne
    addressTwo
    city
    zip
    region
    squareLocationId
    delivery
    pickup
    announcement {
      en
      zh
      _id
    }
    scheduledDeliveryHours {
      _id
      days
      startTime {
        hours
        minutes
      }
      endTime {
        hours
        minutes
      }
      cutOffTime
      allowedDateRange {
        startDate
        endDate
      }
    }
    location {
      coordinates
    }
    warehouseScheduleDeliveryMaxDistance
    allowedPickDates
    disallowedPickDates
    formattedOpenTimeSlot
    deliveryMaxDistance
    isOpen
    warehouseType
    cutOffTime
    backgroundImages {
      key
      _id
    }
    connectedAccountId
    pickupAddressIds
    pickupAddresses {
      ...ADDRESS_FRAGMENT
    }
    isParent
    childrenWarehouses {
      _id
    }
    allowedVariants {
      markAsSoldOut
      allowedShippingDate
      maximumPerOrder
      description {
        _id
        zh
        en
      }
      picture {
        _id
        key
      }
      videoIds {
        _id
        videoId
        language
      }
      name {
        _id
        zh
        en
      }
      subtitle {
        _id
        zh
        en
      }
      product {
        _id
        name {
          _id
          zh
          en
        }
        description {
          _id
          en
          zh
        }
        id
        picture {
          _id
          key
        }
        hashtags
        ranking
        category {
          _id
          id
          name
          ranking
          hashtags
          title {
            zh
            en
          }
          pictures {
            _id
            key
          }
        }
        cateringCategoryId
        cateringCategory {
          _id
          id
          name
          ranking
          hashtags
          title {
            _id
            zh
            en
          }
          pictures {
            _id
            key
          }
        }
        unit
        unitNumber
        unitShape
        ingredients
        nutritionFacts
        allergen
      }
      servesNumber
      shape
      numberOfUnits
      ranking
      originalPrice
      listPrice
      picture {
        _id
        key
      }
      minimumQuantity
      _id
      allowedInventoryCheck
    }
  }
  ${ADDRESS_FRAGMENT}
`;

// Only for bunbao Home page
export const WAREHOUSE_INFO_FIND_ONE = gql`
  query ($filter: FilterFindOneWarehouseInput) {
    warehouseOne(filter: $filter) {
      _id
      name
      title {
        _id
        zh
        en
      }
      alias
      addressOne
      addressTwo
      city
      zip
      region
      squareLocationId
      cutOffTime
      announcement {
        _id
        en
        zh
      }
      location {
        coordinates
      }
      deliveryMaxDistance
      scheduledDeliveryTimeRange {
        start
        end
      }
      deliveryMaxDistance
      formattedOpenTimeSlot
      isOpen
      warehouseType
      backgroundImages {
        key
        _id
      }
      hours {
        day
        start
        end
      }
      videoIds {
        videoId
        language
      }
      childrenWarehouseIdsRanking {
        warehouseId
        ranking
      }
    }
  }
`;

// bunbao website allowedVariants
export const BUNBAO_ALLOWED_VARIANTS = gql`
  query ($filter: FilterFindOneWarehouseInput) {
    warehouseOne(filter: $filter) {
      _id
      allowedVariants {
        _id
        name {
          _id
          en
          zh
        }
        product {
          _id
          category {
            _id
            title {
              _id
              en
              zh
            }
          }
        }
      }
    }
  }
`;

export const BUNBAO_ID_ONLY = gql`
  query ($filter: FilterFindOneWarehouseInput) {
    warehouseOne(filter: $filter) {
      _id
    }
  }
`;

export const WAREHOUSE_FIND_ONE = gql`
  query ($filter: FilterFindOneWarehouseInput) {
    warehouseOne(filter: $filter) {
      _id
      name
      title {
        zh
        en
      }
      alias
      addressOne
      addressTwo
      city
      zip
      region
      squareLocationId
      cutOffTime
      announcement {
        en
        zh
      }
      location {
        coordinates
      }
      isShutDown
      allowedPickupVariantIds
      deliveryMaxDistance
      scheduledDeliveryTimeRange {
        start
        end
      }
      scheduledDeliveryHours {
        days
        startTime {
          hours
          minutes
        }
        endTime {
          hours
          minutes
        }
        cutOffTime
        allowedDateRange {
          startDate
          endDate
        }
      }
      formattedOpenTimeSlot
      isOpen
      warehouseType
      backgroundImages {
        key
        _id
      }
      hours {
        day
        start
        end
      }
      videoIds {
        videoId
        language
      }
      allowedPickupVariants {
        ...MENU_VARIANT
      }
      allowedVariants {
        ...MENU_VARIANT
      }
      allowedFoodTruckVariantIds
      childrenWarehouses {
        _id
        name
        title {
          _id
          zh
          en
        }
        alias
        addressOne
        addressTwo
        city
        videoIds {
          videoId
          language
        }
        cutOffTime
        phoneNumber
        allowedVariants {
          ...MENU_VARIANT
        }
      }
      childrenWarehouseIdsRanking {
        warehouseId
        ranking
      }
    }
  }
  fragment MENU_VARIANT on Variant {
    markAsSoldOut
    allowedShippingDate
    maximumPerOrder
    description {
      zh
      en
    }
    picture {
      _id
      key
    }
    videoIds {
      _id
      videoId
      language
    }
    name {
      zh
      en
    }
    subtitle {
      zh
      en
    }
    product {
      _id
      name {
        zh
        en
      }
      description {
        en
        zh
      }
      id
      picture {
        _id
        key
      }
      hashtags
      ranking
      category {
        _id
        id
        pictures {
          _id
          key
        }
        name
        ranking
        hashtags
        title {
          zh
          en
        }
      }
      subcategory {
        _id
        id
        name
        ranking
        hashtags
        title {
          zh
          en
        }
        color
      }
      unit
      unitNumber
      unitShape
      ingredients
      nutritionFacts
      allergen
    }
    servesNumber
    shape
    numberOfUnits
    ranking
    originalPrice
    isChangeListPriceByDates
    changeListPriceDates {
      startDate
      endDate
    }
    listPrice
    picture {
      key
    }
    minimumQuantity
    _id
    allowedInventoryCheck
    excludeFromFreeShipping
    isItNotAbleToAddCart
  }
`;

export const WAREHOUSE_BY_ID = gql`
  query ($Id: MongoID!) {
    warehouseById(_id: $Id) {
      ...WAREHOUSE_FRAGMENT
    }
  }
  ${WAREHOUSE_FRAGMENT}
`;

export const WAREHOUSE_FIND_MANY = gql`
  query ($filter: FilterFindManyWarehouseInput) {
    warehouseMany(filter: $filter) {
      ...WAREHOUSE_FRAGMENT
    }
  }
  ${WAREHOUSE_FRAGMENT}
`;

export const WAREHOUSE_BY_OWNER = gql`
  query ($filter: FilterFindManyWarehouseInput) {
    warehouseMany(filter: $filter) {
      _id
      name
      userIds
      isParent
    }
  }
`;

export const WAREHOUSE_PRODUCTS_OPERATION = gql`
  mutation (
    $warehouseIds: [MongoID!]
    $variantIDs: [MongoID]
    $allowedPickupVariantIds: [MongoID]
    $hotDisplayPickupVariantIds: [MongoID]
    $allowedFoodTruckVariantIds: [MongoID]
  ) {
    warehouseVariantOperation(
      warehouseIds: $warehouseIds
      variantIDs: $variantIDs
      allowedPickupVariantIds: $allowedPickupVariantIds
      hotDisplayPickupVariantIds: $hotDisplayPickupVariantIds
      allowedFoodTruckVariantIds: $allowedFoodTruckVariantIds
    ) {
      sku
      name {
        en
      }
    }
  }
`;

export const WAREHOUSE_SEARCH_BY_LOCATION = gql`
  query ($latitude: Float!, $longitude: Float!, $radius: Float!) {
    warehouseByLocation(
      latitude: $latitude
      longitude: $longitude
      radius: $radius
    ) {
      _id
      name
      pickup
      dineIn
      scheduledDelivery
      delivery
      title {
        en
        zh
      }
      partnerName
      isOpen
      isComingSoon
      hotDisplayPickupVariants {
        _id
        listPrice
        originalPrice
        name {
          zh
          en
        }
        product {
          _id
          picture {
            _id
            key
          }
        }
        picture {
          _id
          key
        }
      }
      openTimeSlot {
        _id
        day
        start
        end
      }
      users {
        _id
        email
      }
      addressOne
      addressTwo
      city
      state
      zip
      region
      squareLocationId
      warehouseType
      location {
        coordinates
      }
      volume
      warehouseType
      thirdParty
      hours {
        day
        start
        end
      }
      deleted
    }
  }
`;

export const WAREHOUSE_BY_ID_LOCATION = gql`
  query ($input: MongoID!) {
    warehouseById(_id: $input) {
      _id
      location {
        coordinates
      }
      deliveryMaxDistance
      warehouseScheduleDeliveryMaxDistance
    }
  }
`;

export const WAREHOUSE_PRODUCTS_QUERY = gql`
  query ($input: MongoID!) {
    warehouseById(_id: $input) {
      _id
      name
      customerOrders {
        variants {
          name {
            zh
            en
          }
          quantity
        }
        address {
          lastName
          firstName
          phoneNumber
        }
        paymentStatus
        customerNotes
        subtotal
        tax
        deliveryFee
        total
        createdAt
        warehouseStatus
        _id
        displayId
        user {
          email
        }
      }
      partnerName
      hours {
        start
        end
        day
      }
      users {
        _id
        email
      }
      addressOne
      addressTwo
      city
      state
      zip
      region
      squareLocationId
      warehouseType
      location {
        coordinates
      }
      volume
      warehouseType
      allowedVariantIds
      allowedVariants {
        _id
        name {
          zh
          en
        }
        description {
          zh
          en
        }
        remark
        remarkName {
          zh
          en
        }
        sku
        ranking
        pictureId
        picture {
          _id
          key
          category
        }
        listPrice
        servesNumber
        shape
        deleted
        product {
          unit
          unitNumber
        }
      }
      allowedStoreVariants {
        _id
        name {
          zh
          en
        }
        description {
          zh
          en
        }
        remark
        remarkName {
          zh
          en
        }
        sku
        ranking
        pictureId
        picture {
          _id
          key
          category
        }
        listPrice
        servesNumber
        shape
        deleted
        product {
          unit
          unitNumber
        }
      }
    }
  }
`;

export const WAREHOUSE_UPDATE_ONE = gql`
  mutation ($input: UpdateOneWarehouseInput!, $_id: MongoID) {
    warehouseUpdateOne(input: { record: $input, filter: { _id: $_id } }) {
      record {
        _id
      }
    }
  }
`;

export const WAREHOUSE_ALLOWED_VARIANT_DASHBOARD = gql`
  query ($input: MongoID!) {
    warehouseById(_id: $input) {
      _id
      allowedVariantIds
      allowedPickupVariantIds
      hotDisplayPickupVariantIds
      hotDisplayPickupVariants {
        name {
          en
          zh
        }
        remarkName {
          zh
          en
        }
        warehouseId
        warehouse {
          name
        }
        _id
      }
      allowedPickupVariants {
        name {
          en
          zh
        }
        remarkName {
          zh
          en
        }
        warehouseId
        warehouse {
          name
        }
        _id
        product {
          _id
          category {
            _id
            title {
              en
              zh
            }
          }
        }
      }
      allowedVariants {
        name {
          en
          zh
        }
        remarkName {
          zh
          en
        }
        warehouseId
        warehouse {
          name
        }
        _id
        product {
          _id
          category {
            _id
            title {
              en
              zh
            }
          }
        }
      }
    }
  }
`;

export const WAREHOUSE_UPDATE = gql`
  mutation (
    $warehouseId: String!
    $name: String!
    $userIds: [String!]
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $state: String!
    $zip: String!
    $region: String!
    $squareLocationId: String
    $latitude: Float!
    $longitude: Float!
    $type: String!
    $volume: Int
    $thirdParty: ThirdPartyInput
    $hours: [HoursInput]
    $partnerName: String
    $phoneNumber: String!
    $dineIn: Boolean
    $scheduledDelivery: Boolean
    $delivery: Boolean
    $pickup: Boolean
    $alias: String
    $backgroundImageIds: [String]
    $isParent: Boolean
    $videoIds: [VideoIdInput]
    $notificationPhoneNumbers: [String]
    $cutOffTime: Float
    $scheduledDeliveryTimeRangeStart: Float
    $scheduledDeliveryTimeRangeEnd: Float
    $commissionPercentage: Float
    $deliveryMaxDistance: Float
    $announcement: AnnouncementInput
    $disallowedPickDates: [String]
    $allowedPickDates: [String]
    $marketplaceCommissionPercentage: Float
    $isExcludeCalculateCommissionFee: Boolean
    $titleZh: String
    $titleEn: String
    $ownerId: MongoID
    $isShutDown: Boolean
  ) {
    warehouseUpdateAdmin(
      phoneNumber: $phoneNumber
      warehouseId: $warehouseId
      name: $name
      userIds: $userIds
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      state: $state
      zip: $zip
      region: $region
      squareLocationId: $squareLocationId
      latitude: $latitude
      longitude: $longitude
      type: $type
      volume: $volume
      thirdParty: $thirdParty
      hours: $hours
      partnerName: $partnerName
      dineIn: $dineIn
      scheduledDelivery: $scheduledDelivery
      delivery: $delivery
      pickup: $pickup
      alias: $alias
      backgroundImageIds: $backgroundImageIds
      isParent: $isParent
      videoIds: $videoIds
      notificationPhoneNumbers: $notificationPhoneNumbers
      cutOffTime: $cutOffTime
      scheduledDeliveryTimeRangeStart: $scheduledDeliveryTimeRangeStart
      scheduledDeliveryTimeRangeEnd: $scheduledDeliveryTimeRangeEnd
      commissionPercentage: $commissionPercentage
      deliveryMaxDistance: $deliveryMaxDistance
      announcement: $announcement
      disallowedPickDates: $disallowedPickDates
      allowedPickDates: $allowedPickDates
      marketplaceCommissionPercentage: $marketplaceCommissionPercentage
      isExcludeCalculateCommissionFee: $isExcludeCalculateCommissionFee
      titleZh: $titleZh
      titleEn: $titleEn
      ownerId: $ownerId
      isShutDown: $isShutDown
    ) {
      name
    }
  }
`;

const WAREHOUSE_MAP_FRAGMENT = gql`
  fragment WAREHOUSE_MAP_FRAGMENT on Warehouse {
    _id
    alias
    name
    title {
      zh
      en
    }
    pickup
    dineIn
    scheduledDelivery
    delivery
    partnerName
    isOpen
    isComingSoon
    hotDisplayPickupVariants {
      _id
      listPrice
      originalPrice
      name {
        zh
        en
      }
      product {
        _id
        picture {
          _id
          key
        }
      }
      picture {
        _id
        key
      }
    }
    openTimeSlot {
      _id
      day
      start
      end
    }
    users {
      _id
      email
    }
    addressOne
    addressTwo
    city
    state
    zip
    region
    squareLocationId
    warehouseType
    location {
      coordinates
    }
    volume
    warehouseType
    thirdParty
    hours {
      day
      start
      end
    }
    deleted
  }
`;

export const FIX_LOCATION_PICKUP_QUERY = gql`
  query ($filter: FilterFindManyCampaignContentInput) {
    campaignContentFindMany(filter: $filter) {
      _id
      defaultAddressId
      defaultAddress {
        _id
        phoneNumber
        companyName
        fullAddress
        lastName
        firstName
        addressOne
        addressTwo
        zipCode
        gateCode
        city
        state
        type
      }
      pickupWindow {
        _id
        start
        end
        day
      }
      pickupTimeSlotFormatted
      prepareDay
      endDate
      warehouseId
      warehouse {
        _id
      }
    }
  }
`;

export const WAREHOUSES_QUERY = gql`
  query ($filter: FilterFindManyWarehouseInput) {
    warehouseMany(filter: $filter) {
      ...WAREHOUSE_MAP_FRAGMENT
    }
  }
  ${WAREHOUSE_MAP_FRAGMENT}
`;

export const WAREHOUSES_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyWarehouseInput
    $sort: SortFindManyWarehouseInput
  ) {
    warehousePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        alias
        name
        title {
          zh
          en
        }
        videoIds {
          videoId
          language
        }
        announcement {
          zh
          en
        }
        hotDisplayPickupVariants {
          _id
          listPrice
          originalPrice
          name {
            zh
            en
          }
          product {
            _id
            picture {
              _id
              key
            }
          }
          picture {
            _id
            key
          }
        }
        isExcludeCalculateCommissionFee
        disallowedPickDates
        allowedPickDates
        marketplaceCommissionPercentage
        notificationPhoneNumbers
        cutOffTime
        scheduledDeliveryTimeRange {
          start
          end
        }
        childrenWarehouses {
          _id
          name
        }
        childrenWarehouseIdsRanking {
          warehouseId
          ranking
        }
        backgroundImages {
          _id
          key
          category
          userId
        }
        backgroundImageIds
        pickup
        dineIn
        scheduledDelivery
        delivery
        partnerName
        users {
          _id
          email
        }
        addressOne
        addressTwo
        phoneNumber
        city
        state
        zip
        region
        squareLocationId
        warehouseType
        location {
          coordinates
        }
        volume
        warehouseType
        thirdParty
        hours {
          day
          start
          end
        }
        deleted
        isParent
        commissionPercentage
        deliveryMaxDistance
        ownerId
        owner {
          email
          _id
        }
        connectedAccountId
        isShutDown
      }
    }
  }
`;

export const WAREHOUSES_CURRENT_USER = gql`
  query {
    warehouseCurrentUser {
      _id
      name
      addressOne
      warehouseType
      location {
        coordinates
      }
    }
  }
`;

export const WAREHOUSE_CREATE = gql`
  mutation (
    $name: String!
    $userIds: [String!]
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $state: String!
    $zip: String!
    $region: String!
    $squareLocationId: String
    $latitude: Float!
    $longitude: Float!
    $type: String!
    $volume: Int
    $thirdParty: ThirdPartyInput
    $hours: [HoursInput]
    $partnerName: String
    $phoneNumber: String!
    $dineIn: Boolean
    $scheduledDelivery: Boolean
    $delivery: Boolean
    $pickup: Boolean
    $alias: String
    $backgroundImageIds: [String]
    $isParent: Boolean
    $videoIds: [VideoIdInput]
    $notificationPhoneNumbers: [String]
    $cutOffTime: Float
    $scheduledDeliveryTimeRangeStart: Float
    $scheduledDeliveryTimeRangeEnd: Float
    $commissionPercentage: Float
    $deliveryMaxDistance: Float
    $announcement: AnnouncementInput
    $disallowedPickDates: [String]
    $allowedPickDates: [String]
    $marketplaceCommissionPercentage: Float
    $isExcludeCalculateCommissionFee: Boolean
    $titleZh: String
    $titleEn: String
    $ownerId: MongoID
    $isShutDown: Boolean
  ) {
    warehouseCreateOne(
      name: $name
      userIds: $userIds
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      state: $state
      zip: $zip
      region: $region
      squareLocationId: $squareLocationId
      latitude: $latitude
      longitude: $longitude
      type: $type
      volume: $volume
      thirdParty: $thirdParty
      hours: $hours
      partnerName: $partnerName
      phoneNumber: $phoneNumber
      dineIn: $dineIn
      scheduledDelivery: $scheduledDelivery
      delivery: $delivery
      pickup: $pickup
      alias: $alias
      backgroundImageIds: $backgroundImageIds
      isParent: $isParent
      videoIds: $videoIds
      notificationPhoneNumbers: $notificationPhoneNumbers
      cutOffTime: $cutOffTime
      scheduledDeliveryTimeRangeStart: $scheduledDeliveryTimeRangeStart
      scheduledDeliveryTimeRangeEnd: $scheduledDeliveryTimeRangeEnd
      commissionPercentage: $commissionPercentage
      deliveryMaxDistance: $deliveryMaxDistance
      announcement: $announcement
      disallowedPickDates: $disallowedPickDates
      allowedPickDates: $allowedPickDates
      marketplaceCommissionPercentage: $marketplaceCommissionPercentage
      isExcludeCalculateCommissionFee: $isExcludeCalculateCommissionFee
      titleZh: $titleZh
      titleEn: $titleEn
      ownerId: $ownerId
      isShutDown: $isShutDown
    ) {
      _id
      name
      addressOne
    }
  }
`;

export const WAREHOUSE_DELETE = gql`
  mutation ($warehouseId: MongoID!) {
    warehouseDeletedOneAdmin(warehouseId: $warehouseId) {
      _id
    }
  }
`;

export const WAREHOUSE_RESTORE = gql`
  mutation ($warehouseId: MongoID!) {
    warehouseRestoreOneAdmin(warehouseId: $warehouseId) {
      _id
    }
  }
`;

export const WAREHOUSES_REPLENISH = gql`
  query ($search: String!) {
    warehouseMany(filter: { search: $search }) {
      _id
      name
    }
  }
`;

export const WAREHOUSES_NET_INVENTORY = gql`
  query ($input: MongoID!) {
    inventoryCurrentWarehouse(warehouseId: $input) {
      _id
      totalQuantity
    }
  }
`;

export const WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS = gql`
  query ($warehouseId: MongoID!, $variantIds: [MongoID]) {
    inventoryCurrentWarehouseWithVariantIds(
      warehouseId: $warehouseId
      variantIds: $variantIds
    ) {
      totalQuantity
      _id
    }
  }
`;

export const CUSTOMER_ORDER_REFUND = gql`
  mutation (
    $reason: String!
    $ID: MongoID!
    $amount: Float
    $doesRefundToBalance: Boolean
  ) {
    refundCustomerOrder(
      reason: $reason
      orderID: $ID
      amount: $amount
      doesRefundToBalance: $doesRefundToBalance
    )
  }
`;

export const WAREHOUSE_QUERY_ID = gql`
  query ($input: MongoID!) {
    warehouseById(_id: $input) {
      _id
      name
      zip
      isOpen
      warehouseType
    }
  }
`;

export const WAREHOUSE_MANY_NAME_ID = gql`
  query ($filter: FilterFindManyWarehouseInput) {
    warehouseMany(filter: $filter) {
      _id
      name
      warehouseType
    }
  }
`;

export const WAREHOUSE_PRODUCTS_QUERY_STORE = gql`
  query ($input: MongoID!) {
    warehouseById(_id: $input) {
      id
      _id
      alias
      backgroundImages {
        _id
        key
        category
        userId
      }
      backgroundImageIds
      name
      isOpen
      openTimeSlot {
        _id
        day
        start
        end
      }
      formattedOpenTimeSlot
      pickup
      scheduledDelivery
      delivery
      dineIn
      partnerName
      hours {
        start
        end
        day
      }
      addressOne
      addressTwo
      city
      state
      zip
      region
      squareLocationId
      warehouseType
      location {
        coordinates
      }
      volume
      warehouseType
      allowedVariantIds
      allowedStoreVariants {
        id
        _id
        name {
          zh
          en
        }
        description {
          zh
          en
        }
        remark
        remarkName {
          zh
          en
        }
        sku
        ranking
        pictureId
        listPrice
        servesNumber
        shape
        deleted
        picture {
          key
        }
        product {
          name {
            en
            zh
          }
          id
          unitNumber
          unit
          pictureId
          picture {
            _id
            key
          }
          category {
            _id
            id
            name
            title {
              zh
              en
            }
            ranking
            hashtags
          }
        }
      }
    }
  }
`;

export const WAREHOUSE_GET_CONNECT_URL = gql`
  mutation ($warehouseId: MongoID!) {
    warehouseGetConnectUrl(warehouseId: $warehouseId) {
      url
    }
  }
`;

export const WAREHOUSE_CONNECT_OAUTH = gql`
  mutation ($warehouseId: MongoID!, $code: String!, $state: String!) {
    warehouseConnectOAuth(
      warehouseId: $warehouseId
      code: $code
      state: $state
    ) {
      _id
    }
  }
`;

export const WAREHOUSE_REVOKE_CONNECT_OAUTH = gql`
  mutation ($warehouseId: MongoID!) {
    warehouseRevokeConnectOAuth(warehouseId: $warehouseId) {
      _id
    }
  }
`;
