import gql from 'graphql-tag';

export const ORDER_FRAGMENT = gql`
  fragment ORDER_FRAGMENT on Order {
    _id
    products {
      _id
      productId
      quantity
      name
      price
      isVariant
      variantId
    }
    userId
    user {
      email
      _id
      firstName
      lastName
    }
    operatorUserId
    operator {
      _id
      firstName
      lastName
    }
    discount {
      percentage
      amount
    }
    addressOne
    addressTwo
    adjustment
    city
    confirmed
    deliveryFee
    deliveryNotes
    finalized
    headcount
    invoiceId
    invoicePDF
    invoiceUrl
    mailedCount
    notes
    paid
    scheduleDate
    uncollectible
    refund
    void
    zip
    deleted
  }
`;

export const ORDER_CREATE = gql`
  mutation (
    $products: [OrderProductInput]!
    $scheduleDate: Float!
    $headcount: Int
    $notes: String
    $deliveryNotes: String
    $contactName: String!
    $contactPhoneNumber: String!
    $companyName: String!
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $zip: String!
    $state: String!
    $tipSelected: String!
    $tipPercentage: Float!
    $tipAmount: Float!
    $couponCode: String
  ) {
    orderCreateOne(
      products: $products
      scheduleDate: $scheduleDate
      headcount: $headcount
      deliveryNotes: $deliveryNotes
      notes: $notes
      contactName: $contactName
      contactPhoneNumber: $contactPhoneNumber
      companyName: $companyName
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      zip: $zip
      state: $state
      tipSelected: $tipSelected
      tipAmount: $tipAmount
      tipPercentage: $tipPercentage
      couponCode: $couponCode
    ) {
      _id
      userId
      taxRate
      deliveryFee
      products {
        _id
        name
        price
        quantity
      }
    }
  }
`;

export const ORDER_CREATE_ADMIN = gql`
  mutation (
    $companyId: MongoID!
    $products: [OrderProductInput]!
    $scheduleDate: Float!
    $headcount: Int
    $notes: String
    $deliveryNotes: String
    $contactName: String!
    $contactPhoneNumber: String!
    $companyName: String!
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $zip: String!
    $state: String!
    $discount: OrderDiscountPerAmountInput!
    $freeDelivery: Boolean!
    $adjustment: Float!
  ) {
    orderCreateOneAdmin(
      companyId: $companyId
      products: $products
      scheduleDate: $scheduleDate
      headcount: $headcount
      notes: $notes
      deliveryNotes: $deliveryNotes
      contactName: $contactName
      contactPhoneNumber: $contactPhoneNumber
      companyName: $companyName
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      zip: $zip
      state: $state
      discount: $discount
      freeDelivery: $freeDelivery
      adjustment: $adjustment
    ) {
      _id
      userId
      taxRate
      deliveryFee
      products {
        _id
        name
        price
        quantity
      }
    }
  }
`;

export const ORDER_QUERY = gql`
  query ($filter: FilterFindManyOrderInput) {
    orderMany(filter: $filter) {
      _id
    }
  }
`;

export const ORDER_MANY = gql`
  query ($filter: FilterFindManyOrderInput, $limit: Int) {
    orderMany(filter: $filter, limit: $limit) {
      ...ORDER_FRAGMENT
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ORDER_BY_ID = gql`
  query ($id: MongoID!) {
    orderById(_id: $id) {
      _id
      products {
        _id
        productId
        quantity
        name
        price
        isVariant
        variantId
      }
      userId
      user {
        _id
        firstName
        lastName
      }
      operatorUserId
      operator {
        _id
        firstName
        lastName
      }
      discount {
        percentage
        amount
      }
      addressOne
      addressTwo
      adjustment
      city
      confirmed
      contactName
      contactPhoneNumber
      deliveryFee
      deliveryNotes
      finalized
      headcount
      invoiceId
      invoicePDF
      invoiceUrl
      mailedCount
      notes
      paid
      scheduleDate
      subtotal
      taxRate
      uncollectible
      refund
      void
      zip
      deleted
    }
  }
`;

export const ORDER_PAGINATION_CURRENT_USER = gql`
  query (
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyOrderInput
    $filter: FilterFindManyOrderInput
  ) {
    orderPaginationCurrentUser(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        _id
        createdAt
        scheduleDate
        contactName
        contactPhoneNumber
        addressOne
        addressTwo
        city
        state
        zip
        confirmed
        finalized
        paid
        void
        refund
        uncollectible
        headcount
        invoiceUrl
        products {
          _id
          name
          price
          quantity
        }
        discount {
          percentage
          amount
        }
        subtotal
        taxRate
        deliveryFee
        notes
        deliveryNotes
        deleted
      }
    }
  }
`;

export const ORDER_PAGINATION_ADMIN = gql`
  query (
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyOrderInput
    $filter: FilterFindManyOrderInput
  ) {
    orderPaginationAdmin(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        _id
        userId
        createdAt
        scheduleDate
        contactName
        contactPhoneNumber
        addressOne
        addressTwo
        city
        state
        zip
        confirmed
        finalized
        paid
        void
        refund
        uncollectible
        subtotal
        taxRate
        headcount
        invoiceUrl
        discount {
          percentage
          amount
        }
        deliveryFee
        adjustment
        products {
          _id
          productId
          name
          price
          quantity
          isVariant
          variantId
        }
        notes
        deliveryNotes
        deleted
      }
    }
  }
`;

export const ORDER_DELETE = gql`
  mutation ($orderId: MongoID!) {
    orderDelete(orderId: $orderId) {
      _id
    }
  }
`;

export const ORDER_CONFIRM = gql`
  mutation ($orderId: String!) {
    orderConfirm(orderId: $orderId) {
      _id
    }
  }
`;

export const ORDER_FINALIZE = gql`
  mutation ($orderId: String!) {
    orderFinalize(orderId: $orderId) {
      _id
      invoiceUrl
    }
  }
`;

export const ORDER_MARK_VOID = gql`
  mutation ($orderId: MongoID!) {
    orderMarkVoid(orderId: $orderId) {
      _id
    }
  }
`;

export const ORDER_MARK_UNCOLLECTIBLE = gql`
  mutation ($orderId: MongoID!) {
    orderMarkUncollectible(orderId: $orderId) {
      _id
    }
  }
`;

export const ORDER_FETCH_DELIVERY_FEE = gql`
  mutation (
    $addressOne: String!
    $addressTwo: String
    $city: String!
    $state: String!
    $zip: String!
    $arrivalTime: String!
  ) {
    orderFetchDeliveryFee(
      addressOne: $addressOne
      addressTwo: $addressTwo
      city: $city
      state: $state
      zip: $zip
      arrivalTime: $arrivalTime
    ) {
      duration
      distance
      fee
    }
  }
`;

export const CATERING_EMAIL_REQUEST = gql`
  mutation (
    $companyName: String
    $name: String
    $address1: String
    $address2: String
    $city: String
    $zip: String
    $email: String!
    $phone: String
    $ext: String
    $date: String
    $time: String
    $headcount: String
    $notes: String
    $secret: String!
  ) {
    cateringCreateInformationEmail(
      companyName: $companyName
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      zip: $zip
      email: $email
      phone: $phone
      ext: $ext
      date: $date
      time: $time
      headcount: $headcount
      notes: $notes
      secret: $secret
    ) {
      status
    }
  }
`;
