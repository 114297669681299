import gql from 'graphql-tag';

export const CONFIGURATION_FIND_ONE = gql`
  query ($filter: FilterFindOneConfigurationInput!) {
    configurationOne(filter: $filter) {
      _id
      name
      isCatering
      donations {
        _id
        amount
        date
        link
        description {
          zh
          en
        }
      }
      donationCategoryLink
      cateringConfiguration {
        _id
        notificationEmails
        notificationPhoneNumbers
        notificationContent
        deliveryFee {
          mile
          minute
        }
      }
      isCustomerOrderConfiguration
      customerOrderConfiguration {
        _id
        isEnableTransferMoneyToWarehouseConnectedAccount
        fixLocationReminderMessage
        fixLocationAndWarehouseMessage
        estimatedMessage
        deliveredMessage
        labelInformationFontSize
        labelInformation
        marketNotices {
          _id
          notice {
            _id
            en
            zh
          }
          ranking
        }
      }
      isProductsRanking
      isProductConfiguration
      productsRankingConfiguration {
        _id
        productIds
      }
      isScheduledDeliveryConfiguration
      scheduledDeliveryConfiguration {
        _id
        currentMode
        minimumAmount
        maxDistance
        isTurnOnMaxDistanceForAllOfWarehouse
        distance {
          _id
          minMile
          maxMile
          flatFee
        }
        duration {
          mile
          minute
        }
        marketMinimumAmountForShippingFree
        marketShippingFee
      }
      isPointConfiguration
      pointConfiguration {
        _id
        isTurnOn
        exchangeRatePerDollar
      }
      isReferConfiguration
      referConfiguration {
        _id
        isTurnOn
        referRatePerOrder
        receivedReferRatePerOrder
        referralLevel {
          _id
          minSales
          maxSales
          referralCoefficient
        }
        isEnableWaitingOrderCompletelyFinished
        waitingHoursWhenDelivered
      }
      isGiftCardFreeVariantConfiguration
      giftCardFreeVariantConfiguration {
        _id
        couponLevels {
          _id
          startDate
          endDate
          discountName {
            _id
            zh
            en
          }
          freeVariantId
          cardValue
        }
        emailCouponNote
      }
      contactConfiguration {
        _id
        adsPhoneNumberList
      }
      isDoubleElevenConfiguration
      doubleElevenCouponConfiguration {
        _id
        couponLevels {
          _id
          startDate
          endDate
          couponValue
          cardValue
          minSpendingCriteria
          numCoupons
          discountName {
            _id
            zh
            en
          }
          dayOrMonthLimitation
          countLimitation
          defaultCouponMonthLimitation
        }
        emailCouponNote
        redeemGiftCardInstantly
      }
      deliveryZipCodes
      disableShippingDate
      words
      externalZipCodes {
        _id
        day
        zipCode
        areaName
      }
    }
  }
`;

export const CONFIGURATION_SYNC = gql`
  query ($filter: FilterFindOneConfigurationInput!) {
    configurationOne(filter: $filter) {
      legacyCustomerOrderConfiguration
    }
  }
`;

export const CONFIGURATION_UPDATE_CATERING = gql`
  mutation (
    $emails: [String]
    $phoneNumbers: [String]
    $content: String!
    $mile: Float!
    $minute: Float!
  ) {
    configurationUpdateCatering(
      emails: $emails
      phoneNumbers: $phoneNumbers
      content: $content
      mile: $mile
      minute: $minute
    ) {
      _id
      name
      isCatering
      isProductConfiguration
      cateringConfiguration {
        _id
        notificationEmails
        notificationPhoneNumbers
        notificationContent
      }
      words
    }
  }
`;

export const CONFIGURATION_UPDATE_PRODUCTS_RANKING = gql`
  mutation ($productIds: [String]) {
    configurationUpdateProductsRanking(productIds: $productIds) {
      _id
      name
      isProductsRanking
      productsRankingConfiguration {
        _id
        productIds
      }
    }
  }
`;

export const CONFIGURATION_CREATE_ONE = gql`
  mutation ($input: CreateOneConfigurationInput!) {
    configurationCreateOne(input: { record: $input }) {
      record {
        _id
        words
      }
    }
  }
`;

export const CONFIGURATION_UPDATE_ONE = gql`
  mutation ($input: UpdateOneConfigurationInput!, $id: MongoID!) {
    configurationUpdateOne(input: { record: $input, filter: { _id: $id } }) {
      record {
        _id
        words
      }
    }
  }
`;

export const WECHAT_TOKEN = gql`
  query ($url: String!) {
    configurationWechatToken(url: $url) {
      appId
      timestamp
      nonceStr
      signature
    }
  }
`;

export const SHORT_URL = gql`
  query ($url: String!) {
    configurationShortUrl(url: $url) {
      shortUrl
    }
  }
`;
