/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import Icon, {
//   CheckCircleOutlined,
//   CloseCircleOutlined,
// } from '@ant-design/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import * as actions from '../../../../store/actions';
import AddressSelection from '../AddressSelection/AddressSelection';
import StepBar from '../StepBar/StepBar';
// import { ReactComponent as addressPinIcon } from '../../../../assets/images/icons/address-pin.svg';
import { CHECK_ZIP_CODE_AVAILABILITY } from '../../../../api/address';
import { apolloClient } from '../../../../util';

export class Shipping extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedAddress: null, // id only
      selectedAddressDetail: null,
      // selectedShippingTime: null,
      // datePicker
      // shippingDate: null,
      selectedShippingDate: null,
      lockDatePicker: false,
      isZipCodeValid: null,
      // mobileCalendarVisibility: false,
      // disableDate: [],
      // onlyAllowedDate: null,
      // onlyAllowedWeekDay: null,
      // counter: 0,
    };
  }

  componentDidMount = async () => {
    // check mode for back
    const {
      history: { location },
      preselectPickupInfo,
    } = this.props;

    if (!location.state || !location.state.edit) {
      if (preselectPickupInfo) {
        await this.handleSelectAddress(preselectPickupInfo);
      }
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleSelectAddress = async (node) => {
    const {
      updateOneUserAttribute,
      currentShoppingMode,
      currentAddressType,
      history,
      getCurrentWarehouseCart,
      updateWarehouseCart,
    } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    console.log('You selected Node:', node);
    await this.setState({
      selectedAddress: node._id,
      selectedAddressDetail: node,
    });
    // await this.setState({ addressSuccess: true });
    // await this.getCurrentStep();
    if (node.selectedDate) {
      const { selectedDate } = node;
      // ========= add del
      if (currentShoppingMode === 'delivery') {
        const date = moment(selectedDate);
        if (date) {
          date.set({ h: 18, m: 0, s: 0 });
          shoppingCart.deliveryTime = date.valueOf();
          await updateWarehouseCart(shoppingCart);
        }
      }
      await this.setState({
        lockDatePicker: true,
        selectedShippingDate: moment(selectedDate).format('YYYY-MM-DD'),
      });
    }
    let onlyAllowedWeekDay;
    if (node.onlyAllowedWeekDay) {
      onlyAllowedWeekDay = node.onlyAllowedWeekDay;
    }
    console.log('passed onlyAllowedWeekDay', onlyAllowedWeekDay);
    if (currentShoppingMode === 'delivery' && currentAddressType === 'pickup') {
      console.log('overwrite address', node);
      await updateOneUserAttribute(node, 'overWriteAddress');
    } else {
      await updateOneUserAttribute(null, 'overWriteAddress');
    }
    if (node.campaignContentId) {
      updateOneUserAttribute(
        node.campaignContentId,
        'currentCampaignContentId'
      );
    } else if (currentShoppingMode !== 'groupBuy') {
      updateOneUserAttribute(null, 'currentCampaignContentId');
    }
    // push to payment page
    const {
      selectedAddress,
      selectedAddressDetail,
      lockDatePicker,
      // onlyAllowedWeekDay,
      selectedShippingDate,
    } = this.state;
    history.push('payment', {
      selectedAddress,
      selectedAddressDetail,
      lockDatePicker,
      selectedShippingDate,
      onlyAllowedWeekDay,
    });
  };

  handleCheckZipCode = async (zipCode) => {
    if (!zipCode) {
      return;
    }
    try {
      const {
        data: { checkZipCodeAvailability },
      } = await apolloClient.query({
        query: CHECK_ZIP_CODE_AVAILABILITY,
        variables: { zipCode },
        fetchPolicy: 'network-only',
      });
      this.setState({
        isZipCodeValid: checkZipCodeAvailability,
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      currentShoppingMode,
      onlyAllowedDate,
      warehouseDeliveryMode,
      language,
    } = this.props;
    // const { onlyAllowedDate } = this.state;
    const { isZipCodeValid } = this.state;
    return (
      <div
        css={css`
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
        `}
      >
        <StepBar currentStep={0} />
        {/* <div
          style={{
            textAlign: 'center',
            margin: '20px auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1',
            }}
          >
            <div>
              <Input
                allowClear
                type="number"
                onInput={(e) => {
                  if (e.target.value.length > 5) {
                    e.target.value = e.target.value.slice(0, 5);
                  }
                }}
                prefix={
                  <Icon
                    component={addressPinIcon}
                    style={{ fontSize: '25px' }}
                  />
                }
                placeholder={
                  language === 'en' ? 'Enter zip Code' : '请输入邮编'
                }
                onChange={(e) => {
                  if (e.target.value.length >= 5) {
                    this.handleCheckZipCode(e.target.value);
                  } else {
                    this.setState({ isZipCodeValid: null });
                  }
                }}
              />
            </div>
            {isZipCodeValid === true && (
              <CheckCircleOutlined
                style={{
                  fontSize: '23px',
                  paddingLeft: '10px',
                  color: 'green',
                }}
              />
            )}
            {isZipCodeValid === false && (
              <CloseCircleOutlined
                style={{ fontSize: '23px', paddingLeft: '10px', color: 'red' }}
              />
            )}
          </div>
          <div style={{ paddingTop: '10px' }}>
            * <FormattedMessage id="zipCodeTip" />
          </div>
        </div> */}

        {onlyAllowedDate && onlyAllowedDate.length > 0 && (
          <div css={{ maxWidth: '500px', margin: '0 auto' }}>
            <Alert
              message={
                <div>
                  <FormattedMessage id="payment.currentAvailableDate" />
                  <div>
                    {onlyAllowedDate.map((date) => {
                      const today = moment();
                      if (
                        today.diff(moment(date)) <= 0 &&
                        today.isSame(moment(date.trim()), 'day') === false
                      ) {
                        return (
                          <span key={date}>
                            {language === 'zh'
                              ? moment(date)
                                  .locale('zh-cn')
                                  .format('MM-DD [(]dddd[) ] ')
                              : moment(date).format('MM-DD [(]dddd[) ] ')}
                          </span>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              }
              type="success"
            />
          </div>
        )}
        <AddressSelection
          currentShoppingMode={currentShoppingMode}
          warehouseDeliveryMode={warehouseDeliveryMode}
          updating={false}
          handleSelectAddress={this.handleSelectAddress}
          onlyAllowedDate={onlyAllowedDate}
          isZipCodeValid={isZipCodeValid}
        />
      </div>
    );
  }
}

Shipping.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  currentShoppingMode: PropTypes.string.isRequired,
  currentAddressType: PropTypes.string.isRequired,
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deliveryTime: PropTypes.number,
    discountCode: PropTypes.string,
  }),
  currentWarehouseCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  language: PropTypes.string.isRequired,
  updateOneUserAttribute: PropTypes.func.isRequired,
  groupBuyInfo: PropTypes.shape({}),
  preselectPickupInfo: PropTypes.shape({}),
  history: ReactRouterPropTypes.history.isRequired,
  onlyAllowedDate: PropTypes.arrayOf(PropTypes.string),
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  warehouseDeliveryMode: PropTypes.bool,
};

Shipping.defaultProps = {
  shoppingCart: {
    productList: [],
    boxList: [],
    deliveryTime: null,
  },
  currentWarehouseCart: {
    productList: [],
  },
  groupBuyInfo: null,
  preselectPickupInfo: null,
  onlyAllowedDate: null,
  warehouseDeliveryMode: null,
};

const mapStateToProps = (state) => ({
  shoppingCart: state.cart.shoppingCart,
  currentWarehouseCart: state.cart.currentWarehouseCart,
  items: state.cart.items,
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  currentAddressType: state.user.currentAddressType,
  groupBuyInfo: state.user.groupBuyInfo,
  preselectPickupInfo: state.user.preselectPickupInfo,
  userId: state.user.userId,
  taxRate: state.warehouse.taxRate,
});

const mapDispatchToProps = (dispatch) => ({
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
  addItem: (item) => dispatch(actions.addItem(item)),
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
  overWriteCallBack: (callback) =>
    dispatch(actions.overWriteCallBack(callback)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Shipping))
);
