import * as actionTypes from './actionTypes';

export const updateColumnsState =
  (tableName, columnsStateMap) => (dispatch) => {
    dispatch({
      type: actionTypes.TABLE_COLUMNS_STATE_UPDATE,
      columnsStateMap,
      tableName,
    });
  };

export const resetAllColumnsStates = (dispatch) => {
  dispatch({ type: actionTypes.TABLE_RESET_ALL_COLUMNS_STATES });
};
