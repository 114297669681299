/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { Col, Row, Select, Tag, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import { WarningOutlined } from '@ant-design/icons';
import { BUCKET_URL, BUCKET_CDN_URL } from '../../../config';
import { colorGenerator, displayDescription } from '../../../util';

const { Option } = Select;

export class CateringProductModal extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    // console.log(props);
    // this.state = {
    //   selectedDisplayMode: 'picture',
    // };
  }

  // onRadioChange = (e) => {
  //   console.log(`radio checked:${e.target.value}`);
  //   this.setState({ selectedDisplayMode: e.target.value });
  // };

  render() {
    const {
      selectedProduct,
      selectedProductVariant,
      selectedProductQuantity,
      handleRenderIngredients,
      handleChangeVariant,
      handleSelectProductQuantityChange,
      handleAddProductToCart,
      handleCloseProductModal,
      language,
    } = this.props;
    // const { selectedDisplayMode } = this.state;
    const pictureRender = () => {
      return selectedProduct.picture !== null ? (
        <img
          style={{
            // objectFit: 'cover',
            width: '100%',
            // height: '300px',
          }}
          src={BUCKET_URL + selectedProduct.picture.key}
          alt="Bao Product"
        />
      ) : (
        <img
          style={{
            // objectFit: 'cover',
            width: '100%',
            // height: '300px',
          }}
          src={`${BUCKET_CDN_URL}media/products/tasting_%E7%A4%BC%E7%9B%92.jpg`}
          alt="Bao Product"
        />
      );
    };

    // const videoRender = () => {
    //   return (
    //     selectedProduct.variants.length > 0 && (
    //       <VideoBanner videos={selectedProduct.variants} containerWidth="auto" />
    //     )
    //   );
    // };
    return (
      <div css={{ marginTop: '20px' }}>
        {selectedProduct ? (
          <React.Fragment>
            <Row css={{}}>
              <Col css={{ width: '100%', position: 'relative' }}>
                {/* <div css={{ textAlign: 'center' }}>
                  <Radio.Group
                    onChange={this.onRadioChange}
                    defaultValue="picture"
                  >
                    <Radio.Button value="picture">Picture</Radio.Button>
                    <Radio.Button value="video">Video</Radio.Button>
                  </Radio.Group>
                </div> */}
                {pictureRender()}
                {/* {selectedDisplayMode === 'picture'
                  ? pictureRender()
                  : videoRender()} */}
                {selectedProduct.nutritionFacts && (
                  <div
                    css={{
                      position: 'absolute',
                      height: '80px',
                      width: '80px',
                      bottom: '-40px',
                      borderRadius: '50%',
                      right: '20px',
                      backgroundColor: '#F51D2C',
                      boxShadow: '2px 8px 24px rgba(245, 29, 44, 0.25)',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                    }}
                  >
                    <div style={{ fontSize: '24px', fontWeight: '500' }}>
                      {selectedProduct.nutritionFacts
                        .split('\n')[0]
                        .split(',')[1]
                        ?.replace('cal', '')}
                    </div>
                    <div style={{ fontSize: '12px', fontWeight: '400' }}>
                      {
                        selectedProduct.nutritionFacts
                          .split('\n')[0]
                          .split(',')[0]
                      }
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row
              style={{ fontSize: '23px', fontWeight: '900', marginTop: '24px' }}
            >
              <Col>{selectedProduct.name[language].replace(/\(.+\)/, '')}</Col>
            </Row>
            <Row>
              {selectedProduct.variants.length > 0 ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  {selectedProductVariant ? (
                    <React.Fragment>
                      <div>
                        <span style={{ fontSize: '20px' }}>
                          {`$${selectedProductVariant.listPrice}`}
                        </span>
                        {`($${(
                          selectedProductVariant.listPrice /
                          selectedProductVariant.servesNumber
                        ).toFixed(2)}/person), serves ${
                          selectedProductVariant.servesNumber
                        }`}
                      </div>
                      <div style={{ color: '#6D6E71' }}>{`${
                        language === 'en' ? 'Net' : '净重'
                      }: ${
                        selectedProductVariant.numberOfUnits *
                        selectedProduct.unitNumber
                      } ${selectedProduct.unit}`}</div>
                    </React.Fragment>
                  ) : null}
                </div>
              ) : (
                <Row>
                  <Col>
                    <div>
                      <span
                        style={{ fontSize: '18px' }}
                      >{`$${selectedProduct.listPrice}`}</span>
                      {`($${(
                        selectedProduct.listPrice / selectedProduct.servesNumber
                      ).toFixed(2)}/person), serves ${
                        selectedProduct.servesNumber
                      }`}
                    </div>
                    <div>{`Net: ${selectedProduct.unitNumber} ${selectedProduct.unit}`}</div>
                  </Col>
                </Row>
              )}
            </Row>
            <Row
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px',
              }}
            >
              <div
                css={{
                  p: {
                    marginBottom: '0px',
                  },
                }}
              >
                {convert(
                  convertRawToHTML(
                    JSON.parse(
                      displayDescription(selectedProduct.description[language])
                    )
                  )
                )}
              </div>
            </Row>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 2 }}>
                <Row type="flex" gutter={10}>
                  <Row xs={24} sm={24} lg={12}>
                    {selectedProduct.ingredients &&
                    selectedProduct.ingredients !== '' ? (
                      <Row>
                        <Col>
                          <div
                            style={{
                              // textAlign: 'center',
                              fontSize: '17px',
                              fontWeight: '500',
                              marginTop: '10px',
                            }}
                          >
                            <FormattedMessage id="cateringProductModal.ingredients" />
                          </div>
                          <div style={{ overflowWrap: 'break-word' }}>
                            {handleRenderIngredients()}
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </Row>
                  {/* <Row xs={24} sm={24} lg={12}>
                    {selectedProduct.nutritionFacts &&
                    selectedProduct.nutritionFacts !== '' ? (
                      <Row>
                        <Col>
                          <Row
                            style={{
                              textAlign: 'center',
                              fontSize: '17px',
                              fontWeight: '500',
                              marginTop: '10px',
                            }}
                          >
                            Nutrition Facts
                          </Row>
                          <Row>
                            <Col
                              span={24}
                              key="per serving"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Amount per serving 1{' '}
                              {`${selectedProduct.unitShape} (${selectedProduct.unitNumber} ${selectedProduct.unit})`}
                            </Col>
                            {selectedProduct.nutritionFacts
                              .split('\n')
                              .map((fact) => {
                                if (
                                  !fact
                                    .toLowerCase()
                                    .match('amount per serving')
                                ) {
                                  return (
                                    <Row
                                      key={nanoid(6)}
                                      gutter={2}
                                      style={{ width: '100%' }}
                                    >
                                      <Col
                                        span={12}
                                        style={{ whiteSpace: 'nowrap' }}
                                      >
                                        {firstLetterToUpperCase(
                                          fact.split(',')[0]
                                        )}
                                      </Col>
                                      <Col
                                        style={{
                                          textAlign: 'right',
                                          whiteSpace: 'nowrap',
                                        }}
                                        span={12}
                                      >
                                        {firstLetterToUpperCase(
                                          fact.split(',')[1]
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                }
                                return null;
                              })}
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                  </Row> */}
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col>
                    {selectedProduct.hashtags.map((hashtag) => (
                      <Tag key={nanoid(6)} color={colorGenerator(hashtag)}>
                        {hashtag}
                      </Tag>
                    ))}
                  </Col>
                </Row>
                {selectedProduct.variants.length > 0 ? (
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <div style={{ color: '#1890FF', paddingBottom: '5px' }}>
                          <FormattedMessage id="cateringProductModal.selectSize" />
                          :{' '}
                        </div>
                        <div>
                          <Select
                            defaultValue={selectedProduct.variants[0]._id}
                            style={{ width: 120 }}
                            onChange={handleChangeVariant}
                          >
                            {selectedProduct.variants.map((variant) => (
                              <Option key={variant._id} value={variant._id}>
                                {variant.shape
                                  .split(/(?=[A-Z])/)
                                  .map(
                                    (s) =>
                                      s.charAt(0).toUpperCase() + s.substring(1)
                                  )
                                  .join(' ')}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div style={{ marginLeft: '20px' }}>
                        <div style={{ color: '#1890FF', paddingBottom: '5px' }}>
                          <FormattedMessage id="cateringProductModal.selectQuantity" />
                          :{' '}
                        </div>
                        <div>
                          <Select
                            value={selectedProductQuantity}
                            onChange={handleSelectProductQuantityChange}
                            style={{ width: 60 }}
                          >
                            {selectedProductVariant.minimumQuantity &&
                            selectedProductVariant.minimumQuantity > 1
                              ? [...Array(100).keys()]
                                  .slice(selectedProductVariant.minimumQuantity)
                                  .map((number) => (
                                    <Option key={number} value={number}>
                                      {number}
                                    </Option>
                                  ))
                              : [...Array(100).keys()]
                                  .slice(1)
                                  .map((number) => (
                                    <Option key={number} value={number}>
                                      {number}
                                    </Option>
                                  ))}
                          </Select>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {/* <Row>
                    <Col>{`$${selectedProduct.listPrice}`}</Col>
                    <div>{`${selectedProduct.unitNumber} ${selectedProduct.unit}`}</div>
                  </Row> */}
                    <div style={{ marginTop: '10px' }}>
                      <span style={{ color: '#1890FF' }}>
                        {' '}
                        <FormattedMessage id="cateringProductModal.selectQuantity" />
                        :
                      </span>
                      <Select
                        defaultValue={selectedProductVariant.minimumQuantity}
                        onChange={handleSelectProductQuantityChange}
                        style={{ width: 60 }}
                      >
                        {selectedProductVariant.minimumQuantity &&
                        selectedProductVariant.minimumQuantity > 1
                          ? [...Array(100).keys()]
                              .slice(selectedProductVariant.minimumQuantity)
                              .map((number) => (
                                <Option key={number} value={number}>
                                  {number}
                                </Option>
                              ))
                          : [...Array(100).keys()].slice(1).map((number) => (
                              <Option key={number} value={number}>
                                {number}
                              </Option>
                            ))}
                      </Select>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div
                style={{
                  flex: 1,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {selectedProduct.allergen && selectedProduct.allergen !== '' && (
                  <div
                    style={{
                      backgroundColor: '#FFFDE5',
                      border: '1px solid #FF9F48',
                      borderRadius: '25px',
                      padding: '10px',
                      minHeight: '120px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                      <WarningOutlined
                        style={{
                          color: '#FAAD14',
                          fontSize: '18px',
                          paddingRight: '8px',
                        }}
                      />
                      <span style={{ color: '#8C8C8C' }}>
                        <FormattedMessage id="cateringProductModal.allergen" />
                      </span>
                    </div>

                    <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 5,
                        color: '#8C8C8C',
                      }}
                    >{` ${selectedProduct.allergen}`}</div>
                  </div>
                )}
              </div>
            </div>
            {selectedProductVariant?.minimumQuantity > 0 && (
              <div style={{ paddingTop: '15px', color: '#F51D2C' }}>
                <FormattedMessage id="cateringProductModal.minimumQuantity" />:
                {` ${selectedProductVariant.minimumQuantity}`}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '15px',
              }}
            >
              <div style={{ flex: 1 }}>
                {selectedProduct.variants.length > 0 ? (
                  <div>
                    {language === 'en' ? 'Total' : '一共'}
                    {`  ${
                      selectedProductQuantity *
                      selectedProductVariant.numberOfUnits
                    } ${selectedProduct.unitShape} ${
                      selectedProductQuantity *
                      selectedProductVariant.numberOfUnits *
                      selectedProduct.unitNumber
                    } ${selectedProduct.unit},  ${
                      language === 'en' ? 'will serve' : '供给'
                    } ${
                      selectedProductQuantity *
                      selectedProductVariant.servesNumber
                    } ${language === 'en' ? 'people' : '人'}`}
                  </div>
                ) : (
                  <div>
                    {`  ${language === 'en' ? 'Total' : '一共'} ${
                      selectedProductQuantity * selectedProduct.unitNumber
                    } ${selectedProduct.unit}, ${
                      language === 'en' ? 'will serve' : '供给'
                    } ${
                      selectedProductQuantity * selectedProduct.servesNumber
                    } ${language === 'en' ? 'people' : '人'}`}
                  </div>
                )}
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={{
                    minWidth: '100px',
                    borderRadius: '25px',
                    borderColor: '#F51D2C',
                    color: '#F51D2C',
                    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
                  }}
                  onClick={() => {
                    handleCloseProductModal();
                  }}
                >
                  <FormattedMessage id="cateringProductModal.cancel" />
                </Button>
                <Button
                  style={{
                    minWidth: '100px',
                    borderRadius: '25px',
                    boxShadow: '0px 4px 12px 0px rgba(245, 29, 44, 0.25)',
                  }}
                  type="primary"
                  onClick={() => {
                    handleAddProductToCart();
                  }}
                >
                  <FormattedMessage id="cateringProductModal.addCart" />
                </Button>
              </div>
            </div>
            {/* {selectedProduct.allergen && selectedProduct.allergen !== '' ? (
              <Alert
                style={{ marginTop: '10px' }}
                message={`Allergen Information: ${selectedProduct.allergen}`}
                type="warning"
                showIcon
              />
            ) : null} */}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

CateringProductModal.defaultProps = {
  selectedProduct: null,
};

CateringProductModal.propTypes = {
  selectedProduct: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
    picture: PropTypes.shape({ _id: PropTypes.string, key: PropTypes.string }),
    name: PropTypes.shape({ en: PropTypes.string, zh: PropTypes.string }),
    description: PropTypes.shape({
      en: PropTypes.string,
      zh: PropTypes.string,
    }),
    remark: PropTypes.string,
    sku: PropTypes.string,
    categoryId: PropTypes.string,
    listPrice: PropTypes.number,
    unit: PropTypes.string,
    unitNumber: PropTypes.number,
    unitShape: PropTypes.string,
    servesNumber: PropTypes.number,
    hashtags: PropTypes.arrayOf(PropTypes.string),
    ranking: PropTypes.number,
    allergen: PropTypes.string,
    nutritionFacts: PropTypes.string,
    catering: PropTypes.bool,
    ingredients: PropTypes.string,
    videoIds: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedProductVariant: PropTypes.shape({
    variants: PropTypes.shape({ length: PropTypes.func, map: PropTypes.func }),
    picture: PropTypes.shape({ _id: PropTypes.string, key: PropTypes.string }),
    name: PropTypes.shape({ en: PropTypes.string, zh: PropTypes.string }),
    description: PropTypes.shape({
      en: PropTypes.string,
      zh: PropTypes.string,
    }),
    remark: PropTypes.string,
    sku: PropTypes.string,
    categoryId: PropTypes.string,
    listPrice: PropTypes.number,
    numberOfUnits: PropTypes.number,
    servesNumber: PropTypes.number,
    hashtags: PropTypes.string,
    ranking: PropTypes.number,
    allergen: PropTypes.string,
    nutritionFacts: PropTypes.string,
    catering: PropTypes.bool,
    ingredients: PropTypes.string,
    minimumQuantity: PropTypes.number,
  }).isRequired,
  selectedProductQuantity: PropTypes.number.isRequired,
  handleRenderIngredients: PropTypes.func.isRequired,
  handleChangeVariant: PropTypes.func.isRequired,
  handleSelectProductQuantityChange: PropTypes.func.isRequired,
  handleAddProductToCart: PropTypes.func.isRequired,
  handleCloseProductModal: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(CateringProductModal);
