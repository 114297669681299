import gql from 'graphql-tag';

export const CONTACT_FRAGMENT = gql`
  fragment CONTACT_FRAGMENT on CONTACT {
    firstName
    lastName
    phoneNumber
    isSubscriber
  }
`;

export const CONTACT_SYNC = gql`
  mutation {
    syncContacts
  }
`;

export const CONTACT_SEND_MESSAGE = gql`
  mutation (
    $sendType: String!
    $messageContents: [String]
    $messageZhContents: [String]
    $phoneNumberList: String
  ) {
    sendSMSToContacts(
      sendType: $sendType
      messageContents: $messageContents
      messageZhContents: $messageZhContents
      phoneNumberList: $phoneNumberList
    )
  }
`;

export const CONTACT_COUNT = gql`
  query {
    contactCount
  }
`;

export const CONTACT_OPENED_HISTORY = gql`
  mutation ($hashtag: String!) {
    updateContactOpenedHistory(hashtag: $hashtag)
  }
`;
