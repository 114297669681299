/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Button } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

export class BoxDetail extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const {
      collection,
      variants,
      language,
      handleIncrement,
      handleDecrement,
      onOkBoxModal,
      onCancelBoxModal,
      handleDeleteCollection,
      boxMaxLimit,
    } = this.props;

    let totalCount = 0;

    variants.forEach((variant) => {
      totalCount += variant.count;
    });

    const renderListPrice = (variant) => {
      console.log(collection);
      if (
        collection.container.discountPercentage > 1 ||
        collection.container.discountPercentage < 1
      ) {
        return (
          <div>
            $
            {(
              variant.count *
              variant.listPrice *
              collection.container.discountPercentage
            ).toFixed(2)}
          </div>
        );
      }
      return <div>${(variant.count * variant.listPrice).toFixed(2)}</div>;
    };

    return (
      <div>
        {collection ? (
          <React.Fragment>
            <Row
              type="flex"
              justify="center"
              style={{ fontSize: 20, fontWeight: 'bold' }}
            >
              {totalCount !== boxMaxLimit ? (
                <FormattedMessage id="isNotFull" />
              ) : (
                <React.Fragment>
                  <FormattedMessage id="currentBox" />
                  {language === 'zh' ? collection.name.zh : collection.name.en}
                  <FormattedMessage id="isFull" />
                </React.Fragment>
              )}
            </Row>
            {variants.map((variant) => {
              return (
                <div
                  css={{
                    margin: '5px',
                    display: 'flex',
                    border: '1px solid #e8e8e8',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    '& .dish-picture': {
                      height: '80px',
                      width: '80px',
                      objectFit: 'cover',
                      '& img': {
                        height: '80px',
                        width: '80px',
                        objectFit: 'cover',
                        borderRadius: '5px',
                      },
                    },
                    '& .dish-details': {
                      padding: '5px 15px',
                      width: '100%',
                    },
                    '& .dish-title': {
                      fontSize: '17px',
                      fontWeight: '400',
                    },
                  }}
                  key={variant.id}
                >
                  <div className="dish-picture">
                    <img src={variant.imgUrl} alt="dish" />
                  </div>
                  <div className="dish-details">
                    <div className="dish-title">{variant.name[language]}</div>
                    <div>
                      {' '}
                      <div
                        css={{
                          '& .dish-price': {
                            fontSize: '16px',
                            display: 'inline-block',
                          },
                          '& .dish-functional-block': {
                            display: 'inline-block',
                            float: 'right',
                          },
                          '& .dish-increment': {
                            userSelect: 'none',
                            display: 'inline-block',
                            width: '30px',
                            height: '30px',
                            border: '1px solid #e8e8e8',
                            backgroundColor: '#ff4949',
                            borderRadius: '5px',
                            textAlign: 'center',
                            fontSize: '20px',
                            color: 'white',
                            cursor: 'pointer',
                          },
                          '& .dish-decrement': {
                            userSelect: 'none',
                            display: 'inline-block',
                            width: '30px',
                            height: '30px',
                            border: '1px solid #e8e8e8',
                            backgroundColor: '#ff4949',
                            borderRadius: '5px',
                            textAlign: 'center',
                            fontSize: '20px',
                            color: 'white',
                            cursor: 'pointer',
                          },
                          '& .dish-counter': {
                            textAlign: 'center',
                            display: 'inline-block',
                            fontSize: '20px',
                            margin: '0 5px',
                            width: '30px',
                            height: '30px',
                            border: '1px solid #e8e8e8',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <div className="dish-price">
                          {renderListPrice(variant)}
                          {/* ${(variant.count * variant.listPrice).toFixed(2)} */}
                        </div>
                        <div className="dish-functional-block">
                          <div
                            className="dish-decrement"
                            role="presentation"
                            onClick={() => {
                              handleDecrement(variant.id);
                            }}
                          >
                            <MinusOutlined />
                          </div>
                          <div className="dish-counter">{variant.count}</div>
                          <div
                            className="dish-increment"
                            role="presentation"
                            onClick={() => {
                              handleIncrement(variant.id);
                            }}
                          >
                            <PlusOutlined />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div css={{ marginTop: '20px', textAlign: 'right' }}>
              {totalCount < boxMaxLimit && (
                <Button
                  key="back"
                  onClick={() => {
                    onCancelBoxModal();
                  }}
                >
                  <FormattedMessage id="addMore" />
                </Button>
              )}
              <Button
                onClick={() => {
                  handleDeleteCollection();
                }}
                css={{ margin: '0 10px' }}
              >
                <FormattedMessage id="deleteCollection" />
              </Button>
              {totalCount === boxMaxLimit ? (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    onOkBoxModal();
                  }}
                >
                  <FormattedMessage id="addToShopping" />
                </Button>
              ) : null}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

BoxDetail.defaultProps = {
  collection: null,
  boxMaxLimit: 9,
};

BoxDetail.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  onOkBoxModal: PropTypes.func.isRequired,
  onCancelBoxModal: PropTypes.func.isRequired,
  handleDeleteCollection: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  collection: PropTypes.shape({
    name: PropTypes.shape({
      zh: PropTypes.string,
      en: PropTypes.string,
    }),
    container: PropTypes.shape({
      discountPercentage: PropTypes.number,
    }),
  }),
  variants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  boxMaxLimit: PropTypes.number,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(injectIntl(BoxDetail));
