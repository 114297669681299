import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as actions from '../../store/actions';
import { getCustomerPath } from '../../util';

export class Logout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirectToCustomer: false,
      redirectToFoundation: false,
    };
  }

  componentDidMount = async () => {
    const {
      location,
      updateShoppingCart,
      updateWarehouseCart,
      updateOneWarehouseAttribute,
    } = this.props;
    updateOneWarehouseAttribute(null, 'manageWarehouse');
    console.log(location);
    if (location.state && location.state.from === 'customer') {
      await updateShoppingCart({
        productList: [],
        boxList: [],
      });
      const empty = {
        productList: [],
        boxList: [],
      };
      await updateWarehouseCart(empty);
      console.log(getCustomerPath(''));
      this.setState({ redirectToCustomer: true });
    } else {
      this.setState({ redirectToFoundation: true });
    }
  };

  componentWillUnmount = async () => {
    const { logout, userUpdate } = this.props;
    await sessionStorage.clear();
    logout();
    userUpdate();
  };

  handleClearUser = () => {};

  render() {
    const { redirectToCustomer, redirectToFoundation } = this.state;
    const url = getCustomerPath('');
    return (
      <div>
        {redirectToCustomer ? <Redirect to={url} /> : null}
        {redirectToFoundation ? <Redirect to="/" /> : null}
      </div>
    );
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  userUpdate: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  updateShoppingCart: PropTypes.func.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  updateOneWarehouseAttribute: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(actions.logout());
  },
  userUpdate: () => {
    dispatch(actions.userUpdate(null));
  },
  updateShoppingCart: (shoppingCart) =>
    dispatch(actions.updateShoppingCart(shoppingCart)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  updateOneWarehouseAttribute: (input, attributeName) =>
    dispatch(actions.updateOneWarehouseAttribute(input, attributeName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
