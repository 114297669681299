import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';
import { isMobile } from 'react-device-detect';
import { apolloClient } from '../../../util';
import { USER_FM_REDEEM } from '../../../api/user';
import { getCurrentUser } from '../../../store/actions/user';

function NewUserFMAdsModal() {
  const isFarmerMarketNewUser = useSelector(
    (state) => state.user.isFarmerMarketNewUser
  );
  const language = useSelector((state) => state.language.lang);
  const userCreatedAt = useSelector((state) => state.user.createdAt);
  const [showModal, setShowModal] = useState(false);
  const [showRedeemedModal, setShowRedeemedModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userCreatedAt && moment().diff(moment(userCreatedAt), 'days') === 0) {
      setShowModal(isFarmerMarketNewUser);
    } else {
      setShowModal(false);
    }
  }, [isFarmerMarketNewUser]);

  const handleRedeem = async () => {
    await apolloClient.mutate({
      mutation: USER_FM_REDEEM,
    });
    dispatch(getCurrentUser());
  };

  return (
    <div>
      <Modal
        visible={showModal}
        footer={null}
        header={null}
        onCancel={() => setShowModal(false)}
        maskClosable={false}
      >
        <div
          style={{
            width: '100%',
            marginTop: '15px',
          }}
        >
          {language === 'zh' ? (
            <img
              style={{
                width: '100%',
              }}
              src="https://sfo.cdn.bunbao.xyz/images/campaign/redeemBaoZh.jpg"
              alt="Redeem Bao"
            />
          ) : (
            <img
              style={{
                width: '100%',
              }}
              src="https://sfo.cdn.bunbao.xyz/images/campaign/redeemBaoEn.jpg"
              alt="Redeem Bao"
            />
          )}
        </div>
        <div
          style={
            isMobile
              ? { position: 'absolute', bottom: '30px', width: '100%' }
              : { position: 'absolute', bottom: '35px', width: '100%' }
          }
        >
          <Button
            size={isMobile ? 'small' : 'large'}
            shape="round"
            style={
              isMobile
                ? { float: 'left', left: '5%' }
                : { float: 'left', left: '10%' }
            }
            onClick={() => {
              // call api to redeem gift
              handleRedeem();
              setShowModal(false);
              setShowRedeemedModal(true);
            }}
          >
            {language === 'zh' ? '兑换' : 'Redeem'}
          </Button>
          <Button
            size={isMobile ? 'small' : 'large'}
            shape="round"
            onClick={() => {
              setShowModal(false);
            }}
            style={
              isMobile
                ? { float: 'right', right: '20%' }
                : { float: 'right', right: '20%' }
            }
          >
            {language === 'zh' ? '关闭' : 'Close'}
          </Button>
        </div>
      </Modal>
      <Modal
        visible={showRedeemedModal}
        footer={null}
        header={null}
        onCancel={() => setShowRedeemedModal(false)}
        maskClosable={false}
      >
        <div
          style={{
            width: '100%',
            marginTop: '15px',
          }}
        >
          {language === 'zh' ? (
            <img
              style={{
                width: '100%',
              }}
              src="https://sfo.cdn.bunbao.xyz/images/campaign/redeemedBaoZh.jpg"
              alt="Redeem Bao"
            />
          ) : (
            <img
              style={{
                width: '100%',
              }}
              src="https://sfo.cdn.bunbao.xyz/images/campaign/redeemedBaoEn.jpg"
              alt="Redeem Bao"
            />
          )}
        </div>
        <div
          style={
            isMobile
              ? { position: 'absolute', bottom: '30px', width: '100%' }
              : { position: 'absolute', bottom: '35px', width: '100%' }
          }
        >
          <div
            style={{
              width: '40%',
              marginRight: '35%',
              marginLeft: '35%',
            }}
          >
            <Button
              shape="round"
              size={isMobile ? 'small' : 'large'}
              onClick={() => {
                setShowRedeemedModal(false);
              }}
            >
              {language === 'zh' ? '关闭' : 'Close'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

NewUserFMAdsModal.propTypes = {};

export default NewUserFMAdsModal;
