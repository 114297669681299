import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import thunk from 'redux-thunk';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import * as localForage from 'localforage';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import cartReducer from './store/reducers/cart';
import warehouseReducer from './store/reducers/warehouse';
import languageReducer from './store/reducers/language';
import managerReducer from './store/reducers/manager';
import tableReducer from './store/reducers/table';
import { authTokenMiddleware, cartLastUpdateMiddleware } from './middleware';
// import { LOCAL_STORAGE_VERSION } from './config';

localForage.config({
  name: 'foundation',
  version: 1.0,
  storeName: 'crane',
});

let composeEnhancers = compose;
const composeWithDevToolsExtension =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
if (
  process.env.NODE_ENV === 'development' &&
  typeof composeWithDevToolsExtension === 'function'
) {
  composeEnhancers = composeWithDevToolsExtension;
}

// set user blacklist for callback
const saveSubsetBlacklistFilter = createBlacklistFilter('user', ['callback']);

// setting redux persisConfig
const persistConfig = {
  key: 'foundation',
  storage: localForage,
  transforms: [saveSubsetBlacklistFilter],
  version: 1,
  timeout: 0,
  migrate: createMigrate(
    {
      1: (state) => {
        console.log('Creating new state for migration 1');
        return {
          ...state,
          cart: {
            items: [], // for catering use
            shoppingCart: {
              // for bunbao use
              productList: [],
              boxList: [],
            },
            currentWarehouseCart: {
              productList: [],
              boxList: [],
            },
            allWarehouseCart: {},
            tipAmount: 0,
            tipPercentage: 0,
            tipSelected: 'none',
            cartLastUpdate: null,
          },
        };
      },
    },
    {
      2: (state) => {
        console.log('Creating new state for migration 2');
        return {
          ...state,
          cart: {
            items: [], // for catering use
            shoppingCart: {
              // for bunbao use
              productList: [],
              boxList: [],
            },
            currentWarehouseCart: {
              productList: [],
              boxList: [],
            },
            allWarehouseCart: {},
            tipAmount: 0,
            tipPercentage: 0,
            tipSelected: 'none',
            cartLastUpdate: null,
          },
        };
      },
    },
    { debug: false }
  ),
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  cart: cartReducer,
  language: languageReducer,
  warehouse: warehouseReducer,
  manager: managerReducer,
  table: tableReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [thunk, authTokenMiddleware, cartLastUpdateMiddleware];

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleWares))
);
const persistor = persistStore(store);

export { store, persistor };
