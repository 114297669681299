/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, message } from 'antd';
import * as actions from '../../../store/actions';
import { getCustomerPath, apolloClient } from '../../../util';
import { BANNER_FIND_MANY } from '../../../api/banner';
import { SUBSCRIBER_CREATE } from '../../../api/subscriber';
import { USER_FIND_ONE } from '../../../api/user';
import Desktop from './Desktop';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: true,
      slides: [],
      pending: false,
      showGoogleSignUpNotice: false,
      // isGuestPending: false,
    };
  }

  componentDidMount = async () => {
    console.log('login page fire');
    await this.handleFetchBanner();
    await this.setState({ loading: false });
  };

  handleFetchBanner = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'login' } },
      });
      // console.log(bannerFindMany);
      // sort banner
      const rawData = bannerFindMany;
      const slides = _.orderBy(rawData, ['ranking'], ['desc']);
      this.setState({ slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  responseGoogle = async (response) => {
    if (!response.error) {
      // try to auth
      const {
        oauth2,
        getCurrentUser,
        loginRedirectPage,
        history,
        isFarmerMarketUser,
      } = this.props;
      try {
        // first check user, does it exist our database?
        const responseFindUser = await apolloClient.query({
          query: USER_FIND_ONE,
          variables: { filter: { email: response.profileObj.email } },
          fetchPolicy: 'network-only',
        });
        if (responseFindUser.data.userOne) {
          await oauth2('google', response.profileObj.email, response.tokenId);
          try {
            await apolloClient.mutate({
              mutation: SUBSCRIBER_CREATE,
              variables: { email: response.profileObj.email },
            });
          } catch (error) {
            console.error(error);
          }
          await getCurrentUser();
          const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
          if (copyLink.length > 2) {
            // jump to last path
            history.push(copyLink);
            const { saveLinkForLogin } = this.props;
            // remove last path
            saveLinkForLogin('');
          }
          if (isFarmerMarketUser) {
            history.push(getCustomerPath('supermarket?special=true'));
          } else {
            history.push(getCustomerPath('supermarket'));
          }
        } else {
          this.setState({ showGoogleSignUpNotice: true });
          message.error('User does not exist, please sign up');
        }
      } catch (error) {
        message.error('Can not login, please try again');
      }
    } else {
      console.log(response);
    }
  };

  responseFacebook = async (response) => {
    console.log(response);
    const { accessToken, email } = response;
    const {
      oauth2,
      getCurrentUser,
      loginRedirectPage,
      history,
      isFarmerMarketUser,
    } = this.props;
    console.log(accessToken);
    try {
      // first check user, does it exist our database?
      const responseFindUser = await apolloClient.query({
        query: USER_FIND_ONE,
        variables: { filter: { email } },
        fetchPolicy: 'network-only',
      });
      if (responseFindUser.data.userOne) {
        // create token with Facebook
        await oauth2('facebook', email, accessToken);
        try {
          await apolloClient.mutate({
            mutation: SUBSCRIBER_CREATE,
            variables: { email },
          });
        } catch (error) {
          console.error(error);
        }
        await getCurrentUser();
        const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
        if (copyLink.length > 2) {
          // jump to last path
          history.push(copyLink);
          const { saveLinkForLogin } = this.props;
          // remove last path
          saveLinkForLogin('');
        }
        if (isFarmerMarketUser) {
          history.push(getCustomerPath('supermarket?special=true'));
        } else {
          history.push(getCustomerPath('supermarket'));
        }
      } else {
        this.setState({ showGoogleSignUpNotice: true });
        message.error('User does not exist, please sign up');
      }
    } catch (error) {
      console.error(error);
      message.error('Can not login, please try again');
    }
  };

  handleClickLoginButton = async (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
    } = this.props;
    await validateFields(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ pending: true });
      const {
        auth,
        getCurrentUser,
        history,
        loginRedirectPage,
        isFarmerMarketUser,
      } = this.props;
      const { email, password } = values;
      const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
      // try to login
      try {
        await auth(email, password);
        await getCurrentUser();
        if (copyLink.length > 2) {
          // jump to last path
          history.push(copyLink);
          const { saveLinkForLogin } = this.props;
          // remove last path
          saveLinkForLogin('');
        }
        if (isFarmerMarketUser) {
          history.push(getCustomerPath('supermarket?special=true'));
        } else {
          history.push(getCustomerPath('supermarket'));
        }
      } catch (error) {
        this.setState({ pending: false });
        message.error('Can not login, please try again');
      }
    });
  };

  handleLoginAsGuest = async () => {
    const {
      guestAuth,
      getCurrentUser,
      history,
      loginRedirectPage,
      isFarmerMarketUser,
    } = this.props;
    // this.setState({
    //   isGuestPending: true,
    // });
    const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
    // try to login
    try {
      await guestAuth();
      await getCurrentUser();
      if (copyLink.length > 2) {
        // jump to last path
        history.push(copyLink);
        const { saveLinkForLogin } = this.props;
        // remove last path
        saveLinkForLogin('');
      }
      // check fm user or not, then jump to supermarket menu
      if (isFarmerMarketUser) {
        history.push(getCustomerPath('supermarket?special=true'));
      } else {
        history.push(getCustomerPath('supermarket'));
      }
    } catch (error) {
      this.setState({ pending: false });
      message.error('Can not login, please try again');
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      language,
      intl,
      group,
    } = this.props;
    const { slides, pending, loading, showGoogleSignUpNotice } = this.state;
    const device = isMobile ? 'mobile' : 'desktop';

    return (
      <Row
        style={{
          minHeight: 'calc(100vh - 172px)',
        }}
        align="top"
        justify="center"
      >
        <Col>
          <Desktop
            intl={intl}
            pending={pending}
            language={language}
            loading={loading}
            responseGoogle={this.responseGoogle}
            responseFacebook={this.responseFacebook}
            handleClickLoginButton={this.handleClickLoginButton}
            slides={slides}
            device={device}
            group={group}
            showGoogleSignUpNotice={showGoogleSignUpNotice}
            getFieldDecorator={getFieldDecorator}
            handleLoginAsGuest={this.handleLoginAsGuest}
          />
        </Col>
      </Row>
    );
  }
}

Login.defaultProps = {
  group: null,
  isFarmerMarketUser: false,
};

Login.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  auth: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  loginRedirectPage: PropTypes.string.isRequired,
  saveLinkForLogin: PropTypes.func.isRequired,
  oauth2: PropTypes.func.isRequired,
  guestAuth: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  isFarmerMarketUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  loginRedirectPage: state.auth.loginRedirectPage,
  group: state.user.group,
  isFarmerMarketUser: state.user.isFarmerMarketUser,
});

const mapDispatchToProps = (dispatch) => ({
  auth: (email, password) => dispatch(actions.auth(email, password)),
  guestAuth: () => dispatch(actions.guestAuth()),
  getCurrentUser: () => {
    dispatch(actions.getCurrentUser());
  },
  saveLinkForLogin: (input) => dispatch(actions.saveLinkForLogin(input)),
  oauth2: (type, email, accessToken) =>
    dispatch(actions.oauth2(type, email, accessToken)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create({ name: 'customer_login_form' })(Login))
);
