/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  RiseOutlined,
} from '@ant-design/icons';

import {
  Tag,
  List,
  Menu,
  Button,
  Popover,
  Row,
  Alert,
  message,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

import { nanoid } from 'nanoid';
// import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import { CREATE_FAKE_ORDER } from '../../apiCanary/order';
import {
  colorGenerator,
  saleorClient,
  processOrderForSaleor,
} from '../../util';
import BaoziLoading from '../../assets/images/baozi.gif';
import redUser from '../../assets/images/icons/userRed.svg';
import grayUser from '../../assets/images/icons/userGray.svg';
import {
  addressFormatterOnlyAddress,
  addressFormatterOnlyName,
} from '../../helper/Formatter/Formatter';

export const shippingRuleValidation = async (shoppingList) => {
  const finalInput = processOrderForSaleor(shoppingList, '', null);
  // console.log('final input for draft order', finalInput);
  try {
    const { data } = await saleorClient.mutate({
      mutation: CREATE_FAKE_ORDER,
      variables: finalInput,
    });
    if (data && data.fakeOrderCreate) {
      return true;
    }
  } catch (error) {
    console.error(error);
    message.error('Error happened before checkout', 15);
    return false;
  }
  return false;
};

const openTimeSlotRender = (warehouse) => {
  const dateArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = moment().day();
  // console.log(warehouse.openTimeSlot);
  const menu = (
    <div css={{ margin: '5px 0' }}>
      {warehouse.openTimeSlot.map((element) => (
        <div
          key={element._id}
          style={{
            fontSize: '11px',
            lineHeight: '1.3',
            color:
              dateArray[today] === element.day ? 'red' : 'rgba(0, 0, 0, 0.65)',
          }}
        >
          <div style={{ width: '30px', display: 'inline-block' }}>
            {element.day}
          </div>
          <div style={{ width: '50px', display: 'inline-block' }}>
            {element.start}
          </div>
          <div style={{ width: '10px', display: 'inline-block' }}>-</div>
          <div style={{ width: '50px', display: 'inline-block' }}>
            {element.end}
          </div>
        </div>
      ))}
    </div>
  );
  return menu;
};

export const openTimeSlotRender2 = (openTimeSlot) => {
  let copy = openTimeSlot;
  if (!openTimeSlot) {
    copy = [];
  }
  const menu = (
    <div>
      {copy.map((element) => (
        <div key={nanoid(5)} style={{ fontSize: '12px', lineHeight: '1.3' }}>
          <div style={{ width: '30px', display: 'inline-block' }}>
            {element.day}
          </div>
          <div style={{ width: '50px', display: 'inline-block' }}>
            {element.start}
          </div>
          <div style={{ width: '10px', display: 'inline-block' }}>-</div>
          <div style={{ width: '50px', display: 'inline-block' }}>
            {element.end}
          </div>
        </div>
      ))}
    </div>
  );
  return menu;
};

export const openTimeSlotRender3 = (openTimeSlot = []) => {
  let menu = null;
  menu = (
    <div>
      {openTimeSlot.map((element) => (
        <div key={nanoid(5)} style={{ fontSize: '12px', lineHeight: '1.3' }}>
          {element}
        </div>
      ))}
    </div>
  );
  return menu;
};

const openClose = (warehouse) => {
  // show today open hours
  const today = moment().format('ddd');
  const openHours = [];
  warehouse.openTimeSlot.forEach((timeSlot) => {
    if (timeSlot.day === today) {
      openHours.push(timeSlot);
    }
  });
  return (
    <div>
      {openHours.map((element) => (
        <div key={element._id} style={{ fontSize: '12px', lineHeight: '1.3' }}>
          <div style={{ width: '30px', display: 'inline-block' }}>
            {element.day}
          </div>
          <div style={{ width: '50px', display: 'inline-block' }}>
            {element.start}
          </div>
          <div style={{ width: '10px', display: 'inline-block' }}>-</div>
          <div style={{ width: '50px', display: 'inline-block' }}>
            {element.end}
          </div>
        </div>
      ))}
      {
        <Tag
          color={
            warehouse.isOpen ? colorGenerator('Open') : colorGenerator('Stone')
          }
        >
          {warehouse.isOpen ? (
            <FormattedMessage id="open" />
          ) : (
            <FormattedMessage id="openTime" />
          )}
        </Tag>
      }
    </div>
  );
};

const openCloseMenu = (warehouse) => {
  // show today open hours
  const today = moment().format('ddd');
  const openHours = [];
  warehouse.openTimeSlot.forEach((timeSlot) => {
    if (timeSlot.day === today) {
      openHours.push(timeSlot);
    }
  });

  const menu = (
    <Menu>
      {openHours.map((element) => (
        <Menu.Item key={element._id}>
          <div
            key={element._id}
            style={{ fontSize: '12px', lineHeight: '1.3' }}
          >
            <div style={{ width: '30px', display: 'inline-block' }}>
              {element.day}
            </div>
            <div style={{ width: '50px', display: 'inline-block' }}>
              {element.start}
            </div>
            <div style={{ width: '10px', display: 'inline-block' }}>-</div>
            <div style={{ width: '50px', display: 'inline-block' }}>
              {element.end}
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Popover content={menu} placement="bottom" trigger="click">
      <Button style={{ marginLeft: '5px' }}>
        <FormattedMessage id="openTime" />
      </Button>
    </Popover>
  );
};

export const openHourDropDown = (timeArray) => {
  const openHours = timeArray || [];
  console.log('timeArray');
  const menu = (
    <Menu>
      {openHours.map((element) => (
        <Menu.Item key={element}>{element}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Popover content={menu} placement="bottom" trigger="click">
      <Button style={{ marginLeft: '5px' }}>
        <FormattedMessage id="openTime" />
      </Button>
    </Popover>
  );
};

const variantList = (variants, language = 'en') => {
  return (
    <List
      size="small"
      bordered
      dataSource={variants}
      renderItem={(item) => {
        if (item.quantity > 0) {
          return (
            <List.Item>{`${item.name[language]} ${item.quantity}`}</List.Item>
          );
        }
        return <React.Fragment />;
      }}
    />
  );
};

export const variantList2 = (variants, language) => {
  return (
    <List
      size="small"
      bordered
      dataSource={variants}
      renderItem={(item) => {
        if (item.count > 0) {
          return (
            <List.Item>{`${item.name[language]} ${
              item.count || item.quantity
            }`}</List.Item>
          );
        }
        return <React.Fragment />;
      }}
    />
  );
};

export const boxDisplayList = (variants, language, collection, intl) => {
  if (collection) {
    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="center"
          style={{ fontSize: 20, fontWeight: 'bold' }}
        >
          <FormattedMessage id="currentBox" />
          {` ${intl.formatMessage({ id: collection.name })} `}
          <FormattedMessage id="isFull" />
        </Row>

        <List
          size="small"
          bordered
          dataSource={variants}
          renderItem={(item) => {
            if (item.count > 0) {
              return (
                <List.Item>{`${item.name[language]} ${item.count}`}</List.Item>
              );
            }
            return <React.Fragment />;
          }}
        />
        <Alert
          message={intl.formatMessage({ id: 'cantNotChange' })}
          type="info"
        />
      </React.Fragment>
    );
  }
  return null;
};

export const loadings = (replaceMsg = null) => {
  return (
    <Row style={{ textAlign: 'center' }}>
      <Row>
        <img src={BaoziLoading} alt="loading" />
        <img src={BaoziLoading} alt="loading" />
        <img src={BaoziLoading} alt="loading" />
      </Row>
      <Row style={{ fontSize: '14px', marginTop: '20px' }}>
        {replaceMsg ? (
          <FormattedMessage id={replaceMsg} />
        ) : (
          <FormattedMessage id="loading" />
        )}
      </Row>
    </Row>
  );
};

export const successIcon = <CheckCircleTwoTone twoToneColor="#52c41a" />;

export const questionIcon = <ExclamationCircleTwoTone twoToneColor="red" />;

export const failedIcon = <CloseCircleTwoTone twoToneColor="#ee4540" />;

export const shippingRuleDisplay = (rule) => {
  return (
    <div>
      {rule.byDistance.map((singleRule, index) => (
        <div key={nanoid(5)}>
          {index === rule.byDistance.length - 1 ? (
            <div
              css={{ fontSize: '15px', lineHeight: '1.5', padding: '0 10px' }}
            >
              <RiseOutlined /> {singleRule.mile}-{rule.maxMile}miles: $
              {singleRule.minimumAmount}
            </div>
          ) : (
            <div
              css={{ fontSize: '15px', lineHeight: '1.5', padding: '0 10px' }}
            >
              <RiseOutlined />{' '}
              {`${singleRule.mile}-${rule.byDistance[index + 1].mile} miles: $${
                singleRule.minimumAmount
              }`}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const discountRuleDisplay = (rule) => {
  return (
    <Menu>
      {rule.map((singleRule) => (
        <Menu.Item key={nanoid(5)}>
          <div style={{ fontSize: '12px', lineHeight: '1.3' }}>
            <div style={{ width: '30px', display: 'inline-block' }}>
              {`${singleRule.participatorAmount} and more: ${
                100 - singleRule.discount.amount
              } %off`}
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const groupInfoBox = (element, joinInGroup = null) => {
  return (
    <div
      css={{
        width: isMobile ? '100%' : '30%',
        background: 'white',
        padding: '5px 10px',
      }}
      key={nanoid(5)}
    >
      <div
        css={{
          border: '1px solid #DF242F',
          padding: '10px',
          borderRadius: '5px',
          lineHeight: '2',
          '& .group-buy-name': {
            fontSize: '20px',
            textAlign: 'center',
            fontWeight: '600',
          },
          '& .group-buy-code': {
            fontSize: '18px',
            textAlign: 'center',
          },
          '& .group-buy-charge-time': {
            fontSize: '15px',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '2px',
            border: '1px solid #DF242F',
          },
          '& .group-buy-delivery-time': {
            fontSize: '15px',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '2px',
            border: '1px solid #DF242F',
          },
        }}
      >
        <div className="group-buy-name">
          <FormattedMessage id="groupName" />: {element.name}
        </div>

        <div className="group-buy-code">
          <FormattedMessage id="groupCode" />: {element.displayId}{' '}
          <CopyToClipboard
            text={element.displayId}
            onCopy={() => {
              // this.setState({
              //   copied: true,
              // });
            }}
          >
            <Button size="small" type="primary">
              <FormattedMessage id="copy" />
            </Button>
          </CopyToClipboard>
        </div>
        <div css={{ textAlign: 'center' }}>
          {/* <Progress
            percent={25 * element.participators.length}
            successPercent={25}
            status="active"
          /> */}
          <div>
            {element.participators.map(() => {
              return (
                <img
                  key={nanoid(5)}
                  css={{ width: '50px' }}
                  src={redUser}
                  alt="user icon"
                />
              );
            })}
            {Array(
              4 -
                (element.participators.length > 3
                  ? 3
                  : element.participators.length)
            )
              .fill(null)
              .map(() => {
                return (
                  <img
                    key={nanoid(5)}
                    css={{ width: '50px' }}
                    src={grayUser}
                    alt="user icon"
                  />
                );
              })}
          </div>
          <FormattedMessage id="groupParticipatorAmount" />:{' '}
          {element.participators.length}
        </div>

        <div>
          <FormattedMessage id="groupStatus" />:{' '}
          <FormattedMessage id={element.status} />
        </div>
        <div>
          <FormattedMessage id="deliveryAddress" />：
          {addressFormatterOnlyAddress(element.owner.oneOrder.address)}
        </div>
        <div>
          <FormattedMessage id="groupLeader" />:{' '}
          {addressFormatterOnlyName(element.owner.oneOrder.address)}
        </div>
        <div>
          <FormattedMessage id="groupNote2" />: {element.note}
        </div>

        <div className="group-buy-delivery-time">
          <FormattedMessage id="deliveryTime" />:{' '}
          {element.owner.oneOrder.deliveryTime
            ? moment(element.owner.oneOrder.deliveryTime).format(
                'YYYY-MM-DD HH:mm'
              )
            : null}
        </div>
        <div className="group-buy-charge-time">
          <FormattedMessage id="chargeTime" /> :
          {element.owner.oneOrder.deliveryTime
            ? moment(element.owner.oneOrder.deliveryTime - 129600000).format(
                'YYYY-MM-DD HH:mm'
              )
            : null}
        </div>
        {joinInGroup ? (
          <div>
            <Button
              type="primary"
              onClick={() => {
                joinInGroup(element);
              }}
            >
              <FormattedMessage id="nextStep" />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function ErrorCodeModal(props) {
  const { intl, error } = props;
  const translatedError = intl.formatMessage({ id: error });
  const msg = intl
    .formatMessage({ id: 'errorForGeneral' })
    .replace('[xx.error]', translatedError);
  Modal.warning({
    title: 'error',
    content: msg,
    onOk: () => {
      Modal.destroyAll();
    },
  });
  return null;
}

ErrorCodeModal.propTypes = {
  error: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
};
export default injectIntl(ErrorCodeModal);

// const mapStateToProps = (state) => ({
//   userId: state.user.userId,
//   language: state.language.lang,
// });

export { openTimeSlotRender, openClose, openCloseMenu, variantList };
