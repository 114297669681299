import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../../../assets/images/logo.png';

import './FoundationNav.css';

// const { Title } = Typography;
const { Header } = Layout;

export class FoundationNav extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { token } = this.props;
    return (
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          height: '70px',
          backgroundColor: 'white',
          boxShadow: '0 3px 16px hsla(0,0%,86.7%,0.2)',
          padding: '0 0',
        }}
      >
        <div className="homeLogo">
          <img src={logo} alt="BunBao Logo" />
        </div>
        <span
          style={{
            lineHeight: '1.50',
            fontWeight: '500',
            fontSize: '2em',
            margin: '10px 0 0 0',
            cssFloat: 'left',
            fontFamily: 'Calistoga',
            color: 'black',
          }}
        >
          Foundation
        </span>
        <Menu theme="light" mode="horizontal" style={{ lineHeight: '64px' }}>
          {process.env.NODE_ENV === 'development' && (
            <Menu.Item key="point">
              <Link to="/point" target="_blank">
                Point
              </Link>
            </Menu.Item>
          )}
          {process.env.NODE_ENV === 'development' && (
            <Menu.Item key="customer">
              <Link to="/customer" target="_blank">
                Customer
              </Link>
            </Menu.Item>
          )}
          {!token ? (
            <Menu.Item key="login">
              <Link to="/login">Login</Link>
            </Menu.Item>
          ) : null}
          {token ? (
            <Menu.Item key="dashboard">
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
          ) : null}
          {token ? (
            <Menu.Item key="logout">
              <Link to={{ pathname: '/Logout', state: { from: 'foundation' } }}>
                Logout
              </Link>
            </Menu.Item>
          ) : null}
        </Menu>
      </Header>
    );
  }
}

FoundationNav.defaultProps = {
  token: null,
};

FoundationNav.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps, null)(FoundationNav);
