import gql from 'graphql-tag';
import { CAMPAIGN_CUSTOMER_ORDER_FRAGMENT } from './customerOrder';

const CAMPAIGN_CONTENT_FRAGMENT = gql`
  fragment CampaignContentFullAttributes on CampaignContent {
    pickupWindow {
      _id
      start
      end
      day
    }
    location {
      coordinates
    }
    _id
    status
    displayId
    minimumDeliveryFee
    name
    note
    defaultAddressId
    defaultAddress {
      _id
      type
      companyName
      phoneNumber
      fullAddress
      lastName
      firstName
      addressOne
      addressTwo
      zipCode
      gateCode
      city
      state
    }
    pickupTimeSlotFormatted
    owner {
      _id
      user {
        _id
        email
      }
      oneOrder {
        _id
        deliveryTime
        address {
          _id
          lastName
          firstName
          addressOne
          addressTwo
          city
          zipCode
          state
          phoneNumber
        }
      }
    }
    startDate
    endDate
    official
    ownerId
    createdAt
    deleted
    prepareDay
    participators {
      _id
      saleorOrderId
      oneOrderId
      userId
      user {
        _id
        email
      }
      oneOrder {
        ...CAMPAIGN_CUSTOMER_ORDER_FRAGMENT
      }
    }
    name
    note
  }
  ${CAMPAIGN_CUSTOMER_ORDER_FRAGMENT}
`;

const CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT = gql`
  fragment CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT on CampaignContent {
    pickupWindow {
      _id
      start
      end
      day
    }
    location {
      coordinates
    }
    _id
    status
    displayId
    minimumDeliveryFee
    name
    note
    defaultAddressId
    defaultAddress {
      _id
      phoneNumber
      companyName
      fullAddress
      lastName
      firstName
      addressOne
      addressTwo
      zipCode
      gateCode
      city
      state
      type
    }
    pickupTimeSlotFormatted
    owner {
      user {
        _id
        email
      }
    }
    warehouseId
    warehouse {
      _id
      name
    }
    startDate
    endDate
    official
    ownerId
    createdAt
    deleted
    prepareDay
    participators {
      _id
      oneOrderId
      userId
      user {
        _id
        email
      }
    }
  }
`;

export const CAMPAIGN_CONTENT_FIX_LOCATION_FRAGMENT = gql`
  fragment CAMPAIGN_CONTENT_FIX_LOCATION_FRAGMENT on CampaignContent {
    pickupWindow {
      _id
      start
      end
      day
    }
    _id
    status
    name
    warehouseId
    defaultAddressId
    location {
      coordinates
    }
    defaultAddress {
      _id
      phoneNumber
      fullAddress
      companyName
      lastName
      firstName
      addressOne
      addressTwo
      zipCode
      gateCode
      city
      state
      type
    }
    pickupTimeSlotFormatted
    official
    deleted
    endDate
    prepareDay
    warehouse {
      _id
      name
      region
      title {
        _id
        en
        zh
      }
    }
  }
`;

export const FETCH_FIX_LOCATION_CAMPAIGN = gql`
  query ($filter: FilterFindManyCampaignInput) {
    campaignFindMany(filter: $filter) {
      name
      startDate
      endDate
      type
      _id
      deleted
      campaignContents {
        ...CAMPAIGN_CONTENT_FIX_LOCATION_FRAGMENT
      }
    }
  }
  ${CAMPAIGN_CONTENT_FIX_LOCATION_FRAGMENT}
`;

export const FETCH_CAMPAIGN = gql`
  query ($filter: FilterFindManyCampaignInput) {
    campaignFindMany(filter: $filter) {
      name
      startDate
      endDate
      type
      _id
      deleted
      discountRank {
        _id
        discountId
        participatorAmount
        discount {
          _id
          code
          name {
            _id
            zh
            en
          }
          amount
        }
      }
      campaignContents {
        ...CampaignContentFullAttributes
      }
    }
  }
  ${CAMPAIGN_CONTENT_FRAGMENT}
`;

export const FETCH_CAMPAIGN_RULE = gql`
  query ($filter: FilterFindManyCampaignInput) {
    campaignFindMany(filter: $filter) {
      name
      startDate
      endDate
      type
      _id
      deleted
      discountRank {
        _id
        discountId
        participatorAmount
        discount {
          _id
          code
          name {
            _id
            zh
            en
          }
          amount
        }
      }
    }
  }
`;

export const FETCH_CAMPAIGN_OVERVIEW = gql`
  query ($filter: FilterFindManyCampaignInput) {
    campaignFindMany(filter: $filter) {
      name
      startDate
      endDate
      type
      _id
      deleted
      discountRank {
        _id
        discountId
        participatorAmount
        discount {
          _id
          code
          name {
            _id
            zh
            en
          }
          amount
        }
      }
    }
  }
`;

export const CAMPAIGN_PAGINATION = gql`
  query ($page: Int!, $perPage: Int!) {
    campaignPagination(page: $page, perPage: $perPage) {
      items {
        name
        startDate
        endDate
        type
        _id
        deleted
        discountRank {
          _id
          discountId
          participatorAmount
          discount {
            _id
            code
            name {
              _id
              zh
              en
            }
            amount
          }
        }
      }
      count
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation ($input: CreateOneCampaignInput!) {
    campaignCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation ($input: UpdateOneCampaignInput!, $Id: MongoID) {
    campaignUpdateOne(input: { record: $input, filter: { _id: $Id } }) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_CAMPAIGN_CONTENT = gql`
  mutation ($input: CreateOneCampaignContentInput!) {
    campaignContentCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_CONTENT = gql`
  mutation ($input: UpdateOneCampaignContentInput!, $Id: MongoID) {
    campaignContentUpdateOne(input: { record: $input, filter: { _id: $Id } }) {
      record {
        _id
      }
    }
  }
`;

export const FETCH_CAMPAIGN_CONTENT = gql`
  query ($filter: FilterFindManyCampaignContentInput) {
    campaignContentFindMany(filter: $filter) {
      ...CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT
    }
  }
  ${CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT}
`;

export const FETCH_CAMPAIGN_INDIVIDUAL_PAGINATION = gql`
  query (
    $filter: FilterFindManyCampaignIndividualInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyCampaignIndividualInput
  ) {
    campaignIndividualPagination(
      filter: $filter
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      count
      items {
        _id
        createdAt
        user {
          _id
          email
        }
        oneOrder {
          _id
          discountAmount
          displayId
          deliveryFee
          subtotal
          paymentStatus
          total
        }
      }
    }
  }
`;

export const FETCH_CAMPAIGN_CONTENT_FIND_ONE = gql`
  query ($filter: FilterFindOneCampaignContentInput) {
    campaignContentOne(filter: $filter) {
      ...CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT
    }
  }
  ${CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT}
`;

export const FETCH_CAMPAIGN_CONTENT_DRAFT = gql`
  query ($filter: FilterFindManyCampaignContentInput) {
    campaignContentFindManyShowDraft(filter: $filter) {
      ...CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT
    }
  }
  ${CAMPAIGN_CONTENT_DASHBOARD_FRAGMENT}
`;

export const FETCH_CAMPAIGN_INDIVIDUAL = gql`
  query ($filter: FilterFindManyCampaignIndividualInput) {
    campaignIndividualFindMany(filter: $filter) {
      saleorOrderId
      oneOrderId
      user {
        _id
        email
      }
      campaignContent {
        _id
        pickupWindow {
          _id
          start
          end
          day
        }
        pickupTimeSlotFormatted
      }
    }
  }
`;

export const CREATE_CAMPAIGN_INDIVIDUAL = gql`
  mutation ($input: CreateOneCampaignIndividualInput!) {
    campaignIndividualParticipate(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_INDIVIDUAL_ORDER = gql`
  mutation ($Id: MongoID, $orderId: MongoID) {
    campaignIndividualUpdate(
      input: { filter: { _id: $Id }, record: { oneOrderId: $orderId } }
    ) {
      record {
        _id
        oneOrderId
      }
    }
  }
`;

export const FETCH_MY_CAMPAIGN_CONTENT = gql`
  query (
    $filter: FilterFindManyCampaignContentInput
    $sort: SortFindManyCampaignContentInput
  ) {
    campaignContentFindMine(filter: $filter, sort: $sort) {
      ...CampaignContentFullAttributes
    }
  }
  ${CAMPAIGN_CONTENT_FRAGMENT}
`;

export const FETCH_MY_CAMPAIGN_INDIVIDUAL = gql`
  query ($contentId: MongoID, $userId: MongoID) {
    campaignIndividualFindMany(
      filter: { campaignContentId: $contentId, userId: $userId }
    ) {
      _id
      oneOrder {
        _id
        paymentStatus
      }
    }
  }
`;

export const SEND_TEST_MSG = gql`
  mutation ($Id: MongoID!) {
    campaignContentTestSendCoordination(Id: $Id)
  }
`;
