import { connect } from 'react-redux';
import React, { Component } from 'react';

import {
  BellOutlined,
  CarOutlined,
  FileDoneOutlined,
  GiftOutlined,
  ShoppingCartOutlined,
  SmileOutlined,
} from '@ant-design/icons';

import { Button, Col, Divider, Row, Steps } from 'antd';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import { CUSTOMER_ORDER_BY_ID } from '../../../../api/customerOrder';
import { apolloClient, getColor, getCustomerPath } from '../../../../util';

const { Step } = Steps;

export class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      order: null,
    };
  }

  componentDidMount = async () => {
    const { order } = this.props;
    if (order) {
      this.setState({ order });
    } else {
      await this.fetchCustomerOrders();
    }
    console.log(order);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchCustomerOrders = async () => {
    // fetch the last order
    const { match } = this.props;

    const { data } = await apolloClient.query({
      query: CUSTOMER_ORDER_BY_ID,
      variables: { id: match.params.orderId },
      fetchPolicy: 'network-only',
    });
    if (data && data.customerOrderById) {
      this.setState({
        order: data.customerOrderById,
      });
    }
  };

  getStep = (step) => {
    switch (step) {
      case 'notConfirmed':
        return 0;
      case 'confirmed':
        return 1;
      case 'readyForPickup':
        return 2;
      case 'complete':
        return 3;
      default:
        return 0;
    }
  };

  handleOpenGoogleMap = (lat, lng) => {
    const link = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(link, '_blank'); // to open new page
  };

  refundVariantQuantity = (id) => {
    const { order } = this.props;
    if (order && order.refundVariants && order.refundVariants.length > 0) {
      const index = order.refundVariants.findIndex(
        (item) => item.variantId === id
      );
      if (index > -1) {
        return order.refundVariants[index].quantity;
      }
    }
    return 0;
  };

  isCheckPriceSame = (variant) => {
    if (variant.commissionFee === 0 && variant.warehouseEarning === 0) {
      return true;
    }
    if (variant.price > -1) {
      const sumPrice = (
        variant.commissionFee + variant.warehouseEarning
      ).toFixed(2);
      return sumPrice === variant.price.toFixed(2);
    }
    return true;
  };

  render() {
    // const { language, group } = this.props;
    // console.log('group:', group);
    const { order } = this.state;
    const { intl, language } = this.props;
    return (
      <div>
        {order ? (
          <div
            style={{
              padding: '20px 25px 30px 25px',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
          >
            <Row style={{ fontSize: '16px', fontWeight: 'bold' }}>
              <Col span={24}>
                <Divider
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  orientation="left"
                >
                  <FormattedMessage id="orders" />{' '}
                  <span style={{ color: getColor(order.warehouseStatus) }}>
                    <FormattedMessage id={order.warehouseStatus} />
                  </span>
                </Divider>
              </Col>
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Col>
                <Steps
                  size="small"
                  current={this.getStep(order.warehouseStatus)}
                >
                  <Step
                    title={intl.formatMessage({ id: 'notConfirmed' })}
                    icon={<BellOutlined />}
                  />
                  <Step
                    title={intl.formatMessage({ id: 'confirmed' })}
                    icon={<FileDoneOutlined />}
                  />
                  <Step
                    title={intl.formatMessage({ id: 'readyForPickup' })}
                    icon={<GiftOutlined />}
                  />
                  <Step
                    title={intl.formatMessage({ id: 'complete' })}
                    icon={<SmileOutlined />}
                  />
                </Steps>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Divider
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  orientation="left"
                >
                  <FormattedMessage id="storeInfo" />
                </Divider>
              </Col>
              {order.warehouse && (
                <Col
                  xs={24}
                  sm={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={{ marginBottom: '10px' }}
                >
                  <div>{order.warehouse.addressOne}</div>
                  <div>{order.warehouse.addressTwo}</div>
                  <div>{`${order.warehouse.city}, ${order.warehouse.zip}`}</div>
                  <div>
                    <FormattedMessage id="phone" />:{' '}
                    <a href={`tel:${order.warehouse.phoneNumber}`}>
                      {order.warehouse.phoneNumber}
                    </a>
                  </div>
                </Col>
              )}
              <Col xs={24} sm={12} lg={12} xl={12} xxl={12}>
                <div>
                  <Link to={`${getCustomerPath('store')}/${order.warehouseId}`}>
                    <Button
                      type="primary"
                      style={{ width: '140px', marginBottom: '10px' }}
                    >
                      <ShoppingCartOutlined style={{ fontSize: '16px' }} />{' '}
                      <FormattedMessage id="reorder" />
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button
                    style={{ width: '140px' }}
                    type="primary"
                    onClick={() => {
                      this.handleOpenGoogleMap(
                        order.warehouse.location.coordinates[1],
                        order.warehouse.location.coordinates[0]
                      );
                    }}
                  >
                    <CarOutlined style={{ fontSize: '16px' }} />{' '}
                    <FormattedMessage id="direction" />
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  orientation="left"
                >
                  <FormattedMessage id="orderDetails" />
                </Divider>
              </Col>
              <Col span={24} style={{ fontSize: '14px' }}>
                {`${intl.formatMessage({ id: 'Id' })} : ${order.displayId}`}
              </Col>
              <Col span={24} style={{ fontSize: '14px' }}>
                {`${intl.formatMessage({ id: 'orderCreated' })}
                : ${moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
              </Col>
              <Col span={24} style={{ fontSize: '14px' }}>
                <FormattedMessage id="deliveryFee" />:{' '}
                {order.deliveryFee && order.deliveryFee.fee > 0
                  ? `$${order.deliveryFee.fee}`
                  : 0}
              </Col>
              <Col span={24} style={{ fontSize: '14px' }}>
                <FormattedMessage id="tax" />:{' '}
                {order.tax > 0 ? `$${order.tax}` : 0}
              </Col>
              <Col style={{ fontSize: '14px' }}>
                <FormattedMessage id="totalPrice" />
                {` $ ${order.subtotal.toFixed(2)} `}
              </Col>
              <Col style={{ fontSize: '14px' }}>
                <FormattedMessage id="totalItem" />
                {` ${order.variants.reduce(
                  (sum, variant) => sum + variant.quantity,
                  0
                )} `}{' '}
                <FormattedMessage id="items" />
              </Col>

              <Col span={24} style={{ fontSize: '14px' }}>
                {order.variants.map((variant) => {
                  return (
                    <div key={variant._id}>
                      <div>
                        {variant.quantity}x {variant.name[language]}
                        {variant.price > -1 ? (
                          <span>
                            {this.isCheckPriceSame(variant) ? (
                              <span> {` - ${variant.price}`}</span>
                            ) : (
                              <span>
                                {` - `}
                                <span
                                  style={{
                                    textDecoration: 'line-through',
                                    color: 'red',
                                  }}
                                >
                                  {variant.price}
                                </span>{' '}
                                <span>
                                  {(
                                    variant.commissionFee +
                                    variant.warehouseEarning
                                  ).toFixed(2)}
                                </span>
                              </span>
                            )}
                          </span>
                        ) : null}
                      </div>
                      {this.refundVariantQuantity(variant._id) > 0 && (
                        <div>
                          {'    '}Refunded x{' '}
                          {this.refundVariantQuantity(variant._id)}
                        </div>
                      )}
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  orientation="left"
                >
                  <FormattedMessage id="contactInfo" />
                </Divider>
              </Col>
              {order.address && (
                <Col span={24} style={{ fontSize: '14px' }}>
                  {` ${order.address.firstName} ${
                    order.address.lastName
                  } ${order.address.phoneNumber.replace('+1', '')}`}
                </Col>
              )}
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

OrderDetails.defaultProps = {
  match: null,
  order: null,
};

OrderDetails.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  match: ReactRouterPropTypes.match,
  order: PropTypes.shape({
    refundVariants: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        variantId: PropTypes.string,
      })
    ),
  }),
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  group: state.user.group,
});

export default connect(mapStateToProps, null)(injectIntl(OrderDetails));
