export default {
  en: {
    contactUs: 'Contact Us',
    contactNotes:
      'If you have any questions, please use the contact information and wechat code below to contact us. We will respond to you as soon as possible',
    deliveryQuestion: 'Have your order delivered in',
    deliveryAnswerFor24Hours: 'Free delivery Mon-Fri 3PM to 7PM',
    deliveryAnswerFor30Minutes: "BunBao's favorite items within minutes",
    pickupQuestion: 'Pick up your order within',
    pickupAnswerFor24Hours: 'Saving money with special discount',
    pickupAnswerFor30Minutes: 'Skip the line and pick up at nearest store',
    outForDeliveryArea:
      'Your address is out of our delivery area but we also offer pick up service in other areas',
    outForScheduledDeliveryArea:
      'Your address is out of our delivery area to this farmers market/store (distance over {miles} miles)',
    outForDeliveryAreaFM:
      'Your address is out of our delivery area to this farmers market (7 miles or less)',
    externalDeliveryArea:
      'We are sorry that we only offer limited delivery time to your area: [xx.area] for [xx.date] because of the distance. You can click "Continue" button below OR you can check out our pick up service.',
    outForDeliveryArea2:
      ' , OR you can check our Group Buy which covers more areas',
    plsContactUs: 'You can use the lower right button to contact us',
    plsFillOneOfThese: 'You order need follow one of rules below',
    groupBuyDescriptionZero: 'Menu preview, click on',
    groupBuyDescriptionOne:
      'Bulk shopping is cheaper, group up with your friends to unlock more discount.',
    groupBuyDescriptionTwo:
      'Shipping is free. Minimum order depends on your shipping distance.',
    groupBuyDescriptionThree:
      'Order will be delivered at your chosen time window.',
    groupBuyDescriptionFour:
      'This will make you the owner of this group order, you shall decide where and when this order will be delivered, but everyone will still pay for their own order using their own account',
    groupBuyDescription5:
      'I was invited and I have a code to join group order. ',
    groupBuyDistanceDescription:
      'Based on your address, your group must together buy a minimum of $',
    groupBuyNotice:
      'Please Notice: The group buying deadline will before this date for 36 hours',
    groupEndTime: 'Your order cutoff time is：[time]',
    groupBuyFriendOnlyDescription:
      "Selecting 'No' will make the group visible on website and allow everyone to join in",
    groupBuyOutOfDeliveryRange:
      'We are sorry that your address is not within our delivery areas.',
    functionOutOfOrder: 'We are sorry this function is in maintenance now.',
    noShippingDateAvailable:
      'Your shopping cart has delivery date conflicts because of limited shipping time goods{variantName}. Please change your shopping cart before checkout.',
    limitedShippingDate:
      'You have {variantName} in your cart with designated delivery date: {shippingDates}.',
    backToShoppingCartEditing: 'Back to cart',
    replaceWebsiteSale:
      'Website sale :[xx.xx] is applied to your order.Use coupon will replace the discount',
    oldCustomerOnly: 'This Activity is for Customers with Orders Only.',
    errorDiscountInvalidCode: 'Code does not exist',
    errorDiscountZeroDiscount: 'Code does not work on your order.',
    errorUnSupportOrderType: 'Code does not support current order type',
    errorAllowedWarehouseId: 'Code does not support current shop',
    errorForPayment:
      'Shopping Mode: [xx.mode]. Error: [xx.error]. Retry or get contact with customer service (510)573-0672. Email:support@bunbao.com',
    errorForGeneral:
      'Error: [xx.error]. Retry or get contact with customer service (510)573-0672. Email:support@bunbao.com',
    errorStoreClose: 'Store has not opened yet',
    errorExceedMaximum: 'Sorry, This product is limited for [xx.xx] per order',
    errorInsufficientStock:
      '[xx.xx] has insufficient stock or out of stock, please go back to shopping cart to edit it.',
    fakeEmailError:
      'We are sorry that your email is detected as an undeliverable email address so we can not sign you up. If you have any questions or your email address is valid, get contact with customer service (510)573-0672. Email:support@bunbao.com',
  },
  zh: {
    contactUs: '联系我们',
    contactNotes:
      '如果您对订单有任何疑问，请通过下方联系方式或者扫描我们的微信二维码联系我们，我们会尽我们所能帮您解决问题。',
    deliveryQuestion: '您希望在多长时间内收到您的订单？',
    deliveryAnswerFor24Hours: '周一到周五，下午三点至六点，免费配送',
    deliveryAnswerFor30Minutes: '在线下单，到店即取，帮您节省时间与金钱',
    pickupQuestion: '您希望在多久后取到您的订单？',
    pickupAnswerFor24Hours: '预约取货，解锁最多口味，最多优惠',
    pickupAnswerFor30Minutes: '通过距离您最近的云店下单，棒!包精选，即点即送',
    outForDeliveryArea:
      '很抱歉您的地址在配送范围外，不过我们在其他地区提供自取服务',
    outForScheduledDeliveryArea:
      '很抱歉，您的地址在这个农贸市场或商店的配送范围外（您与它的距离超过了{miles}迈）',
    outForDeliveryAreaFM:
      '很抱歉您的地址在这个Farmers Market的配送范围外(7 miles以下)',
    externalDeliveryArea:
      '很抱歉因为距离问题我们在您的地区: [xx.area] 只在: [xx.date] 提供配送.您可以选择继续或者试试我们的自取服务.',
    outForDeliveryArea2: ',或者您可以试试我们的可以配送更远的团购功能',
    plsContactUs: '您可以使用右下的按钮联系我们',
    plsFillOneOfThese: '您的订单目前不符合指引',
    groupBuyDescriptionZero: '更多菜品选择，快入团查看吧。预览菜单',
    groupBuyDescriptionOne: '人越多越便宜，邀请朋友解锁更高折扣',
    groupBuyDescriptionTwo: '免运费，起送价格取决于配送距离',
    groupBuyDescriptionThree: '在您指定的时间窗口送达',
    groupBuyDescriptionFour:
      '你可以决定配送地址和配送时间，但你邀请的每个人都需要自己下单并完成支付',
    groupBuyDescription5: '我有邀请码 ',
    groupBuyDistanceDescription: '基于您的配送地址,您的起送价格为$',
    groupBuyNotice: '团购将根据您选择的配送时间,提前36小时截单',
    groupEndTime: '本团的截单时间为：[time]',
    groupBuyFriendOnlyDescription:
      "选择'否'会使得您的团购在网站上可见并且允许所有人参与",
    groupBuyOutOfDeliveryRange: '很抱歉您的地址不在我们的配送范围',
    functionOutOfOrder: '很抱歉该功能在维护中.',
    noShippingDateAvailable:
      '您的购物车包含限定日期冲突的产品{variantName},没有可选配送日期,请更改您的购物车',
    limitedShippingDate:
      '您的可选 自取/配送日期为: {shippingDates}. 因为您有限时产品 {variantName}',
    backToShoppingCartEditing: '返回购物车修改',
    replaceWebsiteSale: '满足网站促销 :[xx.xx] 使用折扣券会替换当前折扣',
    oldCustomerOnly: '该活动仅限于下过单的用户.',
    errorDiscountInvalidCode: '折扣码不存在',
    errorDiscountZeroDiscount: '折扣对当前订单无效',
    errorUnSupportOrderType: '折扣不支持当前订单种类',
    errorAllowedWarehouseId: '折扣不支持当前店铺',
    errorForPayment:
      '购物模式: [xx.mode]. 错误: [xx.error]. 重试或者联系我们的客服: (510)573-0672 邮箱:support@bunbao.com',
    errorForGeneral:
      '错误: [xx.error]. 重试或者联系我们的客服: (510)573-0672 邮箱:support@bunbao.com',
    errorStoreClose: '商店未营业',
    errorExceedMaximum: '很抱歉该商品每单上限 [xx.xx] 个',
    errorInsufficientStock:
      '产品[xx.xx]库存不足您购买的数量或者售罄，请回购物车修改.',
    fakeEmailError:
      '很抱歉我们检测到您的邮箱不是真实邮箱所以我们不能进行注册.如果您有疑问或者您的邮箱为真实邮箱,重试或者联系我们的客服: (510)573-0672 邮箱:support@bunbao.com',
  },
};
