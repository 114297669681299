/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

export default function BenefitList({ language }) {
  return (
    <>
      <Title level={4} style={{ fontSize: '1.1rem' }}>
        {language === 'zh' ? '您将获得' : "You'll get"}
      </Title>
      <ul className="member-benefit-list">
        <li>
          <span>
            {/* <GiftTwoTone twoToneColor="#DF242F" /> */}
            &#127873;
          </span>
          {language === 'zh'
            ? '专属的优惠与折扣'
            : 'Exclusive coupons and rewards'}
        </li>
        <li>
          <span>
            {/* <ProfileTwoTone twoToneColor="#DF242F" /> */}
            &#128241;
          </span>
          {language === 'zh' ? '对历史订单随时随地的管理' : 'Order management'}
        </li>
        <li>
          <span>
            {/* <ThunderboltTwoTone twoToneColor="#DF242F" /> */}
            &#9889;
          </span>
          {language === 'zh'
            ? '更快更便捷的下单流程'
            : 'Easier & Faster checkout'}
        </li>
      </ul>
    </>
  );
}
