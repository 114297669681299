/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import {
  CalendarOutlined,
  RedEnvelopeOutlined,
  CheckOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  LoadingOutlined,
  InfoCircleTwoTone,
  SmileOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Alert,
  Row,
  Modal,
  Input,
  Col,
  message,
  Tooltip,
  Form,
  Switch,
  Calendar,
  Select,
  notification,
  // TimePicker,
} from 'antd';
import FormBuilder from 'antd-form-builder';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
// import { Calendar } from 'antd-mobile';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { convertRawToHTML } from 'braft-convert';
import BraftEditor from 'braft-editor';
import StepBar from '../StepBar/StepBar';
import * as actions from '../../../../store/actions';
import CheckoutCart from '../CheckoutCart/CheckoutCart';
import {
  variantList2,
  successIcon,
  questionIcon,
} from '../../../../components/SmallComponents/SmallComponents';
import './Payment.less';
import {
  addressFormatter,
  addressFormatterOnlyName,
  addressFormatterOnlyAddress,
  discountErrorFormatter,
  customerOrderFormatter,
  pseudoTypeFormatter,
} from '../../../../helper/Formatter/Formatter';
import CouponCode from '../../../../components/MiddleComponents/CouponCode';
import {
  apolloClient,
  getCustomerPath,
  updateVariantPrice,
} from '../../../../util';
import {
  CUSTOMER_ORDER_CREATE,
  CUSTOMER_ORDER_FETCH_DELIVERY_FEE,
} from '../../../../api/customerOrder';
import { FETCH_CAMPAIGN_CONTENT_FIND_ONE } from '../../../../api/campaign';
import {
  // VARIANT_FIND_BY_ID,
  // VARIANTS_QUERY_IDS,
  VARIANT_ADD_ONS,
} from '../../../../api/variant';
import { USER_FIND_ONE, GUEST_SIGN_UP } from '../../../../api/user';
import { SUBSCRIBER_CREATE } from '../../../../api/subscriber';
import {
  CURRENT_USER_BEST_COUPON,
  FETCH_DISCOUNT_ONE,
} from '../../../../api/discount';
import OrderPaymentForm from '../../../../components/Forms/OrderPaymentForm/OrderPaymentForm';
import { TAX_RATE_FETCH } from '../../../../api/taxRate';
import { WAREHOUSE_FIND_ONE, WAREHOUSE_BY_ID } from '../../../../api/warehouse';
import { CONFIGURATION_FIND_ONE } from '../../../../api/configuration';
import { BUCKET_URL, DEFAULT_IMAGE, BUCKET_CDN_URL } from '../../../../config';
import { VariantsAddOns } from '../VariantsAddOns/VariantsAddOns';
import { CATEGORY_QUERY } from '../../../../api/category';

/**
 * START Halloween Promo
 */

const PROMO_VARIANT_ID = '61688aab5e40330020ec7113';
const PROMO_COUPON_CODE = 'HALLOWEEN10OFF';
const PROMO_MIN_SPEND = 65; // 65 dollar minimum.
const NOTIFICATION_KEY = 'halloween_notification';

const closeNotification = () => {
  notification.close(NOTIFICATION_KEY);
};

const sendNotification = (fillCode, language) => {
  const btn = (
    <Button
      onClick={() => {
        closeNotification();
        fillCode();
      }}
    >
      {language === 'en' ? 'Got It' : '知道了'}
    </Button>
  );
  notification.open({
    message: (
      <h3 style={{ fontWeight: 'bolder' }}>
        {language === 'en' ? 'Happy Halloween!' : '万圣节快乐！'}
      </h3>
    ),
    description: (
      <div>
        {language === 'en'
          ? 'SCORE! Your order qualifies the coupon '
          : '恭喜！您的订单可以使用折扣码 '}
        <Tooltip title={language === 'en' ? 'Copy to clipboard' : '点击复制'}>
          <Button
            type="text"
            // onClick={() => navigator.clipboard.writeText(PROMO_COUPON_CODE)}
            onClick={() => {
              fillCode();
            }}
            style={{
              backgroundColor: '#d9f7be',
              fontSize: '1rem',
              fontWeight: 'bold',
              margin: '16px auto',
            }}
          >
            {PROMO_COUPON_CODE}
          </Button>
        </Tooltip>
        <br />
        {language === 'en'
          ? '. Remember to apply the code below.'
          : '记得在下方输入折扣码然后点击“检查并使用”按钮。'}
      </div>
    ),
    icon: <SmileOutlined style={{ color: '#52c41a' }} />,
    duration: 0,
    btn,
    key: NOTIFICATION_KEY,
    maxCount: 1,
  });
};

const cartQualifiesPromo = (cartItems, itemId) => {
  if (!cartItems || !cartItems.length || cartItems.length < 1) {
    return false;
  }

  let sum = 0;
  cartItems.forEach((product) => {
    if (product.count > 0) {
      sum += product.count * product.listPrice;
    }
  });

  if (sum < PROMO_MIN_SPEND) {
    return false;
  }

  const boxContentHasItem = (contentItems) => {
    if (!contentItems || !contentItems.length || contentItems.length < 1) {
      return false;
    }
    for (let i = 0; i < contentItems.length; i += 1) {
      if (contentItems[i].id === itemId) {
        return true;
      }
    }
    return false;
  };

  for (let i = 0; i < cartItems.length; i += 1) {
    if (
      cartItems[i].id === itemId ||
      boxContentHasItem(cartItems[i].boxContent)
    ) {
      return true;
    }
  }
  return false;
};
/**
 * End Halloween Promo
 */

const addWeekdays = (date, days) => {
  let newDays = days; // use a clone
  let newDate = moment(date);
  while (newDays > 0) {
    newDate = newDate.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (newDate.day() !== 6 && newDate.day() !== 7) {
      newDays -= 1;
    }
  }
  return newDate;
};

export class Payment extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedAddress: null, // id only
      shoppingList: [],
      // boxList: [],
      shippingDate: null,
      boxContentModal: false,
      boxContent: null,
      selectedShippingDate: null,
      // selectedShippingTime: null,
      // coupon
      couponCode: null,
      validCouponCode: '',
      websiteSaleCode: null,
      // invalidCodeMessage: '',
      discountAmount: 0,
      // loading: false,
      popCouponVisible: false,
      // datePicker
      lockDatePicker: false,
      mobileCalendarVisibility: false,
      onlyAllowedWeekDay: null,
      taxRate: 0,
      deliveryMode: null,
      deliveryMinimumAmount: 0,
      deliveryDistanceRange: [],
      deliveryDurationPreMinute: 0,
      deliveryDistancePreMile: 0,
      deliveryFee: 0,
      scheduledDeliveryTimeRangeStart: 0,
      scheduledDeliveryTimeRangeEnd: 0,
      marketMinimumAmountForShippingFree: 0,
      marketShippingFee: 0,
      warehouseObj: null,
      selectedDiscount: null,
      availableSchedulePickUpSlot: [],
      checkPriceModal: false,
      prepareDay: 0,
      selectedPickupDate: null,
      pickupOptionsPage: 1,
      fmPickupTimeSlotFormatted: [], // fm pickup mode
      userEmail: '',
      invalidEmailMessage: '',
      isRegistered: false,
      hasRegisteredModal: false,
      isResisterLoading: false,
      warehouseAddons: null,
      addonsModal: false,
      onlyAllowedDateAddonsCart: [],
      collectionsList: [],
      doNotHideMobileCalendarModal: false,
    };
    this.intervalUpdate = null;
    this.pickupOptionsArray = [];
    this.formRef = React.createRef();
  }

  componentDidMount = async () => {
    const {
      history,
      history: { location },
      shoppingList,
      currentShoppingMode,
      getCurrentWarehouseCart,
      isScheduledDelivery,
      currentAddressType,
      warehouseId,
      warehouseDeliveryMode,
      updateWarehouseCart,
      language,
    } = this.props;
    console.log(
      '****************',
      currentShoppingMode,
      'address',
      currentAddressType,
      isScheduledDelivery
    );

    if (cartQualifiesPromo(shoppingList, PROMO_VARIANT_ID)) {
      sendNotification(
        () =>
          this.setState({
            couponCode: PROMO_COUPON_CODE,
          }),
        language
      );
    }

    this.fetchCustomerCouponApplyNotice();

    // if (language === 'zh') {
    //   moment.locale('zh-cn', {
    //     week: {
    //       dow: 0,
    //     },
    //   });
    // } else {
    //   moment.locale('en');
    // }

    await this.fetchCollectionData();

    const { state } = location;
    console.log('state', state);
    if (state && state.selectedAddressDetail !== undefined) {
      console.log('trigger state change');

      // check variant price
      // const variantsPromise = [];
      // const variantIds = [];

      // for (let i = 0; i < shoppingList.length; i += 1) {
      //   // check price
      //   if (shoppingList[i].boxContent) {
      //     for (let j = 0; j < shoppingList[i].boxContent.length; j += 1) {
      //       variantIds.push(shoppingList[i].boxContent[j]._id);
      //     }
      //   } else {
      //     variantIds.push(shoppingList[i]._id);
      //   }
      // }
      // const variantsResponse = await apolloClient.query({
      //   query: VARIANTS_QUERY_IDS,
      //   variables: { input: variantIds },
      //   fetchPolicy: 'network-only',
      // });
      const { collectionsList } = this.state;
      const shoppingCart = await getCurrentWarehouseCart();
      console.log(shoppingList);
      console.log('redux!!!!!!!!!', shoppingCart);
      await updateVariantPrice(
        shoppingCart,
        updateWarehouseCart,
        collectionsList
      );
      // const variants = variantsResponse.data.variantByIds;

      // update shoppingList
      // const newShoppingList = shoppingList.map((shoppingListItem) => {
      //   const newShoppingListItem = { ...shoppingListItem };
      //   if (shoppingListItem.boxContent) {
      //     newShoppingListItem.boxContent = shoppingListItem.boxContent.map(
      //       (item) => {
      //         // TODO: check collection
      //         const count = shoppingListItem.boxContent.reduce(
      //           (sum, element) => sum + element.count,
      //           0
      //         );
      //         console.log(count);

      //         let discountPercentage = 1;
      //         if (
      //           collectionsList.find(
      //             (element) => element.container.capacity === count
      //           )
      //         ) {
      //           discountPercentage = collectionsList.find(
      //             (element) => element.container.capacity === count
      //           ).container.discountPercentage;
      //         }
      //         const newItem = { ...item };
      //         const foundVariant = variants.find(
      //           (variant) => variant._id === newItem._id
      //         );
      //         if (foundVariant) {
      //           // check date and change list price
      //           const today = moment().valueOf();
      //           if (
      //             foundVariant.isChangeListPriceByDates &&
      //             today <= foundVariant.changeListPriceDates.endDate &&
      //             today >= foundVariant.changeListPriceDates.startDate
      //           ) {
      //             newItem.listPrice = foundVariant.listPrice;
      //           } else if (
      //             foundVariant.isChangeListPriceByDates &&
      //             (today > foundVariant.changeListPriceDates.endDate ||
      //               today < foundVariant.changeListPriceDates.startDate)
      //           ) {
      //             newItem.listPrice = foundVariant.originalPrice;
      //           } else {
      //             newItem.listPrice = foundVariant.listPrice;
      //           }
      //           newItem.listPrice *= discountPercentage;
      //         }
      //         return newItem;
      //       }
      //     );
      //     let totalPrice = 0;
      //     newShoppingListItem.boxContent.forEach((item) => {
      //       totalPrice += item.listPrice * item.count;
      //     });
      //     newShoppingListItem.listPrice = totalPrice;
      //   } else {
      //     const foundVariant = variants.find(
      //       (variant) => variant._id === shoppingListItem._id
      //     );
      //     if (foundVariant) {
      //       // check date and change list price
      //       const today = moment().valueOf();
      //       if (
      //         foundVariant.isChangeListPriceByDates &&
      //         today <= foundVariant.changeListPriceDates.endDate &&
      //         today >= foundVariant.changeListPriceDates.startDate
      //       ) {
      //         newShoppingListItem.listPrice = foundVariant.listPrice;
      //       } else if (
      //         foundVariant.isChangeListPriceByDates &&
      //         (today > foundVariant.changeListPriceDates.endDate ||
      //           today < foundVariant.changeListPriceDates.startDate)
      //       ) {
      //         newShoppingListItem.listPrice = foundVariant.originalPrice;
      //       } else {
      //         newShoppingListItem.listPrice = foundVariant.listPrice;
      //       }
      //     }
      //   }
      //   return newShoppingListItem;
      // });
      const newShoppingCart = await getCurrentWarehouseCart();

      this.setState({
        selectedAddress: state.selectedAddress,
        selectedAddressDetail: state.selectedAddressDetail,
        lockDatePicker: false,
        shoppingList: _.concat(
          newShoppingCart.productList,
          newShoppingCart.boxList
        ),
      });
      if (currentShoppingMode === 'pickup') {
        const warehouseObj = await this.handleFetchWarehouseData(warehouseId);
        this.setState({
          warehouseObj,
          lockDatePicker: false,
        });
      }
      if (state.lockDatePicker === true) {
        this.setState({
          shippingDate: moment(state.selectedShippingDate),
          selectedShippingDate: state.selectedShippingDate,
        });
      }
      if (state.onlyAllowedWeekDay) {
        this.setState({ onlyAllowedWeekDay: state.onlyAllowedWeekDay });
      }
      await this.handleCheckCouponSubmit(true);

      console.log(currentAddressType, currentShoppingMode);
      // console.log(currentShoppingMode);
      if (
        currentAddressType === 'pickup' &&
        currentShoppingMode === 'delivery' &&
        !state.selectedAddressDetail.pickupWindow
      ) {
        const { preselectPickupInfo } = this.props;
        if (preselectPickupInfo && preselectPickupInfo.campaignContentId) {
          await this.fetchCampaignContent(
            preselectPickupInfo.campaignContentId
          );
        }
      }

      // handle fetch tax rate and delivery fee
      if (isScheduledDelivery) {
        await this.handleFetchScheduledDeliveryConfiguration();
        // fetch tax rate
        await this.handleFetchTaxRate();
        // fetch delivery fee
        await this.handleFetchDeliveryFee();
      } else if (currentShoppingMode === 'delivery' && !warehouseDeliveryMode) {
        await this.handleFetchScheduledDeliveryConfiguration();
      } else if (currentShoppingMode === 'delivery' && warehouseDeliveryMode) {
        // TODO: check warehouse delivery fee
      } else {
        const { taxRate: warehouseTaxRate } = this.props;
        this.setState({ taxRate: warehouseTaxRate });
      }
    } else if (currentShoppingMode === 'donation') {
      const shoppingCart = await getCurrentWarehouseCart();
      const newShoppingList = _.concat(
        shoppingCart.productList,
        shoppingCart.boxList
      );
      this.setState({ shoppingList: newShoppingList });
    } else if (currentShoppingMode === 'eShop') {
      const shoppingCart = await getCurrentWarehouseCart();
      const newShoppingList = _.concat(
        shoppingCart.productList,
        shoppingCart.boxList
      );
      this.setState({ shoppingList: newShoppingList });
    } else {
      history.push(getCustomerPath('checkout/shipping'));
    }
    // selectDefaultDate
    for (let i = 0; i < 14; i += 1) {
      const date =
        i === 0 ? moment().add(i, 'd') : moment().add(i, 'd').startOf();
      const isDisabled = this.handleDisableDate(date);
      if (!isDisabled) {
        this.selectDefaultDate(isDisabled, date);
        break;
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  componentWillUnmount = () => {
    closeNotification();
  };

  fetchCustomerCouponApplyNotice = async () => {
    // check user token
    const { token, language, currentShoppingMode } = this.props;
    console.log(token);
    if (token && currentShoppingMode !== 'eShop') {
      // check customer coupon with current cart price
      const { data } = await apolloClient.query({
        query: CURRENT_USER_BEST_COUPON,
      });
      const { currentUserBestDiscount } = data;
      console.log(currentUserBestDiscount);
      if (currentUserBestDiscount) {
        const btn = (
          <Button
            onClick={() => {
              notification.close('best_discount_notification');
              // this.setState({
              //   couponCode: currentUserBestDiscount.code,
              // });
            }}
          >
            {language === 'en' ? 'Got It' : '知道了'}
          </Button>
        );
        notification.open({
          message: (
            <h3 style={{ fontWeight: 'bolder' }}>
              {language === 'en' ? 'Ta-da!' : '哒哒！'}
            </h3>
          ),
          description: (
            <div>
              {language === 'en'
                ? 'SCORE! Your may apply coupon on this order '
                : '恭喜！您的订单或许可以使用折扣码 '}
              <Tooltip
                title={language === 'en' ? 'Copy to clipboard' : '点击复制'}
              >
                <Button
                  type="text"
                  onClick={() => {
                    this.setState({
                      couponCode: currentUserBestDiscount.code,
                    });
                  }}
                  style={{
                    backgroundColor: '#d9f7be',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    margin: '16px auto',
                  }}
                >
                  {currentUserBestDiscount.code}
                </Button>
              </Tooltip>
              {currentUserBestDiscount.minimumAmountSpent > 0 && (
                <>
                  <br />
                  {language === 'en'
                    ? 'Minimum amount spend:'
                    : '订单总金额须满足:'}{' '}
                  ${currentUserBestDiscount.minimumAmountSpent}
                </>
              )}

              <br />
              {language === 'en' ? (
                <div>
                  Remember to apply the code below. You also can select another
                  coupon in{' '}
                  <Button
                    onClick={() => {
                      this.setState({
                        popCouponVisible: true,
                      });
                    }}
                    size="small"
                    type="primary"
                  >
                    <RedEnvelopeOutlined /> Coupon Wallet
                  </Button>
                  .
                </div>
              ) : (
                <div>
                  记得在下方输入折扣码然后点击“检查并使用”按钮。您也可以打开
                  <Button
                    onClick={() => {
                      this.setState({
                        popCouponVisible: true,
                      });
                    }}
                    size="small"
                    type="primary"
                  >
                    <RedEnvelopeOutlined /> 优惠券卡包
                  </Button>
                  选择其他优惠券。
                </div>
              )}
            </div>
          ),
          icon: <SmileOutlined style={{ color: '#52c41a' }} />,
          duration: 0,
          btn,
          key: 'best_discount_notification',
          maxCount: 1,
        });
      }
    }
  };

  fetchCollectionData = async () => {
    const {
      data: { categoryMany },
    } = await apolloClient.query({
      query: CATEGORY_QUERY,
      variables: {
        filter: {
          type: 'collection',
          warehouseId: null,
        },
      },
      fetchPolicy: 'network-only',
    });

    if (categoryMany) {
      const collectionsList =
        (categoryMany.length &&
          categoryMany.map((item) => {
            return {
              _id: item._id,
              id: item._id,
              title: item.name,
              name: { zh: item.title.zh, en: item.title.en },
              container: item.container,
            };
          })) ||
        [];

      await this.setState({ collectionsList });
    } else {
      message.warn('error happened during fetching collections');
    }
  };

  fetchCampaignContent = async (campaignContentId) => {
    try {
      const {
        data: { campaignContentOne },
      } = await apolloClient.query({
        query: FETCH_CAMPAIGN_CONTENT_FIND_ONE,
        variables: { filter: { _id: campaignContentId, deleted: false } },
      });
      this.setState({
        fmPickupTimeSlotFormatted:
          campaignContentOne.pickupTimeSlotFormatted || [],
        availableSchedulePickUpSlot: campaignContentOne.pickupWindow || [],
        prepareDay: campaignContentOne.prepareDay,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleFetchWarehouseData = async (Id) => {
    try {
      const {
        data: { warehouseById },
      } = await apolloClient.query({
        query: WAREHOUSE_BY_ID,
        variables: { Id },
        fetchPolicy: 'network-only',
      });
      return warehouseById;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  handleFetchScheduledDeliveryConfiguration = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isScheduledDeliveryConfiguration: true },
      },
      fetchPolicy: 'network-only',
    });

    if (configurationOne) {
      const { scheduledDeliveryConfiguration } = configurationOne;
      console.log(scheduledDeliveryConfiguration);
      await this.setState({
        deliveryMode: scheduledDeliveryConfiguration.currentMode,
        deliveryMinimumAmount: scheduledDeliveryConfiguration.minimumAmount,
        deliveryDistanceRange: scheduledDeliveryConfiguration.distance,
        deliveryDistancePreMile: scheduledDeliveryConfiguration.duration.mile,
        deliveryDurationPreMinute:
          scheduledDeliveryConfiguration.duration.minute,
        marketMinimumAmountForShippingFree:
          scheduledDeliveryConfiguration.marketMinimumAmountForShippingFree,
        marketShippingFee: scheduledDeliveryConfiguration.marketShippingFee,
      });
    }
  };

  handleFetchTaxRate = async () => {
    const { selectedAddressDetail } = this.state;
    let zipCode = '';
    if (!selectedAddressDetail) {
      zipCode = '94538';
    } else {
      zipCode = selectedAddressDetail.zipCode;
    }
    const { data } = await apolloClient.mutate({
      mutation: TAX_RATE_FETCH,
      variables: {
        zipCode: zipCode.toString(),
      },
    });
    const { taxRateFetch } = data;
    this.setState({ taxRate: taxRateFetch.percentage });
  };

  handleFetchDeliveryFee = async () => {
    const { selectedAddressDetail } = this.state;
    const { warehouseId } = this.props;
    if (selectedAddressDetail) {
      // fetch warehouse
      const warehouseResponse = await apolloClient.query({
        query: WAREHOUSE_FIND_ONE,
        variables: { filter: { _id: warehouseId } },
        fetchPolicy: 'network-only',
      });
      console.log(warehouseResponse);
      const {
        data: { warehouseOne },
      } = warehouseResponse;

      this.setState({
        scheduledDeliveryTimeRangeStart:
          warehouseOne.scheduledDeliveryTimeRange.start,
        scheduledDeliveryTimeRangeEnd:
          warehouseOne.scheduledDeliveryTimeRange.end,
      });

      let destinationString;
      let originString;
      if (selectedAddressDetail.addressTwo) {
        destinationString = `${selectedAddressDetail.addressOne},${selectedAddressDetail.addressTwo},${selectedAddressDetail.city}, ${selectedAddressDetail.zipCode}`;
      } else {
        destinationString = `${selectedAddressDetail.addressOne},${selectedAddressDetail.city},${selectedAddressDetail.zipCode}`;
      }
      if (warehouseOne.addressTwo) {
        originString = `${warehouseOne.addressOne},${warehouseOne.addressTwo},${warehouseOne.city},${warehouseOne.zip}`;
      } else {
        originString = `${warehouseOne.addressOne},${warehouseOne.city},${warehouseOne.zip}`;
      }
      const response = await apolloClient.query({
        query: CUSTOMER_ORDER_FETCH_DELIVERY_FEE,
        variables: {
          originString,
          destinationString,
        },
      });
      const { customerOrderFetchDeliveryFee } = response.data;
      this.setState({
        deliveryFee: customerOrderFetchDeliveryFee.fee,
      });
    } else {
      this.setState({
        deliveryFee: 0,
      });
    }
  };

  handleClickButton = () => {
    const { history, currentShoppingMode } = this.props;
    if (currentShoppingMode === 'groupBuy') {
      history.goBack();
    } else {
      history.replace({
        pathname: getCustomerPath('checkout/shipping'),
        state: { edit: true },
      });
    }
  };

  handleChangeMobileDate = (date) => {
    this.handleDateChange(moment(date), moment(date).format('YYYY-MM-DD'));
  };

  handleViewBox = (input) => {
    this.setState({ boxContent: input, boxContentModal: true });
  };

  handleDateChange = async (date, dateString) => {
    const { selectedDiscount, collectionsList } = this.state;
    const {
      updateWarehouseCart,
      currentShoppingMode,
      // shoppingList,
      getCurrentWarehouseCart,
    } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    await updateVariantPrice(
      shoppingCart,
      updateWarehouseCart,
      collectionsList
    );

    // if (date && dateString) {
    //   shoppingList.forEach((elem, index) => {
    //     shoppingList[index].shippingDate = dateString;
    //   });
    // }
    // console.log('shoppingList date', shoppingList);

    // // update price
    // const variantIds = [];
    // shoppingCart.productList.forEach((product) => {
    //   variantIds.push(product._id);
    // });
    // shoppingCart.boxList.forEach((box) => {
    //   box.boxContent.forEach((product) => {
    //     variantIds.push(product._id);
    //   });
    // });
    // const variantsResponse = await apolloClient.query({
    //   query: VARIANTS_QUERY_IDS,
    //   variables: { input: variantIds },
    //   fetchPolicy: 'network-only',
    // });

    // const variants = variantsResponse.data.variantByIds;

    // // update shoppingCart
    // const newShoppingCart = {
    //   ...shoppingCart,
    //   productList: shoppingCart.productList.map((product) => {
    //     const newShoppingListItem = { ...product };
    //     const foundVariant = variants.find(
    //       (variant) => variant._id === newShoppingListItem._id
    //     );
    //     console.log('found variant', foundVariant);
    //     if (foundVariant) {
    //       // check date and change list price
    //       const today = moment().valueOf();
    //       if (
    //         foundVariant.isChangeListPriceByDates &&
    //         today <= foundVariant.changeListPriceDates.endDate &&
    //         today >= foundVariant.changeListPriceDates.startDate
    //       ) {
    //         newShoppingListItem.listPrice = foundVariant.listPrice;
    //       } else if (
    //         foundVariant.isChangeListPriceByDates &&
    //         (today > foundVariant.changeListPriceDates.endDate ||
    //           today < foundVariant.changeListPriceDates.startDate)
    //       ) {
    //         newShoppingListItem.listPrice = foundVariant.originalPrice;
    //       } else {
    //         newShoppingListItem.listPrice = foundVariant.listPrice;
    //       }
    //     }
    //     return newShoppingListItem;
    //   }),
    //   boxList: shoppingCart.boxList.map((box) => {
    //     const newShoppingListItem = { ...box };
    //     // TODO: check collection
    //     newShoppingListItem.boxContent = box.boxContent.map((item) => {
    //       const count = box.boxContent.reduce(
    //         (sum, element) => sum + element.count,
    //         0
    //       );
    //       console.log(count);

    //       let discountPercentage = 1;
    //       if (
    //         collectionsList.find(
    //           (element) => element.container.capacity === count
    //         )
    //       ) {
    //         discountPercentage = collectionsList.find(
    //           (element) => element.container.capacity === count
    //         ).container.discountPercentage;
    //       }
    //       const newItem = { ...item };
    //       const foundVariant = variants.find(
    //         (variant) => variant._id === newItem._id
    //       );
    //       if (foundVariant) {
    //         // check date and change list price
    //         const today = moment().valueOf();
    //         if (
    //           foundVariant.isChangeListPriceByDates &&
    //           today <= foundVariant.changeListPriceDates.endDate &&
    //           today >= foundVariant.changeListPriceDates.startDate
    //         ) {
    //           newItem.listPrice = foundVariant.listPrice;
    //         } else if (
    //           foundVariant.isChangeListPriceByDates &&
    //           (today > foundVariant.changeListPriceDates.endDate ||
    //             today < foundVariant.changeListPriceDates.startDate)
    //         ) {
    //           newItem.listPrice = foundVariant.originalPrice;
    //         } else {
    //           newItem.listPrice = foundVariant.listPrice;
    //         }
    //         newItem.listPrice *= discountPercentage;
    //       }
    //       return newItem;
    //     });
    //     let totalPrice = 0;
    //     newShoppingListItem.boxContent.forEach((item) => {
    //       totalPrice += item.listPrice * item.count;
    //     });
    //     newShoppingListItem.listPrice = totalPrice;
    //     return newShoppingListItem;
    //   }),
    // };

    // await updateWarehouseCart(newShoppingCart);
    const newShoppingCart = await getCurrentWarehouseCart();
    if (selectedDiscount && selectedDiscount.freeVariant) {
      const shoppingListCpy = _.concat(
        newShoppingCart.productList,
        newShoppingCart.boxList
      );
      const freeVariant = selectedDiscount.freeVariant && {
        ...selectedDiscount.freeVariant,
      };
      if (freeVariant) {
        freeVariant.count = 1;
        freeVariant.listPrice = 0;
        freeVariant.imgUrl =
          freeVariant && freeVariant.picture
            ? BUCKET_URL + freeVariant.picture.key
            : BUCKET_URL + freeVariant.product.picture.key;
        const newShoppingList = [...shoppingListCpy, freeVariant];
        this.setState({
          shoppingList: newShoppingList,
        });
      }
    } else {
      const shoppingListCpy = _.concat(
        newShoppingCart.productList,
        newShoppingCart.boxList
      );
      await this.setState({
        shoppingList: shoppingListCpy,
      });
    }

    await this.setState({
      shippingDate: date,
      selectedShippingDate: dateString,
    });
    if (currentShoppingMode === 'delivery') {
      if (date && dateString) {
        date.set({ h: 18, m: 0, s: 0 });
        console.log(date, date.valueOf());
        newShoppingCart.deliveryTime = date.valueOf();
        console.log('update shipping cart before');
        await updateWarehouseCart(newShoppingCart);
        console.log('update shipping cart after');
      }
    } else if (currentShoppingMode === 'pickup') {
      if (date && dateString) {
        date.set({ h: 18, m: 0, s: 0 });
        await this.setState({
          selectedPickupDate: {
            id: dateString,
            addressId: null,
            pickupTime: dateString,
          },
        });
        newShoppingCart.pickupDate = date.valueOf();
        await updateWarehouseCart(newShoppingCart);
        console.log('update shipping cart for pickup');
      }
    }
    await this.handleVariantsAddons();
  };

  // handleTimeChange = (time, timeString) => {
  //   this.setState({ selectedShippingTime: timeString });
  // };

  checkPriceUpdate = async () => {
    const { getCurrentWarehouseCart } = this.props;
    const { shoppingList } = this.state;
    const shoppingCart = await getCurrentWarehouseCart();
    const freeVariant = shoppingList.find((item) => item.listPrice === 0);
    const shoppingListCpy = _.concat(
      shoppingCart.productList,
      shoppingCart.boxList
    );
    if (freeVariant) {
      shoppingListCpy.push(freeVariant);
    }
    await this.setState({
      shoppingList: shoppingListCpy,
      checkPriceModal: true,
    });
  };

  handleDisableDate = (current) => {
    const {
      availableShippingDate,
      onlyAllowedWeekDay,
      warehouseObj,
      availableSchedulePickUpSlot,
      selectedAddressDetail,
      onlyAllowedDateAddonsCart,
    } = this.state;
    const {
      onlyAllowedDate,
      disableDate,
      currentShoppingMode,
      currentAddressType,
    } = this.props;

    if (
      _.find(disableDate, (date) => {
        return date === moment(current).format('MM-DD');
      })
    ) {
      return true;
    }

    if (onlyAllowedDateAddonsCart && onlyAllowedDateAddonsCart.length > 0) {
      const findDate = _.find(onlyAllowedDateAddonsCart, (date) => {
        return date === moment(current).format('YYYY-MM-DD');
      });
      if (!findDate) {
        return true;
      }
    }

    if (onlyAllowedDate !== null) {
      const findDate = _.find(onlyAllowedDate, (date) => {
        return date === moment(current).format('YYYY-MM-DD');
      });
      if (!findDate) {
        return true;
      }
    }

    // console.log('receiver onlyAllowedWeekDay',onlyAllowedWeekDay)
    if (currentShoppingMode === 'pickup') {
      const defaultStart = moment()
        .startOf('day')
        .add(warehouseObj.cutOffTime, 'h');
      // TODO: remove after holiday change 28 to 14
      const defaultEnd = moment().add(28, 'day').endOf('day');
      let sameDay = false;
      let allowDates = false;
      // mobile time will fail isBetween check
      const defaultDates = !current
        .startOf('day')
        .add(1, 'h')
        .isBetween(defaultStart, defaultEnd);
      if (
        warehouseObj.disallowedPickDates.length &&
        warehouseObj.disallowedPickDates.includes('Today')
      ) {
        sameDay =
          current && current.startOf('day').add(1, 'h') < moment().endOf('day');
      }
      if (
        warehouseObj.allowedPickDates &&
        warehouseObj.allowedPickDates.length
      ) {
        const startDate = moment(warehouseObj.allowedPickDates[0]).startOf(
          'day'
        );
        const startEnd = moment(warehouseObj.allowedPickDates[1]).endOf('day');
        allowDates = !current
          .startOf('day')
          .add(1, 'h')
          .isBetween(startDate, startEnd);
      }
      return sameDay || allowDates || defaultDates;
    }
    if (currentShoppingMode === 'delivery' && currentAddressType === 'pickup') {
      const { prepareDay } = this.state;
      // every warehouse should have availableSchedulePickUpSlot, never trigger
      if (
        !(
          selectedAddressDetail &&
          selectedAddressDetail.pickupWindow &&
          selectedAddressDetail.pickupWindow.length
        ) &&
        !availableSchedulePickUpSlot.length
      ) {
        return true;
      }
      let extraDay = 0;
      let timeArrayDetail = [];
      if (availableSchedulePickUpSlot.length > 0) {
        timeArrayDetail = availableSchedulePickUpSlot || [];
        extraDay = prepareDay || 0;
      } else {
        timeArrayDetail = selectedAddressDetail.pickupWindow || [];
        extraDay = selectedAddressDetail.prepareDay || 0;
      }
      const availableDate = [];
      timeArrayDetail.forEach((hour) => {
        const nextDay = moment().day(hour.day);
        const today = extraDay > 0 ? moment().add(extraDay, 'd') : moment();
        if (today > moment().day(hour.day).startOf('days')) {
          let count = 0;
          while (
            moment()
              .day(hour.day + 7 * count)
              .startOf('days') < today
          ) {
            count += 1;
          }
          const nextDayString = moment()
            .day(hour.day + 7 * count)
            .format('YYYY-MM-DD');
          const oneWeek = moment()
            .day(hour.day + 7 * (count + 1))
            .format('YYYY-MM-DD');
          const twoWeeks = moment()
            .day(hour.day + 7 * (count + 2))
            .format('YYYY-MM-DD');
          availableDate.push(nextDayString);
          availableDate.push(oneWeek);
          availableDate.push(twoWeeks);
        } else {
          const nextDayString = nextDay.format('YYYY-MM-DD');
          const oneWeek = nextDay.add(7, 'days').format('YYYY-MM-DD');
          const twoWeeks = nextDay.add(14, 'days').format('YYYY-MM-DD');

          availableDate.push(nextDayString);
          availableDate.push(oneWeek);
          availableDate.push(twoWeeks);
        }
      });
      const currDate = moment(current).format('YYYY-MM-DD');
      if (availableDate.includes(currDate)) {
        return false;
      }
      return true;
    }

    if (availableShippingDate && availableShippingDate.length > 0) {
      const found = availableShippingDate.findIndex(
        (e) => e === moment(current).format('YYYY-MM-DD')
      );
      if (found >= 0) {
        return false;
      }
      return true;
    }
    // count days
    // TODO: change 20 to 10 after holiday
    if (
      onlyAllowedDate === null &&
      onlyAllowedDateAddonsCart.length === 0 &&
      moment(current) > addWeekdays(moment(), 20)
    ) {
      return true;
    }
    if (current <= moment()) {
      return true;
    }
    if (
      moment(current).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      return true;
    }
    // disable date for weekend
    // if (isMobile) {
    //   if (
    //     onlyAllowedDate === null &&
    //     onlyAllowedDateAddonsCart.length === 0 &&
    //     (moment(current).weekday() === 6 || moment(current).weekday() === 5)
    //   ) {
    //     return true;
    //   }
    // } else
    if (
      onlyAllowedDate === null &&
      onlyAllowedDateAddonsCart.length === 0 &&
      (moment(current).weekday() === 6 || moment(current).weekday() === 0)
    ) {
      return true;
    }

    if (
      onlyAllowedWeekDay !== null &&
      onlyAllowedWeekDay.day &&
      onlyAllowedWeekDay.day.length > 0
    ) {
      const dayDay = moment(current).day();
      if (!onlyAllowedWeekDay.day.includes(dayDay)) {
        return true;
      }
    }

    return false;
  };

  handleDisableMobileDate = (current) => {
    if (current.toString() === 'Invalid Date') {
      return false;
    }
    const res = this.handleDisableDate(moment(current));
    if (res === true) {
      return { info: 'Disable', disable: true };
    }
    return false;
  };

  processProductListSubTotal = (productList) => {
    let sum = 0;
    productList.forEach((product) => {
      if (product.count > 0) {
        sum += product.count * product.listPrice;
      }
    });
    return sum;
  };

  handleVariantsAddons = async () => {
    const { websiteSaleCode, shoppingList, selectedShippingDate } = this.state;
    const { currentShoppingMode, isScheduledDelivery } = this.props;
    if (isScheduledDelivery || currentShoppingMode !== 'delivery') {
      return;
    }
    const input = {
      orderTotal: this.processProductListSubTotal(shoppingList),
    };
    if (websiteSaleCode && websiteSaleCode.code) {
      input.discountCode = websiteSaleCode.code;
    }
    // if (shippingDate) {
    //   input.shippingDate = shippingDate;
    // }
    if (selectedShippingDate) {
      input.shippingDate = selectedShippingDate;
    }
    try {
      const {
        data: { warehouseAddons },
      } = await apolloClient.mutate({
        mutation: VARIANT_ADD_ONS,
        variables: { ...input },
      });

      const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
      warehouseAddons.items.forEach((newElement) => {
        newElement.category = newElement.product.category;
        newElement.hashtags = newElement.product.hashtags;
        newElement.imgUrl = getImg(newElement);
        newElement.id = newElement._id;
        if (newElement.count === undefined) {
          newElement.count = 0;
        }
        newElement.needBox = false;
        newElement.product.imgUrl = getImg(newElement.product);
        if (newElement.markAsSoldOut === true) {
          newElement.soldOut = true;
        }
        if (newElement.product.imgUrl !== DEFAULT_IMAGE) {
          newElement.product.imgUrl = `${BUCKET_CDN_URL}${newElement.product.imgUrl}`;
        }
        if (newElement.imgUrl !== DEFAULT_IMAGE) {
          newElement.imgUrl = `${BUCKET_CDN_URL}${newElement.imgUrl}`;
        } else {
          newElement.imgUrl = newElement.product.imgUrl;
        }
        if (newElement.isChangeListPriceByDates) {
          // check date and change list price
          const today = moment().valueOf();
          if (
            today > newElement.changeListPriceDates.endDate ||
            today < newElement.changeListPriceDates.startDate
          ) {
            newElement.listPrice = newElement.originalPrice;
          }
        }
      });

      await this.setState({
        warehouseAddons,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleDisabledHours = () =>
    [...Array(11).keys()].concat([18, 19, 20, 21, 22, 23]);

  handleCheckCouponSubmit = async (websiteSaleValue = false) => {
    let websiteSale = websiteSaleValue;
    const { validCouponCode } = this.state;
    let { couponCode } = this.state;
    const {
      // shoppingCart,
      getCurrentWarehouseCart,
      updateWarehouseCart,
      // updateShoppingCart,
      intl,
      currentShoppingMode,
      currentAddressType,
      warehouseId,
    } = this.props;
    let discountObj;
    const shoppingCart = await getCurrentWarehouseCart();
    if (validCouponCode) {
      const newShoppingList = _.concat(
        shoppingCart.productList,
        shoppingCart.boxList
      );
      this.setState({
        validCouponCode: '',
        discountAmount: 0,
        couponCode: null,
        selectedDiscount: null,
        shoppingList: newShoppingList,
      });
      shoppingCart.discountCode = null;
      await updateWarehouseCart(shoppingCart);
      couponCode = null;
      websiteSale = true;
    }
    // await this.setState({ loading: true });
    const shoppingList = _.concat(
      shoppingCart.productList,
      shoppingCart.boxList
    );

    // console.log('warehouseId', warehouseId);

    console.log(currentAddressType);

    const customerOrder = {
      discountCode: couponCode,
      variantInput: customerOrderFormatter(shoppingList),
      pseudoOrderType: pseudoTypeFormatter(
        currentShoppingMode,
        currentAddressType
      ),
      warehouseId,
      realMode: false,
    };

    if (currentShoppingMode === 'delivery') {
      const referralCode = window.sessionStorage.getItem('referralCode');
      const referralOrderId = window.sessionStorage.getItem('referralOrderId');
      if (referralCode && referralOrderId) {
        customerOrder.referralCode = referralCode;
        customerOrder.referralOrderId = referralOrderId;
      }
    }

    let hasFreeVariant = false;
    let newShoppingList = null;
    if (websiteSale === false) {
      try {
        const {
          data: { discountFindOne },
        } = await apolloClient.query({
          query: FETCH_DISCOUNT_ONE,
          variables: { filter: { code: couponCode } },
          fetchPolicy: 'network-only',
        });
        if (discountFindOne) {
          discountObj = discountFindOne;
          const freeVariant = discountFindOne.freeVariant && {
            ...discountFindOne.freeVariant,
          };
          if (freeVariant) {
            hasFreeVariant = true;
            freeVariant.count = 1;
            freeVariant.listPrice = 0;
            freeVariant.imgUrl = discountFindOne.freeVariant.picture
              ? BUCKET_URL + discountFindOne.freeVariant.picture.key
              : BUCKET_URL + discountFindOne.freeVariant.product.picture.key;
            newShoppingList = [...shoppingList, freeVariant];
          }
        } else {
          this.setState({
            selectedDiscount: null,
          });
          const warnMsg = discountErrorFormatter(
            discountObj,
            'errorDiscountInvalidCode',
            intl
          );
          Modal.warning({
            content: warnMsg,
            onOk: async () => {
              Modal.destroyAll();
            },
          });
          // await this.setState({ loading: false });
          return;
        }
      } catch (error) {
        message.error('error happened during fetching discount', 15);
      }
    }

    try {
      const { data } = await apolloClient.mutate({
        mutation: CUSTOMER_ORDER_CREATE,
        variables: customerOrder,
      });
      console.log(data.orderCreateCustomerOrder);
      if (discountObj) {
        this.setState({
          selectedDiscount: discountObj,
        });
      }
      if (newShoppingList) {
        this.setState({
          shoppingList: newShoppingList,
        });
      }
      if (
        data.orderCreateCustomerOrder._id &&
        data.orderCreateCustomerOrder.discountAmount >= 0
      ) {
        const { discountAmount } = data.orderCreateCustomerOrder;
        if (discountAmount === 0 && hasFreeVariant === false) {
          if (websiteSale === false) {
            Modal.warning({
              content: intl.formatMessage({ id: 'errorDiscountZeroDiscount' }),
              onOk: async () => {
                Modal.destroyAll();
              },
            });
          }
        } else {
          const { group } = this.props;
          const setStateInput = {
            validCouponCode: couponCode,
            // discountAmount,
          };
          // guest mode does not apply discount
          if (!(group && group.name === 'guest')) {
            setStateInput.discountAmount = discountAmount;
          }
          if (websiteSale === false) {
            setStateInput.websiteSaleCode = null;
            shoppingCart.discountCode = couponCode;
            await updateWarehouseCart(shoppingCart);
          } else if (data.orderCreateCustomerOrder.discount) {
            setStateInput.websiteSaleCode =
              data.orderCreateCustomerOrder.discount;
          }
          message.success(intl.formatMessage({ id: 'codeSuccess' }));
          await this.setState(setStateInput);
        }
      } else {
        Modal.warning({
          content: 'error happened applying code',
          onOk: async () => {
            Modal.destroyAll();
          },
        });
      }
    } catch (error) {
      console.error(error);
      const warnMsg = discountErrorFormatter(
        discountObj,
        error.graphQLErrors[0].message,
        intl
      );
      Modal.warning({
        content: warnMsg,
        onOk: async () => {
          Modal.destroyAll();
        },
      });
      // message.error('error happened during applying code',15)
    }
    // await this.setState({ loading: false });
  };

  handlePaymentSuccess = async () => {
    // await this.setState({ paymentSuccess: true });
    // push to order page
  };

  selectDefaultDate = async (isDisabled, date) => {
    if (isDisabled) {
      return;
    }
    const { shippingDate } = this.state;
    const {
      getCurrentWarehouseCart,
      currentShoppingMode,
      currentAddressType,
      updateWarehouseCart,
    } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    if (currentShoppingMode === 'pickup' && currentAddressType === 'pickup') {
      // cloud store mode, already has default value
      return;
    }
    if (currentShoppingMode === 'pickup' && currentAddressType !== 'pickup') {
      // not first time , return
      if (shippingDate) {
        return;
      }
      // first time
      shoppingCart.pickupDate = date.valueOf();
      console.log('update shopping cart fro pickup');
      await updateWarehouseCart({ ...shoppingCart });
      this.setState({
        shippingDate: moment(date),
        selectedShippingDate: moment(date).format('YYYY-MM-DD'),
      });
      await this.handleVariantsAddons();
    } else {
      // not first time , return
      if (shippingDate) {
        return;
      }
      // first time
      shoppingCart.deliveryTime = date.valueOf();
      console.log('update shopping cart for delivery');
      await updateWarehouseCart({ ...shoppingCart });
      this.setState({
        shippingDate: moment(date),
        selectedShippingDate: moment(date).format('YYYY-MM-DD'),
      });
      await this.handleVariantsAddons();
    }
  };

  handleIsRegistered = async (checked) => {
    if (checked) {
      const { userEmail } = this.state;
      try {
        const {
          data: { userOne },
        } = await apolloClient.query({
          query: USER_FIND_ONE,
          variables: { filter: { email: userEmail } },
        });
        if (userOne) {
          this.setState({
            hasRegisteredModal: true,
          });
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
    this.setState({
      isRegistered: checked,
    });
  };

  handleAddToShoppingCart = async (shoppingList) => {
    const { warehouseAddons, onlyAllowedDateAddonsCart } = this.state;
    const { getCurrentWarehouseCart, updateWarehouseCart, language, intl } =
      this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    shoppingList.forEach((item) => {
      const index = shoppingCart.productList.findIndex(
        (product) => product._id === item._id
      );
      if (index > -1) {
        shoppingCart.productList[index].count += item.count;
      } else {
        shoppingCart.productList.push(item);
      }
    });
    await updateWarehouseCart({ ...shoppingCart });

    // refresh
    const newShoppingCart = await getCurrentWarehouseCart();
    this.setState({
      shoppingList: _.concat(
        newShoppingCart.productList,
        newShoppingCart.boxList
      ),
    });
    await this.handleCheckCouponSubmit(true);
    await this.handleVariantsAddons();

    // reset disabledDate
    let newOnlyAllowedDate = [...onlyAllowedDateAddonsCart];
    const variantNameNote = [];
    shoppingList.forEach((variant) => {
      if (variant.allowedShippingDate.length > 0) {
        if (!newOnlyAllowedDate.length) {
          newOnlyAllowedDate = variant.allowedShippingDate;
        } else {
          newOnlyAllowedDate = _.intersection(
            newOnlyAllowedDate,
            variant.allowedShippingDate
          );
        }
        variantNameNote.push(variant.name[language]);
      }
    });
    this.setState({
      onlyAllowedDateAddonsCart: newOnlyAllowedDate,
    });

    if (newOnlyAllowedDate.length > 0) {
      if (newOnlyAllowedDate.length === 0) {
        Modal.success({
          content: intl.formatMessage(
            { id: 'noShippingDateAvailable' },
            { variantName: variantNameNote }
          ),
          onOk: async () => {
            Modal.destroyAll();
          },
          icon: <InfoCircleTwoTone />,
        });
      } else {
        Modal.success({
          content: intl.formatMessage(
            { id: 'limitedShippingDate' },
            {
              shippingDates: newOnlyAllowedDate
                .filter((date) => {
                  const today = moment();
                  return (
                    today.diff(moment(date), 'day') <= 0 &&
                    today.isSame(moment(date.trim()), 'day') === false
                  );
                })
                .map((date) => {
                  if (language === 'zh') {
                    return moment(date)
                      .locale('zh-cn')
                      .format('MMM Do[ (]dddd[)]');
                  }
                  return moment(date).format('MMM Do[ (]dddd[)]');
                })
                .join(','),
              variantName: variantNameNote.join(','),
            }
          ),
          onOk: async () => {
            Modal.destroyAll();
          },
          icon: <InfoCircleTwoTone />,
        });
      }
    }

    // clean up add on list
    if (warehouseAddons && warehouseAddons.items.length > 0) {
      warehouseAddons.items.forEach((item) => {
        item.count = 0;
      });
    }
    this.setState({ addonsModal: false });
  };

  checkIfContainGroupBuyVariant = (array) => {
    let result = false;
    array.forEach((item) => {
      if (item?.isGroupBuy) {
        result = true;
      }
    });
    return result;
  };

  render() {
    const {
      currentShoppingMode,
      currentAddressType,
      language,
      intl,
      personalDiscount,
      warehouseDeliveryMode,
      onlyAllowedDateWithTime,
      isScheduledDelivery,
      group,
    } = this.props;

    const {
      shoppingList,
      selectedAddressDetail,
      discountAmount,
      // invalidCodeMessage,
      mobileCalendarVisibility,
      boxContentModal,
      boxContent,
      selectedShippingDate,
      lockDatePicker,
      shippingDate,
      websiteSaleCode,
      validCouponCode,
      // selectedShippingTime,
      couponCode,
      popCouponVisible,
      selectedAddress,
      taxRate,
      deliveryFee,
      deliveryMode,
      deliveryMinimumAmount,
      deliveryDistanceRange,
      deliveryDurationPreMinute,
      deliveryDistancePreMile,
      scheduledDeliveryTimeRangeStart,
      scheduledDeliveryTimeRangeEnd,
      marketMinimumAmountForShippingFree,
      marketShippingFee,
      warehouseObj,
      // selectedDiscount,
      checkPriceModal,
      selectedPickupDate,
      pickupOptionsPage,
      fmPickupTimeSlotFormatted,
      hasRegisteredModal,
      isRegistered,
      warehouseAddons,
      addonsModal,
    } = this.state;

    const pickupOptionsRender = (pickupAddressArray) => {
      if (!pickupAddressArray.length) {
        return null;
      }
      const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
      const validDates = [];
      pickupAddressArray.forEach((pickupAddress) => {
        let fullAddresses = [];
        const keys = ['addressOne', 'addressTwo', 'city', 'zipCode'];
        keys.forEach((key) => {
          if (pickupAddress[key]) {
            fullAddresses.push(pickupAddress[key]);
          }
        });
        fullAddresses = fullAddresses.join(', ');
        // generate valid dates

        pickupAddress.days.forEach((day) => {
          const {
            allowedDateRange: { startDate, endDate },
            endTime,
            startTime,
            cutOffTime,
          } = pickupAddress;
          let count = 0;
          let date = moment().add(count, 'w').day(day);
          while (date.startOf('day').isBefore(moment(endDate))) {
            const dateStr = date.format('YYYY-MM-DD');
            const startTimeStr = `${
              startTime.hours < 10 ? `0${startTime.hours}` : startTime.hours
            }:${
              startTime.minutes < 10
                ? `0${startTime.minutes}`
                : startTime.minutes
            }`;

            const endTimeStr = `${
              endTime.hours < 10 ? `0${endTime.hours}` : endTime.hours
            }:${
              endTime.minutes < 10 ? `0${endTime.minutes}` : endTime.minutes
            }`;
            const dateWithHours = moment(`${dateStr} ${startTimeStr}`);
            // console.log(startTimeStr, dateWithHours.format('YYYY-MM-DD HH:mm'));
            if (
              date.startOf('day').isBetween(startDate, endDate) &&
              dateWithHours.isAfter(moment().add(cutOffTime, 'hour'))
            ) {
              // console.log(`${dateStr} ${startTimeStr} ~ ${endTimeStr}`);
              validDates.push({
                _id: pickupAddress._id,
                date,
                dateStr: `${dateStr} ${startTimeStr} ~ ${endTimeStr}`,
                hourStr: `${startTimeStr} ~ ${endTimeStr}`,
                fullAddresses,
              });
            }
            count += 1;
            date = moment().add(count, 'w').day(day);
          }
        });
      });

      validDates.sort((a, b) => a.date - b.date);
      // console.log('valid date', validDates);
      this.pickupOptionsArray = validDates;

      const handleSelected = async (obj) => {
        const pickupDate = moment(obj.date).valueOf();
        const shoppingCart = await getCurrentWarehouseCart();
        shoppingCart.pickupDate = pickupDate;
        await updateWarehouseCart({ ...shoppingCart });
        await this.setState({
          selectedPickupDate: {
            id: obj._id + obj.dateStr,
            addressId: obj._id,
            pickupTime: obj.dateStr,
          },
        });
      };

      if (validDates.length === 0) {
        return <div>No Options</div>;
      }

      if (validDates.length > 0 && !selectedPickupDate) {
        handleSelected(validDates[0]);
      }

      return validDates
        .map((obj) => (
          <div
            key={obj._id + obj.dateStr}
            className="card-container"
            style={{
              width: isMobile ? 'calc(95vw - 35px)' : '336px',
              border:
                selectedPickupDate &&
                selectedPickupDate.id === obj._id + obj.dateStr
                  ? '2px solid red'
                  : 'none',
            }}
            role="presentation"
            onClick={() => {
              handleSelected(obj);
            }}
          >
            <div>
              <div>{`${moment(obj.date).format('MM-DD ddd')} ${
                obj.hourStr
              }`}</div>
              <div>{obj.fullAddresses}</div>
            </div>
            <div>
              <CheckOutlined
                style={{
                  color:
                    selectedPickupDate &&
                    selectedPickupDate.id === obj._id + obj.dateStr
                      ? 'green'
                      : '#ccc',
                }}
              />
            </div>
          </div>
        ))
        .slice(0, pickupOptionsPage * 4);
    };

    const formattedPickupTime = (array, date) => {
      let translationDate = date;
      switch (date) {
        case '周一':
          translationDate = 'Mon';
          break;
        case '周二':
          translationDate = 'Tue';
          break;
        case '周三':
          translationDate = 'Wed';
          break;
        case '周四':
          translationDate = 'Thu';
          break;
        case '周五':
          translationDate = 'Fri';
          break;
        case '周六':
          translationDate = 'Sat';
          break;
        case '周日':
          translationDate = 'Sun';
          break;
        default:
          translationDate = date;
      }
      if (!(array && array.length)) {
        return null;
      }
      return array
        .find((item) => item.includes(translationDate))
        .replace(translationDate, '');
    };

    const deliveryTimeRender = () => {
      if (
        scheduledDeliveryTimeRangeStart !== 0 &&
        scheduledDeliveryTimeRangeEnd !== 0
      ) {
        return (
          <span>
            {language === 'zh'
              ? `您的订单会在该日期的 ${scheduledDeliveryTimeRangeStart}:00 - ${scheduledDeliveryTimeRangeEnd}:00 配送到您家`
              : `Your order will be delivered on this date between ${scheduledDeliveryTimeRangeStart}:00 - ${scheduledDeliveryTimeRangeEnd}:00.`}
          </span>
        );
      }
      if (warehouseDeliveryMode) {
        if (onlyAllowedDateWithTime && onlyAllowedDateWithTime.length > 0) {
          console.log(selectedShippingDate);
          const foundDate = onlyAllowedDateWithTime.find(
            (item) => item.date === selectedShippingDate
          );
          if (foundDate) {
            return (
              <span>
                {language === 'zh'
                  ? `您的订单会在该日期的 ${foundDate.startTime}~${foundDate.endTime} 配送到您家`
                  : `Your order will be delivered on this date between ${foundDate.startTime}~${foundDate.endTime}`}
              </span>
            );
          }
        }
      }
      return (
        <span>
          {language === 'zh'
            ? '您的订单会在该日期的 15:00~19:00 配送到您家'
            : 'Your order will be delivered on this date between 15:00~19:00'}
        </span>
      );
    };

    const renderShippingDate = () => {
      if (
        currentShoppingMode === 'delivery' ||
        currentShoppingMode === 'pickup'
      ) {
        const flag = false;
        return (
          <div className={isMobile ? 'common-section-card' : null}>
            {currentShoppingMode !== 'pickup' && (
              <Row style={isMobile ? {} : { margin: '10px 0px' }}>
                {isMobile ? null : (
                  <div
                    css={{
                      backgroundColor: '#DF242F',
                      display: 'inline-block',
                      width: '5px',
                      height: '20px',
                      marginRight: '5px',
                    }}
                  />
                )}
                <span className={isMobile ? 'common-label' : ''}>
                  <FormattedMessage
                    id={
                      currentAddressType === 'delivery'
                        ? 'shippingDate'
                        : 'pickupTimeWindow'
                    }
                  />{' '}
                </span>

                {selectedShippingDate ? (
                  successIcon
                ) : (
                  <span css={{ color: '#DF242F' }}>
                    {questionIcon}{' '}
                    <FormattedMessage
                      id={
                        currentAddressType === 'delivery'
                          ? 'pleaseSelectShippingDate'
                          : 'pleaseSelectPickupDate'
                      }
                    />
                  </span>
                )}
              </Row>
            )}
            {isMobile && currentShoppingMode !== 'pickup' ? (
              <hr className="common-divider-line" />
            ) : null}
            {!isMobile &&
            currentShoppingMode === 'delivery' &&
            currentAddressType === 'delivery' ? (
              <Alert
                css={{ margin: '10px 0px' }}
                message={<span>{deliveryTimeRender()}</span>}
                type="warning"
              />
            ) : null}
            {/* time picker for delivery only */}
            <Row type="flex" justify="center">
              {flag ? (
                <React.Fragment>
                  {currentShoppingMode !== 'pickup' && (
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {/* {shippingDate ? (
                        <Button type="primary">
                          <CalendarOutlined />{' '}
                          {moment(selectedShippingDate).format('MM-DD ddd')}
                        </Button>
                      ) : (
                        <Button type="primary">
                          <CalendarOutlined />{' '}
                          <FormattedMessage id="pleaseChooseDate" />
                        </Button>
                      )} */}
                      <div style={{ fontWeight: '500', fontSize: '16px' }}>
                        <CalendarOutlined />{' '}
                        {shippingDate ? (
                          <span>
                            {moment(selectedShippingDate).format('MM-DD ddd')}
                            {currentShoppingMode === 'delivery' &&
                              currentAddressType === 'delivery' &&
                              ` ${scheduledDeliveryTimeRangeStart || 15}:00 ~ ${
                                scheduledDeliveryTimeRangeEnd || 19
                              }:00`}
                            {currentShoppingMode === 'delivery' &&
                              currentAddressType === 'pickup' &&
                              formattedPickupTime(
                                selectedAddressDetail.pickupTimeSlotFormatted ||
                                  fmPickupTimeSlotFormatted,
                                moment(shippingDate).format('ddd')
                              )}
                          </span>
                        ) : (
                          <FormattedMessage id="pleaseChooseDate" />
                        )}
                      </div>
                      <div>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            if (!lockDatePicker) {
                              this.setState({ mobileCalendarVisibility: true });
                            }
                          }}
                          role="presentation"
                        >
                          <FormattedMessage id="edit" />
                        </Button>
                      </div>
                    </div>
                  )}
                  {currentAddressType === 'pickup' &&
                    currentShoppingMode === 'pickup' &&
                    shippingDate &&
                    warehouseObj && (
                      <div
                        key={moment(shippingDate).format('YYYY-MM-DD')}
                        className="card-container"
                        role="presentation"
                        onClick={async () => {
                          await this.setState({
                            selectedPickupDate: {
                              id: moment(shippingDate).format('YYYY-MM-DD'),
                              addressId: null,
                              pickupTime:
                                moment(shippingDate).format('YYYY-MM-DD'),
                            },
                          });
                        }}
                      >
                        <div>
                          <div>
                            {language === 'zh'
                              ? moment(shippingDate)
                                  .locale('zh-cn')
                                  .format('MM-DD [(]dddd[)]')
                              : moment(shippingDate).format('MM-DD [(]dddd[)]')}
                          </div>
                          <div>{`${warehouseObj.addressOne},${warehouseObj.city},${warehouseObj.zip}`}</div>
                        </div>
                        <div>
                          {selectedPickupDate &&
                            selectedPickupDate.id ===
                              moment(shippingDate).format('YYYY-MM-DD') && (
                              <CheckOutlined style={{ color: 'green' }} />
                            )}
                        </div>
                      </div>
                    )}
                </React.Fragment>
              ) : (
                <div style={{ width: '100%' }}>
                  {currentShoppingMode !== 'pickup' && (
                    <DatePicker
                      autoFocus
                      onChange={async (date, dateString) => {
                        await this.handleDateChange(date, dateString);
                      }}
                      value={shippingDate}
                      format="MM-DD [(]dddd[)]"
                      disabledDate={this.handleDisableDate}
                      disabled={lockDatePicker}
                      placeholder={intl.formatMessage({
                        id: 'pleaseChooseDate',
                      })}
                      showToday={false}
                      css={{
                        width: '100%',
                      }}
                      dropdownClassName="payment-calendar-dropdown"
                    />
                  )}
                </div>
              )}
            </Row>

            {/* {isMobile &&
            currentShoppingMode === 'delivery' &&
            currentAddressType === 'delivery' ? (
              <div
                css={{
                  margin: '10px 0px',
                  textAlign: 'center',
                  color: '#DF242F',
                  fontSize: '13px',
                }}
              >
                <span>*{deliveryTimeRender()}</span>
              </div>
            ) : null} */}

            {/* {isMobile &&
            currentShoppingMode === 'delivery' &&
            currentAddressType === 'pickup' ? (
              <div
                css={{
                  margin: '10px 0px',
                  textAlign: 'center',
                  color: '#DF242F',
                  fontSize: '13px',
                }}
              >
                {shippingDate && (
                  <span>
                    *
                    <span> */}
            {/* {language === 'zh'
                        ? `您可以在该日期的 ${formattedPickupTime(
                            selectedAddressDetail.pickupTimeSlotFormatted ||
                              fmPickupTimeSlotFormatted,
                            moment(shippingDate).format('ddd')
                          )} 取走您的订单`
                        : `Your order will be ready for pickup on this date between ${formattedPickupTime(
                            selectedAddressDetail.pickupTimeSlotFormatted ||
                              fmPickupTimeSlotFormatted,
                            moment(shippingDate).format('ddd')
                          )}.`} */}
            {/* {language === 'zh'
                        ? '请您在以上日期取货'
                        : 'Please pick up by the date above'}
                    </span>
                  </span>
                )}
              </div>
            ) : null} */}

            {currentAddressType === 'pickup' &&
              currentShoppingMode === 'pickup' &&
              warehouseObj &&
              warehouseObj.pickupAddresses.length > 0 && (
                <React.Fragment>
                  <Row style={isMobile ? {} : { margin: '10px 0px' }}>
                    {!isMobile && (
                      <div
                        css={{
                          backgroundColor: '#DF242F',
                          display: 'inline-block',
                          width: '5px',
                          height: '20px',
                          marginRight: '5px',
                        }}
                      />
                    )}
                    <span className={isMobile ? 'common-label' : null}>
                      <FormattedMessage id="pickupOptions" />
                    </span>
                  </Row>
                  {isMobile ? <hr className="common-divider-line" /> : null}
                  <Row>
                    <div className="card-outer-container ">
                      {pickupOptionsRender(warehouseObj.pickupAddresses)}
                    </div>
                    {this.pickupOptionsArray.length >=
                      4 * pickupOptionsPage && (
                      <div style={{ width: '100%' }}>
                        <div
                          css={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                          role="presentation"
                          onClick={() => {
                            this.setState((prevState) => {
                              return {
                                pickupOptionsPage:
                                  prevState.pickupOptionsPage + 1,
                              };
                            });
                          }}
                        >
                          <div>
                            <FormattedMessage id="moreOptions" />
                          </div>
                          <div>
                            <DownOutlined />
                          </div>
                        </div>
                      </div>
                    )}
                  </Row>
                </React.Fragment>
              )}
          </div>
        );
      }
      return null;
    };

    const renderAnnouncement = () => {
      if (
        currentShoppingMode === 'pickup' &&
        warehouseObj &&
        ((language === 'en' &&
          BraftEditor.createEditorState(
            convertRawToHTML(JSON.parse(warehouseObj.announcement.en || '{}'))
          ).toText()) ||
          (language === 'zh' &&
            BraftEditor.createEditorState(
              convertRawToHTML(JSON.parse(warehouseObj.announcement.zh || '{}'))
            ).toText()))
      ) {
        return (
          <div className={isMobile ? 'common-section-card' : null}>
            <div
              className={isMobile ? 'common-label' : ''}
              style={isMobile ? {} : { margin: '10px 0' }}
            >
              {!isMobile && (
                <div
                  css={{
                    backgroundColor: '#DF242F',
                    display: 'inline-block',
                    width: '5px',
                    height: '20px',
                    marginRight: '5px',
                  }}
                />
              )}
              <FormattedMessage id="announcement" />
            </div>
            {isMobile ? <hr className="common-divider-line" /> : null}
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {language === 'en'
                ? warehouseObj &&
                  BraftEditor.createEditorState(
                    convertRawToHTML(
                      JSON.parse(warehouseObj.announcement.en || '{}')
                    )
                  ).toText()
                : warehouseObj &&
                  BraftEditor.createEditorState(
                    convertRawToHTML(
                      JSON.parse(warehouseObj.announcement.zh || '{}')
                    )
                  ).toText()}
            </div>
          </div>
        );
      }
      return null;
    };

    const renderShippingAddress = () => {
      if (
        currentShoppingMode === 'donation' ||
        currentShoppingMode === 'eShop'
      ) {
        return null;
      }
      return (
        <div className={isMobile ? 'common-section-card' : null}>
          <div
            className={isMobile ? 'common-label' : ''}
            style={isMobile ? {} : { margin: '10px 0' }}
          >
            {!isMobile && (
              <div
                css={{
                  backgroundColor: '#DF242F',
                  display: 'inline-block',
                  width: '5px',
                  height: '20px',
                  marginRight: '5px',
                }}
              />
            )}
            {currentAddressType === 'delivery' ? (
              <FormattedMessage id="deliveryAddress" />
            ) : (
              <FormattedMessage id="contactInfo" />
            )}
          </div>
          {isMobile ? <hr className="common-divider-line" /> : null}
          <div
            css={
              isMobile
                ? {
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }
                : {}
            }
          >
            {currentAddressType === 'delivery'
              ? addressFormatter(selectedAddressDetail)
              : addressFormatterOnlyName(selectedAddressDetail)}
            <Button
              style={{ marginLeft: '5px' }}
              onClick={this.handleClickButton}
              type="primary"
              size="small"
            >
              <FormattedMessage id="edit" />{' '}
            </Button>
          </div>
          {currentAddressType === 'pickup' &&
          currentShoppingMode === 'delivery' ? (
            <React.Fragment>
              <div style={{ margin: '10px 0' }}>
                <FormattedMessage id="pickupLocation" />
              </div>
              <div>{addressFormatterOnlyAddress(selectedAddressDetail)}</div>
            </React.Fragment>
          ) : null}
        </div>
      );
    };

    const validateCouponCode = () => {
      if (!validCouponCode && personalDiscount.length > 0) {
        const filterArray = personalDiscount.filter((coupon) => {
          if (
            moment(coupon.endDate).isBefore(moment()) ||
            (coupon.usageLimit !== null &&
              coupon.usageLimit >= 0 &&
              coupon.usageLimit === coupon.used)
          ) {
            return false;
          }
          return true;
        });
        return filterArray.length > 0;
      }
      return false;
    };

    // const renderCoupon = () => {
    //   if (group && group.name === 'guest') {
    //     return null;
    //   }
    //   if (currentShoppingMode === 'delivery') {
    //     return (
    //       <div
    //         className={isMobile ? 'common-section-card' : null}
    //         style={isMobile ? {} : { margin: '10px 0' }}
    //       >
    //         <div>
    //           {!isMobile && (
    //             <div
    //               css={{
    //                 backgroundColor: '#DF242F',
    //                 display: 'inline-block',
    //                 width: '5px',
    //                 height: '20px',
    //                 marginRight: '5px',
    //               }}
    //             />
    //           )}
    //           {!validCouponCode ? (
    //             <span className={isMobile ? 'common-label' : null}>
    //               <FormattedMessage id="handInputCoupon" />
    //             </span>
    //           ) : (
    //             <span>
    //               <FormattedMessage id="payment.currentUsingCoupon" />
    //             </span>
    //           )}
    //           {websiteSaleCode && websiteSaleCode.name ? (
    //             <div css={{ margin: '10px 0 0 0' }}>
    //               <Alert
    //                 message={intl
    //                   .formatMessage({ id: 'replaceWebsiteSale' })
    //                   .replace('[xx.xx]', websiteSaleCode.name[language])}
    //                 type="info"
    //               />
    //             </div>
    //           ) : null}
    //         </div>
    //         <Col
    //           span={24}
    //           css={{
    //             // border: '1px solid #f2f2f2',
    //             paddingRight: '5px',
    //             paddingLeft: '5px',
    //           }}
    //         >
    //           <Row type="flex" justify="start" align="middle">
    //             <Col
    //               // span={(() => {
    //               //   if (isMobile) {
    //               //     return 24;
    //               //   }
    //               //   if (validCouponCode || personalDiscount.length === 0) {
    //               //     return 24;
    //               //   }
    //               //   return 12;
    //               // })()}
    //               span={24}
    //               css={
    //                 {
    //                   // borderRight: '1px solid #f2f2f2',
    //                   // paddingRight: '5px',
    //                 }
    //               }
    //             >
    //               <Row
    //                 type="flex"
    //                 justify="space-between"
    //                 align="middle"
    //                 gutter={10}
    //               >
    //                 <Col span={24}>
    //                   <div css={{ display: 'flex' }}>
    //                     {validCouponCode ? (
    //                       <div css={{ margin: '15px 0' }}>
    //                         <div>
    //                           <FormattedMessage id="currentCoupon" />:
    //                           <span
    //                             style={{ fontWeight: 'bolder' }}
    //                           >{` ${validCouponCode}`}</span>
    //                         </div>
    //                         <div>
    //                           <span style={{ fontWeight: 'bolder' }}>
    //                             {language === 'zh'
    //                               ? `${selectedDiscount.name.zh}`
    //                               : `${selectedDiscount.name.en}`}
    //                           </span>
    //                         </div>
    //                         <div>
    //                           <React.Fragment>
    //                             {validCouponCode && selectedDiscount
    //                               ? selectedDiscount.freeVariant && (
    //                                   <div>
    //                                     <img
    //                                       style={{
    //                                         height: '90px',
    //                                         borderRadius: '4px',
    //                                       }}
    //                                       src={
    //                                         selectedDiscount.freeVariant.picture
    //                                           ? BUCKET_URL +
    //                                             selectedDiscount.freeVariant
    //                                               .picture.key
    //                                           : BUCKET_URL +
    //                                             selectedDiscount.freeVariant
    //                                               .product.picture.key
    //                                       }
    //                                       alt="free variant"
    //                                     />
    //                                   </div>
    //                                 )
    //                               : null}
    //                           </React.Fragment>
    //                         </div>
    //                       </div>
    //                     ) : (
    //                       <React.Fragment>
    //                         <Input
    //                           name="note"
    //                           placeholder={intl.formatMessage({
    //                             id: 'haveCoupon',
    //                           })}
    //                           value={couponCode}
    //                           css={{ margin: '15px 0px', width: '100%' }}
    //                           onChange={(e) => {
    //                             this.setState({
    //                               couponCode: e.target.value.toUpperCase(),
    //                               invalidCodeMessage: '',
    //                             });
    //                           }}
    //                         />
    //                       </React.Fragment>
    //                     )}
    //                   </div>
    //                 </Col>
    //               </Row>
    //             </Col>
    //             <div
    //               css={{
    //                 display: 'flex',
    //                 justifyContent: 'space-between',
    //                 width: '100%',
    //                 marginBottom: '5px',
    //               }}
    //             >
    //               <Button
    //                 onClick={() => {
    //                   this.handleCheckCouponSubmit();
    //                 }}
    //                 type={validCouponCode ? 'default' : 'primary'}
    //                 size="small"
    //               >
    //                 {validCouponCode ? (
    //                   <FormattedMessage id="remove" />
    //                 ) : (
    //                   <FormattedMessage id="apply" />
    //                 )}
    //               </Button>
    //               <div css={{ textAlign: 'center' }}>
    //                 <Button
    //                   type="primary"
    //                   css={{ display: 'flex' }}
    //                   onClick={() => {
    //                     this.setState({
    //                       popCouponVisible: true,
    //                     });
    //                   }}
    //                   size="small"
    //                 >
    //                   <div>
    //                     <RedEnvelopeOutlined />
    //                   </div>
    //                   <FormattedMessage id="selectFromWallet" />
    //                 </Button>
    //               </div>
    //             </div>
    //             {/* {validateCouponCode() ? (

    //             ) : null} */}
    //           </Row>
    //           {invalidCodeMessage ? (
    //             <Alert
    //               style={{ fontSize: '12px' }}
    //               message={invalidCodeMessage}
    //               type="error"
    //             />
    //           ) : null}
    //         </Col>
    //       </div>
    //     );
    //   }
    //   return null;
    // };

    const renderEmail = () => {
      const { userEmail, invalidEmailMessage, isResisterLoading } = this.state;
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      const meta = [
        {
          key: 'password',
          widget: 'password',
          onChange: () => {
            const form = this.formRef.current;
            if (form.isFieldTouched('confirmPassword')) {
              form.validateFields(['confirmPassword']);
            }
          },
          widgetProps: {
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
          },
          placeholder: intl.formatMessage({ id: 'password' }),
          rules: [
            // This is equivalent with "required: true"
            {
              required: true,
              min: 6,
              message: intl.formatMessage({ id: 'plsPassword' }),
            },
            {
              required: true,
              message: 'Password is required',
            },
          ],
        },
        {
          key: 'confirmPassword',
          widget: 'password',
          required: true,
          widgetProps: {
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
          },
          placeholder: intl.formatMessage({ id: 'confirmPassword' }),
          rules: [
            {
              validator: (rule, value, callback) => {
                const form = this.formRef.current;
                if (value !== form.getFieldValue('password')) {
                  callback(new Error('Two passwords are inconsistent.'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
      ];

      const handleClickSignUpButton = async (values) => {
        const { password } = values;
        const { userId, getCurrentUser } = this.props;
        this.setState({
          isResisterLoading: true,
        });
        try {
          const {
            data: { guestConvertToCustomer },
          } = await apolloClient.mutate({
            mutation: GUEST_SIGN_UP,
            variables: {
              userId,
              password,
              userEmail,
            },
          });
          console.log(guestConvertToCustomer);
          try {
            await apolloClient.mutate({
              mutation: SUBSCRIBER_CREATE,
              variables: { email: userEmail },
            });
          } catch (error) {
            console.error(error);
          }
          await getCurrentUser();
          await this.handleCheckCouponSubmit(true);
          this.setState({
            isResisterLoading: false,
          });
        } catch (error) {
          console.log(error);
          this.setState({
            isResisterLoading: false,
          });
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].message === 'fakeEmailError'
          ) {
            Modal.warning({
              content: intl.formatMessage({ id: 'fakeEmailError' }),
            });
          } else if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].message === 'Registered'
          ) {
            Modal.warning({
              content:
                language === 'zh'
                  ? '邮件已注册，请直接登录'
                  : 'Email has already registered, please log in directly.',
            });
          } else {
            message.error('Can not sign up, please try again');
          }
        }
      };

      if (group && group.name === 'guest') {
        return (
          <div className={isMobile ? 'common-section-card' : null}>
            <div
              className={isMobile ? 'common-label' : ''}
              style={isMobile ? {} : { margin: '10px 0' }}
            >
              {!isMobile && (
                <div
                  css={{
                    backgroundColor: '#DF242F',
                    display: 'inline-block',
                    width: '5px',
                    height: '20px',
                    marginRight: '5px',
                  }}
                />
              )}
              <FormattedMessage id="email" />{' '}
              {userEmail && !invalidEmailMessage ? (
                successIcon
              ) : (
                <span css={{ color: '#DF242F' }}>{questionIcon}</span>
              )}
            </div>
            {isMobile ? <hr className="common-divider-line" /> : null}
            <div
              css={
                isMobile
                  ? {
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }
                  : {}
              }
            >
              <Tooltip
                placement="topLeft"
                title={
                  language === 'zh' ? (
                    <span>
                      <ExclamationCircleOutlined /> 请您输入您的邮箱接收订单详情
                    </span>
                  ) : (
                    <span>
                      <ExclamationCircleOutlined /> Please type your email for
                      receive order confirmation email
                    </span>
                  )
                }
                visible={!userEmail}
              >
                <Input
                  name="email"
                  type="email"
                  placeholder={intl.formatMessage({
                    id: 'plsEmail',
                  })}
                  value={userEmail}
                  css={{ margin: '15px 0px', width: '100%' }}
                  onChange={(e) => {
                    console.log(pattern.test(e.target.value));
                    if (!pattern.test(e.target.value)) {
                      this.setState({
                        invalidEmailMessage: intl.formatMessage({
                          id: 'invalidEmail',
                        }),
                      });
                    } else {
                      this.setState({
                        invalidEmailMessage: '',
                      });
                    }
                    this.setState({
                      userEmail: e.target.value,
                    });
                  }}
                />
              </Tooltip>
            </div>
            {invalidEmailMessage ? (
              <Alert
                style={{ fontSize: '12px' }}
                message={invalidEmailMessage}
                type="error"
              />
            ) : null}
            {!invalidEmailMessage && userEmail && (
              <div style={{ marginBottom: '15px' }}>
                <Switch
                  checked={isRegistered}
                  onChange={this.handleIsRegistered}
                />
                <span style={{ paddingLeft: '10px' }}>
                  <FormattedMessage id="payment.wantToSignUp" />
                </span>
              </div>
            )}
            {isRegistered && (
              <div>
                <Form
                  ref={this.formRef}
                  onFinish={handleClickSignUpButton}
                  onValuesChange={() => this.forceUpdate()}
                >
                  <FormBuilder form={this.formRef} meta={meta} />
                  <Form.Item>
                    <Button
                      css={{ width: '100%' }}
                      type="primary"
                      htmlType="submit"
                      disabled={isResisterLoading}
                    >
                      {isResisterLoading ? (
                        <React.Fragment>
                          <LoadingOutlined />{' '}
                        </React.Fragment>
                      ) : null}
                      <FormattedMessage id="signUp" />
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        );
      }
      return null;
    };

    const renderPayment = () => {
      const { invalidEmailMessage, userEmail } = this.state;
      const hasGroupBuy = this.checkIfContainGroupBuyVariant(shoppingList);
      const payment = (
        <OrderPaymentForm
          handlePaymentSuccess={this.handlePaymentSuccess}
          selectedAddress={
            selectedPickupDate && selectedPickupDate.addressId
              ? selectedPickupDate.addressId
              : selectedAddress
          }
          saleorOrderInput={{}}
          checkPriceUpdate={this.checkPriceUpdate}
          payDisabled={
            !!(
              group?.name === 'guest' &&
              (invalidEmailMessage || !userEmail || isRegistered)
            )
          }
          userEmail={group && group.name === 'guest' ? userEmail : null}
          disabledAlipay={hasGroupBuy}
        />
      );
      if (currentShoppingMode === 'delivery' && shippingDate) {
        return payment;
      }
      if (
        currentShoppingMode === 'pickup' &&
        (shippingDate || selectedPickupDate)
      ) {
        return payment;
      }
      if (
        currentShoppingMode === 'donation' ||
        currentShoppingMode === 'eShop'
      ) {
        return payment;
      }
      return null;
    };
    return (
      <div className="payment-space">
        <div className="payment-custom-calender">
          <Modal
            footer={null}
            visible={checkPriceModal}
            destroyOnClose
            onCancel={() => {
              this.setState({ checkPriceModal: false });
            }}
          >
            <div>
              <FormattedMessage id="paymentError.priceDifferent" />
            </div>
          </Modal>
          {/* <Calendar
            type="one"
            locale={enUS}
            visible={mobileCalendarVisibility}
            onCancel={() => {
              this.setState({ mobileCalendarVisibility: false });
            }}
            onConfirm={this.handleChangeMobileDate}
            getDateExtra={this.handleDisableMobileDate}
          /> */}
          <Modal
            css={{ top: 10 }}
            visible={mobileCalendarVisibility}
            footer={null}
            onCancel={() => {
              this.setState({ mobileCalendarVisibility: false });
            }}
            width="100vw"
          >
            <Calendar
              disabledDate={this.handleDisableDate}
              headerRender={({ value, onChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];

                const current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i += 1) {
                  current.month(i);
                  months.push(localeData.monthsShort(current));
                }

                for (let index = start; index < end; index += 1) {
                  monthOptions.push(
                    <Select.Option className="month-item" key={`${index}`}>
                      {months[index]}
                    </Select.Option>
                  );
                }
                const month = value.month();

                const year = moment().year();
                const options = [];
                for (let i = year; i < year + 2; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }
                return (
                  <div style={{ padding: 8 }}>
                    <Row gutter={8}>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          onChange={async (newYear) => {
                            const now = value.clone().year(newYear);
                            await this.setState({
                              doNotHideMobileCalendarModal: true,
                            });
                            onChange(now);
                          }}
                          value={String(year)}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={String(month)}
                          onChange={async (selectedMonth) => {
                            const newValue = value.clone();
                            newValue.month(parseInt(selectedMonth, 10));
                            await this.setState({
                              doNotHideMobileCalendarModal: true,
                            });
                            onChange(newValue);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
              value={shippingDate}
              mode="month"
              onSelect={async (date) => {
                this.handleChangeMobileDate(date);
                const { doNotHideMobileCalendarModal } = this.state;
                if (doNotHideMobileCalendarModal === false) {
                  await this.setState({
                    mobileCalendarVisibility: false,
                  });
                }
                await this.setState({ doNotHideMobileCalendarModal: false });
              }}
              // fullscreen={false}
            />
          </Modal>
          <Modal
            visible={boxContentModal}
            footer={null}
            onCancel={() => {
              this.setState({ boxContentModal: false });
            }}
          >
            {boxContent ? variantList2(boxContent, language) : null}
          </Modal>
          <StepBar currentStep={1} />

          {/* couponCode */}
          <Modal
            destroyOnClose
            footer={
              <Button
                onClick={() => {
                  this.setState({
                    popCouponVisible: false,
                  });
                }}
              >
                <FormattedMessage id="closeModal" />
              </Button>
            }
            onCancel={() => {
              this.setState({
                popCouponVisible: false,
              });
            }}
            visible={popCouponVisible}
          >
            <div>
              {validateCouponCode() &&
                _.filter(
                  _.orderBy(personalDiscount, ['startDate'], ['asc']),
                  (coupon) => {
                    return moment(coupon.startDate).isBefore(moment());
                  }
                ).map((e) => (
                  <div
                    key={e._id}
                    role="presentation"
                    id={e._id}
                    onClick={async () => {
                      await this.setState({
                        couponCode: e.code,
                        // invalidCodeMessage: '',
                      });
                      this.handleCheckCouponSubmit();
                      await this.setState({
                        popCouponVisible: false,
                      });
                    }}
                  >
                    <CouponCode id={e._id} coupon={e} couponCode={couponCode} />
                  </div>
                ))}
              {!validateCouponCode() && (
                <div style={{ fontSize: '13px', color: '#909090' }}>
                  <FormattedMessage id="noDiscount" />
                </div>
              )}
            </div>
          </Modal>

          <Modal
            visible={hasRegisteredModal}
            footer={null}
            title={null}
            onCancel={() => {
              this.setState({
                hasRegisteredModal: false,
              });
            }}
            destroyOnClose
          >
            <div style={{ textAlign: 'center' }}>
              <FormattedMessage id="payment.hasRegistered" />
              <div style={{ marginTop: '15px' }}>
                <Link to={getCustomerPath('login')}>
                  <Button type="primary">
                    <FormattedMessage id="login" />
                  </Button>
                </Link>
              </div>
            </div>
          </Modal>

          <Modal
            visible={addonsModal}
            footer={null}
            title={null}
            maskClosable
            onCancel={() => {
              this.setState({ addonsModal: false });
            }}
            style={{ top: '0px' }}
          >
            {warehouseAddons && warehouseAddons.items.length > 0 && (
              <VariantsAddOns
                language={language}
                variantList={warehouseAddons.items}
                handleAddToShoppingCart={this.handleAddToShoppingCart}
              />
            )}
          </Modal>

          {/* Render Announcement */}
          {renderAnnouncement()}
          {/* Render Address */}
          {renderShippingAddress()}
          {/* date and coupon render */}
          <React.Fragment>
            {/* shipping date */}
            {renderShippingDate()}
            {/* {currentShoppingMode === 'groupBuy' &&
          currentAddressType === 'delivery' &&
          selectedShippingDate ? (
            <React.Fragment>
              <Row style={{ margin: '10px 0px' }}>
                <FormattedMessage id="deliveryTime" />
                {selectedShippingTime ? successIcon : questionIcon}
              </Row>
              <Row type="flex" justify="center">
                <TimePicker
                  style={{ width: '100%' }}
                  format="HH:mm"
                  minuteStep={15}
                  hideDisabledOptions
                  defaultOpenValue={moment('11:00', 'HH:mm')}
                  disabledHours={this.handleDisabledHours}
                  placeholder={intl.formatMessage({
                    id: 'selectYouDeliveryTime',
                  })}
                  onChange={this.handleTimeChange}
                />
              </Row>
            </React.Fragment>
          ) : null} */}
            {/* coupon code */}
            {/* {renderCoupon()} */}
          </React.Fragment>
          <div
            style={
              isMobile
                ? {
                    overflow: 'hidden',
                    borderRadius: '5px',
                    border: '1px solid #e2e2e2',
                  }
                : null
            }
          >
            <CheckoutCart
              websiteSaleCode={websiteSaleCode}
              discountAmount={discountAmount}
              shoppingList={shoppingList}
              handleIncrement={null}
              handleDecrement={null}
              viewOnlyMode
              taxRate={taxRate}
              marketMinimumAmountForShippingFree={
                marketMinimumAmountForShippingFree
              }
              marketShippingFee={marketShippingFee}
              deliveryFee={deliveryFee}
              deliveryMode={deliveryMode}
              deliveryMinimumAmount={deliveryMinimumAmount}
              deliveryDistanceRange={deliveryDistanceRange}
              deliveryDurationPreMinute={deliveryDurationPreMinute}
              deliveryDistancePreMile={deliveryDistancePreMile}
              handleViewBox={this.handleViewBox}
            />
            {!isScheduledDelivery &&
              currentShoppingMode === 'delivery' &&
              warehouseAddons &&
              warehouseAddons.items.length > 0 && (
                <div>
                  {warehouseAddons.deltaAmount &&
                    warehouseAddons.discountAmount && (
                      <div style={{ padding: '10px 0px', fontWeight: 500 }}>
                        <FormattedMessage
                          id="payment.addonsPriceTip"
                          values={{
                            price: `$${Number(
                              warehouseAddons.deltaAmount
                            ).toFixed(2)}`,
                            discount: `$${warehouseAddons.discountAmount}`,
                          }}
                        />{' '}
                        ,
                        {group && group.name !== 'guest' && (
                          <span style={{ color: 'red' }}>
                            <Link to={getCustomerPath('supermarket')}>
                              <FormattedMessage id="payment.goShopping" />
                            </Link>
                          </span>
                        )}
                        {group && group.name === 'guest' && (
                          <span style={{ color: 'red' }}>
                            <FormattedMessage id="payment.signUpTip" />
                          </span>
                        )}
                      </div>
                    )}
                  {/* <div style={{ paddingBottom: '10px' }}>
                    <div style={{ paddingBottom: '10px', fontWeight: 500 }}>
                      <FormattedMessage id="payment.variantAddons" />:
                    </div>
                    <div style={{ display: 'flex' }}>
                      {warehouseAddons.items
                        .map((item) => {
                          return (
                            <div key={item._id}>
                              <img
                                src={item.imgUrl}
                                style={{
                                  height: '100px',
                                  width: '100px',
                                  objectFit: 'cover',
                                  marginRight: '10px',
                                }}
                                alt=""
                                onClick={() => {
                                  this.setState({
                                    addonsModal: true,
                                  });
                                }}
                                role="presentation"
                              />
                            </div>
                          );
                        })
                        .slice(0, 3)}
                    </div>
                  </div> */}
                </div>
              )}
          </div>
          <div>{renderEmail()}</div>
          <div>
            {/* Payment */}
            {renderPayment()}
          </div>
        </div>
      </div>
    );
  }
}

Payment.defaultProps = {
  token: null,
  shoppingCart: {
    productList: [],
    boxList: [],
    deliveryTime: null,
  },
  currentWarehouseCart: {
    productList: [],
  },
  groupBuyInfo: null,
  preselectPickupInfo: null,
  onlyAllowedWeekDay: null,
  onlyAllowedDate: null,
  onlyAllowedDateWithTime: null,
  disableDate: [],
  warehouseId: null,
  warehouseDeliveryMode: null,
};

Payment.propTypes = {
  token: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  shoppingList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      shippingDate: PropTypes.string,
      boxContent: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  personalDiscount: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  taxRate: PropTypes.number.isRequired,
  currentShoppingMode: PropTypes.string.isRequired,
  currentAddressType: PropTypes.string.isRequired,
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deliveryTime: PropTypes.number,
    discountCode: PropTypes.string,
  }),
  currentWarehouseCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  groupBuyInfo: PropTypes.shape({}),
  preselectPickupInfo: PropTypes.shape({
    campaignContentId: PropTypes.string,
  }),
  // updateShoppingCart: PropTypes.func.isRequired,
  onlyAllowedWeekDay: PropTypes.shape({
    day: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onlyAllowedDate: PropTypes.arrayOf(PropTypes.string),
  onlyAllowedDateWithTime: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  disableDate: PropTypes.arrayOf(PropTypes.string),
  updateWarehouseCart: PropTypes.func.isRequired,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  warehouseId: PropTypes.string,
  isScheduledDelivery: PropTypes.bool.isRequired,
  warehouseDeliveryMode: PropTypes.bool,
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  shoppingCart: state.cart.shoppingCart,
  currentWarehouseCart: state.cart.currentWarehouseCart,
  items: state.cart.items,
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  currentAddressType: state.user.currentAddressType,
  groupBuyInfo: state.user.groupBuyInfo,
  preselectPickupInfo: state.user.preselectPickupInfo,
  userId: state.user.userId,
  warehouseId: state.warehouse.warehouseId,
  taxRate: state.warehouse.taxRate,
  isScheduledDelivery: state.warehouse.isScheduledDelivery,
  group: state.user.group,
});

const mapDispatchToProps = (dispatch) => ({
  // updateShoppingCart: (input) => dispatch(actions.updateShoppingCart(input)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
  addItem: (item) => dispatch(actions.addItem(item)),
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
  overWriteCallBack: (callback) =>
    dispatch(actions.overWriteCallBack(callback)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Payment))
);
