/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Title, Paragraph } = Typography;

export class About extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    const { language } = this.props;
    return (
      <div css={isMobile ? { padding: '10px' } : { padding: '30px 50px' }}>
        <Typography>
          <Title>
            <FormattedMessage id="aboutUs" />
          </Title>
          <div>
            <div>
              <Title level={2}>
                <FormattedMessage id="ourSlogan" />
              </Title>
              <iframe
                title="BunBao video"
                width={isMobile ? '100%' : '560'}
                height="315"
                src="https://www.youtube-nocookie.com/embed/DTDLNxwQM2c?controls=0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Title level={2}>
                {language === 'zh' ? '棒' : 'Bun "棒👍️"'}
              </Title>
              <Paragraph>
                <FormattedMessage id="aboutUsParagraphSectionOne" />
              </Paragraph>
              <Title level={2}>{language === 'zh' ? '包' : 'Bao "包"'}</Title>
              <Paragraph>
                <FormattedMessage id="aboutUsParagraphSectionTwo" />
              </Paragraph>
              <Title level={2}>
                {language === 'zh' ? '送到家' : 'Delivery'}
              </Title>
              <Paragraph>
                <FormattedMessage id="aboutUsParagraphSectionThree" />
              </Paragraph>
            </div>
          </div>
        </Typography>
      </div>
    );
  }
}

About.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(injectIntl(About));
