/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { nanoid } from 'nanoid';
import {
  ShoppingCartOutlined,
  NotificationOutlined,
  QuestionOutlined,
  ShopOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
  ClockCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import TextLoop from 'react-text-loop';
import {
  Row,
  message,
  Modal,
  Drawer as WebDrawer,
  Button,
  Badge,
  Tag,
  Alert,
  BackTop,
} from 'antd';
import moment from 'moment';
import { convertRawToHTML } from 'braft-convert';
import BraftEditor from 'braft-editor';
import * as QueryString from 'query-string';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { isMobile, isMobileSafari } from 'react-device-detect';
import Parabola from '../../../components/Others/Parabola/Parabola';
import { apolloClient, translateWords, getCustomerPath } from '../../../util';
import Bao from '../../../assets/images/bao.png';
import BaoComplete from '../../../assets/images/baoColor.png';
import background from '../../../assets/images/homepage/sectionSmallBackground.png';
import { DEFAULT_IMAGE, BUCKET_CDN_URL, BUCKET_URL } from '../../../config';
import flatBox from '../../../assets/images/icons/flatBox.svg';
// api
import { CATEGORY_QUERY } from '../../../api/category';
import {
  WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
  WAREHOUSE_FIND_ONE,
} from '../../../api/warehouse';
import { BANNER_FIND_MANY } from '../../../api/banner';
import { FETCH_BOX } from '../../../api/container';
import { CONFIGURATION_FIND_ONE } from '../../../api/configuration';
// component
import CustomerServiceChooseModal from '../../../components/Modals/CustomerServiceChooseModal/CustomerServiceChooseModal';
import SideCart from '../../../components/Drawers/SideCart/SideCart';
import ComboSelection from '../../../components/ComboSelection/ComboSelection';
import MenuBanner from '../../../components/MenuBanner/MenuBanner';
import BoxDetail from './BoxDetail/BoxDetail';
import SingleProductCard from './SingleProductCard/SingleProductCard';
import * as actions from '../../../store/actions';
import ProductDetailModal from '../../../components/Modals/ProductDetailModal/ProductDetailModal';
import Loading from '../../../components/Others/Loading/Loading';
import WarehouseHeader from '../../../components/MiddleComponents/WarehouseHeader';
import DonationHeader from '../../../components/MiddleComponents/DonationHeader';
import ArticleModal from '../../../components/Modals/ArticleModal/ArticleModal';
import VideoBannerAlt from '../../../components/VideoBanner/VideoBannerAlt';
import Announcement from './Announcement/Announcement';
import freeShipping from '../../../assets/images/icons/freeShipping.svg';
import timerImage from '../../../assets/images/icons/time-monochromatic.svg';
import bunbaoLogo from '../../../assets/images/BunbaoSmall.png';
// import bunbaoLogoWhite from '../../../assets/images/BunbaoWhiteSmall.png';
import './DeliveryMenu.less';

const boxMaxLimit = 9;
const { innerHeight: height } = window;

const getCountByCollection = (collection) => {
  console.log(collection);
  if (collection) {
    switch (collection.title) {
      case 'three-flavor-box': {
        return 3;
      }
      case 'one-flavor-box': {
        return 9;
      }
      default: {
        return 1;
      }
    }
  }
  return 1;
};

const HEADER_HEIGHT = 66;
export class DeliveryMenu extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      productList: [],
      staticProductList: [],
      staticProductIdToIndex: {},
      // higherRankingList: [],
      loading: true,
      // ingredientToData: {},
      categoryToData: {},
      categories: [],
      // saleToData: {},
      // collection part
      collectionsList: [],
      collectionModal: false,
      currentCollection: null,
      productForCollection: null,
      // box part
      boxModal: false,
      // viewBox: false,
      // shoppingCart
      drawerModal: false,
      mobileDrawerModal: false,
      slides: [],
      // category
      selectedCategory: 'All',
      // choose modal
      serviceChooseModalVisible: false,
      serviceMode: null,
      // Product detail modal
      productDetailModalVisible: false,
      selectedProductId: null,
      ourMissionModalVisible: false,
      donationFAQModalVisible: false,
      // scrollToTop
      scrollTop: 0,
      ballX: 0,
      ballY: 0,
      isBallVisible: false,
      words: [],
      // Warehouse Info
      currentWarehouseObj: null,
      // isDeliveryMenu: false,
      warehouseScheduledDeliveryMode: false,
      childrenWarehouseOrWebsiteMode: false,
      totalCount: 0,
      videoModalVisible: false,
      selectedVideo: null,
      storeAnnouncementModalVisible: false,
      freeShippingObj: null,
      customerOrderConfiguration: null,
      boxContainerVisible: false,
      isFreeShoppingAlertClosed: false,
      isShutDown: false,
    };
    this.$originArray = {};
    this.sliderRef = React.createRef();
    this.sliderContainerRef = React.createRef();
    this.headerBannerHeightRef = React.createRef();
  }

  componentDidMount = async () => {
    console.log('Delivery Menu render once');
    // check query params
    const {
      location: { search, pathname },
      setUserAddressType,
      setUserShoppingMode,
      history,
    } = this.props;
    // check params

    const { cart, productId, warehouseId } = QueryString.parse(search);
    const { match } = this.props;
    const { warehouseId: paramWarehouseId } = match.params;
    const path = window.location.pathname;
    // console.log(search, pathname);
    // console.log(pathname.endsWith('delivery-menu'), !search);
    // console.log(
    //   pathname.endsWith('delivery-menu'),
    //   search.startsWith('?productId=')
    // );
    if (pathname.endsWith('delivery-menu') && !search) {
      history.push(getCustomerPath('supermarket'));
      return;
    }
    if (
      pathname.endsWith('delivery-menu') &&
      search.startsWith('?productId=')
    ) {
      history.push(getCustomerPath(`supermarket${search}`));
      return;
    }

    this.handleFetchFreeShippingAmount();
    let isDeliveryMenu = true;
    if (path.includes('pickup-menu')) {
      // set shopping mode to delivery menu
      console.log('store mode', warehouseId);
      await setUserShoppingMode('pickup');
      await setUserAddressType('pickup');
      isDeliveryMenu = false;
    } else if (path.includes('delivery-menu')) {
      // set shopping mode to delivery menu
      setUserShoppingMode('delivery');
      console.log('delivery menu mode');
    } else if (path.includes('donation')) {
      setUserShoppingMode('donation');
      isDeliveryMenu = false;
      await this.handleFetchProductData(paramWarehouseId, 'donation');
    } else if (path.includes('eshop')) {
      setUserShoppingMode('eShop');
      isDeliveryMenu = false;
      await this.handleFetchProductData(paramWarehouseId, 'eShop');
    }

    // check special case for warehouse delivery
    console.log('Is Delivery Menu? ', isDeliveryMenu);

    // await this.setState({ isDeliveryMenu });
    if (isDeliveryMenu && !paramWarehouseId && !warehouseId) {
      await this.fetchBanner();
      await this.handleFetchProductData();
      await this.fetchCollectionData();
      console.log('normal delivery menu');
    } else if (!isDeliveryMenu && warehouseId) {
      await this.handleFetchProductData(warehouseId, 'pickup');
      // await this.setState({currentWarehouseObj:{_id:warehouseId}})
      console.log('pickup menu', warehouseId);
    } else if (isDeliveryMenu && warehouseId) {
      await this.setState({ warehouseScheduledDeliveryMode: true });
      await this.handleFetchProductData(warehouseId, 'warehouseDelivery');
      console.log('warehouse delivery menu');
    }
    // await this.handleFetchProductData();

    // process shopping cart items
    await this.fetchShoppingCart();
    await this.handleFetchWords();
    if (cart === 'true') {
      if (isMobile) {
        await this.handleMobileShoppingCart();
      } else {
        this.setState({ drawerModal: true });
      }
    }
    window.addEventListener('scroll', this.handleScroll, true);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // check product id to show product modal
    if (productId) {
      // check product id
      const { productList } = this.state;
      if (
        productList.findIndex((product) => product._id === productId) !== -1
      ) {
        this.onOpenProductDetailModal(productId);
      }
    }
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { shoppingCart } = prevState;
  //   console.log(shoppingCart);
  // };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      if (!isMobile) {
        document.body.style.cssText = '';
      }
    }, 300);
  };

  handleFetchFreeShippingAmount = async () => {
    try {
      const {
        data: { configurationOne },
      } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: {
          filter: { isScheduledDeliveryConfiguration: true },
        },
      });
      this.setState({
        freeShippingObj: configurationOne.scheduledDeliveryConfiguration,
      });

      const {
        data: { configurationOne: customerOrderConfiguration },
      } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: { filter: { isCustomerOrderConfiguration: true } },
      });
      this.setState({
        customerOrderConfiguration:
          customerOrderConfiguration.customerOrderConfiguration,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleFetchWords = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isProductConfiguration: true },
      },
    });
    this.setState({ words: configurationOne.words });
  };

  translation = (word) => {
    const { words } = this.state;
    return translateWords(word, words);
  };

  filteredShoppingCart = async (staticProductIdToIndex) => {
    const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    const filteredShoppingCart = {
      ...shoppingCart,
      productList: shoppingCart.productList.filter(
        (element) => staticProductIdToIndex[element.id] > -1
      ),
    };
    await updateWarehouseCart(filteredShoppingCart);
  };

  // ========= Fetching Part
  fetchShoppingCart = async () => {
    const {
      staticProductIdToIndex,
      staticProductList,
      // isDeliveryMenu,
    } = this.state;
    const { getCurrentWarehouseCart } = this.props;
    // if(allWarehouseCart[currentWarehouseId]){
    await this.filteredShoppingCart(staticProductIdToIndex);
    const shoppingCart = await getCurrentWarehouseCart();
    // let shoppingCart = localStorage.getItem('shoppingCart');
    // shoppingCart = JSON.parse(shoppingCart);
    if (shoppingCart && (shoppingCart.productList || shoppingCart.boxList)) {
      shoppingCart.productList.forEach((element) => {
        if (element.count > 0 && staticProductIdToIndex[element.id] > -1) {
          // console.log("staticProductIdToIndex",staticProductIdToIndex);
          // console.log("staticProductList",staticProductList);
          // console.log("position ",staticProductIdToIndex[element.id]);
          // console.log('element', element);
          // console.log("same ",staticProductList[staticProductIdToIndex[element.id]]);
          staticProductList[staticProductIdToIndex[element.id]].count =
            element.count;
          // console.log(element);
          this.setState((prevState) => {
            return {
              totalCount: prevState.totalCount + element.count,
            };
          });
        }
      });
      shoppingCart.boxList.map(
        (element) =>
          element.count > 0 &&
          this.setState((prevState) => {
            return {
              totalCount: prevState.totalCount + element.count,
            };
          })
      );
      this.setState({ staticProductList, shoppingCart });
    }
    // }
  };

  fetchCollectionData = async () => {
    const {
      data: { categoryMany },
    } = await apolloClient.query({
      query: CATEGORY_QUERY,
      variables: { filter: { type: 'collection' } },
      fetchPolicy: 'network-only',
    });
    console.log(categoryMany);
    if (categoryMany) {
      const collectionsList =
        (categoryMany.length &&
          categoryMany.map((item) => {
            console.log(item);
            if (item.picture) {
              return {
                _id: item._id,
                id: item._id,
                title: item.name,
                name: { zh: item.title.zh, en: item.title.en },
                backgroundImage: {
                  url: BUCKET_URL + item.picture.key,
                },
              };
            }
            let pictureKey = '';
            switch (item.name) {
              case 'three-flavor-box':
                pictureKey = 'media/collection-backgrounds/3combo.jpg';
                break;
              case 'one-flavor-box':
                pictureKey =
                  'media/collection-backgrounds/%E6%A3%92%E5%8C%85_%E6%BB%91%E5%AB%A9%E7%8C%AA%E8%82%89x9.JPG';
                break;
              default:
                pictureKey =
                  'media/collection-backgrounds/%E6%A3%92%E5%8C%85_%E7%BB%BC%E5%90%88%E5%8F%A3%E5%91%B3.jpg';
            }
            return {
              _id: item._id,
              id: item._id,
              title: item.name,
              name: { zh: item.title.zh, en: item.title.en },
              backgroundImage: {
                url: BUCKET_URL + pictureKey,
              },
            };
          })) ||
        [];
      // BUCKET_URL;
      this.setState({ collectionsList });
    } else {
      message.warn('error happened during fetching collections');
    }
  };

  // TODO : donation continue
  handleFetchProductData = async (
    warehouseId = null,
    shoppingMode = 'delivery'
  ) => {
    const {
      setWarehouse,
      location: { search },
      setUserAddressType,
      setUserShoppingMode,
      updateOneWarehouseAttribute,
      language,
      getCurrentWarehouseCart,
      updateWarehouseCart,
    } = this.props;
    // change category ranking
    const { subWarehouseAlias } = QueryString.parse(search);
    let filter = { warehouseType: 'website' };
    if (shoppingMode === 'donation') {
      filter = { warehouseType: 'donation' };
    }
    if (shoppingMode === 'eShop') {
      filter = { warehouseType: 'eShop' };
    }
    if (warehouseId) {
      filter = { _id: warehouseId };
    }
    try {
      const {
        data: { warehouseOne },
      } = await apolloClient.query({
        query: WAREHOUSE_FIND_ONE,
        variables: { filter },
        fetchPolicy: 'network-only',
      });
      const {
        data: { containerMany },
      } = await apolloClient.query({
        query: FETCH_BOX,
        variables: {},
        fetchPolicy: 'network-only',
      });

      const variantsNeedsInventory = [];

      // set pick up or delivery
      // check is farmersMarket
      const { warehouseScheduledDeliveryMode } = this.state;
      if (warehouseOne.warehouseType === 'website') {
        this.setState({ childrenWarehouseOrWebsiteMode: true });
      }
      // only for warehouse has children warehouse
      if (
        warehouseOne.childrenWarehouses &&
        warehouseOne.childrenWarehouses.length > 0
      ) {
        this.setState({ childrenWarehouseOrWebsiteMode: true });
        console.log('the warehouse has children warehouses');
        let allowedVariants = [];
        warehouseOne.childrenWarehouses.forEach((warehouse) => {
          // process allowedVariants to add warehouse id
          const newAllowedVariants = warehouse.allowedVariants.map(
            (variant) => {
              const newElement = variant;
              newElement.warehouseId = warehouse._id;
              newElement.warehouse = {
                _id: warehouse._id,
                name: warehouse.name,
                title: {
                  zh: warehouse.title.zh,
                  en: warehouse.title.en,
                },
                alias: warehouse.alias,
                addressOne: warehouse.addressOne,
                addressTwo: warehouse.addressTwo,
                city: warehouse.city,
                cutOffTime: warehouse.cutOffTime,
                phoneNumber: warehouse.phoneNumber,
                videoIds: warehouse.videoIds,
              };
              return newElement;
            }
          );
          allowedVariants = _.concat(allowedVariants, newAllowedVariants);
        });
        warehouseOne.allowedVariants = allowedVariants;
        setUserShoppingMode('delivery');
        setUserAddressType('delivery');
        await updateOneWarehouseAttribute(true, 'isScheduledDelivery');
      } else {
        // not farms market
        await updateOneWarehouseAttribute(false, 'isScheduledDelivery');
      }
      const websiteMenu =
        shoppingMode === 'pickup'
          ? warehouseOne.allowedPickupVariants
          : warehouseOne.allowedVariants;
      await setWarehouse(warehouseOne._id);
      const shoppingCart = await getCurrentWarehouseCart();
      if (
        (warehouseId && shoppingMode === 'pickup') ||
        shoppingMode === 'eShop' ||
        warehouseScheduledDeliveryMode
      ) {
        if (
          ((language === 'en' &&
            BraftEditor.createEditorState(
              convertRawToHTML(JSON.parse(warehouseOne.announcement.en || '{}'))
            ).toText()) ||
            (language === 'zh' &&
              BraftEditor.createEditorState(
                convertRawToHTML(
                  JSON.parse(warehouseOne.announcement.zh || '{}')
                )
              ).toText())) &&
          warehouseOne.childrenWarehouses.length > 0
        ) {
          this.setState({
            storeAnnouncementModalVisible: true,
          });
        }
        await this.setState({ currentWarehouseObj: warehouseOne });
      }
      const staticProductIdToIndex = {};
      const categoryToData = { All: [] };
      const categories = new Set();
      const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
      console.log(websiteMenu);
      websiteMenu.forEach((newElement, index) => {
        if (newElement.allowedInventoryCheck === true) {
          variantsNeedsInventory.push(newElement._id);
          websiteMenu[index].allowedInventoryCheck =
            newElement.allowedInventoryCheck;
        }
        websiteMenu[index].category = newElement.product.category;
        websiteMenu[index].hashtags = newElement.product.hashtags;
        websiteMenu[index].imgUrl = getImg(newElement);
        websiteMenu[index].id = newElement._id;
        // websiteMenu[index].rank = newElement.ranking;
        websiteMenu[index].count = 0;
        websiteMenu[index].needBox = false;
        websiteMenu[index].videoIds = newElement.videoIds;
        websiteMenu[index].product.imgUrl = getImg(newElement.product);
        if (websiteMenu[index].markAsSoldOut === true) {
          websiteMenu[index].soldOut = true;
        }
        if (websiteMenu[index].product.imgUrl !== DEFAULT_IMAGE) {
          websiteMenu[
            index
          ].product.imgUrl = `${BUCKET_CDN_URL}${websiteMenu[index].product.imgUrl}`;
        }
        if (websiteMenu[index].imgUrl !== DEFAULT_IMAGE) {
          websiteMenu[
            index
          ].imgUrl = `${BUCKET_CDN_URL}${websiteMenu[index].imgUrl}`;
        } else {
          websiteMenu[index].imgUrl = websiteMenu[index].product.imgUrl;
        }
        if (websiteMenu[index].isChangeListPriceByDates) {
          // check date and change list price
          const today = moment().valueOf();
          if (
            today > websiteMenu[index].changeListPriceDates.endDate ||
            today < websiteMenu[index].changeListPriceDates.startDate
          ) {
            websiteMenu[index].listPrice = websiteMenu[index].originalPrice;
          }
        }
      });
      console.log(websiteMenu);

      // process categories
      websiteMenu.forEach((newElement, index) => {
        if (
          warehouseScheduledDeliveryMode &&
          warehouseOne.childrenWarehouses.length !== 0
        ) {
          const ranking = warehouseOne.childrenWarehouseIdsRanking.find(
            (item) => item.warehouseId === newElement.warehouse._id
          );

          // calculate cut of time
          const today = moment();
          let sortedHours = _.orderBy(warehouseOne.hours, ['day'], ['asc']);
          sortedHours = sortedHours.map((hour) => {
            return moment()
              .isoWeekday(hour.day)
              .startOf('day')
              .subtract(newElement.warehouse.cutOffTime, 'hour');
          });
          let cutOffTimeDate = 'None';
          for (let i = 0; i < sortedHours.length; i += 1) {
            if (today < sortedHours[i]) {
              cutOffTimeDate = sortedHours[i].format('MM/DD HH:mm');
              break;
            }
          }

          const warehouseObject = {
            name: newElement.warehouse._id,
            alias: newElement.warehouse.alias,
            ranking: ranking ? ranking.ranking : 5,
            title: {
              en: newElement.warehouse.title.en,
              zh: newElement.warehouse.title.zh,
            },
            addressOne: newElement.warehouse.addressOne,
            addressTwo: newElement.warehouse.addressTwo,
            city: newElement.warehouse.city,
            phoneNumber: newElement.warehouse.phoneNumber,
            type: 'product',
            videoIds: newElement.warehouse.videoIds,
            cutOffTimeDate,
          };
          if (newElement.warehouse.alias === subWarehouseAlias) {
            warehouseObject.ranking = -1;
          }
          if (
            ![...categories].find(
              (category) => category.name === newElement.warehouse._id
            )
          ) {
            categories.add(warehouseObject);
          }
          if (newElement.warehouse) {
            if (newElement.warehouseId in categoryToData) {
              categoryToData[newElement.warehouseId].push(newElement.id);
            } else {
              categoryToData[newElement.warehouseId] = [newElement.id];
            }
          }
          staticProductIdToIndex[newElement.id] = index;
        } else {
          // depends category name parameters, then change category ranking and move to top
          // if (newElement.category.name === categoryName) {
          //   newElement.category.ranking = 99999;
          // }
          categories.add(newElement.category);
          if (newElement.category) {
            if (newElement.category.name in categoryToData) {
              categoryToData[newElement.category.name].push(newElement.id);
            } else {
              categoryToData[newElement.category.name] = [newElement.id];
            }
          }
          staticProductIdToIndex[newElement.id] = index;
        }
      });

      // add inventory
      console.log('variantsNeedsInventory', variantsNeedsInventory);
      const {
        data: { inventoryCurrentWarehouseWithVariantIds },
      } = await apolloClient.query({
        query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
        variables: {
          warehouseId: warehouseOne._id,
          variantIds: variantsNeedsInventory,
        },
        fetchPolicy: 'network-only',
      });
      if (inventoryCurrentWarehouseWithVariantIds) {
        inventoryCurrentWarehouseWithVariantIds.forEach((variant) => {
          const findOne = _.find(websiteMenu, (element) => {
            return element._id === variant._id;
          });
          if (findOne) {
            findOne.totalQuantity = variant.totalQuantity;
            if (variant.totalQuantity <= 0) {
              findOne.soldOut = true;
            }
          }
        });
      }

      // console.log('Categories', [...categories]);
      // console.log('Website Menu', websiteMenu);
      const newProductList = _.sortBy(websiteMenu, [
        (o) => {
          return o.ranking;
        },
      ]);
      // console.log('newProductList', newProductList);
      // temporary
      const boxSample = containerMany[0];
      // if is delivery menu
      if (warehouseId === null && shoppingMode === 'delivery') {
        if (boxSample.allowedVariantIds.length > 0) {
          boxSample.allowedVariantIds.forEach((variantId) => {
            const index = staticProductIdToIndex[variantId];
            if (index > -1) {
              websiteMenu[index].needBox = true;
            }
          });
        }
        if (boxSample.notAllowedVariantIds.length > 0) {
          websiteMenu.forEach((newElement, index) => {
            websiteMenu[index].needBox = true;
          });
          boxSample.notAllowedVariantIds.forEach((variantId) => {
            const index = staticProductIdToIndex[variantId];
            // console.log('index', index);
            if (index >= 0) {
              websiteMenu[index].needBox = false;
            }
          });
        }
      }
      //
      // console.log('new');
      // console.log('categoryToData', categoryToData);
      // console.log('websiteMenu', websiteMenu);
      // console.log('staticProductIdToIndex', staticProductIdToIndex);
      const sortedCategories = _.orderBy(
        [...categories],
        ['ranking', 'name'],
        ['asc', 'asc']
      );
      await this.setState({
        staticProductList: websiteMenu,
        productList: newProductList,
        loading: false,
        categoryToData,
        categories: sortedCategories,
        staticProductIdToIndex,
        isShutDown: warehouseOne.isShutDown,
      });
      if (sortedCategories.length > 0) {
        this.setState({
          selectedCategory: sortedCategories[0].name,
        });
      }
      // syncs shoppingCart with websiteMenu
      Object.keys(shoppingCart).forEach((key) => {
        if (key === 'productList' && shoppingCart[key]) {
          shoppingCart[key].forEach((item) => {
            const findIndex = websiteMenu.findIndex(
              (obj) => obj._id === item._id
            );
            if (findIndex > -1) {
              item.listPrice = websiteMenu[findIndex].listPrice;
            }
          });
        }
        if (key === 'boxList' && shoppingCart[key]) {
          shoppingCart[key].forEach((item) => {
            const { boxContent } = item;
            if (boxContent) {
              boxContent.forEach((boxItem, index) => {
                const findIndex = websiteMenu.findIndex(
                  (obj) => boxItem._id === obj._id
                );
                if (findIndex > -1) {
                  if (boxContent[index]._id === websiteMenu[findIndex]._id) {
                    boxContent[index].listPrice =
                      websiteMenu[findIndex].listPrice;
                  }
                }
              });
              const totalPrice = boxContent.reduce((sum, box) => {
                return sum + box.listPrice * box.count;
              }, 0);
              item.listPrice = totalPrice.toFixed(2);
            }
          });
        }
      });
      console.log('final', shoppingCart);
      await updateWarehouseCart({ ...shoppingCart });
    } catch (error) {
      console.error(error);
      message.error('error happened during fetching menu!');
    }
  };

  fetchBanner = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'menu' } },
      });
      // console.log(bannerFindMany);
      // sort banner
      const rawData = bannerFindMany;
      const slides = _.orderBy(rawData, ['ranking'], ['asc']);
      console.log('slides', slides);
      this.setState({ slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  // ========= Handling Part

  handleProcessDidMount = async () => {};

  handleIncrement = async (input) => {
    const { productList, currentCollection } = this.state;
    const { intl } = this.props;
    const foundIndex = productList.findIndex((x) => x.id === input);
    if (foundIndex >= 0) {
      const foundCategory = productList[foundIndex].needBox;
      if (foundCategory) {
        if (currentCollection === null) {
          this.setState({
            collectionModal: true,
            productForCollection: input,
            productDetailModalVisible: false,
          });
          return;
        }
        if (boxMaxLimit === currentCollection.productCount) {
          // box is full , pop modal
          this.setState({ boxModal: true });
          return;
        }
        productList[foundIndex].inCurrentBox = true;
        currentCollection.productCount +=
          getCountByCollection(currentCollection);
        productList[foundIndex].count +=
          getCountByCollection(currentCollection);
        if (boxMaxLimit === currentCollection.productCount) {
          // box is full , pop modal
          this.setState({ boxModal: true, productList });
          return;
        }
      } else {
        if (
          productList[foundIndex].maximumPerOrder &&
          productList[foundIndex].maximumPerOrder > 0 &&
          productList[foundIndex].maximumPerOrder <=
            productList[foundIndex].count
        ) {
          message.warning(
            intl
              .formatMessage({ id: 'errorExceedMaximum' })
              .replace('[xx.xx]', productList[foundIndex].maximumPerOrder)
          );
          return;
        }
        if (
          productList[foundIndex].totalQuantity &&
          productList[foundIndex].totalQuantity <= productList[foundIndex].count
        ) {
          return;
        }
        productList[foundIndex].count += 1;
        this.updateShoppingCart(productList[foundIndex]);
      }
      if (isMobile === false) {
        // Parabola Animation
        this.$origin = this.$originArray[input];
        await this.onAnimate();
      }
      // cons
      this.setState({ productList, productDetailModalVisible: false });
      // const { intl } = this.props;
      message.success(intl.formatMessage({ id: 'addSign' }), 1);
    } else {
      message.error('ERROR');
    }
  };

  handleDecrement = async (input) => {
    // console.log('decrement', input);
    const { productList, currentCollection } = this.state;
    const foundIndex = productList.findIndex((x) => x.id === input);
    if (foundIndex >= 0) {
      if (productList[foundIndex].count > 0) {
        // if count > 0
        // saleor version
        // const foundCategory = _.find(comboRequireCategory, (e) => {
        //   return e === productList[foundIndex].category.name;
        // });
        const foundCategory = productList[foundIndex].needBox;
        if (foundCategory && currentCollection) {
          currentCollection.productCount -=
            getCountByCollection(currentCollection);
          productList[foundIndex].count -=
            getCountByCollection(currentCollection);
        } else {
          productList[foundIndex].count -= 1;
          this.updateShoppingCart(productList[foundIndex]);
        }
        const { intl } = this.props;
        message.success(intl.formatMessage({ id: 'deleteSign' }, 1));
      }
      this.setState({ productList });
    } else {
      // delete the product and set count to 0
      message.error('ERROR');
    }
  };

  handleDelete = async (input) => {
    const { productList } = this.state;
    const foundIndex = productList.findIndex((x) => x.id === input);
    if (foundIndex >= 0) {
      if (productList[foundIndex].count > 0) {
        // if count > 0
        productList[foundIndex].count = 0;
        this.updateShoppingCart(productList[foundIndex]);
        const { intl } = this.props;
        message.success(intl.formatMessage({ id: 'deleteSign' }, 1));
      }
      this.setState({ productList });
    } else {
      message.error('ERROR');
    }
  };

  handleSelectCollection = async (input, index) => {
    const { productForCollection, collectionsList } = this.state;
    if (productForCollection) {
      collectionsList[index].productCount = 0;
      // input.productCount = 0;
      await this.setState({ currentCollection: input, collectionModal: false });
      await this.handleIncrement(productForCollection);
    } else {
      message.error('ERROR');
    }
  };

  handleDeleteCollection = async () => {
    const { productList } = this.state;
    productList.forEach((product, index) => {
      // saleor version
      // const foundCategory = _.find(comboRequireCategory, (e) => {
      //   return e === product.category.name;
      // });
      const foundCategory = product.needBox;
      if (foundCategory) {
        productList[index].count = 0;
      }
    });
    this.setState({ currentCollection: null, productList, boxModal: false });
  };

  handleShoppingCart = async () => {
    const { drawerModal } = this.state;
    this.setState({ drawerModal: !drawerModal });
  };

  handleCloseDrawerModal = async () => {
    console.log('closeDrawerModal');
    await this.setState({ drawerModal: false });
  };

  handleMobileShoppingCart = async () => {
    const { overWriteCallBack } = this.props;
    await overWriteCallBack(this.handleCloseMobileShoppingCart);
    this.setState((prevState) => ({
      mobileDrawerModal: !prevState.mobileDrawerModal,
    }));
  };

  handleCloseMobileShoppingCart = async () => {
    this.setState({ mobileDrawerModal: false });
  };

  // handle function for scroll event
  handleScroll = (event) => {
    if (!event.target.scrollingElement) {
      return;
    }
    this.setState({ scrollTop: event.target.scrollingElement.scrollTop });
  };

  // Modal Function

  onCancelCollectionModal = async () => {
    this.setState({ collectionModal: false });
  };

  onCancelBoxModal = async () => {
    this.setState({ boxModal: false });
  };

  onOpenProductDetailModal = (id) => {
    const { history, location } = this.props;
    const { pathname, search } = location;
    const searchObject = QueryString.parse(search);
    let query = '';
    Object.keys(searchObject).forEach((key) => {
      if (key !== 'productId') {
        query += `${key}=${searchObject[key]}&`;
      }
    });
    const newPath = `${pathname}?${query}productId=${id}`;
    history.replace(newPath);
    this.setState({ productDetailModalVisible: true, selectedProductId: id });
  };

  onCloseProductDetailModal = () => {
    const { history, location } = this.props;
    const { pathname, search } = location;
    const searchObject = QueryString.parse(search);
    let query = '';
    Object.keys(searchObject).forEach((key) => {
      if (key !== 'productId') {
        query += `${key}=${searchObject[key]}&`;
      }
    });
    query = query.slice(0, -1);
    const newPath = `${pathname}?${query}`;
    history.replace(newPath);
    this.setState({ productDetailModalVisible: false });
  };

  onOkBoxModal = async () => {
    const boxContent = JSON.parse(JSON.stringify(this.getCurrentBox()));
    const { currentCollection, staticProductList, totalCount } = this.state;
    const {
      updateWarehouseCart,
      getCurrentWarehouseCart,
      // currentWarehouseId,
    } = this.props;
    // const { intl } = this.props;
    const newId = nanoid(4);
    let listPrice = 0;
    boxContent.forEach((element) => {
      listPrice += element.listPrice * element.count;
    });
    const box = {
      id: newId,
      _id: newId,
      boxContent,
      currentCollection,
      count: 1,
      collapsed: false,
      imgUrl: currentCollection.backgroundImage.url,
      name: currentCollection.name,
      title: currentCollection.title,
      listPrice: listPrice.toFixed(2),
    };
    const shoppingCart = await getCurrentWarehouseCart();
    if (shoppingCart && shoppingCart.boxList) {
      shoppingCart.boxList.push(box);
      this.setState({ shoppingCart, totalCount: totalCount + 1 });
      await updateWarehouseCart(shoppingCart);
    } else {
      console.log('should never reached');
      const { shoppingCart: newCart } = this.state;
      newCart.boxList.push(box);
      this.setState({ shoppingCart: newCart });
      await localStorage.setItem('shoppingCart', JSON.stringify(newCart));
    }
    // message.success(intl.formatMessage({ id: 'addSign' }));
    // clean up
    staticProductList.forEach((e) => {
      if (e.inCurrentBox === true) {
        e.count = 0;
        e.quantity = 0;
        e.inCurrentBox = 0;
      }
    });
    await this.setState({
      boxModal: false,
      staticProductList,
      currentCollection: null,
    });
  };

  // ========= Processing Part

  getCurrentBox = () => {
    const res = [];
    const { staticProductList } = this.state;
    staticProductList.forEach((e) => {
      if (e.inCurrentBox === true) {
        if (e.count !== 0) {
          e.quantity = e.count;
          res.push(e);
        }
      }
    });
    return res;
  };

  updateShoppingCartCollection = async (input) => {
    const {
      getCurrentWarehouseCart,
      // currentWarehouseId,
      updateWarehouseCart,
    } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    if (shoppingCart && shoppingCart.boxList) {
      const index = _.findIndex(shoppingCart.boxList, ['id', input.id]);
      if (index >= 0) {
        shoppingCart.boxList[index] = input;
        if (input.count === 0) {
          _.remove(shoppingCart.boxList, (e) => {
            return e.count === 0;
          });
        }
        this.setState({ shoppingCart });
        this.calculatedTotalCount();
        await updateWarehouseCart(shoppingCart);
      } else {
        message.error('ERROR');
      }
    }
  };

  calculatedTotalCount = () => {
    const { shoppingCart } = this.state;
    let result = 0;
    if (shoppingCart.productList.length > 0) {
      shoppingCart.productList.forEach((product) => {
        result += product.count;
      });
    }
    if (shoppingCart.boxList.length > 0) {
      shoppingCart.boxList.forEach((box) => {
        result += box.count;
      });
    }
    this.setState({ totalCount: result });
  };

  updateShoppingCart = async (input) => {
    const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    if (shoppingCart && shoppingCart.productList) {
      const index = _.findIndex(shoppingCart.productList, ['id', input.id]);
      if (index >= 0) {
        shoppingCart.productList[index] = input;
      } else {
        console.log('input < 0 else', input);
        shoppingCart.productList.push(input);
      }
      if (input.count === 0) {
        _.remove(shoppingCart.productList, (e) => {
          return e.count === 0;
        });
      }
      this.setState({ shoppingCart });
      this.calculatedTotalCount();
      await updateWarehouseCart(shoppingCart);
      // console.log("updated shopping cart!!");
    } else {
      const { shoppingCart: newCart } = this.state;
      // const newCart = shoppingCart;
      console.log('should never reached');
      newCart.productList.push(input);
      // console.log("created shopping cart!!");
    }
  };

  filterProcessor = async (id, type = 'category') => {
    const { staticProductList, categoryToData } = this.state;
    this.setState({ selectedCategory: id });
    let res = [];
    switch (type) {
      case 'category': {
        if (id === 'All') {
          res = staticProductList;
          break;
        }
        categoryToData[id].forEach((e) => {
          const foundObj = _.find(staticProductList, (element) => {
            return element.id === e;
          });
          res.push(foundObj);
        });
        break;
      }
      default:
        break;
    }
    res = _.sortBy(res, [
      (o) => {
        return o.rank;
      },
    ]).reverse();
    this.setState({ productList: res });
  };

  handleScrollToCategory = (category, selectedTag) => {
    this.setState({ selectedCategory: category.name });
    const element = document.getElementById(category.name);
    // const deltaTop =
    //   (this.headerBannerHeightRef.current &&
    //     this.headerBannerHeightRef.current.clientHeight) ||
    //   0;
    // console.log(this.headerBannerHeightRef.current);
    // console.dir(this.headerBannerHeightRef.current);
    // console.dir(selectedTag);
    // console.log(selectedTag.offsetLeft);

    if (!isMobileSafari) {
      this.sliderRef.current.scrollTo({
        left: isMobile
          ? selectedTag.offsetLeft - 5
          : selectedTag.offsetLeft - 25,
        behavior: 'smooth',
      });
      window.scrollTo({
        top: element.offsetTop - HEADER_HEIGHT,
        behavior: 'smooth',
      });
    } else {
      this.sliderRef.current.scrollTo(selectedTag.offsetLeft - 5, 0);
      window.scrollTo(0, element.offsetTop - HEADER_HEIGHT);
    }
  };

  handleChangeOriginArray = (id, dom) => {
    this.$originArray[id] = dom;
  };

  onAnimate = () => {
    return new Promise((resolve) => {
      const animationDone = () => {
        this.setState({
          isBallVisible: false,
        });
        resolve();
      };
      const config = {
        ballWrapper: this.$wrapper,
        origin: this.$origin,
        target: this.$target,
        time: 600,
        a: 0.0009,
        callback: this.updateLocation,
        finish: animationDone.bind(this),
        offset: 0,
      };
      Parabola(config);
    });
  };

  updateLocation = (x, y) => {
    this.setState({
      ballX: x,
      ballY: y,
      isBallVisible: true,
    });
  };

  leftAffixButtonForMenu = () => {
    const { currentCollection, boxContainerVisible } = this.state;
    if (!currentCollection) {
      return null;
    }
    return (
      <div css={{ position: 'fixed', bottom: '20vh', zIndex: '100' }}>
        {!isMobile && (
          <div
            css={{
              background: 'white',
              position: 'relative',
            }}
          >
            {/* <div style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  this.setState({
                    boxContainerVisible: false,
                  });
                }}
                style={{ border: '0px' }}
                icon={<CloseSquareOutlined />}
                shape="circle"
              />
            </div> */}
            {this.collectionBoxContainer()}
          </div>
        )}

        {isMobile && boxContainerVisible && (
          <div
            css={{
              background: 'white',
              position: 'relative',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <div
                onClick={() => {
                  this.setState({
                    boxContainerVisible: false,
                  });
                }}
                role="presentation"
                css={{
                  border: '1px solid #fff',
                  backgroundColor: '#DF242F',
                  borderRadius: '5px',
                  textAlign: 'center',
                  fontSize: isMobile ? '14px' : '20px',
                  color: 'white',
                  cursor: 'pointer',
                  ':hover': { backgroundColor: '#eb4b50' },
                }}
              >
                <FormattedMessage id="closeModal" />
              </div>
            </div>
            {this.collectionBoxContainer()}
          </div>
        )}
        {!boxContainerVisible && isMobile && (
          <Button
            css={{
              borderRadius: '50%',
              width: '64px',
              height: '64px',
              padding: '0px',
              boxShadow: '0px 3px 12px rgba(15,15,15,.45)',
            }}
            onClick={async () => {
              await this.setState({ boxContainerVisible: true });
            }}
          >
            <img
              src={flatBox}
              css={{ width: '60px', height: '60px' }}
              alt="Color Bao"
            />
          </Button>
        )}
      </div>
    );
  };

  collectionBoxContainer = () => {
    const { currentCollection } = this.state;
    const { language } = this.props;

    if (!currentCollection) {
      return null;
    }

    return (
      <div css={{ height: '120px', width: '120px' }}>
        <div
          css={{
            fontSize: '18px',
            fontWeight: '500',
            textAlign: 'center',
          }}
        >
          {language === 'zh'
            ? currentCollection.name.zh
            : currentCollection.name.en}
        </div>
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            border: '1px solid #ff4949',
            backgroundImage: `url(${background}) `,
            backgroundSize: 'cover',
            borderRadius: '5px',
            padding: isMobile ? '1px' : '4px',
            '& .cell': {
              flex: '0 0 32%',
              marginRight: 'auto',
              marginLeft: 'auto',
              padding: isMobile ? '1px' : '4px',
            },
          }}
        >
          {[...Array(9).keys()].map((e) => (
            <div key={e} className="cell">
              <img
                alt="bao"
                style={{ width: '100%' }}
                src={e >= currentCollection.productCount ? Bao : BaoComplete}
              />
            </div>
          ))}
        </div>
        <div
          onClick={() => {
            this.handleDeleteCollection();
          }}
          role="presentation"
          css={{
            marginTop: '10px',
            border: '1px solid #fff',
            backgroundColor: '#DF242F',
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: isMobile ? '14px' : '20px',
            color: 'white',
            cursor: 'pointer',
            ':hover': { backgroundColor: '#eb4b50' },
          }}
        >
          <FormattedMessage id="deleteCollection" />
        </div>
        <div
          onClick={() => {
            this.setState({ boxModal: true });
          }}
          role="presentation"
          css={{
            border: '1px solid #fff',
            backgroundColor: '#DF242F',
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: isMobile ? '14px' : '20px',
            color: 'white',
            cursor: 'pointer',
            ':hover': { backgroundColor: '#eb4b50' },
          }}
        >
          <FormattedMessage id="viewBox" />
        </div>
      </div>
    );
  };

  freeShippingBanner = () => {
    const {
      freeShippingObj,
      customerOrderConfiguration,
      isFreeShoppingAlertClosed,
      currentWarehouseObj,
    } = this.state;
    const { language, intl } = this.props;
    // console.log(freeShippingObj);
    if (freeShippingObj) {
      const bannerRender = (value, flag) => {
        return (
          <div
            css={{
              padding: '10px',
              paddingLeft: '1rem',
              display: 'flex',
              alignItems: 'center',
              minHeight: isFreeShoppingAlertClosed ? 'auto' : '82.5px',
            }}
          >
            <Alert
              afterClose={() => {
                this.setState({ isFreeShoppingAlertClosed: true });
              }}
              css={{
                width: '100%',
              }}
              className="shipping-banner-alert"
              message={
                <div css={{ width: '100%' }}>
                  <TextLoop
                    css={{ width: '100%' }}
                    interval={10000}
                    mask
                    noWrap={false}
                  >
                    <div css={{ width: '100%', wordWrap: 'break-word' }}>
                      <div css={{ width: '100%' }}>
                        <img
                          style={{
                            height: '20px',
                            marginRight: '10px',
                          }}
                          src={freeShipping}
                          alt="free shipping"
                        />

                        {intl.formatMessage(
                          { id: 'menu.freeShippingScheduledDelivery' },
                          { amount: ` $${value} ` }
                        )}
                      </div>
                      <div>
                        {flag === 'delivery-menu-with-warehouse-id' &&
                          freeShippingObj.distance.map((item) => {
                            if (
                              item.maxMile <
                              currentWarehouseObj.deliveryMaxDistance
                            ) {
                              return (
                                <div css={{ width: '100% ' }} key={nanoid(5)}>
                                  {/* {`Delivery fee is $${item.flatFee} for ${item.minMile}~${item.maxMile} miles`} */}
                                  <FormattedMessage
                                    id="menu.deliveryFeeByRange"
                                    values={{
                                      deliveryFee: `$${item.flatFee}`,
                                      range: `${item.minMile}~${item.maxMile}`,
                                    }}
                                  />
                                </div>
                              );
                            }
                            if (
                              item.minMile <
                                currentWarehouseObj.deliveryMaxDistance &&
                              item.maxMile >=
                                currentWarehouseObj.deliveryMaxDistance
                            ) {
                              return (
                                <div css={{ width: '100% ' }} key={nanoid(5)}>
                                  {/* {`Delivery fee is $${item.flatFee} for ${item.minMile}~${item.maxMile} miles`} */}
                                  <FormattedMessage
                                    id="menu.deliveryFeeByRange"
                                    values={{
                                      deliveryFee: `$${item.flatFee}`,
                                      range: `${item.minMile}~${currentWarehouseObj.deliveryMaxDistance}`,
                                    }}
                                  />
                                </div>
                              );
                            }
                            return null;
                          })}
                        {flag === 'delivery-menu' && (
                          <div css={{ width: '100% ' }}>
                            <FormattedMessage
                              id="menu.deliveryFeeByAmount"
                              values={{
                                deliveryFee: `$${freeShippingObj.marketShippingFee}`,
                                amount: `$${value}`,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {flag === 'delivery-menu' &&
                      _.orderBy(
                        customerOrderConfiguration.marketNotices,
                        ['ranking'],
                        ['asc']
                      ).map((notice) => {
                        return (
                          <div key={nanoid(5)} css={{ width: '100%' }}>
                            {notice.notice[language].split('\n').map((line) => {
                              return <div key={nanoid(5)}>{line}</div>;
                            })}
                          </div>
                        );
                      })}
                  </TextLoop>
                </div>
              }
              type="warning"
              closable
            />
          </div>
        );
      };
      const {
        location: { search, pathname: path },
      } = this.props;
      if (path.includes('delivery-menu') && !search.includes('?warehouseId=')) {
        return bannerRender(
          freeShippingObj.marketMinimumAmountForShippingFree,
          'delivery-menu'
        );
      }
      if (search.includes('?warehouseId=')) {
        return bannerRender(
          freeShippingObj.minimumAmount,
          'delivery-menu-with-warehouse-id'
        );
      }
    }
    return null;
  };

  categoryIconRender = (array, isSelected = false) => {
    if ((!array || (array && array.length === 0)) && !isSelected) {
      return bunbaoLogo;
    }
    if ((!array || (array && array.length === 0)) && isSelected) {
      return bunbaoLogo;
    }
    if (isSelected && array[1]) {
      return BUCKET_URL + array[1].key;
    }
    if (!isSelected && array[0]) {
      return BUCKET_URL + array[0].key;
    }
    if (isSelected && !array[1]) {
      return bunbaoLogo;
    }
    return bunbaoLogo;
  };

  render() {
    const {
      drawerModal,
      mobileDrawerModal,
      loading,
      productList,
      // shoppingCart,
      collectionsList,
      // categoryToData,
      categories,
      collectionModal,
      currentCollection,
      boxModal,
      slides,
      selectedCategory,
      serviceChooseModalVisible,
      serviceMode,
      scrollTop,
      productDetailModalVisible,
      selectedProductId,
      ballX,
      ballY,
      isBallVisible,
      currentWarehouseObj,
      ourMissionModalVisible,
      donationFAQModalVisible,
      warehouseScheduledDeliveryMode,
      totalCount,
      videoModalVisible,
      selectedVideo,
      storeAnnouncementModalVisible,
      childrenWarehouseOrWebsiteMode,
      isShutDown,
      // freeShippingObj,
    } = this.state;

    const {
      language,
      history,
      intl,
      // setUserShoppingMode,
      // shoppingCart,
      // setWarehouse,
      allWarehouseCart,
      currentWarehouseId,
      // setUserAddressType,
      currentShoppingMode,
    } = this.props;
    // console.log("language",language);
    if (loading === false) {
      //  console.log("productList",productList)
      // console.log("productList",categoryToData)
      // console.log('collectionsList', collectionsList);
      // console.log("staticProductList",staticProductList);
      // console.log("staticProductIdToIndex",staticProductIdToIndex);
      // console.log('shoppingCart', shoppingCart);
    }
    const emptyCart = {
      productList: [],
      boxList: [],
    };
    const shoppingCart = allWarehouseCart[currentWarehouseId] || emptyCart;
    if (!shoppingCart.productList) {
      shoppingCart.productList = [];
    }
    if (!shoppingCart.boxList) {
      shoppingCart.boxList = [];
    }
    // console.log('shoppingCart in render',shoppingCart)
    let leftCategory;
    let rightMenu;
    let webDrawerComponent;
    let sideCartContent;
    let boxDetailModal;
    const renderAllProductsArray = [];

    const animateStyle = {
      transform: `translate(${ballX}px, ${ballY}px)`,
      opacity: isBallVisible ? 1 : 0,
    };

    if (loading === false) {
      categories.forEach((category) => {
        renderAllProductsArray.push(
          warehouseScheduledDeliveryMode && childrenWarehouseOrWebsiteMode ? (
            <div
              key={category.name}
              id={category.name}
              css={{
                width: '100%',
                lineHeight: '1.4',
                marginTop: '10px',
                marginBottom: '10px',
                display: 'flex',
              }}
            >
              <div
                css={{
                  backgroundColor: 'red',
                  width: '10px',
                  height: '100%',
                  marginRight: '5px',
                }}
              />
              <div css={{ width: 'calc( 100% - 10px )' }}>
                <div
                  css={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  <ShopOutlined />{' '}
                  {language === 'zh' ? category.title.zh : category.title.en}
                  &nbsp;
                  {category.videoIds && category.videoIds.length > 0 && (
                    <VideoCameraOutlined
                      style={{ color: 'red', marginLeft: '20px' }}
                      onClick={async () => {
                        await this.setState({
                          selectedVideo: category.videoIds,
                        });
                        await this.setState({
                          videoModalVisible: true,
                        });
                      }}
                    />
                  )}
                </div>
                <div css={{ fontSize: '15px' }}>
                  <ClockCircleOutlined />{' '}
                  {category.cutOffTimeDate === 'None' ? (
                    <FormattedMessage id="menu.canNotOrderForThisWeek" />
                  ) : (
                    <FormattedMessage
                      id="menu.orderBeforeTheDay"
                      values={{ date: category.cutOffTimeDate }}
                    />
                  )}
                </div>
                {/* <div css={{ fontSize: '15px' }}>
                  <EnvironmentOutlined /> {category.addressOne}{' '}
                  {category.addressTwo}, {category.city}
                </div> */}
                <div css={{ fontSize: '15px' }}>
                  <PhoneOutlined /> {category.phoneNumber}
                </div>
              </div>
            </div>
          ) : (
            <div
              id={category.name}
              css={{
                width: '100%',
                fontSize: '20px',
                fontWeight: 'bold',
                lineHeight: '1.4',
                marginTop: '10px',
                marginBottom: '10px',
              }}
              key={category.id}
            >
              <div
                css={{
                  backgroundColor: 'red',
                  display: 'inline-block',
                  width: '10px',
                  height: '100%',
                  marginRight: '5px',
                }}
              />
              {language === 'zh' ? category.title.zh : category.title.en}
            </div>
          )
        );
        productList.forEach((e) => {
          if (
            warehouseScheduledDeliveryMode &&
            childrenWarehouseOrWebsiteMode
          ) {
            if (category.name === e.warehouseId) {
              renderAllProductsArray.push(
                <SingleProductCard
                  key={e._id}
                  isShutDown={isShutDown}
                  e={e}
                  language={language}
                  translation={this.translation}
                  onOpenProductDetailModal={this.onOpenProductDetailModal}
                  handleDecrement={this.handleDecrement}
                  handleChangeOriginArray={this.handleChangeOriginArray}
                  handleIncrement={this.handleIncrement}
                />
              );
            }
          } else if (category.name === e.category.name) {
            renderAllProductsArray.push(
              <SingleProductCard
                key={e._id}
                e={e}
                language={language}
                translation={this.translation}
                onOpenProductDetailModal={this.onOpenProductDetailModal}
                handleDecrement={this.handleDecrement}
                handleChangeOriginArray={this.handleChangeOriginArray}
                handleIncrement={this.handleIncrement}
              />
            );
          }
        });
      });

      // count total count for boxes and products
      // let totalCount = 0;
      // if (shoppingCart.productList.length > 0) {
      //   shoppingCart.productList.forEach((product) => {
      //     totalCount += product.count;
      //   });
      // }
      // if (shoppingCart.boxList.length > 0) {
      //   shoppingCart.boxList.forEach((box) => {
      //     totalCount += box.count;
      //   });
      // }
      // check banner height for fix left side
      // let bannerHeight = 0;
      // if (document.getElementById('menuBanner')) {
      //   bannerHeight = document.getElementById('menuBanner').offsetHeight;
      // }
      // const sliderSetting = {
      //   dots: false,
      //   infinite: false,
      //   speed: 500,
      //   slidesToShow: 3,
      //   slidesToScroll: 1,
      //   swipe: true,
      //   // swipeToSlide: true,
      //   initialSlide: 0,
      // };
      leftCategory = (
        <div
          style={{
            minHeight: this.sliderRef.current
              ? `${this.sliderRef.current.clientHeight + 10}px`
              : 'auto',
          }}
        >
          <div
            style={
              scrollTop >
              (this.sliderContainerRef.current &&
                this.sliderContainerRef.current.offsetTop)
                ? {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    zIndex: '100',
                    backgroundColor: 'white',
                    // height: isMobile ? '80px' : '93px',
                    width: '100%',
                    padding: isMobile ? '5px 0px' : '5px 10px',
                  }
                : null
            }
            css={{
              padding: isMobile ? '5px 0px' : '5px 10px',
              position: 'relative',
            }}
          >
            {!isMobile && categories.length > 0 && (
              <LeftOutlined
                style={{
                  position: 'absolute',
                  left: '0px',
                  color: 'black',
                  top: 'calc(50% - 10px)',
                  fontSize: '20px',
                  // background: 'white',
                }}
                onClick={() => {
                  if (
                    this.sliderRef.current &&
                    this.sliderRef.current.scrollLeft > -1
                  ) {
                    this.sliderRef.current.scrollLeft -= window.innerWidth * 50;
                  }
                }}
              />
            )}

            {!isMobile && categories.length > 0 && (
              <RightOutlined
                style={{
                  position: 'absolute',
                  right: '0px',
                  color: 'black',
                  top: 'calc(50% - 10px)',
                  fontSize: '20px',
                  // background: 'white',
                }}
                onClick={() => {
                  if (
                    this.sliderRef.current &&
                    this.sliderRef.current.scrollLeft > -1
                  ) {
                    this.sliderRef.current.scrollLeft += window.innerWidth * 50;
                  }
                }}
              />
            )}
            <div
              ref={this.sliderRef}
              css={{
                display: 'flex',
                WebkitOverflowScrolling: 'touch',
                scrollBehavior: 'smooth',
                overflowX: 'scroll',
                '::-webkit-scrollbar': {
                  width: '0px !important',
                  display: 'none',
                },
                padding: isMobile ? '0px' : '0px 20px',
                minHeight: isMobile ? 'auto' : '64px',
                alignItems: 'center',
              }}
            >
              {categories.map((category) => {
                const {
                  location: { pathname, search },
                } = history;
                return (
                  <div
                    key={
                      warehouseScheduledDeliveryMode
                        ? category.name
                        : category.id
                    }
                    onClick={(event) => {
                      // this.filterProcessor(category);
                      this.handleScrollToCategory(
                        category,
                        event.currentTarget
                      );
                    }}
                    css={{
                      userSelect: 'none',
                      border:
                        selectedCategory === category.name
                          ? '2px solid #DF242F'
                          : '',
                      borderRadius: '3px',
                      padding: isMobile ? '4px 10px' : '5px 10px',
                      fontWeight: '600',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: isMobile ? '#DF242F' : '#ff8364',
                        color: 'white',
                      },
                      flexShrink: 0,
                      height: 'fit-content',
                    }}
                    role="presentation"
                  >
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {pathname.includes('delivery-menu') &&
                        (!search ||
                          (search && !search.includes('warehouseId'))) && (
                          <div>
                            <img
                              style={{ height: '64px', marginBottom: '2px' }}
                              src={
                                selectedCategory === category.name
                                  ? this.categoryIconRender(
                                      category.pictures,
                                      true
                                    )
                                  : this.categoryIconRender(
                                      category.pictures,
                                      false
                                    )
                              }
                              alt=""
                            />
                          </div>
                        )}
                      <div>
                        {warehouseScheduledDeliveryMode ? (
                          <span>
                            <ShopOutlined />{' '}
                          </span>
                        ) : null}
                        {language === 'zh'
                          ? category.title.zh
                          : category.title.en}{' '}
                        {category.hashtags &&
                          category.hashtags.length > 0 &&
                          category.hashtags.map((hashtag) => {
                            return (
                              <Tag
                                key={hashtag}
                                color={
                                  selectedCategory === category.name
                                    ? 'white'
                                    : '#DF242F'
                                }
                                css={{
                                  fontSize: '12px',
                                  color:
                                    selectedCategory === category.name
                                      ? 'red'
                                      : 'white',
                                }}
                              >
                                {hashtag}
                              </Tag>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {!isShutDown && (
              <div
                ref={(dom) => {
                  this.$target = dom;
                }}
                style={{
                  background: '#df242f',
                  color: 'white',
                  fontWeight: '400',
                  padding: '.5rem 1rem',
                  zIndex: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0 2px 0 rgba(0,0,0,.045)',
                }}
                css={
                  isMobile
                    ? {
                        width: '100%',
                        borderRadius: '0',
                        position: 'fixed',
                        zIndex: '5',
                        bottom: 0,
                        left: 0,
                        minHeight: '40px',
                      }
                    : {
                        cursor: 'pointer',
                        padding: '5px 15px',
                        fontSize: '14px',
                        right: '0',
                        position: 'absolute',
                        borderRadius: '2px',
                        userSelect: 'none',
                      }
                }
                role="presentation"
                onClick={() => {
                  if (isMobile) {
                    this.handleMobileShoppingCart();
                  } else {
                    this.handleShoppingCart();
                  }
                }}
              >
                <div
                  style={{ paddingBottom: 'env(safe-area-inset-bottom,20px)' }}
                >
                  <Badge
                    count={totalCount}
                    offset={[5, -10]}
                    css={{ color: 'white' }}
                  >
                    <ShoppingCartOutlined css={{ fontSize: 20 }} />{' '}
                    <FormattedMessage
                      id="viewCart"
                      css={{
                        fontSize: 20,
                      }}
                    />
                  </Badge>
                </div>
              </div>
            )}
          </div>
        </div>
      );
      rightMenu = (
        <div>
          {productList.length > 0 ? (
            <Row
              type="flex"
              gutter={15}
              style={{
                margin: '0',
                padding: isMobile ? '0px 10px' : '0px 15px',
                backgroundColor: 'white',
              }}
            >
              {renderAllProductsArray}
            </Row>
          ) : (
            <div style={{ fontSize: '27px', textAlign: 'center' }}>
              <div>
                <img src={timerImage} alt="Timer" css={{ height: '200px' }} />
              </div>
              <div>
                <FormattedMessage id="empty" />
              </div>
            </div>
          )}
        </div>
      );
      if (shoppingCart) {
        sideCartContent = (
          <SideCart
            css={{
              backgroundColor: '#fafafa',
            }}
            handleIncrement={this.handleIncrement}
            updateShoppingCartCollection={this.updateShoppingCartCollection}
            handleDecrement={this.handleDecrement}
            handleDelete={this.handleDelete}
            handleInsufficient={null}
            taxRate={0}
            intl={intl}
            handleMobileShoppingCart={this.handleMobileShoppingCart}
            handleCloseDrawerModal={this.handleCloseDrawerModal}
          />
        );
        webDrawerComponent = (
          <WebDrawer
            closable
            width="500px"
            placement="left"
            // closable={false}
            onClose={this.handleShoppingCart}
            visible={drawerModal}
            // getContainer={false}
            css={{ position: 'fixed' }}
            drawerStyle={{
              backgroundColor: '#fafafa',
            }}
            destroyOnClose
          >
            {sideCartContent}
          </WebDrawer>
        );
      }
      boxDetailModal = (
        <Modal
          visible={boxModal}
          onCancel={this.onCancelBoxModal}
          onOk={this.onOkBoxModal}
          footer={null}
        >
          <BoxDetail
            variants={this.getCurrentBox()}
            collection={currentCollection}
            handleIncrement={this.handleIncrement}
            handleDecrement={this.handleDecrement}
            onOkBoxModal={this.onOkBoxModal}
            onCancelBoxModal={this.onCancelBoxModal}
            handleDeleteCollection={this.handleDeleteCollection}
          />
        </Modal>
      );
    }

    const cartCssObject = {
      padding: '5px',
      zIndex: '20',
      // height: `calc( ${height}px - 45px )`,
      backgroundColor: '#fafafa',
      width: '100%',
      overflowX: 'hidden',
      paddingBottom: 'calc(env(safe-area-inset-bottom,0) + 40px)',
      position: 'relative',
    };

    return (
      <div
        css={{
          minHeight: isMobile ? height : '100vh',
          backgroundColor: '#FFF',
          position: 'relative',
        }}
        ref={(dom) => {
          this.$wrapper = dom;
        }}
      >
        <Modal
          footer={null}
          title={
            <div style={{ textAlign: 'center' }}>
              <FormattedMessage id="announcement" />
            </div>
          }
          visible={storeAnnouncementModalVisible}
          onCancel={() => {
            this.setState({ storeAnnouncementModalVisible: false });
          }}
        >
          <Announcement warehouse={currentWarehouseObj} />
        </Modal>
        <Modal
          maskClosable={false}
          visible={serviceChooseModalVisible}
          footer={null}
          title={null}
          width={768}
          onCancel={this.onCloseServiceModal}
        >
          <CustomerServiceChooseModal
            onCloseServiceModal={this.onCloseServiceModal}
            serviceMode={serviceMode}
          />
        </Modal>
        <Modal
          visible={productDetailModalVisible}
          footer={null}
          title={null}
          destroyOnClose
          onCancel={this.onCloseProductDetailModal}
          style={isMobile ? { top: 0 } : {}}
        >
          <ProductDetailModal
            productList={productList}
            selectedProductId={selectedProductId}
            handleIncrement={this.handleIncrement}
            handleDecrement={this.handleDecrement}
            isShutDown={isShutDown}
          />
        </Modal>
        {loading ? <Loading /> : null}
        {shoppingCart ? webDrawerComponent : null}
        <Modal
          visible={collectionModal}
          onCancel={this.onCancelCollectionModal}
          title={null}
          footer={null}
        >
          <ComboSelection
            intl={intl}
            collectionsList={collectionsList}
            handleSelectCollection={this.handleSelectCollection}
          />
        </Modal>
        <Modal
          title={null}
          footer={null}
          maskClosable
          closable
          onCancel={() => {
            this.setState({ videoModalVisible: false });
          }}
          visible={videoModalVisible}
          centered
          width="100%"
          bodyStyle={{
            minHeight: isMobile ? 300 : 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          destroyOnClose
          // wrapClassName="video-modal-content"
        >
          {videoModalVisible && (
            <div style={{ width: '100%' }}>
              <VideoBannerAlt
                bannerFullWidth={
                  isMobile ? window.innerWidth - 16 : window.innerWidth * 0.95
                }
                videoIds={selectedVideo}
              />
            </div>
          )}
        </Modal>
        {boxDetailModal}
        {!loading &&
          (isMobile ? (
            <div css={cartCssObject}>
              {mobileDrawerModal ? (
                <React.Fragment> {sideCartContent} </React.Fragment>
              ) : (
                <React.Fragment>
                  <div ref={this.headerBannerHeightRef}>
                    <div id="menuBanner">
                      {loading === false && slides.length > 0 ? (
                        <MenuBanner slides={slides} language={language} />
                      ) : null}
                    </div>
                    <div>
                      <WarehouseHeader
                        warehouse={currentWarehouseObj}
                        history={history}
                        warehouseScheduledDeliveryMode={
                          warehouseScheduledDeliveryMode
                        }
                      />
                    </div>
                    {currentShoppingMode !== 'pickup' &&
                      childrenWarehouseOrWebsiteMode === true &&
                      this.freeShippingBanner()}
                    {currentShoppingMode === 'donation' ? (
                      <div css={{ textAlign: 'center', margin: '30px auto' }}>
                        <div
                          css={{
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '700',
                            margin: '10px auto',
                          }}
                        >
                          <FormattedMessage id="donation.shopName" />
                        </div>
                        <div>
                          <Modal
                            style={{ top: 0 }}
                            width="100%"
                            visible={ourMissionModalVisible}
                            onCancel={() => {
                              this.setState({ ourMissionModalVisible: false });
                            }}
                            onOk={() => {
                              this.setState({ ourMissionModalVisible: false });
                            }}
                          >
                            <ArticleModal alias="donation-faq" />
                          </Modal>
                          <Modal
                            style={{ top: 0 }}
                            width="100%"
                            visible={donationFAQModalVisible}
                            onCancel={() => {
                              this.setState({ donationFAQModalVisible: false });
                            }}
                            onOk={() => {
                              this.setState({ donationFAQModalVisible: false });
                            }}
                          >
                            <ArticleModal alias="donation-our-mission" />
                          </Modal>
                        </div>
                        <div css={{ textAlign: 'center' }}>
                          <Button
                            type="primary"
                            css={{ marginRight: '10px ' }}
                            onClick={() => {
                              this.setState({ ourMissionModalVisible: true });
                            }}
                          >
                            <NotificationOutlined />{' '}
                            <FormattedMessage id="donation.ourMission" />
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              this.setState({ donationFAQModalVisible: true });
                            }}
                          >
                            <QuestionOutlined />{' '}
                            <FormattedMessage id="donation.faq" />
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {this.leftAffixButtonForMenu()}
                  <div ref={this.sliderContainerRef}>{leftCategory}</div>
                  <div>{rightMenu}</div>
                  {currentShoppingMode === 'donation' ? (
                    <DonationHeader
                      css={{
                        marginTop: '30px',
                        indexZ: 999,
                        backgroundColor: 'white',
                      }}
                    />
                  ) : null}
                </React.Fragment>
              )}
            </div>
          ) : (
            <div
              css={{
                maxWidth: '1300px',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <BackTop />
              <div ref={this.headerBannerHeightRef}>
                <div>
                  {loading === false && slides.length > 0 ? (
                    <div
                      css={{
                        width: '93%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <MenuBanner slides={slides} language={language} />
                    </div>
                  ) : null}
                </div>
                {currentShoppingMode === 'donation' ? (
                  <div>
                    <div
                      css={{
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '700',
                        margin: '10px auto',
                      }}
                    >
                      <FormattedMessage id="donation.shopName" />
                    </div>
                    <div>
                      <Modal
                        width="80%"
                        visible={ourMissionModalVisible}
                        onCancel={() => {
                          this.setState({ ourMissionModalVisible: false });
                        }}
                        onOk={() => {
                          this.setState({ ourMissionModalVisible: false });
                        }}
                      >
                        <ArticleModal alias="donation-faq" />
                      </Modal>
                      <Modal
                        width="80%"
                        visible={donationFAQModalVisible}
                        onCancel={() => {
                          this.setState({ donationFAQModalVisible: false });
                        }}
                        onOk={() => {
                          this.setState({ donationFAQModalVisible: false });
                        }}
                      >
                        <ArticleModal alias="donation-our-mission" />
                      </Modal>
                    </div>
                    <div css={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        css={{ marginRight: '10px ' }}
                        onClick={() => {
                          this.setState({ ourMissionModalVisible: true });
                        }}
                      >
                        <NotificationOutlined />{' '}
                        <FormattedMessage id="donation.ourMission" />
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          this.setState({ donationFAQModalVisible: true });
                        }}
                      >
                        <QuestionOutlined />
                        <FormattedMessage id="donation.faq" />
                      </Button>
                    </div>
                  </div>
                ) : null}
                <div>
                  <WarehouseHeader
                    warehouse={currentWarehouseObj}
                    history={history}
                    warehouseScheduledDeliveryMode={
                      warehouseScheduledDeliveryMode
                    }
                  />
                </div>
                {currentShoppingMode !== 'pickup' &&
                  childrenWarehouseOrWebsiteMode === true &&
                  this.freeShippingBanner()}
              </div>
              {/* <Row>
                <Col span={4}>
                  {loading === false ? (
                    <Affix offsetTop={65}>{leftCategory}</Affix>
                  ) : null}
                </Col>
                <Col span={20}>{rightMenu}</Col>
              </Row> */}
              {this.leftAffixButtonForMenu()}
              <div ref={this.sliderContainerRef}>{leftCategory}</div>
              <div>{rightMenu}</div>
              {currentShoppingMode === 'donation' ? (
                <div>
                  <DonationHeader />
                </div>
              ) : null}
            </div>
          ))}
        <div
          css={{
            width: '20px',
            height: '20px',
            background: '#DF242F',
            position: 'absolute',
            left: '0',
            top: '0',
            borderRadius: '50%',
            opacity: '0',
            zIndex: '5',
          }}
          style={animateStyle}
        />
      </div>
    );
  }
}
DeliveryMenu.defaultProps = {
  match: null,
  // currentAddressType: null,
};

DeliveryMenu.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  // updateShoppingCart: PropTypes.func.isRequired,
  setWarehouse: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  overWriteCallBack: PropTypes.func.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  // currentAddressType: PropTypes.string,
  currentWarehouseId: PropTypes.string.isRequired,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match,
  allWarehouseCart: PropTypes.shape({}).isRequired,
  currentShoppingMode: PropTypes.string.isRequired,
  updateOneWarehouseAttribute: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  shoppingCart: state.cart.shoppingCart,
  currentWarehouseId: state.warehouse.warehouseId,
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  // currentAddressType: state.user.currentAddressType,
  allWarehouseCart: state.cart.allWarehouseCart,
});

const mapDispatchToProps = (dispatch) => ({
  setWarehouse: (warehouseId) => dispatch(actions.setWarehouse(warehouseId)),
  setUserShoppingMode: (input) => dispatch(actions.setUserShoppingMode(input)),
  clearItems: () => dispatch(actions.clearItems()),
  // updateShoppingCart: (input) => dispatch(actions.updateShoppingCart(input)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  overWriteCallBack: (callback) =>
    dispatch(actions.overWriteCallBack(callback)),
  updateOneWarehouseAttribute: (input, attributeName) =>
    dispatch(actions.updateOneWarehouseAttribute(input, attributeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeliveryMenu));
