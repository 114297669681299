/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import {
  MinusOutlined,
  PlusOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { Alert, Button, Col, Row, message, Modal } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as actions from '../../../store/actions';
// import { nanoid } from 'nanoid';
import { DEFAULT_IMAGE } from '../../../config';
import cartEmpty from '../../../assets/images/cart_empty.png';
import { getCustomerPath } from '../../../util';
import OrderValidationModal from '../../Modals/OrderValidationModal/OrderValidationModal';

export class SideCart extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      validationModalVisible: false,
      // isDelivery: true,
    };
  }

  componentDidMount = async () => {};

  processProductList = (productList) => {
    let sum = 0;
    productList.forEach((product) => {
      if (product.count > 0) {
        sum += product.count * product.listPrice;
      }
    });
    return sum.toFixed(2);
  };

  onOpenChange = (...args) => {
    console.log(args);
    // this.setState({ open: !this.state.open });
  };

  checkOut = async () => {
    const { currentShoppingMode } = this.props;
    if (currentShoppingMode === 'delivery') {
      this.handleOpenValidationModal();
    } else if (currentShoppingMode === 'pickup') {
      this.handleOpenValidationModal();
    } else if (currentShoppingMode === 'donation') {
      this.handleOpenValidationModal();
    } else if (currentShoppingMode === 'eShop') {
      this.handleOpenValidationModal();
    } else {
      message.error('error shopping mode');
    }
  };

  getImage = (e) => {
    return e.imgUrl ? e.imgUrl : DEFAULT_IMAGE;
  };

  validationPassedCallback = async () => {
    const {
      // currentWarehouseId,
      history,
      location,
      // handleInsufficient,
      // setUserShoppingMode,
      setUserAddressType,
      handleCloseDrawerModal,
      currentShoppingMode,
      currentWarehouseId,
      getCurrentWarehouseCart,
      updateOneWarehouseAttribute,
    } = this.props;
    // const shoppingList
    // if(allWarehouseCart[currentWarehouseId]){
    // save link for back to menu
    const { pathname, search } = location;
    if (search) {
      if (search.search('cart=true') === -1) {
        await updateOneWarehouseAttribute(
          `${pathname + search}&cart=true`,
          'lastWarehousePath'
        );
      } else {
        await updateOneWarehouseAttribute(
          `${pathname + search}`,
          'lastWarehousePath'
        );
      }
    } else {
      await updateOneWarehouseAttribute(
        `${pathname}?cart=true`,
        'lastWarehousePath'
      );
    }
    //
    const shoppingList = await getCurrentWarehouseCart();
    console.log(shoppingList);
    // }
    // inventory check
    console.log('currentShoppingMode', currentShoppingMode);
    if (currentWarehouseId && currentShoppingMode === 'pickup') {
      // const {currentCampaignContentId } = this.props;
      // if (currentShoppingMode === 'groupBuy' && currentCampaignContentId) {
      //   // close modal and drawer
      //   await this.handleCloseValidationModal();
      //   await handleCloseDrawerModal();
      //   history.push(getCustomerPath('checkout/shipping'));
      //   return;
      // }
      // const variants = [];
      // shoppingList.forEach((variant) => {
      //   variants.push({ _id: variant._id, quantity: variant.count });
      // });
      // console.log(variants);
      // const { data } = await apolloClient.query({
      //   query: CHECK_INVENTORY_FOR_ORDER,
      //   variables: { warehouseId, variants },
      //   fetchPolicy: 'network-only',
      // });
      // if (data.checkInventoryForOrder.length > 0) {
      //   data.checkInventoryForOrder.forEach((element) => {
      //     handleInsufficient(element);
      //   });
      // } else {
      //   console.log(currentShoppingMode);
      //   await setUserShoppingMode('pickup');
      //   await setUserAddressType('pickup');
      //   // close modal and drawer
      //   await this.handleCloseValidationModal();
      //   await handleCloseDrawerModal();
      // }
      await setUserAddressType('pickup');
      history.push(getCustomerPath('checkout/shipping'));
    } else if (currentShoppingMode === 'delivery') {
      console.log('delivery mode checkout');
      // close modal and drawer
      await this.handleCloseValidationModal();
      await handleCloseDrawerModal();
      history.push(getCustomerPath('checkout/shipping'));
    } else if (currentWarehouseId && currentShoppingMode === 'donation') {
      history.push(getCustomerPath('checkout/payment'));
    } else if (currentWarehouseId && currentShoppingMode === 'eShop') {
      history.push(getCustomerPath('checkout/payment'));
    } else {
      message.error('error');
    }
  };

  handleCloseValidationModal = async () => {
    await this.setState({ validationModalVisible: false });
  };

  handleOpenValidationModal = () => {
    this.setState({ validationModalVisible: true });
  };

  handleCloseAllModal = async () => {
    const { handleMobileShoppingCart } = this.props;
    await this.setState({ validationModalVisible: false });
    if (isMobile) {
      await handleMobileShoppingCart();
    }
  };

  render() {
    const {
      handleDecrement,
      handleIncrement,
      handleDelete,
      updateShoppingCartCollection,
      language,
      taxRate,
      intl,
      handleMobileShoppingCart,
      // getCurrentWarehouseCart,
      currentWarehouseId,
      allWarehouseCart,
      // currentWarehouseId
      freeShippingObj,
    } = this.props;
    const emptyCart = {
      productList: [],
      boxList: [],
    };
    const { validationModalVisible } = this.state;
    const shoppingCart = allWarehouseCart[currentWarehouseId] || emptyCart;
    if (!shoppingCart.productList) {
      shoppingCart.productList = [];
    }
    if (!shoppingCart.boxList) {
      shoppingCart.boxList = [];
    }
    console.log('shoppingCart', shoppingCart);
    const { boxList } = shoppingCart;
    const shoppingList = shoppingCart.productList;
    // const { productList: shoppingList, boxList } =
    //   allWarehouseCart[currentWarehouseId] || emptyCart;
    // console.log('allWarehouseCart',allWarehouseCart)
    // console.log('currentWarehouseId',currentWarehouseId)
    // let boxList;
    // if(allWarehouseCart[currentWarehouseId]){
    //   shoppingList = allWarehouseCart[currentWarehouseId].productList;
    //   boxList = allWarehouseCart[currentWarehouseId].boxList;
    // }
    let subtotal = this.processProductList(shoppingList) * 1;
    console.log('1', shoppingList);
    subtotal = (subtotal + this.processProductList(boxList) * 1).toFixed(2);
    console.log('2', boxList);
    const tax = (subtotal * (taxRate / 100)).toFixed(2);
    const total = (tax * 1 + subtotal * 1).toFixed(2);
    // console.log('shoppingList in Modal', boxList);

    const checkIfHasGroupBuyItems = () => {
      return shoppingList?.some((item) => item.isGroupBuy);
    };

    const renderDescription = (e) => {
      return (
        <React.Fragment>
          <div
            css={{
              '& .dish-price': {
                fontSize: '16px',
                display: 'inline-block',
              },
              '& .dish-functional-block': {
                display: 'inline-block',
                float: 'right',
              },
              '& .dish-increment': {
                display: 'inline-block',
                width: '30px',
                height: '30px',
                border: '1px solid #e8e8e8',
                backgroundColor: '#ff4949',
                borderRadius: '5px',
                textAlign: 'center',
                fontSize: '20px',
                color: 'white',
                cursor: 'pointer',
                userSelect: 'none',
              },
              '& .dish-decrement': {
                display: 'inline-block',
                width: '30px',
                height: '30px',
                border: '1px solid #e8e8e8',
                backgroundColor: '#ff4949',
                borderRadius: '5px',
                textAlign: 'center',
                fontSize: '20px',
                color: 'white',
                cursor: 'pointer',
                userSelect: 'none',
              },
              '& .dish-counter': {
                textAlign: 'center',
                display: 'inline-block',
                fontSize: '20px',
                margin: '0 5px',
                width: '30px',
                height: '30px',
                border: '1px solid #e8e8e8',
                borderRadius: '5px',
                cursor: 'pointer',
                userSelect: 'none',
              },
            }}
          >
            <div className="dish-price">
              ${(e.count * e.listPrice).toFixed(2)}
            </div>
            <div className="dish-functional-block">
              {e.count === 1 ? (
                <div
                  className="dish-decrement"
                  role="presentation"
                  onClick={() => {
                    if (e.boxContent) {
                      e.count = 0;
                      updateShoppingCartCollection(e);
                    } else {
                      handleDelete(e._id);
                    }
                  }}
                >
                  <MinusOutlined />
                </div>
              ) : (
                <div
                  className="dish-decrement"
                  role="presentation"
                  onClick={() => {
                    if (e.boxContent) {
                      e.count -= 1;
                      updateShoppingCartCollection(e);
                    } else {
                      handleDecrement(e._id);
                    }
                  }}
                >
                  <MinusOutlined />
                </div>
              )}
              <div className="dish-counter">{e.count}</div>
              <div
                className="dish-increment"
                role="presentation"
                onClick={() => {
                  if (e.boxContent) {
                    e.count += 1;
                    updateShoppingCartCollection(e);
                  } else {
                    handleIncrement(e._id);
                  }
                }}
              >
                <PlusOutlined />
              </div>
            </div>
          </div>
          {e.inSufficient && e.count > e.inSufficientStock ? (
            <div>
              <Alert
                message={`${intl.formatMessage({ id: 'onlyLeft' })} ${
                  e.inSufficientStock
                } ${intl.formatMessage({ id: 'left' })}
              `}
                type="error"
              />
            </div>
          ) : null}
          {e.boxContent ? (
            <div>
              <div
                css={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={() => {
                  e.collapsed = !e.collapsed;
                  updateShoppingCartCollection(e);
                }}
                role="presentation"
              >
                {e.collapsed ? <CaretDownOutlined /> : <CaretRightOutlined />}
                <FormattedMessage id="boxDetails" />
              </div>
              {e.collapsed ? (
                <div
                  css={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '5px',
                    '& .dish-name': {
                      fontSize: '14px',
                      height: '30px',
                      padding: '5px',
                      borderBottom: '1px solid #e8e8e8',
                    },
                    '& .dish-name:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  {e.boxContent.map((element) => (
                    <div className="dish-name" key={element._id}>
                      <div css={{ display: 'inline-block', float: 'left' }}>
                        {element.name[language]}
                      </div>
                      <div css={{ display: 'inline-block', float: 'right' }}>
                        x {element.count}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
        </React.Fragment>
      );
    };
    const renderItemBlock = (element) => {
      return (
        <div
          css={{
            margin: '5px',
            display: 'flex',
            border: '1px solid #e8e8e8',
            backgroundColor: 'white',
            borderRadius: '5px',
            '& .dish-picture': {
              height: '80px',
              width: '80px',
              objectFit: 'cover',
              '& img': {
                height: '80px',
                width: '80px',
                objectFit: 'cover',
                borderRadius: '5px',
              },
            },
            '& .dish-details': {
              padding: '5px 15px',
              width: '100%',
            },
            '& .dish-title': {
              fontSize: '17px',
              fontWeight: '400',
            },
          }}
          key={element._id}
          id={element._id}
        >
          <div className="dish-picture">
            <img src={this.getImage(element)} alt="dish pictures" />
          </div>
          <div className="dish-details">
            <div className="dish-title">{element.name[language]}</div>
            <div>{renderDescription(element)}</div>
            {element.excludeFromFreeShipping && (
              <div css={{ fontSize: '10px' }}>
                <FormattedMessage id="menu.excludeFromFreeShipping" />
              </div>
            )}
          </div>
        </div>
      );
    };
    const renderSidebar = (
      <div css={{ backgroundColor: '#fafafa' }}>
        {boxList.map((e) => {
          return renderItemBlock(e);
        })}
        {shoppingList.map((e) => {
          return renderItemBlock(e);
        })}
        <div css={{ height: '30px', margin: '10px 20px' }}>
          <div css={{ fontSize: '18px' }}>
            <div
              css={{
                float: 'left',
                display: 'inline-block',
                userSelect: 'none',
              }}
            >
              <FormattedMessage id="subtotal" />
            </div>
            <div css={{ float: 'right', display: 'inline-block' }}>
              ${subtotal}
            </div>
          </div>
        </div>
        {tax > 0.1 ? (
          <div css={{ height: '30px', margin: '10px 20px' }}>
            <div css={{ fontSize: '18px' }}>
              <div
                css={{
                  float: 'left',
                  display: 'inline-block',
                  userSelect: 'none',
                }}
              >
                <FormattedMessage id="tax" />
              </div>
              <div css={{ float: 'right', display: 'inline-block' }}>
                ${tax}
              </div>
            </div>
          </div>
        ) : null}
        <div css={{ height: '30px', margin: '10px 20px' }}>
          <div css={{ fontSize: '18px' }}>
            <div
              css={{
                float: 'left',
                display: 'inline-block',
                userSelect: 'none',
              }}
            >
              <FormattedMessage id="totalPrice" />
            </div>
            <div css={{ float: 'right', display: 'inline-block' }}>
              ${total}
            </div>
          </div>
        </div>
        {checkIfHasGroupBuyItems() && (
          <div style={{ color: 'red', padding: '10px 10px' }}>
            *<FormattedMessage id="shoppingCartGroupBuyTips" />
          </div>
        )}
        <div css={{ position: 'sticky', bottom: '0' }}>
          <Button
            size="large"
            type="primary"
            onClick={async () => {
              await this.checkOut();
            }}
            css={isMobile ? { width: 'calc( 50% - 5px)' } : { width: '100%' }}
          >
            <FormattedMessage id="checkOut" />
          </Button>
          {isMobile ? (
            <Button
              size="large"
              style={{ marginLeft: '10px', width: 'calc( 50% - 5px)' }}
              onClick={() => {
                handleMobileShoppingCart();
              }}
            >
              <FormattedMessage id="continueShopping" />
            </Button>
          ) : null}
        </div>
        {freeShippingObj?.marketMinimumAmountForShippingFree > total && (
          <div style={{ paddingTop: '10px', color: '#717171' }}>
            {console.log(freeShippingObj)}
            <FormattedMessage
              id="moreFreeShippingTip"
              values={{
                price: `$${(
                  freeShippingObj?.marketMinimumAmountForShippingFree - total
                ).toFixed(2)}`,
              }}
            />
          </div>
        )}
      </div>
    );
    const finalComponent =
      shoppingList.length > 0 || boxList.length > 0 ? (
        renderSidebar
      ) : (
        <div>
          <Row
            type="flex"
            justify="center"
            css={{
              fontSize: '24px',
              fontWeight: 'normal',
              userSelect: 'none',
            }}
          >
            <Col span={24}>
              <img
                src={cartEmpty}
                alt="cart is empty"
                style={{
                  maxHeight: '200px',
                  maxWidth: '200px',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <div style={{ textAlign: 'center' }}>
                <FormattedMessage id="emptyCart" />
              </div>
              <div style={{ textAlign: 'center' }}>
                {isMobile ? (
                  <Button
                    size="large"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      handleMobileShoppingCart();
                    }}
                  >
                    <FormattedMessage id="continueShopping" />
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      );
    return (
      <React.Fragment>
        <Modal
          visible={validationModalVisible}
          onCancel={this.handleCloseValidationModal}
          footer={null}
        >
          {validationModalVisible ? (
            <OrderValidationModal
              validationPassedCallback={this.validationPassedCallback}
              handleCloseValidationModal={this.handleCloseAllModal}
            />
          ) : null}
        </Modal>
        {finalComponent}
      </React.Fragment>
    );
  }
}

SideCart.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  // handleInsufficient: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  language: PropTypes.string.isRequired,
  taxRate: PropTypes.number.isRequired,
  // warehouseId: PropTypes.string,
  updateShoppingCartCollection: PropTypes.func,
  // setUserShoppingMode: PropTypes.func.isRequired,
  handleMobileShoppingCart: PropTypes.func,
  currentShoppingMode: PropTypes.string,
  // currentCampaignContentId: PropTypes.string,
  setUserAddressType: PropTypes.func.isRequired,
  // shoppingCart: PropTypes.shape({
  //   productList: PropTypes.arrayOf(PropTypes.shape({})),
  //   boxList: PropTypes.arrayOf(PropTypes.shape({})),
  // }),
  currentWarehouseCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  // cartType: PropTypes.string.isRequired,
  handleCloseDrawerModal: PropTypes.func.isRequired,
  currentWarehouseId: PropTypes.string.isRequired,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  allWarehouseCart: PropTypes.shape({}).isRequired,
  updateOneWarehouseAttribute: PropTypes.func.isRequired,
  freeShippingObj: PropTypes.shape({
    marketMinimumAmountForShippingFree: PropTypes.number,
  }),
};

SideCart.defaultProps = {
  currentShoppingMode: 'delivery',
  handleMobileShoppingCart: null,
  updateShoppingCartCollection: null,
  // currentCampaignContentId: null,
  // handleInsufficient: null,
  // warehouseId: null,
  currentWarehouseCart: { productList: [] },
  freeShippingObj: null,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  // currentCampaignContentId: state.user.currentCampaignContentId,
  currentWarehouseCart: state.cart.currentWarehouseCart,
  allWarehouseCart: state.cart.allWarehouseCart,
  currentWarehouseId: state.warehouse.warehouseId,
});

const mapDispatchToProps = (dispatch) => ({
  // setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
  updateWarehouseCart: (currentWarehouseCart) =>
    dispatch(actions.updateWarehouseCart(currentWarehouseCart)),
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  updateOneWarehouseAttribute: (input, attributeName) =>
    dispatch(actions.updateOneWarehouseAttribute(input, attributeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideCart));
