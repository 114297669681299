/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'antd-form-builder';
import { FormattedMessage } from 'react-intl';
import { Form, TimePicker, Input, Checkbox, Button } from 'antd';
import moment from 'moment';
import { apolloClient } from '../../../util';
import { ORDER_CREATE, ORDER_CREATE_ADMIN } from '../../../api/order';

const TimePickerInput = ({ value, onChange }) => {
  const handleDisabledHours = () =>
    [...Array(9).keys()].concat([19, 20, 21, 22, 23]);
  return (
    <TimePicker
      value={value}
      hideDisabledOptions
      disabledHours={handleDisabledHours}
      format="HH:mm"
      minuteStep={15}
      allowClear={false}
      inputReadOnly
      onChange={(time) => onChange(time)}
    />
  );
};

TimePickerInput.defaultProps = {
  value: undefined,
};

TimePickerInput.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

function CateringOrderCheckoutForm(props) {
  const {
    address,
    taxRate,
    tip,
    cart,
    handleSuccessCreateOrder,
    handleCheckMinimum,
    admin,
    subtotal,
    userId,
    estHeadcount,
    calculateTip,
    handleSetHeadcount,
    handleCancel,
    handleSubmit,
    deliveryDetailDate,
    deliveryDetailTime,
  } = props;

  const [form] = Form.useForm();

  const [pending, setPending] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [lastChange, setLastChange] = useState('percentage');

  useEffect(() => {
    if (admin) {
      if (lastChange === 'amount') {
        // setDiscountPercentage((Number(discountAmount) / subtotal) * 100);
        form.setFieldsValue({
          discountPercentage: (Number(discountAmount) / subtotal) * 100,
        });
      } else {
        // setDiscountAmount((Number(discountAmount) / 100) * subtotal);
        form.setFieldsValue({
          discountAmount: (Number(discountPercentage) / 100) * subtotal,
        });
      }
    }
    if (deliveryDetailDate && deliveryDetailTime) {
      form.setFieldsValue({
        scheduleDate: deliveryDetailDate,
        scheduleTime: deliveryDetailTime,
      });
    }
  }, [
    subtotal,
    discountPercentage,
    discountAmount,
    lastChange,
    admin,
    form.setFieldsValue,
    form,
  ]);

  const handleSubmitOrderAdmin = async (values) => {
    console.log(address);

    console.log(values);
    console.log('Received values of form: ', values);
    const { scheduleDate, scheduleTime, headcount, deliveryNotes, notes } =
      values;
    console.log(scheduleDate.format('YYYY-MM-DD'));
    console.log(values);
    setPending(true);
    const schedule = moment(
      `${scheduleDate.format('YYYY-MM-DD')} ${scheduleTime.format('HH:mm')}`
    ).valueOf();
    const itemsArray = [];
    let headcountNumber = 0;
    if (headcount) {
      headcountNumber = Number(headcount);
    }
    // pass cart item to array
    cart.forEach((item) => {
      itemsArray.push({
        productId: item.product._id,
        quantity: item.quantity,
        isVariant: item.isVariant,
        variantId: item.variantId,
      });
    });
    try {
      if (admin) {
        const { adjustment, freeDelivery } = values;
        const response = await apolloClient.mutate({
          mutation: ORDER_CREATE_ADMIN,
          variables: {
            companyId: userId,
            products: itemsArray,
            scheduleDate: Number(schedule),
            headcount: headcountNumber,
            contactName: `${address.firstName} ${address.lastName}`,
            contactPhoneNumber: address.phoneNumber,
            companyName: address.companyName,
            addressOne: address.addressOne,
            addressTwo: address.addressTwo,
            city: address.city,
            state: address.state ? address.state : 'CA',
            zip: address.zipCode.toString(),
            deliveryNotes,
            notes,
            discount: {
              percentage: parseFloat(discountPercentage),
              amount: parseFloat(discountAmount),
            },
            adjustment: parseFloat(adjustment),
            freeDelivery,
          },
        });
        console.log(response);
        setPending(false);
        handleSuccessCreateOrder(response);
      } else {
        const response = await apolloClient.mutate({
          mutation: ORDER_CREATE,
          variables: {
            products: itemsArray,
            scheduleDate: Number(schedule),
            headcount: headcountNumber,
            contactName: `${address.firstName} ${address.lastName}`,
            contactPhoneNumber: address.phoneNumber,
            companyName: address.companyName,
            addressOne: address.addressOne,
            addressTwo: address.addressTwo,
            city: address.city,
            state: address.state ? address.state : 'CA',
            zip: address.zipCode.toString(),
            deliveryNotes,
            notes,
            tipSelected: tip.tipSelected,
            tipPercentage: Number(tip.tipPercentage),
            tipAmount: Number(tip.tipAmount),
          },
        });
        console.log(response);
        handleSuccessCreateOrder(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitOrder = (values) => {
    handleSubmit(values);
  };

  const handlePercentageChange = (e) => {
    console.log(e.target.value);
    const { value } = e.target;
    setDiscountAmount((Number(value) / 100) * subtotal);
    setLastChange('percentage');
    setDiscountPercentage(Number(value));
    form.setFieldsValue({
      discountAmount: (Number(value) / 100) * subtotal,
    });
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setDiscountPercentage((Number(value) / subtotal) * 100);
    setDiscountAmount(Number(value));
    setLastChange('amount');
    form.setFieldsValue({
      discountPercentage: (Number(value) / subtotal) * 100,
    });
  };

  const handleDisabledDate = (current) => {
    if (moment(current).weekday() === 6) {
      return true;
    }
    if (current < moment()) {
      return true;
    }
    // set cut off time for noon
    if (
      Number(moment().format('H')) >= 12 &&
      current < moment().add(1, 'day')
    ) {
      return true;
    }
    return false;
  };

  const meta = {
    disabled: pending,
    fields: [
      {
        key: 'scheduleDate',
        label: <FormattedMessage id="cateringDeliveryTime.scheduleDate" />,
        widget: 'date-picker',
        widgetProps: { disabledDate: handleDisabledDate },
        required: true,
      },
      {
        key: 'scheduleTime',
        label: <FormattedMessage id="cateringDeliveryTime.scheduleTime" />,
        forwardRef: true,
        widget: TimePickerInput,
        required: true,
      },
    ],
  };

  if (admin) {
    meta.fields.push(
      {
        key: 'headcount',
        label: 'Headcount',
        widgetProps: {
          onChange(event) {
            handleSetHeadcount(event.target.value);
          },
          suffix: (
            <div>
              <FormattedMessage id="cateringPayment.pricePerHead" />:{' $'}
              {subtotal !== 0 &&
                form.getFieldValue('headcount') &&
                (
                  (subtotal + subtotal * taxRate + calculateTip()) /
                  form.getFieldValue('headcount')
                ).toFixed(2)}
              {subtotal !== 0 &&
                !form.getFieldValue('headcount') &&
                (
                  (subtotal + subtotal * taxRate + calculateTip()) /
                  estHeadcount
                ).toFixed(2)}
              {subtotal === 0 && 0}
            </div>
          ),
        },
      },
      {
        key: 'deliveryNotes',
        label: 'Delivery Instruction',
        widget: 'textarea',
      },
      {
        key: 'notes',
        label: 'Notes',
        widget: 'textarea',
      }
    );
  }

  return (
    <div>
      <div>
        <Form
          form={form}
          onFinish={admin ? handleSubmitOrderAdmin : handleSubmitOrder}
          css={{
            '.ant-col-8': {
              maxWidth: '100%',
            },
            '.ant-col-16': {
              maxWidth: '100%',
            },
          }}
          layout="vertical"
        >
          {admin && (
            <>
              <Form.Item
                label="Percentage(Subtotal)"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="discountPercentage"
                initialValue={0}
              >
                <Input onChange={handlePercentageChange} addonAfter="%" />
              </Form.Item>
              <Form.Item
                label="Amount(Subtotal)"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="discountAmount"
                initialValue={0}
              >
                <Input
                  type="number"
                  addonBefore="$"
                  onChange={handleAmountChange}
                />
              </Form.Item>
              <Form.Item
                label="Adjustment(Directly Change)"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="adjustment"
                initialValue={0}
              >
                <Input type="number" addonBefore="$" />
              </Form.Item>
              <Form.Item
                label="Free Delivery"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="freeDelivery"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </Form.Item>
            </>
          )}
          <FormBuilder meta={meta} form={form} />
          {!admin && (
            <Form.Item>
              <div
                css={{
                  button: {
                    borderRadius: '25px',
                    width: '100%',
                    maxWidth: '200px',
                  },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button type="primary" htmlType="submit">
                  <FormattedMessage id="deliveryDetailSubmit" />
                </Button>
                <Button
                  css={{ marginLeft: '15px' }}
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  <FormattedMessage id="deliveryDetailCancel" />
                </Button>
              </div>
            </Form.Item>
          )}
          {admin && (
            <Form.Item>
              <div style={{ marginBottom: '20px' }}>
                If you have any questions, feel free contact us at{' '}
                <a href="mailto:cater@bunbao.com">cater@bunbao.com</a>
              </div>
              <Button
                disabled={!address || handleCheckMinimum()}
                size="large"
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
              >
                {handleCheckMinimum() && '$100.00 minimum for delivery'}
                {!handleCheckMinimum() && 'Checkout'}
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );
}

CateringOrderCheckoutForm.defaultProps = {
  address: null,
  userId: null,
  tip: null,
  subtotal: 0,
  taxRate: 0,
  estHeadcount: 0,
  deliveryDetailDate: null,
  deliveryDetailTime: null,
};

CateringOrderCheckoutForm.propTypes = {
  address: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    state: PropTypes.string,
  }),
  tip: PropTypes.shape({
    tipSelected: PropTypes.string,
    tipAmount: PropTypes.number,
    tipPercentage: PropTypes.number,
  }),
  handleSuccessCreateOrder: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCheckMinimum: PropTypes.func.isRequired,
  userId: PropTypes.string,
  admin: PropTypes.bool.isRequired,
  subtotal: PropTypes.number,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  deliveryDetailDate: PropTypes.shape({}),
  deliveryDetailTime: PropTypes.shape({}),
  taxRate: PropTypes.number,
  estHeadcount: PropTypes.number,
  calculateTip: PropTypes.func.isRequired,
  handleSetHeadcount: PropTypes.func.isRequired,
};

export default CateringOrderCheckoutForm;
