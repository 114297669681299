import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Distributor from '../../../../assets/images/distributor.png';
import Central from '../../../../assets/images/central.png';
import FarmersMarket from '../../../../assets/images/farmersMarket.png';

export class WarehousePoint extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { warehouseType, handleClickWarehouse, warehouse } = this.props;
    const { selected } = warehouse;

    return (
      <div
        onClick={() => {
          handleClickWarehouse(warehouse);
        }}
        role="presentation"
      >
        {warehouseType === 'central' ? (
          <img
            src={Central}
            style={
              selected
                ? {
                    width: '70px',
                    height: '70px',
                    transform: 'translate(-50%, -100%)',
                  }
                : {
                    width: '50px',
                    height: '50px',
                    transform: 'translate(-50%, -100%)',
                  }
            }
            alt="central"
          />
        ) : null}
        {warehouseType === 'distributor' ? (
          <img
            src={Distributor}
            style={
              selected
                ? {
                    width: '70px',
                    height: '70px',
                    transform: 'translate(-50%, -100%)',
                  }
                : {
                    width: '50px',
                    height: '50px',
                    transform: 'translate(-50%, -100%)',
                  }
            }
            alt="distributor"
          />
        ) : null}
        {warehouseType === 'farmersMarket' ? (
          <img
            src={FarmersMarket}
            style={
              selected
                ? {
                    width: '70px',
                    height: '70px',
                    transform: 'translate(-50%, -100%)',
                  }
                : {
                    width: '50px',
                    height: '50px',
                    transform: 'translate(-50%, -100%)',
                  }
            }
            alt="farmersMarket"
          />
        ) : null}
      </div>
    );
  }
}

WarehousePoint.propTypes = {
  warehouseType: PropTypes.string.isRequired,
  handleClickWarehouse: PropTypes.func.isRequired,
  warehouse: PropTypes.shape({ selected: PropTypes.bool }).isRequired,
};

export default WarehousePoint;
