/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import convert from 'htmr';
import BraftEditor from 'braft-editor';
import { convertRawToHTML } from 'braft-convert';
import {
  Row,
  message,
  Modal,
  Drawer as WebDrawer,
  Button,
  Badge,
  Tag,
  Alert,
  BackTop,
  Spin,
  Input,
  // Pagination,
} from 'antd';
import {
  ShoppingCartOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  ArrowsAltOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as R from 'ramda';
import TextLoop from 'react-text-loop';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { WechatAPI } from 'react-wechat-api';
import * as QueryString from 'query-string';
import * as actions from '../../../../store/actions';
import { DEFAULT_IMAGE, BUCKET_CDN_URL, BUCKET_URL } from '../../../../config';
import Parabola from '../../../../components/Others/Parabola/Parabola';
import {
  apolloClient,
  checkWeChatUserAgent,
  getCustomerPath,
  translateWords,
  updateVariantPrice,
} from '../../../../util';
import { CONFIGURATION_FIND_ONE } from '../../../../api/configuration';
import { BANNER_FIND_MANY } from '../../../../api/banner';
import { CATEGORY_QUERY, ALLOWED_CATEGORY } from '../../../../api/category';
import { FETCH_BOX } from '../../../../api/container';
import {
  BUNBAO_VARIANT_PAGINATION,
  BUNBAO_VARIANT_KEYWORDS_PAGINATION,
  // VARIANTS_QUERY_IDS,
  CHECK_VARIANTS_VALIDATION,
  BUNBAO_VARIANT_BY_ID,
} from '../../../../api/variant';
import {
  WAREHOUSE_INFO_FIND_ONE,
  WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
} from '../../../../api/warehouse';

import SingleProductCard from '../SingleProductCard/SingleProductCard';
import SideCart from '../../../../components/Drawers/SideCart/SideCart';
import BoxDetail from '../BoxDetail/BoxDetail';
// import Announcement from '../Announcement/Announcement';
import ProductDetailModal from '../../../../components/Modals/ProductDetailModal/ProductDetailModal';
import Loading from '../../../../components/Others/Loading/Loading';
import ComboSelection from '../../../../components/ComboSelection/ComboSelection';
import VideoBannerAlt from '../../../../components/VideoBanner/VideoBannerAlt';
import WarehouseHeader from '../../../../components/MiddleComponents/WarehouseHeader';
import MenuBanner from '../../../../components/MenuBanner/MenuBanner';

import flatBox from '../../../../assets/images/icons/flatBox.svg';
import background from '../../../../assets/images/homepage/sectionSmallBackground.png';
import Bao from '../../../../assets/images/bao.png';
import BaoComplete from '../../../../assets/images/baoColor.png';
import bunbaoLogo from '../../../../assets/images/BunbaoSmall.png';
// import bunbaoLogoWhite from '../../../../assets/images/BunbaoWhiteSmall.png';
import freeShipping from '../../../../assets/images/icons/freeShipping.svg';
import './MarketMenu.less';

const { Search } = Input;
// const HEADER_HEIGHT = 66;
const PAGE_SIZE = isMobile ? 10 : 15;
const { innerHeight: height } = window;
class MarketMenu extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      warehouseId: null,
      productList: [],
      // higherRankingList: [],
      loading: true,
      // ingredientToData: {},
      categoryToData: {},
      categories: [],
      // saleToData: {},
      // collection part
      collectionsList: [],
      collectionModal: false,
      currentCollection: null,
      productForCollection: null,
      // box part
      boxModal: false,
      // viewBox: false,
      shoppingCart: {
        productList: [],
        boxList: [],
      },
      drawerModal: false,
      mobileDrawerModal: false,
      slides: [],
      // category
      selectedCategory: {},
      // choose modal
      serviceChooseModalVisible: false,
      serviceMode: null,
      // Product detail modal
      productDetailModalVisible: false,
      selectedProductId: null,
      ourMissionModalVisible: false,
      donationFAQModalVisible: false,
      // scrollToTop
      scrollTop: 0,
      ballX: 0,
      ballY: 0,
      isBallVisible: false,
      words: [],
      // Warehouse Info
      currentWarehouseObj: null,
      // isDeliveryMenu: false,
      totalCount: 0,
      videoModalVisible: false,
      selectedVideo: null,
      storeAnnouncementModalVisible: false,
      freeShippingObj: null,
      customerOrderConfiguration: null,
      boxContainerVisible: false,
      isFreeShoppingAlertClosed: false,
      totalVariantsNum: 0,
      curPage: 2,
      invalidVariantModal: false,
      isCategoryLoading: false,
      boxContent: [],
      wechatShareData: {
        title: '跟我一起逛【棒包美食超市】，一起享受优惠价格！',
      },
      showTip: false,
      bannerIsClickableModal: false,
      selectedIsClickableBanner: null,
      boxMaxLimit: 9,
      groupBuyLimitModal: false,
      keywords: null,
      isSearchLoading: false,
      specialMenu: false,
      categoryExpanded: false,
    };
    this.$originArray = {};
    this.sliderRef = React.createRef();
    this.sliderContainerRef = React.createRef();
    this.headerBannerHeightRef = React.createRef();
  }

  componentDidMount = async () => {
    const {
      location: { search, pathname },
      setUserShoppingMode,
    } = this.props;
    const { cart, productId, categoryId, special } = QueryString.parse(search);

    if (special === 'true' || pathname === getCustomerPath('bao')) {
      await this.setState({ specialMenu: true });
      // setup redux remember current user
    } else {
      // remove redux
    }
    // delivery menu
    setUserShoppingMode('delivery');
    await this.handleFetchFreeShippingAmount();
    await this.fetchMenuCategories();
    await this.fetchWarehouseInfoData();
    // check category id
    if (categoryId) {
      const { categories } = this.state;
      const category = categories.find((element) => element._id === categoryId);
      await this.setState({
        selectedCategory: category,
        curPage: 2,
        isCategoryLoading: true,
      });
      this.scrollToCategory();
      await this.handleFetchProductData();
    } else {
      await this.handleFetchProductData();
    }
    await this.fetchBanner();
    await this.fetchCollectionData();

    await this.fetchShoppingCart();
    await this.handleFetchWords();

    if (cart === 'true') {
      if (isMobile) {
        await this.handleMobileShoppingCart();
      } else {
        this.setState({ drawerModal: true });
      }
    }

    window.addEventListener('scroll', this.handleScroll, true);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // check product id to show product modal
    if (productId) {
      // check product id
      const { productList } = this.state;
      if (
        productList.findIndex((product) => product._id === productId) !== -1
      ) {
        this.onOpenProductDetailModal(productId);
      } else {
        const isValid = await this.checkVariantsValidation(productId);
        console.log(isValid);
        if (isValid) {
          this.onOpenProductDetailModal(productId);
        } else {
          this.setState({
            invalidVariantModal: true,
          });
        }
      }
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      if (!isMobile) {
        document.body.style.cssText = '';
      }
    }, 300);
  };

  handleFetchSelectProduct = async (productId) => {
    const {
      data: { variantById },
    } = await apolloClient.query({
      query: BUNBAO_VARIANT_BY_ID,
      variables: {
        Id: productId,
      },
    });
    const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
    variantById.product.imgUrl = getImg(variantById.product);
    if (variantById.product.imgUrl !== DEFAULT_IMAGE) {
      variantById.product.imgUrl = `${BUCKET_CDN_URL}${variantById.product.imgUrl}`;
    }
    const productImgUrl = variantById.product.imgUrl;
    const productName = variantById.name.zh;
    const productDescription = variantById.description.zh;
    let unitPrice = '';
    if (variantById.isShowUnitPrice) {
      unitPrice = ` ($${Number(
        variantById.listPrice / variantById.numberOfUnits
      ).toFixed(2)}/${variantById.product.unit})`;
    }
    this.setState({
      wechatShareData: {
        title: `【${productName}${unitPrice}】, 超低价棒包【生鲜聚划算】`,
        desc: `向朋友们种草，朋友下单后，你将获得3%的订单金额红包哦! ${productDescription}`,
        imgUrl: productImgUrl,
      },
    });
  };

  checkVariantsValidation = async (id) => {
    try {
      const {
        data: { checkVariantsValidation },
      } = await apolloClient.query({
        query: CHECK_VARIANTS_VALIDATION,
        variables: {
          variantIds: [id],
          shoppingMode: 'supermarket',
        },
      });
      console.log(checkVariantsValidation);
      return checkVariantsValidation.length === 1;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  handleScroll = (event) => {
    if (!event.target.scrollingElement) {
      return;
    }
    this.setState({ scrollTop: event.target.scrollingElement.scrollTop });
  };

  fetchMenuCategories = async () => {
    try {
      let {
        data: { allowedVariantsCategory },
      } = await apolloClient.query({
        query: ALLOWED_CATEGORY,
        variables: {
          filter: { warehouseId: null, type: 'product', deleted: false },
        },
      });
      console.log(allowedVariantsCategory);
      const { specialMenu } = this.state;
      if (specialMenu) {
        // change category ranking
        console.log(allowedVariantsCategory);
        allowedVariantsCategory = allowedVariantsCategory.map((category) => {
          if (category._id === '5d66d03fac4600001fc02bb4') {
            return { ...category, ranking: -2 };
          }
          if (category._id === '615b3d0ee24264002388b53f') {
            return { ...category, ranking: -1 };
          }
          return { ...category, hidden: true };
        });
        allowedVariantsCategory.push({
          name: 'Icon',
          isIcon: true,
          ranking: 100,
        });
      }

      const sortedCategories = _.orderBy(
        [...allowedVariantsCategory],
        ['ranking', 'name'],
        ['asc', 'asc']
      );

      _.remove(sortedCategories, (e) => {
        if (e._id === '5d66a97dc569470018a2e0cb') {
          return true;
        }
        return e.deleted === true;
      });

      // if (
      //   sortedCategories &&
      //   sortedCategories.length &&
      //   sortedCategories[0]._id !== 'All'
      // ) {
      //   sortedCategories.unshift({
      //     _id: 'All',
      //     id: 'All',
      //     pictures: [],
      //     name: 'All',
      //     ranking: 0,
      //     hashtags: [],
      //     title: {
      //       zh: '全部',
      //       en: 'All',
      //     },
      //   });
      // }
      this.setState({
        selectedCategory: sortedCategories[0],
        categories: sortedCategories,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleExpandedClick = async () => {
    const { categoryExpanded, categories } = this.state;
    if (categoryExpanded) {
      const newCategories = categories.map((category) => {
        if (
          category._id === '5d66d03fac4600001fc02bb4' ||
          category._id === '5ed17be2809a830012c8b0f8' ||
          category.isIcon
        ) {
          return { ...category };
        }
        return { ...category, hidden: true };
      });
      await this.setState({
        selectedCategory: newCategories[0],
        categories: newCategories,
        categoryExpanded: false,
      });
    } else {
      const newCategories = categories.map((category) => {
        if (
          category._id === '5d66d03fac4600001fc02bb4' ||
          category._id === '5ed17be2809a830012c8b0f8' ||
          category.isIcon
        ) {
          return { ...category };
        }
        return { ...category, hidden: false };
      });
      console.log(newCategories);
      await this.setState({
        categories: newCategories,
        categoryExpanded: true,
      });
    }
  };

  getCountByCollection = (collection) => {
    if (collection?.container?.minimumAddQuantity) {
      return collection.container.minimumAddQuantity;
    }
    return 1;
    // if (collection) {
    //   switch (collection.title) {
    //     case 'three-flavor-box': {
    //       return 3;
    //     }
    //     case 'one-flavor-box': {
    //       return 9;
    //     }
    //     default: {
    //       return 1;
    //     }
    //   }
    // }
    // return 1;
  };

  handleFetchFreeShippingAmount = async () => {
    try {
      const {
        data: { configurationOne },
      } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: {
          filter: { isScheduledDeliveryConfiguration: true },
        },
      });
      this.setState({
        freeShippingObj: configurationOne.scheduledDeliveryConfiguration,
      });

      const {
        data: { configurationOne: customerOrderConfiguration },
      } = await apolloClient.query({
        query: CONFIGURATION_FIND_ONE,
        variables: { filter: { isCustomerOrderConfiguration: true } },
      });
      this.setState({
        customerOrderConfiguration:
          customerOrderConfiguration.customerOrderConfiguration,
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchBanner = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'menu' } },
      });
      // console.log(bannerFindMany);
      // sort banner
      const rawData = bannerFindMany;
      const slides = _.orderBy(
        rawData,
        ['ranking', 'createdAt'],
        ['asc', 'asc']
      );
      this.setState({ slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  fetchWarehouseInfoData = async () => {
    const { updateOneWarehouseAttribute, setWarehouse } = this.props;
    try {
      const {
        data: { warehouseOne },
      } = await apolloClient.query({
        query: WAREHOUSE_INFO_FIND_ONE,
        variables: { filter: { warehouseType: 'website' } },
        fetchPolicy: 'network-only',
      });
      console.log(warehouseOne);
      await updateOneWarehouseAttribute(false, 'isScheduledDelivery');
      await setWarehouse(warehouseOne._id);
      await this.setState({
        warehouseId: warehouseOne._id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleFetchProductData = async (
    page = 1,
    perPage = PAGE_SIZE,
    isScrollFetch = false
  ) => {
    const {
      warehouseId,
      selectedCategory,
      curPage,
      productList,
      boxContent,
      keywords,
    } = this.state;
    console.log('keywords', keywords);
    const { getCurrentWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    const filter = {
      deliveryMenuWarehouseIds: [warehouseId],
    };
    if (
      selectedCategory &&
      selectedCategory._id &&
      selectedCategory.id !== 'All' &&
      !keywords
    ) {
      filter.categoryId = selectedCategory._id;
    }
    if (keywords) {
      filter.keywords = keywords;
    }

    try {
      const { data } = await apolloClient.query({
        query: keywords
          ? BUNBAO_VARIANT_KEYWORDS_PAGINATION
          : BUNBAO_VARIANT_PAGINATION,
        variables: {
          page,
          perPage,
          filter,
          sort: 'CATEGORY_VARIANT_RANKING_ASC',
        },
        fetchPolicy: 'network-only',
      });
      const {
        data: { containerMany },
      } = await apolloClient.query({
        query: FETCH_BOX,
        variables: {},
        fetchPolicy: 'network-only',
      });

      const websiteMenu = keywords
        ? data.variantPaginationWithKeywordsAndInfiniteScroll.items
        : data.variantPaginationWithInfiniteScroll.items;
      console.log('websiteMenu', websiteMenu);
      const variantsNeedsInventory = [];
      const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
      websiteMenu.forEach((newElement) => {
        if (newElement.allowedInventoryCheck === true) {
          variantsNeedsInventory.push(newElement._id);
        }
        newElement.category = newElement.product.category;
        newElement.hashtags = newElement.product.hashtags;
        newElement.imgUrl = getImg(newElement);
        newElement.id = newElement._id;
        // websiteMenu[index].rank = newElement.ranking;
        const variant = boxContent.find((item) => item._id === newElement._id);
        if (variant) {
          newElement.count = variant.count;
        }
        if (newElement.count === undefined) {
          newElement.count = 0;
        }
        newElement.needBox = false;
        newElement.product.imgUrl = getImg(newElement.product);
        if (newElement.markAsSoldOut === true) {
          newElement.soldOut = true;
        }
        if (newElement.product.imgUrl !== DEFAULT_IMAGE) {
          newElement.product.imgUrl = `${BUCKET_CDN_URL}${newElement.product.imgUrl}`;
        }
        if (newElement.imgUrl !== DEFAULT_IMAGE) {
          newElement.imgUrl = `${BUCKET_CDN_URL}${newElement.imgUrl}`;
        } else {
          newElement.imgUrl = newElement.product.imgUrl;
        }
        if (newElement.isChangeListPriceByDates) {
          // check date and change list price
          const today = moment().valueOf();
          if (
            today > newElement.changeListPriceDates.endDate ||
            today < newElement.changeListPriceDates.startDate
          ) {
            newElement.listPrice = newElement.originalPrice;
          }
        }
      });

      // add inventory
      console.log('variantsNeedsInventory', variantsNeedsInventory);
      const {
        data: { inventoryCurrentWarehouseWithVariantIds },
      } = await apolloClient.query({
        query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
        variables: {
          warehouseId,
          variantIds: variantsNeedsInventory,
        },
        fetchPolicy: 'network-only',
      });
      if (inventoryCurrentWarehouseWithVariantIds) {
        inventoryCurrentWarehouseWithVariantIds.forEach((variant) => {
          const findOne = _.find(websiteMenu, (element) => {
            return element._id === variant._id;
          });
          if (findOne) {
            findOne.totalQuantity = variant.totalQuantity;
            if (variant.totalQuantity <= 0) {
              findOne.soldOut = true;
            }
          }
        });
      }

      // check variant if need box
      const boxSample = containerMany[0];
      if (boxSample.allowedVariantIds.length > 0) {
        boxSample.allowedVariantIds.forEach((variantId) => {
          const index = websiteMenu.findIndex((item) => item._id === variantId);
          if (index > -1) {
            websiteMenu[index].needBox = true;
          }
        });
      }
      if (boxSample.notAllowedVariantIds.length > 0) {
        websiteMenu.forEach((newElement) => {
          newElement.needBox = true;
        });
        boxSample.notAllowedVariantIds.forEach((variantId) => {
          const index = websiteMenu.findIndex((item) => item._id === variantId);
          // console.log('index', index);
          if (index >= 0) {
            websiteMenu[index].needBox = false;
          }
        });
      }

      // console.log('staticProductIdToIndex', staticProductIdToIndex);
      // const sortedCategories = _.orderBy(
      //   [...categories],
      //   ['ranking', 'name'],
      //   ['asc', 'asc']
      // );
      // console.log('categories', sortedCategories);
      shoppingCart.productList.forEach((element) => {
        const index = websiteMenu.findIndex((item) => item._id === element.id);
        if (element.count > 0 && index > -1) {
          websiteMenu[index].count = element.count;
        }
      });

      if (isScrollFetch) {
        await this.setState({
          curPage: curPage + 1,
          productList: [...productList, ...websiteMenu],
        });
      } else {
        await this.setState({
          productList: [...websiteMenu],
          loading: false,
          isCategoryLoading: false,
          totalVariantsNum: keywords
            ? data.variantPaginationWithKeywordsAndInfiniteScroll.count
            : data.variantPaginationWithInfiniteScroll.count,
          // variantPaginationWithInfiniteScroll.count,
        });
      }
    } catch (error) {
      // console.log('***************', error);
      // message.error('Can not fetch banner');
      this.setState({
        isCategoryLoading: false,
      });
    }
  };

  onSearchBar = async (value) => {
    console.log(value);
    // const {
    //   data: { variantPaginationWithKeywordsAndInfiniteScroll },
    // } = await apolloClient.query({
    //   query: BUNBAO_VARIANT_KEYWORDS_PAGINATION,
    //   variables: {
    //     filter: { keywords: value },
    //   },
    // });
    // console.log(variantPaginationWithKeywordsAndInfiniteScroll);
    await this.setState({
      keywords: value,
      isSearchLoading: true,
    });
    await this.handleFetchProductData();
    await this.setState({
      isSearchLoading: false,
    });
  };

  // updateVariantPrice = async () => {
  //   const { updateWarehouseCart, getCurrentWarehouseCart } = this.props;
  //   const { collectionsList } = this.state;
  //   const shoppingCart = await getCurrentWarehouseCart();
  //   console.log('update *** fire');
  //   console.log(shoppingCart);
  //   // update price
  //   const variantIds = [];
  //   shoppingCart.productList.forEach((product) => {
  //     variantIds.push(product._id);
  //   });
  //   shoppingCart.boxList.forEach((box) => {
  //     box.boxContent.forEach((product) => {
  //       variantIds.push(product._id);
  //     });
  //   });
  //   const variantsResponse = await apolloClient.query({
  //     query: VARIANTS_QUERY_IDS,
  //     variables: { input: variantIds },
  //     fetchPolicy: 'network-only',
  //   });

  //   const variants = variantsResponse.data.variantByIds;
  //   console.log(variants);
  //   let isPriceSame = true;
  //   // update shoppingCart
  //   const newShoppingCart = {
  //     ...shoppingCart,
  //     productList: shoppingCart.productList.map((product) => {
  //       const newShoppingListItem = { ...product };
  //       const foundVariant = variants.find(
  //         (variant) => variant._id === newShoppingListItem._id
  //       );
  //       console.log('found variant', foundVariant);
  //       if (foundVariant) {
  //         // check date and change list price
  //         const today = moment().valueOf();
  //         if (
  //           foundVariant.isChangeListPriceByDates &&
  //           today <= foundVariant.changeListPriceDates.endDate &&
  //           today >= foundVariant.changeListPriceDates.startDate
  //         ) {
  //           isPriceSame = isPriceSame
  //             ? newShoppingListItem.listPrice === foundVariant.listPrice
  //             : false;
  //           newShoppingListItem.listPrice = foundVariant.listPrice;
  //         } else if (
  //           foundVariant.isChangeListPriceByDates &&
  //           (today > foundVariant.changeListPriceDates.endDate ||
  //             today < foundVariant.changeListPriceDates.startDate)
  //         ) {
  //           isPriceSame = isPriceSame
  //             ? newShoppingListItem.listPrice === foundVariant.originalPrice
  //             : false;
  //           newShoppingListItem.listPrice = foundVariant.originalPrice;
  //         } else {
  //           isPriceSame = isPriceSame
  //             ? newShoppingListItem.listPrice === foundVariant.listPrice
  //             : false;
  //           newShoppingListItem.listPrice = foundVariant.listPrice;
  //         }
  //       }
  //       return newShoppingListItem;
  //     }),
  //     boxList: shoppingCart.boxList.map((box) => {
  //       const newShoppingListItem = { ...box };
  //       // check collection
  //       const count = box.boxContent.reduce(
  //         (sum, element) => sum + element.count,
  //         0
  //       );
  //       console.log(count);

  //       let discountPercentage = 1;
  //       if (
  //         collectionsList.find(
  //           (element) => element.container.capacity === count
  //         )
  //       ) {
  //         discountPercentage = collectionsList.find(
  //           (element) => element.container.capacity === count
  //         ).container.discountPercentage;
  //       }
  //       newShoppingListItem.boxContent = box.boxContent.map((item) => {
  //         const newItem = { ...item };
  //         const foundVariant = variants.find(
  //           (variant) => variant._id === newItem._id
  //         );
  //         if (foundVariant) {
  //           // check date and change list price
  //           const today = moment().valueOf();
  //           console.log(
  //             newItem.listPrice,
  //             foundVariant.listPrice,
  //             foundVariant.originalPrice
  //           );
  //           if (
  //             foundVariant.isChangeListPriceByDates &&
  //             today <= foundVariant.changeListPriceDates.endDate &&
  //             today >= foundVariant.changeListPriceDates.startDate
  //           ) {
  //             isPriceSame = isPriceSame
  //               ? newItem.listPrice === foundVariant.listPrice
  //               : false;
  //             newItem.listPrice = foundVariant.listPrice;
  //           } else if (
  //             foundVariant.isChangeListPriceByDates &&
  //             (today > foundVariant.changeListPriceDates.endDate ||
  //               today < foundVariant.changeListPriceDates.startDate)
  //           ) {
  //             isPriceSame = isPriceSame
  //               ? newItem.listPrice === foundVariant.originalPrice
  //               : false;
  //             newItem.listPrice = foundVariant.originalPrice;
  //           } else {
  //             isPriceSame = isPriceSame
  //               ? newItem.listPrice === foundVariant.listPrice
  //               : false;
  //             newItem.listPrice = foundVariant.listPrice;
  //           }
  //           newItem.listPrice *= discountPercentage;
  //         }
  //         return newItem;
  //       });
  //       let totalPrice = 0;
  //       newShoppingListItem.boxContent.forEach((item) => {
  //         totalPrice += item.listPrice * item.count;
  //       });
  //       newShoppingListItem.listPrice = totalPrice;
  //       return newShoppingListItem;
  //     }),
  //   };
  //   await updateWarehouseCart(newShoppingCart);
  // };

  fetchCollectionData = async () => {
    const {
      data: { categoryMany },
    } = await apolloClient.query({
      query: CATEGORY_QUERY,
      variables: {
        filter: {
          type: 'collection',
          warehouseId: null,
        },
      },
      fetchPolicy: 'network-only',
    });

    if (categoryMany) {
      const collectionsList =
        (categoryMany.length &&
          categoryMany.map((item) => {
            let pictureKey = '';
            switch (item.name) {
              case 'three-flavor-box':
                pictureKey = 'media/collection-backgrounds/3combo.jpg';
                break;
              case 'one-flavor-box':
                pictureKey =
                  'media/collection-backgrounds/%E6%A3%92%E5%8C%85_%E6%BB%91%E5%AB%A9%E7%8C%AA%E8%82%89x9.JPG';
                break;
              default:
                pictureKey =
                  'media/collection-backgrounds/%E6%A3%92%E5%8C%85_%E7%BB%BC%E5%90%88%E5%8F%A3%E5%91%B3.jpg';
            }
            if (item?.pictures?.length >= 1) {
              pictureKey = item.pictures[0].key;
            }
            return {
              _id: item._id,
              id: item._id,
              title: item.name,
              name: { zh: item.title.zh, en: item.title.en },
              backgroundImage: {
                url: BUCKET_URL + pictureKey,
              },
              container: item.container,
            };
          })) ||
        [];
      // BUCKET_URL;
      await this.setState({ collectionsList });
    } else {
      message.warn('error happened during fetching collections');
    }
  };

  // ========= Fetching Part
  fetchShoppingCart = async () => {
    // const {
    //   staticProductIdToIndex,
    //   staticProductList,
    //   // isDeliveryMenu,
    // } = this.state;
    const { collectionsList } = this.state;
    const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
    // if(allWarehouseCart[currentWarehouseId]){
    // TODO : staticProductIdToIndex
    // await this.filteredShoppingCart(staticProductIdToIndex);
    const shoppingCartTmp = await getCurrentWarehouseCart();
    await updateVariantPrice(
      shoppingCartTmp,
      updateWarehouseCart,
      collectionsList
    );
    const shoppingCart = await getCurrentWarehouseCart();

    if (shoppingCart && (shoppingCart.productList || shoppingCart.boxList)) {
      shoppingCart.productList.forEach((element) => {
        if (element.count > 0) {
          this.setState((prevState) => {
            return {
              totalCount: prevState.totalCount + element.count,
            };
          });
        }
      });
      shoppingCart.boxList.map(
        (element) =>
          element.count > 0 &&
          this.setState((prevState) => {
            return {
              totalCount: prevState.totalCount + element.count,
            };
          })
      );
      this.setState({ shoppingCart });
    }

    this.setState({ shoppingCart });
  };

  handleFetchWords = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isProductConfiguration: true },
      },
    });
    this.setState({ words: configurationOne.words });
  };

  handleDeleteCollection = async () => {
    const { boxContent } = this.state;
    boxContent.forEach((product, index) => {
      const foundCategory = product.needBox;
      if (foundCategory) {
        boxContent[index].count = 0;
      }
    });
    this.setState({ currentCollection: null, boxContent: [], boxModal: false });
  };

  collectionBoxContainer = () => {
    const { currentCollection, boxMaxLimit } = this.state;
    const { language } = this.props;

    if (!currentCollection) {
      return null;
    }

    return (
      <div css={{ height: '120px', width: '120px' }}>
        <div
          css={{
            fontSize: '18px',
            fontWeight: '500',
            textAlign: 'center',
          }}
        >
          {language === 'zh'
            ? currentCollection.name.zh
            : currentCollection.name.en}
        </div>
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            border: '1px solid #ff4949',
            backgroundImage: `url(${background}) `,
            backgroundSize: 'cover',
            borderRadius: '5px',
            padding: isMobile ? '1px' : '4px',
            '& .cell': {
              flex: '0 0 32%',
              marginRight: 'auto',
              marginLeft: 'auto',
              padding: isMobile ? '1px' : '4px',
            },
          }}
        >
          {[...Array(boxMaxLimit).keys()].map((e) => (
            <div key={e} className="cell">
              <img
                alt="bao"
                style={{ width: '100%' }}
                src={e >= currentCollection.productCount ? Bao : BaoComplete}
              />
            </div>
          ))}
        </div>
        <div
          onClick={() => {
            this.handleDeleteCollection();
          }}
          role="presentation"
          css={{
            marginTop: '10px',
            border: '1px solid #fff',
            backgroundColor: '#DF242F',
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: isMobile ? '14px' : '20px',
            color: 'white',
            cursor: 'pointer',
            ':hover': { backgroundColor: '#eb4b50' },
          }}
        >
          <FormattedMessage id="deleteCollection" />
        </div>
        <div
          onClick={() => {
            this.setState({ boxModal: true });
          }}
          role="presentation"
          css={{
            border: '1px solid #fff',
            backgroundColor: '#DF242F',
            borderRadius: '5px',
            textAlign: 'center',
            fontSize: isMobile ? '14px' : '20px',
            color: 'white',
            cursor: 'pointer',
            ':hover': { backgroundColor: '#eb4b50' },
          }}
        >
          <FormattedMessage id="viewBox" />
        </div>
      </div>
    );
  };

  leftAffixButtonForMenu = () => {
    const { currentCollection, boxContainerVisible } = this.state;
    if (!currentCollection) {
      return null;
    }
    return (
      <div css={{ position: 'fixed', bottom: '20vh', zIndex: '100' }}>
        {!isMobile && (
          <div
            css={{
              background: 'white',
              position: 'relative',
            }}
          >
            {this.collectionBoxContainer()}
          </div>
        )}

        {isMobile && boxContainerVisible && (
          <div
            css={{
              background: 'white',
              position: 'relative',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <div
                onClick={() => {
                  this.setState({
                    boxContainerVisible: false,
                  });
                }}
                role="presentation"
                css={{
                  border: '1px solid #fff',
                  backgroundColor: '#DF242F',
                  borderRadius: '5px',
                  textAlign: 'center',
                  fontSize: isMobile ? '14px' : '20px',
                  color: 'white',
                  cursor: 'pointer',
                  ':hover': { backgroundColor: '#eb4b50' },
                }}
              >
                <FormattedMessage id="closeModal" />
              </div>
            </div>
            {this.collectionBoxContainer()}
          </div>
        )}
        {!boxContainerVisible && isMobile && (
          <Button
            css={{
              borderRadius: '50%',
              width: '64px',
              height: '64px',
              padding: '0px',
              boxShadow: '0px 3px 12px rgba(15,15,15,.45)',
            }}
            onClick={async () => {
              await this.setState({ boxContainerVisible: true });
            }}
          >
            <img
              src={flatBox}
              css={{ width: '60px', height: '60px' }}
              alt="Color Bao"
            />
          </Button>
        )}
      </div>
    );
  };

  translation = (word) => {
    const { words } = this.state;
    return translateWords(word, words);
  };

  handleIsClickableClicked = (banner) => {
    const { language } = this.props;
    this.setState({
      bannerIsClickableModal: !!BraftEditor.createEditorState(
        convertRawToHTML(JSON.parse(banner.bannerContent[language] || '{}'))
      ).toText(),
      selectedIsClickableBanner: banner,
    });
  };

  onOpenProductDetailModal = async (id, showTip = false) => {
    await this.handleFetchSelectProduct(id);
    const { history, location, referralCode } = this.props;
    const { pathname, search } = location;
    const searchObject = QueryString.parse(search);
    let query = '';
    Object.keys(searchObject).forEach((key) => {
      if (key !== 'productId') {
        query += `${key}=${searchObject[key]}&`;
      }
    });
    let newPath = `${pathname}?${query}productId=${id}`;
    if (
      checkWeChatUserAgent() &&
      referralCode &&
      query.search('referralCode') === -1
    ) {
      newPath = `${newPath}&referralCode=${referralCode}`;
    }
    history.replace(newPath);
    await this.setState({
      productDetailModalVisible: true,
      selectedProductId: id,
      showTip,
    });
  };

  calculatedTotalCount = () => {
    const { shoppingCart } = this.state;
    let result = 0;
    if (shoppingCart.productList.length > 0) {
      shoppingCart.productList.forEach((product) => {
        result += product.count;
      });
    }
    if (shoppingCart.boxList.length > 0) {
      shoppingCart.boxList.forEach((box) => {
        result += box.count;
      });
    }
    this.setState({ totalCount: result });
  };

  decreaseShoppingCartNumber = async (id) => {
    const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
    const { boxContent, currentCollection } = this.state;

    const variant = boxContent.find((item) => item._id === id);
    if (variant) {
      if (variant.count > 0) {
        currentCollection.productCount -=
          this.getCountByCollection(currentCollection);
        variant.count -= this.getCountByCollection(currentCollection);
      }
      _.remove(boxContent, (e) => {
        return e.count === 0;
      });
      this.setState({
        boxContent: [...boxContent],
      });
    } else {
      const shoppingCart = await getCurrentWarehouseCart();
      if (shoppingCart && shoppingCart.productList) {
        const index = _.findIndex(shoppingCart.productList, ['id', id]);
        if (index > -1) {
          if (shoppingCart.productList[index].count > 0) {
            if (
              (shoppingCart.productList[index]?.minimumAddQuantity || 1) >=
              shoppingCart.productList[index].count
            ) {
              shoppingCart.productList[index].count = 0;
            } else {
              shoppingCart.productList[index].count -= 1;
            }
            //  shoppingCart.productList[index].count -= 1;
          }
        }
        _.remove(shoppingCart.productList, (e) => {
          return e.count === 0;
        });

        this.setState({ shoppingCart });
        this.calculatedTotalCount();
        await updateWarehouseCart(shoppingCart);
      }
    }
  };

  fetchVariantById = async (Id) => {
    try {
      const {
        data: { variantById },
      } = await apolloClient.query({
        query: BUNBAO_VARIANT_BY_ID,
        variables: {
          Id,
        },
        fetchPolicy: 'network-only',
      });
      const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
      if (variantById) {
        variantById.category = variantById.product.category;
        variantById.hashtags = variantById.product.hashtags;
        variantById.imgUrl = getImg(variantById);
        variantById.id = variantById._id;
        // websiteMenu[index].rank = variantById.ranking;
        // if (variantById.count === undefined) {
        variantById.count = 0;
        // }
        variantById.needBox = false;
        variantById.product.imgUrl = getImg(variantById.product);
        if (variantById.markAsSoldOut === true) {
          variantById.soldOut = true;
        }
        if (variantById.product.imgUrl !== DEFAULT_IMAGE) {
          variantById.product.imgUrl = `${BUCKET_CDN_URL}${variantById.product.imgUrl}`;
        }
        if (variantById.imgUrl !== DEFAULT_IMAGE) {
          variantById.imgUrl = `${BUCKET_CDN_URL}${variantById.imgUrl}`;
        } else {
          variantById.imgUrl = variantById.product.imgUrl;
        }
        if (variantById.isChangeListPriceByDates) {
          // check date and change list price
          const today = moment().valueOf();
          if (
            today > variantById.changeListPriceDates.endDate ||
            today < variantById.changeListPriceDates.startDate
          ) {
            variantById.listPrice = variantById.originalPrice;
          }
        }
        const { currentWarehouseId } = this.props;
        if (variantById.allowedInventoryCheck) {
          const {
            data: { inventoryCurrentWarehouseWithVariantIds },
          } = await apolloClient.query({
            query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
            variables: {
              warehouseId: currentWarehouseId,
              variantIds: [variantById._id],
            },
          });
          if (inventoryCurrentWarehouseWithVariantIds) {
            inventoryCurrentWarehouseWithVariantIds.forEach((variant) => {
              if (variant._id === variantById._id) {
                variantById.totalQuantity = variant.totalQuantity;
                if (variant.totalQuantity <= 0) {
                  variantById.soldOut = true;
                }
              }
            });
          }
        }
        const {
          data: { containerMany },
        } = await apolloClient.query({
          query: FETCH_BOX,
          variables: {},
        });
        // check variant if need box
        const boxSample = containerMany[0];
        if (boxSample.allowedVariantIds.length > 0) {
          boxSample.allowedVariantIds.forEach((variantId) => {
            if (variantById._id === variantId) {
              variantById.needBox = true;
            }
          });
        }
        if (boxSample.notAllowedVariantIds.length > 0) {
          boxSample.notAllowedVariantIds.forEach((variantId) => {
            if (variantById._id === variantId) {
              variantById.needBox = false;
            }
          });
        }
      }

      return variantById;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  increaseShoppingCartNumber = async (id, product) => {
    const { getCurrentWarehouseCart, updateWarehouseCart, intl } = this.props;
    const { currentCollection, boxContent, boxMaxLimit } = this.state;
    const shoppingCart = await getCurrentWarehouseCart();
    // product if need a  box
    const { needBox } = product;
    let isSuccess = true;
    if (needBox) {
      const index = boxContent.findIndex((item) => item._id === product._id);
      if (index > -1) {
        product.count = boxContent[index].count;
      }
      if (currentCollection === null) {
        this.setState({
          collectionModal: true,
          productForCollection: id,
          productDetailModalVisible: false,
        });
        return;
      }
      if (boxMaxLimit === currentCollection.productCount) {
        // box is full , pop modal
        Modal.destroyAll();
        this.setState({ boxModal: true });
        return;
      }
      product.inCurrentBox = true;
      currentCollection.productCount +=
        this.getCountByCollection(currentCollection);
      product.count += this.getCountByCollection(currentCollection);
      if (index > -1) {
        boxContent[index] = { ...product };
      } else {
        boxContent.push({ ...product });
      }
      this.setState({
        boxContent: [...boxContent],
      });
      if (boxMaxLimit === currentCollection.productCount) {
        // box is full , pop modal
        Modal.destroyAll();
        this.setState({ boxModal: true });
      }
    } else {
      if (shoppingCart && shoppingCart.productList) {
        const index = _.findIndex(shoppingCart.productList, ['id', id]);
        if (index > -1) {
          if (
            product.maximumPerOrder &&
            product.maximumPerOrder > 0 &&
            product.maximumPerOrder <= shoppingCart.productList[index].count
          ) {
            message.warning(
              intl
                .formatMessage({ id: 'errorExceedMaximum' })
                .replace('[xx.xx]', product.maximumPerOrder)
            );
            return;
          }
          if (
            shoppingCart.productList[index].totalQuantity &&
            shoppingCart.productList[index].totalQuantity <=
              shoppingCart.productList[index].count
          ) {
            return;
          }
          if (
            (shoppingCart.productList[index]?.minimumAddQuantity || 1) >
            shoppingCart.productList[index].count
          ) {
            shoppingCart.productList[index].count =
              shoppingCart.productList[index]?.minimumAddQuantity || 1;
          } else {
            shoppingCart.productList[index].count += 1;
          }
          // shoppingCart.productList[index].count += 1;
        } else {
          if (
            product.maximumPerOrder &&
            product.maximumPerOrder > 0 &&
            product.maximumPerOrder <= product.count
          ) {
            message.warning(
              intl
                .formatMessage({ id: 'errorExceedMaximum' })
                .replace('[xx.xx]', product.maximumPerOrder)
            );
            return;
          }
          if (product.totalQuantity && product.totalQuantity <= product.count) {
            return;
          }
          product.count = product?.minimumAddQuantity || 1;
          const checkIfGroupBuy = this.checkWhetherHasGroupBuy(
            shoppingCart.productList
          );
          if (checkIfGroupBuy && product.isGroupBuy) {
            this.setState({
              groupBuyLimitModal: true,
            });
            isSuccess = false;
          } else {
            shoppingCart.productList.push(product);
          }
        }
      }

      this.setState({ shoppingCart });
      this.calculatedTotalCount();
      await updateWarehouseCart(shoppingCart);
      if (isSuccess) {
        message.success(intl.formatMessage({ id: 'addSign' }), 1);
        this.setState({
          productDetailModalVisible: false,
        });
      } else {
        message.error(intl.formatMessage({ id: 'addFail' }), 1);
      }
    }
  };

  checkWhetherHasGroupBuy = (list) => {
    console.log(1291, list);
    if (list?.length > 0) {
      let result = false;
      list.forEach((item) => {
        if (item.isGroupBuy) {
          result = true;
        }
      });
      return result;
    }
    return false;
  };

  updateShoppingCart = async (input) => {
    const { getCurrentWarehouseCart, updateWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    if (shoppingCart && shoppingCart.productList) {
      const index = _.findIndex(shoppingCart.productList, ['id', input.id]);
      if (index >= 0) {
        shoppingCart.productList[index] = input;
      } else {
        console.log('input < 0 else', input);
        const checkIfGroupBuy = this.checkWhetherHasGroupBuy(
          shoppingCart.productList
        );
        if (checkIfGroupBuy && input.isGroupBuy) {
          input.count -= 1;
          this.setState({
            groupBuyLimitModal: true,
          });
        } else {
          shoppingCart.productList.push(input);
        }
      }
      if (input.count === 0) {
        _.remove(shoppingCart.productList, (e) => {
          return e.count === 0;
        });
      }
      this.setState({ shoppingCart });
      this.calculatedTotalCount();
      await updateWarehouseCart(shoppingCart);
      // console.log("updated shopping cart!!");
    } else {
      const { shoppingCart: newCart } = this.state;
      // const newCart = shoppingCart;
      console.log('should never reached');
      newCart.productList.push(input);
      // console.log("created shopping cart!!");
    }
  };

  handleDecrement = async (id) => {
    // console.log('decrement', input);
    const { productList, currentCollection, boxContent } = this.state;
    const foundIndex = productList.findIndex((x) => x.id === id);
    if (foundIndex >= 0) {
      if (productList[foundIndex].count > 0) {
        // if count > 0
        // saleor version
        // const foundCategory = _.find(comboRequireCategory, (e) => {
        //   return e === productList[foundIndex].category.name;
        // });
        const foundCategory = productList[foundIndex].needBox;
        if (foundCategory && currentCollection) {
          currentCollection.productCount -=
            this.getCountByCollection(currentCollection);
          productList[foundIndex].count -=
            this.getCountByCollection(currentCollection);
        } else {
          if (
            (productList[foundIndex]?.minimumAddQuantity || 1) >=
            productList[foundIndex].count
          ) {
            productList[foundIndex].count = 0;
          } else {
            productList[foundIndex].count -= 1;
          }
          this.updateShoppingCart(productList[foundIndex]);
        }
        const index = boxContent.findIndex(
          (item) => item._id === productList[foundIndex]._id
        );
        if (index > -1) {
          boxContent[index] = productList[foundIndex];
        } else {
          boxContent.push(productList[foundIndex]);
        }
        _.remove(boxContent, (e) => {
          return e.count === 0;
        });

        this.setState({
          boxContent: [...boxContent],
        });
        const { intl } = this.props;
        message.success(intl.formatMessage({ id: 'deleteSign' }, 1));
      }
      this.setState({ productList });
    } else {
      // delete the product and set count to 0
      // message.error('ERROR');
      console.log('not found');
      this.decreaseShoppingCartNumber(id);
    }
  };

  handleChangeOriginArray = (id, dom) => {
    this.$originArray[id] = dom;
  };

  handleIncrement = async (id) => {
    const { productList, currentCollection, boxContent, boxMaxLimit } =
      this.state;
    const { intl } = this.props;
    const foundIndex = productList.findIndex((x) => x.id === id);
    console.log(foundIndex);
    if (foundIndex >= 0) {
      const foundCategory = productList[foundIndex].needBox;
      if (foundCategory) {
        if (currentCollection === null) {
          this.setState({
            collectionModal: true,
            productForCollection: id,
            productDetailModalVisible: false,
          });
          return;
        }
        if (boxMaxLimit === currentCollection.productCount) {
          // box is full , pop modal
          this.setState({ boxModal: true });
          return;
        }
        productList[foundIndex].inCurrentBox = true;
        currentCollection.productCount +=
          this.getCountByCollection(currentCollection);
        productList[foundIndex].count +=
          this.getCountByCollection(currentCollection);

        const index = boxContent.findIndex(
          (item) => item._id === productList[foundIndex]._id
        );
        if (index > -1) {
          boxContent[index] = productList[foundIndex];
        } else {
          boxContent.push(productList[foundIndex]);
        }

        this.setState({
          boxContent: [...boxContent],
        });
        if (boxMaxLimit === currentCollection.productCount) {
          // box is full , pop modal
          this.setState({ boxModal: true, productList });
          return;
        }
      } else {
        if (
          productList[foundIndex].maximumPerOrder &&
          productList[foundIndex].maximumPerOrder > 0 &&
          productList[foundIndex].maximumPerOrder <=
            productList[foundIndex].count
        ) {
          message.warning(
            intl
              .formatMessage({ id: 'errorExceedMaximum' })
              .replace('[xx.xx]', productList[foundIndex].maximumPerOrder)
          );
          return;
        }
        if (
          productList[foundIndex].totalQuantity &&
          productList[foundIndex].totalQuantity <= productList[foundIndex].count
        ) {
          return;
        }
        if (
          (productList[foundIndex]?.minimumAddQuantity || 1) >
          productList[foundIndex].count
        ) {
          productList[foundIndex].count =
            productList[foundIndex]?.minimumAddQuantity || 1;
        } else {
          productList[foundIndex].count += 1;
        }
        this.updateShoppingCart(productList[foundIndex]);
      }
      if (isMobile === false) {
        // Parabola Animation
        // remove parabola animation
        // this.$origin = this.$originArray[id];
        // await this.onAnimate();
      }
      // cons
      this.setState({ productList, productDetailModalVisible: false });
      // const { intl } = this.props;
      const { groupBuyLimitModal } = this.state;
      if (!groupBuyLimitModal) {
        message.success(intl.formatMessage({ id: 'addSign' }), 1);
      } else {
        message.error(intl.formatMessage({ id: 'addFail' }), 1);
      }
    } else {
      console.log('not Found');
      const product = await this.fetchVariantById(id);
      this.increaseShoppingCartNumber(id, product);
      // message.error('ERROR');
    }
  };

  // handleScrollToCategory = (category, selectedTag) => {
  //   // this.setState({ selectedCategory: category.name });
  //   const element = document.getElementById(category.name);
  //   // const deltaTop =
  //   //   (this.headerBannerHeightRef.current &&
  //   //     this.headerBannerHeightRef.current.clientHeight) ||
  //   //   0;
  //   // console.log(this.headerBannerHeightRef.current);
  //   // console.dir(this.headerBannerHeightRef.current);
  //   // console.dir(selectedTag);
  //   // console.log(selectedTag.offsetLeft);

  //   if (!isMobileSafari) {
  //     this.sliderRef.current.scrollTo({
  //       left: isMobile
  //         ? selectedTag.offsetLeft - 5
  //         : selectedTag.offsetLeft - 25,
  //       behavior: 'smooth',
  //     });
  //     window.scrollTo({
  //       top: element.offsetTop - HEADER_HEIGHT,
  //       behavior: 'smooth',
  //     });
  //   } else {
  //     this.sliderRef.current.scrollTo(selectedTag.offsetLeft - 5, 0);
  //     window.scrollTo(0, element.offsetTop - HEADER_HEIGHT);
  //   }
  // };

  scrollToCategory = () => {
    const { scrollTop } = this.state;
    if (
      this.sliderContainerRef.current &&
      scrollTop > this.sliderContainerRef.current.offsetTop
    ) {
      const sliderHeight =
        (this.sliderRef.current && this.sliderRef.current.clientHeight / 2) ||
        30;
      // if (!isMobileSafari) {
      //   window.scrollTo({
      //     top: this.sliderContainerRef.current.offsetTop + sliderHeight,
      //     behavior: 'smooth',
      //   });
      // } else {
      //   window.scrollTo(
      //     0,
      //     this.sliderContainerRef.current.offsetTop + sliderHeight
      //   );
      // }
      window.scrollTo(
        0,
        this.sliderContainerRef.current.offsetTop + sliderHeight
      );
    }
  };

  categoryIconRender = (array, isSelected = false) => {
    if ((!array || (array && array.length === 0)) && !isSelected) {
      return bunbaoLogo;
    }
    if ((!array || (array && array.length === 0)) && isSelected) {
      return bunbaoLogo;
    }
    if (isSelected && array[1]) {
      return BUCKET_URL + array[1].key;
    }
    if (!isSelected && array[0]) {
      return BUCKET_URL + array[0].key;
    }
    if (isSelected && !array[1]) {
      return bunbaoLogo;
    }
    return bunbaoLogo;
  };

  handleMobileShoppingCart = async () => {
    const { overWriteCallBack } = this.props;
    await overWriteCallBack(this.handleCloseMobileShoppingCart);
    this.setState((prevState) => ({
      mobileDrawerModal: !prevState.mobileDrawerModal,
    }));
  };

  handleShoppingCart = async () => {
    const { drawerModal } = this.state;
    this.setState({ drawerModal: !drawerModal });
  };

  updateShoppingCartCollection = async (input) => {
    const {
      getCurrentWarehouseCart,
      // currentWarehouseId,
      updateWarehouseCart,
    } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    if (shoppingCart && shoppingCart.boxList) {
      const index = _.findIndex(shoppingCart.boxList, ['id', input.id]);
      if (index >= 0) {
        shoppingCart.boxList[index] = input;
        if (input.count === 0) {
          _.remove(shoppingCart.boxList, (e) => {
            return e.count === 0;
          });
        }
        this.setState({ shoppingCart });
        this.calculatedTotalCount();
        await updateWarehouseCart(shoppingCart);
      } else {
        message.error('ERROR');
      }
    }
  };

  handleDelete = async (id) => {
    const { productList } = this.state;
    const { intl } = this.props;
    const foundIndex = productList.findIndex((x) => x.id === id);
    if (foundIndex >= 0) {
      if (productList[foundIndex].count > 0) {
        // if count > 0
        productList[foundIndex].count = 0;
        this.updateShoppingCart(productList[foundIndex]);

        message.success(intl.formatMessage({ id: 'deleteSign' }, 1));
      }
      this.setState({ productList });
    } else {
      // message.error('ERROR');
      this.decreaseShoppingCartNumber(id);
      message.success(intl.formatMessage({ id: 'deleteSign' }, 1));
    }
  };

  handleCloseDrawerModal = async () => {
    console.log('closeDrawerModal');
    await this.setState({ drawerModal: false });
  };

  onCancelBoxModal = async () => {
    this.setState({ boxModal: false });
  };

  onOkBoxModal = async () => {
    // const boxContent = JSON.parse(JSON.stringify(this.getCurrentBox()));
    const { currentCollection, productList, totalCount, boxContent } =
      this.state;
    const {
      updateWarehouseCart,
      getCurrentWarehouseCart,
      // currentWarehouseId,
    } = this.props;
    // const { intl } = this.props;
    const newId = nanoid(4);
    let listPrice = 0;
    // should check collection discount percentage
    let percentage = 1;
    if (currentCollection?.container?.discountPercentage) {
      percentage = currentCollection.container.discountPercentage;
    }
    boxContent.forEach((element) => {
      listPrice += element.listPrice * percentage * element.count;
    });
    const box = {
      id: newId,
      _id: newId,
      boxContent: [...boxContent],
      currentCollection,
      count: 1,
      collapsed: false,
      imgUrl: currentCollection.backgroundImage.url,
      name: currentCollection.name,
      title: currentCollection.title,
      listPrice: listPrice.toFixed(2),
    };
    const shoppingCart = await getCurrentWarehouseCart();
    if (shoppingCart && shoppingCart.boxList) {
      shoppingCart.boxList.push(box);
      this.setState({ shoppingCart, totalCount: totalCount + 1 });
      await updateWarehouseCart(shoppingCart);
    } else {
      console.log('should never reached');
      const { shoppingCart: newCart } = this.state;
      newCart.boxList.push(box);
      this.setState({ shoppingCart: newCart });
      await localStorage.setItem('shoppingCart', JSON.stringify(newCart));
    }
    // message.success(intl.formatMessage({ id: 'addSign' }));
    // clean up
    boxContent.forEach((e) => {
      const index = productList.findIndex((element) => element._id === e._id);
      if (index > -1) {
        productList[index].count = 0;
        productList[index].quantity = 0;
        productList[index].inCurrentBox = 0;
      }
    });
    await this.setState({
      boxModal: false,
      productList: [...productList],
      currentCollection: null,
      boxContent: [],
    });
  };

  onCloseProductDetailModal = () => {
    const { history, location } = this.props;
    const { pathname, search } = location;
    const searchObject = QueryString.parse(search);
    let query = '';
    Object.keys(searchObject).forEach((key) => {
      if (key !== 'productId') {
        query += `${key}=${searchObject[key]}&`;
      }
    });
    query = query.slice(0, -1);
    const newPath = `${pathname}?${query}`;
    history.replace(newPath);
    this.setState({ productDetailModalVisible: false });
  };

  onCancelCollectionModal = async () => {
    this.setState({ collectionModal: false });
  };

  handleSelectCollection = async (input, index) => {
    const { productForCollection, collectionsList } = this.state;
    if (productForCollection) {
      collectionsList[index].productCount = 0;
      // input.productCount = 0;
      await this.setState({
        currentCollection: input,
        collectionModal: false,
        boxMaxLimit: input.container.capacity,
      });
      await this.handleIncrement(productForCollection);
    } else {
      message.error('ERROR');
    }
  };

  freeShippingBanner = () => {
    const {
      freeShippingObj,
      customerOrderConfiguration,
      isFreeShoppingAlertClosed,
    } = this.state;
    const { language, intl } = this.props;
    // console.log(freeShippingObj);
    if (freeShippingObj) {
      const bannerRender = (value, flag) => {
        return (
          <div
            css={{
              padding: '10px',
              paddingLeft: '1rem',
              display: 'flex',
              alignItems: 'center',
              minHeight: isFreeShoppingAlertClosed ? 'auto' : '82.5px',
            }}
          >
            <Alert
              afterClose={() => {
                this.setState({ isFreeShoppingAlertClosed: true });
              }}
              css={{
                width: '100%',
              }}
              className="shipping-banner-alert"
              message={
                <div css={{ width: '100%' }}>
                  <TextLoop
                    css={{ width: '100%' }}
                    interval={10000}
                    mask
                    noWrap={false}
                  >
                    <div css={{ width: '100%', wordWrap: 'break-word' }}>
                      <div css={{ width: '100%' }}>
                        <img
                          style={{
                            height: '20px',
                            marginRight: '10px',
                          }}
                          src={freeShipping}
                          alt="free shipping"
                        />
                        {intl.formatMessage(
                          { id: 'freeShopping' },
                          { value: ` $${value} ` }
                        )}
                      </div>
                      <div>
                        {flag === 'delivery-menu-with-warehouse-id' &&
                          freeShippingObj.distance.map((item) => (
                            <div css={{ width: '100% ' }} key={nanoid(5)}>
                              {/* {`Delivery fee is $${item.flatFee} for ${item.minMile}~${item.maxMile} miles`} */}
                              <FormattedMessage
                                id="menu.deliveryFeeByRange"
                                values={{
                                  deliveryFee: `$${item.flatFee}`,
                                  range: `${item.minMile}~${item.maxMile}`,
                                }}
                              />
                            </div>
                          ))}
                        {flag === 'delivery-menu' && (
                          <div css={{ width: '100% ' }}>
                            <FormattedMessage
                              id="menu.deliveryFeeByAmount"
                              values={{
                                deliveryFee: `$${freeShippingObj.marketShippingFee}`,
                                amount: `$${value}`,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {flag === 'delivery-menu' &&
                      _.orderBy(
                        customerOrderConfiguration.marketNotices,
                        ['ranking'],
                        ['asc']
                      ).map((notice) => {
                        return (
                          <div key={nanoid(5)} css={{ width: '100%' }}>
                            {notice.notice[language].split('\n').map((line) => {
                              return <div key={nanoid(5)}>{line}</div>;
                            })}
                          </div>
                        );
                      })}
                  </TextLoop>
                </div>
              }
              type="warning"
              closable
            />
          </div>
        );
      };
      const {
        location: { search, pathname: path },
      } = this.props;
      if (path.includes('supermarket')) {
        return bannerRender(
          freeShippingObj.marketMinimumAmountForShippingFree,
          'delivery-menu'
        );
      }
      if (search.includes('?warehouseId=')) {
        return bannerRender(
          freeShippingObj.minimumAmount,
          'delivery-menu-with-warehouse-id'
        );
      }
    }
    return null;
  };

  onAnimate = () => {
    return new Promise((resolve) => {
      const animationDone = () => {
        this.setState({
          isBallVisible: false,
        });
        resolve();
      };
      const config = {
        ballWrapper: this.$wrapper,
        origin: this.$origin,
        target: this.$target,
        time: 600,
        a: 0.0009,
        callback: this.updateLocation,
        finish: animationDone.bind(this),
        offset: 0,
      };
      Parabola(config);
    });
  };

  updateLocation = (x, y) => {
    this.setState({
      ballX: x,
      ballY: y,
      isBallVisible: true,
    });
  };

  handleClickCategoryIcon = async (category, selectedCategory) => {
    // this.filterProcessor(category);
    // this.handleScrollToCategory(category, event.currentTarget);
    const { history } = this.props;

    // handle change url to add category id
    if (category._id !== 'All') {
      const { location } = this.props;
      const { pathname, search } = location;
      const searchObject = QueryString.parse(search);
      let query = '';
      Object.keys(searchObject).forEach((key) => {
        if (key !== 'categoryId') {
          query += `${key}=${searchObject[key]}&`;
        }
      });
      const newPath = `${pathname}?${query}categoryId=${category._id}`;
      history.replace(newPath);
    } else {
      // just set up to default url
      const { location } = this.props;
      const { pathname, search } = location;
      const searchObject = QueryString.parse(search);
      let query = '';
      Object.keys(searchObject).forEach((key) => {
        if (key !== 'categoryId') {
          query += `${key}=${searchObject[key]}&`;
        }
      });
      const newPath = `${pathname}?${query}`;
      history.replace(newPath);
    }
    if (selectedCategory && selectedCategory._id === category._id) {
      this.scrollToCategory();
      return;
    }
    await this.setState({
      selectedCategory: category,
      curPage: 2,
      isCategoryLoading: true,
    });
    this.scrollToCategory();
    await this.handleFetchProductData();
  };

  render() {
    const {
      drawerModal,
      mobileDrawerModal,
      loading,
      productList,
      // shoppingCart,
      collectionsList,
      // categoryToData,
      categories,
      collectionModal,
      currentCollection,
      boxModal,
      slides,
      selectedCategory,
      scrollTop,
      productDetailModalVisible,
      selectedProductId,
      ballX,
      ballY,
      isBallVisible,
      currentWarehouseObj,
      totalCount,
      videoModalVisible,
      selectedVideo,
      // storeAnnouncementModalVisible,
      freeShippingObj,
      totalVariantsNum,
      curPage,
      invalidVariantModal,
      isCategoryLoading,
      boxContent,
      wechatShareData,
      showTip,
      bannerIsClickableModal,
      selectedIsClickableBanner,
      boxMaxLimit,
      groupBuyLimitModal,
      keywords,
      isSearchLoading,
      categoryExpanded,
      specialMenu,
    } = this.state;

    const { language, history, intl, allWarehouseCart, currentWarehouseId } =
      this.props;

    const emptyCart = {
      productList: [],
      boxList: [],
    };
    const shoppingCart = allWarehouseCart[currentWarehouseId] || emptyCart;
    if (!shoppingCart.productList) {
      shoppingCart.productList = [];
    }
    if (!shoppingCart.boxList) {
      shoppingCart.boxList = [];
    }
    let leftCategory;
    let rightMenu;
    let webDrawerComponent;
    let sideCartContent;
    let boxDetailModal;

    const animateStyle = {
      transform: `translate(${ballX}px, ${ballY}px)`,
      opacity: isBallVisible ? 1 : 0,
    };

    // eslint-disable-next-line
    const searchBar = () => {
      return (
        <div
          style={{
            width: '80%',
            margin: 'auto',
            marginBottom: '10px',
            marginTop: '5px',
          }}
        >
          <Search
            allowClear
            enterButton={<SearchOutlined />}
            size="large"
            loading={isSearchLoading}
            onSearch={async (value) => {
              await this.onSearchBar(value);
            }}
          />
        </div>
      );
    };
    if (loading === false) {
      leftCategory = (
        <div
          style={{
            minHeight: this.sliderRef.current
              ? `${this.sliderRef.current.clientHeight + 10}px`
              : 'auto',
          }}
        >
          <div
            style={
              scrollTop >
              (this.sliderContainerRef.current &&
                this.sliderContainerRef.current.offsetTop)
                ? {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    zIndex: '100',
                    backgroundColor: 'white',
                    // height: isMobile ? '80px' : '93px',
                    width: '100%',
                    padding: isMobile ? '5px 0px' : '5px 10px',
                  }
                : null
            }
            css={{
              padding: isMobile ? '5px 0px' : '5px 10px',
              position: 'relative',
            }}
          >
            {!keywords && (
              <React.Fragment>
                {!isMobile && categories.length > 0 && (
                  <LeftOutlined
                    style={{
                      position: 'absolute',
                      left: '0px',
                      color: 'black',
                      top: 'calc(50% - 10px)',
                      fontSize: '20px',
                      // background: 'white',
                    }}
                    onClick={() => {
                      if (
                        this.sliderRef.current &&
                        this.sliderRef.current.scrollLeft > -1
                      ) {
                        this.sliderRef.current.scrollLeft -=
                          window.innerWidth * 50;
                      }
                    }}
                  />
                )}

                {!isMobile && categories.length > 0 && (
                  <RightOutlined
                    style={{
                      position: 'absolute',
                      right: '0px',
                      color: 'black',
                      top: 'calc(50% - 10px)',
                      fontSize: '20px',
                      // background: 'white',
                    }}
                    onClick={() => {
                      if (
                        this.sliderRef.current &&
                        this.sliderRef.current.scrollLeft > -1
                      ) {
                        this.sliderRef.current.scrollLeft +=
                          window.innerWidth * 50;
                      }
                    }}
                  />
                )}
                <div
                  ref={this.sliderRef}
                  css={{
                    display: 'flex',
                    WebkitOverflowScrolling: 'touch',
                    scrollBehavior: 'smooth',
                    overflowX: 'scroll',
                    '::-webkit-scrollbar': {
                      width: '0px !important',
                      display: 'none',
                    },
                    padding: isMobile ? '0px' : '0px 20px',
                    minHeight: isMobile ? 'auto' : '64px',
                    alignItems: 'center',
                  }}
                >
                  {categories.map((category) => {
                    if (category.hidden || category.deleted) {
                      return null;
                    }
                    // expand icon
                    if (category.isIcon) {
                      return null;
                    }
                    if (category.isIcon) {
                      return (
                        <div
                          onClick={() => {
                            this.handleExpandedClick();
                          }}
                          role="presentation"
                        >
                          {categoryExpanded ? (
                            <ShrinkOutlined style={{ fontSize: '40px' }} />
                          ) : (
                            <ArrowsAltOutlined style={{ fontSize: '40px' }} />
                          )}
                        </div>
                      );
                    }
                    return (
                      <div
                        key={category._id}
                        // onClick={(event) => {
                        //   // this.filterProcessor(category);
                        //   this.handleScrollToCategory(category, event.currentTarget);
                        // }}
                        onClick={async () => {
                          this.handleClickCategoryIcon(
                            category,
                            selectedCategory
                          );
                        }}
                        css={{
                          userSelect: 'none',
                          border:
                            selectedCategory?._id === category._id
                              ? '2px solid #DF242F'
                              : '2px solid white',
                          borderRadius: '3px',
                          padding: isMobile ? '4px 10px' : '5px 10px',
                          fontWeight: '600',
                          cursor: 'pointer',
                          ':hover': {
                            // backgroundColor: isMobile ? '#DF242F' : '#ff8364',
                            // color: 'white',
                            border: isMobile
                              ? '2px solid #DF242F'
                              : '2px solid #ff8364',
                          },
                          margin: '1px',
                          boxSizing: 'border-box',
                          flexShrink: 0,
                          height: 'fit-content',
                          position: 'relative',
                        }}
                        role="presentation"
                      >
                        {selectedCategory?.name === category.name &&
                          isCategoryLoading && (
                            <div
                              css={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0,0,0,.15)',
                                left: '0px',
                                top: '0px',
                              }}
                            >
                              <Spin className="market-menu-spin-dot" />
                            </div>
                          )}
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            <img
                              style={{ height: '64px', marginBottom: '2px' }}
                              src={
                                selectedCategory?.name === category.name
                                  ? this.categoryIconRender(
                                      category.pictures,
                                      true
                                    )
                                  : this.categoryIconRender(
                                      category.pictures,
                                      false
                                    )
                              }
                              alt=""
                            />
                          </div>

                          <div>
                            {language === 'zh'
                              ? category.title.zh
                              : category.title.en}{' '}
                            {category.hashtags &&
                              category.hashtags.length > 0 &&
                              category.hashtags.map((hashtag) => {
                                return (
                                  <Tag
                                    key={hashtag}
                                    color={
                                      selectedCategory?.name === category.name
                                        ? 'white'
                                        : '#DF242F'
                                    }
                                    css={{
                                      fontSize: '12px',
                                      color:
                                        selectedCategory?.name === category.name
                                          ? 'red'
                                          : 'white',
                                    }}
                                  >
                                    {hashtag}
                                  </Tag>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
            <div
              ref={(dom) => {
                this.$target = dom;
              }}
              style={{
                background: '#df242f',
                color: 'white',
                fontWeight: '400',
                padding: '.5rem 1rem',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 2px 0 rgba(0,0,0,.045)',
              }}
              css={
                isMobile
                  ? {
                      width: '100%',
                      borderRadius: '0',
                      position: 'fixed',
                      zIndex: '5',
                      bottom: 0,
                      left: 0,
                      minHeight: '40px',
                    }
                  : {
                      cursor: 'pointer',
                      padding: '5px 15px',
                      fontSize: '14px',
                      margin: '5px',
                      right: '0',
                      position: 'absolute',
                      borderRadius: '2px',
                      userSelect: 'none',
                    }
              }
              role="presentation"
              onClick={() => {
                if (isMobile) {
                  this.handleMobileShoppingCart();
                } else {
                  this.handleShoppingCart();
                }
              }}
            >
              <div
                style={{ paddingBottom: 'env(safe-area-inset-bottom,20px)' }}
              >
                <Badge
                  count={totalCount}
                  offset={[5, -10]}
                  css={{ color: 'white' }}
                >
                  <ShoppingCartOutlined css={{ fontSize: 20 }} />{' '}
                  <FormattedMessage
                    id="viewCart"
                    css={{
                      fontSize: 20,
                    }}
                  />
                </Badge>
              </div>
            </div>
          </div>
        </div>
      );
      rightMenu = (
        <div>
          <InfiniteScroll
            dataLength={productList.length}
            next={async () => {
              await this.handleFetchProductData(curPage, PAGE_SIZE, true);
            }}
            hasMore={(curPage - 1) * PAGE_SIZE < totalVariantsNum}
            loader={
              <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <Spin />
              </div>
            }
          >
            <Row
              type="flex"
              gutter={15}
              style={{
                margin: '30px 0px',
                padding: isMobile ? '0px 10px' : '0px 15px',
                backgroundColor: 'white',
              }}
            >
              {productList.length > 0 ? (
                productList.map((product) => (
                  <SingleProductCard
                    key={product._id}
                    e={product}
                    language={language}
                    translation={this.translation}
                    onOpenProductDetailModal={this.onOpenProductDetailModal}
                    handleDecrement={this.handleDecrement}
                    handleChangeOriginArray={this.handleChangeOriginArray}
                    handleIncrement={this.handleIncrement}
                  />
                ))
              ) : (
                <div style={{ fontSize: '30px' }}>
                  <FormattedMessage id="empty" />
                </div>
              )}
            </Row>
          </InfiniteScroll>
          {/* {productList.length > 0 && (
            <div style={{ margin: !isMobile ? '20px' : '20px 0px' }}>
              <Pagination
                current={curPage}
                defaultCurrent={1}
                total={totalVariantsNum}
                onChange={async (pageNumber) => {
                  await this.setState({ curPage: pageNumber });
                  await this.handleFetchProductData(pageNumber);
                }}
              />
            </div>
          )} */}
        </div>
      );
      if (shoppingCart) {
        sideCartContent = (
          <SideCart
            css={{
              backgroundColor: '#fafafa',
            }}
            freeShippingObj={freeShippingObj}
            handleIncrement={this.handleIncrement}
            updateShoppingCartCollection={this.updateShoppingCartCollection}
            handleDecrement={this.handleDecrement}
            handleDelete={this.handleDelete}
            handleInsufficient={null}
            taxRate={0}
            intl={intl}
            handleMobileShoppingCart={this.handleMobileShoppingCart}
            handleCloseDrawerModal={this.handleCloseDrawerModal}
          />
        );
        webDrawerComponent = (
          <WebDrawer
            closable
            width="500px"
            placement="left"
            // closable={false}
            onClose={this.handleShoppingCart}
            visible={drawerModal}
            // getContainer={false}
            css={{ position: 'fixed' }}
            drawerStyle={{
              backgroundColor: '#fafafa',
            }}
            destroyOnClose
          >
            {sideCartContent}
          </WebDrawer>
        );
      }
      boxDetailModal = (
        <Modal
          visible={boxModal}
          zIndex={2000}
          onCancel={this.onCancelBoxModal}
          onOk={this.onOkBoxModal}
          footer={null}
        >
          <BoxDetail
            variants={boxContent}
            collection={currentCollection}
            boxMaxLimit={boxMaxLimit}
            handleIncrement={this.handleIncrement}
            handleDecrement={this.handleDecrement}
            onOkBoxModal={this.onOkBoxModal}
            onCancelBoxModal={this.onCancelBoxModal}
            handleDeleteCollection={this.handleDeleteCollection}
          />
        </Modal>
      );
    }
    const cartCssObject = {
      padding: '5px',
      zIndex: '20',
      // height: `calc( ${height}px - 45px )`,
      backgroundColor: '#fafafa',
      width: '100%',
      overflowX: 'hidden',
      paddingBottom: 'calc(env(safe-area-inset-bottom,0) + 40px)',
      position: 'relative',
    };
    return (
      <div
        css={{
          minHeight: isMobile ? height : '100vh',
          backgroundColor: '#FFF',
          position: 'relative',
        }}
        ref={(dom) => {
          this.$wrapper = dom;
        }}
      >
        <WechatAPI shareData={wechatShareData}>
          <div />
        </WechatAPI>
        <Modal
          visible={invalidVariantModal}
          title={null}
          footer={null}
          maskClosable
          destroyOnClose
          onCancel={() => {
            this.setState({
              invalidVariantModal: false,
            });
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage id="invalidVariant" />
          </div>
        </Modal>
        <Modal
          visible={bannerIsClickableModal}
          footer={null}
          title={null}
          destroyOnClose
          onCancel={() => {
            this.setState({ bannerIsClickableModal: false });
          }}
          style={isMobile ? { top: 0 } : {}}
        >
          {selectedIsClickableBanner && (
            <div>
              {language === 'zh' ? (
                <div>
                  {convert(
                    convertRawToHTML(
                      JSON.parse(selectedIsClickableBanner.bannerContent.zh)
                    )
                  )}
                </div>
              ) : (
                <div>
                  {convert(
                    convertRawToHTML(
                      JSON.parse(selectedIsClickableBanner.bannerContent.en)
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </Modal>
        <Modal
          visible={productDetailModalVisible}
          footer={null}
          title={null}
          destroyOnClose
          onCancel={this.onCloseProductDetailModal}
          style={isMobile ? { top: 0 } : {}}
        >
          <ProductDetailModal
            productList={productList}
            selectedProductId={selectedProductId}
            handleIncrement={this.handleIncrement}
            handleDecrement={this.handleDecrement}
            showTip={showTip}
          />
        </Modal>
        {loading ? <Loading /> : null}
        {shoppingCart ? webDrawerComponent : null}
        <Modal
          visible={collectionModal}
          onCancel={this.onCancelCollectionModal}
          title={null}
          footer={null}
          width={isMobile ? '100%' : '900px'}
        >
          <ComboSelection
            intl={intl}
            collectionsList={collectionsList}
            handleSelectCollection={this.handleSelectCollection}
          />
        </Modal>
        <Modal
          title={null}
          footer={null}
          maskClosable
          closable
          onCancel={() => {
            this.setState({ videoModalVisible: false });
          }}
          visible={videoModalVisible}
          centered
          width="100%"
          bodyStyle={{
            minHeight: isMobile ? 300 : 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          destroyOnClose
          // wrapClassName="video-modal-content"
        >
          {videoModalVisible && (
            <div style={{ width: '100%' }}>
              <VideoBannerAlt
                bannerFullWidth={
                  isMobile ? window.innerWidth - 16 : window.innerWidth * 0.95
                }
                videoIds={selectedVideo}
              />
            </div>
          )}
        </Modal>
        <Modal
          visible={groupBuyLimitModal}
          title={null}
          footer={null}
          maskClosable
          destroyOnClose
          onCancel={() => {
            this.setState({
              groupBuyLimitModal: false,
            });
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage id="groupBuyLimitOneOrder" />
          </div>
        </Modal>

        {boxDetailModal}
        {!loading &&
          (isMobile ? (
            <div css={cartCssObject}>
              {mobileDrawerModal ? (
                <React.Fragment> {sideCartContent} </React.Fragment>
              ) : (
                <React.Fragment>
                  <div ref={this.headerBannerHeightRef}>
                    <div id="menuBanner">
                      {loading === false &&
                      !specialMenu &&
                      slides.length > 0 ? (
                        <MenuBanner
                          slides={slides}
                          language={language}
                          handleIsClickableClicked={
                            this.handleIsClickableClicked
                          }
                          handleBannerClick={this.onOpenProductDetailModal}
                          handleClickCategoryIcon={this.handleClickCategoryIcon}
                        />
                      ) : null}
                    </div>
                    <div>
                      <WarehouseHeader
                        warehouse={currentWarehouseObj}
                        history={history}
                        warehouseScheduledDeliveryMode={false}
                      />
                    </div>
                    {freeShippingObj && this.freeShippingBanner()}
                    {searchBar()}
                  </div>
                  {this.leftAffixButtonForMenu()}

                  <div ref={this.sliderContainerRef}>{leftCategory}</div>
                  <div>{rightMenu}</div>
                </React.Fragment>
              )}
            </div>
          ) : (
            <div
              css={{
                maxWidth: '1300px',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <BackTop />
              <div ref={this.headerBannerHeightRef}>
                <div>
                  {loading === false && !specialMenu && slides.length > 0 ? (
                    <div
                      css={{
                        width: '93%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <MenuBanner
                        slides={slides}
                        language={language}
                        handleBannerClick={this.onOpenProductDetailModal}
                        handleIsClickableClicked={this.handleIsClickableClicked}
                        handleClickCategoryIcon={this.handleClickCategoryIcon}
                      />
                    </div>
                  ) : null}
                </div>
                <div>
                  <WarehouseHeader
                    warehouse={currentWarehouseObj}
                    history={history}
                    warehouseScheduledDeliveryMode={false}
                  />
                </div>
                {freeShippingObj && this.freeShippingBanner()}
                {searchBar()}
              </div>
              {this.leftAffixButtonForMenu()}
              <div ref={this.sliderContainerRef}>{leftCategory}</div>
              <div>{rightMenu}</div>
            </div>
          ))}
        <div
          css={{
            width: '20px',
            height: '20px',
            background: '#DF242F',
            position: 'absolute',
            left: '0',
            top: '0',
            borderRadius: '50%',
            opacity: '0',
            zIndex: '5',
          }}
          style={animateStyle}
        />
      </div>
    );
  }
}

MarketMenu.defaultProps = {
  match: null,
  referralCode: null,
  // currentAddressType: null,
};

MarketMenu.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  shoppingCart: PropTypes.shape({
    productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    boxList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  // updateShoppingCart: PropTypes.func.isRequired,
  setWarehouse: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  overWriteCallBack: PropTypes.func.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  // currentAddressType: PropTypes.string,
  currentWarehouseId: PropTypes.string.isRequired,
  getCurrentWarehouseCart: PropTypes.func.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match,
  allWarehouseCart: PropTypes.shape({}).isRequired,
  currentShoppingMode: PropTypes.string.isRequired,
  updateOneWarehouseAttribute: PropTypes.func.isRequired,
  referralCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  shoppingCart: state.cart.shoppingCart,
  currentWarehouseId: state.warehouse.warehouseId,
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  // currentAddressType: state.user.currentAddressType,
  allWarehouseCart: state.cart.allWarehouseCart,
  referralCode: state.user.referralCode,
});

const mapDispatchToProps = (dispatch) => ({
  setWarehouse: (warehouseId) => dispatch(actions.setWarehouse(warehouseId)),
  setUserShoppingMode: (input) => dispatch(actions.setUserShoppingMode(input)),
  clearItems: () => dispatch(actions.clearItems()),
  // updateShoppingCart: (input) => dispatch(actions.updateShoppingCart(input)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  overWriteCallBack: (callback) =>
    dispatch(actions.overWriteCallBack(callback)),
  updateOneWarehouseAttribute: (input, attributeName) =>
    dispatch(actions.updateOneWarehouseAttribute(input, attributeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MarketMenu));
