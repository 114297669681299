/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { List, Typography } from 'antd';
// import React from 'react';
import hiring from '../../../assets/images/hiring.jpeg';

const { Title, Paragraph } = Typography;

export default function Career() {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  const data = [
    // {
    //   title: 'Food Scientist',
    //   url: 'https://drive.google.com/file/d/1EKVWZrN033FnJg4uOienr7zVYUD0NG5v/view?usp=sharing',
    // },
    {
      title: 'Farmers Market Sales',
      url: 'https://drive.google.com/file/d/16oF4s9_vW9VE66X7zp4BmV4SgOTqPWT3/view?usp=sharing',
    },
    {
      title: 'Marketing Specialist',
      url: 'https://drive.google.com/file/d/1EamG9_ozc5RTzozpQezLs9LbPs0Xscs0/view?usp=sharing',
    },
    {
      title: 'Regional Farmers Market Coordinator',
      url: 'https://drive.google.com/file/d/1FBqFda2TDZNWZhAmkdgbIM4Hp7bEBziq/view?usp=sharing',
    },
  ];

  return (
    <div css={{ margin: 'auto', maxWidth: '800px', minHeight: '100vh' }}>
      <Typography>
        <img src={hiring} alt="hiring" css={{ maxWidth: '800px' }} />
        <Title>Who Are We</Title>
        <Paragraph>
          BUNBAO.COM INC is dedicated to &ldquo;bringing Chinese steamed buns
          into a mainstream fast food market in the United States&rdquo; in a
          new retail form. After four years of operation, Bunbao has
          successfully operated 100 retails and cooperated with Costco in the
          last year, with a sales increase rate of 80% to 2020.
        </Paragraph>
        <Paragraph>
          Notably, Bunbao achieved the business vision through connecting
          Bunbao.com online platform, and offline food trucks as well as Farmers
          Market locations together, within reach of hundreds of communities,
          allowing consumers to meet and taste its serving products, and more
          importantly, recognize the brand and “Order Online, Deliver to Home”
          business feature, through more and more landing locations.
        </Paragraph>
        <Paragraph>
          At present, Bunbao is ready for the third round of capital raising,
          and plan to expand and duplicate business models to other areas,
          including LA, Seattle, etc. Bunbao welcomes all talents with
          tremendous passion and creative ideas to join the company to create a
          promising future together.
        </Paragraph>
      </Typography>
      <div>
        <Title>Opportunities</Title>
        <List
          size="large"
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  href={item.url}
                  target="_blank"
                  key="open-link"
                  rel="noreferrer"
                >
                  Open Link
                </a>,
              ]}
            >
              <span css={{ fontWeight: 500 }}>{item.title}</span>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
