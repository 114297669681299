import gql from 'graphql-tag';

const ORDER_FRAGMENT = gql`
  fragment OrderFullAttributes on Order {
    status
    created
    customerNote
    totalCaptured {
      amount
    }
    total {
      net {
        amount
      }
    }
    discountAmount {
      amount
    }
    payments {
      edges {
        node {
          chargeStatus
        }
      }
    }
    paymentStatus
    userEmail
    discountName
    boxs {
      number
      shippingDate
      quantity
      name
      boxsLines {
        productName
        translatedProductName
        quantity
      }
    }
    lines {
      productName
      quantity
    }
    number
    id
    displayId
    paymentStatus
    shippingAddress {
      gateCode
      lastName
      firstName
      phone
      streetAddress1
      streetAddress2
      city
      postalCode
    }
  }
`;
export const CREATE_FAKE_ORDER = gql`
  mutation ($code: String, $groupbuy: Boolean, $input: DraftOrderCreateInput!) {
    fakeOrderCreate(code: $code, groupbuy: $groupbuy, input: $input) {
      errors {
        field
        message
      }
      ruleMsg
      engRuleMsg
      validate
      order {
        id
        discountAmount {
          amount
        }
        total {
          net {
            amount
          }
        }
        subtotal {
          net {
            amount
          }
        }
        totalBalance {
          currency
          amount
          localized
        }
      }
    }
  }
`;

export const CREATE_BOX = gql`
  mutation draftOrder($order: DraftOrderCreateInput!) {
    draftOrderCreate(input: $order) {
      order {
        status
        id
        number
      }
    }
  }
`;

export const VALID_VOUCHER = gql`
  query ($code: String!, $id: ID!) {
    bunbaoVoucher(code: $code, id: $id) {
      code
      discountValue
      discountValueType
      maxAmountSpent {
        amount
      }
      minAmountSpent {
        amount
      }
    }
  }
`;

export const APPLY_CODE = gql`
  mutation ($code: VoucherApplyInput!, $id: ID!) {
    voucherApply(input: $code, id: $id) {
      order {
        voucher {
          bunbaoType
        }
        number
        created
        voucher {
          code
          used
        }
        discountAmount {
          amount
        }
        total {
          net {
            amount
          }
        }
      }
    }
  }
`;

export const DELIVERY_ORDER_BY_USER_LAST = gql`
  query Orders($id: ID!) {
    orderByUser(id: $id, first: 1) {
      edges {
        node {
          status
          created
          totalCaptured {
            amount
          }
          total {
            net {
              amount
            }
          }
          discountAmount {
            amount
          }
          payments {
            edges {
              node {
                chargeStatus
              }
            }
          }
          discountName
          boxs {
            name
            shippingDate
            quantity
            boxsLines {
              productName
              translatedProductName
              quantity
            }
          }
          number
          id
          displayId
          paymentStatus
          shippingAddress {
            lastName
            firstName
            phone
            streetAddress1
            streetAddress2
            city
            postalCode
          }
        }
      }
    }
  }
`;

export const DELIVERY_ORDER_BY_USER_OVERVIEW = gql`
  query Orders($email: String) {
    orderByUser(email: $email) {
      edges {
        node {
          status
          created
          total {
            net {
              amount
            }
          }
          boxs {
            quantity
          }
          number
          id
          displayId
          paymentStatus
        }
      }
    }
  }
`;

export const ORDER_BY_ID = gql`
  query Orders($id: ID!) {
    order(id: $id) {
      status
      created
      totalCaptured {
        amount
      }
      total {
        net {
          amount
        }
      }
      discountAmount {
        amount
      }
      payments {
        edges {
          node {
            chargeStatus
          }
        }
      }
      discountName
      boxs {
        shippingDate
        quantity
        name
        boxsLines {
          productName
          translatedProductName
          quantity
        }
      }
      lines {
        productName
        quantity
      }
      number
      id
      displayId
      paymentStatus
      shippingAddress {
        lastName
        firstName
        phone
        streetAddress1
        streetAddress2
        city
        postalCode
      }
    }
  }
`;

export const FETCH_ORDERS_BY_DATE = gql`
  query ($start: String, $end: String) {
    orderByDateAndIds(start: $start, end: $end) {
      edges {
        node {
          ...OrderFullAttributes
        }
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const FETCH_ORDERS_BY_IDS = gql`
  query ($ids: [ID]) {
    orderByDateAndIds(ids: $ids) {
      edges {
        node {
          ...OrderFullAttributes
        }
      }
    }
  }
  ${ORDER_FRAGMENT}
`;
