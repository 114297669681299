export default {
  en: {
    paymentError: {
      deliveryAndPickupMakingOrderError:
        'Error happened during making order, error code 2',
      groupBuyingMakingOrderError:
        'Error happened during making order, error code 3',
      incorrectShippingDate:
        'Incorrect Delivery Date, please refresh page and try again',
      priceDifferent: 'We have updated your cart price, please check it out.',
    },
    payment: {
      redirectNoticeAlipay:
        "Please don't close the page when you choosing Alipay payment to pay your order, and please wait for redirect to our website when finish payment",
      payWithAlipay: 'Pay with Alipay',
      currentUsingCoupon: 'Coupon Code',
      payment: 'Payment Method',
      payFailed: 'Payment Failed',
      payCompleted: 'Payment Completed',
      alipayProcessing: "Processing with Alipay, please don't close the page",
      alipayComplete: 'Click it if you successfully pay your order',
      alipayFailure: 'Click it if you failed to pay your order',
      backToMenu: 'Back to Menu',
      backToDonation: 'Back to Donation',
      payAgain: 'We did not charge you account, please go back and try again',
      currentAvailableDate: 'Current available delivery date',
      wantToSignUp:
        'Wanna using coupon and enjoy benefits? Sign Up Now! Sign up user will have a lot of benefits',
      hasRegistered: 'Email has already registered, please log in directly',
      yourBalance: 'Your Balance:',
      currentBalance: 'Current balance:',
      doesUseBalance: 'Pay with balance',
      variantAddons: 'People also like',
      addonsPriceTip: '* spend {price} more, get {discount}',
      goShopping: 'go shopping >',
      signUpTip: 'please fill out the email and set the password below',
    },
  },
  zh: {
    paymentError: {
      deliveryAndPickupMakingOrderError: '创建订单时发现错误，错误代码2',
      groupBuyingMakingOrderError: '创建订单时发现错误，错误代码3',
      incorrectShippingDate: '配送日期不正确，请刷新重试',
      priceDifferent: '我们更新了您购物车内商品的价格，请您查看后再次付款。',
    },
    payment: {
      redirectNoticeAlipay:
        '点击下方按钮后，您将跳转到支付宝进行付款，请勿关闭此页面，并且，支付成功后请等待页面自动跳转到BunBao.com',
      payWithAlipay: '使用支付宝付款',
      currentUsingCoupon: '正在使用折扣券',
      payment: '支付',
      payFailed: '支付失败',
      payCompleted: '支付成功',
      alipayProcessing: '等待中，请在支付宝支付，在支付完成前请不要关闭此窗口',
      alipayComplete: '在支付宝支付成功，请点击',
      alipayFailure: '在支付宝支付失败，请点击重试',
      backToMenu: '返回菜单',
      backToDonation: '返回捐赠',
      payAgain: '我们没有从您的账户扣款，请您返回尝试重新支付',
      currentAvailableDate: '当前可选的最近的日期是',
      wantToSignUp:
        '想使用折扣和优惠码吗？现在注册吧！注册用户可以享受优惠和折扣哦～',
      hasRegistered: '邮件已注册，请直接登录',
      yourBalance: '您当前余额:',
      currentBalance: '当前余额:',
      doesUseBalance: '使用余额付款',
      variantAddons: '大家还喜欢',
      addonsPriceTip: '* 您还差 {price} ,就可以立减 {discount}',
      goShopping: '去凑单>',
      signUpTip: '下方填写邮箱并设置密码,即可获得优惠',
    },
  },
};
