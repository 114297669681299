export default {
  en: {
    donation: {
      shopName: 'Donate a Meal to Our Frontline',
      description:
        'We are so grateful for our frontline who continue to provide our community with essential services through this crisis. We want to join hands and rally around them. With every $5 you donate, Bunbao will donate a meal (valued $10 as minimum)  to different agencies and organizations who are helping our community to stay safe and healthy. This is an easy way to support our frontline while we staying at home. ',
      totalWeSpend: 'Total Amount We Delivered',
      currentOurActivity: 'We have delivered',
      totalDonations: 'Total Donation We Received',
      donationList: 'Users Donation List',
      yourDonation: 'Your Donation',
      donated: ' donated ',
      partiallyUsed: 'Partially Delivered',
      used: 'Delivered',
      notUsed: 'Waiting',
      in: 'in',
      moreDetails: 'More Details',
      totalAmountDelivered: 'Total Amount Delivered',
      pleaseCheckout: 'More Details Please Checkout The Link',
      clickIt: 'Click It to Read Details',
      ourMission: 'Our Mission',
      faq: 'FAQs',
      note: 'Please leave some words to our frontline heros here to support their hard work',
      noteForDonation: 'Note for your donation',
      details: 'Details',
    },
  },
  zh: {
    donation: {
      shopName: '为抗击疫情的他/她捐献一顿饭',
      description:
        '捐款将用于为医院的医护人员和在COVID-19前线响应的人员如警察提供爱心饭。我们也在考虑捐赠给受COVID-19影响的人们，如低收入家庭，学校学生以及老人社区等。',
      totalWeSpend: '我们已送达',
      currentOurActivity: '已送达列表',
      totalDonations: '我们一共收到',
      donationList: '捐赠款列表',
      yourDonation: '您的捐赠',
      donated: ' 捐赠了 ',
      used: '已送达',
      partiallyUsed: '部分送达',
      notUsed: '等待中',
      in: '在',
      moreDetails: '详情请点击',
      totalAmountDelivered: '已经送达',
      pleaseCheckout: '更多详情新闻信息，请查看我们的捐款专栏',
      clickIt: '点击查看说明',
      ourMission: '倡议书',
      faq: '操作说明',
      note: '请给前线工作人员写一句话，为他们加油打气吧！',
      noteForDonation: '留下您的祝福',
      details: '细节',
    },
  },
};
