import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  warehouseId: null,
  manageWarehouse: null,
  lastWarehousePath: null,
  isScheduledDelivery: false,
  taxRate: 0,
};

export const warehouseUpdate = (state, action) => {
  const updateState = {
    warehouseId: action.warehouseId,
    taxRate: action.taxRate,
  };
  return updateObject(state, updateState);
};

export const attributeUpdate = (state, action) => {
  const updateState = {
    [action.attributeName]: action.attributeValue,
  };
  // console.log("updateState",updateState);
  return updateObject(state, updateState);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WAREHOUSE_UPDATE:
      return warehouseUpdate(state, action);
    case actionTypes.WAREHOUSE_ATTRIBUTE_UPDATE:
      return attributeUpdate(state, action);
    default:
      return state;
  }
};
