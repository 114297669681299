/** @jsxRuntime classic */
/** @jsx jsx */
import { connect } from 'react-redux';
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Carousel } from 'antd';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { BUCKET_CDN_URL } from '../../config';

function MenuBanner(props) {
  const {
    slides,
    language,
    handleBannerClick,
    handleIsClickableClicked,
    handleClickCategoryIcon,
    hasArrow,
  } = props;
  const Arrow = ({ type, style, className, onClick }) => (
    <div
      style={{
        ...style,
        transform: type === 'right' ? 'rotate(-45deg)' : 'rotate(135deg)',
        border: 'solid rgba(0, 0, 0, 0.65)',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
      }}
      role="presentation"
      className={className}
      onClick={onClick}
    />
  );
  Arrow.defaultProps = { style: null, className: null, onClick: null };
  Arrow.propTypes = {
    type: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  const device = isMobile ? 'mobile' : 'desktop';
  const history = useHistory();
  return (
    <div>
      <Carousel
        autoplay
        autoplaySpeed={5000}
        arrows
        prevArrow={hasArrow && <Arrow type="left" />}
        nextArrow={hasArrow && <Arrow type="right" />}
      >
        {slides.map((slide) =>
          slide.banners.map((banner) =>
            banner.language === language && banner.device === device ? (
              <div role="presentation">
                <img
                  style={{ width: '100%' }}
                  src={`${BUCKET_CDN_URL}${banner.picture.key}`}
                  alt={slide.name}
                  onClick={() => {
                    if (slide.isClickable && handleIsClickableClicked) {
                      handleIsClickableClicked(slide);
                    } else if (slide.isProduct && handleBannerClick) {
                      handleBannerClick(slide.productId);
                    } else if (slide.isLink) {
                      history.push(slide.link);
                    } else if (slide.isCategory) {
                      handleClickCategoryIcon({ _id: slide.categoryId });
                    }
                  }}
                  role="presentation"
                />
              </div>
            ) : null
          )
        )}
      </Carousel>
    </div>
  );
}

MenuBanner.defaultProps = {
  handleBannerClick: null,
  handleIsClickableClicked: null,
  handleClickCategoryIcon: null,
  hasArrow: true,
};

MenuBanner.propTypes = {
  language: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleBannerClick: PropTypes.func,
  handleIsClickableClicked: PropTypes.func,
  handleClickCategoryIcon: PropTypes.func,
  hasArrow: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(MenuBanner);
