import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Badge, Modal, Button } from 'antd';
import SingleProductCard from '../../DeliveryMenu/SingleProductCard/SingleProductCard';
import ProductDetailModal from '../../../../components/Modals/ProductDetailModal/ProductDetailModal';
import ShoppingBag from '../../../../assets/images/icons/shoppingBagVector.svg';
import './VariantsAddons.less';

export class VariantsAddOns extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      shoppingCart: [],
      productDetailModalVisible: false,
      shoppingCartVisible: true,
      selectedProductId: null,
    };
  }

  handleDecrement = (id) => {
    const { shoppingCart } = this.state;
    const { variantList } = this.props;
    const product = variantList.find((item) => item._id === id);
    if (product) {
      if (product.count > 0) {
        product.count -= 1;
      }
      const index = shoppingCart.findIndex((item) => item._id === id);
      if (index > -1) {
        // already in the shopping cart
        shoppingCart[index] = product;
        if (shoppingCart[index].count === 0) {
          shoppingCart.splice(index, 1);
        }
      }
      this.setState({
        shoppingCart: [...shoppingCart],
      });
    }
  };

  handleIncrement = (id) => {
    console.log(id);
    const { shoppingCart } = this.state;
    const { variantList } = this.props;
    const product = variantList.find((item) => item._id === id);
    if (product) {
      product.count += 1;
      const index = shoppingCart.findIndex((item) => item._id === id);
      if (index > -1) {
        // already in the shopping cart
        shoppingCart[index] = product;
      } else {
        // not in shopping cart
        shoppingCart.push(product);
      }
      this.setState({
        shoppingCart: [...shoppingCart],
      });
    }
  };

  handleProductDetailModal = (id) => {
    this.setState({ productDetailModalVisible: true, selectedProductId: id });
  };

  calculatedTotalCount = () => {
    const { shoppingCart } = this.state;
    return shoppingCart.reduce((sum, cur) => {
      return sum + cur.count || 0;
    }, 0);
  };

  calculatedTotalPrice = () => {
    const { shoppingCart } = this.state;
    const totalPrice = shoppingCart.reduce((sum, cur) => {
      return sum + cur.count * cur.listPrice;
    }, 0);
    return Number(totalPrice).toFixed(2);
  };

  handleSubmitShoppingCart = () => {
    const { handleAddToShoppingCart } = this.props;
    const { shoppingCart } = this.state;
    handleAddToShoppingCart(shoppingCart);
    this.setState({ shoppingCart: [] });
  };

  render() {
    const { language, variantList } = this.props;
    const {
      productDetailModalVisible,
      shoppingCartVisible,
      selectedProductId,
      shoppingCart,
    } = this.state;

    return (
      <div className="variants-addons-space">
        <Modal
          title={null}
          footer={null}
          visible={productDetailModalVisible}
          destroyOnClose
          onCancel={() => {
            this.setState({
              productDetailModalVisible: false,
            });
          }}
          style={{ top: '10px' }}
        >
          <ProductDetailModal
            productList={variantList}
            selectedProductId={selectedProductId}
            handleIncrement={this.handleIncrement}
            handleDecrement={this.handleDecrement}
            increaseShoppingCartNumber={() => {}}
          />
        </Modal>
        <div style={{ height: 'auto', paddingTop: '20px' }}>
          <div
            className="shopping-cart"
            style={
              shoppingCartVisible
                ? {
                    height: 'auto',
                    padding: '10px 10px 30px 10px',
                  }
                : {}
            }
          >
            {shoppingCart.length > 0 ? (
              <div>
                {shoppingCart.map((item) => {
                  return (
                    <div key={item._id}>
                      <div className="shoppingCart-item">
                        <div>
                          <img
                            style={{
                              width: '80px',
                              height: '80px',
                              objectFit: 'cover',
                            }}
                            src={item.imgUrl}
                            alt=""
                          />
                        </div>
                        <div>
                          <div>{item.name[language]}</div>
                          <div>{Number(item.listPrice).toFixed(2)}</div>
                          <div className="dish-functional-block">
                            <div
                              className="dish-decrement"
                              role="presentation"
                              onClick={() => {
                                this.handleDecrement(item.id);
                              }}
                            >
                              <MinusOutlined />
                            </div>
                            <div className="dish-counter">{item.count}</div>
                            <div
                              className="dish-decrement"
                              role="presentation"
                              onClick={() => {
                                this.handleIncrement(item.id);
                              }}
                            >
                              <PlusOutlined />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="shopping-item-price">
                  <div>
                    <FormattedMessage id="totalPrice" />
                  </div>
                  <div> ${this.calculatedTotalPrice()}</div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    style={{ width: '50%' }}
                    type="primary"
                    onClick={() => {
                      this.handleSubmitShoppingCart();
                    }}
                  >
                    <FormattedMessage id="addToShopping" />
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <FormattedMessage id="emptyCart" />
              </div>
            )}
          </div>
          <div
            style={{ textAlign: 'center', position: 'relative', top: '-15px' }}
          >
            <div className="cart-border-line" />
            <Badge count={this.calculatedTotalCount()}>
              <img
                style={{
                  height: '30px',
                  color: 'red',
                  padding: '0px 6px 3px 6px',
                  background: 'white',
                  borderBottom: '1px solid #ccc',
                  borderRadius: '0px 0px 100px 100px',
                }}
                src={ShoppingBag}
                alt=""
                role="presentation"
                onClick={() => {
                  this.setState({ shoppingCartVisible: !shoppingCartVisible });
                }}
              />
            </Badge>
          </div>
        </div>

        {variantList.length > 0 ? (
          variantList.map((product) => (
            <SingleProductCard
              key={product._id}
              e={product}
              language={language}
              onOpenProductDetailModal={this.handleProductDetailModal}
              handleDecrement={this.handleDecrement}
              handleIncrement={this.handleIncrement}
              handleChangeOriginArray={() => {}}
              translation={() => {}}
              forceMobileUI
            />
          ))
        ) : (
          <div style={{ fontSize: '30px' }}>
            <FormattedMessage id="empty" />
          </div>
        )}
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   language: state.language.lang,
// });

VariantsAddOns.propTypes = {
  variantList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  language: PropTypes.string.isRequired,
  handleAddToShoppingCart: PropTypes.func.isRequired,
};

// VariantsAddOns.defaultProps = {
//   language: 'en',
// };
export default VariantsAddOns;
