export default {
  en: {
    vote: {
      createNewPost: 'Post',
      title: 'Title',
      description: 'Description',
      nickName: 'Nickname',
      picture: 'Picture',
      author: 'Author',
      inValidVote: 'You have reached the maximum votes per day',
      totalVotes: 'Votes:',
      share: 'Share',
      vote: 'Vote',
      inValidPost: 'Exceed the number of votes available for today',
      duplicateVotePost:
        'Duplicate post, you can not create two post at the same campaign',
      totalVoteOder: 'Sort by Votes',
      numberOrder: 'Sort by Vote Number',
      viewAllPosts: 'View All Posts',
      viewMyPost: 'View My Post',
      postStatusTip: 'Your post status is:',
      postReason: 'Reason:',
      imageTags: 'Tag It!',
    },
  },
  zh: {
    vote: {
      createNewPost: '我要上传',
      title: '标题',
      description: '描述',
      nickName: '昵称',
      picture: '照片',
      author: '作者',
      inValidVote: '超出当天可用投票次数',
      totalVotes: '总票数:',
      share: '分享',
      vote: '投票',
      inValidPost: '每位用户仅限一帖',
      duplicateVotePost: '您不可以在同一个活动中创建两个帖子',
      totalVoteOder: '票数排序',
      numberOrder: '编号排序',
      viewAllPosts: '查看所有作品',
      viewMyPost: '我的帖子',
      postStatusTip: '您帖子的状态为:',
      postReason: '原因:',
      imageTags: '标记ta!',
    },
  },
};
