export default {
  en: {
    productForm: {
      pictureNotice: 'Please upload horizontal picture (Size: 248x181)',
    },
    variantForm: {
      originalPrice: 'Original Price',
      discountPrice: 'Discount Price (Show in the menu)',
      priceNotice: 'Merchants bear the discounted price',
      pictureNotice:
        'Default using product picture. Please upload horizontal picture (Size: 248x181)',
    },
  },
  zh: {
    productForm: {
      pictureNotice: '请上传横版图片(尺寸: 248x181)',
    },
    variantForm: {
      originalPrice: '原价',
      discountPrice: '折扣价格(菜单中显示此价格)',
      priceNotice: '商户承担折扣价格',
      pictureNotice: '默认继承商品图片，请上传横版图片(尺寸: 248x181)',
    },
  },
};
