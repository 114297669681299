import React from 'react';
import FormBuilder from 'antd-form-builder';
import { Form, Button, message } from 'antd';
import { apolloClient } from '../../../util';
import { USER_UPDATE_CURRENT_PASSWORD } from '../../../api/user';

function UpdatePasswordForm() {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const { oldPassword, newPassword } = values;
    const { data } = apolloClient
      .mutate({
        mutation: USER_UPDATE_CURRENT_PASSWORD,
        variables: {
          oldPassword,
          newPassword,
        },
      })
      .then(() => {
        console.log(data);
        form.resetFields();
        message.success('Update password successful!');
      })
      .catch(() => {
        message.error('Old password is not correct!');
      });
  };

  const meta = {
    columns: 1,
    fields: [
      {
        key: 'oldPassword',
        label: 'Old Password',
        widget: 'password',
        required: true,
      },
      {
        key: 'newPassword',
        label: 'New Password',
        widget: 'password',
        onChange: () => {
          if (form.isFieldTouched('confirmPassword')) {
            form.validateFields(['confirmPassword']);
          }
        },
        rules: [
          {
            required: true,
            message: 'Password is required',
          },
        ],
      },
      {
        key: 'confirmPassword',
        label: 'Confirm Password',
        widget: 'password',
        required: true,
        rules: [
          {
            validator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (value !== form.getFieldValue('newPassword')) {
                  reject(new Error('Two passwords are inconsistent.'));
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
    ],
  };

  return (
    <Form form={form} onFinish={handleSubmit} style={{ margin: 'auto' }}>
      <FormBuilder meta={meta} form={form} />
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}

export default UpdatePasswordForm;
