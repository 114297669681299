import gql from 'graphql-tag';

export const ARTICLE_FRAGMENT = gql`
  fragment ARTICLE_FRAGMENT on Article {
    _id
    title {
      _id
      zh
      en
    }
    alias
    content {
      zh
      en
    }
    type
    categoryIds
    categories {
      _id
      name
      title {
        _id
        zh
        en
      }
    }
    isTop
    user {
      _id
      email
    }
    userId
    ranking
    deleted
    createdAt
    updatedAt
  }
`;

export const ARTICLE_CREATE_ONE = gql`
  mutation ($record: CreateOneArticleInput!) {
    articleCreateOne(input: { record: $record }) {
      record {
        ...ARTICLE_FRAGMENT
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_UPDATE_BY_ID = gql`
  mutation ($record: UpdateByIdArticleInput!) {
    articleUpdateById(input: { record: $record }) {
      record {
        ...ARTICLE_FRAGMENT
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyArticleInput
    $filter: FilterFindManyArticleInput
  ) {
    articlePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        ...ARTICLE_FRAGMENT
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_FIND_ONE = gql`
  query ($filter: FilterFindOneArticleInput, $sort: SortFindOneArticleInput) {
    articleOne(filter: $filter, sort: $sort) {
      ...ARTICLE_FRAGMENT
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_FIND_MANY = gql`
  query ($filter: FilterFindManyArticleInput, $sort: SortFindManyArticleInput) {
    articleMany(filter: $filter, sort: $sort) {
      ...ARTICLE_FRAGMENT
    }
  }
  ${ARTICLE_FRAGMENT}
`;

export const ARTICLE_DELETE_OR_RESTORE = gql`
  mutation ($id: MongoID!) {
    articleDeleteOrRestoreOne(_id: $id) {
      _id
    }
  }
`;
