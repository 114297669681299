/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Result, Button, message, Modal } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as QueryString from 'query-string';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WechatAPI } from 'react-wechat-api';
import * as actions from '../../../../store/actions';
import StepBar from '../StepBar/StepBar';
import {
  getCustomerPath,
  apolloClient,
  waitFunction,
  // checkWeChatUserAgent,
} from '../../../../util';
import { ORDER_SUBMIT_ALIPAY_PAYMENT } from '../../../../api/payment';
import {
  CUSTOMER_ORDER_BY_ID,
  // CUSTOMER_ORDER_SHARE_START_TIME,
} from '../../../../api/customerOrder';
// import boxImage from '../../../../assets/images/box.png';
import { CONFIGURATION_FIND_ONE } from '../../../../api/configuration';
import copy from '../../../../assets/images/icons/copy.svg';
import tipPicture from '../../../../assets/images/shareTip.png';
import buttonPicture from '../../../../assets/images/shareButton.png';

export class Order extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      success: false,
      loading: true,
      paymentType: null,
      guestOrderId: null,
      showModal: false,
      showTipModal: false,
      // isTurnOn: false,
      wechatShareData: {
        title: '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
        link: () => window.location.href,
      },
    };
  }

  componentDidMount = async () => {
    const {
      location: { search, state },
      referralCode,
    } = this.props;
    // console.log(this.props);
    // console.log(state);
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: { filter: { isReferConfiguration: true } },
    });
    console.log(configurationOne);
    if (configurationOne.referConfiguration) {
      this.setState({
        // isTurnOn: configurationOne.referConfiguration.isTurnOn,
      });
    }

    const {
      /* eslint-disable-next-line */
      client_secret,
      source,
      orderId,
      paymentType,
    } = QueryString.parse(search);
    // return values
    // client_secret=src_client_secret_XOvIo2D3ubL8mBt56RzhF7uf&livemode=false&orderId=123&source=src_1GR4JgCxrQhSkXGSIGYqYgkB
    this.setState({
      guestOrderId: orderId,
    });
    if (paymentType === 'alipay') {
      this.setState({ loading: true, paymentType: 'alipay' });
      // try to charge the customer by alipay
      try {
        await waitFunction(5000);
        console.log('waiting for 5 seconds');
        // first check order status
        let orderResponse = await apolloClient.query({
          query: CUSTOMER_ORDER_BY_ID,
          variables: { id: orderId },
          fetchPolicy: 'network-only',
        });
        console.log(orderResponse);
        // if the order already paid
        if (
          orderResponse.data.customerOrderById &&
          orderResponse.data.customerOrderById.paymentStatus === 'paid'
        ) {
          this.setState({ loading: false, success: true });
          // should clean up
          this.handleCleanup();
        } else if (
          orderResponse.data.customerOrderById &&
          orderResponse.data.customerOrderById.isLocked === true
        ) {
          // wait for 5 seconds
          // second check order status
          await waitFunction(5000);
          console.log('waiting for 5 seconds');
          // check status again
          orderResponse = await apolloClient.query({
            query: CUSTOMER_ORDER_BY_ID,
            variables: { id: orderId },
            fetchPolicy: 'network-only',
          });
          if (
            orderResponse.data.customerOrderById &&
            orderResponse.data.customerOrderById.paymentStatus === 'paid'
          ) {
            await this.setState({
              loading: false,
              success: true,
              wechatShareData: {
                title: '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
                link: `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${state.orderId}`,
              },
            });
            // should clean up
            this.handleCleanup();
          } else {
            // try to pay it
            this.handleChargeOrder(orderId, source, client_secret);
          }
        } else {
          this.handleChargeOrder(orderId, source, client_secret);
        }
      } catch (error) {
        // show retry button
        message.error('Can not resolve the payment');
        this.setState({
          loading: false,
          success: false,
        });
      }
    } else if (paymentType === 'creditCard') {
      this.setState({
        success: true,
        wechatShareData: {
          title: '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
          link: `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${state.orderId}`,
        },
      });
    } else {
      this.setState({ loading: false, success: false });
    }
  };

  handleChargeOrder = async (orderId, source, clientSecret) => {
    // try to pay it
    const response = await apolloClient.mutate({
      mutation: ORDER_SUBMIT_ALIPAY_PAYMENT,
      variables: {
        orderId,
        sourceId: source,
        clientSecret,
      },
    });
    console.log(response);
    if (response.data.orderCreateAlipayPayment) {
      this.setState({ loading: false, success: true });
      // should clean up
      this.handleCleanup();
    } else {
      this.setState({ loading: false, success: false });
    }
  };

  handleCleanup = async () => {
    const {
      // currentShoppingMode,
      // updateShoppingCart,
      updateWarehouseCart,
      updateOneUserAttribute,
    } = this.props;
    const empty = {
      productList: [],
      boxList: [],
    };
    // if (currentShoppingMode === 'delivery') {
    //   updateShoppingCart({
    //     productList: [],
    //     boxList: [],
    //   });
    // } else {
    //   updateWarehouseCart({ productList: [] });
    // }
    await updateOneUserAttribute(null, 'currentCampaignContentId');
    await updateOneUserAttribute(null, 'groupBuyInfo');
    await updateOneUserAttribute(null, 'overWriteAddress');
    await updateOneUserAttribute(null, 'preselectPickupInfo');
    await updateWarehouseCart(empty);
  };

  render() {
    const {
      currentShoppingMode,
      group,
      referralCode,
      location: { state },
      language,
      intl,
    } = this.props;
    const {
      loading,
      success,
      paymentType,
      guestOrderId,
      showModal,
      // isTurnOn,
      wechatShareData,
      showTipModal,
    } = this.state;

    return (
      <div>
        {showTipModal && (
          <div
            css={{
              position: 'fixed',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: '18888',
              backgroundColor: '#000000',
              opacity: '0.7',
            }}
          >
            <div css={{ position: 'absolute', right: '0', top: '0' }}>
              <img
                src={tipPicture}
                alt="Tip"
                css={{ width: '70%', float: 'right' }}
              />
              <img
                src={buttonPicture}
                alt="Button"
                css={{ width: '70%', float: 'right' }}
                onClick={() => {
                  this.setState({ showTipModal: false });
                }}
                role="presentation"
              />
            </div>
          </div>
        )}
        <WechatAPI shareData={wechatShareData}>
          <div />
        </WechatAPI>
        <StepBar currentStep={2} />
        <Modal
          visible={showModal}
          onCancel={() => {
            this.setState({ showModal: false });
          }}
          footer={null}
        >
          <CopyToClipboard
            text={
              language === 'zh'
                ? `送您一张【BunBao 优惠券】点击链接享受全单5%折扣！${window.location.host}/share-order?referralCode=${referralCode}&orderId=${state.orderId}`
                : `Friends buy friends save. Click link to enjoy 5% discount. ${window.location.host}/share-order?referralCode=${referralCode}&orderId=${state.orderId}`
            }
            onCopy={() => {
              Modal.success({
                content: intl.formatMessage({ id: 'copied' }),
              });
            }}
          >
            <div style={{ cursor: 'pointer' }}>
              <div style={{ padding: '10px' }}>
                <img style={{ width: '32px' }} src={copy} alt="Copy Icon" />
              </div>
              <div style={{ padding: '10px' }}>
                {language === 'zh'
                  ? '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！'
                  : 'Friends buy friends save. Click link to enjoy 5% discount.'}
                <br />
                {window.location.host}/share-order?referralCode=
                {referralCode}
                &orderId=
                {state.orderId}
              </div>
            </div>
          </CopyToClipboard>
        </Modal>
        <div>
          {loading && !success && paymentType === 'alipay' && (
            <Result
              icon={<LoadingOutlined />}
              title={<FormattedMessage id="payment.alipayProcessing" />}
              // extra={<Button type="primary">Try pay again using Alipay</Button>}
            />
          )}
          {loading === false && !success && paymentType === 'alipay' && (
            <Result
              status="error"
              title={<FormattedMessage id="payment.payFailed" />}
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    const { history } = this.props;
                    history.replace(getCustomerPath('checkout/payment'));
                  }}
                >
                  <FormattedMessage id="payment.payAgain" />
                </Button>
              }
            />
          )}
          {success && (
            <div>
              <Result
                status="success"
                title={
                  <FormattedMessage
                    id={
                      currentShoppingMode !== 'donation'
                        ? 'thankYouForPeacherAtBunBao'
                        : 'thankYouForDonation'
                    }
                  />
                }
                subTitle={
                  currentShoppingMode === 'groupBuy' ? (
                    <FormattedMessage id="willRedirectToGroupBuying" />
                  ) : null
                }
                extra={[
                  <Button size="large" key="next">
                    <Link
                      to={() => {
                        if (group && group.name === 'guest') {
                          return getCustomerPath(
                            guestOrderId
                              ? `search-order?orderId=${guestOrderId}`
                              : 'search-order'
                          );
                        }
                        if (
                          currentShoppingMode === 'delivery' ||
                          currentShoppingMode === 'donation'
                        ) {
                          return getCustomerPath('account/orders');
                        }
                        if (currentShoppingMode === 'groupBuy') {
                          return getCustomerPath('account/campaign');
                        }
                        return getCustomerPath('account/orders');
                      }}
                    >
                      <FormattedMessage
                        id={
                          currentShoppingMode === 'groupBuy'
                            ? 'clickCheckOrderGroup'
                            : 'clickCheckOrder'
                        }
                      />
                    </Link>
                  </Button>,
                ]}
              />
              {/* {isTurnOn && group.name !== 'guest' && (
                <div
                  css={{
                    textAlign: 'center',
                    maxWidth: '500px',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '500px',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      display: 'flex',
                      backgroundColor: '#F7C5BF',
                      padding: '20px',
                      borderRadius: '10px',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <div>{language === 'zh' ? '分享' : 'Share'}</div>
                      <div>{language === 'zh' ? '订单' : 'Order'}</div>
                    </div>
                    <div style={{ flexGrow: 2 }}>
                      {language === 'zh'
                        ? '分享成功拿3%返利，跟买者得5%折扣'
                        : 'You get 3% reward, your friends get 5% discount'}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <img src={boxImage} alt="box" height="50" width="50" />
                    </div>
                  </div>
                  <div style={{ margin: '10px 0' }}>
                    {language === 'zh'
                      ? '好友通过您的订单分享链接成功下单后，您将立即获得订单3%现金返利。而好友也将获得新订单的5%优惠。'
                      : 'After your friends successfully place an order through your order sharing link, you will immediately receive a 3% cash rebate on the order, your friends will also get a 5% discount on new orders.'}
                  </div>
                  <Button
                    style={{ margin: '20px 0' }}
                    type="primary"
                    size="large"
                    key="share"
                    onClick={async () => {
                      if (referralCode) {
                        // get url with referral code
                        try {
                          await apolloClient.mutate({
                            mutation: CUSTOMER_ORDER_SHARE_START_TIME,
                            variables: {
                              orderId: state.orderId,
                            },
                          });
                        } catch (error) {
                          console.error(error);
                        }
                        let content = '';
                        let originalUrl = `https://bunbao.com/share-order?referralCode=${referralCode}&orderId=${state.orderId}`;
                        if (process.env.NODE_ENV !== 'production') {
                          originalUrl = `https://test.bunbao.com/share-order?referralCode=${referralCode}&orderId=${state.orderId}`;
                        }
                        if (language === 'zh') {
                          content = `送您一张【BunBao 优惠券】点击链接享受全单5%折扣！${originalUrl}`;
                        } else {
                          content = `Friends buy friends save. Click link to enjoy 5% discount. ${originalUrl}`;
                        }
                        if (!navigator.clipboard) {
                          const textField = document.createElement('textarea');
                          textField.innerText = content;
                          document.body.appendChild(textField);
                          textField.select();
                          document.execCommand('copy');
                          textField.remove();
                        } else {
                          console.log(navigator.clipboard);
                          try {
                            await navigator.clipboard.writeText(content);
                          } catch (error) {
                            const textField =
                              document.createElement('textarea');
                            textField.innerText = content;
                            document.body.appendChild(textField);
                            textField.select();
                            document.execCommand('copy');
                            textField.remove();
                            console.error(error);
                          }
                        }
                        if (navigator.share) {
                          if (language === 'zh') {
                            navigator
                              .share({
                                title: content,
                                text: content,
                                url: originalUrl,
                              })
                              .then(() => console.log('Successful share'))
                              .catch((error) =>
                                console.log('Error sharing', error)
                              );
                          } else {
                            navigator
                              .share({
                                title: content,
                                text: content,
                                url: originalUrl,
                              })
                              .then(() => console.log('Successful share'))
                              .catch((error) =>
                                console.log('Error sharing', error)
                              );
                          }
                        }
                        if (checkWeChatUserAgent()) {
                          this.setState({ showTipModal: true });
                        } else {
                          this.setState({ showModal: true });
                        }
                      }
                    }}
                  >
                    {language === 'zh' ? '立即分享' : 'Share Now'}
                  </Button>
                  <div>
                    {language === 'zh' ? '活动细则：' : 'Reward Program Rules'}
                  </div>
                  <div style={{ textAlign: 'left' }}>
                    <div>
                      {language === 'zh'
                        ? '1.当天下单超过$35，可以发起一次订单分享活动。'
                        : '1.If you place an order for more than $35, you can initiate an order sharing activity.'}
                    </div>
                    <div>
                      {language === 'zh'
                        ? '2.整单分享有效期为24小时。'
                        : '2.The order sharing is valid for 24 hours.'}
                    </div>
                    <div>
                      {language === 'zh'
                        ? '3.如果您的朋友通过您的分享链接成功下单后，您立即获得您的订单3%的现金返利。'
                        : '3.If your friend successfully places an order through your sharing link, you will immediately get a 3% cash rebate on your order.'}
                    </div>
                    <div>
                      {language === 'zh'
                        ? '4.如果朋友跟买，也将获得自身订单的5%折扣。'
                        : '4.If a friend buys with your sharing link, he will also get a 5% discount on his order.'}
                    </div>
                    <div>
                      {language === 'zh'
                        ? '5.每单可无限分享，产生新订单可再分享。'
                        : '5.Each order can be shared indefinitely, and new orders can be shared again.'}
                    </div>
                    <div>
                      {language === 'zh'
                        ? '6.活动解释权归BunBao.com所有。'
                        : '6.The right to interpret activities is vested in BunBao.com.'}
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Order.propTypes = {
  currentShoppingMode: PropTypes.string.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  // updateShoppingCart: PropTypes.func.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  updateOneUserAttribute: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  referralCode: PropTypes.string,
  language: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

Order.defaultProps = {
  group: null,
  referralCode: null,
};

const mapStateToProps = (state) => ({
  currentShoppingMode: state.user.currentShoppingMode,
  group: state.user.group,
  referralCode: state.user.referralCode,
  language: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
  // updateShoppingCart: (shoppingCart) =>
  //   dispatch(actions.updateShoppingCart(shoppingCart)),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  // setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
  // setUserAddressType: (mode) => dispatch(actions.setUserAddressType(mode)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Order))
);
