/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  EnvironmentOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Modal, Row, Col, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../store/actions';
import { ADDRESS_QUERY } from '../../../../api/address';
import { getCustomerPath, apolloClient } from '../../../../util';
import Distributor from '../../../../assets/images/distributor.png';
import Central from '../../../../assets/images/central.png';
import FarmersMarket from '../../../../assets/images/farmersMarket.png';
import {
  blockCssObjectTypeOne,
  borderCssObjectTypeOne,
} from '../../../../components/SmallComponents/SmallCssSample';
// import closeIcon from '../../../../assets/images/icons/closeStore.svg';
// import openIcon from '../../../../assets/images/icons/openStore.svg';
import {
  // openClose,
  openTimeSlotRender,
} from '../../../../components/SmallComponents/SmallComponents';
import AddressCard from '../../../../components/Others/AddressCard/AddressCard';
import { BUCKET_CDN_URL } from '../../../../config';
// import RequireLoginModal from '../../../../components/Modals/RequireLoginModal/RequireLoginModal';

const shouldHidePickupButton = ({ region }) => {
  if (region === 'Sacramento') {
    return true;
  }

  return false;
};

function WarehouseCard(props) {
  const {
    warehouse,
    warehouseSelectors,
    index,
    handleClickWarehouse,
    handleOpenDeliveryModal,
    mapMode,
  } = props;

  const sliderRef = useRef(null);
  const group = useSelector((state) => state.user.group);
  const token = useSelector((state) => state.auth.token);
  const lang = useSelector((state) => state.language.lang);
  const dispatch = useDispatch();
  const updateOneUserAttribute = (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName));
  const setUserAddressType = (input) =>
    dispatch(actions.setUserAddressType(input));
  const setUserShoppingMode = (input) =>
    dispatch(actions.setUserShoppingMode(input));
  const saveLinkForLogin = (input) => dispatch(actions.saveLinkForLogin(input));
  const guestAuth = () => dispatch(actions.guestAuth());
  const getCurrentUser = () => dispatch(actions.getCurrentUser());
  const history = useHistory();
  const [informationSelectModal, setModal] = useState(false);
  // const [loginModal, setLoginModal] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const handleClickLoginButton = async () => {
    const path = `${window.location.pathname}?mode=scheduledPickup`;
    await saveLinkForLogin(path);
    // console.lgo(path);
    history.push(getCustomerPath('login'));
  };
  const fetchMyAddress = async () => {
    try {
      const { data } = await apolloClient.query({
        query: ADDRESS_QUERY,
        fetchPolicy: 'network-only',
      });
      if (data.userCurrent.addresses) {
        setAddresses(data.userCurrent.addresses);
      }
    } catch (error) {
      console.error(error);
      message.warn('Error happened, can not fetch addresses');
    }
  };

  const handleGuestCreateAddress = (address) => {
    if (address && group && group.name === 'guest') {
      setAddresses([...addresses, address]);
    }
  };

  const handleLoginAsGuest = async () => {
    setModal(false);
    try {
      await guestAuth();
      await getCurrentUser();
    } catch (error) {
      message.error('Can not login, please try again');
    }
  };

  useEffect(() => {
    if (token) {
      fetchMyAddress();
    }
  }, [token]);

  const typeIcon = (type) => {
    switch (type) {
      case 'central':
        return <img src={Central} alt="Central" />;
      case 'distributor':
        return <img src={Distributor} alt="Distributor" />;
      case 'farmersMarket':
        return <img src={FarmersMarket} alt="Framers Market" />;
      default:
        return <img src={Distributor} alt="Distributor" />;
    }
  };

  const renderModal = () => {
    if (!token) {
      return (
        <Modal
          footer={null}
          title={null}
          width={768}
          closable
          maskClosable
          visible={informationSelectModal}
          onCancel={() => {
            setModal(false);
          }}
        >
          <div css={borderCssObjectTypeOne}>
            <div
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: isMobile ? '14px' : '16px',
              }}
            >
              <FormattedMessage id="loginRedirectingContinue" />
            </div>

            <div
              role="presentation"
              onClick={async () => {
                await handleClickLoginButton();
              }}
              css={blockCssObjectTypeOne}
            >
              <FormattedMessage id="login" />
            </div>
            <div
              role="presentation"
              onClick={async () => {
                await handleLoginAsGuest();
              }}
              css={blockCssObjectTypeOne}
              style={{ marginTop: '10px' }}
            >
              <FormattedMessage id="loginAsGuest" />
            </div>
          </div>
        </Modal>
      );
    }
    return (
      <Modal
        footer={null}
        title={null}
        width={768}
        closable
        maskClosable
        visible={informationSelectModal}
        onCancel={() => {
          setModal(false);
        }}
      >
        <Row type="flex" justify="center" gutter={16}>
          <Col
            span={24}
            css={{
              padding: '10px',
            }}
          >
            <AddressCard
              node={warehouse}
              editedAddressId={null}
              handleGuestCreateAddress={handleGuestCreateAddress}
              fetchMyAddress={fetchMyAddress} // add
              handleSelectAddress={async (node) => {
                const preselectPickupInfo = {};
                preselectPickupInfo._id = node.defaultAddressId;
                preselectPickupInfo.addressOne = node.addressOne;
                preselectPickupInfo.addressTwo = node.addressTwo;
                preselectPickupInfo.zipCode = node.zipCode;
                preselectPickupInfo.firstName = node.firstName;
                preselectPickupInfo.lastName = node.lastName;
                preselectPickupInfo.phoneNumber = node.phoneNumber;
                preselectPickupInfo.selectedDate = node.selectedDate;
                preselectPickupInfo.campaignContentId = node.campaignContentId;
                console.log('preselectPickupInfo', preselectPickupInfo);
                await updateOneUserAttribute(
                  preselectPickupInfo,
                  'preselectPickupInfo'
                );
                // await updateOneUserAttribute(warehouse.campaignContentId,'currentCampaignContentId');
                history.push(getCustomerPath('delivery-menu'));
              }} // add
              handleSelectEditAddress={() => {}}
              locationInfo
              addresses={addresses}
              handleCheckZipCode={() => {}}
              onlyAllowedDate={null}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const renderButton = () => {
    if (mapMode === 'scheduledPickup') {
      return (
        <div
          role="presentation"
          onClick={async () => {
            if (!token) {
              setModal(true);
            } else {
              await setUserAddressType('pickup');
              await setUserShoppingMode('delivery');
              setModal(true);
            }
          }}
        >
          {/* <Link
            to={`${getCustomerPath('delivery-menu')}`}
            onClick={async () => {
              setModal(true)
              // if(warehouse&&fixLocationWarehouseIdArray[warehouse._id]){
              //   await updateOneUserAttribute(
              //     fixLocationWarehouseIdArray[warehouse._id],
              //     'currentCampaignContentId'
              //   );
              // }
            }}
          > */}
          <div className="action-button" css={{ backgroundColor: '#ff4d4d' }}>
            <span css={{ fontWeight: '600' }}>
              <FormattedMessage id="scheduledPickup" />
            </span>
          </div>
          {/* </Link> */}
        </div>
      );
    }
    if (mapMode === 'pickupASAP') {
      return (
        <div>
          <Link
            to={
              warehouse.warehouseType === 'website'
                ? 'pickup-menu'
                : `pickup-menu?warehouseId=${warehouse._id}`
            }
            // to={`${getCustomerPath(`
            //  store/${warehouse._id}`)}`}
            onClick={async () => {}}
          >
            <div
              className="action-button"
              css={{
                backgroundColor: '#ff4d4d',
              }}
            >
              <span css={{ fontWeight: '600' }}>
                <FormattedMessage id="map.menu" />
              </span>
            </div>
          </Link>
        </div>
      );
    }
    if (mapMode === 'deliveredASAP') {
      return (
        <div
          className="action-button"
          css={{
            backgroundColor: warehouse.isOpen === true ? '#ff4d4d' : '#bfbfbf',
          }}
          onClick={() => {
            if (warehouse.delivery) {
              handleOpenDeliveryModal();
            }
          }}
          role="presentation"
        >
          <FormattedMessage id="deliveredASAP" />
        </div>
      );
    }
    if (mapMode === 'scheduledDelivery') {
      return (
        <Link
          to={getCustomerPath(
            warehouse.warehouseType === 'website'
              ? 'supermarket'
              : `delivery-menu?warehouseId=${warehouse._id}`
          )}
        >
          <div
            className="action-button"
            css={{
              backgroundColor: '#ff4d4d',
            }}
            onClick={async () => {
              await updateOneUserAttribute(null, 'preselectPickupInfo');
              await updateOneUserAttribute(null, 'currentCampaignContentId');
            }}
            role="presentation"
          >
            <FormattedMessage id="scheduledDelivery" />
          </div>
        </Link>
      );
    }
    return null;
  };

  const hotDisplayMenu = () => {
    const priceRender = (e) => {
      if (
        e.originalPrice &&
        Number(e.originalPrice) !== 0 &&
        Number(e.originalPrice) !== Number(e.listPrice)
      ) {
        return (
          <span>
            <span css={{ color: 'red ' }}>${e.listPrice}</span>{' '}
            <del>${e.originalPrice}</del>
          </span>
        );
      }
      return `$${e.listPrice}`;
    };
    const scrollItem =
      warehouse.hotDisplayPickupVariants.map((variant) => (
        <div
          key={variant._id}
          css={{
            width: '150px',
            flexShrink: 0,
            marginRight: '10px',
            borderRadius: '3px',
            overflow: 'hidden',
          }}
        >
          <div
            css={{
              backgroundImage: `url(${BUCKET_CDN_URL}${variant.product.picture.key})`,
              width: '100%',
              paddingBottom: '75%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
              backgroundSize: 'cover',
            }}
          />
          <div>{variant.name[lang]}</div>
          <div>{priceRender(variant)}</div>
        </div>
      )) || [];
    return (
      scrollItem.length > 0 && (
        <div css={{ position: 'relative', paddingTop: '10px' }}>
          {!isMobile && (
            <LeftOutlined
              style={{
                position: 'absolute',
                left: '0px',
                color: 'black',
                top: '50px',
                fontSize: '20px',
              }}
              onClick={() => {
                if (sliderRef.current && sliderRef.current.scrollLeft > -1) {
                  sliderRef.current.scrollLeft -= window.innerWidth * 50;
                }
              }}
            />
          )}
          {!isMobile && (
            <RightOutlined
              style={{
                position: 'absolute',
                right: '0px',
                color: 'black',
                top: '50px',
                fontSize: '20px',
              }}
              onClick={() => {
                if (sliderRef.current && sliderRef.current.scrollLeft > -1) {
                  sliderRef.current.scrollLeft += window.innerWidth * 50;
                }
              }}
            />
          )}
          <div
            ref={sliderRef}
            css={{
              display: 'flex',
              WebkitOverflowScrolling: 'touch',
              scrollBehavior: 'smooth',
              overflowX: 'scroll',
              '::-webkit-scrollbar': { width: '0px !important' },
              margin: isMobile ? '0px' : '0px 20px',
            }}
          >
            {scrollItem}
          </div>
        </div>
      )
    );
  };

  return (
    <React.Fragment>
      {renderModal()}
      <div
        key={warehouse._id}
        id={warehouse._id}
        style={
          warehouseSelectors && warehouseSelectors[index]
            ? { backgroundColor: '#ededed' }
            : {}
        }
        onClick={() => {
          handleClickWarehouse(warehouse);
        }}
        role="presentation"
        css={{
          cursor: 'pointer',
          backgroundColor: 'white',
          margin: '10px',
          padding: '10px',
          display: 'flex',
          flexWrap: 'wrap',
          height: '100%',
          // backgroundImage: (() => {
          //   if (
          //     warehouse.isOpen &&
          //     mapMode !== 'scheduledPickup' &&
          //     mapMode !== 'scheduledDelivery'
          //   ) {
          //     return `url(${openIcon})`;
          //   }
          //   if (
          //     !warehouse.isOpen &&
          //     mapMode !== 'scheduledPickup' &&
          //     mapMode !== 'scheduledDelivery'
          //   ) {
          //     return `url(${closeIcon})`;
          //   }
          //   return 'none';
          // })(),
          backgroundPosition: 'top 10px right 10px',
          backgroundSize: '32px',
          backgroundRepeat: 'no-repeat',
          '& .information-area': {
            width: mapMode === 'pickupASAP' ? '100%' : 'calc( 100% - 100px )',
          },
          '& .action-area': {
            width: '85px',
            margin: 'auto',
          },
          '& .action-button': {
            // margin: '15px 0px',
            padding: '4px',
            width: '100%',
            borderRadius: '3px',
            color:
              warehouse.dineIn || warehouse.pickup || warehouse.delivery
                ? 'white'
                : 'white',
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '14px',
          },
        }}
      >
        <div className="information-area">
          <div
            css={{
              marginBottom: '10px',
              display: 'grid',
              grid: '1fr /1fr 100px',
            }}
          >
            <div>
              <div css={{ display: 'inline-block' }}>
                <span
                  css={{
                    display: 'inline-block',
                    '& img': { height: '30px', width: '30px' },
                  }}
                >
                  {typeIcon(warehouse.warehouseType)}
                </span>
              </div>
              <div css={{ display: 'inline-block' }}>
                <span
                  css={{
                    lineHeight: '1.4',
                    fontWeight: '600',
                    fontsize: '20px',
                    padding: '0 5px',
                  }}
                >
                  {warehouse.title ? warehouse.title[lang] : warehouse.name}
                </span>
              </div>
            </div>

            {mapMode === 'pickupASAP' && (
              <div>
                <Link
                  to={
                    warehouse.warehouseType === 'website'
                      ? 'pickup-menu'
                      : `pickup-menu?warehouseId=${warehouse._id}`
                  }
                  // to={`${getCustomerPath(`
                  //  store/${warehouse._id}`)}`}
                >
                  <div
                    className="action-button"
                    css={{
                      backgroundColor: '#ff4d4d',
                    }}
                  >
                    <span css={{ fontWeight: '600' }}>
                      <FormattedMessage id="map.menu" />
                    </span>
                  </div>
                </Link>
                {warehouse.delivery && (
                  <Link to={`delivery-menu?warehouseId=${warehouse._id}`}>
                    <div
                      className="action-button"
                      css={{
                        marginTop: '5px',
                        backgroundColor: '#ff4d4d',
                      }}
                    >
                      <span css={{ fontWeight: '600' }}>
                        <FormattedMessage id="map.lookupDeliveryMenu" />
                      </span>
                    </div>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div>
            {warehouse.distance ? (
              <div css={{ marginBottom: '5px', fontSize: '12px' }}>
                {warehouse.distance.toFixed(2)}
                {' Miles Away'}
              </div>
            ) : null}
            <div style={{ fontSize: '11px', lineHeight: '1.2' }}>
              <div>
                <span>
                  <EnvironmentOutlined />
                </span>{' '}
                {warehouse.addressOne}
              </div>
              <div>{warehouse.addressTwo}</div>
              <div>{`${warehouse.city}, ${warehouse.zip}`}</div>
            </div>
            {/* {openClose(warehouse)} */}
            {!history.location.search.includes('mode=pickupASAP') &&
              openTimeSlotRender(warehouse)}
            {history.location.search.includes('mode=pickupASAP') &&
              hotDisplayMenu()}
          </div>
          <div css={{ display: 'block', width: '100%' }}>
            <div
              style={{
                fontSize: '12px',
              }}
            >
              {warehouse.partnerName !== '' &&
              warehouse.partnerName !== undefined &&
              warehouse.partnerName !== null ? (
                <React.Fragment>
                  <span>
                    <FormattedMessage id="partnerWith" />{' '}
                  </span>
                  {warehouse.partnerName}
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
        <div
          // className={mapWindowPop === true ? 'action-area-map' : 'action-area'}
          className="action-area"
          css={{
            '& .action-button': {
              // margin: '15px 0px',
              padding: '4px',
              width: '100%',
              borderRadius: '3px',
              color:
                warehouse.dineIn || warehouse.pickup || warehouse.delivery
                  ? 'white'
                  : 'white',
              textAlign: 'center',
              fontWeight: '600',
              fontSize: '14px',
            },
          }}
        >
          {!shouldHidePickupButton(warehouse) && renderButton()}
          {/* {JSON.stringify(warehouse)} */}
          {/* <div css={{ textAlign: 'center' }}>{renderOpenClose()}</div> */}
        </div>
      </div>
    </React.Fragment>
  );
}

WarehouseCard.defaultProps = {};

WarehouseCard.propTypes = {
  warehouse: PropTypes.shape({
    _id: PropTypes.string,
    partnerName: PropTypes.string,
    name: PropTypes.string,
    dineIn: PropTypes.bool,
    pickup: PropTypes.bool,
    delivery: PropTypes.bool,
    city: PropTypes.string,
    zip: PropTypes.string,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    distance: PropTypes.number,
    warehouseType: PropTypes.string,
    title: PropTypes.shape({
      zh: PropTypes.string,
      en: PropTypes.string,
    }),
    isOpen: PropTypes.bool,
    hotDisplayPickupVariants: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          zh: PropTypes.string,
          en: PropTypes.string,
        }),
        listPrice: PropTypes.number,
        picture: PropTypes.shape({
          key: PropTypes.string,
          _id: PropTypes.string,
        }),
        product: PropTypes.shape({
          picture: PropTypes.shape({
            key: PropTypes.string,
            _id: PropTypes.string,
          }),
        }),
      })
    ),
  }).isRequired,
  warehouseSelectors: PropTypes.arrayOf(PropTypes.bool).isRequired,
  index: PropTypes.number.isRequired,
  handleClickWarehouse: PropTypes.func.isRequired,
  handleOpenDeliveryModal: PropTypes.func.isRequired,
  mapMode: PropTypes.string.isRequired,
};

export default WarehouseCard;
