import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Typography, Divider, Alert } from 'antd';
import {
  getCustomerPath,
  checkAlipayUserAgent,
  checkWeChatUserAgent,
} from '../../../util';
import './Desktop.less';
import GoogleSignInButton from './components/GoogleSignInButton';
import FacebookSignInButton from './components/FacebookSignInButton';
import SignInForm from './components/SignInForm';
import BenefitList from './components/BenefitList';

const { Title } = Typography;

export default function Desktop({
  pending,
  language,
  responseGoogle,
  responseFacebook,
  handleClickLoginButton,
  group,
  showGoogleSignUpNotice,
  getFieldDecorator,
  handleLoginAsGuest,
}) {
  return (
    <Row justify="center" align="middle" style={{ padding: '16px' }}>
      <Col style={{ maxWidth: '100%', width: '420px' }}>
        {checkWeChatUserAgent() === true && (
          <>
            <Alert
              type="warning"
              showIcon
              description={
                language === 'zh'
                  ? '您正在使用的浏览器不支持Google/Facebook账户登陆，如有需要请使用其他浏览器'
                  : "This browser does not support Google/Facebook Sign in, please use another browser if you'd liek to continue with your Google/Facebook account"
              }
            />
            <br />
          </>
        )}
        <Title level={2} style={{ fontSize: '1.32rem', textAlign: 'center' }}>
          {language === 'zh' ? '登陆' : 'Login'}
        </Title>
        <SignInForm
          handleClickLoginButton={handleClickLoginButton}
          language={language}
          getFieldDecorator={getFieldDecorator}
          pending={pending}
        />
        <Divider style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
          {language === 'zh' ? '首次来到 ' : 'New To '}BunBao.com?
        </Divider>

        <Link to={getCustomerPath('signup')}>
          <button type="button" className="sign-up custom-button">
            {language === 'zh' ? '创建一个账号' : 'Create an account'}
          </button>
        </Link>
        {checkAlipayUserAgent() !== true && checkWeChatUserAgent() !== true && (
          <>
            <Divider>{language === 'zh' ? '或者' : 'or'}</Divider>
            {showGoogleSignUpNotice && (
              <div
                css={{
                  textAlign: 'center',
                  margin: '10px 0',
                  fontSize: '16px',
                  color: 'red',
                }}
              >
                <FormattedMessage
                  id="emailDoesNotExist"
                  values={{
                    signUp: (
                      <Link to={getCustomerPath('signup')}>
                        <Button
                          type="primary"
                          size="small"
                          css={{ margin: '0px 2px' }}
                        >
                          {language === 'zh' ? '注册' : 'Sign Up'}
                        </Button>
                      </Link>
                    ),
                  }}
                />
              </div>
            )}
            <GoogleSignInButton
              language={language}
              responseGoogle={responseGoogle}
            />
            <FacebookSignInButton
              language={language}
              responseFacebook={responseFacebook}
            />
          </>
        )}

        <div style={{ marginTop: '16px' }}>
          <BenefitList language={language} />
        </div>
        {group && group.name === 'guest'
          ? null
          : !checkAlipayUserAgent() && (
              <Typography.Link
                style={{
                  float: 'left',
                  textDecoration: 'underline',
                }}
                onClick={handleLoginAsGuest}
              >
                {language === 'zh'
                  ? '不了，以游客身份继续'
                  : 'Continue as Guest without those benefits'}
              </Typography.Link>
            )}
      </Col>
    </Row>
  );
}

Desktop.defaultProps = {
  group: null,
};

Desktop.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  pending: PropTypes.bool.isRequired,
  responseGoogle: PropTypes.func.isRequired,
  responseFacebook: PropTypes.func.isRequired,
  handleClickLoginButton: PropTypes.func.isRequired,
  showGoogleSignUpNotice: PropTypes.bool.isRequired,
  handleLoginAsGuest: PropTypes.func.isRequired,
};
