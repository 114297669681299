import general from './general';
import others from './others';
import groupBuying from './groupBuying';
import about from './about';
import homepage from './homepage';
import serviceModal from './serviceModal';
import menu from './menu';
import catering from './catering';
import product from './product';
import message from './message';
import discount from './discount';
import payment from './payment';
import account from './account';
import dashboard from './dashboard';
import donation from './donation';
import map from './map';
import form from './form';
import vote from './vote';

const translation = { en: {}, zh: {} };

const translationFiles = {
  homepage,
  general,
  about,
  others,
  groupBuying,
  serviceModal,
  menu,
  catering,
  product,
  message,
  discount,
  payment,
  account,
  dashboard,
  map,
  donation,
  form,
  vote,
};

// support nested messages
const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
};

Object.keys(translationFiles).forEach((key) => {
  Object.assign(translation.en, flattenMessages(translationFiles[key].en));
  Object.assign(translation.zh, flattenMessages(translationFiles[key].zh));
});

export default translation;
