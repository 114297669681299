/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Divider, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { nanoid } from 'nanoid';
import { GiftOutlined, TagsOutlined, WalletOutlined } from '@ant-design/icons';
import * as actions from '../../../store/actions';
import { CUSTOMER_ORDER_CREATE } from '../../../api/customerOrder';
import { CHECK_VARIANTS_VALIDATION } from '../../../api/variant';
import { APPLY_RULE, FETCH_SHIPPING_RULE } from '../../../api/rule';
import {
  // processOrderForSaleor,
  getCustomerPath,
  // saleorClient,
  apolloClient,
  checkAlipayUserAgent,
} from '../../../util';
import { customerOrderFormatter } from '../../../helper/Formatter/Formatter';
import {
  blockCssObjectTypeOne,
  borderCssObjectTypeOne,
} from '../../SmallComponents/SmallCssSample';
import Loading from '../../Others/Loading/Loading';

export class OrderValidationModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: false,
      rules: [],
      notLogin: false,
      invalidVariants: false,
      checkVariantsValidation: [],
      hideGuestButton: false,
      isEShopPath: false,
    };
  }

  componentDidMount = async () => {
    const {
      currentShoppingMode,
      validationPassedCallback,
      token,
      location,
      group,
    } = this.props;
    // check path and shop
    const { pathname } = location;
    if (pathname === getCustomerPath('eshop')) {
      await this.setState({
        hideGuestButton: true,
        isEShopPath: true,
      });
    }

    if (!token) {
      this.setState({ notLogin: true });
      return;
    }
    if (group.name === 'guest' && pathname === getCustomerPath('eshop')) {
      this.setState({ notLogin: true });
      return;
    }
    console.log(currentShoppingMode);
    // if (currentShoppingMode === 'pickup') {
    // await validationPassedCallback();
    // } else
    if (currentShoppingMode === 'donation') {
      await validationPassedCallback();
    } else {
      await this.createFakeOrder();
    }
  };

  checkVariantsValidation = async () => {
    const { getCurrentWarehouseCart } = this.props;
    const shoppingCart = await getCurrentWarehouseCart();
    const shoppingCartVariantIds = shoppingCart.productList.map(
      (item) => item._id
    );
    try {
      const {
        data: { checkVariantsValidation },
      } = await apolloClient.query({
        query: CHECK_VARIANTS_VALIDATION,
        variables: {
          variantIds: shoppingCartVariantIds,
          shoppingMode: 'supermarket',
        },
      });
      console.log(checkVariantsValidation);
      await this.setState({
        checkVariantsValidation,
        invalidVariants:
          checkVariantsValidation.length !== shoppingCart.productList.length,
      });
      return checkVariantsValidation.length !== shoppingCart.productList.length;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  validShoppingCart = async () => {
    const { updateWarehouseCart, getCurrentWarehouseCart } = this.props;
    const { checkVariantsValidation } = this.state;
    const shoppingCart = await getCurrentWarehouseCart();
    shoppingCart.productList = shoppingCart.productList.filter((item) =>
      checkVariantsValidation.includes(item._id)
    );
    await updateWarehouseCart(shoppingCart);
  };

  createFakeOrder = async () => {
    const {
      // shoppingCart,
      getCurrentWarehouseCart,
      intl,
      validationPassedCallback,
      currentShoppingMode,
      isScheduledDelivery,
      currentWarehouseId,
      history: {
        location: { pathname },
      },
    } = this.props;
    console.log(pathname);
    const shoppingCart = await getCurrentWarehouseCart();
    const shoppingList = _.concat(
      shoppingCart.productList,
      shoppingCart.boxList
    );
    const { token } = this.props;
    if (token) {
      if (
        currentShoppingMode === 'delivery' ||
        currentShoppingMode === 'pickup'
      ) {
        await this.setState({ loading: true });
        if (pathname.includes('supermarket')) {
          const isInvalid = await this.checkVariantsValidation();
          if (isInvalid) {
            return;
          }
        }
        const finalInput = {
          variantInput: customerOrderFormatter(shoppingList),
          realMode: false,
        };
        console.log('final input for draft order', finalInput);
        console.log('final shoppingList', finalInput);
        try {
          // create fake order
          const { data } = await apolloClient.mutate({
            mutation: CUSTOMER_ORDER_CREATE,
            variables: finalInput,
          });
          const orderId = data.orderCreateCustomerOrder._id;
          if (orderId) {
            // message.success(orderId)
            // fetch rules
            const {
              data: { ruleValidateOrder },
            } = await apolloClient.query({
              query: APPLY_RULE,
              variables: {
                orderId,
                isScheduledDelivery,
                shoppingMode: currentShoppingMode,
                warehouseId: currentWarehouseId,
              },
              fetchPolicy: 'network-only',
            });
            if (ruleValidateOrder === true) {
              message.success('success');
              await validationPassedCallback();
              return;
            }
            const {
              data: { ruleFindMany },
            } = await apolloClient.query({
              query: FETCH_SHIPPING_RULE,
              variables: {
                filter:
                  currentShoppingMode === 'pickup'
                    ? { warehouseId: currentWarehouseId }
                    : {
                        name:
                          isScheduledDelivery === false
                            ? 'twentyFourDelivery'
                            : 'scheduledDelivery',
                      },
              },
              fetchPolicy: 'network-only',
            });
            const rules = [];
            ruleFindMany.forEach((rule) => {
              rules.push(rule.description);
            });
            await this.setState({ loading: false, rules });
            return;
          }
          message.error('error happened creating order', 15);
        } catch (error) {
          console.error(error);
          const errorMsg =
            intl.formatMessage({ id: 'errorValidatingOrder' }) +
            intl.formatMessage({ id: 'plsContactUs' });
          message.error(errorMsg, 15);
        }
      } else {
        await validationPassedCallback();
        return;
      }
    } else {
      this.setState({ notLogin: true });
      return;
    }
    this.setState({ loading: false });
  };

  handleClickButton = async (path = 'login') => {
    const {
      saveLinkForLogin,
      currentShoppingMode,
      history,
      isScheduledDelivery,
      location,
    } = this.props;
    if (currentShoppingMode === 'delivery' && !isScheduledDelivery) {
      await saveLinkForLogin(getCustomerPath('supermarket?cart=true'));
    } else {
      const { pathname, search } = location;
      if (search) {
        if (search.search('cart=true') === -1) {
          await saveLinkForLogin(`${pathname + search}&cart=true`);
        } else {
          await saveLinkForLogin(`${pathname + search}`);
        }
      } else {
        await saveLinkForLogin(`${pathname}?cart=true`);
      }
    }
    if (path === 'login') {
      history.push(getCustomerPath('login'));
    } else {
      history.push(getCustomerPath(path));
    }
  };

  handleLoginAsGuest = async () => {
    const { guestAuth, getCurrentUser } = this.props;
    // try to login
    try {
      await guestAuth();
      await getCurrentUser();
      await this.createFakeOrder();
    } catch (error) {
      message.error('Can not login, please try again');
    }
  };

  render() {
    const { loading, rules, notLogin, invalidVariants, isEShopPath } =
      this.state;
    const { language, handleCloseValidationModal, currentShoppingMode } =
      this.props;

    const renderRules = () => {
      return rules.map((rule, index) => {
        return (
          <div key={nanoid(5)}>
            <div
              onClick={async () => {
                await handleCloseValidationModal();
              }}
              css={blockCssObjectTypeOne}
              role="presentation"
            >
              {rule[language]}
            </div>
            {index !== rules.length - 1 ? (
              <Divider>
                <FormattedMessage id="orOr" />
              </Divider>
            ) : null}
          </div>
        );
      });
    };
    if (invalidVariants) {
      return (
        <div css={borderCssObjectTypeOne}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: isMobile ? '14px' : '16px',
            }}
          >
            <FormattedMessage id="invalidVariants" />
          </div>

          <div
            role="presentation"
            onClick={async () => {
              // this.setState({
              //   invalidVariants: false,
              // });
              const { getCurrentWarehouseCart } = this.props;
              await this.validShoppingCart();
              const shoppingCart = await getCurrentWarehouseCart();
              if (
                shoppingCart.productList?.length ||
                shoppingCart.boxList?.length
              ) {
                await this.createFakeOrder();
              }
              handleCloseValidationModal();
            }}
            css={blockCssObjectTypeOne}
          >
            <FormattedMessage id="confirm" />
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div>
          <Loading replaceMsg="checkingOrder" />
        </div>
      );
    }
    if (notLogin) {
      return (
        <div css={borderCssObjectTypeOne}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: isMobile ? '14px' : '16px',
            }}
          >
            <FormattedMessage id="loginRedirecting" />
          </div>
          <div css={{ fontWeight: 'bold', textAlign: 'center' }}>
            <div>
              <GiftOutlined />{' '}
              {language === 'zh'
                ? '享受专属优惠折扣'
                : 'Receive exclusive coupons and rewards'}
            </div>
            <div>
              <TagsOutlined />{' '}
              {language === 'zh'
                ? '随时随地管理您的历史订单'
                : 'Manage your past and present orders'}
            </div>
            <div>
              <WalletOutlined />{' '}
              {language === 'zh'
                ? '安全保存您的地址和支付方式，更快更便捷'
                : 'Easier checkout with your saved payment method and shipping address'}
            </div>
          </div>
          <div
            role="presentation"
            onClick={async () => {
              await this.handleClickButton();
            }}
            css={{
              width: '100%',
              fontWeight: '700',
              padding: '0px 15px',
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: isMobile ? '14px' : '16px',
              borderRadius: '5px',
              borderRight: '3px solid white',
              border: '1px solid #DF242F',
              backgroundColor: '#DF242F',
              color: 'white',
              marginRight: '5px',
            }}
          >
            <FormattedMessage id="login" />
          </div>
          <div
            role="presentation"
            onClick={async () => {
              await this.handleClickButton('signup');
            }}
            css={{
              width: '100%',
              fontWeight: '700',
              padding: '0px 15px',
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: isMobile ? '14px' : '16px',
              borderRadius: '5px',
              borderRight: '3px solid white',
              border: '1px solid #DF242F',
              backgroundColor: '#DF242F',
              color: 'white',
              marginRight: '5px',
              marginTop: '10px',
            }}
          >
            <FormattedMessage id="signUp" />
          </div>
          {!isEShopPath && !checkAlipayUserAgent() && (
            <React.Fragment>
              <div css={{ fontWeight: 'bold', textAlign: 'center' }}>
                {language === 'zh'
                  ? '游客模式不享受任何福利和优惠'
                  : 'Guest will not valid any benefits'}
              </div>
              <div
                role="presentation"
                onClick={async () => {
                  await this.handleLoginAsGuest();
                }}
                css={{
                  width: '100%',
                  fontWeight: '700',
                  padding: '0px 15px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontSize: isMobile ? '14px' : '16px',
                  borderRadius: '5px',
                  borderRight: '3px solid white',
                  border: '1px solid #DF242F',
                  backgroundColor: 'white',
                  color: '#DF242F',
                  marginRight: '5px',
                }}
              >
                <FormattedMessage id="loginAsGuest" />
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }

    if (currentShoppingMode === 'delivery') {
      return (
        <div css={borderCssObjectTypeOne}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: isMobile ? '14px' : '16px',
            }}
          >
            <FormattedMessage id="deliveryRule" />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'lighter',
              fontSize: isMobile ? '12px' : '14px',
            }}
          >
            <FormattedMessage id="plsFillOneOfThese" />
          </div>
          {renderRules()}
        </div>
      );
    }
    if (currentShoppingMode === 'pickup') {
      return (
        <div css={borderCssObjectTypeOne}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: isMobile ? '14px' : '16px',
            }}
          >
            {language === 'zh' ? '自取规则' : 'Pickup Rules'}
          </div>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'lighter',
              fontSize: isMobile ? '12px' : '14px',
            }}
          >
            <FormattedMessage id="plsFillOneOfThese" />
          </div>
          {renderRules()}
        </div>
      );
    }
    return null;
  }
}

OrderValidationModal.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  saveLinkForLogin: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  validationPassedCallback: PropTypes.func.isRequired,
  handleCloseValidationModal: PropTypes.func.isRequired,
  token: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
  currentShoppingMode: PropTypes.string,
  currentWarehouseId: PropTypes.string.isRequired,
  updateWarehouseCart: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  guestAuth: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

OrderValidationModal.defaultProps = {
  token: null,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  token: state.auth.token,
  group: state.user.group,
  currentShoppingMode: state.user.currentShoppingMode,
  isScheduledDelivery: state.warehouse.isScheduledDelivery,
  currentWarehouseId: state.warehouse.warehouseId,
});

const mapDispatchToProps = (dispatch) => ({
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  guestAuth: () => dispatch(actions.guestAuth()),
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(OrderValidationModal)));
