/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Button, Tooltip } from 'antd';
import { isMobile } from 'react-device-detect';
import VIP from '../../assets/images/vip.png';
// import * as actions from '../../../store/actions';
import { USER_CURRENT } from '../../api/user';
import { apolloClient } from '../../util';

export class ComboSelection extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isVip: false,
    };
  }

  componentDidMount = async () => {
    const isVip = await this.isUserVIP();
    await this.setState({ isVip });
  };

  isUserVIP = async () => {
    try {
      const {
        data: { userCurrent },
      } = await apolloClient.query({
        query: USER_CURRENT,
        variables: {},
        fetchPolicy: 'network-only',
      });
      if (userCurrent.isVip === true || userCurrent.isVip === false) {
        return userCurrent.isVip;
      }
    } catch (error) {
      return false;
    }
    return true;
  };

  render() {
    const { collectionsList, handleSelectCollection, intl, language } =
      this.props;
    const { isVip } = this.state;
    console.log('collectionsList', collectionsList);

    // const renderDiscountPercentage = (collection) => {
    //   if (collection.container.discountPercentage < 1) {
    //     return (
    //       <div>
    //         减价{' '}
    //         {((1 - collection.container.discountPercentage) * 100).toFixed(2)}%
    //       </div>
    //     );
    //   }
    //   if (collection.container.discountPercentage > 1) {
    //     return (
    //       <div>
    //         加价{' '}
    //         {((collection.container.discountPercentage - 1) * 100).toFixed(2)}%
    //       </div>
    //     );
    //   }
    //   return <div css={{ height: '22px' }} />;
    // };

    const renderPriceNotices = (collection) => {
      if (collection?.container?.discountPercentage < 1) {
        return (
          <div>
            减价{' '}
            {((1 - collection.container.discountPercentage) * 100).toFixed(2)}%
          </div>
        );
      }
      if (collection?.container?.discountPercentage > 1) {
        return (
          <div>
            {language === 'zh' ? (
              <div>
                小盒包装，价格增{' '}
                {((collection.container.discountPercentage - 1) * 100).toFixed(
                  0
                )}
                %，介意勿选！
              </div>
            ) : (
              <div>
                We charge an extra{' '}
                {((collection.container.discountPercentage - 1) * 100).toFixed(
                  0
                )}
                % for 4pc pack due to packaging cost.
              </div>
            )}
          </div>
        );
      }
      return <div css={{}} />;
    };

    return (
      <Row
        type="flex"
        justify="space-between"
        gutter={[5, 20]}
        css={{ marginTop: '30px' }}
      >
        {collectionsList.map((collection, index) => (
          <Col key={collection._id} span={isMobile ? 10 : 5}>
            <img
              style={{ width: '100%' }}
              src={
                collection.backgroundImage
                  ? collection.backgroundImage.url
                  : null
              }
              alt={collection.title}
              onClick={() => {
                handleSelectCollection(collection, index);
              }}
              role="presentation"
            />
            <Row
              type="flex"
              justify="start"
              align="middle"
              css={{ textAlign: 'center' }}
            >
              <Col
                style={{
                  fontsize: '25px',
                  fontWeight: 'bold',
                  margin: '5px 0',
                }}
                span={24}
              >
                {collection.title === 'mix-and-match' ? (
                  <img style={{ width: '16px' }} src={VIP} alt="vip" />
                ) : null}{' '}
                {language === 'zh' ? collection.name.zh : collection.name.en}
              </Col>
              {/* <Col span={24}>{renderDiscountPercentage(collection)}</Col> */}
            </Row>
            {collection.title === 'mix-and-match' && !isVip ? (
              <Tooltip title={intl.formatMessage({ id: 'vipRule' })}>
                <Button css={{ width: '100%' }}>
                  <FormattedMessage id="view" />
                </Button>
              </Tooltip>
            ) : (
              <Button
                css={{ width: '100%' }}
                type="primary"
                onClick={() => {
                  handleSelectCollection(collection, index);
                }}
              >
                <FormattedMessage id="select" />
              </Button>
            )}
            <Row>
              <Col span={24} css={{ textAlign: 'center' }}>
                {renderPriceNotices(collection)}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }
}

ComboSelection.propTypes = {
  language: PropTypes.string.isRequired,
  collectionsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelectCollection: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

ComboSelection.defaultProps = {};

const mapStateToProps = (state) => ({
  saleorId: state.auth.saleorId,
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(ComboSelection);
