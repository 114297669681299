/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CheckOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Button, notification } from 'antd';
import FormBuilder from 'antd-form-builder';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { USER_RESET_PASSWORD_BY_PASS_CODE_AND_USER_ID } from '../../../api/user';
import { apolloClient, getCustomerPath } from '../../../util';
import './ResetPasswordForm.less';

function ResetPasswordForm(props) {
  const { intl } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const history = useHistory();

  const handleSubmit = async (values) => {
    const { userId, code } = props;
    setUpdated(true);
    const { password } = values;
    console.log(password, code, userId);
    try {
      const updatePasswordResponse = await apolloClient.mutate({
        mutation: USER_RESET_PASSWORD_BY_PASS_CODE_AND_USER_ID,
        variables: {
          userId,
          newPassword: password,
          code,
        },
      });
      console.log(updatePasswordResponse);
      notification.open({
        message: intl.formatMessage({ id: 'successResetPassword' }),
        description: intl.formatMessage({ id: 'usingNewPassword' }),
        icon: <CheckOutlined style={{ color: 'green' }} />,
        duration: 0,
        placement: 'bottomRight',
      });
      history.push(getCustomerPath('login'));
      // console.log('Submit: ', form.getFieldsValue());
    } catch (error) {
      setUpdated(false);
    }
  };

  const meta = {
    columns: 1,
    fields: [
      {
        key: 'password',
        widget: 'password',
        placeholder: intl.formatMessage({ id: 'newPassword' }),
        disabled: updated,
        widgetProps: {
          prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
        },
        onChange: () => {
          if (form.isFieldTouched('confirmPassword')) {
            form.validateFields(['confirmPassword']);
          }
        },
        rules: [
          // This is equivalent with "required: true"
          {
            required: true,
            message: 'Password is required',
          },
        ],
      },
      {
        key: 'confirmPassword',
        widget: 'password',
        placeholder: intl.formatMessage({ id: 'confirmPassword' }),
        required: true,
        disabled: updated,
        widgetProps: {
          prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
        },
        rules: [
          {
            validator: (rule, value, callback) => {
              if (value !== form.getFieldValue('password')) {
                callback(new Error('Two passwords are inconsistent.'));
              } else {
                callback();
              }
            },
          },
        ],
      },
    ],
  };
  return (
    <div>
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="reset-password-form"
        >
          <FormBuilder form={form} meta={meta} />
          <Form.Item>
            <Button
              disabled={updated}
              type="primary"
              htmlType="submit"
              className="custom-button"
            >
              <FormattedMessage id="resetPassword" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

ResetPasswordForm.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  code: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default injectIntl(ResetPasswordForm);
