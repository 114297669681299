/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';

export class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return <div css={{ minHeight: '100vh' }}>Campaigns</div>;
  }
}

export default Campaigns;
