import gql from 'graphql-tag';

const COUPON_FRAGMENT = gql`
  fragment couponFragment on Coupon {
    _id
    isFoodTruckOrder
    isCateringOrder
    deleted
    code
    couponType
    couponValue
    createdAt
    updatedAt
  }
`;
export const FETCH_COUPON = gql`
  query ($filter: FilterFindManyCouponInput) {
    couponFindMany(filter: $filter) {
      ...couponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

export const FETCH_COUPON_BY_CODE = gql`
  query ($code: String!) {
    couponFindByCode(code: $code) {
      ...couponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;
export const FETCH_COUPON_PAGINATION = gql`
  query ($page: Int!, $perPage: Int!, $filter: FilterFindManyCouponInput) {
    couponPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...couponFragment
      }
    }
  }
  ${COUPON_FRAGMENT}
`;

export const CREATE_COUPON = gql`
  mutation ($input: CreateOneCouponInput!) {
    couponCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const CREATE_STRIPE_COUPON = gql`
  mutation ($input: CreateOneCouponInput!) {
    couponCreateStripeOne(input: { record: $input })
  }
`;
export const COUPON_DELETE_ONE = gql`
  mutation ($Id: MongoID!) {
    couponDeleteOne(_id: $Id)
  }
`;

export const UPDATE_COUPON = gql`
  mutation ($input: UpdateOneCouponInput!, $_id: MongoID) {
    couponUpdateOne(input: { record: $input, filter: { _id: $_id } }) {
      record {
        _id
      }
    }
  }
`;
