/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FormBuilder from 'antd-form-builder';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import {
  Button,
  Row,
  Col,
  message,
  Modal,
  Form,
  Typography,
  Divider,
  Alert,
} from 'antd';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';
import {
  getCustomerPath,
  apolloClient,
  checkAlipayUserAgent,
  checkWeChatUserAgent,
} from '../../../util';
import * as actions from '../../../store/actions';
import { SUBSCRIBER_CREATE, SUBSCRIBER_TAG } from '../../../api/subscriber';
import { BANNER_FIND_MANY } from '../../../api/banner';
import {
  USER_CREATE,
  USER_FIND_ONE,
  USER_ADD_INVITATION_CODE,
  USER_FIND_BY_FILTER,
  USER_FIND_BY_ID,
} from '../../../api/user';
import BenefitList from '../Login/components/BenefitList';
import GoogleSignInButton from '../Login/components/GoogleSignInButton';
import FacebookSignInButton from '../Login/components/FacebookSignInButton';
import './SignUp.less';

const { Title } = Typography;

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: true,
      slides: [],
      pending: false,
      confirmDirty: false,
      invitationCode: null,
      inviter: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount = async () => {
    const {
      location: { search },
    } = this.props;
    const { invitationCode } = QueryString.parse(search);
    await this.setState({ invitationCode });
    // console.log(invitationCode, invitationCode.length);
    await this.handleFetchBanner();
    if (invitationCode && invitationCode.length === 24) {
      console.log('fetch inviter');
      await this.handleFetchInviter();
    } else if (invitationCode) {
      await this.handleCheckInvitationCodeForFM();
    }
    await this.setState({ loading: false });
  };

  handleFetchBanner = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'login' } },
      });
      // console.log(bannerFindMany);
      // sort banner
      const rawData = bannerFindMany;
      const slides = _.orderBy(rawData, ['ranking'], ['desc']);
      console.log('slides', slides);
      this.setState({ slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  handleFetchInviter = async () => {
    const { invitationCode } = this.state;
    try {
      const {
        data: { userById },
      } = await apolloClient.query({
        query: USER_FIND_BY_ID,
        variables: { id: invitationCode },
        fetchPolicy: 'network-only',
      });
      if (userById) {
        await this.setState({ inviter: userById });
        const form = this.formRef.current;
        form.setFieldsValue({
          invitationCode,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleCheckInvitationCodeForFM = async () => {
    const { invitationCode } = this.state;
    try {
      const {
        data: { userOne },
      } = await apolloClient.query({
        query: USER_FIND_BY_FILTER,
        variables: {
          filter: {
            invitationCodeForFarmersMarket: invitationCode,
            isEnableInvitationCodeForFarmersMarket: true,
          },
        },
      });
      console.log(userOne);
      if (userOne) {
        const form = this.formRef.current;
        form.setFieldsValue({
          invitationCode,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    const { intl } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(intl.formatMessage({ id: 'isConsistent' }));
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  handleClickSignUpButton = async (values) => {
    const { getCurrentUser, intl, isFarmerMarketUser } = this.props;
    let invitationCode;
    const { invitationCode: initialInvitationCode } = this.state;
    this.setState({ pending: true });
    const { auth, history, loginRedirectPage } = this.props;
    const { email, password, invitationCode: formInvitationCode } = values;
    if (initialInvitationCode) {
      invitationCode = initialInvitationCode;
    } else {
      invitationCode = formInvitationCode;
    }

    const copyLink = JSON.parse(JSON.stringify(loginRedirectPage));
    // let showModal = false;
    // create new user
    try {
      const {
        data: { userCreateOne },
      } = await apolloClient.mutate({
        mutation: USER_CREATE,
        variables: {
          email,
          password,
          groupType: 'customer',
          invitationCode:
            invitationCode && invitationCode.length === 24
              ? null
              : invitationCode,
          fromWebsite: true,
        },
      });
      console.log(userCreateOne);
      try {
        await apolloClient.mutate({
          mutation: SUBSCRIBER_CREATE,
          variables: { email },
        });
        if (invitationCode && invitationCode.length !== 24) {
          // tag it
          await apolloClient.mutate({
            mutation: SUBSCRIBER_TAG,
            variables: {
              email,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      if (invitationCode && invitationCode.length === 24) {
        await this.addInvitationCodeAndShowProp(email);
      }
      await auth(email, password);
      await getCurrentUser();
      if (copyLink.length > 2) {
        // jump to last path
        history.push(copyLink);
        const { saveLinkForLogin } = this.props;
        // remove last path
        saveLinkForLogin('');
      }
      // check fm user or not, then jump to supermarket menu
      if (isFarmerMarketUser) {
        history.push(getCustomerPath('supermarket?special=true'));
      } else {
        history.push(getCustomerPath('supermarket'));
      }
    } catch (error) {
      console.error(error);
      this.setState({ pending: false });
      const { language } = this.props;

      if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].message === 'fakeEmailError'
      ) {
        Modal.warning({
          content: intl.formatMessage({ id: 'fakeEmailError' }),
        });
      } else if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].message === 'invitationCodeInvalid'
      ) {
        Modal.warning({
          content:
            language === 'zh' ? '邀请码不正确' : 'Invitation Code Invalid',
        });
      } else {
        message.error('Can not sign up, please try again');
      }
    }
  };

  addInvitationCodeAndShowProp = async (email) => {
    const { history } = this.props;
    const { invitationCode } = this.state;
    if (invitationCode) {
      try {
        const {
          data: { userAddInvitationCode },
        } = await apolloClient.mutate({
          mutation: USER_ADD_INVITATION_CODE,
          variables: {
            email,
            invitationCode,
          },
        });
        if (userAddInvitationCode.personalDiscountIds.length > 0) {
          Modal.success({
            content: 'You have a new coupon!',
            onOk: () => {
              history.push(getCustomerPath('account/personal-coupon'));
            },
          });
        }
      } catch (error) {
        console.error(error);
        message.error('error happened adding invitation code');
      }
    }
  };

  responseGoogle = async (response) => {
    if (!response.error) {
      const form = this.formRef.current;
      const { oauth2, getCurrentUser, isFarmerMarketUser, history } =
        this.props;
      const { invitationCode: initialInvitationCode } = this.state;
      console.log(form.getFieldValue('invitationCode'));
      const formInvitationCode = form.getFieldValue('invitationCode');
      let invitationCode;
      if (initialInvitationCode) {
        invitationCode = initialInvitationCode;
      } else {
        invitationCode = formInvitationCode;
      }
      // try to auth
      try {
        await oauth2('google', response.profileObj.email, response.tokenId);
        try {
          await apolloClient.mutate({
            mutation: SUBSCRIBER_CREATE,
            variables: { email: response.profileObj.email },
          });
        } catch (error) {
          console.error(error);
        }
        try {
          if (invitationCode) {
            // tag it
            await apolloClient.mutate({
              mutation: SUBSCRIBER_TAG,
              variables: {
                email: response.profileObj.email,
              },
            });
            if (invitationCode.length === 24) {
              await this.addInvitationCodeAndShowProp(
                response.profileObj.email
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
        await getCurrentUser();
        if (isFarmerMarketUser) {
          history.push(getCustomerPath('supermarket?special=true'));
        } else {
          history.push(getCustomerPath('supermarket'));
        }
      } catch (error) {
        message.error('Can not login, please try again');
      }
    } else {
      console.log(response);
    }
  };

  responseFacebook = async (response) => {
    const form = this.formRef.current;
    const { oauth2, getCurrentUser, history, isFarmerMarketUser } = this.props;
    const { invitationCode: initialInvitationCode } = this.state;
    console.log(form.getFieldValue('invitationCode'));
    const formInvitationCode = form.getFieldValue('invitationCode');
    let invitationCode;
    if (initialInvitationCode) {
      invitationCode = initialInvitationCode;
    } else {
      invitationCode = formInvitationCode;
    }
    // try to auth
    try {
      await oauth2('facebook', response.email, response.accessToken);
      try {
        await apolloClient.mutate({
          mutation: SUBSCRIBER_CREATE,
          variables: { email: response.email },
        });
      } catch (error) {
        console.error(error);
      }
      try {
        if (invitationCode) {
          // tag it
          await apolloClient.mutate({
            mutation: SUBSCRIBER_TAG,
            variables: {
              email: response.email,
            },
          });
          if (invitationCode.length === 24) {
            await this.addInvitationCodeAndShowProp(response.email);
          }
        }
      } catch (error) {
        console.error(error);
      }
      await getCurrentUser();
      if (isFarmerMarketUser) {
        history.push(getCustomerPath('supermarket?special=true'));
      } else {
        history.push(getCustomerPath('supermarket'));
      }
    } catch (error) {
      message.error('Can not login, please try again');
    }
  };

  render() {
    const { language, intl } = this.props;
    const { slides, pending, loading, inviter, invitationCode } = this.state;
    const device = isMobile ? 'mobile' : 'desktop';
    const meta = {
      columns: 1,
      fields: [
        {
          key: 'email',
          hasFeedback: true, // Show validation status icon in the right
          required: true, // this adds an entry to rules: [{ required: true, message: 'Username is required' }]
          widgetProps: {
            prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
            className: 'data-hj-whitelist',
          },
          placeholder: intl.formatMessage({ id: 'email' }),
          rules: [
            {
              validator: async (rule, value) => {
                try {
                  const {
                    data: { userOne },
                  } = await apolloClient.query({
                    query: USER_FIND_ONE,
                    variables: { filter: { email: value } },
                    fetchPolicy: 'network-only',
                  });
                  if (userOne) {
                    return Promise.reject(
                      intl.formatMessage({ id: 'emailExists' })
                    );
                    // throw new Error(intl.formatMessage({ id: 'emailExists' }))
                  }
                } catch (error) {
                  console.error(error);
                  /* eslint-disable-next-line */
                  return Promise.reject('Please try again');
                }
                return null;
              },
            },
            { type: 'email', message: 'Invalid email address' },
          ],
        },
        {
          key: 'password',
          widget: 'password',
          onChange: () => {
            const form = this.formRef.current;
            if (form.isFieldTouched('confirmPassword')) {
              form.validateFields(['confirmPassword']);
            }
          },
          widgetProps: {
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
          },
          placeholder: intl.formatMessage({ id: 'password' }),
          rules: [
            // This is equivalent with "required: true"
            {
              required: true,
              min: 6,
              message: intl.formatMessage({ id: 'plsPassword' }),
            },
            {
              required: true,
              message: 'Password is required',
            },
          ],
        },
        {
          key: 'confirmPassword',
          widget: 'password',
          required: true,
          widgetProps: {
            prefix: <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
          },
          placeholder: intl.formatMessage({ id: 'confirmPassword' }),
          rules: [
            {
              validator: (rule, value, callback) => {
                const form = this.formRef.current;
                if (value !== form.getFieldValue('password')) {
                  callback(new Error('Two passwords are inconsistent.'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
        {
          key: 'invitationCode',
          widgetProps: {
            prefix: <SoundOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
          },
          placeholder: intl.formatMessage({ id: 'invitationCode' }),
          initialValue: invitationCode,
          disabled: !!inviter || invitationCode === 'fm2020',
        },
      ],
    };

    return (
      <Row
        style={{
          minHeight: 'calc(100vh - 172px)',
        }}
        align="top"
        justify="center"
      >
        <Col>
          <Row justify="center" align="middle" style={{ padding: '16px' }}>
            <Col style={{ maxWidth: '100%', width: '420px' }}>
              {checkWeChatUserAgent() === true && (
                <>
                  <Alert
                    type="warning"
                    showIcon
                    description={
                      language === 'zh'
                        ? '您正在使用的浏览器不支持Google/Facebook账户登陆，如有需要请使用其他浏览器'
                        : "This browser does not support Google/Facebook Sign in, please use another browser if you'd liek to continue with your Google/Facebook account"
                    }
                  />
                  <br />
                </>
              )}
              <Title
                level={2}
                style={{ fontSize: '1.32rem', textAlign: 'center' }}
              >
                {language === 'zh' ? '创建新账号' : 'Sign Up'}
              </Title>
              <Form
                ref={this.formRef}
                onFinish={this.handleClickSignUpButton}
                onValuesChange={() => this.forceUpdate()}
                className="sign-up-form"
              >
                <FormBuilder form={this.formRef} meta={meta} />
                <Form.Item>
                  <Button
                    css={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                    disabled={pending}
                    className="custom-button"
                  >
                    {pending ? (
                      <React.Fragment>
                        <LoadingOutlined />{' '}
                      </React.Fragment>
                    ) : null}
                    <FormattedMessage id="signUp" />
                  </Button>
                </Form.Item>
              </Form>
              <Divider style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                {language === 'zh'
                  ? '已经有BunBao.com的账号了？'
                  : 'Have a BunBao.com account?'}
              </Divider>

              <Link to={getCustomerPath('login')}>
                <button type="button" className="sign-up custom-button">
                  {language === 'zh' ? '登陆' : 'Login'}
                </button>
              </Link>
              {checkAlipayUserAgent() !== true &&
                checkWeChatUserAgent() !== true && (
                  <>
                    <Divider>{language === 'zh' ? '或者' : 'or'}</Divider>
                    <GoogleSignInButton
                      language={language}
                      responseGoogle={this.responseGoogle}
                    />
                    <FacebookSignInButton
                      language={language}
                      responseFacebook={this.responseFacebook}
                    />
                  </>
                )}
              <div style={{ marginTop: '16px' }}>
                <BenefitList language={language} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

SignUp.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  auth: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  loginRedirectPage: PropTypes.string.isRequired,
  saveLinkForLogin: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  oauth2: PropTypes.func.isRequired,
  isFarmerMarketUser: PropTypes.bool,
};

SignUp.defaultProps = {
  isFarmerMarketUser: false,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  loginRedirectPage: state.auth.loginRedirectPage,
  isFarmerMarketUser: state.user.isFarmerMarketUser,
});

const mapDispatchToProps = (dispatch) => ({
  auth: (email, password) => dispatch(actions.auth(email, password)),
  saveLinkForLogin: (input) => dispatch(actions.saveLinkForLogin(input)),
  getCurrentUser: () => {
    dispatch(actions.getCurrentUser());
  },
  oauth2: (type, email, accessToken) =>
    dispatch(actions.oauth2(type, email, accessToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignUp));
