// import React from 'react';
// import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';
// import { notification, Button } from 'antd';
import { WEB_PUSH_PUBLISH_KEY } from './config';
// import { apolloClient } from './util';
// import { SUBSCRIBER_CREATE_NOTIFICATION } from './api/subscriber';

const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = Buffer.from(base64, 'base64').toString('binary');
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const pushServerPublicKey = urlB64ToUint8Array(WEB_PUSH_PUBLISH_KEY());

const isServiceWorkerSupported = () => {
  return 'serviceWorker' in navigator;
};

/**
 * checks if Push notification and service workers are supported by your browser
 */
const isPushNotificationSupported = () => {
  return 'PushManager' in window;
};

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
const askUserPermission = async () => {
  const result = await Notification.requestPermission();
  return result;
};
/**
 * shows a notification
 */
// const sendNotification = () => {
//   const img = '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg';
//   const text = 'Take a look at this brand new t-shirt!';
//   const title = 'New Product Available';
//   const options = {
//     body: text,
//     icon: '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg',
//     vibrate: [200, 100, 200],
//     tag: 'new-product',
//     image: img,
//     badge: 'https://spyna.it/icons/android-icon-192x192.png',
//     actions: [
//       {
//         action: 'Detail',
//         title: 'View',
//         icon: 'https://via.placeholder.com/128/ff0000',
//       },
//     ],
//   };
//   navigator.serviceWorker.ready.then((serviceWorker) => {
//     serviceWorker.showNotification(title, options);
//   });
// };

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              );
              window.location.reload();
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

/**
 *
 */
const registerServiceWorker = () => {
  // return navigator.serviceWorker
  //   .register('/service-worker.js')
  //   .then((registration) => {
  //     registration.onupdatefound = () => {
  //       const installingWorker = registration.installing;
  //       if (installingWorker == null) {
  //         return;
  //       }
  //       installingWorker.onstatechange = () => {
  //         if (installingWorker.state === 'installed') {
  //           if (navigator.serviceWorker.controller) {
  //             console.log(
  //               'New content is available and will be used when all ' +
  //                 'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
  //             );
  //           } else {
  //             console.log('Content is cached for offline use.');
  //           }
  //         }
  //       };
  //     };
  //   })
  //   .catch((error) => {
  //     console.error('Error during service worker registration:', error);
  //   });
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return;
  }

  window.addEventListener('load', () => {
    const swFile =
      process.env.NODE_ENV === 'production' ? 'sw.js' : 'sw-dev.js';
    const swUrl = `${process.env.PUBLIC_URL}/${swFile}`;

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.log(
          'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://bit.ly/CRA-PWA'
        );
      });
    } else {
      // Is not localhost. Just register service worker
      registerValidSW(swUrl);
    }
  });
};

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
const createNotificationSubscription = async () => {
  // wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  const result = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey,
  });
  return result;
};

/**
 * returns the subscription if present or nothing
 */
const getUserSubscription = () => {
  // wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then((serviceWorker) => {
      return serviceWorker.pushManager.getSubscription();
    })
    .then((pushSubscription) => {
      return pushSubscription;
    })
    .catch((error) => {
      console.error(error);
    });
};

// const showNotification = async () => {
//   // ask permission
//   const consent = await askUserPermission();
//   if (consent !== 'granted') {
//     console.error('Consent Denied');
//   } else {
//     try {
//       // create subscription
//       const subscription = await createNotificationSubscription();
//       // send subscription to server
//       const response = await apolloClient.mutate({
//         mutation: SUBSCRIBER_CREATE_NOTIFICATION,
//         variables: {
//           subscription,
//         },
//       });
//       console.log(response);
//     } catch (error) {
//       console.error(error);
//     }
//   }
// };

const pushNotification = async () => {
  if (isServiceWorkerSupported()) {
    try {
      await registerServiceWorker();
      if (isPushNotificationSupported()) {
        const userSubscription = await getUserSubscription();
        if (!userSubscription) {
          // const close = () => {
          //   console.log(
          //     'Notification was closed. Either the close button was clicked or duration time elapsed.'
          //   );
          // };
          // const key = `open${Date.now()}`;
          // const btn = (
          //   <>
          //     <Button
          //       style={{ marginRight: '10px' }}
          //       type="primary"
          //       size="small"
          //       onClick={() => {
          //         notification.close(key);
          //         showNotification();
          //       }}
          //     >
          //       <CheckOutlined /> Turn On
          //     </Button>
          //     <Button
          //       size="small"
          //       onClick={() => {
          //         notification.close(key);
          //       }}
          //     >
          //       <ClockCircleOutlined /> Not Now
          //     </Button>
          //   </>
          // );
          if (
            Notification.permission !== 'denied' ||
            Notification.permission === 'default'
          ) {
            // notification.open({
            //   message: 'Turn on Notifications',
            //   description: (
            //     <div>
            //       Know right away when order is shipping or new products
            //       arrived.
            //     </div>
            //   ),
            //   btn,
            //   key,
            //   onClose: close,
            //   duration: 30,
            //   placement: 'bottomRight',
            // });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export {
  pushNotification,
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
};
