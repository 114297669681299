/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerPath } from '../../../util';
import * as actions from '../../../store/actions';
import bunbaoLogo from '../../../assets/images/logo.png';
import ballon from '../../../assets/images/homepage/balloon.svg';
// import PropTypes from 'prop-types';

function CustomerScheduledDeliveryModal() {
  const history = useHistory();

  const dispatch = useDispatch();
  const updateOneUserAttribute = (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName));
  const setUserAddressType = (input) =>
    dispatch(actions.setUserAddressType(input));
  const setUserShoppingMode = (mode) =>
    dispatch(actions.setUserShoppingMode(mode));

  const language = useSelector((state) => state.language.lang);

  const handleSelectWebsiteDelivery = async () => {
    await setUserAddressType('delivery');
    await setUserShoppingMode('delivery');
    await updateOneUserAttribute(null, 'preselectPickupInfo');
    await updateOneUserAttribute(null, 'currentCampaignContentId');
    history.push(getCustomerPath('delivery-menu'));
  };

  // const handleSelectMapDelivery = async () => {
  //   await updateOneUserAttribute(null, 'preselectPickupInfo');
  //   await updateOneUserAttribute(null, 'currentCampaignContentId');
  //   history.push(getCustomerPath('map?mode=scheduledDelivery'));
  // };

  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        userSelect: 'none',
        '& .delivery-mode-block': {
          margin: '10px',
          padding: '10px 10px 10px 10px',
          // background: 'linear-gradient(15deg, #ffffff, #e6e6e6)',
          boxShadow: '10px 10px 25px #8a8a8a, -10px -10px 25px #ffffff',
          borderRadius: '4px',
          color: 'black',
          fontSize: '18px',
          fontWeight: 'bold',
          display: 'flex',
          // flexDirection: 'column',
          ':hover': {
            background: 'linear-gradient(190deg, #e6e6e6, #ffffff)',
            boxShadow:
              'inset 10px 10px 25px #8a8a8a, inset -10px -10px 25px #ffffff',
          },
          '& img': {
            display: 'block',
            height: '64px',
            margin: 'auto',
          },
          '& .service-title': {
            textAlign: 'center',
            padding: language === 'zh' ? '15px' : '0px',
          },
        },
      }}
    >
      <div
        onClick={() => {
          handleSelectWebsiteDelivery();
        }}
        role="presentation"
        className="delivery-mode-block"
      >
        <div>
          <img src={bunbaoLogo} alt="BunBao Logo" />
        </div>
        <div>BunBao Menu</div>
      </div>
      <div
        onClick={() => {
          // handleSelectMapDelivery();
        }}
        role="presentation"
        className="delivery-mode-block"
      >
        <div css={{ width: '128px' }}>
          <img src={ballon} alt="Ballon" />
        </div>
        <div>
          <div>Farmers Market Menu</div>
          <div>Coming Soon</div>
        </div>
      </div>
    </div>
  );
}

CustomerScheduledDeliveryModal.propTypes = {};

export default CustomerScheduledDeliveryModal;
