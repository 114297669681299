import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../containers/Foundation/Login/Login';
import Logout from '../containers/Logout/Logout';
// import SignUp from '../containers/Foundation/SignUp/SignUp';
import Home from '../containers/Foundation/Home/Home';
import Customer from '../containers/Customer/Customer';
import Loading from '../components/Others/Loading/Loading';

// const Customer = React.lazy(() => import('../containers/Customer/Customer'));
const Dashboard = React.lazy(() => import('../containers/Dashboard/Dashboard'));
const Point = React.lazy(() => import('../containers/Point/Point'));

const DevelopmentRoutes = (token, group) => {
  if (token && group !== null) {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/customer" component={Customer} />
          <Route path="/point" component={Point} />
          <Route exact path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/customer" component={Customer} />
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/signup" component={SignUp} /> */}
        <Route path="/point" component={Point} />
        <Redirect from="/dashboard" to="/login" />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

const FoundationRoutes = (token, group, host) => {
  if (token && group !== null) {
    if (host === 'catering.bunbao.com') {
      return (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/logout" component={Logout} />
            <Route
              exact
              path="/bunbao-catering"
              component={() => {
                window.location.href = 'https://bunbao.com/business';
                return null;
              }}
            />
            <Redirect to="/dashboard" />
          </Switch>
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dashboard" component={Dashboard} />
          <Route exact path="/logout" component={Logout} />
          <Redirect from="/dashboard" to="/login" />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
  if (host === 'catering.bunbao.com') {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/signup" component={SignUp} /> */}
          <Redirect from="/dashboard" to="/login" />
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/signup" component={SignUp} /> */}
        <Redirect from="/dashboard" to="/login" />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

const CustomerRoutes = (token, group) => {
  if (token && group !== null) {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/" component={Customer} />
          <Route exact path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/" component={Customer} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

const PointRoutes = (token, group) => {
  if (token && group !== null) {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/" component={Point} />
          <Route exact path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
};

export { DevelopmentRoutes, FoundationRoutes, CustomerRoutes, PointRoutes };
