import React from 'react';
import {
  Pagination as Pag,
  Button,
  message,
  Modal,
  Form,
  Radio,
  Space,
  Alert,
  Select,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import SwiperCore, { Pagination } from 'swiper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  DeleteOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import convert from 'htmr';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { convertRawToHTML } from 'braft-convert';
import TextLoop from 'react-text-loop';
import { apolloClient, getCustomerPath } from '../../../util';
import * as actions from '../../../store/actions';
import {
  VOTE_CAMPAIGN_BY_ID,
  VOTE_POST_PAGINATION,
  VOTE_POST_UPDATE,
  VOTE_POST_CREATE_ONE,
  VOTE_POST_FIND_ONE,
  VOTE_POST_UPDATE_ONE,
  VOTE_LOG_FIND_MANY,
} from '../../../api/vote';
import { WAREHOUSE_FIND_ONE } from '../../../api/warehouse';
import { BUCKET_URL } from '../../../config';
import ImageTags from '../../../components/Others/ImageTags/ImageTags';
import PictureUploader from '../../../components/Others/PictureUploader/PictureUploader';
import shareIcon from '../../../assets/images/icons/share.png';
import Like from '../../../assets/images/icons/like.png';

import './VoteCampaign.less';
import 'swiper/swiper.less';
/* eslint-disable import/no-extraneous-dependencies */
import 'swiper/components/pagination/pagination.less';

const { Option } = Select;

const mosaic = (email) => {
  const res = email.split('@');
  if (res.length >= 2) {
    return `${email.substring(0, 3)}***@${res[1]}`;
  }
  return `${email.substring(0, 3)}***`;
};

SwiperCore.use([Pagination]);

const PAGE_SIZE = 12;

class VoteCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      voteCampaignObj: null,
      votePostArray: [],
      totalPosts: 0,
      uploadModalVisible: false,
      unSignInModalVisible: false,
      radioFilter: 'total',
      voteConfirmVisible: false,
      campaignRuleVisible: false,
      selectedVotePostId: null,
      selectedVotePost: null,
      currentUserPost: null,
      postStatusModal: false,
      votedPostModal: false,
      canNotVoteModal: false,
      updatedVotePost: null,
      canNotVoteByDateLimitModal: false,
      canNotCreateByDateLimitModal: false,
      picturesArray: [],
      currentSlideIndex: 0,
      productSearchArray: [],
      allowedVariants: [],
      isDragging: false,
      voteLogs: [],
      mobilePage: 2,
    };
    this.formRef = React.createRef();
    this.trashBinRef = React.createRef();
    this.dragParentContainer = React.createRef();
  }

  async componentDidMount() {
    const {
      location: { search },
      token,
    } = this.props;
    if (search && search.includes('?voteCampaignId=')) {
      const voteCampaignId = search.replace('?voteCampaignId=', '');
      await this.fetchVoteCampaignById(voteCampaignId);
      await this.fetchVotePostPagination();
      await this.fetchCurrentUserPost();
      await this.handleFetchVoteLogs();
    }
    // check token and pending vote post id
    if (token) {
      const pendingVotePostId = await sessionStorage.getItem(
        'pendingVotePostId'
      );
      if (pendingVotePostId) {
        try {
          const { votePostArray } = this.state;
          const selectedVotePost = votePostArray.find(
            (post) => post._id === pendingVotePostId
          );
          this.setState({
            selectedVotePost,
            selectedVotePostId: pendingVotePostId,
            voteConfirmVisible: true,
          });
        } catch (error) {
          console.error(error);
        }
        await sessionStorage.removeItem('pendingVotePostId');
      }
    }
  }

  refreshPage = async () => {
    await this.fetchVotePostPagination();
    await this.fetchCurrentUserPost();
  };

  handleFetchVoteLogs = async () => {
    const { voteCampaignObj } = this.state;
    const response = await apolloClient.query({
      query: VOTE_LOG_FIND_MANY,
      variables: {
        filter: { voteCampaignId: voteCampaignObj._id },
        limit: 20,
        sort: 'CREATEDAT_DESC',
      },
    });
    console.log(response);
    const {
      data: { voteLogMany },
    } = response;
    const voteLogs = voteLogMany.map((log) => {
      return {
        email: mosaic(log.user.email),
        amount: log.discount.amount,
        voteCounter: log.voteCounter,
        valueType: log.discount.valueType,
        votePost: log.votePost,
      };
    });
    this.setState({ voteLogs });
  };

  fetchCurrentUserPost = async () => {
    const { voteCampaignObj } = this.state;
    const { userId } = this.props;
    try {
      const {
        data: { votePostFindOne },
      } = await apolloClient.query({
        query: VOTE_POST_FIND_ONE,
        variables: {
          filter: {
            voteCampaignId: voteCampaignObj._id,
            userId,
          },
        },
        fetchPolicy: 'network-only',
      });
      console.log('currentUserPost', votePostFindOne);
      this.setState({
        currentUserPost: votePostFindOne,
      });
    } catch (err) {
      console.log(err);
    }
  };

  fetchVoteCampaignById = async (id) => {
    try {
      const {
        data: { voteCampaignById },
      } = await apolloClient.query({
        query: VOTE_CAMPAIGN_BY_ID,
        variables: { id },
      });
      console.log(voteCampaignById);
      this.setState({
        voteCampaignObj: voteCampaignById,
      });
    } catch (err) {
      console.log(err);
    }
  };

  fetchVotePostPagination = async (page = 1) => {
    const { voteCampaignObj, radioFilter } = this.state;
    try {
      let sort = 'DISPLAYID_ASC';
      if (radioFilter === 'total') {
        sort = 'TOTALVOTES_DESC';
      }
      const {
        data: { votePostPagination },
      } = await apolloClient.query({
        query: VOTE_POST_PAGINATION,
        variables: {
          page,
          perPage: PAGE_SIZE,
          filter: { voteCampaignId: voteCampaignObj._id, status: 'pass' },
          sort,
        },
        fetchPolicy: 'network-only',
      });
      console.log(votePostPagination);
      switch (radioFilter) {
        case 'total':
          votePostPagination.items.sort((a, b) => b.totalVotes - a.totalVotes);
          break;
        case 'displayId':
          votePostPagination.items.sort((a, b) => a.displayId - b.displayId);
          break;
        default:
          break;
      }
      this.setState({
        votePostArray: votePostPagination.items,
        totalPosts: votePostPagination.count,
      });
    } catch (err) {
      console.log(err);
    }
  };

  mobileScrollFetch = async (page) => {
    const { voteCampaignObj, radioFilter, votePostArray, mobilePage } =
      this.state;
    try {
      let sort = 'DISPLAYID_ASC';
      if (radioFilter === 'total') {
        sort = 'TOTALVOTES_DESC';
      }
      const {
        data: { votePostPagination },
      } = await apolloClient.query({
        query: VOTE_POST_PAGINATION,
        variables: {
          page,
          perPage: PAGE_SIZE,
          filter: { voteCampaignId: voteCampaignObj._id, status: 'pass' },
          sort,
        },
        fetchPolicy: 'network-only',
      });
      console.log(votePostPagination);
      const totalArray = votePostArray.concat(votePostPagination.items);
      console.log(totalArray);
      switch (radioFilter) {
        case 'total':
          totalArray.sort((a, b) => b.totalVotes - a.totalVotes);
          break;
        case 'displayId':
          totalArray.sort((a, b) => a.displayId - b.displayId);
          break;
        default:
          break;
      }
      this.setState({
        votePostArray: totalArray,
        totalPosts: votePostPagination.count,
        mobilePage: mobilePage + 1,
      });
    } catch (err) {
      console.log(err);
    }
  };

  fetchVoteTotal = async () => {
    // const {}
  };

  onPaginationChange = async (page) => {
    console.log(page);
    await this.fetchVotePostPagination(page);
  };

  handleCheckUrl = () => {
    if (process.env.NODE_ENV !== 'production') {
      return 'localhost:3222/customer';
    }
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'test.bunbao.com';
    }
    return 'bunbao.com';
  };

  handleCloseVoteConfirm = async () => {
    this.setState({
      voteConfirmVisible: false,
    });
  };

  handleShareButton = async (id) => {
    const { lang } = this.props;
    const { voteCampaignObj } = this.state;
    const shareLink = `https://${this.handleCheckUrl()}/vote-post?votePostId=${id}`;

    if (navigator.share) {
      if (lang === 'zh') {
        navigator
          .share({
            title: voteCampaignObj.shareTitle
              ? voteCampaignObj.shareTitle[lang]
              : '我在棒!包晒美食，晒美食赢取大礼',
            text: voteCampaignObj.shareSubtitle
              ? voteCampaignObj.shareSubtitle[lang]
              : '我在棒!包晒美食，晒美食赢取大礼',
            url: shareLink,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        navigator
          .share({
            title: voteCampaignObj.shareTitle
              ? voteCampaignObj.shareTitle[lang]
              : 'BunBao.com voting',
            text: voteCampaignObj.shareSubtitle
              ? voteCampaignObj.shareSubtitle[lang]
              : 'BunBao.com voting',
            url: shareLink,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    }
    let newShareLink = voteCampaignObj.shareTitle
      ? `${voteCampaignObj.shareTitle[lang]}\n${shareLink}`
      : `BunBao.com Voting\n${shareLink}`;
    if (lang === 'zh') {
      newShareLink = voteCampaignObj.shareTitle
        ? `${voteCampaignObj.shareTitle[lang]}\n${shareLink}`
        : `我我在棒!包晒美食，晒美食赢取大礼 \n${shareLink}`;
    }
    await navigator.clipboard.writeText(newShareLink);
    message.success(
      lang === 'zh' ? '分享链接已经复制' : 'The share link is copied'
    );
  };

  handleVoteButton = async (votePostId) => {
    const { votePostArray } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { votePostUpdate },
      } = await apolloClient.mutate({
        mutation: VOTE_POST_UPDATE,
        variables: {
          votePostId,
        },
      });
      console.log(votePostUpdate);
      votePostArray.forEach((item) => {
        if (item._id === votePostUpdate.votePost._id) {
          item.totalVotes = votePostUpdate.votePost.totalVotes;
        }
      });
      this.setState({
        votePostArray: [...votePostArray],
        updatedVotePost: votePostUpdate,
        votedPostModal: true,
      });
    } catch (err) {
      console.dir(err);
      const error = err.graphQLErrors.length && err.graphQLErrors[0].message;
      if (error === 'Please Log in') {
        message.error(error);
        history.push(getCustomerPath(`login?votePostId=${votePostId}`));
      }
      if (error === 'Invalid Vote') {
        this.setState({
          canNotVoteModal: true,
        });
      }
      if (error === 'Can not vote by date limit') {
        this.setState({ canNotVoteByDateLimitModal: true });
      }
    }
    await this.handleCloseVoteConfirm();
  };

  checkForLogin = () => {
    const { token } = this.props;
    return !!token;
  };

  createVotePostSubmit = async (values) => {
    const { voteCampaignObj, currentUserPost, picturesArray } = this.state;
    const { intl } = this.props;
    const { description, pictures, title, nickName } = values;
    const pictureIds = pictures.map((item) => item.response.pictureId);
    const pictureTags = picturesArray.map((item) => {
      return {
        picture: {
          pictureId: item.response.pictureId,
          key: item.response.url,
        },
        tags: [...item.tags],
      };
    });
    const input = {
      record: {
        pictureIds,
        description,
        title,
        nickName,
        pictureTags,
      },
    };
    console.log(values, pictureTags);
    if (!currentUserPost) {
      input.record.voteCampaignId = voteCampaignObj._id;
      try {
        const {
          data: { votePostCreateOne },
        } = await apolloClient.mutate({
          mutation: VOTE_POST_CREATE_ONE,
          variables: {
            input,
          },
        });
        console.log(votePostCreateOne);
        this.setState({
          uploadModalVisible: false,
        });
        this.refreshPage();
      } catch (err) {
        console.log(err);
        const error = err.graphQLErrors.length && err.graphQLErrors[0].message;
        if (error === 'Duplicated') {
          message.error(intl.formatMessage({ id: 'vote.duplicateVotePost' }));
        } else if (error === 'Can not create post by date limit') {
          this.setState({
            canNotCreateByDateLimitModal: true,
            uploadModalVisible: false,
          });
        } else {
          message.error(error || 'ERROR');
        }
      }
    } else {
      console.log('Need to update');
      input.record._id = currentUserPost._id;
      try {
        const {
          data: { votePostUpdateById },
        } = await apolloClient.mutate({
          mutation: VOTE_POST_UPDATE_ONE,
          variables: {
            input,
          },
        });
        console.log(votePostUpdateById);
        this.setState({
          uploadModalVisible: false,
        });
        this.refreshPage();
      } catch (err) {
        console.log(err);
      }
    }
  };

  redirectToLoginPage = async () => {
    const { history, saveLinkForLogin } = this.props;
    const { voteCampaignObj } = this.state;
    if (!voteCampaignObj) {
      return;
    }
    await saveLinkForLogin(
      getCustomerPath(`vote-campaign?voteCampaignId=${voteCampaignObj._id}`)
    );
    history.push(getCustomerPath(`login`));
  };

  normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleViewMyPost = () => {
    const { history } = this.props;
    const { currentUserPost } = this.state;
    if (currentUserPost.status === 'pass') {
      history.push(
        getCustomerPath(`vote-post?votePostId=${currentUserPost._id}`)
      );
      return;
    }
    setTimeout(() => {
      this.formRef.current.setFieldsValue({
        title: currentUserPost.title,
        description: currentUserPost.description,
        nickName: currentUserPost.nickName,
      });
      if (
        currentUserPost.pictures.length > 0 &&
        currentUserPost.pictureTags.length === 0
      ) {
        this.formRef.current.setFieldsValue({
          pictures:
            (currentUserPost.pictures &&
              currentUserPost.pictures.map((pic) => {
                return {
                  uid: pic._id,
                  name: pic.key,
                  status: 'done',
                  response: { pictureId: pic._id, url: pic.key },
                  url: BUCKET_URL + pic.key,
                };
              })) ||
            [],
        });
      }
      if (currentUserPost.pictureTags.length > 0) {
        const fileList = currentUserPost.pictureTags.map((pictureTag) => {
          return {
            uid: pictureTag.picture._id,
            name: pictureTag.picture.key,
            status: 'done',
            response: {
              pictureId: pictureTag.picture.pictureId,
              url: pictureTag.picture.key,
            },
            url: BUCKET_URL + pictureTag.picture.key,
            tags: pictureTag.tags.map((tag) => {
              return {
                direction: tag.direction,
                key: tag.key,
                label: tag.label,
                value: tag.value,
                x: tag.x,
                y: tag.y,
              };
            }),
          };
        });
        this.setState({
          picturesArray: fileList,
        });
        this.formRef.current.setFieldsValue({ pictures: fileList });
      }
      // this.setState({
      //   picturesArray:
      //     (currentUserPost.pictures &&
      //       currentUserPost.pictures.map((pic) => {
      //         return {
      //           uid: pic._id,
      //           name: pic.key,
      //           status: 'done',
      //           response: { pictureId: pic._id },
      //           url: BUCKET_URL + pic.key,
      //         };
      //       })) ||
      //     [],
      // });
    }, 300);
    this.setState({
      uploadModalVisible: true,
      postStatusModal: true,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  searchTags = async (value) => {
    const { allowedVariants } = this.state;
    if (!allowedVariants.length) {
      const filter = {
        warehouseType: 'website',
      };
      try {
        const {
          data: { warehouseOne },
        } = await apolloClient.query({
          query: WAREHOUSE_FIND_ONE,
          variables: {
            filter,
          },
        });
        // console.log(warehouseOne.allowedVariants);
        this.setState({
          allowedVariants: warehouseOne.allowedVariants || [],
        });
      } catch (err) {
        console.log(err);
      }
    }
    await this.setState({
      productSearchArray: allowedVariants.filter((item) => {
        const str = value.toLowerCase();
        const en = (item.name.en && item.name.en.toLowerCase()) || '';
        const zh = (item.name.zh && item.name.zh.toLowerCase()) || '';
        return en.includes(str) || zh.includes(str);
      }),
    });
  };

  render() {
    const {
      voteCampaignObj,
      votePostArray,
      totalPosts,
      uploadModalVisible,
      unSignInModalVisible,
      radioFilter,
      voteConfirmVisible,
      campaignRuleVisible,
      selectedVotePost,
      currentUserPost,
      postStatusModal,
      canNotVoteModal,
      votedPostModal,
      updatedVotePost,
      canNotVoteByDateLimitModal,
      canNotCreateByDateLimitModal,
      picturesArray,
      currentSlideIndex,
      productSearchArray,
      isDragging,
      voteLogs,
      mobilePage,
    } = this.state;
    const { lang, token } = this.props;
    if (!voteCampaignObj) {
      return null;
    }

    const Arrow = ({ type, style, className, onClick }) => (
      <div
        style={{
          ...style,
          transform: type === 'right' ? 'rotate(-45deg)' : 'rotate(135deg)',
          border: 'solid rgba(0, 0, 0, 0.65)',
          borderWidth: '0 3px 3px 0',
          display: 'inline-block',
          padding: '3px',
        }}
        role="presentation"
        className={className}
        onClick={onClick}
      />
    );
    Arrow.defaultProps = { style: null, className: null, onClick: null };
    Arrow.propTypes = {
      type: PropTypes.string.isRequired,
      style: PropTypes.shape({}),
      className: PropTypes.string,
      onClick: PropTypes.func,
    };

    const meta = {
      formItemLayout: [4, 20],
      fields: [
        {
          key: 'title',
          label: <FormattedMessage id="vote.title" />,
          widget: 'input',
          required: true,
        },
        {
          key: 'description',
          label: <FormattedMessage id="vote.description" />,
          widget: 'textarea',
          required: true,
        },
        {
          key: 'nickName',
          label: <FormattedMessage id="vote.nickName" />,
          widget: 'input',
          required: true,
        },
        {
          key: 'pictures',
          label: <FormattedMessage id="vote.picture" />,
          widget: PictureUploader,
          required: true,
          widgetProps: {
            imageLimit: voteCampaignObj.imageLimit,
            category: 'vote',
          },
          getValueFromEvent: this.normFile,
          valuePropName: 'fileList',
        },
      ],
    };
    return (
      <div className="vote-campaign-space">
        <Modal
          visible={canNotVoteByDateLimitModal}
          onCancel={() => {
            this.setState({ canNotVoteByDateLimitModal: false });
          }}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                this.setState({ canNotVoteByDateLimitModal: false });
              }}
            >
              {lang === 'zh' ? '好的' : 'OK'}
            </Button>,
          ]}
        >
          <div>
            {convert(
              convertRawToHTML(
                JSON.parse(
                  voteCampaignObj.canNotVoteNoticeByDateLimit[lang] || '{}'
                )
              )
            )}
          </div>
        </Modal>
        <Modal
          visible={canNotCreateByDateLimitModal}
          onCancel={() => {
            this.setState({ canNotCreateByDateLimitModal: false });
          }}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                this.setState({ canNotCreateByDateLimitModal: false });
              }}
            >
              {lang === 'zh' ? '好的' : 'OK'}
            </Button>,
          ]}
        >
          <div>
            {convert(
              convertRawToHTML(
                JSON.parse(
                  voteCampaignObj.canNotCreateNoticeByDateLimit[lang] || '{}'
                )
              )
            )}
          </div>
        </Modal>
        <Modal
          visible={votedPostModal}
          onCancel={() => {
            this.setState({ votedPostModal: false });
          }}
          footer={
            currentUserPost
              ? [
                  <Button
                    key="ok"
                    type="primary"
                    onClick={() => {
                      this.setState({ votedPostModal: false });
                    }}
                  >
                    {lang === 'zh' ? '好的' : 'OK'}
                  </Button>,
                ]
              : [
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({
                        votedPostModal: false,
                        uploadModalVisible: true,
                      });
                    }}
                  >
                    {lang === 'zh' ? '我要参加' : 'I want to Post'}
                  </Button>,
                  <Button
                    key="ok"
                    type="primary"
                    onClick={() => {
                      this.setState({ votedPostModal: false });
                    }}
                  >
                    {lang === 'zh' ? '好的' : 'OK'}
                  </Button>,
                ]
          }
          destroyOnClose
        >
          {updatedVotePost && (
            <div>
              <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                <CheckCircleTwoTone twoToneColor="green" />{' '}
                {lang === 'zh' ? '投票成功' : 'Voted'}
              </div>
              {updatedVotePost.discount === null ? (
                <div>{voteCampaignObj.votedNoticeWithoutBonus[lang]}</div>
              ) : (
                <div>
                  {currentUserPost ? (
                    <div>
                      {voteCampaignObj.votedNotice
                        ? convert(
                            convertRawToHTML(
                              JSON.parse(
                                voteCampaignObj.votedNoticeWithPosted[lang] ||
                                  '{}'
                              )
                            )
                              .replace(
                                '[voteCouponValue]',
                                updatedVotePost.discount.valueType ===
                                  'percentage'
                                  ? `${updatedVotePost.discount.amount}%`
                                  : `$${updatedVotePost.discount.amount}`
                              )
                              .replace(
                                '[userTodayVotedCounter]',
                                updatedVotePost.todayVotedCounter
                              )
                          )
                        : 'some messages...some messages...'}
                    </div>
                  ) : (
                    <div>
                      {voteCampaignObj.votedNotice
                        ? convert(
                            convertRawToHTML(
                              JSON.parse(
                                voteCampaignObj.votedNotice[lang] || '{}'
                              )
                            )
                              .replace(
                                '[voteCouponValue]',
                                updatedVotePost.discount.valueType ===
                                  'percentage'
                                  ? `${updatedVotePost.discount.amount}%`
                                  : `$${updatedVotePost.discount.amount}`
                              )
                              .replace(
                                '[userTodayVotedCounter]',
                                updatedVotePost.todayVotedCounter
                              )
                          )
                        : 'some messages...some messages...'}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Modal>
        <Modal
          visible={canNotVoteModal}
          onCancel={() => {
            this.setState({ canNotVoteModal: false });
          }}
          footer={
            currentUserPost
              ? [
                  <Button
                    key="ok"
                    type="primary"
                    onClick={() => {
                      this.setState({ canNotVoteModal: false });
                    }}
                  >
                    {lang === 'zh' ? '好的' : 'OK'}
                  </Button>,
                ]
              : [
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({
                        canNotVoteModal: false,
                        uploadModalVisible: true,
                      });
                    }}
                  >
                    {lang === 'zh' ? '我要参加' : 'I want to Post'}
                  </Button>,
                  <Button
                    key="ok"
                    type="primary"
                    onClick={() => {
                      this.setState({ canNotVoteModal: false });
                    }}
                  >
                    {lang === 'zh' ? '好的' : 'OK'}
                  </Button>,
                ]
          }
        >
          <div>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>
              <CloseCircleTwoTone twoToneColor="red" />{' '}
              <FormattedMessage id="vote.inValidVote" />
            </div>
            {currentUserPost
              ? convert(
                  convertRawToHTML(
                    JSON.parse(
                      voteCampaignObj.canNotVoteNoticeWithPosted[lang] || '{}'
                    )
                  )
                )
              : convert(
                  convertRawToHTML(
                    JSON.parse(voteCampaignObj.canNotVoteNotice[lang] || '{}')
                  )
                )}
          </div>
        </Modal>
        <Modal
          visible={campaignRuleVisible}
          onCancel={() => {
            this.setState({ campaignRuleVisible: false });
          }}
          onOk={() => {
            this.setState({ campaignRuleVisible: false });
          }}
        >
          <div>
            {convert(
              convertRawToHTML(
                JSON.parse(voteCampaignObj.campaignRule[lang] || '{}')
              )
            )}
          </div>
        </Modal>
        <Modal
          visible={voteConfirmVisible}
          onCancel={this.handleCloseVoteConfirm}
          okText={token ? 'OK' : <FormattedMessage id="loginOrSignUp" />}
          onOk={async () => {
            if (token) {
              const { selectedVotePostId } = this.state;
              await this.handleVoteButton(selectedVotePostId);
            } else {
              this.redirectToLoginPage();
            }
          }}
        >
          {token ? (
            <div>
              {voteCampaignObj.voteAskNotice
                ? voteCampaignObj.voteAskNotice[lang]
                    .replace(
                      '[votePostTitle]',
                      selectedVotePost && selectedVotePost.title
                        ? selectedVotePost.title
                        : ''
                    )
                    .replace(
                      '[votePostNickname]',
                      selectedVotePost && selectedVotePost.nickName
                        ? selectedVotePost.nickName
                        : ''
                    )
                : 'Are you sure vote this post?'}
            </div>
          ) : (
            <div>
              {voteCampaignObj.voteAskNoticeWithoutLogin
                ? convert(
                    convertRawToHTML(
                      JSON.parse(
                        voteCampaignObj.voteAskNoticeWithoutLogin[lang] || '{}'
                      )
                    )
                  )
                : 'We remember you vote, but you should login or sign up, click button to sign up or login'}
            </div>
          )}
        </Modal>
        <Modal
          destroyOnClose
          visible={unSignInModalVisible}
          footer={null}
          onCancel={() => {
            this.setState({
              unSignInModalVisible: false,
            });
          }}
        >
          <div style={{ textAlign: 'center', padding: '10px 0px' }}>
            <FormattedMessage id="loginRedirectingContinue" />
          </div>
          <Button
            danger
            style={{ width: '100%' }}
            onClick={async () => {
              await this.redirectToLoginPage();
            }}
          >
            <FormattedMessage id="login" />
          </Button>
        </Modal>
        <Modal
          destroyOnClose
          visible={postStatusModal}
          closable={false}
          maskClosable={false}
          title={null}
          footer={
            <Button
              danger
              onClick={() => {
                if (currentUserPost.status !== 'needChange') {
                  this.setState({
                    uploadModalVisible: false,
                    postStatusModal: false,
                  });
                } else if (currentUserPost.status === 'needChange') {
                  this.setState({
                    postStatusModal: false,
                  });
                }
              }}
            >
              OK
            </Button>
          }
          centered
          zIndex={1010}
        >
          {currentUserPost && currentUserPost.status !== 'pass' && (
            <div>
              <div>
                <FormattedMessage id="vote.postStatusTip" />{' '}
                {currentUserPost.status}
              </div>
              {currentUserPost.reason && (
                <div>
                  <FormattedMessage id="vote.postReason" />{' '}
                  {currentUserPost.reason}
                </div>
              )}
            </div>
          )}
        </Modal>
        <Modal
          destroyOnClose
          maskClosable={false}
          visible={uploadModalVisible}
          footer={null}
          onCancel={() => {
            this.setState({
              uploadModalVisible: false,
            });
          }}
        >
          <div className="vote-post-modal">
            {voteCampaignObj.uploadWindowNotice ? (
              <div style={{ margin: '10px 0px' }}>
                {voteCampaignObj.uploadWindowNotice[lang]}
              </div>
            ) : null}
            <Form
              ref={this.formRef}
              onFinish={this.createVotePostSubmit}
              onValuesChange={async (_, allValues) => {
                if (allValues.pictures) {
                  await allValues.pictures.forEach(async (pic) => {
                    if (pic.originFileObj) {
                      pic.url = await this.getBase64(pic.originFileObj);
                    }
                    if (!pic.tags) {
                      pic.tags = [];
                    }
                  });
                  await this.setState({
                    picturesArray: [...allValues.pictures],
                  });
                }
              }}
            >
              <FormBuilder meta={meta} form={this.formRef} />
              {picturesArray.length > 0 && (
                <Form.Item shouldUpdate>
                  {() => (
                    <div ref={this.dragParentContainer}>
                      {isDragging && (
                        <div
                          className="trash-bin-container"
                          ref={this.trashBinRef}
                        >
                          <DeleteOutlined />{' '}
                          <span style={{ fontSize: '12px' }}>
                            {lang === 'zh'
                              ? '拖拽到这里删除'
                              : 'Drag there& Delete'}
                          </span>
                        </div>
                      )}
                      {this.dragParentContainer.current &&
                        picturesArray[currentSlideIndex] &&
                        picturesArray[currentSlideIndex].tags &&
                        picturesArray[currentSlideIndex].tags.map(
                          (tag, index) => (
                            <Draggable
                              key={tag.key}
                              bounds="parent"
                              onStart={() => {
                                this.setState({
                                  isDragging: true,
                                });
                              }}
                              // onDrag={(_, data) => {
                              //   // if (this.trashBinRef.current) {
                              //   //   console.log(
                              //   //     this.trashBinRef.current.getBoundingClientRect()
                              //   //   );
                              //   // }
                              //   // console.dir(
                              //   //   this.dragParentContainer.current.getBoundingClientRect()
                              //   // );
                              //   // console.log('start', data);
                              //   // const { x, y } = data;
                              //   // const containerHeight = this.dragParentContainer
                              //   //   .current.clientHeight;
                              //   // const containerWidth = this.dragParentContainer
                              //   //   .current.clientWidth;
                              //   // picturesArray[currentSlideIndex].tags[index].x =
                              //   //   x / containerWidth;
                              //   // picturesArray[currentSlideIndex].tags[index].y =
                              //   //   x / containerHeight;
                              // }}
                              onStop={async (_, data) => {
                                // console.log(
                                //   this.dragParentContainer.current.clientHeight,
                                //   this.dragParentContainer.current.clientWidth
                                // );
                                const { x, y } = data;
                                const offSetY = data.node.clientHeight + y;
                                const containerHeight =
                                  this.dragParentContainer.current.clientHeight;
                                const containerWidth =
                                  this.dragParentContainer.current.clientWidth;
                                picturesArray[currentSlideIndex].tags[index].x =
                                  x / containerWidth;
                                picturesArray[currentSlideIndex].tags[index].y =
                                  y / containerHeight;
                                await this.setState({
                                  isDragging: false,
                                  picturesArray: [...picturesArray],
                                });
                                if (
                                  x <= 40 &&
                                  offSetY >= containerHeight - 40
                                ) {
                                  picturesArray[currentSlideIndex].tags = [
                                    ...picturesArray[
                                      currentSlideIndex
                                    ].tags.slice(0, index),
                                    ...picturesArray[
                                      currentSlideIndex
                                    ].tags.slice(index + 1),
                                  ];
                                  await this.setState({
                                    picturesArray: [...picturesArray],
                                  });
                                }
                              }}
                              position={{
                                x:
                                  tag.x > -1
                                    ? tag.x *
                                      this.dragParentContainer.current
                                        .clientWidth
                                    : this.dragParentContainer.current
                                        .clientWidth * 0.5,
                                y:
                                  tag.y > -1
                                    ? tag.y *
                                      this.dragParentContainer.current
                                        .clientHeight
                                    : this.dragParentContainer.current
                                        .clientHeight * 0.5,
                              }}
                            >
                              {/* {console.log(tag.x, tag.y)} */}
                              {/* {console.log(
                                tag.x > -1
                                  ? tag.x *
                                      this.dragParentContainer.current
                                        .clientWidth
                                  : '50%',
                                tag.y > -1
                                  ? tag.y *
                                      this.dragParentContainer.current
                                        .clientHeight
                                  : '50%'
                              )} */}

                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: '1000',
                                  maxWidth: '50%',
                                  display: 'flex',
                                  // transform: `translate(${
                                  //   tag.x > -1
                                  //     ? tag.x *
                                  //       this.dragParentContainer.current
                                  //         .clientWidth
                                  //     : '50%'
                                  // }, ${
                                  //   tag.y > -1
                                  //     ? tag.y *
                                  //       this.dragParentContainer.current
                                  //         .clientHeight
                                  //     : '50%'
                                  // })`,
                                }}
                              >
                                <ImageTags
                                  isEdit
                                  onDotClick={() => {
                                    console.log(
                                      'dot click',
                                      picturesArray[currentSlideIndex].tags
                                    );
                                    tag.direction =
                                      tag.direction === 'left'
                                        ? 'right'
                                        : 'left';
                                    this.setState({
                                      picturesArray: [...picturesArray],
                                    });
                                  }}
                                  tag={tag}
                                />
                              </div>
                            </Draggable>
                          )
                        )}
                      <Swiper
                        className="vote-post-create-slick-dots"
                        autoHeight
                        pagination={{
                          clickable: true,
                        }}
                        onImagesReady={() => {
                          console.log('all images loaded');
                          this.setState({
                            voteCampaignObj: { ...voteCampaignObj },
                          });
                        }}
                        onSlideChangeTransitionEnd={(index) => {
                          console.log('slide change', index.activeIndex);
                          this.setState({
                            currentSlideIndex: index.activeIndex,
                          });
                        }}
                      >
                        {picturesArray.map((pic) => {
                          return (
                            <SwiperSlide key={pic.uid}>
                              <img
                                style={{ margin: 'auto', width: '100%' }}
                                src={pic.url}
                                alt=""
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  )}
                </Form.Item>
              )}
              {picturesArray.length > 0 &&
                picturesArray[currentSlideIndex] &&
                picturesArray[currentSlideIndex].tags && (
                  <Form.Item
                    label={<FormattedMessage id="vote.imageTags" />}
                    extra={
                      lang === 'zh'
                        ? '尝到好吃的就忍不住分享，输入产品关键字，Tag一个或多个棒包商品，给小伙伴们种草吧！'
                        : 'Search products you love, then tag it on the picture!'
                    }
                  >
                    <Select
                      showArrow={false}
                      filterOption={false}
                      showSearch
                      mode="tags"
                      style={{ width: '100%' }}
                      onSearch={this.searchTags}
                      value={picturesArray[currentSlideIndex].tags || []}
                      labelInValue
                      onChange={(value) => {
                        console.log(value);
                        const originalArray = [
                          ...picturesArray[currentSlideIndex].tags,
                        ];
                        // value.map((item) => {
                        //   const index = originalArray.findIndex(
                        //     (obj) => obj.key === item.key
                        //   );
                        //   if (index > -1) {
                        //     return { ...originalArray[index], ...item };
                        //   }
                        //   return { ...item, direction: 'left' };
                        // });

                        picturesArray[currentSlideIndex].tags = value.map(
                          (item) => {
                            const index = originalArray.findIndex(
                              (obj) => obj.key === item.key
                            );
                            if (index > -1) {
                              return { ...originalArray[index], ...item };
                            }
                            return { ...item, direction: 'left', x: -1, y: -1 };
                          }
                        );
                        this.setState({
                          picturesArray: [...picturesArray],
                        });
                        console.log(picturesArray);
                      }}
                    >
                      {productSearchArray.map((item) => (
                        <Option key={item._id} value={item._id}>
                          {item.name[lang]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <div className="page-container">
          <div
            className="vote-banner"
            onClick={() => {
              this.setState({ campaignRuleVisible: true });
            }}
            role="presentation"
          >
            <img
              style={{ width: '100%' }}
              src={`${BUCKET_URL}${
                lang === 'en'
                  ? voteCampaignObj.bannerPicEn.key
                  : voteCampaignObj.bannerPicZh.key
              }`}
              alt="banner"
            />
          </div>
          <div>
            {convert(
              convertRawToHTML(
                JSON.parse(voteCampaignObj.description[lang] || '{}')
              )
            )}
          </div>
          {voteLogs.length > 0 && (
            <div style={{ margin: '10px 0px', width: '100%' }}>
              <Alert
                type="success"
                style={{ width: '100%', wordWrap: 'break-word' }}
                message={
                  <TextLoop
                    mask
                    noWrap={!isMobile}
                    style={{ width: '100%', wordWrap: 'break-word' }}
                  >
                    {voteLogs.map((log) => {
                      if (lang === 'zh') {
                        return (
                          <div
                            key={nanoid(6)}
                            style={{ width: '100%', wordWrap: 'break-word' }}
                          >
                            {log.email} 投给 {log.votePost.title}{' '}
                            {log.voteCounter} 票，并获得 ${log.amount} 优惠券
                          </div>
                        );
                      }
                      return (
                        <div
                          key={nanoid(6)}
                          style={{ width: '100%', wordWrap: 'break-word' }}
                        >
                          {log.email} voted for {log.votePost.title}{' '}
                          {log.voteCounter} time, and got ${log.amount} coupon
                        </div>
                      );
                    })}
                  </TextLoop>
                }
              />
            </div>
          )}
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button
                onClick={() => {
                  this.setState({ campaignRuleVisible: true });
                }}
              >
                {lang === 'zh' ? '投票规则' : 'Vote Rule'}
              </Button>
              {!currentUserPost ? (
                <Button
                  onClick={() => {
                    // check collect date
                    const today = moment().valueOf();
                    if (
                      today < voteCampaignObj.collectStartDate ||
                      today > voteCampaignObj.collectEndDate
                    ) {
                      this.setState({ canNotCreateByDateLimitModal: true });
                    } else {
                      if (!this.checkForLogin()) {
                        this.setState({
                          unSignInModalVisible: true,
                        });
                        return;
                      }
                      this.setState({
                        uploadModalVisible: true,
                      });
                    }
                  }}
                  type="primary"
                >
                  <FormattedMessage id="vote.createNewPost" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    this.handleViewMyPost();
                  }}
                  type="primary"
                >
                  <FormattedMessage id="vote.viewMyPost" />
                </Button>
              )}
            </Space>
          </div>
          {currentUserPost && currentUserPost.status !== 'pass' && (
            <div style={{ margin: '10px 0px' }}>
              <Alert
                message={
                  <div>
                    <span
                      role="presentation"
                      onClick={() => {
                        if (currentUserPost.status === 'reject') {
                          return;
                        }
                        this.handleViewMyPost();
                      }}
                    >
                      <FormattedMessage id="vote.postStatusTip" />{' '}
                      {currentUserPost.status}
                    </span>
                  </div>
                }
                type="warning"
                closable
              />
            </div>
          )}
          <div style={{ margin: '10px 0px' }}>
            <Radio.Group
              onChange={async (e) => {
                await this.setState({
                  radioFilter: e.target.value,
                });
                switch (e.target.value) {
                  case 'total':
                    // votePostArray.sort((a, b) => b.totalVotes - a.totalVotes);

                    await this.fetchVotePostPagination();
                    break;
                  case 'displayId':
                    // votePostArray.sort((a, b) => a.displayId - b.displayId);
                    await this.fetchVotePostPagination();
                    break;
                  default:
                    break;
                }
              }}
              value={radioFilter}
            >
              <Radio value="total">
                <FormattedMessage id="vote.totalVoteOder" />
              </Radio>
              <Radio value="displayId">
                <FormattedMessage id="vote.numberOrder" />
              </Radio>
            </Radio.Group>
          </div>

          <InfiniteScroll
            dataLength={votePostArray.length}
            next={async () => {
              await this.mobileScrollFetch(mobilePage);
            }}
            hasMore={isMobile && (mobilePage - 1) * PAGE_SIZE < totalPosts}
            loader={
              <h4 style={{ textAlign: 'center', color: '#919191' }}>
                Loading...
              </h4>
            }
          >
            <div
              className={
                isMobile ? 'vote-post-container-mobile' : 'vote-post-container'
              }
            >
              {votePostArray.map((votePost) => (
                <div key={votePost._id}>
                  <div
                    className="vote-post-item"
                    style={
                      isMobile
                        ? { width: '100%', margin: '0px' }
                        : { width: '350px' }
                    }
                  >
                    <div className="vote-number-container">
                      <div className="vote-number">{votePost.displayId}</div>
                      <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        <FormattedMessage id="vote.totalVotes" />
                        {votePost.totalVotes}
                      </div>
                    </div>
                    <div className="post-title display-one-line">
                      <Link
                        to={getCustomerPath(
                          `vote-post?votePostId=${votePost._id}`
                        )}
                        style={{ color: 'black' }}
                      >
                        {votePost.title}
                      </Link>
                    </div>
                    <div className="picture-container">
                      <Link
                        to={getCustomerPath(
                          `vote-post?votePostId=${votePost._id}`
                        )}
                        style={{ color: 'black' }}
                      >
                        <div
                          className="picture"
                          style={{
                            backgroundImage: `url(${BUCKET_URL}${votePost.pictures[0].key})`,
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      className="display-one-line"
                      style={{
                        textAlign: 'center',
                        marginTop: '10px',
                        fontSize: '12px',
                      }}
                    >
                      <Link
                        to={getCustomerPath(
                          `vote-post?votePostId=${votePost._id}`
                        )}
                        style={{ color: 'black' }}
                      >
                        <FormattedMessage id="vote.author" />:
                        {votePost.nickName}
                      </Link>
                    </div>
                    <div
                      className="display-multiple-lines"
                      style={{
                        margin: '10px 0px',
                        whiteSpace: 'pre-line',
                        fontSize: '14px',
                        height: '66px',
                      }}
                    >
                      <Link
                        to={getCustomerPath(
                          `vote-post?votePostId=${votePost._id}`
                        )}
                        style={{ color: 'black' }}
                      >
                        {votePost.description}
                      </Link>
                    </div>
                    <div
                      className="button-container"
                      style={
                        isMobile
                          ? {
                              flexDirection: 'row',
                            }
                          : {}
                      }
                    >
                      <Button
                        style={isMobile ? { fontSize: '13px' } : {}}
                        size={isMobile ? 'small' : 'middle'}
                        onClick={() => {
                          this.handleShareButton(votePost._id);
                        }}
                      >
                        <div className="flex-center">
                          <img
                            style={{ height: '100%' }}
                            src={shareIcon}
                            alt=""
                          />
                          <FormattedMessage id="vote.share" />
                        </div>
                      </Button>

                      <Button
                        style={isMobile ? { fontSize: '13px' } : {}}
                        size={isMobile ? 'small' : 'middle'}
                        onClick={() => {
                          // this.handleVoteButton(vote-post._id);

                          // first check date
                          const today = moment().valueOf();
                          if (
                            today < voteCampaignObj.campaignStartDate ||
                            today > voteCampaignObj.campaignEndDate
                          ) {
                            this.setState({ canNotVoteByDateLimitModal: true });
                          } else {
                            if (!token) {
                              sessionStorage.setItem(
                                'pendingVotePostId',
                                votePost._id
                              );
                            }
                            this.setState({
                              voteConfirmVisible: true,
                              selectedVotePostId: votePost._id,
                              selectedVotePost: votePost,
                            });
                          }
                        }}
                      >
                        <div className="flex-center">
                          <img style={{ height: '100%' }} src={Like} alt="" />
                          <FormattedMessage id="vote.vote" />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              {votePostArray.length === 0 && (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  No Posts Yet
                </div>
              )}
            </div>
          </InfiniteScroll>
          {isMobile && (mobilePage - 1) * PAGE_SIZE >= totalPosts && (
            <div style={{ textAlign: 'center' }}>-End-</div>
          )}
          {!isMobile && (
            <Pag
              style={{ textAlign: 'right' }}
              defaultPageSize={PAGE_SIZE}
              defaultCurrent={1}
              total={totalPosts}
              onChange={this.onPaginationChange}
            />
          )}
        </div>
      </div>
    );
  }
}

VoteCampaign.defaultProps = {
  token: null,
  userId: null,
};

VoteCampaign.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  token: PropTypes.string,
  saveLinkForLogin: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lang: state.language.lang,
  token: state.auth.token,
  userId: state.user.userId,
});

const mapDispatchToProps = (dispatch) => ({
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(VoteCampaign));
