import React, { Component } from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from '../../../components/Navigators/FoundationNav/FoundationNav';
import logoWithPic from '../../../assets/images/logoWithPic.svg';
import './Home.css';

const { Paragraph } = Typography;
const { Content, Footer } = Layout;

// import PropTypes from 'prop-types';

export class Home extends Component {
  // static propTypes = {};
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    document.title = 'BunBao 棒!包 - Foundation';
    return (
      <Layout className="layout">
        <Nav />
        <Content
          style={{
            padding: '70px 0px 0px 0px',
            minHeight: 'calc(100vh - 70px)',
            backgroundColor: 'white',
          }}
        >
          <Row
            style={{ minHeight: 'calc(100vh - 70px)' }}
            type="flex"
            justify="center"
            align="middle"
          >
            <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div
                style={{
                  padding: 'auto',
                  textAlign: 'center',
                }}
              >
                <img
                  style={{
                    width: '80%',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    display: 'block',
                  }}
                  src={logoWithPic}
                  alt="BunBao Logo With Pictures"
                />
              </div>
            </Col>
            <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div style={{ padding: '20px 15%' }}>
                <div
                  style={{
                    fontSize: '2em',
                    fontFamily: 'Calistoga',
                  }}
                >
                  Foundation
                </div>
                <Paragraph style={{ fontSize: '20px' }}>
                  The website is BunBao.com, Inc. foundation platform
                </Paragraph>
                <Button size="large">
                  <Link to="/dashboard">Get Started</Link>
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: 'center', height: '100px' }}>
          <div>Foundation ©2019 by BunBao.com, Inc.</div>
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(Home);
