import gql from 'graphql-tag';

export const ACTIVITIES_QUERY = gql`
  {
    activities {
      edges {
        node {
          name
          startDate
          endDate
          id
        }
      }
    }
  }
`;

export const CHECK_USER_IN_ACTIVITY = gql`
  query ($activityId: ID!, $email: String!) {
    contentByUser(activityId: $activityId, email: $email) {
      id
      plate
      userStatus
    }
  }
`;

export const ACTIVITIES_DETAIL_QUERY = gql`
  query ($activityId: ID!, $email: String!) {
    contentByUser(activityId: $activityId, email: $email) {
      id
      plate
      userStatus
      appointmentTime
      defaultAddress {
        id
        firstName
        lastName
        companyName
        streetAddress1
        streetAddress2
        city
        postalCode
        country {
          code
        }
        phone
        frontDoor
        gateCode
      }
      progress {
        edges {
          node {
            order {
              displayId
              boxs {
                shippingDate
                boxsLines {
                  productName
                  translatedProductName
                  quantity
                }
              }
            }
            id
            status
            imageUrls
            order {
              id
            }
            infos
            updateTime
            previousOne {
              id
            }
          }
        }
      }
    }
  }
`;

export const IMAGE_UPDATE = gql`
  mutation ($id: ID!, $link: [String]!, $date: Date!) {
    progressOrder(progressId: $id, imageLink: $link, shippingDate: $date) {
      resultOrder {
        id
      }
    }
  }
`;
