/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import YouTube from 'react-youtube';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { ReactComponent as muteIcon } from '../../assets/images/icons/mute.svg';
import { ReactComponent as unmuteIcon } from '../../assets/images/icons/unmute.svg';

const VideoBannerAlt = (props) => {
  const { bannerFullWidth, videoIds } = props;
  const [isMuted, setMute] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const language = useSelector((state) => {
    return state.language.lang;
  });

  return (
    <div
      css={{
        height: isMobile ? '200px' : '300px',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div
        css={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            position: 'absolute',
            height: '100%',
            width: isMobile
              ? bannerFullWidth
              : Math.max(bannerFullWidth / 2, 532),
          }}
        >
          <Button
            css={{
              position: 'absolute',
              zIndex: '10',
              top: '10px',
              right: '10px',
              pointerEvents: 'auto',
            }}
            onClick={() => {
              setMute(!isMuted);
              if (videoPlayer.isMuted()) {
                videoPlayer.unMute();
              } else {
                videoPlayer.mute();
              }
            }}
            shape="circle"
            icon={
              isMuted ? (
                <Icon component={muteIcon} />
              ) : (
                <Icon component={unmuteIcon} />
              )
            }
          />
        </div>
        <YouTube
          id={nanoid(5)}
          opts={{
            host: 'https://www.youtube-nocookie.com',
            height: isMobile ? 200 : 300,
            width: isMobile
              ? bannerFullWidth
              : Math.max(bannerFullWidth / 2, 532),
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              controls: 0,
              modestbranding: 1,
              iv_load_policy: 3,
              rel: 0,
              playsinline: 1,
            },
          }}
          videoId={
            language === 'zh'
              ? videoIds.filter((element) => element.language === 'zh')[0]
                  .videoId
              : videoIds.filter((element) => element.language === 'en')[0]
                  .videoId
          }
          onReady={(event) => {
            console.log('Youtube event: ', event);
            setVideoPlayer(event.target);
            event.target.mute();
            event.target.playVideo();
          }}
          onPause={(event) => {
            event.target.playVideo();
          }}
          onStateChange={(event) => {
            console.log('Youtube state change: ', event);
            setVideoPlayer(event.target);
            event.target.mute();
            event.target.playVideo();
          }}
          onEnd={(event) => {
            event.target.playVideo();
          }}
        />
      </div>
    </div>
  );
};

VideoBannerAlt.defaultProps = {};

VideoBannerAlt.propTypes = {
  bannerFullWidth: PropTypes.number.isRequired,
  videoIds: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      videoId: PropTypes.string,
    })
  ).isRequired,
};
export default VideoBannerAlt;
