export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SALEOR_ID = 'AUTH_SALEOR_ID';
export const AUTH_SAVE_LINK = 'AUTH_SAVE_LINK';

export const USER_UPDATE = 'USER_UPDATE';
export const SHOPPING_MODE_UPDATE = 'SHOPPING_MODE_UPDATE';
export const ADDRESS_TYPE_UPDATE = 'ADDRESS_TYPE_UPDATE';
export const GET_SALEOR_USER = 'GET_SALEOR_USER';
export const CALLBACK_OVERWRITE = 'CALLBACK_OVERWRITE';
export const ATTRIBUTE_UPDATE = 'ATTRIBUTE_UPDATE';

export const CART_UPDATE_ITEMS = 'CART_UPDATE_ITEMS';
export const CART_UPDATE_SHOPPING_CART = 'CART_UPDATE_SHOPPING_CART';
export const CART_UPDATE_TIP = 'CART_UPDATE_TIP';
export const CART_FETCH_CURRENT_WAREHOUSE_CART =
  'CART_FETCH_CURRENT_WAREHOUSE_CART';
export const CART_UPDATE_ALL_WAREHOUSE_CART = 'CART_UPDATE_ALL_WAREHOUSE_CART';

export const WAREHOUSE_UPDATE = 'WAREHOUSE_UPDATE';
export const WAREHOUSE_ATTRIBUTE_UPDATE = 'WAREHOUSE_ATTRIBUTE_UPDATE';

export const LANGUAGE_SET = 'LANGUAGE_SET';

export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';

export const TABLE_COLUMNS_STATE_UPDATE = 'TABLE_COLUMNS_STATE_UPDATE';
export const TABLE_RESET_ALL_COLUMNS_STATES = 'TABLE_RESET_ALL_COLUMNS_STATES';
