import gql from 'graphql-tag';

export const ADDRESS_FRAGMENT = gql`
  fragment ADDRESS_FRAGMENT on Address {
    warehouseId
    days
    startTime {
      hours
      minutes
    }
    endTime {
      hours
      minutes
    }
    allowedDateRange {
      startDate
      endDate
    }
    cutOffTime
    isIgnored
    type
    companyName
    addressOne
    addressTwo
    city
    gateCode
    phoneNumber
    lastName
    firstName
    zipCode
    fullAddress
    _id
  }
`;

const ADDRESS_QUERY = gql`
  query {
    userCurrent {
      _id
      addresses {
        type
        companyName
        addressOne
        addressTwo
        city
        gateCode
        phoneNumber
        lastName
        firstName
        zipCode
        fullAddress
        _id
      }
    }
  }
`;

const ADDRESSES_BY_USER = gql`
  query ($id: MongoID!) {
    userById(_id: $id) {
      addresses {
        type
        companyName
        addressOne
        addressTwo
        city
        gateCode
        phoneNumber
        lastName
        firstName
        zipCode
        fullAddress
        _id
      }
    }
  }
`;

const CREATE_ADDRESS = gql`
  mutation ($input: CreateOneAddressInput!) {
    addressCreateOne(input: { record: $input }) {
      record {
        type
        companyName
        addressOne
        addressTwo
        city
        gateCode
        phoneNumber
        lastName
        firstName
        zipCode
        fullAddress
        _id
      }
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation ($input: UpdateOneAddressInput!, $_id: MongoID) {
    addressUpdateOne(input: { record: $input, filter: { _id: $_id } }) {
      record {
        type
        companyName
        addressOne
        addressTwo
        city
        gateCode
        phoneNumber
        lastName
        firstName
        zipCode
        fullAddress
        _id
      }
    }
  }
`;

const USER_SET_ADDRESS = gql`
  mutation ($id: MongoID!) {
    userUpdateCurrentUserAddress(newAddress: $id) {
      addresses {
        phoneNumber
        lastName
        firstName
        _id
      }
    }
  }
`;

const ADMIN_SET_USER_ADDRESS = gql`
  mutation ($userId: MongoID!, $addressId: MongoID!) {
    userUpdateAddressByAdmin(userId: $userId, addressId: $addressId) {
      addresses {
        lastName
        firstName
        _id
      }
    }
  }
`;

const ADDRESS_MANY = gql`
  query ($filter: FilterFindManyAddressInput) {
    addressMany(filter: $filter) {
      ...ADDRESS_FRAGMENT
    }
  }
  ${ADDRESS_FRAGMENT}
`;

const CHECK_ZIP_CODE_AVAILABILITY = gql`
  query ($zipCode: String!) {
    checkZipCodeAvailability(zipCode: $zipCode)
  }
`;

export {
  ADDRESS_QUERY,
  CREATE_ADDRESS,
  USER_SET_ADDRESS,
  UPDATE_ADDRESS,
  ADDRESSES_BY_USER,
  ADMIN_SET_USER_ADDRESS,
  ADDRESS_MANY,
  CHECK_ZIP_CODE_AVAILABILITY,
};
