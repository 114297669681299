/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Collapse } from 'antd';
import { connect } from 'react-redux';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import { apolloClient } from '../../../util';
import { ARTICLE_FIND_MANY } from '../../../api/article';
import { CATEGORY_QUERY } from '../../../api/category';

const { Panel } = Collapse;

export class FAQ extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      categories: [],
      articles: [],
      activeKeys: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await this.handleFetchArticles();
    // await this.handleFetchCategories();
  };

  handleFetchCategories = async () => {
    const { data } = await apolloClient.query({
      query: CATEGORY_QUERY,
      variables: {
        filter: { type: 'article' },
      },
    });
    console.log(data);
  };

  handleFetchArticles = async () => {
    const { data } = await apolloClient.query({
      query: ARTICLE_FIND_MANY,
      variables: { filter: { type: 'faq' } },
    });
    // console.log(data);
    const { articleMany } = data;
    console.log(articleMany);
    const categoriesMap = new Map();
    if (articleMany && articleMany.length > 0) {
      articleMany.forEach((article) => {
        article.categories.forEach((category) => {
          categoriesMap.set(category.name, {
            _id: category._id,
            name: category.name,
            title: category.title,
            ranking: category.ranking,
          });
        });
      });
      const categoriesArray = lodash.orderBy(
        Array.from(categoriesMap.values()),
        ['ranking'],
        ['desc']
      );
      const activeKeys = {};
      categoriesArray.forEach((category) => {
        if (!activeKeys[category._id]) {
          activeKeys[category._id] = [];
        }
        // articleMany.forEach((article) => {
        //   if (article.category._id === category._id) {
        //     activeKeys[category._id].push(article._id);
        //   }
        // });
      });
      this.setState({
        categories: categoriesArray,
        articles: articleMany,
        activeKeys,
      });
    }
  };

  handleOnChangeCollapse = (key, categoryId) => {
    // if exist in activeKey, remove it
    const { activeKeys } = this.state;
    activeKeys[categoryId] = key;
    this.setState({ activeKeys });
  };

  render() {
    const { categories, articles, activeKeys } = this.state;
    const { language } = this.props;

    return (
      <div css={{ minHeight: '100vh' }}>
        <div
          css={{
            margin: '19px auto',
            textAlign: 'center',
            fontSize: '30px',
            fontWeight: 'bold',
          }}
        >
          FAQ
        </div>
        <div css={{ margin: 'auto', maxWidth: '800px' }}>
          {categories.map((category) => {
            return (
              <div key={category._id}>
                <div
                  css={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    margin: '10px',
                  }}
                >
                  {language === 'zh' ? category.title.zh : category.title.en}
                </div>
                <Collapse
                  // defaultActiveKey={activeKey}
                  activeKey={activeKeys[category._id]}
                  bordered={false}
                  onChange={(e) => this.handleOnChangeCollapse(e, category._id)}
                >
                  {articles.map((article) => {
                    if (article.categoryIds[0] === category._id) {
                      return (
                        <Panel
                          key={article._id}
                          header={
                            language === 'zh'
                              ? article.title.zh
                              : article.title.en
                          }
                        >
                          <div key={article._id}>
                            {language === 'zh' ? (
                              <div className="braft-output-content">
                                {convert(
                                  convertRawToHTML(
                                    JSON.parse(article.content.zh)
                                  )
                                )}
                              </div>
                            ) : (
                              <div className="braft-output-content">
                                {convert(
                                  convertRawToHTML(
                                    JSON.parse(article.content.en)
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </Panel>
                      );
                    }
                    return null;
                  })}
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

FAQ.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(FAQ);
