import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Typography, Row, Spin, Button } from 'antd';
import { getCustomerPath } from '../../../../util';
import './SignInForm.less';

export default function SignInForm({
  handleClickLoginButton,
  language,
  getFieldDecorator,
  pending,
}) {
  return (
    <Spin spinning={pending}>
      <Form
        onSubmit={handleClickLoginButton}
        layout="vertical"
        className="sign-in-form"
      >
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message:
                  language === 'zh'
                    ? '无效的邮箱格式'
                    : 'Invalid email address',
              },
              {
                required: true,
                message:
                  language === 'zh' ? '邮箱不能为空' : 'Email cannot be empty',
              },
            ],
          })(
            <Input
              className="data-hj-whitelist"
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={language === 'zh' ? '邮箱' : 'Email address'}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Form.Item
            // label={
            //   <>
            //     <Text>{language === 'zh' ? '密码' : 'Password'}</Text>

            //     <Link to={getCustomerPath('reset-password')}>
            //       <Typography.Link
            //         style={{
            //           float: 'right',
            //           textDecoration: 'underline',
            //         }}
            //       >
            //         {language === 'zh' ? '忘记密码?' : 'Forgot Password?'}
            //       </Typography.Link>
            //     </Link>
            //   </>
            // }
            style={{ paddingBottom: 'unset', marginBottom: 'unset' }}
          >
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  min: 6,
                  message:
                    language === 'zh'
                      ? '请输入一个有效的密码（至少6位字符）'
                      : 'Invalid password, please enter 6 or more characters',
                },
              ],
            })(
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder={language === 'zh' ? '密码' : 'Password'}
              />
            )}
          </Form.Item>
          <Row justify="end">
            <Link to={getCustomerPath('reset-password')}>
              <Typography.Link
                style={{
                  float: 'right',
                  textDecoration: 'underline',
                }}
              >
                {language === 'zh' ? '忘记密码?' : 'Forgot Password?'}
              </Typography.Link>
            </Link>
          </Row>
        </Form.Item>
        <Form.Item style={{ marginBottom: 'unset', paddingBottom: 'unset' }}>
          <Button
            style={{ backgroundColor: '#000', color: '#fff' }}
            htmlType="submit"
            type="primary"
            className="custom-button"
          >
            {language === 'zh' ? '登陆' : 'Login'}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}

SignInForm.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  handleClickLoginButton: PropTypes.func.isRequired,
};
