import React, { Component } from 'react';

export class Cart extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return <div>Cart</div>;
  }
}

export default Cart;
