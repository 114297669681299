import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { apolloClient, getCustomerPath } from '../../../../util';
import {
  ORDER_PAGINATION_CURRENT_USER,
  ORDER_CONFIRM,
  ORDER_FINALIZE,
} from '../../../../api/order';

const { Column } = Table;

export class CateringOrders extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      ordersCount: 0,
      orders: [],
      buttonLoading: [],
    };
  }

  async componentDidMount() {
    await this.handleFetchOrders();
  }

  handleFetchOrders = async () => {
    const { pageIndex, pageSize } = this.state;
    try {
      const { data } = await apolloClient.query({
        query: ORDER_PAGINATION_CURRENT_USER,
        variables: {
          page: pageIndex,
          perPage: pageSize,
          sort: 'CREATEDAT_DESC',
          filter: { deleted: false, _operators: { createdAt: { gt: 1 } } },
        },
        fetchPolicy: 'network-only',
      });
      const { orderPaginationCurrentUser } = data;
      this.setState({
        orders: orderPaginationCurrentUser.items,
        ordersCount: orderPaginationCurrentUser.count,
        buttonLoading: [
          ...Array(orderPaginationCurrentUser.items.length).fill(false),
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChangePageIndex = async (pageIndex, pageSize) => {
    await this.setState({ pageIndex, pageSize });
    await this.handleFetchOrders();
  };

  handlePayOrder = async (orderId, index) => {
    const { buttonLoading } = this.state;
    buttonLoading[index] = true;
    this.setState({ buttonLoading });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_CONFIRM,
        variables: { orderId },
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_FINALIZE,
        variables: { orderId },
      });
      console.log(response);
      // directly open new tab for payment
      window.open(response.data.orderFinalize.invoiceUrl, '_blank');
    } catch (error) {
      console.error(error);
    }
    buttonLoading[index] = false;
    this.setState({ buttonLoading });
    // try to fetch orders
    this.handleFetchOrders();
  };

  render() {
    const { pageIndex, orders, ordersCount, pageSize, buttonLoading } =
      this.state;
    const deliveryFee = (record) =>
      record.deliveryFee ? record.deliveryFee.fee : 0;
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <h3>Catering Orders</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              dataSource={orders}
              rowKey={(record) => record._id}
              pagination={{
                total: ordersCount,
                current: pageIndex,
                pageSize,
                onChange: this.handleChangePageIndex,
                onShowSizeChange: this.handleChangePageIndex,
                showSizeChanger: true,
              }}
            >
              <Column
                title="Order Created"
                key="orderCreated"
                render={(text, record) => (
                  <div>{moment(record.createdAt).format('MM-DD HH:mm')}</div>
                )}
              />
              <Column
                title="Schedule Date"
                key="date"
                render={(text, record) => (
                  <div>{moment(record.scheduleDate).format('MM-DD HH:mm')}</div>
                )}
              />
              <Column
                title="Address"
                render={(text, record) => (
                  <div>
                    <div>{record.addressOne}</div>
                    <div>{record.addressTwo}</div>
                    <div>{record.city}</div>
                    <div>{record.zip}</div>
                    <div>{record.state}</div>
                  </div>
                )}
              />
              <Column
                title="Products"
                render={(text, record) => (
                  <Row>
                    {record.products.map((item) => {
                      if (item.quantity > 0) {
                        return (
                          <Col key={item._id} style={{ margin: '3px 0px' }}>
                            {`${item.quantity} X $${item.price} ${item.name}`}
                          </Col>
                        );
                      }
                      return <></>;
                    })}
                    <Col style={{ margin: '3px 0px' }}>
                      {`Subtotal: $${Number(record.subtotal).toFixed(2)}`}
                    </Col>
                    <Col style={{ margin: '3px 0px' }}>
                      {`Tax: $${(
                        Number(record.subtotal) * Number(record.taxRate / 100)
                      ).toFixed(2)}`}
                    </Col>
                    <Col style={{ margin: '3px 0px' }}>
                      {`Delivery Fee: $${Number(deliveryFee(record)).toFixed(
                        2
                      )}`}
                    </Col>
                    <Col style={{ margin: '3px 0px' }}>
                      {`Total: $${(
                        Number(record.subtotal) +
                        Number(record.subtotal) * Number(record.taxRate / 100) +
                        Number(deliveryFee(record))
                      ).toFixed(2)}`}
                    </Col>
                    {JSON.stringify(record)}
                  </Row>
                )}
              />
              <Column
                title="Others"
                render={(text, record) => (
                  <div>
                    <div style={{ marginBottom: '10px' }}>
                      <div>Headcount: </div>
                      {record.headcount ? record.headcount : 0}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      <div>Delivery Notes:</div>
                      {record.deliveryNotes ? record.deliveryNotes : <br />}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      <div>Notes:</div>
                      {record.notes ? record.notes : <br />}
                    </div>
                  </div>
                )}
              />
              <Column
                title="Actions"
                render={(text, record, index) => (
                  <>
                    {record.invoiceUrl ? (
                      <Button
                        type="primary"
                        disabled={record.paid}
                        onClick={() => {
                          window.open(record.invoiceUrl, '_blank');
                        }}
                      >
                        {record.paid ? 'Paid' : 'Pay'}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        disabled={record.paid || buttonLoading[index]}
                        onClick={() => {
                          this.handlePayOrder(record._id, index);
                        }}
                      >
                        {buttonLoading[index] ? <LoadingOutlined /> : null}
                        {record.paid ? 'Paid' : 'Pay'}
                      </Button>
                    )}
                    <Divider type="vertical" />
                    <Link
                      to={getCustomerPath(
                        `account/catering-orders/order/${record._id}`
                      )}
                    >
                      <span className="actionLink">Details</span>
                    </Link>
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CateringOrders;
