/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import 'react-image-lightbox/style.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  ArrowDownOutlined,
  CheckOutlined,
  CloseOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Switch,
  Input,
  message,
  Tooltip,
  Row,
  Alert,
  TimePicker,
  DatePicker,
  Modal,
  Radio,
  Divider,
  Checkbox,
} from 'antd';
import { Calendar } from 'antd-mobile';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import * as R from 'ramda';
import queryString from 'query-string';
import {
  FETCH_CAMPAIGN,
  CREATE_CAMPAIGN_CONTENT,
  FETCH_CAMPAIGN_CONTENT,
  FETCH_MY_CAMPAIGN_INDIVIDUAL,
} from '../../../api/campaign';
import {
  FETCH_MINIMUM_SHIPPING_VALUE,
  FETCH_SHIPPING_RULE,
} from '../../../api/rule';
import { apolloClient, getCustomerPath } from '../../../util';
import { combineShippingTime } from '../../../helper/PureFunction/SmallFunction';
import { addressFormatterOnlyAddress } from '../../../helper/Formatter/Formatter';
import {
  successIcon,
  questionIcon,
  // shippingRuleDisplay,
  // discountRuleDisplay,
} from '../../../components/SmallComponents/SmallComponents';
import AddressSelection from '../Checkout/AddressSelection/AddressSelection';
import MyGroupBuy from '../Account/MyGroupBuy/MyGroupBuy';
import * as actions from '../../../store/actions';
import { WAREHOUSES_QUERY } from '../../../api/warehouse';
import GroupInfoBox from '../../../components/Others/GroupInfoBox/GroupInfoBox';
import GroupBuyingMenu from '../../../components/Others/GroupBuyingMenu/GroupBuyingMenu';
import GroupBuyingGuide from '../../../components/Modals/GroupBuyingGuide/GroupBuyingGuide';

const addWeekdays = (date, days) => {
  let newDays = days; // use a clone
  let newDate = moment(date);
  while (newDays > 0) {
    newDate = newDate.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (newDate.day() !== 6 && newDate.day() !== 7) {
      newDays -= 1;
    }
  }
  return newDate;
};

const { innerHeight: height } = window;
export class GroupBuying extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      campaign: null,
      campaignContentId: null,
      campaignContent: null,
      officialCampaignContent: [],
      // shippingRule: null,
      discountRule: null,
      warehouse: null,
      displayId: null,
      officialGroup: false,
      showGroupJoin: false,
      groupBuyInfo: null,
      selectedShippingDate: null,
      selectedShippingTime: null,
      shippingDate: null,
      groupName: null,
      minimumShippingPrice: 0,
      groupNote: '',
      switchValue: 'myGroupBuy',
      showGroupJoinInput: false,
      showGroupCreate: false,
      mobileCalendarVisibility: false,
      showGroupBuyingGuideModal: false,
      neverShowGroupBuyingGuide: false,
    };
  }

  componentDidMount = async () => {
    const flag = await localStorage.getItem('neverShowGroupBuyingGuide');
    this.setState({ neverShowGroupBuyingGuide: flag === 'true' });
    // require login
    const { token, intl, saveLinkForLogin, location, history } = this.props;
    const values = queryString.parse(location.search);
    const { code } = values;
    const redirect = async () => {
      await saveLinkForLogin(
        getCustomerPath(`group-buying${code ? `?code=${code}` : ''}`)
      );
      history.push(getCustomerPath('login'));
    };
    // console.log('token', token);
    if (token === null) {
      Modal.warning({
        title: intl.formatMessage({ id: 'login' }),
        content: intl.formatMessage({ id: 'loginRedirectingContinue' }),
        onOk: redirect,
      });
    } else {
      // fetch campaign
      await this.handleFetchCampaign();
      // fetch special warehouse
      await this.handleFetchWarehouse();
      await this.fetchOfficialCampaignContent();
      await this.fetchShippingRule();
      if (code) {
        this.setState({ showGroupJoinInput: true, displayId: code });
        this.findCampaignContent();
        // console.log(code);
      }
    }
  };

  fetchShippingRule = async () => {
    try {
      const { data } = await apolloClient.query({
        query: FETCH_SHIPPING_RULE,
        variables: {},
        fetchPolicy: 'network-only',
      });
      if (data.ruleFindMany && data.ruleFindMany.length > 0) {
        console.log('shipping rule fetched');
        // this.setState({ shippingRule: data.ruleFindMany[0] });
      }
    } catch (error) {
      message.error('error fetching shipping rule');
    }
  };

  fetchOfficialCampaignContent = async () => {
    // return;
    // try {
    //   const { data } = await apolloClient.query({
    //     query: FETCH_CAMPAIGN_CONTENT,
    //     variables: {
    //       filter: {
    //         status: 'active',
    //         official: true,
    //         campaignType: 'companyGroupBuy',
    //       },
    //     },
    //     fetchPolicy: 'network-only',
    //   });
    //   if (data.campaignContentFindMany) {
    //     console.log('official campaign content fetched');
    //     this.setState({
    //       officialCampaignContent: data.campaignContentFindMany,
    //     });
    //   }
    // } catch (error) {
    //   message.error('error happened during fetching campaign');
    // }
  };

  fetchMinimumValue = async (addressString) => {
    const { intl, currentAddressType } = this.props;
    // message.error(currentAddressType)
    if (currentAddressType === 'pickup') {
      return true;
    }
    try {
      const { data } = await apolloClient.query({
        query: FETCH_MINIMUM_SHIPPING_VALUE,
        variables: { addressString },
        fetchPolicy: 'network-only',
      });
      if (data.ruleGetMinimumShippingValue.valid === true) {
        this.setState({
          minimumShippingPrice: data.ruleGetMinimumShippingValue.minimumValue,
        });
        return true;
      }
      Modal.warning({
        content: intl.formatMessage({ id: 'groupBuyOutOfDeliveryRange' }),
        onOk: () => {
          Modal.destroyAll();
        },
      });
      return false;
    } catch (error) {
      message.error('error happened during fetching price');
      return false;
    }
  };

  handleFetchCampaign = async () => {
    try {
      const {
        data: { campaignFindMany },
      } = await apolloClient.query({
        query: FETCH_CAMPAIGN,
        variables: { filter: { type: 'companyGroupBuy' } },
      });
      // console.log('campaignFindMany', campaignFindMany);
      const sortRank = _.sortBy(campaignFindMany[0].discountRank, [
        'participatorAmount',
      ]);
      // console.log('sortRank',sortRank);
      await this.setState({
        campaign: campaignFindMany[0],
        discountRule: sortRank,
      });
    } catch (error) {
      message.error('Can not fetch campaign');
    }
  };

  handleFetchWarehouse = async () => {
    try {
      const {
        data: { warehouseMany },
      } = await apolloClient.query({
        query: WAREHOUSES_QUERY,
        variables: { filter: { warehouseType: 'special' } },
      });
      // console.log('warehouseMany', warehouseMany);
      this.setState({ warehouse: warehouseMany[0] });
    } catch (error) {
      message.error('Can not fetch warehouse');
    }
  };

  handleCreateCampaign = async () => {
    try {
      const { campaign, groupName, groupNote, officialGroup } = this.state;
      const input = {
        campaignId: campaign._id,
        name: groupName,
        note: groupNote,
        official: officialGroup,
      };
      const { data } = await apolloClient.mutate({
        mutation: CREATE_CAMPAIGN_CONTENT,
        variables: { input },
      });
      if (data.campaignContentCreateOne.record._id) {
        return data.campaignContentCreateOne.record._id;
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  handleJoinCampaign = async () => {
    const getIndividual = R.pathOr(null, [
      'data',
      'campaignIndividualFindMany',
      0,
      '_id',
    ]);
    const getOrderStatus = R.pathOr(null, [
      'data',
      'campaignIndividualFindMany',
      0,
      'oneOrder',
      'paymentStatus',
    ]);
    const { campaignContentId } = this.state;
    const { userId } = this.props;
    const myIndividual = await apolloClient.query({
      query: FETCH_MY_CAMPAIGN_INDIVIDUAL,
      variables: { contentId: campaignContentId, userId },
      fetchPolicy: 'network-only',
    });
    const individualId = getIndividual(myIndividual);
    const orderStatus = getOrderStatus(myIndividual);
    if (individualId) {
      // update for failed order
      if (orderStatus === 'draft' || orderStatus === null) {
        return true;
      }
      return false;
    }
    return true;
  };

  handleJumpToStoreForNewGroupBuying = async () => {
    const result = await this.handleCreateCampaign();
    if (result) {
      const {
        history,
        setUserShoppingMode,
        setUserAddressType,
        updateOneUserAttribute,
      } = this.props;
      const {
        warehouse,
        groupBuyInfo,
        selectedShippingDate,
        selectedShippingTime,
      } = this.state;
      const deliveryTime = combineShippingTime(
        selectedShippingDate,
        selectedShippingTime
      );
      groupBuyInfo.deliveryTime = deliveryTime;
      await setUserShoppingMode('groupBuy');
      await setUserAddressType('delivery');
      await updateOneUserAttribute(result, 'currentCampaignContentId');
      await updateOneUserAttribute(groupBuyInfo, 'groupBuyInfo');
      history.push(getCustomerPath(`store/${warehouse._id}`));
    } else {
      message.error('error');
    }
  };

  handleSelectAddress = async (node) => {
    // console.log('select node',node);
    const validAddress = await this.fetchMinimumValue(
      addressFormatterOnlyAddress(node)
    );
    if (validAddress === true) {
      const groupBuyInfo = {
        selectedAddress: node._id,
        selectedAddressDetail: node,
      };
      this.setState({ groupBuyInfo });
    }
  };

  handleDateChange = (date, dateString) => {
    this.setState({
      shippingDate: date,
      selectedShippingDate: dateString,
    });
  };

  handleChangeMobileDate = (date) => {
    this.setState({
      shippingDate: moment(date),
      selectedShippingDate: moment(date).format('YYYY-MM-DD'),
      mobileCalendarVisibility: false,
    });
  };

  handleRestartGroupBuying = async () => {
    await this.setState({
      showGroupJoinInput: false,
      showGroupCreate: false,
      groupBuyInfo: null,
      campaignContent: null,
      displayId: null,
    });
  };

  findCampaignContent = async () => {
    let { displayId } = this.state;
    const { userId, intl, history } = this.props;
    if (!userId) {
      history.push(getCustomerPath('login'));
    }
    // form displayId
    const displayIdArray = displayId.match(/[0-9]+/g, displayId);
    console.log(displayIdArray);
    if (
      !displayIdArray ||
      displayIdArray[0].length < 2 ||
      !parseFloat(displayIdArray[0])
    ) {
      console.log('??');
      message.error(intl.formatMessage({ id: 'canNotFoundGroupBuying' }), 15);
      return;
    }
    [displayId] = displayIdArray;

    try {
      const { data } = await apolloClient.query({
        query: FETCH_CAMPAIGN_CONTENT,
        variables: { filter: { displayId: parseFloat(displayId) } },
        fetchPolicy: 'network-only',
      });
      if (data.campaignContentFindMany[0]._id) {
        // console.log(
        //   'data.campaignContentFindMany[0]',
        //   data.campaignContentFindMany[0]
        // );
        await this.setState({
          campaignContentId: data.campaignContentFindMany[0]._id,
          campaignContent: data.campaignContentFindMany[0],
        });
        // message.success('success');
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'canNotFoundGroupBuying' }), 15);
    }
  };

  handleJumpToStore = async () => {
    const { warehouse, groupBuyInfo } = this.state;
    const { updateOneUserAttribute, history } = this.props;
    await updateOneUserAttribute(groupBuyInfo, 'groupBuyInfo');
    history.push(getCustomerPath(`store/${warehouse._id}`));
  };

  handleConfirmJoinGroup = async (campaignContent = null) => {
    const { intl } = this.props;
    if (campaignContent) {
      if (campaignContent.status !== 'active') {
        Modal.warning({
          content: intl.formatMessage({ id: 'finishedRefundGroup' }),
          onOk: () => {
            Modal.destroyAll();
          },
        });
        return;
      }
      this.setState({ campaignContent });
    }
    // show addresses selector
    // this.setState({ showGroupJoin: true, showGroupJoinInput: true });
    const validate = await this.handleJoinCampaign();
    const { campaignContentId } = this.state;
    if (validate) {
      console.log('validate to join');
      const {
        setUserShoppingMode,
        setUserAddressType,
        updateOneUserAttribute,
      } = this.props;
      await setUserShoppingMode('groupBuy');
      await setUserAddressType('pickup');
      await updateOneUserAttribute(
        campaignContentId,
        'currentCampaignContentId'
      );
      this.setState({ showGroupJoin: true });
      // history.push(getCustomerPath(`store/${warehouse._id}`));
    } else {
      console.log('not validate to join');
      Modal.warning({
        content: intl.formatMessage({ id: 'haveJoinedGroup' }),
        onOk: () => {
          Modal.destroyAll();
        },
      });
    }
  };

  handleChangeSwitchValue = (event) => {
    this.setState({ switchValue: event.target.value });
  };

  onChangeInputValue = (event) => {
    // console.log(event.target.value);
    this.setState({ displayId: event.target.value });
  };

  handleDisableDate = (current) => {
    if (window.location.host !== 'bunbao.com') {
      return false;
    }
    if (current > addWeekdays(moment(), 10)) {
      return true;
    }
    if (current <= moment().add(1, 'days')) {
      return true;
    }
    if (moment(current).weekday() === 6 || moment(current).weekday() === 0) {
      return true;
    }
    return false;
  };

  handleDisableMobileDate = (current) => {
    const { availableShippingDate } = this.state;
    if (availableShippingDate && availableShippingDate.length > 0) {
      const found = availableShippingDate.findIndex(
        (e) => e === current.format('YYYY-MM-DD')
      );
      if (found >= 0) {
        return false;
      }
      return { info: 'Disable', disable: true };
    }
    if (current > addWeekdays(moment(), 10)) {
      return { info: 'Disable', disable: true };
    }
    if (current <= moment()) {
      return { info: 'Disable', disable: true };
    }
    if (moment(current).weekday() === 6 || moment(current).weekday() === 0) {
      return { info: 'Disable', disable: true };
    }
    return false;
  };

  handleDisabledHours = () =>
    [...Array(11).keys()].concat([18, 19, 20, 21, 22, 23]);

  handleDisabledHoursTest = () => [];

  handleTimeChange = (time, timeString) => {
    this.setState({ selectedShippingTime: timeString });
  };

  handleShowGroupJoinOrCreate = async (selected) => {
    const { setUserShoppingMode, setUserAddressType } = this.props;
    const { neverShowGroupBuyingGuide } = this.state;
    console.log(neverShowGroupBuyingGuide);
    if (!neverShowGroupBuyingGuide) {
      this.handleOpenGroupBuyingModal();
    }
    await setUserShoppingMode('groupBuy');
    if (selected === 'create') {
      await setUserAddressType('delivery');
      this.setState({ showGroupCreate: true, showGroupJoinInput: false });
    } else {
      await setUserAddressType('pickup');
      this.setState({ showGroupCreate: false, showGroupJoinInput: true });
    }
  };

  getShippingDateMsg = () => {
    const { selectedShippingTime, selectedShippingDate } = this.state;
    const { intl } = this.props;
    if (selectedShippingTime && selectedShippingDate) {
      const newTime = moment(
        combineShippingTime(selectedShippingDate, selectedShippingTime)
      )
        .subtract(36, 'hours')
        .format('MM-DD HH:mm');
      return intl
        .formatMessage({ id: 'groupEndTime' })
        .replace('[time]', newTime);
    }
    return intl.formatMessage({ id: 'groupBuyNotice' });
  };

  handleOpenGroupBuyingModal = () => {
    this.setState({ showGroupBuyingGuideModal: true });
  };

  handleCloseGroupBuyingModal = () => {
    this.setState({ showGroupBuyingGuideModal: false });
  };

  checkDoNotShowModalWhenClickCreateOrJoinButton = async (e) => {
    await localStorage.setItem('neverShowGroupBuyingGuide', e.target.checked);
    await this.setState({
      neverShowGroupBuyingGuide: e.target.checked,
    });
  };

  render() {
    const {
      displayId,
      groupBuyInfo,
      officialCampaignContent,
      selectedShippingTime,
      selectedShippingDate,
      shippingDate,
      groupName,
      groupNote,
      campaignContent,
      showGroupJoin,
      switchValue,
      minimumShippingPrice,
      // shippingRule,
      discountRule,
      warehouse,
      showGroupCreate,
      showGroupJoinInput,
      mobileCalendarVisibility,
      showGroupBuyingGuideModal,
      neverShowGroupBuyingGuide,
    } = this.state;
    const { intl, token } = this.props;

    const renderDateAndOther = (
      <div>
        <Alert
          css={{ margin: '20px 0px' }}
          message={this.getShippingDateMsg()}
          type="warning"
        />
        <Row style={{ margin: '10px 0px' }}>
          <FormattedMessage id="shippingDate" />
          {selectedShippingDate ? successIcon : questionIcon}
        </Row>
        <Row type="flex" justify="center">
          {isMobile ? (
            <div
              role="presentation"
              onClick={() => {
                this.setState({ mobileCalendarVisibility: true });
              }}
            >
              {shippingDate ? (
                <Button type="primary">{selectedShippingDate}</Button>
              ) : (
                <Button type="primary">
                  <FormattedMessage id="pleaseChooseDate" />
                </Button>
              )}
            </div>
          ) : (
            <DatePicker
              style={{ width: '100%' }}
              onChange={this.handleDateChange}
              value={shippingDate}
              disabledDate={this.handleDisableDate}
              placeholder={intl.formatMessage({ id: 'selectYouPickupDate' })}
              showToday={false}
            />
          )}
        </Row>
        {selectedShippingDate ? (
          <div>
            <Row style={{ margin: '10px 0px' }}>
              <FormattedMessage id="deliveryTime" />
              {selectedShippingTime ? successIcon : questionIcon}
            </Row>
            <Row type="flex" justify="center">
              <TimePicker
                style={{ width: '100%' }}
                format="HH:mm"
                minuteStep={15}
                hideDisabledOptions
                defaultOpenValue={moment('11:00', 'HH:mm')}
                disabledHours={
                  window.location.host !== 'bunbao.com'
                    ? this.handleDisabledHoursTest
                    : this.handleDisabledHours
                }
                placeholder={intl.formatMessage({
                  id: 'selectYouDeliveryTime',
                })}
                onChange={this.handleTimeChange}
              />
            </Row>
          </div>
        ) : null}
        {selectedShippingDate && selectedShippingTime ? (
          <div>
            <div style={{ margin: '10px 0' }}>
              <div>
                <FormattedMessage id="groupName" />
                {groupName ? successIcon : questionIcon}
              </div>
              <Input
                name="groupName"
                value={groupName ? `${groupName}` : ''}
                style={{ margin: '15px auto', width: '100%' }}
                onChange={(e) => {
                  this.setState({ groupName: e.target.value });
                }}
              />
            </div>
            <div style={{ margin: '10px 0' }}>
              <div>
                <FormattedMessage id="groupNote" />
              </div>
              <Input
                name="groupNote"
                value={groupNote ? `${groupNote}` : ''}
                style={{ margin: '15px auto', width: '100%' }}
                onChange={(e) => {
                  this.setState({ groupNote: e.target.value });
                }}
              />
            </div>
            <div style={{ margin: '10px 0' }}>
              <div>
                <FormattedMessage id="groupOfficialOrNot" />
                <Tooltip
                  title={intl.formatMessage({
                    id: 'groupBuyFriendOnlyDescription',
                  })}
                >
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
              <Switch
                onChange={(checked) => {
                  this.setState({ officialGroup: !checked });
                }}
                checkedChildren={intl.formatMessage({ id: 'yes' })}
                unCheckedChildren={intl.formatMessage({ id: 'no' })}
                defaultChecked
              />
            </div>
          </div>
        ) : null}
        {selectedShippingDate && selectedShippingTime && groupName ? (
          <Row style={{ margin: '10px 0px', textAlign: 'center' }}>
            <Button
              type="primary"
              shape="round"
              icon={<CheckOutlined />}
              css={{
                height: '50px',
                width: '100%',
                textAlign: 'center',
                margin: '10px 0px',
                padding: '10px 20px',
              }}
              onClick={() => {
                this.handleJumpToStoreForNewGroupBuying();
              }}
            >
              <FormattedMessage id="createGroupBuyingPlaceOrder" />
            </Button>
          </Row>
        ) : null}
      </div>
    );

    const renderGroupCreate = () => {
      if (showGroupCreate && groupBuyInfo === null) {
        return (
          <div style={{ width: '100%' }}>
            <div css={{ width: '80%', margin: '20px auto' }}>
              <Alert
                message={
                  <div>
                    {intl.formatMessage({ id: 'chooseAddressOrCreate' })}{' '}
                    <ArrowDownOutlined />
                  </div>
                }
                type="info"
                showIcon
              />
            </div>
            <AddressSelection handleSelectAddress={this.handleSelectAddress} />
          </div>
        );
      }
      if (groupBuyInfo) {
        return (
          <div style={{ width: 'auto' }}>
            <h2>
              <FormattedMessage id="groupBuyDistanceDescription" />
              {`${minimumShippingPrice} `}
            </h2>
            <div>{renderDateAndOther}</div>
          </div>
        );
      }
      return <div />;
    };

    const renderGroupJoin = () => {
      if (campaignContent && !showGroupJoin) {
        return (
          <Row type="flex" justify="center" css={{ padding: '10px' }}>
            <GroupInfoBox
              discountRule={discountRule}
              element={campaignContent}
              joinInGroup={this.handleConfirmJoinGroup}
            />
            {/* {groupInfoBox(campaignContent, this.handleConfirmJoinGroup)} */}
          </Row>
        );
      }
      if (campaignContent && showGroupJoin && !groupBuyInfo) {
        return (
          <AddressSelection handleSelectAddress={this.handleSelectAddress} />
        );
      }
      if (campaignContent && showGroupJoin && groupBuyInfo) {
        return (
          <Button
            type="primary"
            shape="round"
            icon={<CheckOutlined />}
            css={{
              height: '50px',
              textAlign: 'center',
              margin: '10px 0px',
              padding: '10px 20px',
            }}
            onClick={this.handleJumpToStore}
          >
            <FormattedMessage id="groupJoinAndPlaceOrder" />
          </Button>
        );
      }
      return (
        <div css={{ padding: '20px 0' }}>
          <Input
            css={{ display: 'inline-block', width: '200px' }}
            value={displayId}
            onChange={this.onChangeInputValue}
            placeholder={intl.formatMessage({
              id: 'pleaseTypeGroupBuyingCode',
            })}
          />
          <Button
            css={{
              display: 'inline-block',
              marginLeft: '10px',
            }}
            type="primary"
            onClick={() => {
              this.findCampaignContent();
            }}
          >
            Go
          </Button>
        </div>
      );
    };

    const renderGroupBuyingList = () => {
      return (
        <div
          css={{
            display: 'flex',
            padding: '10px',
            flexDirection: isMobile ? 'column' : 'row',
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {officialCampaignContent.map((campaignContentOne) => (
            <GroupInfoBox
              key={campaignContentOne._id}
              element={campaignContentOne}
              joinInGroup={this.handleConfirmJoinGroup}
              discountRule={discountRule}
            />
          ))}
        </div>
      );
    };

    const renderPublicAndMyGroup = () => {
      if (switchValue === 'groupList') {
        return renderGroupBuyingList();
      }
      if (token) {
        return <MyGroupBuy />;
      }
      return null;
    };

    return (
      <div>
        <Modal
          visible={showGroupBuyingGuideModal}
          onCancel={this.handleCloseGroupBuyingModal}
          onOk={this.handleCloseGroupBuyingModal}
          footer={[
            <Checkbox
              key="agree"
              defaultChecked={neverShowGroupBuyingGuide}
              onChange={this.checkDoNotShowModalWhenClickCreateOrJoinButton}
            >
              <FormattedMessage id="doNotShowAgain" />
            </Checkbox>,
            <Button
              key="back"
              type="primary"
              onClick={this.handleCloseGroupBuyingModal}
            >
              OK
            </Button>,
          ]}
        >
          <GroupBuyingGuide />
        </Modal>
        <div
          css={{
            minHeight: isMobile ? height : '100vh',
            backgroundColor: 'white',
            '& .am-calendar .content': {
              top: '45px',
              height: `calc(${height}px - 45px )`,
            },
          }}
        >
          <Calendar
            type="one"
            visible={mobileCalendarVisibility}
            onCancel={() => {
              this.setState({ mobileCalendarVisibility: false });
            }}
            onConfirm={this.handleChangeMobileDate}
            getDateExtra={this.handleDisableMobileDate}
            // onSelectHasDisableDate={this.onSelectHasDisableDate}
            // getDateExtra={this.getDateExtra}
            // minDate={new Date(+now - 5184000000)}
            // maxDate={new Date(+now + 31536000000)}
          />
          <div
            css={{
              padding: '20px',
              // backgroundColor: '#ecfcff',
              textAlign: 'center',
            }}
          >
            <div
              className="font-effect-fire-animation"
              css={{
                fontSize: isMobile ? '28px ' : '30px',
                fontWeight: '900',
                color: '#DF242F',
                textStroke: '20px black',
                // textShadow:
                // '1px 1px 20px #DF242F, 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135',
              }}
            >
              <FormattedMessage id="groupBuying" />
            </div>
            <div>
              <GroupBuyingMenu warehouse={warehouse} />
            </div>
            <div>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  this.handleOpenGroupBuyingModal();
                }}
              >
                <OrderedListOutlined /> <FormattedMessage id="checkGuide" />
              </Button>
            </div>
          </div>
          <div css={{ display: 'flex', flexWrap: 'wrap' }}>
            <div css={{ width: '50%' }}>
              <div
                css={{
                  fontSize: isMobile ? '16px' : '18px',
                  fontWeight: '900',
                  padding: isMobile ? '15px' : '10px',
                  cursor: 'pointer',
                  backgroundColor: '#e32249',
                  textAlign: 'center',
                  width: '90%',
                  color: 'white',
                  borderRadius: '5px',
                  margin: 'auto',
                }}
                onClick={async () => {
                  await this.handleShowGroupJoinOrCreate('create');
                }}
                role="presentation"
              >
                <FormattedMessage id="createNewGroupBuying" />
                <Tooltip
                  title={intl.formatMessage({ id: 'groupBuyDescriptionFour' })}
                >
                  {' '}
                  <QuestionCircleOutlined css={{ color: 'white' }} />
                </Tooltip>
              </div>
            </div>
            <div css={{ width: '50%' }}>
              <div
                css={{
                  fontSize: isMobile ? '16px' : '18px',
                  fontWeight: '900',
                  padding: isMobile ? '15px' : '10px',
                  cursor: 'pointer',
                  backgroundColor: '#f17362',
                  textAlign: 'center',
                  width: '90%',
                  color: 'white',
                  borderRadius: '5px',
                  margin: 'auto',
                }}
                onClick={async () => {
                  await this.handleShowGroupJoinOrCreate('join');
                }}
                role="presentation"
              >
                <FormattedMessage id="joinGroupBuying" />
              </div>
            </div>
          </div>
          <div>
            {showGroupCreate ? (
              <div
                css={{
                  minHeight: '100px',
                  // backgroundColor: '#e32249',
                  padding: '10px 0px 20px 0px',
                }}
              >
                <Row type="flex" justify="center">
                  <React.Fragment>{renderGroupCreate()}</React.Fragment>
                </Row>
                <div
                  css={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '25px',
                  }}
                >
                  <Button
                    shape="round"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      this.handleRestartGroupBuying();
                    }}
                  >
                    <FormattedMessage id="cancelAndRestart" />
                  </Button>
                </div>
              </div>
            ) : null}
            {showGroupJoinInput ? (
              <div
                css={{
                  // margin: '0px 10px 10px 10px',
                  // backgroundColor: '#f17362',
                  textAlign: 'center',
                  minHeight: '100px',
                }}
              >
                {renderGroupJoin()}
                <div
                  css={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '25px',
                    marginLeft: '10px',
                  }}
                >
                  <Button
                    shape="round"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      this.handleRestartGroupBuying();
                    }}
                  >
                    <FormattedMessage id="cancelAndRestart" />
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div css={{ marginTop: '50px' }}>
            <Divider>
              <Radio.Group
                size="large"
                defaultValue="myGroupBuy"
                value={switchValue}
                buttonStyle="solid"
                onChange={this.handleChangeSwitchValue}
              >
                <Radio.Button value="groupList">
                  <FormattedMessage id="openingPublicGroupBuying" />
                </Radio.Button>
                <Radio.Button value="myGroupBuy">
                  <FormattedMessage id="myGroupBuy" />
                </Radio.Button>
              </Radio.Group>
            </Divider>
          </div>
          <div css={{ margin: '10px 0', overflow: 'scroll' }}>
            {renderPublicAndMyGroup()}
          </div>
        </div>
      </div>
    );
  }
}

GroupBuying.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  updateOneUserAttribute: PropTypes.func.isRequired,
  saveLinkForLogin: PropTypes.func.isRequired,
  userId: PropTypes.string,
  token: PropTypes.string,
  currentAddressType: PropTypes.string,
};

GroupBuying.defaultProps = {
  userId: null,
  currentAddressType: null,
  token: null,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  userId: state.user.userId,
  token: state.auth.token,
  currentAddressType: state.user.currentAddressType,
});

const mapDispatchToProps = (dispatch) => ({
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
  setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
  setUserAddressType: (mode) => dispatch(actions.setUserAddressType(mode)),
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GroupBuying));
