import gql from 'graphql-tag';

export const FETCH_MY_MESSAGE = gql`
  {
    messageMany {
      _id
      receiver {
        email
      }
      createdAt
      isRead
      type
      customerOrderId
      customerOrder {
        variants {
          name {
            _id
            en
            zh
          }
          quantity
        }
        warehouse {
          addressOne
          addressTwo
          partnerName
          name
          city
          fullAddress
          location {
            coordinates
          }
        }
        _id
        displayId
        warehouseStatus
      }
      inventory {
        warehouse {
          name
        }
        variants {
          variant {
            name {
              en
              zh
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_MY_MESSAGE = gql`
  mutation ($input: UpdateOneMessageInput!, $Id: MongoID) {
    messageUpdateOne(input: { record: $input, filter: { _id: $Id } }) {
      record {
        _id
      }
    }
  }
`;

export const MESSAGE_USER_NEW_LAST_UNREAD_MESSAGE = gql`
  query {
    messageCurrentUserNewMessage {
      _id
      receiver {
        email
      }
      createdAt
      isRead
      type
      customerOrderId
      customerOrder {
        variants {
          name {
            en
            zh
          }
          quantity
        }
        warehouse {
          addressOne
          addressTwo
          partnerName
          name
          city
          fullAddress
          location {
            coordinates
          }
        }
        _id
        displayId
        warehouseStatus
      }
      inventory {
        warehouse {
          name
        }
        variants {
          variant {
            name {
              en
              zh
            }
          }
        }
      }
    }
  }
`;

export const MESSAGE_USER_NEW_MESSAGE = gql`
  subscription {
    messageAdded {
      _id
      receiver {
        email
      }
      createdAt
      isRead
      type
      customerOrderId
      customerOrder {
        variants {
          name {
            en
            zh
          }
          quantity
        }
        warehouse {
          addressOne
          addressTwo
          partnerName
          name
          city
          fullAddress
          location {
            coordinates
          }
        }
        _id
        displayId
        warehouseStatus
      }
      inventory {
        warehouse {
          name
        }
        variants {
          variant {
            name {
              en
              zh
            }
          }
        }
      }
    }
  }
`;

export const MESSAGE_PUSH_NOTIFICATION = gql`
  mutation (
    $title: String!
    $text: String!
    $image: String!
    $tag: String!
    $url: String!
  ) {
    subscriberPushNotification(
      title: $title
      text: $text
      image: $image
      tag: $tag
      url: $url
    ) {
      totalCount
      errorCount
    }
  }
`;
