/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { Row, Col, message, Modal } from 'antd';
import { Modal as MobileModal, List as MobileList } from 'antd-mobile';
import { isMobile } from 'react-device-detect';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import ReactRouterPropTypes from 'react-router-prop-types';
import { RightOutlined } from '@ant-design/icons';
import * as actions from '../../../store/actions';
import {
  getCustomerPath,
  apolloClient,
  checkAlipayUserAgent,
} from '../../../util';

import facebook from '../../../assets/images/icons/facebook.svg';
import instagram from '../../../assets/images/icons/instagram.svg';
import yelp from '../../../assets/images/icons/yelp.svg';
// import backgroundImage from '../../../assets/images/homepage/background.png';

// import userGroup from '../../../assets/images/homepage/userGroup.svg';
// import ridingScooter from '../../../assets/images/homepage/ridingScooter.svg';
// import openMaps from '../../../assets/images/homepage/openMaps.svg';
// import navigation from '../../../assets/images/homepage/navigation.svg';
// import rocketBoy from '../../../assets/images/homepage/rocket_boy.svg';
// import groupBuy from '../../../assets/images/homepage/groupBuy.svg';
// import aroundMe from '../../../assets/images/homepage/aroundMe.svg';
// import nurse from '../../../assets/images/homepage/health.svg';
// import bunbaoLogo from '../../../assets/images/bunBaoLogo.png';
// import cloudStore from '../../../assets/images/homepage/cloudStore.svg';
import scheduledDelivery from '../../../assets/images/homepage/scheduledDelivery.svg';

import { BANNER_FIND_MANY } from '../../../api/banner';
import MenuBanner from '../../../components/MenuBanner/MenuBanner';
import CustomerFooter from '../../../components/Footer/CustomerFooter/CustomerFooter';
import { BUCKET_CDN_URL } from '../../../config';
import VideoBanner from '../../../components/VideoBanner/VideoBanner';
import CustomerScheduledDeliveryModal from '../../../components/Modals/CustomerScheduledDeliveryModal/CustomerScheduledDeliveryModal';

const { Item: MobileItem } = MobileList;
const { Brief: MobileBrief } = MobileItem;

const { innerHeight: height } = window;

export class Home extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      slides: [],
      videoSlides: [],
      squares: [],
      scheduledDeliveryModalVisibility: false,
      cloudStorePickupModalVisibility: false,
      bunbaoPrimeModalVisibility: false,
    };
  }

  componentDidMount = async () => {
    await this.handleFetchBanner();
    await this.handleFetchSquares();
    await this.handleFetchVideos();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  handleFetchBanner = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'homepage' } },
      });
      // sort banner
      const rawData = bannerFindMany;
      const slides = _.orderBy(rawData, ['ranking'], ['desc']);
      console.log('slides', slides);
      this.setState({ slides });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  handleFetchVideos = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'video' } },
      });
      // sort banner
      const rawData = bannerFindMany;
      const videos = _.orderBy(rawData, ['ranking'], ['desc']);
      console.log('video', videos);
      this.setState({ videoSlides: videos });
    } catch (error) {
      message.error('Can not fetch video');
    }
  };

  handleFetchSquares = async () => {
    try {
      const {
        data: { bannerFindMany },
      } = await apolloClient.query({
        query: BANNER_FIND_MANY,
        variables: { filter: { deleted: false, category: 'square' } },
      });
      const rawData = bannerFindMany;
      const squares = _.orderBy(rawData, ['ranking'], ['desc']);
      console.log('squares', squares);
      this.setState({ squares });
    } catch (error) {
      message.error('Can not fetch banner');
    }
  };

  handleShowScheduledDeliveryModal = async () => {
    this.setState({ scheduledDeliveryModalVisibility: true });
  };

  handleShowBunBaoPrimeModal = async () => {
    this.setState({ bunbaoPrimeModalVisibility: true });
  };

  handleShowCloudStorePickupModal = async () => {
    this.setState({ cloudStorePickupModalVisibility: true });
  };

  handleCloseAllMobileModal = async () => {
    this.setState({
      scheduledDeliveryModalVisibility: false,
      bunbaoPrimeModalVisibility: false,
      cloudStorePickupModalVisibility: false,
    });
  };

  handleMenuClick = async (value) => {
    const {
      history,
      setUserAddressType,
      setUserShoppingMode,
      updateOneUserAttribute,
      isFarmerMarketUser,
    } = this.props;
    console.log(value);
    switch (value.key) {
      case 'cloudStorePickupAsap':
        history.push(getCustomerPath('map?mode=pickupASAP'));
        break;
      case 'cloudStoreDeliveredAsap':
        history.push(getCustomerPath('map?mode=deliveredASAP'));
        break;
      case 'bunbaoMenu':
        await setUserAddressType('delivery');
        await setUserShoppingMode('delivery');
        await updateOneUserAttribute(null, 'preselectPickupInfo');
        await updateOneUserAttribute(null, 'currentCampaignContentId');
        // history.push(getCustomerPath('delivery-menu'));
        if (isFarmerMarketUser) {
          history.push(getCustomerPath('supermarket?special=true'));
        } else {
          history.push(getCustomerPath('supermarket'));
        }
        break;
      case 'scheduledPickup':
        history.push(getCustomerPath('map?mode=scheduledPickup'));
        break;
      case 'bunbaoCatering':
        history.push(getCustomerPath('catering'));
        break;
      case 'bunbaoGame':
        history.push(getCustomerPath('games'));
        break;
      case 'farmersMarket':
        history.push(getCustomerPath('map?mode=scheduledDelivery'));
        break;
      default:
        console.log('do nothing');
    }
  };

  render() {
    const {
      language,
      // setUserAddressType,
      // setUserShoppingMode,
      // updateOneUserAttribute,
    } = this.props;
    const {
      slides,
      squares,
      videoSlides,
      scheduledDeliveryModalVisibility,
      cloudStorePickupModalVisibility,
      bunbaoPrimeModalVisibility,
    } = this.state;

    const blockTitle = {
      textAlign: 'center',
      fontSize: '38px',
      fontWeight: '500',
      color: 'rgba(0,131,138)',
      lineHeight: '54px',
      letterSpacing: '0ch',
    };

    const mobileBlockTitle = {
      textAlign: 'center',
      fontSize: '23px',
      fontWeight: '500',
      color: 'rgba(0,131,138)',
      lineHeight: '40px',
      letterSpacing: '0ch',
    };

    const blockStyle = { marginTop: '25px' };

    const device = isMobile ? 'mobile' : 'desktop';

    return (
      <div>
        <div css={{ minHeight: '100vh', overflowX: 'hidden' }}>
          {isMobile ? (
            <React.Fragment>
              <MobileModal
                popup
                visible={scheduledDeliveryModalVisibility}
                onClose={this.handleCloseAllMobileModal}
                animationType="slide-up"
              >
                {/* <CustomerScheduledDeliveryModal /> */}
                <MobileList renderHeader={() => 'Please choose a service'}>
                  <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'farmersMarket' });
                    }}
                  >
                    <FormattedMessage id="scheduledDelivery" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.specialScheduledDeliveryExplain" />
                    </MobileBrief>
                  </MobileItem>
                  <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'scheduledPickup' });
                    }}
                  >
                    <FormattedMessage id="scheduledPickup" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.scheduledPickupExplain" />
                    </MobileBrief>
                  </MobileItem>
                </MobileList>
              </MobileModal>
              <MobileModal
                // title={language === 'zh' ? '请选择' : 'Please Choose'}
                popup
                visible={cloudStorePickupModalVisibility}
                onClose={this.handleCloseAllMobileModal}
                animationType="slide-up"
              >
                <MobileList renderHeader={() => 'Please choose a service'}>
                  <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'cloudStorePickupAsap' });
                    }}
                  >
                    <FormattedMessage id="allStores" />
                    <MobileBrief>
                      <FormattedMessage id="dineInIconDetails" />
                    </MobileBrief>
                  </MobileItem>
                  <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'cloudStoreDeliveredAsap' });
                    }}
                  >
                    <FormattedMessage id="deliveredASAP" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.pickupASAPExplain" />
                    </MobileBrief>
                  </MobileItem>
                </MobileList>
              </MobileModal>
              <MobileModal
                // title={language === 'zh' ? '请选择' : 'Please Choose'}
                popup
                visible={bunbaoPrimeModalVisibility}
                onClose={this.handleCloseAllMobileModal}
                animationType="slide-up"
              >
                <MobileList renderHeader={() => 'Please choose a service'}>
                  <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'bunbaoMenu' });
                    }}
                  >
                    <FormattedMessage id="scheduledDelivery" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.scheduledDeliveryExplain" />
                    </MobileBrief>
                  </MobileItem>
                  <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'scheduledPickup' });
                    }}
                  >
                    <FormattedMessage id="scheduledPickup" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.scheduledPickupExplain" />
                    </MobileBrief>
                  </MobileItem>
                  {/* <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'bunbaoCatering' });
                    }}
                  >
                    <FormattedMessage id="catering" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.cateringExplain" />
                    </MobileBrief>
                  </MobileItem> */}
                  {/* <MobileItem
                    arrow="horizontal"
                    onClick={() => {
                      this.handleMenuClick({ key: 'game' });
                    }}
                  >
                    <FormattedMessage id="game" />
                    <MobileBrief>
                      <FormattedMessage id="homepage.gameExplain" />
                    </MobileBrief>
                  </MobileItem> */}
                </MobileList>
              </MobileModal>
            </React.Fragment>
          ) : (
            <Modal
              footer={null}
              header={null}
              onCancel={this.handleCloseAllMobileModal}
              visible={scheduledDeliveryModalVisibility}
            >
              <CustomerScheduledDeliveryModal />
            </Modal>
          )}

          {isMobile ? (
            <React.Fragment>
              <div css={{ backgroundColor: 'white' }}>
                <Row
                  css={{
                    height: '100vh',
                  }}
                >
                  <Col span={24}>
                    <div
                      css={{
                        backgroundImage: `url(${BUCKET_CDN_URL}images/backgrounds/background.png)`,
                        minHeight: '20vh',
                        position: 'relative',
                        backgroundSize: '500px',
                      }}
                    >
                      <div
                        css={{
                          bottom: '0',
                          position: 'absolute',
                          borderRadius: '50% / 100% 100% 0 0',
                          backgroundColor: 'white',
                          height: '15vh',
                          width: '100%',
                        }}
                      />
                      <div
                        css={{
                          top: `${language === 'zh' ? '11vh' : '7vh'}`,
                          fontFamily:
                            'Kalam, Futura, "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB", "Droid Sans Fallback", "Microsoft YaHei", sans-serif',
                          fontSize: '1.4em',
                          fontWeight: '600',
                          textAlign: 'center',
                          width: '100%',
                          color: 'black',
                          position: 'absolute',
                        }}
                      >
                        {/* {language === 'zh' ? (
                          <span
                            css={{
                              fontFamily: 'li-xia',
                              fontSize: '1.4em',
                              fontWeight: '500',
                            }}
                          >
                            棒包，送到家
                          </span>
                        ) : ( */}
                        <span>
                          BunBao
                          <br />
                          Handcrafted Quality
                          <br />
                          Delivered Fresh
                        </span>
                        {/* )} */}
                      </div>
                    </div>
                  </Col>
                  {/* YouTube Slides */}
                  {videoSlides.length > 0 && (
                    <Col span={24}>
                      <VideoBanner
                        css={{ zIndex: '10' }}
                        videos={videoSlides}
                      />
                    </Col>
                  )}
                  {/* YouTube Slides */}
                  <Col span={24}>
                    <div
                      css={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        userSelect: 'none',
                        margin: '10px',
                        '& .mobile-inner-button-style': {
                          margin: '10px',
                          color: 'black',
                          fontSize: '15px',
                          fontWeight: 'bold',
                          padding: '5px 10px 5px 10px',
                          borderRadius: '2px',
                          width: '70%',
                          display: 'flex',
                          boxShadow:
                            '10px 10px 25px #8a8a8a, -10px -10px 25px #ffffff',
                          justifyContent: 'center',
                          alignItems: 'center',
                          '& .mobile-inner-button-title': {
                            height: '100%',
                            margin: '0 auto',
                            textAlign: 'center',
                          },
                        },
                        // ':hover': {
                        //   background: 'linear-gradient(190deg, #e6e6e6, #ffffff)',
                        //   boxShadow:
                        //     'inset 10px 10px 25px #8a8a8a, inset -10px -10px 25px #ffffff',
                        // },
                        '& img': {
                          height: '48px',
                          width: '48px',
                        },
                      }}
                    >
                      {/* <div
                        className="mobile-inner-button-style"
                        onClick={async () => {
                          // this.handleShowBunBaoPrimeModal();
                          this.handleMenuClick({ key: 'bunbaoMenu' });
                        }}
                        role="presentation"
                      >
                        <img src={bunbaoLogo} alt="Cloud Store" />
                        <div className="mobile-inner-button-title">
                          <FormattedMessage id="homepage.BunBao" />
                        </div>
                      </div> */}
                      <div
                        className="mobile-inner-button-style"
                        onClick={async () => {
                          // this.handleShowScheduledDeliveryModal();
                          this.handleMenuClick({ key: 'scheduledPickup' });
                        }}
                        role="presentation"
                      >
                        <img src={scheduledDelivery} alt="Cloud Store" />
                        <div className="mobile-inner-button-title">
                          <FormattedMessage id="homepage.farmersMarket" />
                        </div>
                      </div>
                      {/* <div
                        className="mobile-inner-button-style"
                        onClick={async () => {
                          this.handleShowCloudStorePickupModal();
                        }}
                        role="presentation"
                      >
                        <img src={cloudStore} alt="Cloud Store" />
                        <div className="mobile-inner-button-title">
                          <FormattedMessage id="homepage.cloudStore" />
                        </div>
                      </div> */}
                    </div>
                  </Col>
                  <Col
                    span={24}
                    css={{
                      margin: '40px auto 0 auto',
                    }}
                  >
                    <div
                      css={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <MenuBanner slides={slides} language={language} />
                    </div>
                  </Col>
                  <Col span={24} css={blockStyle}>
                    <div
                      css={{
                        marginTop: '25px',
                        marginRight: isMobile ? '0px' : '50px',
                        marginLeft: isMobile ? '0px' : '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        '& img': {
                          userSelect: 'none',
                          height: '360px',
                          width: isMobile ? '100%' : '360px',
                          objectFit: 'cover',
                        },
                        '& .square-block': {
                          position: 'relative',
                          height: '360px',
                          textAlign: 'center',
                          width: isMobile ? '100%' : '360px',
                          margin: '10px',
                          ':hover': {
                            visibility: 'visible',
                            backgroundColor: 'white',
                          },
                        },
                        '& .description': {
                          opacity: '0.0',
                          position: 'relative',
                          height: '360px',
                          width: isMobile ? '100%' : '360px',
                          bottom: '360px',
                          overflow: 'hidden',
                          fontSize: '13px',
                          fontWeight: '500',
                          padding: '10px',
                          marginRight: 'auto',
                          marginLeft: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'black',
                          ':hover': {
                            opacity: '0.9',
                            backgroundColor: 'white',
                          },
                        },
                      }}
                    >
                      {squares.map((square) =>
                        square.banners.map((banner) => {
                          return banner.language === language &&
                            banner.device === device ? (
                            <div className="square-block" key={square._id}>
                              <img
                                src={`${BUCKET_CDN_URL}${banner.picture.key}`}
                                alt="square"
                              />
                              <div className="description">
                                <span>
                                  {square.description[language]
                                    .split('\n')
                                    .map((element, index) => {
                                      if (index === 0) {
                                        return (
                                          <div
                                            key={nanoid(5)}
                                            css={{
                                              fontWeight: '700',
                                              fontSize: '18px',
                                            }}
                                          >
                                            {element}
                                          </div>
                                        );
                                      }
                                      return (
                                        <div key={nanoid(5)}>{element}</div>
                                      );
                                    })}
                                </span>
                              </div>
                            </div>
                          ) : null;
                        })
                      )}
                    </div>
                  </Col>
                  {checkAlipayUserAgent() !== true && (
                    <Col span={24} css={blockStyle}>
                      <div css={mobileBlockTitle}>
                        <FormattedMessage id="followUs" />
                      </div>
                      <div
                        css={{
                          marginTop: '25px',
                          marginBottom: '25px',
                          textAlign: 'center',
                          '& img': {
                            height: '64px',
                            width: '64px',
                            margin: '0px 10px',
                          },
                        }}
                      >
                        <a
                          href="https://www.facebook.com/bunbaoofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={facebook} alt="Facebook Icon" />
                        </a>
                        <a
                          href="https://www.yelp.com/biz/bunbao-fremont-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={yelp} alt="Yelp Icon" />
                        </a>
                        <a
                          href="https://www.instagram.com/bunbao_official/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={instagram} alt="Instagram Icon" />
                        </a>
                      </div>
                    </Col>
                  )}
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <CustomerFooter />
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          ) : (
            <Row>
              <Col span={24}>
                <div
                  css={{
                    minHeight: '30vh',
                    position: 'relative',
                    backgroundImage: `url(${BUCKET_CDN_URL}images/backgrounds/background.png)`,
                    backgroundSize: '1800px',
                  }}
                >
                  <div
                    css={{
                      bottom: '0',
                      position: 'absolute',
                      borderRadius: '50% / 100% 100% 0 0',
                      backgroundColor: 'white',
                      height: '25vh',
                      width: '100%',
                    }}
                  />
                  <div
                    css={{
                      top: `${language === 'zh' ? '12vh' : '7vh'}`,
                      position: 'absolute',
                      fontFamily:
                        'Kalam, Futura, "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB", "Droid Sans Fallback", "Microsoft YaHei", sans-serif',
                      fontSize: height < 650 ? '1.4em' : '2.5em',
                      fontWeight: '700',
                      textAlign: 'center',
                      width: '100%',
                      color: 'black',
                    }}
                  >
                    {/* {language === 'zh' ? (
                      <span
                        css={{
                          fontFamily: 'li-xia',
                          fontSize: '2em',
                          fontWeight: '500',
                        }}
                      >
                        棒包，送到家
                      </span>
                    ) : ( */}
                    <span>
                      BunBao
                      <br />
                      Handcrafted Quality
                      <br />
                      Delivered Fresh
                    </span>
                    {/* )} */}
                  </div>
                </div>
              </Col>
              {/* YouTube Slides */}
              {videoSlides.length > 0 && (
                <Col span={24}>
                  <VideoBanner videos={videoSlides} />
                </Col>
              )}
              {/* YouTube Slides */}
              <Col span={24} css={{ marginTop: '10px' }}>
                <div
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    userSelect: 'none',
                    '& .service-button-style': {
                      margin: '10px',
                      // background: 'linear-gradient(15deg, #ffffff, #e6e6e6)',
                      // boxShadow:
                      //   '10px 10px 25px #8a8a8a, -10px -10px 25px #ffffff',
                      // borderRadius: '4px',
                      display: 'flex',
                      width: '220px',
                      '& .inner-button-style': {
                        width: '100%',
                        color: 'black',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        padding: '10px 10px 10px 10px',
                        borderRadius: '2px',
                        boxShadow:
                          '10px 10px 25px #8a8a8a, -10px -10px 25px #ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                      // ':hover': {
                      //   background: 'linear-gradient(190deg, #e6e6e6, #ffffff)',
                      //   boxShadow:
                      //     'inset 10px 10px 25px #8a8a8a, inset -10px -10px 25px #ffffff',
                      // },
                      '& img': {
                        height: '64px',
                        width: '64px',
                      },
                      '& .service-title': {
                        width: '110px',
                        textAlign: 'center',
                        // padding: language === 'zh' ? '15px' : '0px',
                      },
                    },
                  }}
                >
                  {/* <div className="service-button-style"> */}
                  {/* <Dropdown
                      overlay={
                        <Menu onClick={this.handleMenuClick}>
                          <Menu.Item key="bunbaoMenu">
                            <FormattedMessage id="scheduledDelivery" />
                          </Menu.Item>
                          <Menu.Item key="scheduledPickup">
                            <FormattedMessage id="scheduledPickup" />
                          </Menu.Item>
                          <Menu.Item key="bunbaoCatering">
                            <FormattedMessage id="catering" />
                          </Menu.Item>
                          <Menu.Item key="bunbaoGame">
                            <FormattedMessage id="game" />
                          </Menu.Item>
                        </Menu>
                      }
                    > */}
                  {/* <div
                      className="inner-button-style"
                      onClick={() => {
                        this.handleMenuClick({ key: 'bunbaoMenu' });
                      }}
                      css={{ cursor: 'pointer' }}
                      role="presentation"
                    >
                      <img src={bunbaoLogo} alt="BunBao" />
                      <div className="service-title">
                        <FormattedMessage id="homepage.BunBao" />
                      </div>
                      <div>
                        <RightOutlined />
                      </div>
                    </div> */}
                  {/* </Dropdown> */}
                  {/* </div> */}
                  <div className="service-button-style">
                    {/* <Dropdown
                      overlay={
                        <Menu onClick={this.handleMenuClick}>
                          <Menu.Item key="farmersMarket">
                            <FormattedMessage id="scheduledDelivery" />
                          </Menu.Item>
                          <Menu.Item key="scheduledPickup">
                            <FormattedMessage id="scheduledPickup" />
                          </Menu.Item>
                        </Menu>
                      }
                    > */}
                    <div
                      className="inner-button-style"
                      onClick={() => {
                        this.handleMenuClick({ key: 'scheduledPickup' });
                      }}
                      css={{ cursor: 'pointer' }}
                      role="presentation"
                    >
                      <img src={scheduledDelivery} alt="Delivery" />
                      <div className="service-title">
                        <FormattedMessage id="homepage.farmersMarket" />
                      </div>
                      <div>
                        <RightOutlined />
                      </div>
                    </div>
                    {/* </Dropdown> */}
                  </div>
                  {/* <div className="service-button-style">
                    <Dropdown
                      overlay={
                        <Menu onClick={this.handleMenuClick}>
                          <Menu.Item key="cloudStorePickupAsap">
                            <FormattedMessage id="allStores" />
                          </Menu.Item>
                          <Menu.Item key="cloudStoreDeliveredAsap">
                            <FormattedMessage id="deliveredASAP" />
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <div className="inner-button-style">
                        <img src={cloudStore} alt="Cloud Store" />
                        <div className="service-title">
                          <FormattedMessage id="cloudStore" />
                        </div>
                        <div>
                          <DownOutlined />
                        </div>
                      </div>
                    </Dropdown>
                  </div> */}
                </div>
              </Col>
              <Col
                span={24}
                css={{
                  margin: '80px auto 0 auto',
                }}
              >
                <div
                  css={{
                    maxWidth: '1300px',
                    width: '90%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <MenuBanner slides={slides} language={language} />
                </div>
              </Col>
              <Col span={24} css={blockStyle}>
                <div
                  css={{
                    maxWidth: '1160px',
                    marginTop: '25px',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    flexWrap: 'wrap',
                    '& img': {
                      userSelect: 'none',
                      height: '360px',
                      width: '360px',
                      objectFit: 'cover',
                    },
                    '& .square-block': {
                      userSelect: 'none',
                      position: 'relative',
                      textAlign: 'center',
                      height: '360px',
                      width: '360px',
                      margin: '10px',
                      ':hover': {
                        visibility: 'visible',
                        backgroundColor: 'white',
                      },
                    },
                    '& .description': {
                      userSelect: 'none',
                      opacity: '0.0',
                      position: 'relative',
                      height: '360px',
                      width: '360px',
                      bottom: '360px',
                      overflow: 'hidden',
                      fontSize: '13spx',
                      fontWeight: '500',
                      display: 'flex',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      alignItems: 'center',
                      padding: '10px',
                      justifyContent: 'center',
                      color: 'black',
                      ':hover': {
                        opacity: '0.9',
                        backgroundColor: 'white',
                      },
                    },
                  }}
                >
                  {squares.map((square) =>
                    square.banners.map((banner) => {
                      return banner.language === language &&
                        banner.device === device ? (
                        <div className="square-block" key={square._id}>
                          <img
                            src={`${BUCKET_CDN_URL}${banner.picture.key}`}
                            alt="square"
                          />
                          <div className="description">
                            <span>
                              {square.description[language]
                                .split('\n')
                                .map((element, index) => {
                                  if (index === 0) {
                                    return (
                                      <div
                                        key={nanoid(5)}
                                        css={{
                                          fontWeight: '700',
                                          fontSize: '18px',
                                        }}
                                      >
                                        {element}
                                      </div>
                                    );
                                  }
                                  return <div key={nanoid(5)}>{element}</div>;
                                })}
                            </span>
                          </div>
                        </div>
                      ) : null;
                    })
                  )}
                </div>
              </Col>
              <Col span={24} css={blockStyle}>
                <div css={blockTitle}>
                  <FormattedMessage id="followUs" />
                </div>
                <div
                  css={{
                    marginTop: '25px',
                    marginBottom: '25px',
                    textAlign: 'center',
                    '& img': {
                      height: '64px',
                      width: '64px',
                      margin: '0px 10px',
                    },
                  }}
                >
                  <a
                    href="https://www.facebook.com/bunbaoofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="Facebook Icon" />
                  </a>
                  <a
                    href="https://www.yelp.com/biz/bunbao-fremont-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={yelp} alt="Yelp Icon" />
                  </a>
                  <a
                    href="https://www.instagram.com/bunbao_official/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instagram} alt="Instagram Icon" />
                  </a>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  updateOneUserAttribute: PropTypes.func.isRequired,
  setUserAddressType: PropTypes.func.isRequired,
  setUserShoppingMode: PropTypes.func.isRequired,
  // saleorId: PropTypes.string,
  // loginRedirectPage: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
  isFarmerMarketUser: PropTypes.bool,
};

Home.defaultProps = {
  // saleorId: null,
  // loginRedirectPage: '',
  isFarmerMarketUser: false,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  isFarmerMarketUser: state.user.isFarmerMarketUser,
  // loginRedirectPage: state.auth.loginRedirectPage,
  // saleorId: state.auth.saleorId,
});

const mapDispatchToProps = (dispatch) => ({
  updateOneUserAttribute: (input, attributeName) =>
    dispatch(actions.updateOneUserAttribute(input, attributeName)),
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
  setUserShoppingMode: (mode) => dispatch(actions.setUserShoppingMode(mode)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home));
