import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  userId: null,
  email: null,
  code: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  group: null,
  language: 'en',
  currentShoppingMode: null, // pickup, delivery, donation
  currentAddressType: null, // pickup, delivery
  currentCampaignContentId: null,
  preselectPickupInfo: null,
  groupBuyInfo: null,
  overWriteAddress: null,
  saleorId: null,
  isNewUser: null,
  callback: null,
  customerOrderCount: null,
  referralCode: null,
  isFarmerMarketNewUser: false,
  isFarmerMarketUser: false,
  createdAt: null,
};

export const userUpdate = (state, action) => {
  const updateState = {
    userId: action.userId,
    email: action.email,
    code: action.code,
    firstName: action.firstName,
    lastName: action.lastName,
    phoneNumber: action.phoneNumber,
    group: action.group,
    language: action.language,
    isNewUser: action.isNewUser,
    customerOrderCount: action.customerOrderCount,
    referralCode: action.referralCode,
    isFarmerMarketNewUser: action.isFarmerMarketNewUser,
    isFarmerMarketUser: action.isFarmerMarketUser,
    createdAt: action.createdAt,
  };
  return updateObject(state, updateState);
};

export const shoppingModeUpdate = (state, action) => {
  const updateState = {
    currentShoppingMode: action.currentShoppingMode,
  };
  return updateObject(state, updateState);
};

export const addressTypeUpdate = (state, action) => {
  const updateState = {
    currentAddressType: action.currentAddressType,
  };
  return updateObject(state, updateState);
};

export const getSaleorUserUpdate = (state, action) => {
  const updateState = {
    email: action.email,
  };
  return updateObject(state, updateState);
};

export const callbackUpdate = (state, action) => {
  const updateState = {
    callback: action.callback,
  };
  return updateObject(state, updateState);
};

export const updateGroup = (state, action) => {
  const updateState = {
    group: action.group,
  };
  return updateObject(state, updateState);
};

export const attributeUpdate = (state, action) => {
  const updateState = {
    [action.attributeName]: action.attributeValue,
  };
  // console.log("updateState",updateState);
  return updateObject(state, updateState);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_UPDATE:
      return userUpdate(state, action);
    case actionTypes.SHOPPING_MODE_UPDATE:
      return shoppingModeUpdate(state, action);
    case actionTypes.ADDRESS_TYPE_UPDATE:
      return addressTypeUpdate(state, action);
    case actionTypes.GET_SALEOR_USER:
      return getSaleorUserUpdate(state, action);
    case actionTypes.CALLBACK_OVERWRITE:
      return callbackUpdate(state, action);
    case actionTypes.ATTRIBUTE_UPDATE:
      return attributeUpdate(state, action);
    default:
      return state;
  }
};
