/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Form, message, TimePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import FormBuilder from 'antd-form-builder';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { CATERING_EMAIL_REQUEST } from '../../../api/order';
import { apolloClient } from '../../../util';
// import PropTypes from 'prop-types';

function CateringCustomizeForm() {
  const [form] = Form.useForm();

  const meta = [
    {
      key: 'companyName',
      label: <FormattedMessage id="customerCateringHome.companyName" />,
      required: true,
    },
    {
      key: 'name',
      label: <FormattedMessage id="customerCateringHome.name" />,
      required: true,
    },
    {
      key: 'address1',
      label: <FormattedMessage id="customerCateringHome.address1" />,
      required: true,
    },
    {
      key: 'address2',
      label: <FormattedMessage id="customerCateringHome.address2" />,
      required: true,
    },
    {
      key: 'city',
      label: <FormattedMessage id="customerCateringHome.city" />,
      required: true,
    },
    {
      key: 'zip',
      label: <FormattedMessage id="customerCateringHome.zip" />,
      required: true,
    },
    {
      key: 'email',
      label: <FormattedMessage id="customerCateringHome.email" />,
      required: true,
    },
    {
      key: 'phone',
      label: <FormattedMessage id="customerCateringHome.phone" />,
      required: true,
    },
    {
      key: 'date',
      label: <FormattedMessage id="customerCateringHome.date" />,
      widget: 'date-picker',
      required: true,
    },
    {
      key: 'time',
      label: <FormattedMessage id="customerCateringHome.time" />,
      widget: TimePicker,
      required: true,
    },
    {
      key: 'headcount',
      label: <FormattedMessage id="customerCateringHome.headCount" />,
      widget: 'number',
      required: true,
    },
    {
      key: 'notes',
      label: <FormattedMessage id="customerCateringHome.notes" />,
      widget: 'textarea',
      widgetProps: {
        autoSize: { minRows: 4 },
      },
    },
  ];

  const handleSubmit = async (values) => {
    console.log(values);
    const {
      companyName,
      name,
      address1,
      address2,
      city,
      zip,
      email,
      phone,
      ext,
      date,
      time,
      notes,
      headcount,
    } = values;
    const dateString = moment(date).format('YYYY-MM-DD');
    const timeString = moment(time).format('HH:mm');
    console.log(`${dateString} ${timeString}`);
    try {
      const response = await apolloClient.mutate({
        mutation: CATERING_EMAIL_REQUEST,
        variables: {
          companyName,
          name,
          address1,
          address2,
          city,
          zip,
          email,
          phone,
          ext,
          date: dateString,
          time: timeString,
          notes,
          headcount: `${headcount}`,
          secret: 'BunBao',
        },
      });
      console.log(response);
      message.success(
        'Submit successful! We will contact you soon. Thank you!',
        10
      );
    } catch (error) {
      message.error('Sorry, please try again.');
    }
  };

  return (
    <div
      css={{
        label: {
          color: 'white',
        },
        '.ant-col-8': {
          maxWidth: '100%',
        },
        '.ant-col-16': {
          maxWidth: '100%',
        },
        input: {
          borderRadius: '25px',
        },
        textarea: {
          borderRadius: '25px',
        },
        '.ant-picker': {
          borderRadius: '25px',
        },
        '.ant-input-number': {
          borderRadius: '25px',
        },
        '.ant-form-item-explain.ant-form-item-explain-error': {
          color: '#cdcdcd',
        },
      }}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <FormBuilder form={form} meta={meta} />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            color="#fff"
            htmlType="submit"
            style={{
              borderRadius: '25px',
              minWidth: '200px',
              fontSize: '20px',
              height: 'auto',
            }}
          >
            <span style={{ color: '#F54D58', fontWeight: 'bold' }}>
              <FormattedMessage id="customerCateringHome.submit" />
            </span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

CateringCustomizeForm.propTypes = {};

export default CateringCustomizeForm;
