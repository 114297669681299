import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Overview from './Overview/Overview';
// import PersonalCoupon from './PersonalCoupon/PersonalCoupon';
import CustomerSetting from './CustomerSetting/CustomerSetting';
import MessageSystem from './MessageSystem/MessageSystem';
// import ActivityDetail from './ActivityDetail/ActivityDetail';
import DeliveryOrders from './DeliveryOrders/DeliveryOrders';
import DeliveryOrderDetail from './DeliveryOrderDetail/DeliveryOrderDetail';
import OrderDetails from './OrderDetails/OrderDetails';
import Points from './Points/Points';
import Balance from './Balance/Balance';
import MyGroupBuy from './MyGroupBuy/MyGroupBuy';
import Invitation from './Invitation/Invitation';
import PaymentManagement from './PaymentManagement/PaymentManagement';
import { getCustomerPath } from '../../../util';
import * as actions from '../../../store/actions';
import CateringOrders from './CateringOrders/CateringOrders';
import CateringOrder from '../../Dashboard/CateringOrder/CateringOrder';

export class Account extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const { pathname } = window.location;
    console.log('pathname', pathname);
  };

  handleSetLanguage = (language) => {
    const { setLanguage } = this.props;
    setLanguage(language);
  };

  getRedirect = async () => {
    let newPath;
    const { loginRedirectPage, saveLinkForLogin, history } = this.props;
    if (loginRedirectPage) {
      newPath = loginRedirectPage;
      await saveLinkForLogin('');
    } else {
      newPath = 'account';
    }
    console.log('newPath', newPath);
    history.push(getCustomerPath(newPath));
  };

  getPath = (fifth) => {
    if (fifth === 'order') {
      return 'account/orders';
    }
    if (fifth === 'delivery-order') {
      return 'account/delivery-orders';
    }
    return 'account';
  };

  render() {
    const { match } = this.props;

    return (
      <div
        style={{
          position: 'relative',
          zIndex: 11,
          padding: '20px 10px 10px 10px',
          backgroundColor: 'white',
          // minHeight: '100vh',
          // overflow: 'scroll',
          // height: '100vh',
          // overflow: 'scroll',
        }}
      >
        <Switch>
          <Route exact path={`${match.url}/overview`} component={Overview} />
          <Route
            exact
            path={`${match.url}/setting`}
            component={CustomerSetting}
          />
          <Route
            exact
            path={`${match.url}/invitation`}
            component={Invitation}
          />
          <Route exact path={`${match.url}/points`} component={Points} />
          <Route exact path={`${match.url}/balance`} component={Balance} />
          <Route
            exact
            path={`${match.url}/orders`}
            component={DeliveryOrders}
          />
          <Route
            exact
            path={`${match.url}/orders/delivery-order/:orderId`}
            component={DeliveryOrderDetail}
          />
          <Route
            exact
            path={`${match.url}/orders/order/:orderId`}
            component={OrderDetails}
          />
          {/* <Route
            exact
            path={`${match.url}/personal-coupon`}
            component={PersonalCoupon}
          /> */}
          <Route
            exact
            path={`${match.url}/message-system`}
            component={MessageSystem}
          />
          <Route
            exact
            path={`${match.url}/payment-management`}
            component={PaymentManagement}
          />
          {/* <Route
            exact
            path={`${match.url}/activity-detail`}
            component={ActivityDetail}
          /> */}
          <Route
            exact
            path={`${match.url}/catering-orders`}
            component={CateringOrders}
          />
          <Route
            exact
            path={`${match.url}/catering-orders/order/:orderId`}
            component={CateringOrder}
          />
          <Route exact path={`${match.url}/campaign`} component={MyGroupBuy} />
          <Redirect from={match.url} to={getCustomerPath('account-overview')} />
        </Switch>
      </div>
    );
  }
}

Account.propTypes = {
  // language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  loginRedirectPage: PropTypes.string.isRequired,
  saveLinkForLogin: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = (state) => ({
  firstName: state.user.firstName,
  email: state.user.email,
  group: state.user.group,
  language: state.language.lang,
  loginRedirectPage: state.auth.loginRedirectPage,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(actions.setLanguage(language)),
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
