import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  allTableSettings: {},
};

export const attributeUpdate = (state, action) => {
  const updateState = {
    [action.attributeName]: action.attributeValue,
  };
  return updateObject(state, updateState);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_UPDATE:
      return attributeUpdate(state, action);
    default:
      return state;
  }
};
