/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { HomeOutlined, ReadOutlined, BookOutlined } from '@ant-design/icons';
import { apolloClient, getCustomerPath } from '../../../util';
import { ARTICLE_FIND_ONE } from '../../../api/article';

export class Story extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      story: null,
    };
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const {
      match: {
        params: { id: storyId },
      },
    } = this.props;
    console.log(storyId);
    if (storyId) {
      await this.handleFetchStory(storyId);
    }
  };

  handleFetchStory = async (storyId) => {
    // fetch story from our database
    console.log(storyId);
    const { data } = await apolloClient.query({
      query: ARTICLE_FIND_ONE,
      variables: { filter: { _id: storyId } },
    });
    console.log(data);
    this.setState({ story: data.articleOne });
  };

  render() {
    const { story } = this.state;
    const { language, history } = this.props;

    return (
      <div css={{ minHeight: '100vh' }}>
        {story && (
          <div
            css={{ margin: '10px auto', maxWidth: '900px', padding: '10px' }}
          >
            <Breadcrumb>
              <Breadcrumb.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(getCustomerPath('stories'));
                }}
              >
                <HomeOutlined /> Stories
              </Breadcrumb.Item>
              <Breadcrumb.Item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (story.categories.length > 0) {
                    history.push(
                      getCustomerPath(
                        `stories?categoryId=${story.categories[0]._id}`
                      )
                    );
                  } else {
                    history.push(getCustomerPath('stories'));
                  }
                }}
              >
                <BookOutlined />{' '}
                {story.categories > 0 &&
                  language === 'zh' &&
                  story.categories[0].title.zh}
                {story.categories > 0 && story.categories[0].title.en}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <ReadOutlined />{' '}
                {language === 'zh' ? story.title.zh : story.title.en}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div
              css={{
                fontSize: '30px',
                fontWeight: 'bold',
                color: '#000000a6',
              }}
            >
              {language === 'zh' ? story.title.zh : story.title.en}
            </div>
            <div>{moment(story.createdAt).format('ll')}</div>
            <div>
              {language === 'zh' ? (
                <div className="braft-output-content">
                  {convert(convertRawToHTML(JSON.parse(story.content.zh)))}
                </div>
              ) : (
                <div className="braft-output-content">
                  {convert(convertRawToHTML(JSON.parse(story.content.en)))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Story.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(Story);
