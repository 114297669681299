import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import { useSelector } from 'react-redux';
import { ARTICLE_FIND_ONE } from '../../../api/article';
import { apolloClient } from '../../../util';

function ArticleModal(props) {
  // get alias
  const { alias } = props;

  const language = useSelector((state) => state.language.lang);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticleByAlias = async () => {
      if (alias !== null) {
        const { data } = await apolloClient.query({
          query: ARTICLE_FIND_ONE,
          variables: { filter: { alias } },
        });
        console.log(data);
        setArticle(data.articleOne);
      }
      setLoading(false);
    };
    fetchArticleByAlias();
  }, [alias]);

  return (
    <div>
      {article === null && loading === false ? (
        <span>
          If you see this sentence, it means that this article does not exist,
          please go to dashboard =&gt; article =&gt; create a new article and
          set alias for &quot;{alias}&quot;
        </span>
      ) : null}
      {article !== null && loading === false ? (
        <div>
          {language === 'zh' ? (
            <div className="braft-output-content">
              {convert(convertRawToHTML(JSON.parse(article.content.zh)))}
            </div>
          ) : (
            <div className="braft-output-content">
              {convert(convertRawToHTML(JSON.parse(article.content.en)))}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

ArticleModal.defaultProps = {
  alias: null,
};

ArticleModal.propTypes = {
  alias: PropTypes.string,
};

export default ArticleModal;
