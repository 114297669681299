/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  ClockCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Col, Card, Tag, Statistic, Progress, Modal } from 'antd';
import { isMobile } from 'react-device-detect';
import * as QueryString from 'query-string';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { nanoid } from 'nanoid';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  apolloClient,
  checkWeChatUserAgent,
  colorGenerator,
  getCustomerPath,
} from '../../../../util';
import { BUCKET_CDN_URL, DEFAULT_IMAGE } from '../../../../config';
import shareIcon from '../../../../assets/images/icons/shareIcon.png';
import { SHORT_URL } from '../../../../api/configuration';

// import { useHistory } from 'react-router-dom';

const { Countdown } = Statistic;

function SingleProductCard(props) {
  // const [profileState, setProfileState] = useState(props);
  const referralCode = useSelector((state) => state.user.referralCode);
  const {
    e,
    language,
    translation,
    onOpenProductDetailModal,
    handleDecrement,
    handleChangeOriginArray,
    handleIncrement,
    intl,
    isShutDown,
    forceMobileUI,
    history,
  } = props;

  const isGroupBuyEnable = () => {
    const today = new Date().valueOf();
    if (e.isGroupBuy) {
      return e.groupBuyEndDate > today && e.groupBuyStartDate < today;
    }
    return true;
  };

  const getImgUrl = (obj) => {
    const keyMap = {
      en: 'picture',
      zh: 'pictureZh',
    };
    const langKey = keyMap[language];
    if (
      !obj[langKey] &&
      !obj?.product[langKey] &&
      !obj?.product?.picture &&
      !obj?.picture
    ) {
      return DEFAULT_IMAGE;
    }
    let picture = null;
    if (obj[langKey] || obj?.picture) {
      picture = `${BUCKET_CDN_URL}${
        obj[langKey]?.key || obj?.picture?.key || obj?.product?.picture?.key
      }`;
    } else {
      picture = `${BUCKET_CDN_URL}${
        obj?.product[langKey]?.key ||
        obj?.picture?.key ||
        obj?.product?.picture?.key
      }`;
    }
    return picture;
  };

  const cardCssObj = {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    '& .link-button': {
      border: '1px solid #DF242F',
      backgroundColor: '#DF242F',
      borderRadius: '5px',
      textAlign: 'center',
      fontSize: isMobile || forceMobileUI ? '14px' : '18px',
      color: 'white',
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: '#eb4b50',
      },
    },
    '& .increment-or-decrement-button': {
      width: isMobile || forceMobileUI ? '24px' : '30px',
      height: isMobile || forceMobileUI ? '24px' : '30px',
      border:
        e.soldOut !== true && isGroupBuyEnable()
          ? '1px solid #DF242F'
          : '1px solid #8bbabb',
      backgroundColor:
        e.soldOut !== true && isGroupBuyEnable() ? '#DF242F' : '#8bbabb',
      borderRadius: '5px',
      textAlign: 'center',
      fontSize: isMobile || forceMobileUI ? '14px' : '20px',
      color: 'white',
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        backgroundColor:
          e.soldOut !== true && isGroupBuyEnable() ? '#eb4b50' : '#8bbabb',
      },
    },
    '& .counter-section': {
      textAlign: 'center',
      display: 'inline-block',
      fontSize: isMobile || forceMobileUI ? '14px' : '20px',
      margin: '0 5px',
      width: isMobile || forceMobileUI ? '24px' : '30px',
      height: isMobile || forceMobileUI ? '24px' : '30px',
      border: '1px solid #e8e8e8',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  const priceRender = () => {
    // group buy price
    if (e.isGroupBuy) {
      // show different price
      const { listPrice } = e;

      const sortedRank = _.sortBy(
        e.groupBuyDiscountRank,
        ['orderItemCount'],
        ['asc']
      );
      const lowestPrice =
        sortedRank.length > 0
          ? sortedRank[sortedRank.length - 1].listPrice
          : null;
      return (
        <span>
          <span>
            ${listPrice}
            {lowestPrice ? `~ $${lowestPrice}` : null}
          </span>{' '}
          {e.isShowUnitPrice && (
            <span css={{ color: '#565959', fontSize: '12px' }}>
              (${Number(listPrice / e.numberOfUnits).toFixed(2)}/
              {e.product.unit})
            </span>
          )}
        </span>
      );
    }
    if (
      e.originalPrice &&
      Number(e.originalPrice) !== 0 &&
      Number(e.originalPrice) !== Number(e.listPrice)
    ) {
      return (
        <span>
          <span css={{ color: 'red ' }}>${e.listPrice}</span>{' '}
          <del>${e.originalPrice}</del>{' '}
          {e.isShowUnitPrice && (
            <span css={{ color: '#565959', fontSize: '12px' }}>
              (${Number(e.listPrice / e.numberOfUnits).toFixed(2)}/
              {e.product.unit})
            </span>
          )}
        </span>
      );
    }
    return (
      <span>
        ${e.listPrice}{' '}
        {e.isShowUnitPrice && (
          <span css={{ color: '#565959', fontSize: '12px' }}>
            (${Number(e.listPrice / e.numberOfUnits).toFixed(2)}/
            {e.product.unit})
          </span>
        )}
      </span>
    );
  };
  const remainQuantityRender = () => {
    if (e.totalQuantity && e.totalQuantity < 3 && e.totalQuantity > 0) {
      return (
        <span
          css={{
            marginLeft: '5px',
            color: '#fe6845',
            fontSize: '15px',
            fontFamily: 'Calistoga',
          }}
        >
          {e.totalQuantity}
          <FormattedMessage id="left" />
        </span>
      );
    }
    return null;
  };

  const limitedDateRender = () => {
    let dateCounter = 0;
    return (
      <div
        style={{
          position: 'absolute',
          right: '5px',
          fontSize: '14px',
        }}
      >
        {e.allowedShippingDate.length > 0 && (
          <ClockCircleOutlined style={{ marginRight: '3px' }} />
        )}
        {e.allowedShippingDate.map((date, index) => {
          // only show two dates near by today
          const today = moment();
          if (
            today.diff(moment(date.trim()), 'day') <= 0 &&
            today.isSame(moment(date.trim()), 'day') === false &&
            dateCounter <= 1
          ) {
            dateCounter += 1;
            if (
              index === e.allowedShippingDate.length - 1 ||
              dateCounter === 2
            ) {
              return (
                <span key={nanoid(6)}>
                  {`${moment(date.trim(), 'YYYY-MM-DD').format('M-DD')}`}
                </span>
              );
            }
            return (
              <span key={nanoid(6)}>
                {`${moment(date.trim(), 'YYYY-MM-DD').format('M-DD')}, `}
              </span>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const lightningDealRender = () => {
    if (e.isChangeListPriceByDates) {
      const today = moment().valueOf();
      if (
        today >= e.changeListPriceDates.startDate &&
        today <= e.changeListPriceDates.endDate
      ) {
        return (
          <div
            style={{ fontSize: '20px', color: 'white' }}
            css={{ position: 'absolute', top: '3%', left: '3%' }}
          >
            <Tag color="#f50">
              <FormattedMessage id="lightningDeal" />
            </Tag>
          </div>
        );
      }
    }
    return null;
  };

  const groupBuyRender = () => {
    if (e.isGroupBuy) {
      return (
        <div
          css={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            background: '#E40000',
            padding: '2px 0px 2px 4px',
            height: '26px',
            color: 'white',
          }}
        >
          <FormattedMessage id="groupBuy" />
          <div
            css={{
              position: 'absolute',
              height: '100%',
              top: '0px',
              right: '-29px',
              border: '13.1px solid transparent',
              borderLeft: '16px solid #E40000',
              pointerEvents: 'none',
            }}
          />
        </div>
      );
    }
    return null;
  };

  /* eslint-disable-next-line */
  const shareIconRender = (id) => {
    return (
      <div
        style={{ fontSize: '20px', color: 'white' }}
        css={{ position: 'absolute', top: '3%', right: '3%' }}
      >
        <div
          onClick={async () => {
            const { location } = props;
            const { pathname, search } = location;
            const searchObject = QueryString.parse(search);
            let query = '';
            Object.keys(searchObject).forEach((key) => {
              if (key !== 'productId') {
                query += `${key}=${searchObject[key]}&`;
              }
            });
            let originalUrl = `https://${window.location.hostname}${pathname}?${query}productId=${e.id}`;
            if (pathname === getCustomerPath('checkout/payment')) {
              originalUrl = `https://${window.location.hostname}/supermarket?${query}productId=${e.id}`;
            }
            if (referralCode) {
              originalUrl += `&referralCode=${referralCode}`;
            }
            // short url
            try {
              const shortUrlResponse = await apolloClient.query({
                query: SHORT_URL,
                variables: { url: originalUrl },
              });
              const {
                data: {
                  configurationShortUrl: { shortUrl },
                },
              } = shortUrlResponse;
              if (shortUrl !== '') {
                originalUrl = shortUrl;
              }
            } catch (error) {
              console.error(error);
            }
            let content = '';
            let unitPrice = '';
            if (e.isShowUnitPrice) {
              unitPrice = ` ($${Number(e.listPrice / e.numberOfUnits).toFixed(
                2
              )}/${e.product.unit})`;
            }
            if (language === 'zh') {
              content = `【${e.name.zh}${unitPrice}】，超低价棒包【生鲜聚划算】！向朋友们种草，朋友下单后，你将获得3%的订单金额红包哦！ ${originalUrl}`;
            } else {
              content = `Share to your friends and earn credits!\nOnce your friend place an order with this link, earn 3% of his/her order amount right away! ${originalUrl}`;
            }
            if (!navigator && !navigator.clipboard) {
              const textField = document.createElement('textarea');
              textField.innerText = content;
              document.body.appendChild(textField);
              textField.select();
              document.execCommand('copy');
              textField.remove();
            } else {
              await navigator.clipboard.writeText(content);
            }
            if (checkWeChatUserAgent()) {
              onOpenProductDetailModal(id, true);
            } else {
              const tipModal = Modal.success({
                content:
                  language === 'zh' ? (
                    <div>
                      <div>
                        分享此产品给身边的好友，下单成功后您将获得订单返利哦！(链接已经粘贴到剪切板了哦)
                      </div>
                      <div>
                        <div
                          onClick={() => {
                            tipModal.destroy();
                            history.push(getCustomerPath('account/balance'));
                          }}
                          css={{
                            cursor: 'pointer',
                            color: 'red',
                            display: 'inline-block',
                          }}
                          role="presentation"
                        >
                          [查看账户余额]
                        </div>
                        <div
                          onClick={() => {
                            tipModal.destroy();
                            history.push(
                              getCustomerPath('account/balance?rules=true')
                            );
                          }}
                          css={{
                            cursor: 'pointer',
                            color: 'red',
                            display: 'inline-block',
                            marginLeft: '5px',
                          }}
                          role="presentation"
                        >
                          [了解活动规则]
                        </div>
                      </div>
                    </div>
                  ) : (
                    'Copied to your clipboard, share the product to your friends'
                  ),
                maskClosable: true,
              });
              if (navigator?.share) {
                if (language === 'zh') {
                  navigator
                    .share({
                      title: content,
                      text: content,
                      url: originalUrl,
                    })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
                } else {
                  navigator
                    .share({
                      title: content,
                      text: content,
                      url: originalUrl,
                    })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
                }
              }
            }
          }}
          role="presentation"
        >
          <img
            src={shareIcon}
            alt="Share Icon"
            css={{
              height: '30px !important',
              width: '30px !important',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    );
  };

  const subcategoryRender = () => {
    if (e.product.subcategory) {
      return (
        <div
          style={{
            fontSize: '20px',
            color: 'white',
            fontWeight: '600',
            // transform: 'scale(1, 0.6)',
          }}
          css={{ position: 'absolute', bottom: '0px', left: '0px' }}
        >
          <Tag
            color={
              e.product.subcategory.color &&
              e.product.subcategory.color.trim() !== ''
                ? e.product.subcategory.color
                : colorGenerator(e.product.subcategory.title[language])
            }
          >
            {e.product.subcategory.title[language]}
          </Tag>
        </div>
      );
    }
    return null;
  };

  const timerRender = () => {
    if (e.isChangeListPriceByDates) {
      const today = moment().valueOf();
      if (
        today >= e.changeListPriceDates.startDate &&
        today <= e.changeListPriceDates.endDate
      ) {
        return (
          <div
            css={
              isMobile || forceMobileUI
                ? {
                    fontSize: '14px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }
                : {
                    fontSize: '15px',
                    display: 'flex',
                  }
            }
          >
            <div css={{ whiteSpace: 'nowrap' }}>
              <div css={{ marginRight: '3px', display: 'inline-block' }}>
                <FormattedMessage id="endsIn" />
              </div>
              <div css={{ display: 'inline-block' }}>
                <Countdown
                  valueStyle={
                    isMobile || forceMobileUI
                      ? { fontSize: '14px', color: 'red' }
                      : {
                          fontSize: '15px',
                          color: 'red',
                        }
                  }
                  format={
                    moment(e.changeListPriceDates.endDate, 'x').diff(
                      moment(),
                      'days'
                    ) > 1
                      ? `D [${intl.formatMessage({ id: 'days' })}]`
                      : 'H:m:s'
                  }
                  value={moment(e.changeListPriceDates.endDate, 'x')}
                />
              </div>
            </div>
            <Progress
              css={{ width: '100%', marginLeft: '3px' }}
              strokeColor="red"
              size="small"
              percent={
                ((today - e.changeListPriceDates.startDate) /
                  (e.changeListPriceDates.endDate -
                    e.changeListPriceDates.startDate)) *
                100
              }
              status="active"
              showInfo={false}
            />
          </div>
        );
      }
    }
    return null;
  };

  const renderTabs = (text) => {
    return (
      <div
        css={{
          background: '#D75C57',
          padding: '0px 5px',
          flexShrink: '0',
          fontSize: '13px',
          opacity: '.9',
          color: 'white',
          borderRadius: '10px',
        }}
      >
        {text}
      </div>
    );
  };

  const groupBuyPriceRank = () => {
    const sortedRank =
      _.sortBy(e?.groupBuyDiscountRank, ['orderItemCount'], ['asc']) || [];
    let nextPrice = null;
    let nextMaxCount = null;

    for (let i = 0; i < sortedRank.length; i += 1) {
      if (e.currentGroupOrders < sortedRank[i].orderItemCount) {
        nextPrice = sortedRank[i].listPrice;
        nextMaxCount = sortedRank[i].orderItemCount;
        break;
      }
    }
    return { nextPrice, nextMaxCount };
  };

  if (isMobile || forceMobileUI) {
    return (
      <div
        key={e.id}
        css={{
          width: '100%',
          display: 'grid',
          grid: '1fr / 48% 1fr',
          gridGap: '1%',
          marginBottom: '10px',
          alignItems: 'center',
          '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          },
          '& .dish-picture': {
            height: '125px',
            width: '100%',
            position: 'relative',
          },
          '& .dish-details': {
            padding: '5px',
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
          },
          '& .dish-title': { width: '100%' },
          '& .dish-price': { width: '100%' },
          '& .dish-subtitle': { width: '100%', color: 'red' },
        }}
        style={
          e.isGroupBuy
            ? {
                border: '1px solid red',
                borderRadius: '4px',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <div className="dish-picture" role="presentation">
          <img
            alt="dish"
            src={getImgUrl(e)}
            onClick={() => {
              onOpenProductDetailModal(e.id);
            }}
            role="presentation"
          />
          {e.videoIds.length > 0 && (
            <VideoCameraOutlined
              style={{ fontSize: '20px', color: 'white' }}
              css={{ position: 'absolute', bottom: '3%', right: '3%' }}
            />
          )}
          {lightningDealRender()}
          {groupBuyRender()}
          {subcategoryRender()}
          {/* {shareIconRender(e.id)} */}
        </div>
        <div className="dish-details">
          <div>
            <div
              className="dish-title"
              onClick={() => {
                onOpenProductDetailModal(e.id);
              }}
              role="presentation"
            >
              {e.allowedShippingDate.length > 0 && (
                <ClockCircleOutlined style={{ marginRight: '3px' }} />
              )}
              {e.name[language]}
            </div>
            <div className="dish-price">
              {priceRender()}
              {remainQuantityRender()}
            </div>
            {e.subtitle && (
              <div className="dish-subtitle">
                {language === 'zh' ? e.subtitle.zh : e.subtitle.en}
              </div>
            )}
            {e.excludeFromFreeShipping && (
              <div css={{ fontSize: '10px' }}>
                <FormattedMessage id="menu.excludeFromFreeShipping" />
              </div>
            )}
            {timerRender()}
            {/* {e.isGroupBuy && (
              <div css={{ display: 'flex', flexWrap: 'wrap' }}>
                {e?.groupBuyDiscountRank?.length &&
                  renderTabs(`
                  ${language === 'zh' ? '低至 ' : 'Low as '}$${
                    (e?.groupBuyDiscountRank?.length &&
                      e.groupBuyDiscountRank[e.groupBuyDiscountRank.length - 1]
                        ?.listPrice) ||
                    ''
                  } `)}
                {e?.groupBuyDiscountRank?.currentGroupOrders &&
                  renderTabs(`
                    ${e?.groupBuyDiscountRank?.currentGroupOrders || ''}  ${
                    language === 'zh' ? '人团购 ' : 'orders to activate'
                  }`)}
              </div>
            )} */}
            {e?.isGroupBuy && (
              <div style={{ maxWidth: '40%' }}>
                <Progress
                  // style={{ maxWidth: '60%' }}
                  css={{
                    '.ant-progress-text': {
                      color: 'black !important',
                    },
                  }}
                  strokeColor={
                    (e?.currentGroupOrders /
                      (groupBuyPriceRank().nextMaxCount ||
                        e?.groupBuyMaxOrderCount)) *
                      100 >=
                    100
                      ? '#52c41a'
                      : 'red'
                  }
                  percent={
                    (e?.currentGroupOrders /
                      (groupBuyPriceRank().nextMaxCount ||
                        e?.groupBuyMaxOrderCount)) *
                    100
                  }
                  format={() => {
                    const sortedRank =
                      _.sortBy(
                        e?.groupBuyDiscountRank,
                        ['orderItemCount'],
                        ['asc']
                      ) || [];
                    let nextPrice = null;
                    let nextMaxCount = null;

                    for (let i = 0; i < sortedRank.length; i += 1) {
                      if (e.currentGroupOrders < sortedRank[i].orderItemCount) {
                        nextPrice = sortedRank[i].listPrice;
                        nextMaxCount = sortedRank[i].orderItemCount;
                        break;
                      }
                    }
                    if (
                      e.currentGroupOrders >= e?.groupBuyMaxOrderCount &&
                      !(nextPrice && nextMaxCount)
                    ) {
                      return `${
                        language === 'zh' ? '成团抢购中' : 'success'
                      }, $${
                        sortedRank?.length > 0
                          ? sortedRank[sortedRank.length - 1].listPrice
                          : e?.listPrice
                      }`;
                    }
                    if (e.currentGroupOrders >= e?.groupBuyMaxOrderCount) {
                      return `${
                        language === 'zh' && nextPrice && nextMaxCount
                          ? ' 还差'
                          : ''
                      }${nextMaxCount - e?.currentGroupOrders}${
                        language === 'zh'
                          ? `人降价${nextPrice ? `, $${nextPrice}` : ``}`
                          : ` more ${nextPrice ? `, $${nextPrice}` : ``}`
                      }`;
                    }
                    return `${
                      language === 'zh' &&
                      e?.groupBuyMaxOrderCount > e?.currentGroupOrders
                        ? ' 还差'
                        : ''
                    }${e?.groupBuyMaxOrderCount - e?.currentGroupOrders}${
                      language === 'zh' ? '人成团' : ' more ,activated'
                    }`;
                  }}
                />
              </div>
            )}
          </div>
          <div>
            {e?.count > 0 && e?.minimumAddQuantity > 1 && !e?.soldOut && (
              <div style={{ textAlign: 'right', color: 'red' }}>
                {language === 'en'
                  ? `Minimum ${e?.minimumAddQuantity}`
                  : `${e?.minimumAddQuantity}份起订`}
              </div>
            )}
            <div
              css={{
                width: '100%',
                ...cardCssObj,
              }}
            >
              <div />
              {/* <div style={{ width: '40%' }}>
              {e?.isGroupBuy && (
                <Progress
                  strokeColor="red"
                  percent={
                    (e?.currentGroupOrders /
                      (groupBuyPriceRank().nextMaxCount ||
                        e?.groupBuyMaxOrderCount)) *
                    100
                  }
                  format={() => {
                    if (e?.currentGroupOrders > e?.groupBuyMaxOrderCount) {
                      return `${language === 'zh' ? '成团' : 'active'}`;
                    }
                    return `${e?.currentGroupOrders} / ${e?.groupBuyMaxOrderCount}`;
                  }}
                />
              )}
            </div> */}
              <div style={{ display: 'flex' }}>
                {isGroupBuyEnable() && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {e.count > 0 && e.isItNotAbleToAddCart !== true ? (
                      <div
                        className="increment-or-decrement-button"
                        onClick={() => {
                          handleDecrement(e.id);
                        }}
                        role="presentation"
                      >
                        <MinusOutlined />
                      </div>
                    ) : null}
                    {e.count > 0 && e.isItNotAbleToAddCart !== true ? (
                      <div className="counter-section">{e.count}</div>
                    ) : null}
                  </div>
                )}
                {(e.soldOut === true && e.isItNotAbleToAddCart !== true) ||
                (e.totalQuantity &&
                  e.totalQuantity < 0 &&
                  e.isItNotAbleToAddCart !== true) ? (
                  <div css={{ display: 'inline-block', marginRight: '10px' }}>
                    <FormattedMessage id="soldOut" />
                  </div>
                ) : null}
                {e.isGroupBuy && e.groupBuyEndDate < new Date().valueOf() ? (
                  <div
                    css={{
                      display: 'inline-block',
                      marginRight: '10px',
                    }}
                  >
                    <FormattedMessage id="endActivity" />
                  </div>
                ) : null}
                {e.isGroupBuy && e.groupBuyStartDate > new Date().valueOf() ? (
                  <div
                    css={{
                      display: 'inline-block',
                      marginRight: '10px',
                    }}
                  >
                    <FormattedMessage id="comingSoon" />
                  </div>
                ) : null}
                {e.isItNotAbleToAddCart !== true && !e.isLink && !isShutDown && (
                  <div
                    className="increment-or-decrement-button"
                    onClick={() => {
                      if (e.soldOut !== true && isGroupBuyEnable()) {
                        handleIncrement(e.id);
                      }
                    }}
                    role="presentation"
                  >
                    <PlusOutlined />
                  </div>
                )}
                {e.isLink && (
                  <div
                    className="link-button"
                    onClick={() => {
                      history.push(getCustomerPath(e.link));
                    }}
                    role="presentation"
                  >
                    {language === 'zh' ? e.linkTitle.zh : e.linkTitle.en}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Col
      css={{ padding: '13px 0' }}
      xxl={6}
      xl={6}
      lg={8}
      md={8}
      sm={12}
      xs={12}
      key={e.id}
    >
      <Card
        bodyStyle={{ padding: '10px', fontSize: '18px' }}
        style={
          e.isGroupBuy ? { border: '1px solid red', overflow: 'hidden' } : {}
        }
        cover={
          <div css={{ position: 'relative' }}>
            <img
              css={{
                cursor: 'pointer',
                userSelect: 'none',
                height: '181px',
                objectFit: 'cover',
                width: '100%',
              }}
              onClick={() => {
                onOpenProductDetailModal(e.id);
              }}
              role="presentation"
              alt="dish"
              src={getImgUrl(e)}
            />
            {e.videoIds.length > 0 && (
              <VideoCameraOutlined
                style={{ fontSize: '20px', color: 'white' }}
                css={{ position: 'absolute', bottom: '3%', right: '3%' }}
              />
            )}
            {lightningDealRender()}
            {groupBuyRender()}
            {subcategoryRender()}
            {/* {shareIconRender(e.id)} */}
          </div>
        }
      >
        <div>
          <div
            css={{ height: '55px', cursor: 'pointer' }}
            onClick={() => {
              onOpenProductDetailModal(e.id);
            }}
            role="presentation"
          >
            {e.name[language]}
          </div>
          {limitedDateRender()}
          <div css={{ height: '20px', fontSize: '13px', color: 'red' }}>
            {e.subtitle && (
              <div>{language === 'zh' ? e.subtitle.zh : e.subtitle.en}</div>
            )}
          </div>
          {e.isChangeListPriceByDates ? (
            <div css={{ height: '30px' }}>{timerRender()}</div>
          ) : (
            <div css={{ height: '30px' }}>
              {e.hashtags.length > 0 &&
                e.hashtags.map((hashtag) => (
                  <Tag key={nanoid(6)} color={colorGenerator(hashtag)}>
                    {language === 'zh' ? translation(hashtag) : hashtag}
                  </Tag>
                ))}
            </div>
          )}
          <div
            css={{ height: '25px' }}
            className="flex-center"
            style={{ justifyContent: 'flex-start' }}
          >
            {e?.isGroupBuy && (
              <Progress
                style={{ maxWidth: '60%' }}
                css={{
                  '.ant-progress-text': {
                    color: 'black !important',
                  },
                }}
                strokeColor={
                  (e?.currentGroupOrders /
                    (groupBuyPriceRank().nextMaxCount ||
                      e?.groupBuyMaxOrderCount)) *
                    100 >=
                  100
                    ? '#52c41a'
                    : 'red'
                }
                percent={
                  (e?.currentGroupOrders /
                    (groupBuyPriceRank().nextMaxCount ||
                      e?.groupBuyMaxOrderCount)) *
                  100
                }
                format={() => {
                  const sortedRank =
                    _.sortBy(
                      e?.groupBuyDiscountRank,
                      ['orderItemCount'],
                      ['asc']
                    ) || [];
                  let nextPrice = null;
                  let nextMaxCount = null;

                  for (let i = 0; i < sortedRank.length; i += 1) {
                    if (e.currentGroupOrders < sortedRank[i].orderItemCount) {
                      nextPrice = sortedRank[i].listPrice;
                      nextMaxCount = sortedRank[i].orderItemCount;
                      break;
                    }
                  }
                  if (
                    e.currentGroupOrders >= e?.groupBuyMaxOrderCount &&
                    !(nextPrice && nextMaxCount)
                  ) {
                    return `${language === 'zh' ? '已成团' : 'success'}, $${
                      sortedRank?.length > 0
                        ? sortedRank[sortedRank.length - 1].listPrice
                        : e?.listPrice
                    }`;
                  }
                  if (e.currentGroupOrders >= e?.groupBuyMaxOrderCount) {
                    return `${
                      language === 'zh' && nextPrice && nextMaxCount
                        ? ' 还差'
                        : ''
                    }${nextMaxCount - e?.currentGroupOrders}${
                      language === 'zh'
                        ? `人降价${nextPrice ? `, $${nextPrice}` : ``}`
                        : ` more ${nextPrice ? `, $${nextPrice}` : ``}`
                    }`;
                  }
                  return `${
                    language === 'zh' &&
                    e?.groupBuyMaxOrderCount > e?.currentGroupOrders
                      ? ' 还差'
                      : ''
                  }${e?.groupBuyMaxOrderCount - e?.currentGroupOrders}${
                    language === 'zh' ? '人成团' : ' more ,activated'
                  }`;
                }}
              />
            )}
          </div>

          <div
            style={
              {
                // position: 'absolute',
                // bottom: '50px',
                // right: '5px',
              }
            }
          >
            {priceRender()}
            {remainQuantityRender()}
          </div>
          <div css={cardCssObj}>
            <div
              style={{ width: '40%', display: 'flex', alignItems: 'center' }}
            >
              {e.isGroupBuy && (
                <div css={{ display: 'flex', flexWrap: 'wrap' }}>
                  {e?.groupBuyDiscountRank?.length > 0 &&
                    renderTabs(`
                  ${language === 'zh' ? '低至 ' : 'Low as '}$${
                      (e?.groupBuyDiscountRank?.length &&
                        e.groupBuyDiscountRank[
                          e.groupBuyDiscountRank.length - 1
                        ]?.listPrice) ||
                      ''
                    } `)}

                  {/* {e?.currentGroupOrders > 0 &&
                  renderTabs(`
                    ${e?.currentGroupOrders || ''}  ${
                    language === 'zh'
                      ? '人团购 '
                      : `order${
                          e?.currentGroupOrders > 1 ? 's' : ''
                        } to activate`
                  }`)} */}
                </div>
              )}
              {e?.count > 0 && e?.minimumAddQuantity > 1 && !e?.soldOut && (
                <div style={{ textAlign: 'right', color: 'red' }}>
                  {language === 'en'
                    ? `Minimum ${e?.minimumAddQuantity}`
                    : `${e?.minimumAddQuantity}份起订`}
                </div>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              {e.excludeFromFreeShipping && e.isItNotAbleToAddCart !== true && (
                <div
                  css={{
                    display: 'inline-block',
                    marginRight: '10px',
                    fontSize: '10px',
                  }}
                >
                  <FormattedMessage id="menu.excludeFromFreeShipping" />
                </div>
              )}
              {isGroupBuyEnable() && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {e.count > 0 && e.isItNotAbleToAddCart !== true ? (
                    <div
                      className="increment-or-decrement-button"
                      onClick={() => {
                        handleDecrement(e.id);
                      }}
                      role="presentation"
                    >
                      <MinusOutlined />
                    </div>
                  ) : null}
                  {e.count > 0 && e.isItNotAbleToAddCart !== true ? (
                    <div className="counter-section">{e.count}</div>
                  ) : null}
                </div>
              )}

              {e.soldOut === true && e.isItNotAbleToAddCart !== true ? (
                <div
                  css={{
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  <FormattedMessage id="soldOut" />
                </div>
              ) : null}
              {e.isGroupBuy && e.groupBuyEndDate < new Date().valueOf() ? (
                <div
                  css={{
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  <FormattedMessage id="endActivity" />
                </div>
              ) : null}
              {e.isGroupBuy && e.groupBuyStartDate > new Date().valueOf() ? (
                <div
                  css={{
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  <FormattedMessage id="comingSoon" />
                </div>
              ) : null}
              {e.isItNotAbleToAddCart !== true && !e.isLink && !isShutDown ? (
                <div
                  ref={(dom) => {
                    handleChangeOriginArray(e.id, dom);
                  }}
                  className="increment-or-decrement-button"
                  onClick={async () => {
                    if (e.soldOut !== true && isGroupBuyEnable()) {
                      await handleIncrement(e.id);
                      // await setCopied(true);
                    }
                  }}
                  role="presentation"
                >
                  <PlusOutlined />
                </div>
              ) : (
                <div
                  css={{ height: isMobile || forceMobileUI ? '24px' : '30px' }}
                />
              )}
              {e.isLink && (
                <div
                  className="link-button"
                  onClick={() => {
                    history.push(getCustomerPath(e.link));
                  }}
                  role="presentation"
                >
                  {language === 'zh' ? e.linkTitle.zh : e.linkTitle.en}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
}
SingleProductCard.defaultProps = {
  isShutDown: false,
  forceMobileUI: false,
};

SingleProductCard.propTypes = {
  e: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({ zh: PropTypes.string, en: PropTypes.string }),
    soldOut: PropTypes.bool,
    count: PropTypes.number,
    hashtags: PropTypes.arrayOf(PropTypes.string),
    subtitle: PropTypes.shape({ zh: PropTypes.string, en: PropTypes.string }),
    totalQuantity: PropTypes.number,
    imgUrl: PropTypes.string,
    imgUrlZh: PropTypes.string,
    listPrice: PropTypes.number,
    originalPrice: PropTypes.number,
    isShowUnitPrice: PropTypes.bool,
    allowedShippingDate: PropTypes.arrayOf(PropTypes.string),
    videoIds: PropTypes.arrayOf(
      PropTypes.shape({
        videoId: PropTypes.string,
        language: PropTypes.string,
      })
    ),
    product: PropTypes.shape({
      subcategory: PropTypes.shape({
        title: PropTypes.shape({ en: PropTypes.string, zh: PropTypes.string }),
        color: PropTypes.string,
      }),
      unit: PropTypes.string,
      pictureZh: PropTypes.shape({ key: PropTypes.string }),
      picture: PropTypes.shape({ key: PropTypes.string }),
    }),
    excludeFromFreeShipping: PropTypes.bool,
    isChangeListPriceByDates: PropTypes.bool,
    changeListPriceDates: PropTypes.shape({
      startDate: PropTypes.number,
      endDate: PropTypes.number,
    }),
    numberOfUnits: PropTypes.number,
    isItNotAbleToAddCart: PropTypes.bool,
    isGroupBuy: PropTypes.bool,
    currentGroupOrders: PropTypes.number,
    groupBuyMaxOrderCount: PropTypes.number,
    groupBuyEndDate: PropTypes.number,
    groupBuyStartDate: PropTypes.number,
    groupBuyDiscountRank: PropTypes.arrayOf(
      PropTypes.shape({
        listPrice: PropTypes.number,
      })
    ),
    isLink: PropTypes.bool,
    linkTitle: PropTypes.shape({ en: PropTypes.string, zh: PropTypes.string }),
    link: PropTypes.string,
    minimumAddQuantity: PropTypes.number,
  }).isRequired,
  handleChangeOriginArray: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  translation: PropTypes.func.isRequired,
  onOpenProductDetailModal: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isShutDown: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  forceMobileUI: PropTypes.bool,
};

// const mapStateToProps = (state) => ({
//   intl: PropTypes.shape({
//     formatMessage: PropTypes.func.isRequired,
//   }).isRequired,
//   language: state.language.lang,
// });

// export default connect(
//   mapStateToProps,
//   null
//   )(injectIntl(SingleProductCard));

export default withRouter(injectIntl(SingleProductCard));
