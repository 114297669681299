export default {
  en: {
    'Bunbao Category': 'Bunbao Category',
    createdAt: 'Created At',
    remark: 'Remark',
    discountName: 'Name',
    owner: 'Owner',
    used: 'Used',
    'record&remark': 'record&remark',
    remainedUsage: 'Remaining Usage',
    errorDiscountBeforeDate:
      'This promotion has not started yet. Start Day:[xxx]',
    errorDiscountAfterDate: 'Coupon code expired. Expired Date:',
    errorDiscountLtMin:
      'Your cart does not meet the minimum requirement of [$xx.xx] for code [xxx]',
    errorDiscountGtMax:
      'Your cart exceeds the maximum amount of [$xx.xx] for code [xxx]',
    errorDiscountLtBox:
      'Your cart does not meet the minimum boxes number :[$xx.xx] for code [xxx]',
    errorDiscountGtBox:
      'Your cart exceeds the maximum boxes number :[$xx.xx] for code [xxx]',
    errorDiscountGtLimit:
      'It looks like this coupon code [xxx] has reached its maximum use number',
    errorDiscountNwCustomer:
      'This coupon code is assigned only to new customer with no order placed before',
    errorDiscountIncorrectChannel:
      'Coupon code may only be applied for [cloud store/24hr delivery/group buy/catering] orders.',
    errorDiscountInvalidCode: 'Coupon code does not exist',
    errorNotOwner: 'This coupon code belongs to other customer',
    expired: 'Expired',
    reachedMaximumUsage: 'Used',
    startDate: 'Available',
    expiredDate: 'Exp',
    usageCovered: 'Product Type',
    noDiscount: 'No Coupon Available',
    endsIn: 'Ends in',
    lightningDeal: 'Lightning Deal',
  },
  zh: {
    'Bunbao Category': '棒包分类',
    createdAt: '创建时间',
    remark: '标签',
    discountName: '名字',
    owner: '拥有者',
    used: '使用情况',
    'record&remark': '标签&记录',
    remainedUsage: '剩余次数',
    errorDiscountBeforeDate: '折扣码未到生效的时间,生效时间:',
    errorDiscountAfterDate: '折扣码已过有效日期,有效期:',
    errorDiscountLtMin: '购物车需要达到最小金额[$xx.xx]，方可使用折扣码 [xxx]',
    errorDiscountGtMax: '购物车不得超过最高金额[$xx.xx]，方可使用折扣码 [xxx]',
    errorDiscountLtBox: '您购买的商品未达到最低盒数:',
    errorDiscountGtBox: '您购买的商品超过最大盒数',
    errorDiscountGtLimit: '您的折扣码已达到使用上限',
    errorDiscountNwCustomer: '您输入的折扣码仅限未下单的新用户使用',
    errorDiscountIncorrectChannel:
      '该折扣码仅限在 [云店/24小时预定/团购/公司订餐] 使用',
    errorDiscountInvalidCode: '您输入的折扣码不存在',
    errorNotOwner: '这个折扣码不属于您',
    expired: '已过期',
    reachedMaximumUsage: '已使用',
    expiredDate: '过期日期',
    startDate: '生效时间',
    usageCovered: '适用范围',
    noDiscount: '没有折扣券',
    endsIn: '距结束',
    lightningDeal: '秒杀中',
  },
};
