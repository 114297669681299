/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  ClockCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import BraftEditor from 'braft-editor';
import { Row, Col, Tag, Alert, Radio, Modal, Progress } from 'antd';
import TextLoop from 'react-text-loop';
import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  firstLetterToUpperCase,
  colorGenerator,
  apolloClient,
  translateWords,
  checkWeChatUserAgent,
  getCustomerPath,
  displayDescription,
} from '../../../util';
import SingleProductCard from '../../../containers/Customer/DeliveryMenu/SingleProductCard/SingleProductCard';
import * as actions from '../../../store/actions';
import { DEFAULT_IMAGE, BUCKET_CDN_URL } from '../../../config';
import { CONFIGURATION_FIND_ONE, SHORT_URL } from '../../../api/configuration';
import { WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS } from '../../../api/warehouse';
import {
  BUNBAO_VARIANT_BY_ID,
  VARIANT_GROUP_BUY_ACTIVITY,
  CHECK_VARIANTS_VALIDATION,
  BUNBAO_VARIANT_BY_IDS,
} from '../../../api/variant';
import VideoBanner from '../../VideoBanner/VideoBanner';
import { FETCH_BOX } from '../../../api/container';
import shareIcon from '../../../assets/images/icons/shareIcon.png';
import tipPicture from '../../../assets/images/shareTip.png';
import buttonPicture from '../../../assets/images/shareButton.png';

export class ProductDetailModal extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      words: [],
      selectedDisplayMode: 'picture',
      product: null,
      showTipModal: false,
      groupBuyVariantActivity: [],
      recommendedVariants: [],
      groupBuyRuleVisible: false,
    };
  }

  componentDidMount = async () => {
    const { productList, selectedProductId, showTip } = this.props;
    if (showTip) {
      this.setState({ showTipModal: true });
    }
    let product = productList.find(
      (element) => element.id === selectedProductId
    );
    if (!product) {
      product = await this.fetchVariantById(selectedProductId);
    }
    await this.fetchGroupBuyVariantActivity(selectedProductId);
    await this.handleFetchWords();
    await this.checkVariantsValidation(product?.recommendedVariantIds || []);
    this.setState({
      product,
    });
  };

  checkVariantsValidation = async (variantIds) => {
    try {
      const {
        data: { checkVariantsValidation },
      } = await apolloClient.query({
        query: CHECK_VARIANTS_VALIDATION,
        variables: {
          variantIds,
          shoppingMode: 'supermarket',
        },
      });
      console.log('checkVariantsValidation', checkVariantsValidation);
      const {
        data: { variantByIds },
      } = await apolloClient.query({
        query: BUNBAO_VARIANT_BY_IDS,
        variables: { input: checkVariantsValidation || [] },
      });
      console.log('variantByIds', variantByIds);
      const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
      const newVariantArray =
        variantByIds?.filter((variant) => !variant?.markAsSoldOut) || [];
      newVariantArray?.forEach(async (variantById) => {
        variantById.category = variantById.product.category;
        variantById.hashtags = variantById.product.hashtags;
        variantById.imgUrl = getImg(variantById);
        variantById.id = variantById._id;
        // websiteMenu[index].rank = variantById.ranking;
        if (variantById.count === undefined) {
          variantById.count = 0;
        }
        variantById.needBox = false;
        variantById.product.imgUrl = getImg(variantById.product);
        if (variantById.markAsSoldOut === true) {
          variantById.soldOut = true;
        }
        if (variantById.product.imgUrl !== DEFAULT_IMAGE) {
          variantById.product.imgUrl = `${BUCKET_CDN_URL}${variantById.product.imgUrl}`;
        }
        if (variantById.imgUrl !== DEFAULT_IMAGE) {
          variantById.imgUrl = `${BUCKET_CDN_URL}${variantById.imgUrl}`;
        } else {
          variantById.imgUrl = variantById.product.imgUrl;
        }
        if (variantById.isChangeListPriceByDates) {
          // check date and change list price
          const today = moment().valueOf();
          if (
            today > variantById.changeListPriceDates.endDate ||
            today < variantById.changeListPriceDates.startDate
          ) {
            variantById.listPrice = variantById.originalPrice;
          }
        }
        const { currentWarehouseId } = this.props;
        if (variantById.allowedInventoryCheck) {
          const {
            data: { inventoryCurrentWarehouseWithVariantIds },
          } = await apolloClient.query({
            query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
            variables: {
              warehouseId: currentWarehouseId,
              variantIds: [variantById._id],
            },
            fetchPolicy: 'network-only',
          });
          if (inventoryCurrentWarehouseWithVariantIds) {
            inventoryCurrentWarehouseWithVariantIds.forEach((variant) => {
              if (variant._id === variantById._id) {
                variantById.totalQuantity = variant.totalQuantity;
                if (variant.totalQuantity <= 0) {
                  variantById.soldOut = true;
                }
              }
            });
          }
        }
        const {
          data: { containerMany },
        } = await apolloClient.query({
          query: FETCH_BOX,
          variables: {},
          fetchPolicy: 'network-only',
        });
        // check variant if need box
        const boxSample = containerMany[0];
        if (boxSample.allowedVariantIds.length > 0) {
          boxSample.allowedVariantIds.forEach((variantId) => {
            if (variantById._id === variantId) {
              variantById.needBox = true;
            }
          });
        }
        if (boxSample.notAllowedVariantIds.length > 0) {
          boxSample.notAllowedVariantIds.forEach((variantId) => {
            if (variantById._id === variantId) {
              variantById.needBox = false;
            }
          });
        }
      });
      this.setState({
        recommendedVariants: newVariantArray || [],
      });
    } catch (err) {
      console.log(err);
    }
  };

  getImgUrl = (obj) => {
    const { language } = this.props;
    const keyMap = {
      en: 'picture',
      zh: 'pictureZh',
    };
    const langKey = keyMap[language];
    if (
      !obj[langKey] &&
      !obj?.product[langKey] &&
      !obj?.product?.picture &&
      !obj?.picture
    ) {
      return DEFAULT_IMAGE;
    }
    let picture = null;
    if (obj[langKey] || obj?.picture) {
      picture = `${BUCKET_CDN_URL}${
        obj[langKey]?.key || obj?.picture?.key || obj?.product?.picture?.key
      }`;
    } else {
      picture = `${BUCKET_CDN_URL}${
        obj?.product[langKey]?.key ||
        obj?.picture?.key ||
        obj?.product?.picture?.key
      }`;
    }
    return picture;
  };

  fetchGroupBuyVariantActivity = async (id) => {
    try {
      const {
        data: { variantCheckGroupBuyOrderCount },
      } = await apolloClient.query({
        query: VARIANT_GROUP_BUY_ACTIVITY,
        variables: {
          variantId: id,
        },
      });
      this.setState({
        groupBuyVariantActivity: variantCheckGroupBuyOrderCount || [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  fetchVariantById = async (Id) => {
    try {
      const {
        data: { variantById },
      } = await apolloClient.query({
        query: BUNBAO_VARIANT_BY_ID,
        variables: {
          Id,
        },
      });
      const getImg = R.pathOr(DEFAULT_IMAGE, ['picture', 'key']);
      if (variantById) {
        variantById.category = variantById.product.category;
        variantById.hashtags = variantById.product.hashtags;
        variantById.imgUrl = getImg(variantById);
        variantById.id = variantById._id;
        // websiteMenu[index].rank = variantById.ranking;
        if (variantById.count === undefined) {
          variantById.count = 0;
        }
        variantById.needBox = false;
        variantById.product.imgUrl = getImg(variantById.product);
        if (variantById.markAsSoldOut === true) {
          variantById.soldOut = true;
        }
        if (variantById.product.imgUrl !== DEFAULT_IMAGE) {
          variantById.product.imgUrl = `${BUCKET_CDN_URL}${variantById.product.imgUrl}`;
        }
        if (variantById.imgUrl !== DEFAULT_IMAGE) {
          variantById.imgUrl = `${BUCKET_CDN_URL}${variantById.imgUrl}`;
        } else {
          variantById.imgUrl = variantById.product.imgUrl;
        }
        if (variantById.isChangeListPriceByDates) {
          // check date and change list price
          const today = moment().valueOf();
          if (
            today > variantById.changeListPriceDates.endDate ||
            today < variantById.changeListPriceDates.startDate
          ) {
            variantById.listPrice = variantById.originalPrice;
          }
        }
        const { currentWarehouseId } = this.props;
        if (variantById.allowedInventoryCheck) {
          const {
            data: { inventoryCurrentWarehouseWithVariantIds },
          } = await apolloClient.query({
            query: WAREHOUSES_NET_INVENTORY_BY_VARIANTS_IDS,
            variables: {
              warehouseId: currentWarehouseId,
              variantIds: [variantById._id],
            },
            fetchPolicy: 'network-only',
          });
          if (inventoryCurrentWarehouseWithVariantIds) {
            inventoryCurrentWarehouseWithVariantIds.forEach((variant) => {
              if (variant._id === variantById._id) {
                variantById.totalQuantity = variant.totalQuantity;
                if (variant.totalQuantity <= 0) {
                  variantById.soldOut = true;
                }
              }
            });
          }
        }
        const {
          data: { containerMany },
        } = await apolloClient.query({
          query: FETCH_BOX,
          variables: {},
          fetchPolicy: 'network-only',
        });
        // check variant if need box
        const boxSample = containerMany[0];
        if (boxSample.allowedVariantIds.length > 0) {
          boxSample.allowedVariantIds.forEach((variantId) => {
            if (variantById._id === variantId) {
              variantById.needBox = true;
            }
          });
        }
        if (boxSample.notAllowedVariantIds.length > 0) {
          boxSample.notAllowedVariantIds.forEach((variantId) => {
            if (variantById._id === variantId) {
              variantById.needBox = false;
            }
          });
        }
      }

      return variantById;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  isGroupBuyEnable = (product) => {
    const today = new Date().valueOf();
    if (product.isGroupBuy) {
      return (
        product.groupBuyEndDate > today && product.groupBuyStartDate < today
      );
    }
    return true;
  };

  handleFetchWords = async () => {
    const {
      data: { configurationOne },
    } = await apolloClient.query({
      query: CONFIGURATION_FIND_ONE,
      variables: {
        filter: { isProductConfiguration: true },
      },
    });
    this.setState({ words: configurationOne.words });
  };

  translation = (word) => {
    const { words } = this.state;
    return translateWords(word, words);
  };

  onRadioChange = (e) => {
    this.setState({ selectedDisplayMode: e.target.value });
  };

  lightningDealRender = (e) => {
    if (e.isChangeListPriceByDates) {
      const today = moment().valueOf();
      if (
        today >= e.changeListPriceDates.startDate &&
        today <= e.changeListPriceDates.endDate
      ) {
        return (
          <div
            style={{ fontSize: '20px', color: 'white' }}
            css={{ position: 'absolute', top: '3%', left: '3%' }}
          >
            <Tag color="#f50">
              <FormattedMessage id="lightningDeal" />
            </Tag>
          </div>
        );
      }
    }
    return null;
  };

  groupBuyRender = (e) => {
    if (e.isGroupBuy) {
      return (
        <div
          css={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            background: '#E40000',
            padding: '2px 0px 2px 4px',
            height: '26px',
            color: 'white',
          }}
        >
          <FormattedMessage id="groupBuy" />
          <div
            css={{
              position: 'absolute',
              height: '100%',
              top: '0px',
              right: '-29px',
              border: '13.1px solid transparent',
              borderLeft: '16px solid #E40000',
              pointerEvents: 'none',
            }}
          />
        </div>
      );
    }
    return null;
  };

  subcategoryRender = (e) => {
    const { language } = this.props;
    if (e.product.subcategory) {
      return (
        <div
          style={{
            fontSize: '20px',
            color: 'white',
            fontWeight: '600',
            // transform: 'scale(1, 0.6)',
          }}
          css={{ position: 'absolute', bottom: '0px', left: '0px' }}
        >
          <Tag
            color={
              e.product.subcategory.color &&
              e.product.subcategory.color.trim() !== ''
                ? e.product.subcategory.color
                : colorGenerator(e.product.subcategory.title[language])
            }
          >
            {e.product.subcategory.title[language]}
          </Tag>
        </div>
      );
    }
    return null;
  };

  translation = (word) => {
    const { words } = this.state;
    return translateWords(word, words);
  };

  formatDescription = (str) => {
    if (!str) {
      return BraftEditor.createEditorState(
        convertRawToHTML(JSON.parse('{}'))
      ).toText();
    }
    if (str.startsWith('{"block')) {
      return BraftEditor.createEditorState(
        convertRawToHTML(JSON.parse(str))
      ).toText();
    }
    const htmlStr = `<p>${str}</p>`;
    return BraftEditor.createEditorState(htmlStr).toText();
  };

  render() {
    const {
      selectedProductId,
      language,
      handleIncrement,
      currentShoppingMode,
      isShutDown,
    } = this.props;

    const {
      product,
      showTipModal,
      selectedDisplayMode,
      groupBuyVariantActivity,
      recommendedVariants,
      groupBuyRuleVisible,
    } = this.state;

    /* eslint-disable-next-line */
    const shareIconRender = () => {
      return (
        <div
          style={{ fontSize: '20px', color: 'white' }}
          css={{ position: 'absolute', top: '3%', right: '3%' }}
        >
          <div
            onClick={async () => {
              const { referralCode, history } = this.props;
              let originalUrl = window.location.href;
              if (
                originalUrl.search(getCustomerPath('checkout/payment')) !== -1
              ) {
                originalUrl = originalUrl.replace(
                  getCustomerPath('checkout/payment'),
                  getCustomerPath(`supermarket?productId=${product.id}`)
                );
              }
              if (referralCode) {
                originalUrl += `&referralCode=${referralCode}`;
              }
              // short url
              try {
                const shortUrlResponse = await apolloClient.query({
                  query: SHORT_URL,
                  variables: { url: originalUrl },
                });
                const {
                  data: {
                    configurationShortUrl: { shortUrl },
                  },
                } = shortUrlResponse;
                if (shortUrl !== '') {
                  originalUrl = shortUrl;
                }
              } catch (error) {
                console.error(error);
              }
              let content = '';
              let unitPrice = '';
              if (product.isShowUnitPrice) {
                unitPrice = ` ($${Number(
                  product.listPrice / product.numberOfUnits
                ).toFixed(2)}/${product.product.unit})`;
              }
              if (language === 'zh') {
                content = `【${product.name.zh}${unitPrice}】，超低价棒包【生鲜聚划算】！向朋友们种草，朋友下单后，你将获得3%的订单金额红包哦！ ${originalUrl}`;
              } else {
                content = `Share to your friends and earn credits!\nOnce your friend place an order with this link, earn 3% of his/her order amount right away! ${originalUrl}`;
              }
              if (!navigator.clipboard) {
                const textField = document.createElement('textarea');
                textField.innerText = content;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
              } else {
                await navigator.clipboard.writeText(content);
              }

              if (checkWeChatUserAgent()) {
                this.setState({ showTipModal: true });
              } else {
                const tipModal = Modal.success({
                  content:
                    language === 'zh' ? (
                      <div>
                        <div>
                          分享此产品给身边的好友，下单成功后您将获得订单返利哦！(链接已经粘贴到剪切板了哦)
                        </div>
                        <div>
                          <div
                            onClick={() => {
                              tipModal.destroy();
                              history.push(getCustomerPath('account/balance'));
                            }}
                            css={{
                              cursor: 'pointer',
                              color: 'red',
                              display: 'inline-block',
                            }}
                            role="presentation"
                          >
                            [查看账户余额]
                          </div>
                          <div
                            onClick={() => {
                              tipModal.destroy();
                              history.push(
                                getCustomerPath('account/balance?rules=true')
                              );
                            }}
                            css={{
                              cursor: 'pointer',
                              color: 'red',
                              display: 'inline-block',
                              marginLeft: '5px',
                            }}
                            role="presentation"
                          >
                            [了解活动规则]
                          </div>
                        </div>
                      </div>
                    ) : (
                      'Copied to your clipboard, share the product to your friends'
                    ),
                  maskClosable: true,
                });
                if (navigator.share) {
                  if (language === 'zh') {
                    navigator
                      .share({
                        title: content,
                        text: content,
                        url: originalUrl,
                      })
                      .then(() => console.log('Successful share'))
                      .catch((error) => console.log('Error sharing', error));
                  } else {
                    navigator
                      .share({
                        title: content,
                        text: content,
                        url: originalUrl,
                      })
                      .then(() => console.log('Successful share'))
                      .catch((error) => console.log('Error sharing', error));
                  }
                }
              }
            }}
            role="presentation"
          >
            <img
              src={shareIcon}
              alt="Share Icon"
              css={{
                height: '30px',
                width: '30px !important',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      );
    };

    const renderPriceProgress = () => {
      const transparent = 100;
      const decreasePercent = 80 / product.groupBuyDiscountRank.length;
      return (
        <div style={{ display: 'flex' }}>
          {product.groupBuyDiscountRank &&
            product.groupBuyDiscountRank.map((rank, index) => {
              return (
                <div style={{ flex: 1 }} key={nanoid()}>
                  <div
                    style={{
                      backgroundColor: `rgba(255, 0, 0, ${(
                        1 -
                        (transparent - decreasePercent * (index + 1)) / 100
                      ).toFixed(2)} )`,
                      height: '20px',
                      textAlign: 'center',
                      width: '95%',
                      borderRadius: '8px',
                    }}
                  >
                    ${rank.listPrice}
                  </div>
                  {index === 0 && (
                    <div>
                      {rank.orderItemCount}{' '}
                      {language === 'zh' ? ' 人起' : ' Orders'}
                    </div>
                  )}
                  {index !== 0 &&
                    index !== product.groupBuyDiscountRank.length - 1 && (
                      <div>
                        {rank.orderItemCount}{' '}
                        {language === 'zh' ? '人' : ' Orders'}
                      </div>
                    )}
                  {index === product.groupBuyDiscountRank.length - 1 && (
                    <div>
                      {rank.orderItemCount}{' '}
                      {language === 'zh' ? '人以上' : 'Orders'}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      );
    };

    const checkGroupBuyRulesLanguage = () => {
      return (
        product?.groupBuyRules?.ruleDetailDescription &&
        product?.groupBuyRules?.ruleDetailDescription[language] &&
        BraftEditor.createEditorState(
          convertRawToHTML(
            JSON.parse(
              product?.groupBuyRules?.ruleDetailDescription[language] || '{}'
            )
          )
        ).toText()
      );
    };

    return (
      <div css={{ marginTop: '20px' }}>
        <Modal
          visible={checkGroupBuyRulesLanguage() && groupBuyRuleVisible}
          footer={null}
          header={<FormattedMessage id="groupBuyRules" />}
          onCancel={() => {
            this.setState({
              groupBuyRuleVisible: false,
            });
          }}
          destroyOnClose
        >
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {product?.groupBuyRules?.ruleDetailDescription[language] &&
              convert(
                convertRawToHTML(
                  JSON.parse(
                    product?.groupBuyRules?.ruleDetailDescription[language] ||
                      '{}'
                  )
                )
              )}
          </div>
        </Modal>
        {showTipModal && (
          <div
            css={{
              position: 'fixed',
              left: '0',
              top: '0',
              bottom: '0',
              right: '0',
              zIndex: '18888',
              backgroundColor: '#000000',
              opacity: '0.7',
            }}
          >
            <div css={{ position: 'absolute', right: '0', top: '0' }}>
              <img
                src={tipPicture}
                alt="Tip"
                css={{ width: '70%', float: 'right' }}
              />
              <img
                src={buttonPicture}
                alt="Button"
                css={{ width: '70%', float: 'right' }}
                onClick={() => {
                  this.setState({ showTipModal: false });
                }}
                role="presentation"
              />
            </div>
          </div>
        )}

        {product ? (
          <div
            css={{
              '& .product-picture': {
                width: '100%',
              },
              '& .product-picture img': {
                width: '100%',
                objectFit: 'cover',
              },
              '& .product-title': {
                margin: '10px 0',
                fontSize: '20px',
                fontWeight: '800',
                lineHeight: '1.3',
              },
              '& .product-ingredients': {
                margin: '10px 0',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '1.3',
              },
              '& .increment-button': {
                display: 'inline-block',
                width: isMobile ? '24px' : '30px',
                height: isMobile ? '24px' : '30px',
                border:
                  product.soldOut !== true && this.isGroupBuyEnable(product)
                    ? '1px solid #DF242F'
                    : '1px solid #8bbabb',
                backgroundColor:
                  product.soldOut !== true && this.isGroupBuyEnable(product)
                    ? '#DF242F'
                    : '#8bbabb',
                borderRadius: '5px',
                textAlign: 'center',
                fontSize: isMobile ? '14px' : '20px',
                color: 'white',
                cursor: 'pointer',
                userSelect: 'none',
                ':hover': {
                  backgroundColor:
                    product.soldOut !== true && this.isGroupBuyEnable(product)
                      ? '#eb4b50'
                      : '#8bbabb',
                },
              },
              '& .product-description': {
                fontSize: '16px',
              },
            }}
          >
            {product.imgUrl && product.videoIds && product.videoIds.length > 0 && (
              <div css={{ textAlign: 'center', marginBottom: '10px' }}>
                <Radio.Group
                  onChange={this.onRadioChange}
                  defaultValue="picture"
                  buttonStyle="solid"
                >
                  <Radio.Button value="picture">Picture</Radio.Button>
                  <Radio.Button value="video">Video</Radio.Button>
                </Radio.Group>
              </div>
            )}
            {selectedDisplayMode === 'picture' ? (
              <div className="product-picture" style={{ position: 'relative' }}>
                <img src={this.getImgUrl(product)} alt="Product" />
                {this.lightningDealRender(product)}
                {this.subcategoryRender(product)}
                {this.groupBuyRender(product)}
                {/* {shareIconRender(product)} */}
              </div>
            ) : (
              product.videoIds &&
              product.videoIds.length > 0 && (
                <VideoBanner videos={[product]} containerWidth="auto" />
              )
            )}
            <div className="product-title">{product.name[language]} </div>
            {(product?.isGroupBuy ||
              product?.allowedShippingDate?.length > 0) && (
              <div style={{ paddingBottom: '10px' }}>
                <span css={{ color: 'red' }}>
                  <ClockCircleOutlined style={{ marginRight: '6px' }} />{' '}
                  {product?.isGroupBuy && (
                    <span>
                      {language === 'zh' ? '团购时间:' : 'Pre-order:'}
                      {` ${moment(product?.groupBuyStartDate).format(
                        'MM/DD'
                      )} - ${moment(product?.groupBuyEndDate).format(
                        'MM/DD'
                      )} `}
                    </span>
                  )}
                  {product?.allowedShippingDate?.length > 0 && (
                    <span style={{ color: 'red' }}>
                      <FormattedMessage id="limitedProductsDate" />
                      {': '}
                    </span>
                  )}
                </span>

                {product?.allowedShippingDate?.length > 0 &&
                  product.allowedShippingDate.map((date, index) => {
                    if (index === product.allowedShippingDate.length - 1) {
                      return (
                        <span key={nanoid(6)} css={{ color: 'red' }}>
                          {`${moment(date.trim(), 'YYYY-MM-DD').format(
                            'M-DD'
                          )}`}
                        </span>
                      );
                    }
                    return (
                      <span key={nanoid(6)} css={{ color: 'red' }}>
                        {`${moment(date.trim(), 'YYYY-MM-DD').format(
                          'M-DD'
                        )}, `}
                      </span>
                    );
                  })}
              </div>
            )}
            {product?.isGroupBuy &&
              product?.groupBuyDiscountRank &&
              product?.groupBuyDiscountRank?.length > 0 &&
              renderPriceProgress()}
            <div className="product-ingredients">
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  {product.hashtags.map((hashtag) => (
                    <Tag key={nanoid(6)} color={colorGenerator(hashtag)}>
                      {language === 'zh' ? this.translation(hashtag) : hashtag}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row type="flex" gutter={10}>
                <Col xs={24} sm={24} lg={12}>
                  {product.product.ingredients &&
                  product.product.ingredients !== '' ? (
                    <Row>
                      <Col>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '17px',
                            fontWeight: '500',
                            marginTop: '10px',
                          }}
                        >
                          <FormattedMessage id="ingredients" />
                        </div>
                        <div style={{ overflowWrap: 'break-word' }}>
                          {product.product.ingredients
                            .split('\n')
                            .map((ingredient, index) => {
                              if (
                                index !==
                                product.product.ingredients.split('\n').length -
                                  1
                              ) {
                                return language === 'zh'
                                  ? `${this.translation(
                                      `${ingredient}`.toLowerCase()
                                    )}, `
                                  : `${ingredient.toLowerCase()}, `;
                              }
                              return language === 'zh'
                                ? `${this.translation(
                                    `${ingredient}`.toLowerCase()
                                  )}`
                                : `${ingredient.toLowerCase()}`;
                            })}
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  {product.product.nutritionFacts &&
                  product.product.nutritionFacts !== '' ? (
                    <Row>
                      <Col>
                        <Row
                          style={{
                            textAlign: 'center',
                            fontSize: '17px',
                            fontWeight: '500',
                            marginTop: '10px',
                          }}
                        >
                          <FormattedMessage id="nutritionFacts" />
                        </Row>
                        <Row>
                          <Col
                            span={24}
                            key="per serving"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {language === 'zh' ? '每' : 'Amount per serving'} 1{' '}
                            {`${
                              language === 'zh'
                                ? this.translation(product.product.unitShape)
                                : product.product.unitShape
                            } (${product.product.unitNumber} ${
                              product.product.unit
                            })`}
                          </Col>
                          {product.product.nutritionFacts
                            .split('\n')
                            .map((fact) => {
                              if (
                                !fact.toLowerCase().match('amount per serving')
                              ) {
                                return (
                                  <Row
                                    key={nanoid(6)}
                                    gutter={2}
                                    style={{ width: '100%' }}
                                  >
                                    <Col
                                      span={12}
                                      style={{ whiteSpace: 'nowrap' }}
                                    >
                                      {language === 'zh'
                                        ? this.translation(fact.split(',')[0])
                                        : fact.split(',')[0]}
                                    </Col>
                                    <Col
                                      style={{
                                        textAlign: 'right',
                                        whiteSpace: 'nowrap',
                                      }}
                                      span={12}
                                    >
                                      {firstLetterToUpperCase(
                                        fact.split(',')[1]
                                      )}
                                    </Col>
                                  </Row>
                                );
                              }
                              return null;
                            })}
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </div>
            <div css={{ fontSize: '16px', fontWeight: '500' }}>
              {currentShoppingMode === 'donation' && (
                <span>
                  <FormattedMessage id="donation.ourMission" />:
                </span>
              )}
              {(this.formatDescription(product?.description[language]) ||
                this.formatDescription(
                  product?.product?.description[language]
                )) && (
                <span>
                  <FormattedMessage id="productDescription" />:
                </span>
              )}
            </div>
            <div className="product-description">
              {!this.formatDescription(product?.description[language]) &&
                this.formatDescription(
                  product?.product?.description[language]
                ) && (
                  <div key={nanoid(5)} style={{ whiteSpace: 'pre-wrap' }}>
                    {convert(
                      convertRawToHTML(
                        JSON.parse(
                          displayDescription(
                            product.product.description[language]
                          )
                        )
                      )
                    )}
                    {/* {product.product.description[language]} */}
                  </div>
                )}
              {!this.formatDescription(
                product?.product?.description[language]
              ) &&
                this.formatDescription(product?.description[language]) && (
                  <div key={nanoid(5)} style={{ whiteSpace: 'pre-wrap' }}>
                    {convert(
                      convertRawToHTML(
                        JSON.parse(
                          displayDescription(product.description[language])
                        )
                      )
                    )}
                    {/* {product.description[language]} */}
                  </div>
                )}
              {this.formatDescription(
                product?.product?.description[language]
              ) &&
                this.formatDescription(product?.description[language]) && (
                  <div key={nanoid(5)} style={{ whiteSpace: 'pre-wrap' }}>
                    {convert(
                      convertRawToHTML(
                        JSON.parse(
                          displayDescription(product.description[language])
                        )
                      )
                    )}
                    {/* {product.description[language]} */}
                  </div>
                )}
            </div>
            <div>
              {product.product.allergen && product.product.allergen !== '' ? (
                <Alert
                  style={{ marginTop: '10px' }}
                  message={`${
                    language === 'zh' ? '过敏原信息' : 'Allergen Information'
                  }: ${product.product.allergen
                    .split(',')
                    .map((item) =>
                      language === 'zh'
                        ? ` ${this.translation(item.trimLeft().trimRight())}`
                        : ` ${item}`
                    )}`}
                  type="warning"
                  showIcon
                />
              ) : null}
            </div>

            {groupBuyVariantActivity?.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                <Alert
                  type="success"
                  style={{ width: '100%', wordWrap: 'break-word' }}
                  message={
                    <TextLoop
                      interval={1000}
                      mask
                      noWrap={!isMobile}
                      style={{ width: '100%', wordWrap: 'break-word' }}
                    >
                      {groupBuyVariantActivity.map((log) => (
                        <div
                          key={nanoid(6)}
                          style={{ width: '100%', wordWrap: 'break-word' }}
                        >
                          {language === 'zh'
                            ? `${log.email} 订购 x ${log.quantity} ${moment(
                                log.orderDate
                              ).format('MM/DD')}`
                            : `${log.email} purchased x ${
                                log.quantity
                              } ${moment(log.orderDate).format('MM/DD')}`}
                        </div>
                      ))}
                    </TextLoop>
                  }
                />
              </div>
            )}
            {product?.count > 0 &&
              product?.minimumAddQuantity > 1 &&
              !product?.soldOut && (
                <div style={{ textAlign: 'right', color: 'red' }}>
                  {language === 'en'
                    ? `Minimum ${product?.minimumAddQuantity}`
                    : `${product?.minimumAddQuantity}份起订`}
                </div>
              )}
            {product.isItNotAbleToAddCart !== true && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                  width: '100%',
                }}
              >
                <div style={{ maxWidth: 'calc(90% - 150px)', width: '100%' }}>
                  {product?.isGroupBuy && (
                    <div>
                      <Progress
                        strokeColor={
                          (product?.currentGroupOrders /
                            (product?.groupBuyDiscountRank?.length > 0
                              ? product?.groupBuyDiscountRank[
                                  product.groupBuyDiscountRank.length - 1
                                ].orderItemCount
                              : product?.groupBuyMaxOrderCount)) *
                            100 >=
                          100
                            ? '#52c41a'
                            : 'red'
                        }
                        percent={
                          (product?.currentGroupOrders /
                            (product?.groupBuyDiscountRank?.length > 0
                              ? product?.groupBuyDiscountRank[
                                  product.groupBuyDiscountRank.length - 1
                                ].orderItemCount
                              : product?.groupBuyMaxOrderCount)) *
                          100
                        }
                        format={() =>
                          `${product?.currentGroupOrders} / ${
                            product?.groupBuyDiscountRank?.length > 0
                              ? product?.groupBuyDiscountRank[
                                  product.groupBuyDiscountRank.length - 1
                                ].orderItemCount
                              : product?.groupBuyMaxOrderCount
                          }`
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="flex-center">
                  <div css={{ display: 'inline-block' }}>
                    {product.originalPrice &&
                    Number(product.originalPrice) !== 0 &&
                    Number(product.originalPrice) !==
                      Number(product.listPrice) ? (
                      <span>
                        <span css={{ color: 'red ' }}>
                          ${product.listPrice}
                        </span>{' '}
                        <del>${product.originalPrice}</del>{' '}
                        {product.isShowUnitPrice && (
                          <span css={{ color: '#565959', fontSize: '12px' }}>
                            ($
                            {Number(
                              product.listPrice / product.numberOfUnits
                            ).toFixed(2)}
                            /{product.product.unit})
                          </span>
                        )}
                      </span>
                    ) : (
                      <span>
                        ${product.listPrice}{' '}
                        {product.isShowUnitPrice && (
                          <span css={{ color: '#565959', fontSize: '12px' }}>
                            ($
                            {Number(
                              product.listPrice / product.numberOfUnits
                            ).toFixed(2)}
                            /{product.product.unit})
                          </span>
                        )}
                      </span>
                    )}
                  </div>{' '}
                  {!isShutDown && (
                    <div
                      className="increment-button"
                      onClick={async () => {
                        if (
                          product.soldOut !== true &&
                          this.isGroupBuyEnable(product)
                        ) {
                          handleIncrement(selectedProductId);
                        }
                      }}
                      role="presentation"
                    >
                      <PlusOutlined />
                    </div>
                  )}
                </div>
              </div>
            )}
            {product?.isGroupBuy &&
              product?.groupBuyRules?.ruleShortInfo &&
              product?.groupBuyRules?.ruleShortInfo[language] && (
                <div
                  role="presentation"
                  onClick={() => {
                    this.setState({ groupBuyRuleVisible: true });
                  }}
                  style={{
                    padding: '30px 0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontWeight: 'bold',
                        color: 'red',
                        fontSize: '16px',
                      }}
                    >
                      <FormattedMessage id="groupBuyRules" />
                    </div>
                    <div style={{ fontSize: '15px' }}>
                      {product?.groupBuyRules?.ruleShortInfo[language]}
                    </div>
                  </div>
                  <div>
                    <RightOutlined />
                  </div>
                </div>
              )}
            {recommendedVariants?.length > 0 && (
              <div>
                <h3 style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="recommendedVariants" />
                </h3>
                <div>
                  {recommendedVariants.map((variant) => (
                    <SingleProductCard
                      forceMobileUI
                      key={variant._id}
                      e={variant}
                      language={language}
                      translation={this.translation}
                      onOpenProductDetailModal={() => {}}
                      handleDecrement={() => {}}
                      handleChangeOriginArray={() => {}}
                      handleIncrement={() => {
                        if (
                          variant.soldOut !== true &&
                          this.isGroupBuyEnable(variant)
                        ) {
                          handleIncrement(variant._id);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

ProductDetailModal.defaultProps = {
  selectedProductId: null,
  isShutDown: false,
  showTip: false,
  referralCode: null,
};

ProductDetailModal.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedProductId: PropTypes.string,
  handleIncrement: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  currentShoppingMode: PropTypes.string.isRequired,
  currentWarehouseId: PropTypes.string.isRequired,
  isShutDown: PropTypes.bool,
  showTip: PropTypes.bool,
  referralCode: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  currentShoppingMode: state.user.currentShoppingMode,
  currentWarehouseId: state.warehouse.warehouseId,
  referralCode: state.user.referralCode,
});

const mapDispatchToProps = (dispatch) => ({
  updateWarehouseCart: (shoppingCart) =>
    dispatch(actions.updateWarehouseCart(shoppingCart)),
  getCurrentWarehouseCart: () => dispatch(actions.getCurrentWarehouseCart()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailModal)
);
