/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react';
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import * as actions from '../../../store/actions';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Col, Row, Divider, Modal, Alert, Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
// import { FacebookProvider, Share,ShareButton } from 'react-facebook';
import { getCustomerPath, apolloClient } from '../../../../util';
import { USER_CURRENT_COUPON } from '../../../../api/user';
import ruleZh from '../../../../assets/images/invitationEn.jpg';
import ruleEn from '../../../../assets/images/invitationZh.jpg';
// import facebook from '../../../../assets/images/icons/facebook.svg';
// import instagram from '../../../../assets/images/icons/instagram.svg';
// import wechat from '../../../../assets/images/icons/wechat.svg';
// import qrCode from '../../../../assets/images/icons/qr-code.svg';
import copy from '../../../../assets/images/icons/copy.svg';

export class Invitation extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      invitees: 0,
      coupon: 0,
    };
  }

  componentDidMount = async () => {
    await this.fetchCurrentInvitees();
  };

  fetchCurrentInvitees = async () => {
    try {
      const {
        data: { userCurrent },
      } = await apolloClient.query({
        query: USER_CURRENT_COUPON,
        variables: {},
        fetchPolicy: 'network-only',
      });
      if (userCurrent.invitees) {
        let coupon = 0;
        userCurrent.invitees.forEach((invitee) => {
          if (invitee.isNewUser === false) {
            coupon += 1;
          }
        });
        await this.setState({ invitees: userCurrent.invitees.length, coupon });
      }
    } catch (error) {
      message.error('error');
    }
  };

  shareLink = () => {};

  render() {
    const { invitees, coupon } = this.state;
    const { language, intl, history, isNewUser, userId } = this.props;
    const cssObj = {
      border: '2px solid #f2f2f2',
      textAlign: 'center',
      width: '100%',
      // color: '#eb4b50',
      // backgroundColor: 'red',
      borderRadius: '2px',
      // marginTop: '3px',
      // marginBottom: '8px',
      fontSize: '20px',
      boxShadow: ' 3px 3px 3px 3px #ccc',
    };
    const iconCss = {
      textAlign: 'center',
    };
    const link = `${
      window.location.host + getCustomerPath('signup')
    }?invitationCode=${userId}`;

    const renderContent = (
      <React.Fragment>
        {/* <FacebookProvider appId="123456789">
        <ShareButton href="http://www.facebook.com">
          Share
        </ShareButton>
      </FacebookProvider> */}
        <img
          src={language === 'en' ? ruleEn : ruleZh}
          alt="rule"
          css={{ width: '100%' }}
        />

        {isNewUser === true ? (
          <Row justify="center" type="flex">
            <Alert
              showIcon
              message={
                <div>
                  {intl.formatMessage({ id: 'oldCustomerOnly' })}
                  <Button
                    onClick={() => {
                      history.push(getCustomerPath('home'));
                    }}
                    type="primary"
                  >
                    {' '}
                    <FormattedMessage id="placeOrder" />{' '}
                  </Button>{' '}
                </div>
              }
              type="info"
            />
          </Row>
        ) : (
          <React.Fragment>
            <Divider style={{ fontSize: '25px', fontWeight: 'bold' }}>
              <FormattedMessage id="howToShare" />
            </Divider>
            {/* <Row justify="center" type="flex" gutter={10}>
              <Col css={iconCss} span={6}>
                {' '}
                <img
                  style={{ width: '70%' }}
                  src={facebook}
                  alt="facebook Icon"
                />{' '}
                Facebook{' '}
              </Col>
              <Col css={iconCss} span={6}>
                {' '}
                <img
                  style={{ width: '70%' }}
                  src={wechat}
                  alt="wechat Icon"
                />{' '}
                Wechat
              </Col>
              <Col css={iconCss} span={6}>
                {' '}
                <img
                  style={{ width: '70%' }}
                  src={instagram}
                  alt="instagram Icon"
                />{' '}
                Instagram{' '}
              </Col>
            </Row> */}
            <Row justify="center" type="flex" css={{ marginTop: '10px' }}>
              <Col css={iconCss} span={6}>
                <CopyToClipboard
                  text={
                    language === 'zh'
                      ? `湾区最好吃的包子,送到你家!现在点击链接注册,立刻领取$10优惠券,链接:${link}`
                      : `Bunbao, Handcrafted Quality Delivered Fresh.Sign Up Now by Link to Get $10:${link}`
                  }
                  onCopy={() => {
                    Modal.success({
                      content: intl.formatMessage({ id: 'copied' }),
                    });
                  }}
                >
                  <div>
                    <img style={{ width: '70%' }} src={copy} alt="Copy Icon" />
                    <div>
                      {' '}
                      <FormattedMessage id="shareLink" />
                    </div>
                  </div>
                </CopyToClipboard>
              </Col>
              {/* <Col css={iconCss} span={6}>
                {' '}
                <img style={{ width: '70%' }} src={qrCode} alt="Share Icon" />
                <div>
                  <FormattedMessage id="shareCode" />
                </div>{' '}
              </Col> */}
            </Row>
          </React.Fragment>
        )}
        <Divider style={{ fontSize: '25px', fontWeight: 'bold' }}>
          <FormattedMessage id="summary" />
        </Divider>
        <Row justify="center" type="flex" gutter={10}>
          <Col css={{ margin: '3px 10px 8px 10px' }} span={10}>
            <div css={cssObj}>
              <FormattedMessage id="currentInviteesAmount" />
              <div css={{ fontSize: '35px', fontFamily: 'Calistoga' }}>
                {invitees}
              </div>
              <FormattedMessage id="currentInviteesAmount2" />
            </div>
          </Col>
          <Col css={{ margin: '3px 10px 8px 10px' }} span={10}>
            <div css={cssObj}>
              <FormattedMessage id="currentObtainedCoupon" />
              <div css={{ fontSize: '35px', fontFamily: 'Calistoga' }}>
                {coupon}
              </div>
              <FormattedMessage id="currentObtainedCoupon2" />
              <div css={{ borderTop: '1px dashed' }}>
                {' '}
                <Button
                  onClick={() => {
                    history.push(getCustomerPath('account/personal-coupon'));
                  }}
                >
                  <FormattedMessage id="view" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );

    return isMobile ? (
      <div>{renderContent}</div>
    ) : (
      <Row justify="center" type="flex">
        <Col
          css={{ border: '1px solid #f2f2f2' }}
          xxl={8}
          xl={10}
          sm={14}
          lg={18}
          xs={24}
        >
          {renderContent}{' '}
        </Col>{' '}
      </Row>
    );
  }
}
Invitation.propTypes = {
  language: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isNewUser: PropTypes.bool.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  userId: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  language: state.language.lang,
  userId: state.user.userId,
  isNewUser: state.user.isNewUser,
});

// const mapDispatchToProps = (dispatch) => ({
//   setLanguage: (language) => dispatch(actions.setLanguage(language)),
//   saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
// });

export default connect(mapStateToProps, null)(injectIntl(Invitation));
