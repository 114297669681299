export default {
  en: {
    bunBao: 'BunBao',
    categories: 'Categories',
    category: 'Category',
    subcategory: 'Subcategory',
    catering: 'Catering',
    cateringOrder: 'Catering Order',
    cateringOrders: 'Catering Orders',
    configuration: 'Configuration',
    customer: 'Customer',
    customerOrders: 'Customer Orders',
    dashboard: 'Dashboard',
    group: 'Group',
    groups: 'Groups',
    hello: 'Hello',
    currentManagedWarehouse: 'Current Warehouse',
    home: 'Home',
    hours: 'Hours',
    hour: 'Hour',
    inventories: 'Inventories',
    inventory: 'Inventory',
    inventoryLogs: 'Inventory Logs',
    login: 'Login',
    loginAsGuest: 'Login as Guest',
    alreadyGuest: 'Guest Mode',
    loginSignUp: 'Login/Sign Up',
    logout: 'Logout',
    menu: 'Menu',
    free: 'Free',
    minute: 'Minute',
    minutes: 'Minutes',
    order: 'Order',
    overview: 'Overview',
    picture: 'Picture',
    pictures: 'Pictures',
    boxes: 'Boxes',
    suppliers: 'Suppliers',
    product: 'Product',
    products: 'Products',
    profile: 'Profile',
    search: 'Search',
    subscriber: 'Subscriber',
    subscribers: 'Subscribers',
    second: 'Second',
    seconds: 'Seconds',
    user: 'User',
    users: 'Users',
    usersTotal: 'Total Users',
    totalSales: 'Total Sales',
    oldUserRatio: 'order >=1 User',
    receivedAmountTotal: 'Received Amount Total',
    averagePricePerOrder: 'Average Amount Per Order',
    frequencyPerUser: 'Purchase Frequency Per User',
    discountRate: 'Discount Rate',
    rules: 'Rules',
    warehouse: 'Warehouse',
    warehouses: 'Warehouses',
    variant: 'Variant',
    variants: 'Variants',
    discount: 'Discount',
    votes: 'Votes',
    onSale: 'onSale',
    orderNow: 'Order Now',
    campaign: 'Campaign',
    fmInvitation: 'FM Invitation',
    referralCode: 'Referral Code',
    referralConfiguration: 'Referral ConFiguration',
    referralOverall: 'Referral Overall',
    sales: 'Sales',
    previous: 'Previous',
    successSendEmail: 'Successfully Send Email',
    successResetPassword: 'Password Rest Successful',
    usingNewPassword: 'Please using your new password to login',
    invitationCode: 'Invitation Code (Optional)',
    invitationCode2: 'Invitation Code',
    article: 'Article',
    articles: 'Articles',
    manufactureOrder: 'Manufacture Order',
    manufactureOrders: 'Manufacture Orders',
    statistic: 'Statistic',
    date: 'Date',
    label: 'Label',
    labels: 'Labels',
    startTime: 'StartTime',
    endTime: 'End Time',
    warehouseManagement: 'Warehouse Management',
    points: 'Points',
    pointsLog: 'Points Log',
    closeModal: 'Close',
    manufactureProductList: 'Product List',
    days: 'Days',
    day: 'Day',
    loginOrSignUp: 'Login/Signup',
    foodTruckProducts: 'Food Truck Products',
    distributionCenter: 'Distribution Center',
    websiteProductManagement: 'Website Product Management',
    foodTruckReportByCar: 'Food Truck Report By Car',
    foodTruckScheduleAndCatalog: 'Food Truck Schedule And Catalog',
    squareOrders: 'Square Orders',
  },
  zh: {
    bunBao: '棒！包',
    categories: '分类',
    subcategory: '子分类',
    category: '分类',
    catering: '企业餐',
    cateringOrder: '企业餐订单',
    cateringOrders: '企业餐订单',
    configuration: '配置',
    customer: '客户',
    customerOrders: '客户订单',
    dashboard: '总览',
    group: '分组',
    groups: '分组',
    hello: '您好',
    currentManagedWarehouse: '当前店铺',
    home: '主页',
    hours: '小时',
    hour: '小时',
    inventories: '库存',
    inventory: '库存',
    inventoryLogs: '库存记录',
    login: '登录',
    loginAsGuest: '游客登录',
    alreadyGuest: '已是游客',
    loginSignUp: '登录/注册',
    logout: '登出',
    menu: '菜单',
    minute: '分钟',
    minutes: '分钟',
    order: '订单',
    overview: '控制面板',
    picture: '图片',
    pictures: '图片',
    boxes: '盒子',
    suppliers: '供应商',
    product: '产品',
    products: '产品',
    profile: '配置',
    search: '搜索',
    subscriber: '订阅者',
    subscribers: '订阅者',
    second: '秒',
    seconds: '秒',
    user: ' 用户',
    users: ' 用户',
    usersTotal: '用户总数',
    totalSales: '销售额',
    oldUserRatio: '老用户比例',
    receivedAmountTotal: '收款',
    averagePricePerOrder: '客单价',
    frequencyPerUser: '用户订购频率',
    discountRate: '折扣率',
    rules: '规则',
    warehouse: '云店',
    warehouses: '云店',
    variant: '变体',
    variants: '变体',
    discount: '优惠券',
    votes: '投票',
    onSale: '秒杀',
    campaign: '活动',
    fmInvitation: '农贸市场邀请码',
    referralCode: '推销码',
    referralConfiguration: '推销配置',
    referralOverall: '推销总览',
    sales: '销售',
    previous: '上一步',
    successSendEmail: '邮件发送成功',
    successResetPassword: '成功重新设置密码',
    usingNewPassword: '请使用新密码登录',
    invitationCode: '邀请码 (可选)',
    invitationCode2: '邀请码',
    article: '文章',
    articles: '文章',
    free: '免费',
    manufactureOrder: '生产单',
    manufactureOrders: '生产单',
    statistic: '数据统计',
    date: '日期',
    label: '标签',
    labels: '标签',
    startTime: '开始时间',
    endTime: '结束时间',
    warehouseManagement: '本店管理',
    points: '积分',
    pointsLog: '积分日志',
    closeModal: '关闭',
    manufactureProductList: '生产列表',
    days: '天',
    day: '天',
    loginOrSignUp: '登录/注册',
    foodTruckProducts: '餐车产品',
    distributionCenter: '配送中心',
    websiteProductManagement: '网站产品管理',
    foodTruckReportByCar: '餐车报表(按照车辆)',
    foodTruckScheduleAndCatalog: '餐车日程和目录',
    squareOrders: 'Square订单',
  },
};
