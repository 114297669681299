import React, { Component } from 'react';
import { Button, Col, Popconfirm, Row, Table, Tag, message } from 'antd';
import PropTypes from 'prop-types';
import { colorGenerator, apolloClient } from '../../../../util';
import CateringOrderBasicForm from '../../../../components/Forms/CateringOrderBasicForm/CateringOrderBasicForm';
import CateringOrderAdminForm from '../../../../components/Forms/CateringOrderAdminForm/CateringOrderAdminForm';
import {
  ORDER_BY_ID,
  ORDER_CONFIRM,
  ORDER_MARK_VOID,
  ORDER_MARK_UNCOLLECTIBLE,
  ORDER_FINALIZE,
} from '../../../../api/order';

const { Column } = Table;

export class CateringOrderAdmin extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      order: null,
      buttonLoading: false,
    };
  }

  async componentDidMount() {
    // first check order id
    await this.handleFetchOrder();
  }

  handleFetchOrder = async () => {
    const { orderId } = this.props;
    console.log(orderId);
    try {
      const response = await apolloClient.query({
        query: ORDER_BY_ID,
        variables: { id: orderId },
        fetchPolicy: 'network-only',
      });
      console.log(response);
      const { data } = response;
      this.setState({ order: data.orderById });
    } catch (error) {
      console.error(error);
      message.error('Can not fetch order details');
      message.error(error.graphQLErrors[0].message);
    }
  };

  handleConfirmOrder = async () => {
    this.setState({ buttonLoading: true });
    const { order } = this.state;
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_CONFIRM,
        variables: { orderId: order._id },
      });
      console.log(response);
      await this.handleFetchOrder();
    } catch (error) {
      console.error(error);
      message.error(error.graphQLErrors[0].message);
    }
    this.setState({ buttonLoading: false });
  };

  handleFinalizeOrder = async () => {
    const { order } = this.state;
    this.setState({ buttonLoading: true });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_FINALIZE,
        variables: { orderId: order._id },
      });
      console.log(response);
      await this.handleFetchOrder();
    } catch (error) {
      console.error(error);
      message.error(error.graphQLErrors[0].message);
    }
    this.setState({ buttonLoading: false });
  };

  handleMarkOrderVoid = async () => {
    const { order } = this.state;
    this.setState({ buttonLoading: true });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_MARK_VOID,
        variables: { orderId: order._id },
      });
      console.log(response);
      await this.handleFetchOrder();
    } catch (error) {
      console.error(error);
      message.error(error.graphQLErrors[0].message);
    }
    this.setState({ buttonLoading: false });
  };

  handleMarkPaid = async () => {
    const { order } = this.state;
    this.setState({ buttonLoading: true });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_MARK_VOID,
        variables: { orderId: order._id },
      });
      console.log(response);
      await this.handleFetchOrder();
    } catch (error) {
      console.error(error);
      message.error(error.graphQLErrors[0].message);
    }
    this.setState({ buttonLoading: false });
  };

  handleMarkOrderUncollectible = async () => {
    const { order } = this.state;
    this.setState({ buttonLoading: true });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_MARK_UNCOLLECTIBLE,
        variables: { orderId: order._id },
      });
      console.log(response);
      await this.handleFetchOrder();
    } catch (error) {
      console.error(error);
      message.error(error.graphQLErrors[0].message);
    }
    this.setState({ buttonLoading: false });
  };

  render() {
    const { order, buttonLoading } = this.state;
    const deliveryFee = (record) =>
      record.deliveryFee ? record.deliveryFee.fee : 0;
    return (
      <div>
        <Row>
          <Col>
            <h3>
              Order Detail <Tag color={colorGenerator('Admin')}>Admin</Tag>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={18}>
            {order !== null ? (
              <>
                <CateringOrderBasicForm order={order} />
                <CateringOrderAdminForm />
              </>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {order !== null ? (
              <Table
                dataSource={order.products}
                rowKey={(record) => record._id}
                footer={() => (
                  <Row type="flex" justify="end" style={{ textAlign: 'right' }}>
                    <Col span={24} style={{ margin: '3px 0px' }}>
                      {`Subtotal: $${Number(order.subtotal).toFixed(2)}`}
                    </Col>
                    <Col span={24} style={{ margin: '3px 0px' }}>
                      {`Tax: $${(
                        Number(order.subtotal) * Number(order.taxRate / 100)
                      ).toFixed(2)}`}
                    </Col>
                    <Col span={24} style={{ margin: '3px 0px' }}>
                      {`Delivery Fee: $${Number(deliveryFee(order)).toFixed(
                        2
                      )}`}
                    </Col>
                    <Col span={24} style={{ margin: '3px 0px' }}>
                      {`Total: $${(
                        Number(order.subtotal) +
                        Number(order.subtotal) * Number(order.taxRate / 100) +
                        Number(deliveryFee(order))
                      ).toFixed(2)}`}
                    </Col>
                  </Row>
                )}
                pagination={false}
              >
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Quantity" dataIndex="quantity" key="quantity" />
                <Column title="Price" dataIndex="price" key="price" />
                <Column
                  title="Total"
                  key="total"
                  render={(text, record) => (
                    <div>{(record.quantity * record.price).toFixed(2)}</div>
                  )}
                />
              </Table>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col>
            Actions <Tag color="red">Important! CAN NOT UNDO!</Tag>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }} gutter={10}>
          {order !== null ? (
            <Col>
              <Popconfirm
                title="Are you sure confirm this order?"
                onConfirm={this.handleConfirmOrder}
                okText="Yes"
                cancelText="No"
                disabled={order.confirmed}
              >
                <Button
                  loading={buttonLoading}
                  disabled={order.confirmed}
                  style={{ marginRight: '10px' }}
                >
                  Confirm
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure finalize this order?"
                onConfirm={this.handleFinalizeOrder}
                okText="Yes"
                cancelText="No"
                disabled={!order.confirmed || order.finalized}
              >
                <Button
                  loading={buttonLoading}
                  disabled={!order.confirmed || order.finalized}
                  style={{ marginRight: '10px' }}
                >
                  Finalize
                </Button>
              </Popconfirm>
              <Button
                loading={buttonLoading}
                disabled={order.paid || order.uncollectible || order.void}
                style={{ marginRight: '10px' }}
              >
                Mark Paid
              </Button>
              <Popconfirm
                title="Are you sure mark void this order?"
                onConfirm={this.handleMarkOrderVoid}
                okText="Yes"
                cancelText="No"
                disabled={order.paid || order.uncollectible || order.void}
              >
                <Button
                  loading={buttonLoading}
                  disabled={order.paid || order.uncollectible || order.void}
                  style={{ marginRight: '10px' }}
                >
                  Mark Void
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure mark uncollectible this order?"
                onConfirm={this.handleMarkOrderUncollectible}
                okText="Yes"
                cancelText="No"
                disabled={order.paid || order.void || order.uncollectible}
              >
                <Button
                  loading={buttonLoading}
                  disabled={order.paid || order.void || order.uncollectible}
                  style={{ marginRight: '10px' }}
                >
                  Mark Uncollectible
                </Button>
              </Popconfirm>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}

CateringOrderAdmin.propTypes = { orderId: PropTypes.string.isRequired };

export default CateringOrderAdmin;
