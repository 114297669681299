/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabBar, NavBar } from 'antd-mobile';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  EnvironmentOutlined,
  HomeOutlined,
  LeftOutlined,
  TeamOutlined,
  UserOutlined,
  // SmileTwoTone,
  // GiftOutlined,
  // HeartTwoTone,
} from '@ant-design/icons';
import ReactRouterPropTypes from 'react-router-prop-types';
// import hat from '../../../assets/images/icons/hat.png';

import {
  getCustomerPath,
  checkAlipayUserAgent,
  apolloClient,
} from '../../../util';
import { USER_CURRENT_UPDATE } from '../../../api/user';
import * as actions from '../../../store/actions';
import logoFull from '../../../assets/images/logo.png';
import iconChinese from '../../../assets/images/icons/Chinese.png';
import iconEnglish from '../../../assets/images/icons/English.png';

export class CustomerTabBar extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      currentPathname: null,
      hideTabBar: false,
      location: null,
      hideCatering: false,
    };
  }

  componentDidMount = async () => {
    console.log('should only print once in CustomerTabBar.js');
    const flag = checkAlipayUserAgent();
    this.setState({ hideCatering: flag });
    const {
      location,
      location: { pathname },
    } = this.props;
    await this.setState({ location });
    await this.handlePathChange(pathname);
  };

  componentDidUpdate = async (prevProps) => {
    // console.log(prevProps);
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      // console.log('componentDidUpdate');
      await this.handlePathChange(location.pathname);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.location &&
      prevState.location &&
      nextProps.location.pathname !== prevState.location.pathname
    ) {
      // console.log('getDerivedStateFromProps');
      let currentPathname = null;
      let hideTabBar = false;
      const { hostname } = window.location;
      const pathnameArray = nextProps.location.pathname.split('/');
      const [, second, third] = pathnameArray;
      if (
        (process.env.REACT_APP_BUILD_DEV === 'true' ||
          process.env.NODE_ENV === 'development') &&
        hostname !== 'test.bunbao.com'
      ) {
        console.log(pathnameArray);
        currentPathname = third;
        if (third === undefined) {
          currentPathname = '';
        }
      } else {
        currentPathname = second;
      }
      // check tab bar hide
      if (
        ['map', 'catering', 'customer', 'account-overview', ''].find(
          (element) => element === currentPathname
        ) === undefined
      ) {
        hideTabBar = true;
        console.log('hide tab bar');
      }
      return {
        currentPathname,
        location: nextProps.location,
        hideTabBar,
      };
    }
    return null;
  }

  handlePathChange = async (pathname) => {
    let currentPathname = null;
    let hideTabBar = false;
    const { hostname } = window.location;

    const pathnameArray = pathname.split('/');
    const [, second, third] = pathnameArray;
    if (
      (process.env.REACT_APP_BUILD_DEV === 'true' ||
        process.env.NODE_ENV === 'development') &&
      hostname !== 'test.bunbao.com'
    ) {
      console.log(pathnameArray);
      currentPathname = third;
      if (third === undefined) {
        currentPathname = '';
      }
    } else {
      currentPathname = second;
    }
    // check tab bar hide
    if (
      ['map', 'catering', 'customer', 'account-overview', ''].find(
        (element) => element === currentPathname
      ) === undefined
    ) {
      hideTabBar = true;
      console.log('hide tab bar');
    }
    await this.setState({
      hideTabBar,
      currentPathname,
    });
  };

  handleGoBackButton = () => {
    const { callback, overWriteCallBack, history, location } = this.props;

    if (callback) {
      // console.log("not null callback",callback);
      callback();
      overWriteCallBack(null);
    } else if (location.pathname === '/customer/faq') {
      history.push('/customer');
    } else {
      history.goBack();
    }
  };

  updateUserLanguage = async (language) => {
    const { userId } = this.props;
    try {
      const {
        data: { userCurrentUpdate },
      } = await apolloClient.mutate({
        mutation: USER_CURRENT_UPDATE,
        variables: { input: { record: { language, _id: userId } } },
      });
      console.log(userCurrentUpdate);
    } catch (err) {
      console.log(err);
    }
  };

  handleSetLanguage = async (language) => {
    const { setLanguage, userId } = this.props;
    if (userId) {
      await this.updateUserLanguage(language);
    }
    setLanguage(language);
  };

  render() {
    const {
      children,
      intl,
      language,
      token,
      logout,
      history,
      group,
      // location,
    } = this.props;

    const {
      hideTabBar,
      currentPathname,
      // subPathname,
      hideCatering,
    } = this.state;

    return (
      <React.Fragment>
        <NavBar
          css={{
            zIndex: '10',
            position: 'fixed',
            width: '100%',
            userSelect: 'none',
          }}
          mode="light"
          icon={hideTabBar ? <LeftOutlined /> : null}
          onLeftClick={() => {
            if (hideTabBar) {
              this.handleGoBackButton();
            }
          }}
          rightContent={[
            <div key="language-settings">
              {language === 'en' ? (
                <div
                  style={{ width: '32px' }}
                  onClick={() => {
                    this.handleSetLanguage('zh');
                  }}
                  role="presentation"
                >
                  <img
                    src={iconChinese}
                    style={{ height: '24px' }}
                    alt="Chinese Icon"
                    role="presentation"
                  />
                </div>
              ) : (
                <div
                  style={{ width: '32px' }}
                  onClick={() => {
                    this.handleSetLanguage('en');
                  }}
                  role="presentation"
                >
                  <img
                    src={iconEnglish}
                    style={{ height: '24px' }}
                    alt="English Icon"
                    role="presentation"
                  />
                </div>
              )}
            </div>,
          ]}
        >
          <img
            src={logoFull}
            css={{ height: '90%' }}
            alt="BunBao Logo"
            onClick={() => {
              history.replace(getCustomerPath(''));
            }}
            role="presentation"
          />
        </NavBar>
        <div
          style={{
            position: hideTabBar ? '' : 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
          }}
        >
          {hideTabBar === true &&
          currentPathname !== '' &&
          currentPathname !== 'map' &&
          currentPathname !== 'catering' &&
          currentPathname !== 'account-overview' ? (
            <div css={{ backgroundColor: 'white' }}>{children}</div>
          ) : null}
          {hideCatering ? (
            <TabBar
              css={{ userSelect: 'none' }}
              hidden={hideTabBar}
              unselectedTintColor="#949494"
              tintColor="#DF242F"
              prerenderingSiblingsNumber={99}
              barTintColor="white"
            >
              <TabBar.Item
                title={intl.formatMessage({ id: 'home' })}
                key="home"
                icon={<HomeOutlined style={{ fontSize: '24px' }} />}
                selectedIcon={<HomeOutlined style={{ fontSize: '24px' }} />}
                selected={currentPathname === ''}
                onPress={() => {
                  if (currentPathname !== '') {
                    history.replace(getCustomerPath(''));
                  }
                }}
              >
                {currentPathname === '' ? children : null}
              </TabBar.Item>
              <TabBar.Item
                title={intl.formatMessage({ id: 'cloudStore' })}
                key="map"
                icon={<EnvironmentOutlined style={{ fontSize: '24px' }} />}
                selectedIcon={
                  <EnvironmentOutlined style={{ fontSize: '24px' }} />
                }
                selected={currentPathname === 'map'}
                onPress={() => {
                  if (currentPathname !== 'map') {
                    history.replace(getCustomerPath('map'));
                  }
                }}
              >
                {currentPathname === 'map' ? children : null}
              </TabBar.Item>
              <TabBar.Item
                title={
                  token
                    ? intl.formatMessage({ id: 'account' })
                    : intl.formatMessage({ id: 'login' })
                }
                key="account-overview"
                icon={<UserOutlined style={{ fontSize: '24px' }} />}
                selectedIcon={<UserOutlined style={{ fontSize: '24px' }} />}
                selected={currentPathname === 'account-overview'}
                onPress={async () => {
                  if (currentPathname !== 'account-overview') {
                    if (token) {
                      history.replace(getCustomerPath('account-overview'));
                    } else {
                      await logout();
                      history.replace(getCustomerPath('login'));
                    }
                  }
                }}
              >
                {currentPathname === 'account-overview' && token
                  ? children
                  : null}
              </TabBar.Item>
            </TabBar>
          ) : (
            <TabBar
              css={{ userSelect: 'none' }}
              hidden={hideTabBar}
              unselectedTintColor="#949494"
              tintColor="#DF242F"
              prerenderingSiblingsNumber={99}
              barTintColor="white"
            >
              <TabBar.Item
                title={intl.formatMessage({ id: 'home' })}
                key="home"
                icon={<HomeOutlined style={{ fontSize: '24px' }} />}
                selectedIcon={<HomeOutlined style={{ fontSize: '24px' }} />}
                selected={currentPathname === ''}
                onPress={() => {
                  if (currentPathname !== '') {
                    history.replace(getCustomerPath(''));
                  }
                }}
              >
                {currentPathname === '' ? children : null}
              </TabBar.Item>
              <TabBar.Item
                title={intl.formatMessage({ id: 'pickup' })}
                key="map"
                icon={<EnvironmentOutlined style={{ fontSize: '24px' }} />}
                selectedIcon={
                  <EnvironmentOutlined style={{ fontSize: '24px' }} />
                }
                selected={currentPathname === 'map'}
                onPress={() => {
                  if (currentPathname !== 'map') {
                    history.replace(
                      getCustomerPath('map?mode=scheduledPickup')
                    );
                  }
                }}
              >
                {currentPathname === 'map' ? children : null}
              </TabBar.Item>

              {/* Feature requested: Hide catering tab */}
              {/* <TabBar.Item
                title={intl.formatMessage({ id: 'catering' })}
                key="catering"
                // icon={<TeamOutlined style={{ fontSize: '24px' }} />}
                // selectedIcon={<TeamOutlined style={{ fontSize: '24px' }} />}
                icon={
                  <TeamOutlined
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '24px' }}
                  />
                }
                selectedIcon={
                  <TeamOutlined
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '24px' }}
                  />
                }
                selected={currentPathname === 'catering'}
                onPress={() => {
                  // history.push(getCustomerPath('donation'));
                  if (currentPathname !== 'catering') {
                    history.replace(getCustomerPath('catering'));
                  }
                }}
              >
                {currentPathname === 'catering' ? children : null}
              </TabBar.Item> */}
              <TabBar.Item
                title={intl.formatMessage({ id: 'faq' })}
                key="faq"
                // icon={<TeamOutlined style={{ fontSize: '24px' }} />}
                // selectedIcon={<TeamOutlined style={{ fontSize: '24px' }} />}
                icon={
                  <TeamOutlined
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '24px' }}
                  />
                }
                selectedIcon={
                  <TeamOutlined
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '24px' }}
                  />
                }
                selected={currentPathname === 'faq'}
                onPress={() => {
                  // history.push(getCustomerPath('donation'));
                  if (currentPathname !== 'faq') {
                    history.replace(getCustomerPath('faq'));
                  }
                }}
              >
                {currentPathname === 'faq' ? children : null}
              </TabBar.Item>
              {/* <TabBar.Item
                title={intl.formatMessage({ id: 'giftCard' })}
                key="catering"
                // icon={<TeamOutlined style={{ fontSize: '24px' }} />}
                // selectedIcon={<TeamOutlined style={{ fontSize: '24px' }} />}
                icon={
                  // <SmileTwoTone
                  //   twoToneColor="#eb2f96"
                  //   style={{ fontSize: '24px' }}
                  // />
                  <img src={hat} alt="hat icon" width="30" height="30" />
                }
                selectedIcon={
                  // <GiftOutlined
                  //   style={{ fontSize: '24px' }}
                  //   twoToneColor="#eb2f96"
                  // />
                  <img src={hat} alt="hat icon" width="30" height="30" />
                }
                selected={currentPathname === 'eShop'}
                onPress={() => {
                  history.push(getCustomerPath('eshop'));
                }}
              >
                {/* {currentPathname === 'catering' ? children : null} */}
              {/* </TabBar.Item> */}
              <TabBar.Item
                title={
                  token && !(group && group.name === 'guest')
                    ? intl.formatMessage({ id: 'account' })
                    : intl.formatMessage({ id: 'login' })
                }
                key="account-overview"
                icon={<UserOutlined style={{ fontSize: '24px' }} />}
                selectedIcon={<UserOutlined style={{ fontSize: '24px' }} />}
                selected={currentPathname === 'account-overview'}
                onPress={async () => {
                  if (currentPathname !== 'account-overview') {
                    if (token && !(group && group.name === 'guest')) {
                      history.replace(getCustomerPath('account-overview'));
                    } else {
                      await logout();
                      history.replace(getCustomerPath('login'));
                    }
                  }
                }}
              >
                {currentPathname === 'account-overview' && token
                  ? children
                  : null}
              </TabBar.Item>
            </TabBar>
          )}
        </div>
      </React.Fragment>
    );
  }
}

CustomerTabBar.defaultProps = {
  token: null,
  callback: null,
  group: null,
  userId: null,
};

CustomerTabBar.propTypes = {
  children: PropTypes.element.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string,
  overWriteCallBack: PropTypes.func.isRequired,
  callback: PropTypes.func,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
  warehouseId: state.warehouse.warehouseId,
  token: state.auth.token,
  group: state.user.group,
  userId: state.user.userId,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(actions.setLanguage(language)),
  logout: () => {
    dispatch(actions.logout());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerTabBar))
);
