/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import QRCode from '../../../assets/images/contact/QRCode.png';

const { Title, Paragraph } = Typography;

// import PropTypes from 'prop-types';

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <div css={isMobile ? { padding: '10px' } : { padding: '30px 50px' }}>
        <Typography>
          <Title>
            <FormattedMessage id="contactUs" />
          </Title>
          <div>
            <Paragraph>
              <FormattedMessage id="contactNotes" />
            </Paragraph>
            <div>
              <p className="contact-detail">
                <i className="fa fa-phone" /> Tel: (510)573-0672
              </p>
              <p className="contact-detail">
                <i className="fa fa-envelope" /> Email: support@bunbao.com
              </p>
              <p className="contact-detail">
                <i className="fa fa-map-marker" />{' '}
                <FormattedMessage id="addressOne" />: 34755 Ardenwood Blvd,
                Fremont, CA 94555
              </p>
            </div>
            <div>
              <img
                css={{ height: '200px', width: '200px' }}
                src={QRCode}
                alt="BunBao QR"
              />
            </div>

            <iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6327.774333849325!2d-121.96661046802662!3d37.5341569932813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fc0ce85337ca3%3A0xeb51c4930cca8fa5!2s40839+Fremont+Blvd%2C+Fremont%2C+CA+94538!5e0!3m2!1sen!2sus!4v1534114937416"
              frameBorder="0"
              allowFullScreen
              style={{
                width: 800,
                height: 600,
              }}
              title="Google Map"
            />
          </div>
        </Typography>
      </div>
    );
  }
}

export default Contact;
