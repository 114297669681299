import React from 'react';
import { useLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as actions from '../../../store/actions';
import { getCustomerPath } from '../../../util';

const needRedirectPath = ['/delivery-menu', '/store', '/donation'];

function Redirector(props) {
  const { saveLinkForLogin } = props;
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { pathname } = window.location;
  const currentPath = pathname.replace('/customer', '');
  if (
    currentPath === '/login' &&
    lastLocation &&
    lastLocation.pathname.includes('/account')
  ) {
    saveLinkForLogin(lastLocation.pathname);
  }
  let lastPath;
  if (lastLocation && lastLocation.pathname) {
    lastPath = lastLocation.pathname.replace('/customer', '');
  }
  // console.log('lastPath---', lastPath);
  // console.log('currentPath---', currentPath);
  if (lastPath && currentPath && lastPath !== currentPath) {
    let checkOne = false;
    let checkTwo = false;
    needRedirectPath.forEach((path) => {
      if (lastPath.includes(path)) {
        checkOne = true;
      }
      if (currentPath.includes(path)) {
        checkTwo = true;
      }
    });
    if (checkOne && checkTwo) {
      const redirectTo = currentPath.replace('/', '');
      console.log('jump to ', redirectTo);
      if (redirectTo !== 'jump/delivery-menu') {
        history.push(getCustomerPath('jump/path'), { redirectTo });
      }
    }
  }
  return <div />;
}

// export default Redirector;

Redirector.propTypes = {
  saveLinkForLogin: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
});

export default connect(null, mapDispatchToProps)(Redirector);
