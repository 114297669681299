import gql from 'graphql-tag';

export const RULE_FRAGMENT = gql`
  fragment RULE_FRAGMENT on Rule {
    _id
    name
    byDistance {
      mile
      minimumAmount
      _id
    }
    maxMile
    description {
      _id
      zh
      en
    }
    minimumPriceAmount
    minimumBoxAmount
    requiredVariantIds
    excludeVariantIds
    requiredVariants {
      name {
        _id
        zh
        en
      }
      _id
    }
    warehouseId
    warehouse {
      _id
    }
  }
`;

export const RULE_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyRuleInput
    $sort: SortFindManyRuleInput
  ) {
    rulePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        ...RULE_FRAGMENT
      }
    }
  }
  ${RULE_FRAGMENT}
`;

export const FETCH_MINIMUM_SHIPPING_VALUE = gql`
  query ($addressString: String!) {
    ruleGetMinimumShippingValue(address: $addressString) {
      valid
      minimumValue
    }
  }
`;

export const FETCH_SHIPPING_RULE = gql`
  query ($filter: FilterFindManyRuleInput) {
    ruleFindMany(filter: $filter) {
      ...RULE_FRAGMENT
    }
  }
  ${RULE_FRAGMENT}
`;

export const CREATE_RULE = gql`
  mutation ($input: CreateOneRuleInput!) {
    ruleCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const UPDATE_RULE = gql`
  mutation ($input: UpdateOneRuleInput!, $_id: MongoID) {
    ruleUpdateOne(input: { record: $input, filter: { _id: $_id } }) {
      record {
        _id
      }
    }
  }
`;

export const APPLY_RULE = gql`
  query (
    $orderId: MongoID!
    $isScheduledDelivery: Boolean
    $shoppingMode: String
    $warehouseId: MongoID
  ) {
    ruleValidateOrder(
      orderId: $orderId
      isScheduledDelivery: $isScheduledDelivery
      shoppingMode: $shoppingMode
      warehouseId: $warehouseId
    )
  }
`;
