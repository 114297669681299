import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Divider, message } from 'antd';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../store/actions';
import CustomerSettingForm from '../../../../components/Forms/CustomerSettingForm/CustomerSettingFrom';
import { GET_RESET_LINK } from '../../../../apiCanary/user';
import { saleorClient } from '../../../../util';
import UpdatePasswordForm from '../../../../components/Forms/UpdatePasswordForm/NewUpdatePsswordForm';

// function Example() {
//   // Declare a new state variable, which we'll call "count"
//   const [count, setCount] = useState('aaa');

//   const update = () => {
//     if (count === 'aaa') {
//       setCount('bbb');
//     } else if (count === 'bbb') {
//       setCount('ccc');
//     }
//   };

//   return (
//     <div>
//       <p>You clicked {count} times</p>
//       <button
//         onClick={() => {
//           update();
//         }}
//       >
//         Click me
//       </button>
//     </div>
//   );
// }
export class CustomerSetting extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleClickSendEmailButton = async () => {
    const { intl, email } = this.props;
    try {
      const input = { email };
      const { data } = await saleorClient.mutate({
        mutation: GET_RESET_LINK,
        variables: { input },
      });
      if (data.customerPasswordReset && data.customerPasswordReset.errors) {
        const { errors } = data.customerPasswordReset;
        message.error(intl.formatMessage({ id: errors[0].message }), 15);
      } else {
        message.success(intl.formatMessage({ id: 'successAndCheck' }), 10);
      }
    } catch (error) {
      console.error(error);
      message.error('Error Happened', 15);
    }
  };

  render() {
    const { firstName, lastName, getCurrentUser } = this.props;
    return (
      <div style={{ padding: '30px 20px 30px 20px' }}>
        {/* <Example /> */}
        <Row justify="center" type="flex">
          <Divider style={{ fontSize: '20px', fontWeight: 'bold' }}>
            <FormattedMessage id="updateYourInfo" />
          </Divider>
          <CustomerSettingForm
            firstName={firstName}
            lastName={lastName}
            getCurrentUser={getCurrentUser}
            horizontal
          />
        </Row>
        <Row gutter={10} type="flex" justify="center" align="middle">
          <Col span={24}>
            <div css={{ padding: '20px' }}>
              <div
                css={{
                  margin: '0px 0px 20px 0px',
                  fontSize: '2em',
                  fontWeight: '600',
                }}
              >
                <Divider style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  <FormattedMessage id="resetMyPassword" />
                </Divider>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={this.handleClickSendEmailButton}
                    css={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                  >
                    <FormattedMessage id="sendEmail" />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Divider
            style={{ fontSize: '20px', fontWeight: 'bold' }}
            orientation="center"
          >
            <FormattedMessage id="resetMyPassword" />
          </Divider>

          <UpdatePasswordForm />
        </Row>
      </div>
    );
  }
}

CustomerSetting.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  email: state.user.email,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({ name: 'customer_FindBackAccount_form' })(
    injectIntl(CustomerSetting)
  )
);

// export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerSetting) );

// Form.create({ name: 'customer_FindBackAccount_form' })(
//   injectIntl(ResetPassword)
// )
