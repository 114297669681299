/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'antd-form-builder';
import { FormattedMessage } from 'react-intl';
import { Form, Button } from 'antd';
import { apolloClient } from '../../../util';
import {
  CREATE_ADDRESS,
  USER_SET_ADDRESS,
  UPDATE_ADDRESS,
  ADMIN_SET_USER_ADDRESS,
} from '../../../api/address';

const CateringAddressForm = (props) => {
  const {
    address,
    selectedAddress,
    handleFetchAddresses,
    userId,
    admin,
    handleSubmit,
    handleCancel,
  } = props;

  const [form] = Form.useForm();
  const [addressData, setAddressData] = useState(null);
  const [editAddress, setEditAddress] = useState(false);

  useEffect(() => {
    if (selectedAddress !== 'newAddress') {
      setAddressData(address);
      setEditAddress(false);
    } else {
      setEditAddress(true);
    }
  }, [address, selectedAddress, setAddressData, addressData, setEditAddress]); // This will only run when one of those variables change

  const handleSubmitAddressForm = async (values) => {
    console.log(values);
    const {
      firstName,
      lastName,
      phoneNumber,
      companyName,
      addressOne,
      addressTwo,
      city,
      zipCode,
      state,
    } = values;
    const newAddress = {
      firstName,
      lastName,
      phoneNumber,
      companyName,
      addressOne,
      addressTwo,
      city,
      zipCode,
      state,
      type: 'company',
    };
    if (selectedAddress === 'newAddress') {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_ADDRESS,
        variables: { input: newAddress },
      });
      const { _id: addressId } = data.addressCreateOne.record;
      // update user addresses
      if (addressId) {
        if (admin) {
          const updateResponse = await apolloClient.mutate({
            mutation: ADMIN_SET_USER_ADDRESS,
            variables: { userId, addressId },
          });
          handleFetchAddresses(addressId, userId);
          console.log(updateResponse);
        } else {
          // update user addresses
          const updateResponse = await apolloClient.mutate({
            mutation: USER_SET_ADDRESS,
            variables: { id: addressId },
          });
          handleFetchAddresses(addressId);
          console.log(updateResponse);
        }
      } else {
        console.error('error');
      }
      handleSubmit();
    } else {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_ADDRESS,
        variables: {
          input: newAddress,
          _id: selectedAddress,
        },
      });
      console.log(data);
      const { _id } = data.addressUpdateOne.record;
      if (admin) {
        handleFetchAddresses(_id, userId);
      } else {
        handleFetchAddresses(_id);
      }
      setEditAddress(false);
      handleSubmit();
    }
  };

  const meta = {
    fields: [
      {
        key: 'companyName',
        label: 'Company Name',
        required: true,
        initialValue: address ? address.companyName : null,
      },
      {
        key: 'firstName',
        label: 'First Name',
        required: true,
        initialValue: address ? address.firstName : null,
      },
      {
        key: 'lastName',
        label: 'Last Name',
        required: true,
        initialValue: address ? address.lastName : null,
      },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
        required: true,
        initialValue: address ? address.phoneNumber.replace('+1', '') : null,
      },
      {
        key: 'addressOne',
        label: 'Address 1',
        required: true,
        initialValue: address ? address.addressOne : null,
      },
      {
        key: 'addressTwo',
        label: 'Address 2',
        initialValue: address ? address.addressTwo : null,
      },
      {
        key: 'city',
        label: 'City',
        required: true,
        initialValue: address ? address.city : null,
      },
      {
        key: 'zipCode',
        label: 'Zip',
        required: true,
        initialValue: address ? address.zipCode : null,
        rules: [{ len: 5, message: 'Please enter a valid zip code' }],
      },
      { key: 'State', label: 'State', required: true, initialValue: 'CA' },
    ],
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmitAddressForm}
        layout="vertical"
        css={{
          input: {
            borderRadius: '25px',
            borderColor: '#40A9FF',
          },
          '.ant-col-8': {
            maxWidth: '100%',
          },
          '.ant-col-16': {
            maxWidth: '100%',
          },
        }}
      >
        <FormBuilder meta={meta} form={form} />
        <div
          css={{
            button: {
              borderRadius: '25px',
              width: '100%',
              maxWidth: '200px',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="addressSubmit" />
          </Button>
          {editAddress === 'newAddress' ? (
            <Button
              css={{ marginLeft: '15px' }}
              onClick={() => {
                setEditAddress(false);
              }}
            >
              Reset
            </Button>
          ) : (
            <Button
              css={{ marginLeft: '15px' }}
              onClick={() => {
                setEditAddress(false);
                handleCancel();
              }}
            >
              <FormattedMessage id="addressCancel" />
            </Button>
          )}
        </div>
      </Form>
      {/* {!editAddress && (
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Button
            onClick={() => {
              setEditAddress(true);
            }}
          >
            Edit
          </Button>
        </Form.Item>
      )} */}
    </div>
  );
};

CateringAddressForm.defaultProps = {
  address: null,
  userId: null,
};

CateringAddressForm.propTypes = {
  address: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  selectedAddress: PropTypes.string.isRequired,
  handleFetchAddresses: PropTypes.func.isRequired,
  userId: PropTypes.string,
  admin: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default CateringAddressForm;
