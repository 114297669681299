import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../util';

const initialState = {
  items: [], // for catering use
  shoppingCart: {
    // for bunbao use
    productList: [],
    boxList: [],
  },
  currentWarehouseCart: {
    productList: [],
    boxList: [],
  },
  allWarehouseCart: {},
  tipAmount: 0,
  tipPercentage: 0,
  tipSelected: 'none',
  cartLastUpdate: null,
};

export const updateTip = (state, action) => {
  const updatedState = {
    tipAmount: action.tipAmount,
    tipPercentage: action.tipPercentage,
    tipSelected: action.tipSelected,
  };
  return updateObject(state, updatedState);
};

export const updateItems = (state, action) => {
  const updatedState = {
    items: action.items,
    cartLastUpdate: action.cartLastUpdate,
  };
  return updateObject(state, updatedState);
};

export const updateShoppingCartItems = (state, action) => {
  // console.log('update shoppingCart');
  const updatedState = {
    shoppingCart: {
      productList: action.shoppingCart.productList,
      boxList: action.shoppingCart.boxList,
      deliveryTime: action.shoppingCart.deliveryTime,
      pickupDate: action.shoppingCart.pickupDate,
      discountCode: action.shoppingCart.discountCode,
    },
  };
  return updateObject(state, updatedState);
};

export const fetchCurrentWarehouseCartItems = (state, action) => {
  const updatedState = {
    currentWarehouseCart: {
      productList: action.warehouseCart.productList,
      boxList: action.warehouseCart.boxList,
    },
  };
  return updateObject(state, updatedState);
};

export const updateAllWarehouseCart = (state, action) => {
  const { updateCart, updateWarehouseId } = action;
  if (!state.allWarehouseCart) {
    state.allWarehouseCart = {};
  }
  const removeZeroCount = [];
  updateCart.productList.forEach((product) => {
    if (product.count > 0) {
      removeZeroCount.push(product);
    }
  });
  updateCart.productList = removeZeroCount;
  state.allWarehouseCart[updateWarehouseId] = updateCart;
  return state;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CART_UPDATE_ITEMS:
      return updateItems(state, action);
    case actionTypes.CART_UPDATE_SHOPPING_CART:
      return updateShoppingCartItems(state, action);
    case actionTypes.CART_FETCH_CURRENT_WAREHOUSE_CART:
      return fetchCurrentWarehouseCartItems(state, action);
    case actionTypes.CART_UPDATE_ALL_WAREHOUSE_CART:
      return updateAllWarehouseCart(state, action);
    case actionTypes.CART_UPDATE_TIP:
      return updateTip(state, action);
    default:
      return state;
  }
};

export default reducer;
