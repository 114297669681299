/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

function CouponCode(props) {
  const { coupon, intl, language } = props;

  if (
    moment(coupon.endDate).isBefore(moment()) ||
    (coupon.usageLimit !== null &&
      coupon.usageLimit >= 0 &&
      coupon.usageLimit === coupon.used)
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        css={{
          display: 'flex',
          padding: '10px',
          flexDirection: 'row',
          height: '100%',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <div
          css={{
            width: '70%',
            padding: '10px 5px 10px 5px',
            textAlign: 'center',
            border: ' 2px dashed #a6b1e1',
            backgroundColor: '#f2f2f2',
          }}
        >
          <div>{`${coupon.name[language]}`}</div>
          <div>
            {intl.formatMessage({ id: 'appliedCode2' })}:{' '}
            <span style={{ fontWeight: 'bold' }}>{coupon.code}</span>
          </div>
          {coupon.startDate ? (
            <div>
              {`${intl.formatMessage({
                id: 'startDate',
              })}: ${moment(coupon.startDate).format('YYYY-MM-DD')} `}
            </div>
          ) : null}
          {coupon.endDate ? (
            <div style={{}}>
              {`${intl.formatMessage({ id: 'expiredDate' })}: ${moment(
                coupon.endDate
              ).format('YYYY-MM-DD')} `}
              <span style={{ color: 'red' }}>
                {`${
                  moment(coupon.endDate).isBefore(moment())
                    ? intl.formatMessage({ id: 'expired' })
                    : ''
                }
                ${
                  coupon.usageLimit !== null &&
                  coupon.usageLimit >= 0 &&
                  coupon.usageLimit === coupon.used
                    ? intl.formatMessage({ id: 'reachedMaximumUsage' })
                    : ''
                }`}
              </span>
            </div>
          ) : null}
          {coupon.minimumAmountSpent > 0 && (
            <div>
              <FormattedMessage id="minimumAmountSpent" />: $
              {coupon.minimumAmountSpent}
            </div>
          )}
        </div>
        <div
          css={{
            width: '30%',
            padding: '10px 5px 10px 5px',
            textAlign: 'center',
            border: ' 2px dashed #a6b1e1',
            fontWeight: 'bold',
            backgroundColor: (() => {
              if (coupon.bunbaoCategory === 'doubleEleven') {
                if (coupon.name.en.toLowerCase().search('rebate') !== -1) {
                  return '#a6b1e1';
                }
                return '#eab676';
              }
              return '#d63447';
            })(),
            color: 'white',
          }}
        >
          {coupon.amount === 0 ? (
            <div>
              {/* {language === 'zh'
                ? '经典南方猪肉包/4个'
                : 'Southern Style Pork Bao / 4 pc'} */}
            </div>
          ) : (
            <div>
              <div>{` ${coupon.valueType === 'percentage' ? '' : '$'}${
                coupon.amount
              }${coupon.valueType === 'percentage' ? ' %' : ''} `}</div>
              <div>OFF</div>
            </div>
          )}
          {moment(coupon.startDate).isAfter(moment()) ? (
            <div
              css={{
                padding: '2px',
                textAlign: 'center',
                border: ' 2px solid #a6b1e1',
                fontWeight: 'bold',
                borderRadius: '12px',
                backgroundColor: '#f2f2f2',
                color: '#d63447',
                cursor: 'pointer',
              }}
              role="presentation"
            >
              <FormattedMessage id="notActive" />
            </div>
          ) : (
            <div
              css={{
                padding: '2px',
                textAlign: 'center',
                border: ' 2px solid #a6b1e1',
                fontWeight: 'bold',
                borderRadius: '12px',
                backgroundColor: '#f2f2f2',
                color: '#d63447',
              }}
            >
              <FormattedMessage id="use" />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

CouponCode.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  language: PropTypes.string.isRequired,
  coupon: PropTypes.shape({
    name: PropTypes.shape({
      en: PropTypes.string,
      zh: PropTypes.string,
    }),
    code: PropTypes.string,
    valueType: PropTypes.string,
    amount: PropTypes.number,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    minimumAmountSpent: PropTypes.number,
    usageLimit: PropTypes.number,
    used: PropTypes.number,
    bunbaoCategory: PropTypes.string,
  }),
};

CouponCode.defaultProps = {
  coupon: {},
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(injectIntl(CouponCode));
