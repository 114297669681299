import gql from 'graphql-tag';

const FETCH_USER_CARD = gql`
  {
    userCurrentUserCard {
      id
      brand
      last4
    }
  }
`;

const SUBMIT_ORDER = gql`
  mutation (
    $card: String
    $newToken: String
    $orderID: MongoID!
    $saveCardOrNot: Boolean
  ) {
    orderCreateCustomerOrderPayment(
      card: $card
      newToken: $newToken
      orderID: $orderID
      saveCardOrNot: $saveCardOrNot
    )
  }
`;

const ORDER_SUBMIT_ALIPAY_PAYMENT = gql`
  mutation ($orderId: MongoID!, $sourceId: String!, $clientSecret: String!) {
    orderCreateAlipayPayment(
      orderId: $orderId
      sourceId: $sourceId
      clientSecret: $clientSecret
    )
  }
`;

export { FETCH_USER_CARD, SUBMIT_ORDER, ORDER_SUBMIT_ALIPAY_PAYMENT };
