export default {
  en: {
    scheduledDelivery: 'Scheduled Delivery',
    scheduledPickup: 'Scheduled Pickup',
    deliveredASAP: 'Delivered ASAP',
    pickupASAP: 'Pickup',
    allStores: 'All Stores',
    deliveryIcon: 'Delivery',
    deliveryIconDetails: 'Free next-day delivery or instant delivery',
    pickupIcon: 'Pickup',
    pickupIconDetails: 'Skip the line to pick up food at nearest store',
    cateringIcon: 'Catering',
    cateringIconDetails: 'Reliable service and on time delivery',
    dineInIcon: 'Dine-In',
    dineInIconDetails:
      'Pre-order and get exclusive discount at our partner stores',
    groupBuyIcon: 'Rewards',
    groupBuyIconDetails: 'Invite your friends, buy together and pay less',
    aroundMeIcon: 'Markets',
    aroundMeIconDetails: 'Find us at farmers markets, popups and supermarkets',
    terms: 'Terms',
    privacyPolicy: 'Private Policy',
    contactUs: 'Contact Us',
    followUs: 'Follow Us',
    faq: 'FAQ',
    stories: 'Stories',
    referFriend: 'Refer A Friend',
    fullMenu: 'Full Menu',
    homepage: {
      BunBao: 'Deliver',
      farmersMarket: 'Pickup',
      cloudStore: 'Cloud Store',
      scheduledDeliveryExplain: 'Delivered to your door',
      scheduledPickupExplain: 'Pickup at store/FM without delivery fee',
      specialScheduledDeliveryExplain: 'Delivered to your door',
      cateringExplain: 'Professional and delicious',
      gameExplain: 'Play and win',
      pickupASAPExplain: 'Instant delivery',
    },
  },
  zh: {
    scheduledDelivery: '预定配送',
    scheduledPickup: '预定自取',
    deliveredASAP: '现在配送',
    pickupASAP: '自取',
    allStores: '美食团购',
    deliveryIcon: '配送',
    deliveryIconDetails: '30分钟即刻配送，或次日直达免运费',
    pickupIcon: '自取',
    pickupIconDetails: '在线点单，就近取餐，无需到店等待',
    cateringIcon: '企业订餐',
    cateringIconDetails: '提供10至800人的团餐，已服务超过500家企业',
    dineInIcon: '堂食',
    dineInIconDetails: '高质量云店服务，让棒!包随处可得',
    groupBuyIcon: '游戏积分',
    groupBuyIconDetails: '邀请有奖，邀请越多，优惠越多',
    aroundMeIcon: '周边',
    aroundMeIconDetails: '农贸市场、超市、夜市，来找我们吧',
    terms: '免责声明',
    privacyPolicy: '隐私条款',
    contactUs: '联系我们',
    followUs: '关注我们',
    faq: 'FAQ',
    stories: '故事',
    referFriend: '邀请',
    fullMenu: '更多菜单',
    homepage: {
      BunBao: '配送',
      farmersMarket: '自取',
      cloudStore: '云店',
      scheduledDeliveryExplain: '次日直达',
      scheduledPickupExplain: '无需运费，次日自取',
      specialScheduledDeliveryExplain: '送达您的家门口',
      cateringExplain: '提供10至800人的团餐，已服务超过500家企业',
      gameExplain: '玩与赢',
      pickupASAPExplain: '30分钟即刻配送',
    },
  },
};
