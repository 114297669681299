/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Row,
  Col, // message,
  Input,
} from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as actions from '../../../store/actions';
import AddressSearchInput from '../../Inputs/AddressSearchInput/AddressSearchInput';

export class AddressEditCreateForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount = async () => {
    const { editAddress, form } = this.props;
    if (editAddress) {
      // console.log(editAddress.phoneNumber);
      editAddress.phoneNumber = editAddress.phoneNumber.replace(/\+1/g, '');
      form.setFieldsValue({ newAddress: editAddress });
    }
  };

  handleAddressTyping = (addressOne) => {
    const { form } = this.props;
    form.setFieldsValue({
      'newAddress.addressOne': addressOne,
    });
  };

  handleAddressSelect = (address) => {
    const { form } = this.props;
    geocodeByAddress(address)
      .then(async (results) => {
        const place = results[0];
        console.log(place);
        let addressString = '';
        let cityString = '';
        let stateString = '';
        let zipCodeString = '';
        for (let i = 0; i < place.address_components.length; i += 1) {
          const addressType = place.address_components[i].types[0];
          if (addressType === 'street_number') {
            addressString += `${place.address_components[i].short_name} `;
          }
          if (addressType === 'route') {
            addressString += place.address_components[i].long_name;
          }
          if (addressType === 'locality') {
            cityString = place.address_components[i].long_name;
          }
          if (addressType === 'administrative_area_level_1') {
            stateString = place.address_components[i].short_name;
          }
          if (addressType === 'postal_code') {
            zipCodeString = place.address_components[i].short_name;
          }
        }
        console.log('addressString', addressString);
        form.setFieldsValue({
          'newAddress.addressOne': addressString,
          'newAddress.city': cityString,
          'newAddress.zipCode': zipCodeString,
          'newAddress.state': stateString,
        });
        return getLatLng(results[0]);
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  handleResetForm = () => {
    const { form } = this.props;
    form.resetFields(['newAddress']);
  };

  render() {
    const {
      currentAddressType,
      intl,
      handleSubmitForm,
      handleCancelForm,
      form,
      showButton,
      // editAddress,
    } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const addressOneTranslation = intl.formatMessage({ id: 'addressOne' });
    const halfLineCss = {
      width: '50%',
      paddingRight: '5px',
      '& .ant-legacy-form-item-control-wrapper': {
        width: '100%',
      },
    };
    const wholeLineCss = {
      width: '100%',
      '& .ant-legacy-form-item-control-wrapper': {
        width: '100%',
      },
    };
    const renderButtonSet = () => {
      if (showButton === true) {
        return (
          <Row
            type="flex"
            justify="space-between"
            gutter={10}
            style={{ marginTop: '10px' }}
          >
            <Col span={8}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  handleCancelForm();
                }}
              >
                <FormattedMessage id="back" />
              </Button>
            </Col>
            <Col span={8}>
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  this.handleResetForm();
                }}
              >
                <FormattedMessage id="clear" />
              </Button>
            </Col>
            <Col span={8}>
              <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={async () => {
                  await handleSubmitForm();
                }}
              >
                <FormattedMessage id="create" />
              </Button>
            </Col>
          </Row>
        );
      }
      return null;
    };

    const renderDeliveryInfoPart = (
      <React.Fragment>
        <Form.Item css={wholeLineCss}>
          {getFieldDecorator('newAddress.addressOne', {
            rules: [
              {
                message: `${intl.formatMessage({
                  id: 'addressOne',
                })}${intl.formatMessage({ id: 'isEmpty' })}`,
                required: currentAddressType === 'delivery',
              },
            ],
          })(
            <AddressSearchInput
              selected
              address={getFieldValue('newAddress.addressOne')}
              onAddressSelect={this.handleAddressSelect}
              handleAddressTyping={this.handleAddressTyping}
              placeholder={addressOneTranslation}
            />
          )}
        </Form.Item>
        <Form.Item css={wholeLineCss}>
          {getFieldDecorator('newAddress.addressTwo')(
            <Input placeholder={intl.formatMessage({ id: 'addressTwo' })} />
          )}
        </Form.Item>
        <Form.Item css={halfLineCss}>
          {getFieldDecorator('newAddress.city', {
            rules: [
              {
                message: `${intl.formatMessage({
                  id: 'city',
                })}${intl.formatMessage({ id: 'isEmpty' })}`,
                required: currentAddressType === 'delivery',
              },
            ],
          })(<Input placeholder={intl.formatMessage({ id: 'city' })} />)}
        </Form.Item>
        <Form.Item css={halfLineCss}>
          {getFieldDecorator('newAddress.zipCode', {
            rules: [
              {
                message: `${intl.formatMessage({
                  id: 'zipCode',
                })}${intl.formatMessage({ id: 'isEmpty' })}`,
                required: currentAddressType === 'delivery',
              },
            ],
          })(<Input placeholder={intl.formatMessage({ id: 'zipCode' })} />)}
        </Form.Item>
        <Form.Item css={wholeLineCss}>
          {getFieldDecorator('newAddress.gateCode')(
            <Input placeholder={intl.formatMessage({ id: 'gateCode' })} />
          )}
        </Form.Item>
      </React.Fragment>
    );

    const renderNewAddress = (
      <div
        style={{
          border: '1px solid #aeb5b0',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <Form.Item css={halfLineCss}>
          {getFieldDecorator('newAddress.firstName', {
            rules: [
              {
                message: `${intl.formatMessage({
                  id: 'firstName',
                })}${intl.formatMessage({ id: 'isEmpty' })}`,
                required: true,
              },
              {
                validator: async (rule, value) => {
                  if (!value.search(/[\u4e00-\u9fa5\d]/g)) {
                    throw new Error(
                      'Sorry, please write first name in English'
                    );
                  }
                },
              },
            ],
          })(<Input placeholder={intl.formatMessage({ id: 'firstName' })} />)}
        </Form.Item>
        <Form.Item css={halfLineCss}>
          {getFieldDecorator('newAddress.lastName', {
            rules: [
              {
                message: `${intl.formatMessage({
                  id: 'lastName',
                })}${intl.formatMessage({ id: 'isEmpty' })}`,
                required: true,
              },
              {
                validator: async (rule, value) => {
                  if (!value.search(/[\u4e00-\u9fa5\d]/g)) {
                    throw new Error('Sorry, please write last name in English');
                  }
                },
              },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'lastName' })}
              type="text"
            />
          )}
        </Form.Item>
        <Form.Item css={wholeLineCss}>
          {getFieldDecorator('newAddress.phoneNumber', {
            rules: [
              {
                required: true,
                pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                message: intl.formatMessage({ id: 'notValidPhone' }),
              },
            ],
          })(<Input placeholder={intl.formatMessage({ id: 'phone' })} />)}
        </Form.Item>
        {currentAddressType === 'delivery' ? renderDeliveryInfoPart : null}
        {/** submit, add buttons */}
        {renderButtonSet()}
      </div>
    );
    return renderNewAddress;
  }
}

AddressEditCreateForm.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  currentAddressType: PropTypes.string,
  handleSubmitForm: PropTypes.func,
  handleCancelForm: PropTypes.func,
  newAddress: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    gateCode: PropTypes.string,
  }),
  editAddress: PropTypes.shape({
    phoneNumber: PropTypes.string,
  }),
  showButton: PropTypes.bool,
};

AddressEditCreateForm.defaultProps = {
  handleCancelForm: () => {},
  handleSubmitForm: () => {},
  newAddress: null,
  editAddress: null,
  showButton: true,
  currentAddressType: 'delivery',
};
const mapStateToProps = (state) => ({
  languages: state.user.languages,
  currentAddressType: state.user.currentAddressType,
});
const mapDispatchToProps = (dispatch) => ({
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddressEditCreateForm));
