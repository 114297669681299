import { message } from 'antd';
import * as actionTypes from './actionTypes';
import { updateOneWarehouseAttribute } from './warehouse';
import { apolloClient, saleorClient } from '../../util';
import { USER_CURRENT } from '../../api/user';
import { WAREHOUSE_BY_OWNER } from '../../api/warehouse';
import { GET_USER_INFO_BY_ID } from '../../apiCanary/user';
import { HOTJAR_TAGS } from '../../config';

export const userUpdate = (user) => {
  if (user === null) {
    return {
      type: actionTypes.USER_UPDATE,
      userId: null,
      email: null,
      code: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      group: null,
      isNewUser: null,
      language: 'en',
      customerOrderCount: null,
      referralCode: null,
      isFarmerMarketNewUser: false,
      isFarmerMarketUser: false,
      createdAt: null,
    };
  }
  const groupPermissions = user.group.groupPermissions.filter(
    (groupPermission) => groupPermission.view === true
  );
  const groupPermissionsArray = groupPermissions.map((groupPermission) => {
    return groupPermission.name;
  });
  const pathPermissions = user.group.pathPermissions.filter(
    (groupPermission) => groupPermission.view === true
  );
  const pathPermissionsArray = pathPermissions.map((pathPermission) => {
    return pathPermission.name;
  });
  // console.log(groupPermissionsArray);
  const group = {
    name: user.group.name,
    groupPermissions: groupPermissionsArray,
    pathPermissions: pathPermissionsArray,
  };
  return {
    type: actionTypes.USER_UPDATE,
    userId: user._id,
    email: user.email,
    code: user.code,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    isNewUser: user.isNewUser,
    group,
    language: user.language ? user.language : 'en',
    customerOrderCount: user.customerOrderCount,
    referralCode: user.referralCode,
    isFarmerMarketNewUser: user.isFarmerMarketNewUser,
    isFarmerMarketUser: user.isFarmerMarketUser,
    createdAt: user.createdAt,
  };
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const response = await apolloClient.query({
      query: USER_CURRENT,
      fetchPolicy: 'network-only',
    });
    const { data } = response;
    const { userCurrent } = data;
    // only for warehouse owner and list one of warehouse on side bar for dashboard
    if (userCurrent && userCurrent.group.name === 'warehouse') {
      try {
        const {
          data: { warehouseMany },
        } = await apolloClient.query({
          query: WAREHOUSE_BY_OWNER,
          variables: { filter: { containUser: userCurrent._id } },
          fetchPolicy: 'network-only',
        });
        if (
          warehouseMany.length > 0 &&
          warehouseMany[0].userIds.includes(userCurrent._id)
        ) {
          const manageWarehouse = {
            name: warehouseMany[0].name,
            _id: warehouseMany[0]._id,
            isParent: warehouseMany[0].isParent,
          };
          await dispatch(
            updateOneWarehouseAttribute(manageWarehouse, 'manageWarehouse')
          );
        } else {
          message.error('Your account does not have any warehouses');
        }
      } catch (error) {
        console.error(error);
        message.error('get current warehouse error');
      }
    }
    if (userCurrent !== null) {
      try {
        const { email, group, _id: userId } = userCurrent;
        if (window.hj) {
          if (group && group.name === 'guest') {
            window.hj('tagRecording', [HOTJAR_TAGS.GUEST_USER]);
          } else {
            window.hj('tagRecording', [HOTJAR_TAGS.LOGGED_IN_USER]);
          }
          window.hj('identify', userId, {
            email,
          });
        }
      } catch (error) {
        console.error(error);
      }
      return await dispatch(userUpdate(userCurrent));
    }
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setUserShoppingMode =
  (input = null) =>
  async (dispatch, getState) => {
    const { currentShoppingMode } = getState().user;
    let final = null;
    if (input) {
      final = input;
    } else if (currentShoppingMode) {
      final = currentShoppingMode;
    }
    return dispatch({
      type: actionTypes.SHOPPING_MODE_UPDATE,
      currentShoppingMode: final,
    });
  };

export const setUserAddressType =
  (input = null) =>
  async (dispatch, getState) => {
    const { currentAddressType } = getState().user;
    let final = null;
    if (input) {
      final = input;
    } else if (currentAddressType) {
      final = currentAddressType;
    }
    return dispatch({
      type: actionTypes.ADDRESS_TYPE_UPDATE,
      currentAddressType: final,
    });
  };

// only overwrite not fetch
export const updateOneUserAttribute =
  (input = null, attributeName) =>
  async (dispatch) => {
    let final = null;
    if (input) {
      final = input;
    }
    return dispatch({
      type: actionTypes.ATTRIBUTE_UPDATE,
      attributeName,
      attributeValue: final,
    });
  };

export const getSaleorUser = (input) => async (dispatch) => {
  try {
    const { data } = await saleorClient.query({
      query: GET_USER_INFO_BY_ID,
      variables: { id: input },
      fetchPolicy: 'network-only',
    });
    if (data && data.user && data.user.email) {
      return dispatch({
        type: actionTypes.GET_SALEOR_USER,
        email: data.user.email,
      });
    }
  } catch (error) {
    console.error(error);
  }
  return dispatch({
    type: actionTypes.GET_SALEOR_USER,
    email: null,
  });
};

export const overWriteCallBack = (callback) => async (dispatch) => {
  return dispatch({
    type: actionTypes.CALLBACK_OVERWRITE,
    callback,
  });
};
