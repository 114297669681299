export const API_URL = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'https://magpie.bunbao.com/graphql/';
    }
    return 'https://api.bunbao.com/graphql/';
  }
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  return 'http://127.0.0.1:2020/graphql/';
};

export const WEBSOCKET_API_URL = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'wss://magpie.bunbao.com/graphql';
    }
    return 'wss://api.bunbao.com/graphql';
  }
  if (process.env.REACT_APP_WEBSOCKET_API_URL) {
    return process.env.REACT_APP_WEBSOCKET_API_URL;
  }
  return 'ws://127.0.0.1:2020/graphql';
};

export const API_URL_SALEOR = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'https://old.bunbao.com/graphql/';
    }
    return 'https://old.bunbao.com/graphql/';
  }
  if (process.env.REACT_APP_API_URL_SALEOR) {
    return process.env.REACT_APP_API_URL_SALEOR;
  }
  return 'https://old.bunbao.com/graphql/';
};

export const STRIPE_KEY = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'pk_test_HDTM2nTOpF5Mh7J2PBM66ZR7';
    }
    return 'pk_live_lhLReXlNCq3UIHvLyRiYa34n';
  }
  return 'pk_test_HDTM2nTOpF5Mh7J2PBM66ZR7';
};

export const BACK_URL = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'https://test.bunbao.com';
    }
    return 'https://bunbao.com';
  }
  if (process.env.REACT_APP_BACK_URL) {
    return process.env.REACT_APP_BACK_URL;
  }
  return 'https://test.bunbao.com';
};

export const LIVE_OR_NOT = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return false;
    }
    return true;
  }
  return false;
};

// export const API_URL_BUNBAO = 'http://49.51.232.22:8000/graphql/';

export const AVALARA_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://rest.avatax.com/api/v2/taxrates/bypostalcode'
    : 'https://rest.avatax.com/api/v2/taxrates/bypostalcode';

export const AVALARA_ID = '2000142089';
export const AVALARA_KEY = 'FBA74C020DF86BB5';

export const STRIPE_CLIENT_ID =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_BUILD_DEV !== 'true'
    ? 'ca_Guw9oJgcsQmkWbmgAgR5bPe1MvDqRqkD'
    : 'ca_Guw9aSopI2v8IIcPPIVkhVuUa3QfTXUf';

export const GOOGLE_MAP_KEY =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyCwv5bTW3Q0r_bSlIzRvFE8UyjcCjq8zHc'
    : process.env.REACT_APP_TEST_GOOGLE_API_KEY;

export const BUCKET_URL = 'https://bunbao.sfo2.digitaloceanspaces.com/';
export const BUCKET_CDN_URL = 'https://sfo.cdn.bunbao.xyz/';

export const GATEWAY_LOCATION_API_URL =
  'https://dunmvd6mnk.execute-api.us-west-2.amazonaws.com/Prod/';

export const GATEWAY_ORDER_API_URL =
  'https://w3aoayqmb1.execute-api.us-west-2.amazonaws.com/Prod/';

export const SECRET = '05a6bbbf-724e-4470-a159-8de9acf94679';

export const DEFAULT_IMAGE =
  'https://sfo.cdn.bunbao.xyz/media/products/%E5%8D%97%E6%96%B9%E7%8C%AA%E8%82%89_nx8Svje.jpeg';

export const WEB_PUSH_PUBLISH_KEY = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'BAEDnkumeuphlIXnDmFpvoybW-JYEj3AZC6DTKDdkdBbBrE4zzdwDYw9C0QXJyybZRNn-7DX5Ml_Q495hV67sTw';
    }
    return 'BBMNObFwV8Vq7RfMoF8aTXpqx83tgT7dfpntuUNIYrB2cBHECA7XPVZodtQTHUyuHL5oISpJF-TDHjiIC7FLeZs';
  }
  return 'BAEDnkumeuphlIXnDmFpvoybW-JYEj3AZC6DTKDdkdBbBrE4zzdwDYw9C0QXJyybZRNn-7DX5Ml_Q495hV67sTw';
};

export const LOCAL_STORAGE_VERSION = 1;

export const GOOGLE_LOGIN_CLIENT_ID = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return '437726880668-o901kqup7fvru4pnchc7hh79psmj15to.apps.googleusercontent.com';
    }
    return '437726880668-o901kqup7fvru4pnchc7hh79psmj15to.apps.googleusercontent.com';
  }
  return '437726880668-o901kqup7fvru4pnchc7hh79psmj15to.apps.googleusercontent.com';
};

export const HOTJAR_TAGS = {
  NOT_LOGGED_IN_USER: 'Not Logged In User',
  GUEST_USER: 'Guest User',
  LOGGED_IN_USER: 'Logged In User',
  PURCHASE: 'Purchase',
};

export const WECHAT_APP_ID = 'wxe7e405c6e71a47a7';
export const WECHAT_APP_SECRET = 'd038ad471356b28b913a624523632f3f';
