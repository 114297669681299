import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { convertRawToHTML } from 'braft-convert';
import convert from 'htmr';

const Announcement = ({ warehouse }) => {
  console.log(warehouse);
  const language = useSelector((state) => state.language.lang);
  if (!warehouse) {
    return null;
  }

  return (
    <div>
      {language === 'en' ? (
        <div className="braft-output-content">
          {convert(convertRawToHTML(JSON.parse(warehouse.announcement.en)))}
        </div>
      ) : (
        <div className="braft-output-content">
          {convert(convertRawToHTML(JSON.parse(warehouse.announcement.zh)))}
        </div>
      )}
    </div>
  );
};

Announcement.defaultProps = {
  warehouse: null,
};

Announcement.propTypes = {
  warehouse: PropTypes.shape({
    name: PropTypes.string,
    announcement: PropTypes.shape({
      zh: PropTypes.string,
      en: PropTypes.string,
    }),
  }),
};
export default Announcement;
