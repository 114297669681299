import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Input, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

export class CateringOrderBasicForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  async componentDidMount() {
    const { order } = this.props;
    const { headcount, addressOne, addressTwo, city, zip, scheduleDate } =
      order;
    const { form } = this.props;

    const scheduleDay = moment(scheduleDate);
    const scheduleTime = moment(scheduleDate);

    console.log(order);

    form.setFieldsValue({
      headcount,
      scheduleDate: scheduleDay,
      scheduleTime,
      addressOne,
      addressTwo,
      city,
      zip,
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    return (
      <>
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          layout="horizontal"
        >
          <Form.Item label="Headcount">
            {getFieldDecorator('headcount', { rules: [{ required: false }] })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Schedule Date">
            {getFieldDecorator('scheduleDate', {
              rules: [{ required: false }],
            })(<DatePicker />)}
          </Form.Item>
          <Form.Item label="Schedule Time">
            {getFieldDecorator('scheduleTime', {
              rules: [{ required: false }],
            })(<TimePicker />)}
          </Form.Item>
          <Form.Item label="Address 1">
            {getFieldDecorator('addressOne', { rules: [{ required: true }] })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Address 2">
            {getFieldDecorator('addressTwo', { rules: [{ required: false }] })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="City">
            {getFieldDecorator('city', { rules: [{ required: true }] })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Zip">
            {getFieldDecorator('zip', { rules: [{ required: true }] })(
              <Input />
            )}
          </Form.Item>
        </Form>
      </>
    );
  }
}

CateringOrderBasicForm.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  order: PropTypes.shape({
    headcount: PropTypes.number,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    scheduleDate: PropTypes.number,
  }).isRequired,
};

export default Form.create({ name: 'catering_order_form' })(
  CateringOrderBasicForm
);
