import React from 'react';
import PropTypes from 'prop-types';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './ImageTags.less';

// !Important
// Set 50% MAXWIDTH outside,
// position absolute
const ImageTags = (props) => {
  const { tag, onDotClick, isEdit, onItemClick } = props;
  console.log('render');
  if (!tag) {
    return null;
  }

  const clickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isEdit) {
      onDotClick();
    }
  };

  return (
    <div className="image-tags-space">
      {tag.direction === 'left' && (
        <div
          className="image-dots-container"
          style={{
            left: '0px',
          }}
          role="presentation"
          onClick={clickHandler}
          onTouchEnd={clickHandler}
        >
          <div className="image-dots" />
        </div>
      )}
      <div
        className="text-container"
        style={
          tag.direction === 'left'
            ? { marginLeft: '15px' }
            : { marginRight: '15px' }
        }
        onClick={() => {
          if (!isEdit && tag.label !== tag.value) {
            onItemClick(tag.value);
          }
        }}
        role="presentation"
      >
        {tag.direction === 'right' && tag.label !== tag.value && (
          <LeftOutlined style={{ color: 'white' }} />
        )}
        {tag.label}
        {tag.direction === 'left' && tag.label !== tag.value && (
          <RightOutlined style={{ color: 'white' }} />
        )}
      </div>
      {tag.direction === 'right' && (
        <div
          className="image-dots-container"
          style={{
            right: '0px',
          }}
          role="presentation"
          onClick={clickHandler}
          onTouchEnd={clickHandler}
        >
          <div className="image-dots" style={{ marginLeft: 'auto' }} />
        </div>
      )}
    </div>
  );
};

ImageTags.defaultProps = {
  onDotClick: () => {},
  onItemClick: () => {},
  isEdit: false,
};

ImageTags.propTypes = {
  tag: PropTypes.shape({
    direction: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onDotClick: PropTypes.func,
  isEdit: PropTypes.bool,
  onItemClick: PropTypes.func,
};

export default ImageTags;
