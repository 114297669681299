import React, { Component } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, message } from 'antd';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import GoogleLogin from 'react-google-login';
import * as actions from '../../../store/actions';
import { GOOGLE_LOGIN_CLIENT_ID } from '../../../config';
import './Login.css';
import { apolloClient } from '../../../util';
import { USER_FIND_ONE } from '../../../api/user';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  // handleChange = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  handleClickLoginButton = async (e) => {
    e.preventDefault();
    const { form } = this.props;
    await form.validateFields(async (err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        const { email, password } = values;
        const { auth, getCurrentUser } = this.props;
        if (
          email === '' ||
          email === null ||
          password === '' ||
          password === null
        ) {
          return;
        }
        try {
          await auth(email, password);
          // const { authObject } = this.props;
          // console.log(authObject);
          await getCurrentUser();
        } catch (error) {
          message.error('Can not login, please try again');
        }
      }
    });
  };

  responseGoogle = async (response) => {
    if (!response.error) {
      // try to auth
      // console.log(response);
      // console.log(response.tokenId);
      // console.log(response.accessToken);
      // console.log(response.Qt.zu);
      const { oauth2, getCurrentUser } = this.props;
      try {
        const responseFindUser = await apolloClient.query({
          query: USER_FIND_ONE,
          variables: { filter: { email: response.profileObj.email } },
          fetchPolicy: 'network-only',
        });
        if (responseFindUser.data.userOne) {
          await oauth2('google', response.profileObj.email, response.tokenId);
          await getCurrentUser();
        } else {
          message.error('User does not exist, please sign up');
        }
      } catch (error) {
        message.error('Can not login, please try again');
      }
    } else {
      console.log(response);
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="login-background"
      >
        <Col
          xl={8}
          lg={8}
          md={10}
          sm={15}
          xs={22}
          className="login-form-background"
        >
          <h1 style={{ textAlign: 'center' }}>
            {/* <FormattedMessage id="login" defaultMessage="Login" /> */}
            Welcome
          </h1>
          <Form onSubmit={this.handleClickLoginButton}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Please input your email!' },
                ],
              })(
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your password!' },
                ],
              })(
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
          <div style={{ textAlign: 'center', margin: '10px 0' }}>
            <GoogleLogin
              clientId={GOOGLE_LOGIN_CLIENT_ID()}
              buttonText="Login with Google"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy="single_host_origin"
            />
          </div>
          {/* <div style={{ textAlign: 'center' }}>
            Don&apos;t have an account? <Link to="/signup">Sign Up</Link>
          </div> */}
        </Col>
      </Row>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  oauth2: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authObject: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  auth: (email, password) => dispatch(actions.auth(email, password)),
  getCurrentUser: () => {
    dispatch(actions.getCurrentUser());
  },
  oauth2: (type, email, accessToken) =>
    dispatch(actions.oauth2(type, email, accessToken)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create({ name: 'foundation_login_form' })(Login))
);
