import gql from 'graphql-tag';

export const VARIANT_DASHBOARD_FRAGMENT = gql`
  fragment VARIANT_DASHBOARD_FRAGMENT on Variant {
    id
    createdAt
    deleted
    _id
    name {
      zh
      en
      _id
    }
    subtitle {
      _id
      zh
      en
    }
    description {
      _id
      zh
      en
    }
    supplierIds
    manufactureCategoryId
    minimumAddQuantity
    remark
    sku
    ranking
    purchaseCost
    originalPrice
    listPrice
    pictureId
    productId
    product {
      _id
      picture {
        key
        _id
      }
      category {
        _id
        name
      }
    }
    picture {
      _id
      key
      category
    }
    pictureZh {
      _id
      key
      category
    }
    videoIds {
      _id
      videoId
      language
    }
    warehouse {
      _id
      name
    }
    remarkName {
      zh
      en
    }
    servesNumber
    shape
    minimumQuantity
    allowedShippingDate
    markAsSoldOut
    numberOfUnits
    discountRemark
    remarkSelections
    allowedInventoryCheck
    maximumPerOrder
    excludeFromFreeShipping
    isChangeListPriceByDates
    changeListPriceDates {
      startDate
      endDate
    }
    isItNotAbleToAddCart
    taxable
    isShowUnitPrice
    isTopUpCard
    isGroupBuy
    groupBuyEndDate
    groupBuyStartDate
    groupBuyMaxOrderCount
    currentGroupOrders
    recommendedVariantIds
    groupBuyDiscountRank {
      orderItemCount
      listPrice
    }
    groupBuyRules {
      ruleShortInfo {
        zh
        en
      }
      ruleDetailDescription {
        zh
        en
      }
    }
    isLink
    linkTitle {
      _id
      en
      zh
    }
    link
    tagsZh
    tagsEn
    tagsTextZh
    tagsTextEn
    isLabelVariant
  }
`;

export const CHECK_VARIANTS_VALIDATION = gql`
  query ($variantIds: [MongoID!], $shoppingMode: String!) {
    checkVariantsValidation(
      variantIds: $variantIds
      shoppingMode: $shoppingMode
    )
  }
`;

export const VARIANT_FETCH_ALL = gql`
  {
    variantMany(limit: 10000) {
      ...VARIANT_DASHBOARD_FRAGMENT
    }
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

export const VARIANT_FETCH_ALL_FOR_DC = gql`
  {
    variantMany(
      limit: 10000
      filter: { isLabelVariant: true, deleted: false }
    ) {
      name {
        zh
      }
      shape
      listPrice
      _id
    }
  }
`;

export const VARIANTS_SEARCH_FOR_DC = gql`
  query ($search: String!) {
    variantMany(
      filter: { search: $search, isLabelVariant: true, deleted: false }
    ) {
      name {
        zh
      }
      shape
      listPrice
      _id
    }
  }
`;

export const VARIANTS_QUANTITY_BY_DC = gql`
  query ($distributionCenterId: MongoID!, $date: [String]) {
    variantQuantityByDC(
      distributionCenterId: $distributionCenterId
      date: $date
    ) {
      _id
      inStock
      record
    }
  }
`;

export const VARIANTS_RECENT_QUANTITY = gql`
  query ($distributionCenterId: MongoID!, $variantId: MongoID!) {
    variantRecentRecord(
      distributionCenterId: $distributionCenterId
      variantId: $variantId
    ) {
      date
      count
    }
  }
`;

export const DELETE_HISTORY_RECORD = gql`
  mutation (
    $distributionCenterId: MongoID!
    $variantId: MongoID!
    $date: String
  ) {
    deleteHistoryRecord(
      distributionCenterId: $distributionCenterId
      variantId: $variantId
      date: $date
    )
  }
`;

export const VARIANT_REPORT_ONE = gql`
  query ($_id: MongoID!, $startTime: Date!, $endTime: Date!) {
    itemReportOne(_id: $_id, startTime: $startTime, endTime: $endTime) {
      _id
      quantity
      name {
        zh
        en
      }
    }
  }
`;

export const VARIANT_REPORT_ALL = gql`
  query ($startTime: Float!, $endTime: Float!) {
    allItemsReport(startTime: $startTime, endTime: $endTime) {
      _id
      quantity
      name {
        zh
        en
      }
      totalSum
    }
  }
`;

export const VARIANT_FETCH_ALL_WITH_FILTER = gql`
  query ($filter: FilterFindManyVariantInput, $limit: Int, $skip: Int) {
    variantMany(filter: $filter, limit: $limit, skip: $skip) {
      ...VARIANT_DASHBOARD_FRAGMENT
    }
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

export const VARIANT_FETCH_DISCOUNT = gql`
  query ($filter: FilterFindManyVariantInput) {
    variantMany(filter: $filter, limit: 10000) {
      _id
      discountRemark
      remarkSelections
      name {
        zh
        en
      }
      product {
        _id
        category {
          _id
          name
        }
      }
    }
  }
`;

export const VARIANT_CREATE_ONE = gql`
  mutation ($input: CreateOneVariantInput!) {
    variantCreateOne(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;

export const VARIANT_UPDATE_ONE = gql`
  mutation ($input: UpdateByIdVariantInput!) {
    variantUpdateById(input: { record: $input }) {
      record {
        _id
      }
    }
  }
`;
export const VARIANT_UPDATE_MANY = gql`
  mutation (
    $filter: FilterUpdateManyVariantInput!
    $manufactureCategoryId: MongoID
    $isChangeListPriceByDates: Boolean
  ) {
    variantUpdateMany(
      input: {
        filter: $filter
        record: {
          manufactureCategoryId: $manufactureCategoryId
          isChangeListPriceByDates: $isChangeListPriceByDates
        }
      }
    ) {
      numAffected
      nodeId
    }
  }
`;

export const VARIANT_RESTORE_HOT_SALE = gql`
  mutation ($variantIds: [String!]) {
    restoreVariantPriceForHotSale(variantIds: $variantIds) {
      _id
    }
  }
`;

export const VARIANT_DELETE_ONE = gql`
  mutation ($Id: MongoID!) {
    variantDeleteOne(_id: $Id)
  }
`;

export const VARIANT_RESTORE_ONE = gql`
  mutation ($Id: MongoID!) {
    variantRestoreOne(_id: $Id)
  }
`;

export const VARIANTS_SEARCH = gql`
  query ($search: String!) {
    variantMany(filter: { search: $search }) {
      _id
      name {
        en
        zh
      }
    }
  }
`;

export const VARIANTS_FIND_HOT_SALE = gql`
  query ($filter: FilterFindManyVariantInput!) {
    variantMany(filter: $filter) {
      _id
      name {
        en
        zh
      }
      isChangeListPriceByDates
      changeListPriceDates {
        startDate
        endDate
      }
      listPrice
      originalPrice
    }
  }
`;

export const VARIANTS_FILTER_FIND_ID_NAME = gql`
  query ($filter: FilterFindManyVariantInput) {
    variantMany(filter: $filter) {
      _id
      name {
        en
        zh
      }
    }
  }
`;

export const VARIANTS_QUERY_IDS = gql`
  query ($input: [MongoID]!) {
    variantByIds(_ids: $input) {
      ...VARIANT_DASHBOARD_FRAGMENT
    }
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

export const VARIANT_WAREHOUSE_PAGINATION_QUERY = gql`
  query (
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyVariantInput
    $filter: FilterFindManyVariantInput
  ) {
    variantPagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        ...VARIANT_DASHBOARD_FRAGMENT
      }
    }
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

export const BUNBAO_VARIANT_FRAGMENT = gql`
  fragment BUNBAO_VARIANT_FRAGMENT on Variant {
    markAsSoldOut
    allowedShippingDate
    maximumPerOrder
    description {
      _id
      zh
      en
    }
    picture {
      _id
      key
    }
    pictureZh {
      _id
      key
    }
    videoIds {
      _id
      videoId
      language
    }
    name {
      _id
      zh
      en
    }
    subtitle {
      _id
      zh
      en
    }
    product {
      _id
      name {
        _id
        zh
        en
      }
      description {
        _id
        en
        zh
      }
      id
      picture {
        _id
        key
      }
      pictureZh {
        _id
        key
      }
      hashtags
      ranking
      category {
        _id
        id
        pictures {
          _id
          key
        }
        name
        ranking
        hashtags
        title {
          _id
          zh
          en
        }
      }
      subcategory {
        _id
        id
        name
        ranking
        hashtags
        title {
          _id
          zh
          en
        }
        color
      }
      unit
      unitNumber
      unitShape
      ingredients
      nutritionFacts
      allergen
    }
    servesNumber
    shape
    numberOfUnits
    ranking
    originalPrice
    isChangeListPriceByDates
    changeListPriceDates {
      _id
      startDate
      endDate
    }
    listPrice
    picture {
      key
    }
    minimumQuantity
    minimumAddQuantity
    _id
    id
    allowedInventoryCheck
    excludeFromFreeShipping
    isItNotAbleToAddCart
    isShowUnitPrice
    isGroupBuy
    groupBuyEndDate
    groupBuyStartDate
    groupBuyMaxOrderCount
    currentGroupOrders
    recommendedVariantIds
    groupBuyDiscountRank {
      orderItemCount
      listPrice
    }
    groupBuyRules {
      ruleShortInfo {
        zh
        en
      }
      ruleDetailDescription {
        en
        zh
      }
    }
    isLink
    linkTitle {
      _id
      en
      zh
    }
    link
  }
`;

export const VARIANT_GROUP_BUY_ACTIVITY = gql`
  query ($variantId: MongoID!) {
    variantCheckGroupBuyOrderCount(variantId: $variantId) {
      email
      quantity
      orderDate
    }
  }
`;

export const BUNBAO_VARIANT_BY_ID = gql`
  query ($Id: MongoID!) {
    variantById(_id: $Id) {
      ...BUNBAO_VARIANT_FRAGMENT
    }
  }
  ${BUNBAO_VARIANT_FRAGMENT}
`;

export const BUNBAO_VARIANT_BY_IDS = gql`
  query ($input: [MongoID]!) {
    variantByIds(_ids: $input) {
      ...BUNBAO_VARIANT_FRAGMENT
    }
  }
  ${BUNBAO_VARIANT_FRAGMENT}
`;

export const BUNBAO_VARIANT_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $sort: VariantPaginationWithInfiniteScrollSortEnum
    $filter: VariantPaginationWithInfiniteScrollFilterInput
  ) {
    variantPaginationWithInfiniteScroll(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        ...BUNBAO_VARIANT_FRAGMENT
      }
    }
  }
  ${BUNBAO_VARIANT_FRAGMENT}
`;

export const BUNBAO_VARIANT_KEYWORDS_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $sort: VariantPaginationWithInfiniteScrollSortEnum
    $filter: VariantPaginationWithKeywordsAndInfiniteScrollFilterInput
  ) {
    variantPaginationWithKeywordsAndInfiniteScroll(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        ...BUNBAO_VARIANT_FRAGMENT
      }
    }
  }
  ${BUNBAO_VARIANT_FRAGMENT}
`;

export const BUNBAO_VARIANT_KEYWORDS_AND_CATEGORIES_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $sort: VariantPaginationWithKeywordsAndCategoriesInfiniteScrollSortEnum
    $filter: variantPaginationWithKeywordsAndCategoriesInfiniteScrollFilterInput
  ) {
    variantPaginationWithKeywordsAndCategoriesInfiniteScroll(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        ...BUNBAO_VARIANT_FRAGMENT
      }
    }
  }
  ${BUNBAO_VARIANT_FRAGMENT}
`;

export const PRODUCTS_WAREHOUSE_VARIANTS_UPDATE = gql`
  mutation ($warehouseIds: [MongoID], $variantId: MongoID, $price: Float) {
    updateWarehouseVariants(
      warehouseIds: $warehouseIds
      variantId: $variantId
      price: $price
    ) {
      _id
    }
  }
`;

export const PRODUCTS_WAREHOUSE_VARIANTS_QUERY = gql`
  query ($Id: MongoID!) {
    variantById(_id: $Id) {
      warehouseVariantsObjects {
        listPrice
        warehouse {
          _id
          name
          addressOne
          addressTwo
          city
          state
          zip
          warehouseType
        }
      }
    }
  }
`;

export const VARIANT_TO_ABBREVIATION = gql`
  {
    variantMany {
      name {
        zh
        en
      }
      product {
        enAbbreviation
      }
    }
  }
`;

export const VARIANT_FIND_BY_ID = gql`
  query ($_id: MongoID!) {
    variantById(_id: $_id) {
      ...VARIANT_DASHBOARD_FRAGMENT
    }
  }
  ${VARIANT_DASHBOARD_FRAGMENT}
`;

export const VARIANT_ADD_ONS = gql`
  query (
    $warehouseId: MongoID
    $orderTotal: Float!
    $discountCode: String
    $shippingDate: String
  ) {
    warehouseAddons(
      warehouseId: $warehouseId
      orderTotal: $orderTotal
      discountCode: $discountCode
      shippingDate: $shippingDate
    ) {
      items {
        ...BUNBAO_VARIANT_FRAGMENT
      }
      discountAmount
      deltaAmount
    }
  }
  ${BUNBAO_VARIANT_FRAGMENT}
`;

export const VARIANT_SYNC_NAME_WITH_JIEBA = gql`
  mutation {
    variantSyncNameWithJieba
  }
`;

export const VARIANTS_DISCOUNT_PRICE = gql`
  mutation (
    $_ids: [MongoID!]
    $startDate: Float!
    $endDate: Float!
    $percentage: Float!
  ) {
    variantDiscountPrice(
      _ids: $_ids
      startDate: $startDate
      endDate: $endDate
      percentage: $percentage
    )
  }
`;

export const VARIANT_GET_SYNONYMS = gql`
  query ($text: String!, $language: String!) {
    getSynonymsWithWords(text: $text, language: $language) {
      text
    }
  }
`;
