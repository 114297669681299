export default {
  en: {
    map: {
      all: 'All',
      distributor: 'Distributor',
      farmersMarket: 'Market',
      none: 'Click Here',
      scheduledDelivery: 'Scheduled Delivery',
      scheduledPickup: 'Scheduled pickup',
      deliveredASAP: 'Delivered ASAP',
      pickupASAP: 'All Stores',
      menu: 'Pickup Menu',
      lookupDeliveryMenu: 'Delivery Menu',
      tapToExplore: 'Tap to Explore',
      chooseDeliveryPickup: 'Please Choose Delivery / Pickup',
      switchDeliveryPickup: 'Change to Delivery / Pickup',
      switchIt: 'Switch to',
      clickHere: 'Click here and change to delivery or pickup',
    },
  },
  zh: {
    map: {
      all: '所有',
      distributor: '店铺',
      none: '点击这里',
      farmersMarket: 'Market',
      scheduledDelivery: '预定配送',
      scheduledPickup: '预定自取',
      deliveredASAP: '现在配送',
      pickupASAP: '美食团购',
      menu: '查看自取菜单',
      lookupDeliveryMenu: '查看配送菜单',
      tapToExplore: '点击定位',
      chooseDeliveryPickup: '请您选择配送方式',
      switchDeliveryPickup: '换一种配送或者取货方式？',
      switchIt: '换一换',
      clickHere: '点击这里切换配送方式',
    },
  },
};
