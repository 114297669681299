export default {
  en: {
    account: {
      settings: 'Account Settings',
      orders: 'Orders',
      points: 'Points',
      cloudStoreOrders: 'Cloud Store Orders',
      carStickerOrders: 'Car Sticker Orders',
      pickupOrders: 'Your Pickup Orders',
      managePayment: 'Manage Your Saved Payment',
      gamesAndPromotions: 'Check Out Our Games and Promotions',
      viewCouponWallet: 'View Your Coupon Wallet',
      viewInvitation: 'Invite a Friend, Both Get Coupon',
      accountInfo: 'Complete Your Info & Reset Password',
      accountViewGroupBuy: 'View and Share Your Group Buy',
      appliedCode: 'Applied Code: ',
      balance: 'Balance',
      balanceGiftCard: 'Balance & Gift Card',
      balanceTransaction: 'Balance Transaction',
    },
    point: {
      myPoints: 'My Points',
      pointRule: 'Rules',
      transactions: 'Transactions',
      all: 'All',
      add: 'Added',
      used: 'Used',
      point: 'Points',
      operation: 'Operation',
      detail: 'Detail',
      date: 'Date',
    },
    balance: {
      myBalance: 'My Balance',
      balanceRule: 'Rules',
      transactions: 'Transactions',
      balanceTransactions: 'Transactions & Gift Card',
      all: 'All',
      amount: 'Amount',
      add: 'Added',
      used: 'Used',
      dollar: 'USD',
      operation: 'Operation',
      detail: 'Detail',
      date: 'Date',
      referralCode: 'Referral Code',
      goShopping: 'Shopping with balance',
      orderDisplayId: 'Display ID',
      minSales: 'Minimum Sales',
      maxSales: 'Maximum Sales',
      referralCoefficient: 'Current Referral Coefficient',
      orderTotal: 'Order Total',
      topUpExplain: 'Top Up',
      topUpDetail: 'Top up amount{amount}(#{orderId})',
      referralDetail: 'Referral order amount{amount}',
      referralExplain: 'Referral Others',
      viewReferralCode: 'View Referral Code',
      placeOrder: 'Place Order',
      placeOrderExplain: 'Place order amount{amount}(#{orderId})',
      topUp: 'Top Up',
      buyGiftCard: 'Buy Gift Card',
      promotion: 'Promotion',
      promotionDetail: 'Promotion amount{amount}(#{orderId})',
      balanceUsed: 'Balance Spend',
      balanceUsedDetail: 'Balance spend{amount}(#{orderId})',
      refundedBalance: 'Refunded Balance',
      refundedBalanceDetail: 'Refund {amount}(#{orderId})',
      letsShare: 'Cashback Rules',
      status: 'Status',
      invalid: 'Invalid Balance',
      adminOperation: 'Admin Operation',
      redeemGiftCard: 'Redeem Gift Card',
    },
  },
  zh: {
    account: {
      settings: '账户设置',
      orders: '配送订单',
      points: '积分明细',
      cloudStoreOrders: '云店订单',
      carStickerOrders: '车贴配送订单',
      pickupOrders: '取货订单',
      managePayment: '管理您的支付',
      gamesAndPromotions: '快来看看游戏吧',
      viewCouponWallet: '您的专属折扣券',
      viewInvitation: '邀请朋友注册拿折扣券',
      accountInfo: '编辑您的个人信息和修改密码',
      accountViewGroupBuy: '快来看看您的团购吧',
      appliedCode: '使用的折扣码: ',
      balance: '余额',
      balanceGiftCard: '余额 & 礼品卡',
      balanceTransaction: '余额明细',
    },
    point: {
      myPoints: '我的积分',
      pointRule: '积分规则',
      transactions: '积分明细',
      all: '全部',
      add: '获取',
      used: '支出',
      point: '积分',
      operation: '操作',
      detail: '详情',
      date: '日期',
    },
    balance: {
      myBalance: '我的余额',
      balanceRule: '余额规则',
      amount: '金额',
      transactions: '积分明细',
      balanceTransactions: '余额明细 & 礼品卡',
      all: '全部',
      add: '获取',
      used: '支出',
      dollar: '美金',
      operation: '操作',
      detail: '详情',
      orderDisplayId: '订单ID',
      date: '日期',
      referralCode: '推荐码',
      goShopping: '继续购物',
      minSales: '最少消费',
      maxSales: '最多消费',
      referralCoefficient: '当前返还比例',
      referralExplain: '推荐购物',
      topUpExplain: '余额充值',
      balanceUsed: '余额支出',
      balanceUsedDetail: '余额支出{amount}(#{orderId})',
      topUpDetail: '充值金额{amount}(#{orderId})',
      referralDetail: '推荐购买的订单总额{amount}',
      orderTotal: '订单总价',
      viewReferralCode: '查看推荐码',
      placeOrder: '购物下单',
      placeOrderExplain: '订单总金额{amount}(#{orderId})',
      topUp: '充值',
      buyGiftCard: '购买礼品卡',
      promotion: '赠送',
      promotionDetail: '赠送金额{amount}(#{orderId})',
      refundedBalance: '退款余额',
      refundedBalanceDetail: '退款金额{amount}(#{orderId})',
      letsShare: '种草返利规则',
      status: '状态',
      invalid: '无效余额',
      adminOperation: '管理员操作',
      redeemGiftCard: '兑换礼品卡',
    },
  },
};
