/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getCustomerPath } from '../../../util';

export class CustomerFooter extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <div>
          <div
            css={{
              marginRight: '10px',
              display: 'inline-block',
              color: 'black',
            }}
          >
            <Link to={getCustomerPath('about')}>
              <FormattedMessage id="aboutUs" />
            </Link>
          </div>
          <div css={{ marginRight: '10px', display: 'inline-block' }}>
            <Link to={getCustomerPath('terms-of-service')}>
              <FormattedMessage id="terms" />
            </Link>
          </div>
          <div css={{ marginRight: '10px', display: 'inline-block' }}>
            <Link to={getCustomerPath('privacy-policy')}>
              <FormattedMessage id="privacyPolicy" />
            </Link>
          </div>
          <div css={{ marginRight: '10px', display: 'inline-block' }}>
            <Link to={getCustomerPath('contact')}>
              <FormattedMessage id="contactUs" />
            </Link>
          </div>
          <div css={{ marginRight: '10px', display: 'inline-block' }}>
            <Link to={getCustomerPath('stories')}>
              <FormattedMessage id="stories" />
            </Link>
          </div>
          <div css={{ marginRight: '10px', display: 'inline-block' }}>
            <Link to={getCustomerPath('faq')}>
              <FormattedMessage id="faq" />
            </Link>
          </div>
          <div css={{ display: 'inline-block' }}>
            <Link to={getCustomerPath('careers')}>
              <FormattedMessage id="careers" />
            </Link>
          </div>
        </div>
        <div>
          ©2018 - {moment().format('YYYY')} BunBao.com, Inc. All rights reserved
        </div>
      </div>
    );
  }
}

export default CustomerFooter;
