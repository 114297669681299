export default {
  en: {
    dashboard: {
      adminNotice:
        'Today User created, Today delivery Customer Orders, Today schedule Catering Order',
      createdAt: 'Created At',
      lastLogin: 'Last Login',
      name: 'Name',
      category: 'Category',
      ranking: 'Ranking',
      actions: 'Actions',
      addBanner: 'Add Banner',
      picture: 'Picture',
      description: 'Description',
      price: 'Price',
      edit: 'Edit',
      delete: 'Delete',
      addVariant: 'Add Variant',
    },
  },
  zh: {
    dashboard: {
      adminNotice:
        '日期为今日创建的用户，今日配送的客户订单，今日配送的公司订单',
      createdAt: '创建日期',
      lastLogin: '活跃日期',
      name: '名字',
      category: '分类',
      ranking: '排序',
      actions: '操作',
      addBanner: '添加横幅',
      picture: '图片',
      description: '描述',
      price: '价格',
      edit: '操作',
      delete: '删除',
      addVariant: '添加变体',
    },
  },
};
