import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { WAREHOUSE_FIND_ONE } from '../../../api/warehouse';
import { apolloClient, getCustomerPath } from '../../../util';

export class WarehouseRedirector extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      redirectToHome: false,
      redirectToWarehouse: false,
      warehouseUrl: '/',
    };
  }

  componentDidMount = async () => {
    const {
      match: {
        params: {
          type: redirectType,
          id: warehouseAlias,
          name: subWarehouseAlias,
        },
      },
      history: { location },
    } = this.props;
    const { state } = location;
    if (state) {
      const { redirectTo } = state;
      if (redirectTo) {
        await this.setState({
          redirectToWarehouse: true,
          warehouseUrl: getCustomerPath(redirectTo),
        });
        return;
      }
      // history.push(redirectTo)
    }
    console.log('warehouseAlias', warehouseAlias);
    this.handleFetchWarehouse(warehouseAlias, subWarehouseAlias, redirectType);
  };

  handleFetchWarehouse = async (
    warehouseAlias,
    subWarehouseAlias,
    redirectType
  ) => {
    const { data } = await apolloClient.query({
      query: WAREHOUSE_FIND_ONE,
      variables: { filter: { alias: warehouseAlias } },
      fetchPolicy: 'network-only',
    });
    console.log(data);
    if (data.warehouseOne !== null && redirectType) {
      if (redirectType === 'fm') {
        if (subWarehouseAlias && subWarehouseAlias !== '') {
          this.setState({
            redirectToWarehouse: true,
            warehouseUrl: getCustomerPath(
              `delivery-menu?warehouseId=${data.warehouseOne._id}&subWarehouseAlias=${subWarehouseAlias}`
            ),
          });
        } else if (subWarehouseAlias === 'delivery-menu') {
          this.setState({
            redirectToWarehouse: true,
            warehouseUrl: getCustomerPath(`delivery-menu`),
          });
        } else {
          this.setState({
            redirectToWarehouse: true,
            warehouseUrl: getCustomerPath(
              `delivery-menu?warehouseId=${data.warehouseOne._id}`
            ),
          });
        }
      } else {
        this.setState({
          redirectToWarehouse: true,
          warehouseUrl: getCustomerPath(
            `pickup-menu?warehouseId=${data.warehouseOne._id}`
          ),
        });
      }
    } else {
      this.setState({ redirectToHome: true });
    }
  };

  render() {
    const { redirectToHome, redirectToWarehouse, warehouseUrl } = this.state;
    return (
      <div>
        {redirectToHome && <Redirect to="/" />}
        {redirectToWarehouse && <Redirect to={warehouseUrl} />}
      </div>
    );
  }
}

WarehouseRedirector.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default WarehouseRedirector;
