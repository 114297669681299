/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Carousel } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Component } from 'react';
import CateringCustomizeForm from '../../../components/Forms/CateringCustomizeForm/CateringCustomizeForm';
import { BUCKET_URL } from '../../../config';
// import PropTypes from 'prop-types';
import './CateringCustomize.less';

export class CateringCustomize extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const { language } = this.props;
    return (
      <div className="catering-customize-page">
        <div style={{ position: 'relative' }} className="selection-margin-b">
          <Carousel
            autoplay
            dotPosition="bottom"
            className="slide-container"
            style={{
              borderRadius: '25px',
              overflow: 'hidden',
            }}
          >
            {language === 'en' ? (
              <div className="slide-container">
                <img
                  className="slide-image"
                  src={`${BUCKET_URL}images/catering/customer-catering-banner-en.jpg`}
                  alt="catering"
                />
              </div>
            ) : (
              <div className="slide-container">
                <img
                  className="slide-image"
                  src={`${BUCKET_URL}images/catering/customer-catering-banner-zh.jpg`}
                  alt="catering"
                />
              </div>
            )}
          </Carousel>
        </div>
        <div className="page-layout">
          <div style={{ flex: 1, marginRight: '20%' }}>
            <div className="common-title" style={{ fontSize: '30px' }}>
              <FormattedMessage id="customerCateringHome.titleOne" />
            </div>
            <div className="common-paragraph">
              <FormattedMessage id="customerCateringHome.paragraphOne" />
            </div>
            <div className="common-title">
              <FormattedMessage id="customerCateringHome.titleTwo" />
            </div>
            <div className="common-paragraph">
              <FormattedMessage id="customerCateringHome.paragraphTwo" />
            </div>
            <div className="common-title">
              <FormattedMessage id="customerCateringHome.titleThree" />
            </div>
            <div className="common-paragraph">
              <FormattedMessage id="customerCateringHome.paragraphThree" />
            </div>
            <div className="common-title">
              <FormattedMessage id="customerCateringHome.titleFour" />
            </div>
            <div className="common-paragraph">
              <FormattedMessage id="customerCateringHome.paragraphFour" />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="order-form">
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ color: 'white' }}>
                  <FormattedMessage id="customerCateringHome.getStarted" />
                </h2>
              </div>
              <CateringCustomizeForm />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CateringCustomize.propTypes = {
  // token: PropTypes.string,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(CateringCustomize);
