import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, message } from 'antd';
import FormBuilder from 'antd-form-builder';
import { apolloClient } from '../../../util';
import { GIFT_CARD_REDEEM } from '../../../api/giftCard';

function RedeemGiftCardForm(props) {
  const [form] = Form.useForm();
  const { cardNumber, cardPin, handleCloseRedeemModal } = props;

  const handleSubmit = async (values) => {
    // request redeem
    console.log(values);
    const { cardNumber: numberString, cardPin: pinString } = values;
    try {
      const { data } = await apolloClient.mutate({
        mutation: GIFT_CARD_REDEEM,
        variables: {
          cardNumber: numberString,
          cardPin: pinString,
        },
      });
      console.log(data);
      message.success('Redeem successful');
      handleCloseRedeemModal();
    } catch (error) {
      message.error('Sorry, can not redeem gift card');
    }
  };

  const meta = [
    { key: 'cardNumber', label: 'Card Number', initialValue: cardNumber },
    { key: 'cardPin', label: 'Card Pin', initialValue: cardPin },
  ];

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <FormBuilder form={form} meta={meta} />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Redeem
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

RedeemGiftCardForm.defaultProps = {
  cardNumber: null,
  cardPin: null,
};

RedeemGiftCardForm.propTypes = {
  cardNumber: PropTypes.string,
  cardPin: PropTypes.string,
  handleCloseRedeemModal: PropTypes.func.isRequired,
};

export default RedeemGiftCardForm;
