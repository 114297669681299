import React from 'react';
import { Route } from 'react-router-dom';
import { WechatAPIProvider } from 'react-wechat-api';
import wx from 'weixin-js-sdk';
import { WECHAT_TOKEN } from '../../api/configuration';
import { apolloClient } from '../../util';

const getConfig = async ({ url }) => {
  /* should return an object like {
  appId: "wx05d8cb9deee3c05c",
  nonceStr: "nw0y6jnq1ie",
  signature: "e50d96cb73c09ba1e5848456d1ae90ec1b7ccf43",
  timestamp: 1541346529448
  } */
  console.log(url);
  const wechatTokenResponse = await apolloClient.query({
    query: WECHAT_TOKEN,
    variables: { url },
  });
  console.log(wechatTokenResponse);
  const {
    data: {
      configurationWechatToken: { appId, nonceStr, signature, timestamp },
    },
  } = wechatTokenResponse;
  console.log(appId, nonceStr, signature);
  return { appId, nonceStr, signature, timestamp };
};

const defaultShareData = {
  title: '棒!包',
  desc: '试试看吧当日新鲜制作 | 免费配送到家 | 20+口味随你组合',
  link: () => window.location.href, // will return latest URL dynamically
  imgUrl: `${window.location.origin}/shareLogoWeChat.png`,
};

const defaultJsApiList = [
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
  'onMenuShareQQ',
  'onMenuShareQZone',
];

export default function WeChat(props) {
  return (
    <Route>
      {({ location }) => (
        <WechatAPIProvider
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          location={location} // <-- `location` is required
          wx={wx} // <-- `wx` is required
          getConfig={getConfig}
          jsApiList={defaultJsApiList}
          shareData={defaultShareData}
        />
      )}
    </Route>
  );
}
