import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import Axios from 'axios';
import imageCompression from 'browser-image-compression';
import { PICTURE_GET_SIGNED_URL, PICTURE_CREATE } from '../../../api/picture';
import { apolloClient } from '../../../util';

export class PictureUploader extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      uploadUrl: null,
      fileChecker: false,
      fileKey: null,
    };
  }

  // componentDidUpdate(pervProps) {
  //   const { imageUrl } = this.props;
  //   console.log(imageUrl);
  //   if (imageUrl !== null && pervProps.imageUrl === null) {
  //     this.setState({ imageUrl });
  //   }
  // }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const { imageUrl, fileList } = prevState;
  //   if (imageUrl === null && nextProps.imageUrl !== null) {
  //     return {
  //       imageUrl: nextProps.imageUrl,
  //     };
  //   }
  //   if (
  //     fileList.length <= 0 &&
  //     !lodash.some(nextProps.images, (element) => element === null)
  //   ) {
  //     // console.log(lodash.some(nextProps.images, (element) => element === null));
  //     // console.log(nextProps.images);
  //     return { fileList: nextProps.images };
  //   }
  //   return null;
  // }

  beforeUpload = async (file) => {
    // first check file type
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(file.type)) {
      message.error('You can only upload JPG/PNG file!');
      await this.setState({ fileChecker: false });
      return false;
    }
    await this.setState({ fileChecker: true });

    const { category } = this.props;

    try {
      console.log(file);
      const response = await apolloClient.mutate({
        mutation: PICTURE_GET_SIGNED_URL,
        variables: {
          fileType: file.type,
          category: category || 'banner',
        },
      });
      console.log(response);
      const {
        data: { pictureCreateSignedUrl },
      } = response;

      await this.setState({
        uploadUrl: pictureCreateSignedUrl.signedUrl,
        fileKey: pictureCreateSignedUrl.key,
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  customRequest = async ({ action, file, onSuccess, onError, onProgress }) => {
    const { fileChecker } = this.state;
    if (fileChecker === false) {
      return;
    }
    console.log('uploading');
    console.log(action);
    const options = {
      maxSizeMB: 2,
      // useWebWorker: true,
    };
    let compressedFile;
    // compress image file
    if (file.type === 'image/gif') {
      compressedFile = file;
    } else {
      compressedFile = await imageCompression(file, options);
      console.log(
        'compressedFile instanceof Blob',
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
    }

    try {
      // upload file to s3
      await Axios.put(action, compressedFile, {
        headers: {
          'Content-Type': compressedFile.type,
          'x-amz-acl': 'public-read',
        },
        onUploadProgress: onProgress,
      });

      const { category } = this.props;

      // create picture object
      const { fileKey } = this.state;
      const pictureResponse = await apolloClient.mutate({
        mutation: PICTURE_CREATE,
        variables: {
          key: fileKey,
          category: category || 'banner',
        },
      });

      console.log(pictureResponse);

      // return picture id
      onSuccess({
        pictureId: pictureResponse.data.pictureCreate._id,
        url: pictureResponse.data.pictureCreate.key,
      });
    } catch (error) {
      console.error(error);
      message.error('image upload aborted');
      onError();
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  render() {
    const { uploadUrl } = this.state;
    const { fileList, imageLimit, onChange, onRemove } = this.props;
    const list = fileList || [];

    return (
      <Upload
        listType="picture-card"
        // showUploadList={false}
        fileList={list}
        action={uploadUrl}
        beforeUpload={this.beforeUpload}
        onChange={onChange}
        customRequest={this.customRequest}
        onRemove={onRemove}
      >
        {/* {imageUrl ? (
          <img
            role="presentation"
            src={imageUrl}
            alt="avatar"
            style={{ width: '100%' }}
          />
        ) : (
          <div role="presentation">
            <Icon type={loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
          </div>
        )} */}
        {list.length > imageLimit - 1 ? null : (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        )}
      </Upload>
    );
  }
}

PictureUploader.defaultProps = {
  onChange: null,
  fileList: [],
  category: null,
  onRemove: () => {},
};

PictureUploader.propTypes = {
  onChange: PropTypes.func,
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  imageLimit: PropTypes.number.isRequired,
  category: PropTypes.string,
  onRemove: PropTypes.func,
};

export default PictureUploader;
