/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Statistic } from 'antd';
import { Component } from 'react';
import * as QueryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { WechatAPI } from 'react-wechat-api';
import { apolloClient, getCustomerPath, mosaicEmail } from '../../../util';
import ticket from '../../../assets/images/ticket.png';
import { USER_FIND_ONE } from '../../../api/user';
import {
  CUSTOMER_ORDER_BY_ID,
  CUSTOMER_ORDER_SHARE_START_TIME,
} from '../../../api/customerOrder';
import fireworks from '../../../assets/images/fireworks.png';
import fireworksBackground from '../../../assets/images/fireworksBackground.png';

const { Countdown } = Statistic;
export class OrderShare extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      email: '',
      shareStartDate: null,
      wechatShareData: {
        title: '送您一张【BunBao 优惠券】点击链接享受全单5%折扣！',
      },
    };
  }

  componentDidMount = async () => {
    // check order id and referral code
    const {
      location: { search },
      history,
    } = this.props;
    const paramObj = QueryString.parse(search);
    if (paramObj.referralCode && paramObj.orderId) {
      // check order and referral code
      const { referralCode, orderId } = paramObj;
      console.log(referralCode, orderId);
      // check user information
      const responseFindUser = await apolloClient.query({
        query: USER_FIND_ONE,
        variables: { filter: { referralCode: paramObj.referralCode } },
        fetchPolicy: 'network-only',
      });
      if (responseFindUser.data.userOne) {
        try {
          await apolloClient.mutate({
            mutation: CUSTOMER_ORDER_SHARE_START_TIME,
            variables: {
              orderId,
            },
          });
        } catch (error) {
          console.error(error);
        }
        // delete paramObj.referralCode;
        // const newParamObj = QueryString.stringify(paramObj);
        this.setState({ email: responseFindUser.data.userOne.email });

        // check order id
        const responseFindOrder = await apolloClient.query({
          query: CUSTOMER_ORDER_BY_ID,
          variables: { id: orderId },
        });
        console.log(responseFindOrder);
        if (responseFindOrder?.data?.customerOrderById?.shareStartDate) {
          // check date
          this.setState({
            shareStartDate:
              responseFindOrder.data.customerOrderById.shareStartDate,
          });
          window.sessionStorage.setItem('referralCode', paramObj.referralCode);
          window.sessionStorage.setItem('referralOrderId', paramObj.orderId);
        } else {
          history.push(getCustomerPath(''));
        }
      } else {
        history.push(getCustomerPath(''));
      }
    }
  };

  render() {
    const { email, shareStartDate, wechatShareData } = this.state;
    const { history, language } = this.props;
    return (
      <div
        css={{
          textAlign: 'center',
          height: '100vh',
          backgroundImage: `radial-gradient(ellipse farthest-corner at 45px 45px, rgba(255,255,255, 0.5) 0%, rgba(255,255,255, 0.5) ), url(${fireworksBackground})`,
        }}
      >
        <WechatAPI shareData={wechatShareData}>
          <div />
        </WechatAPI>
        <div css={{ fontSize: '30px', margin: '20px 0px' }}>
          <img src={fireworks} alt="fireworks" height="64px" />
          {language === 'zh' ? '太棒啦' : 'Good News!'}
        </div>
        <div css={{ fontSize: '20px', margin: '20px 0px' }}>
          {language === 'zh'
            ? `你收到了一个来自${mosaicEmail(email)}发来的分享链接`
            : `You have received a shared link by ${mosaicEmail(email)}`}
        </div>
        <div
          css={{
            backgroundImage: `url(${ticket})`,
            backgroundSize: 'cover',
            width: '235px',
            height: '100px',
            marginRight: 'auto',
            marginLeft: 'auto',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <div css={{ fontSize: '50px' }}>5%</div>
          <div css={{ fontSize: '30px' }}>OFF</div>
        </div>
        <div css={{ fontSize: '30px', margin: '20px 0px' }}>
          <Countdown
            valueStyle={{ fontWeight: 'bold', color: 'red', fontSize: '40px' }}
            title="Countdown"
            value={moment(shareStartDate).add(1, 'days')}
          />
        </div>
        <div css={{ margin: '20px 0px' }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              history.push(getCustomerPath('supermarket'));
            }}
          >
            {language === 'zh' ? '现在购买' : 'Shop now'}
          </Button>
        </div>
        <div css={{ margin: '20px 0px' }}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              history.push(getCustomerPath('account/balance'));
            }}
          >
            {language === 'zh' ? '查看余额' : 'Check Balance'}
          </Button>
        </div>
      </div>
    );
  }
}

OrderShare.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(OrderShare);
