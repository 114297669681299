import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { PersistGate } from 'redux-persist/integration/react';
import { hotjar } from 'react-hotjar';
import { apolloClient } from './util';
import './index.css';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'braft-editor/dist/output.css';
import App from './App';
// import 'dayjs/locale/zh-cn';
import * as pushNotification from './pushNotifications';
// import * as serviceWorker from './serviceWorker';
import { store, persistor } from './configureStore';
import WeChat from './helper/WeChat/WeChat';

// import { GOOGLE_MAP_KEY } from './config';

require('dotenv').config();

const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

if (
  process.env.NODE_ENV === 'production' &&
  window.location.host !== 'foundation.bunbao.com'
) {
  hotjar.initialize(1326339, 6);
}

// Add Google Analytics
if (process.env.NODE_ENV === 'production') {
  if (window.location.host === 'foundation.bunbao.com') {
    ReactGA.initialize('UA-140415760-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
  } else {
    ReactPixel.init('2597435233911317', {}, pixelOptions);
    ReactPixel.pageView();
    ReactGA.initialize('UA-140415760-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

// const loadGoogleMaps = (callback) => {
//   const existingScript = document.getElementById('googleMaps');

//   if (!existingScript) {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`;
//     script.id = 'googleMaps';
//     document.body.appendChild(script);

//     script.onload = () => {
//       if (callback) {
//         callback();
//       }
//     };
//   }

//   if (existingScript && callback) {
//     callback();
//   }
// };

// loadGoogleMaps();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    release: `production@${process.env.REACT_APP_CURRENT_GIT_SHA}`,
    dsn: 'https://b86ca529606b4f5282c77f1906aec76b@o262103.ingest.sentry.io/5418259',
    ignoreErrors: ['Non-Error exception captured'],
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <WeChat>
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
        </WeChat>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
pushNotification.pushNotification();
