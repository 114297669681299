import React from 'react';
import PropTypes from 'prop-types';
import { Button, message, Modal } from 'antd';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import convert from 'htmr';
import { convertRawToHTML } from 'braft-convert';
import { FormattedMessage, injectIntl } from 'react-intl';
import { apolloClient, getCustomerPath } from '../../../util';
import { BUCKET_URL } from '../../../config';
import { VOTE_POST_BY_ID, VOTE_POST_UPDATE } from '../../../api/vote';
import * as actions from '../../../store/actions';
import shareIcon from '../../../assets/images/icons/share.png';
import Like from '../../../assets/images/icons/like.png';
import ImageTags from '../../../components/Others/ImageTags/ImageTags';
import 'swiper/swiper.less';
/* eslint-disable import/no-extraneous-dependencies */
import 'swiper/components/pagination/pagination.less';
import './VotePost.less';

SwiperCore.use([Pagination]);
class VotePost extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      votePostObj: null,
      postStatusModal: false,
      voteConfirmVisible: false,
      currentSlideIndex: 0,
    };
    this.boundContainer = React.createRef();
  }

  async componentDidMount() {
    console.log(this.boundContainer);
    const {
      location: { search },
    } = this.props;
    if (search && search.includes('?votePostId=')) {
      const votePostId = search.replace('?votePostId=', '');
      await this.fetchVotePostById(votePostId);
    }
  }

  fetchVotePostById = async (id) => {
    try {
      const {
        data: { votePostById },
      } = await apolloClient.query({
        query: VOTE_POST_BY_ID,
        variables: { id },
      });
      console.log(votePostById);
      this.setState({
        votePostObj: votePostById,
      });
      if (votePostById.status !== 'pass') {
        this.setState({
          postStatusModal: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleCheckUrl = () => {
    if (process.env.NODE_ENV !== 'production') {
      return 'localhost:3222/customer';
    }
    if (process.env.REACT_APP_BUILD_DEV === 'true') {
      return 'test.bunbao.com';
    }
    return 'bunbao.com';
  };

  handleShareButton = async (id) => {
    const { votePostObj } = this.state;
    const { lang } = this.props;
    const shareLink = `https://${this.handleCheckUrl()}/vote-post?votePostId=${id}`;
    if (navigator.share) {
      if (lang === 'zh') {
        navigator
          .share({
            title: '我在棒!包晒美食，晒美食赢取大礼',
            text: '我在棒!包晒美食，晒美食赢取大礼',
            url: shareLink,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        navigator
          .share({
            title: 'BunBao.com voting',
            text: 'BunBao.com voting',
            url: shareLink,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    }
    let newShareLink = votePostObj.voteCampaign.shareTitle
      ? `${votePostObj.voteCampaign.shareTitle[lang]}\n${shareLink}`
      : `BunBao.com Voting\n${shareLink}`;
    if (lang === 'zh') {
      newShareLink = votePostObj.voteCampaign.shareTitle
        ? `${votePostObj.voteCampaign.shareTitle[lang]}\n${shareLink}`
        : `我我在棒!包晒美食，晒美食赢取大礼 \n${shareLink}`;
    }
    await navigator.clipboard.writeText(newShareLink);
    message.success(
      lang === 'zh' ? '分享链接已经复制' : 'The share link is copied'
    );
  };

  handleVoteButton = async (votePostId) => {
    const { votePostObj } = this.state;
    const { history, intl, lang } = this.props;
    try {
      const {
        data: { votePostUpdate },
      } = await apolloClient.mutate({
        mutation: VOTE_POST_UPDATE,
        variables: {
          votePostId,
        },
      });
      console.log(votePostUpdate);
      if (votePostObj._id === votePostUpdate.votePost._id) {
        votePostObj.totalVotes = votePostUpdate.votePost.totalVotes;
      }
      this.setState({
        votePostObj: { ...votePostObj },
      });
      Modal.success({
        title: lang === 'zh' ? '投票成功' : 'Voted',
        content:
          votePostUpdate.discount === null ? (
            <div>{votePostObj.voteCampaign.votedNoticeWithoutBonus[lang]}</div>
          ) : (
            <div>
              {votePostObj.voteCampaign.votedNotice
                ? convert(
                    convertRawToHTML(
                      JSON.parse(
                        votePostObj.voteCampaign.votedNotice[lang] || '{}'
                      )
                    )
                      .replace(
                        '[voteCouponValue]',
                        votePostUpdate.discount.valueType === 'percentage'
                          ? `${votePostUpdate.discount.amount}%`
                          : `$${votePostUpdate.discount.amount}`
                      )
                      .replace(
                        '[userTodayVotedCounter]',
                        votePostUpdate.todayVotedCounter
                      )
                  )
                : 'some messages...some messages...'}
            </div>
          ),
      });
    } catch (err) {
      console.dir(err);
      const error = err.graphQLErrors.length && err.graphQLErrors[0].message;
      if (error === 'Please Log in') {
        message.error(error);
        history.push(getCustomerPath(`login?votePostId=${votePostId}`));
      }
      if (error === 'Invalid Vote') {
        Modal.error({
          title: intl.formatMessage({ id: 'vote.inValidVote' }),
          content: (
            <div>
              {convert(
                convertRawToHTML(
                  JSON.parse(
                    votePostObj.voteCampaign.canNotVoteNotice[lang] || '{}'
                  )
                )
              )}
            </div>
          ),
        });
      }
    }
    await this.handleCloseVoteConfirm();
  };

  handleCloseVoteConfirm = async () => {
    this.setState({
      voteConfirmVisible: false,
    });
  };

  redirectToLoginPage = async () => {
    const { history, saveLinkForLogin } = this.props;
    const { votePostObj } = this.state;
    if (!votePostObj) {
      return;
    }
    await saveLinkForLogin(
      getCustomerPath(`vote-post?votePostId=${votePostObj._id}`)
    );
    history.push(getCustomerPath(`login`));
  };

  render() {
    const {
      votePostObj,
      postStatusModal,
      voteConfirmVisible,
      currentSlideIndex,
    } = this.state;
    const { token, lang } = this.props;
    if (!votePostObj) {
      return null;
    }
    const Arrow = ({ type, style, className, onClick }) => (
      <div
        style={{
          ...style,
          transform: type === 'right' ? 'rotate(-45deg)' : 'rotate(135deg)',
          border: 'solid rgba(0, 0, 0, 0.65)',
          borderWidth: '0 3px 3px 0',
          display: 'inline-block',
          padding: '3px',
        }}
        role="presentation"
        className={className}
        onClick={onClick}
      />
    );
    Arrow.defaultProps = { style: null, className: null, onClick: null };
    Arrow.propTypes = {
      type: PropTypes.string.isRequired,
      style: PropTypes.shape({}),
      className: PropTypes.string,
      onClick: PropTypes.func,
    };
    return (
      <div className="vote-post-space">
        <Modal
          destroyOnClose
          visible={postStatusModal}
          closable={false}
          maskClosable={false}
          title={null}
          footer={
            <Button
              danger
              onClick={() => {
                const { history } = this.props;
                history.push(
                  getCustomerPath(
                    `vote-campaign?voteCampaignId=${votePostObj.voteCampaignId}`
                  )
                );
              }}
            >
              OK
            </Button>
          }
          centered
          zIndex={1010}
        >
          {votePostObj && votePostObj.status !== 'pass' && (
            <div>
              <div>
                <FormattedMessage id="vote.postStatusTip" />{' '}
                {votePostObj.status}
              </div>
              {votePostObj.reason && (
                <div>
                  <FormattedMessage id="vote.postReason" /> {votePostObj.reason}
                </div>
              )}
            </div>
          )}
        </Modal>
        <Modal
          visible={voteConfirmVisible}
          onCancel={this.handleCloseVoteConfirm}
          okText={token ? 'OK' : <FormattedMessage id="loginOrSignUp" />}
          onOk={async () => {
            if (token) {
              await this.handleVoteButton(votePostObj._id);
            } else {
              this.redirectToLoginPage();
            }
          }}
        >
          {token ? (
            <div>
              {votePostObj.voteCampaign.voteAskNotice
                ? votePostObj.voteCampaign.voteAskNotice[lang]
                    .replace(
                      '[votePostTitle]',
                      votePostObj && votePostObj.title ? votePostObj.title : ''
                    )
                    .replace(
                      '[votePostNickname]',
                      votePostObj && votePostObj.nickName
                        ? votePostObj.nickName
                        : ''
                    )
                : 'Are you sure vote this post?'}
            </div>
          ) : (
            <div>
              {votePostObj.voteCampaign.voteAskNoticeWithoutLogin
                ? convert(
                    convertRawToHTML(
                      JSON.parse(
                        votePostObj.voteCampaign.voteAskNoticeWithoutLogin[
                          lang
                        ] || '{}'
                      )
                    )
                  )
                : 'We remember you vote, but you should login or sign up, click button to sign up or login'}
            </div>
          )}
        </Modal>
        {votePostObj.status === 'pass' && (
          <div className="page-container">
            <div
              className="vote-post-container"
              style={{ width: isMobile ? '100%' : '40%' }}
            >
              <div
                style={{
                  width: '100%',
                  borderRadius: '8px',
                }}
              >
                <div className="vote-number-container">
                  <div className="vote-number">{votePostObj.displayId}</div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    <FormattedMessage id="vote.totalVotes" />
                    {votePostObj.totalVotes}
                  </div>
                </div>
                <div className="post-title">{votePostObj.title}</div>

                <div ref={this.boundContainer}>
                  {this.boundContainer.current &&
                    this.boundContainer.current.clientWidth > 30 &&
                    votePostObj.pictureTags.length > 0 &&
                    votePostObj.pictureTags[currentSlideIndex].tags.map(
                      (tag) => (
                        <Draggable
                          disabled
                          key={tag._id}
                          bounds="parent"
                          position={{
                            x:
                              tag.x > -1
                                ? tag.x *
                                  this.boundContainer.current.clientWidth
                                : this.boundContainer.current.clientWidth * 0.5,
                            y:
                              tag.y > -1
                                ? tag.y *
                                  this.boundContainer.current.clientHeight
                                : this.boundContainer.current.clientHeight *
                                  0.5,
                          }}
                        >
                          <div
                            style={{
                              maxWidth: '50%',
                              position: 'absolute',
                              zIndex: '1000',
                              display: 'flex',
                            }}
                          >
                            {console.log(
                              this.boundContainer.current.clientWidth,
                              this.boundContainer.current.clientHeight
                            )}
                            <ImageTags
                              tag={tag}
                              onItemClick={(id) => {
                                const { history } = this.props;
                                history.push(
                                  getCustomerPath(
                                    `delivery-menu?productId=${id}`
                                  )
                                );
                                console.log(id);
                              }}
                            />
                          </div>
                        </Draggable>
                      )
                    )}

                  <Swiper
                    className="vote-post-create-slick-dots"
                    autoHeight
                    pagination={{
                      clickable: true,
                    }}
                    onImagesReady={() => {
                      console.log('all images loaded');
                      this.setState({
                        votePostObj: { ...votePostObj },
                      });
                    }}
                    onSlideChangeTransitionEnd={(index) => {
                      console.log('slide change', index.activeIndex);
                      this.setState({
                        currentSlideIndex: index.activeIndex,
                      });
                    }}
                  >
                    {votePostObj.pictureTags.length === 0 &&
                      votePostObj.pictures.map((pic) => {
                        return (
                          <SwiperSlide
                            key={pic._id}
                            style={{ position: 'relative' }}
                          >
                            <img
                              style={{ width: '100%', borderRadius: '6px' }}
                              src={`${BUCKET_URL}${pic.key}`}
                              alt=""
                            />
                          </SwiperSlide>
                        );
                      })}

                    {votePostObj.pictureTags.length > 0 &&
                      votePostObj.pictureTags.map((picTag) => {
                        return (
                          <SwiperSlide
                            key={picTag._id}
                            style={{ position: 'relative' }}
                          >
                            {/* <div
                            style={{
                              backgroundImage: `url(${BUCKET_URL}${pic.key})`,
                            }}
                            className="vote-post-picture"
                            key={pic._id}
                          /> */}
                            <img
                              style={{ width: '100%', borderRadius: '6px' }}
                              src={`${BUCKET_URL}${picTag.picture.key}`}
                              alt=""
                            />
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <FormattedMessage id="vote.author" />:{votePostObj.nickName}
              </div>
              <div style={{ whiteSpace: 'pre-line', margin: '10px 0px' }}>
                {votePostObj.description}
              </div>

              <div className="button-container">
                <Button
                  onClick={() => {
                    this.handleShareButton(votePostObj._id);
                  }}
                >
                  <div className="flex-center">
                    <img style={{ height: '100%' }} src={shareIcon} alt="" />
                    <FormattedMessage id="vote.share" />
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    // this.handleVoteButton(vote-post._id);
                    if (!token) {
                      sessionStorage.setItem(
                        'pendingVotePostId',
                        votePostObj._id
                      );
                    }
                    this.setState({
                      voteConfirmVisible: true,
                    });
                  }}
                >
                  <div className="flex-center">
                    <img style={{ height: '100%' }} src={Like} alt="" />
                    <FormattedMessage id="vote.vote" />
                  </div>
                </Button>
              </div>
            </div>
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                textDecoration: 'underline',
              }}
            >
              <Link
                to={getCustomerPath(
                  `vote-campaign?voteCampaignId=${votePostObj.voteCampaignId}`
                )}
              >
                <FormattedMessage id="vote.viewAllPosts" />
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}
VotePost.defaultProps = {
  token: null,
};

VotePost.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  token: PropTypes.string,
  saveLinkForLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.language.lang,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(VotePost));
