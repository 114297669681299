import _ from 'lodash';
import * as actionTypes from './actionTypes';

export const clearItems = () => {
  return {
    type: actionTypes.CART_UPDATE_ITEMS,
    items: [],
    customerItems: [],
    cartLastUpdate: new Date().getTime(),
    customerCartLastUpdate: new Date().getTime,
  };
};

export const updateTip = (tip) => async (dispatch) => {
  await dispatch({
    type: actionTypes.CART_UPDATE_TIP,
    tipAmount: tip.tipAmount,
    tipPercentage: tip.tipPercentage,
    tipSelected: tip.tipSelected,
  });
};

export const addItem = (item) => async (dispatch, getState) => {
  const { items } = getState().cart;
  console.log(items);
  const foundItem = items.find(
    (element) => item.variantId === element.variantId
  );
  let newItems = items;
  if (foundItem) {
    newItems = items.map((element) => {
      if (element.variantId === item.variantId) {
        return {
          ...element,
          servesNumber: foundItem.servesNumber + item.servesNumber,
          quantity: foundItem.quantity + item.quantity,
        };
      }
      return element;
    });
    const index = newItems.findIndex(
      (element) => item.variantId === element.variantId
    );
    if (newItems[index].quantity < (newItems[index].minimumQuantity || 0)) {
      console.log('less than minimum quantity');
      newItems.splice(index, 1);
    }
  } else {
    newItems.push(item);
  }

  dispatch({
    type: actionTypes.CART_UPDATE_ITEMS,
    items: newItems,
    cartLastUpdate: new Date().getTime(),
  });
};

export const cleanShoppingCart = (ShoppingCart) => {
  const newShoppingCart = JSON.parse(JSON.stringify(ShoppingCart));
  const remainingAttributes = [
    'count',
    'id',
    'name',
    '_id',
    'imgUrl',
    'listPrice',
    'boxContent',
    'allowedShippingDate',
    'collapsed',
    'inSufficient',
    'inSufficientStock',
    'stock',
    'allowedInventoryCheck',
    'warehouseId',
    'excludeFromFreeShipping',
    'isGroupBuy',
    'currentGroupOrders',
    'groupBuyEndDate',
    'groupBuyStartDate',
    'groupBuyRules',
    'minimumAddQuantity',
  ];
  if (newShoppingCart.productList) {
    newShoppingCart.productList = newShoppingCart.productList.map((variant) => {
      return _.pick(variant, remainingAttributes);
    });
  }
  if (newShoppingCart.boxList) {
    newShoppingCart.boxList = newShoppingCart.boxList.map((box) => {
      box.boxContent = box.boxContent.map((subVariant) => {
        return _.pick(subVariant, remainingAttributes);
      });
      return _.pick(box, remainingAttributes);
    });
    // console.log(newShoppingCart);
  }
  return newShoppingCart;
};

export const updateShoppingCart =
  (newShoppingCart = null) =>
  async (dispatch, getState) => {
    const { shoppingCart } = getState().cart;
    // console.log('shoppingCart called', newShoppingCart);
    let updatedCart;
    if (newShoppingCart) {
      // newShoppingCart = cleanShoppingCart(newShoppingCart);
      // updatedCart = newShoppingCart;
      updatedCart = cleanShoppingCart(newShoppingCart);
    } else if (shoppingCart) {
      updatedCart = cleanShoppingCart(shoppingCart);
    } else {
      updatedCart = {
        // for bunbao use
        productList: [],
        boxList: [],
      };
    }
    dispatch({
      type: actionTypes.CART_UPDATE_SHOPPING_CART,
      shoppingCart: updatedCart,
    });
  };
export const getCurrentWarehouseCart = () => async (dispatch, getState) => {
  const { warehouseId } = await getState().warehouse;
  const { allWarehouseCart } = await getState().cart;
  const emptyCart = {
    productList: [],
    boxList: [],
  };
  if (allWarehouseCart && allWarehouseCart[warehouseId]) {
    return JSON.parse(JSON.stringify(allWarehouseCart[warehouseId]));
  }
  return emptyCart;
};

export const updateWarehouseCart =
  (newWarehouseCart = null) =>
  async (dispatch, getState) => {
    const { warehouseId } = getState().warehouse;
    // const { shoppingCart } = getState().cart;
    // console.log('warehouseCart called!!',shoppingCart)
    // return
    // console.log('my warehouseId', warehouseId);
    // console.log('newWarehouseCart', newWarehouseCart);
    if (newWarehouseCart) {
      if (!newWarehouseCart.boxList) {
        newWarehouseCart.boxList = [];
      }
      if (!newWarehouseCart.productList) {
        newWarehouseCart.productList = [];
      }
    }
    const { allWarehouseCart } = await getState().cart;
    let finalCart = {
      productList: [],
      boxList: [],
    };
    if (warehouseId) {
      if (allWarehouseCart && allWarehouseCart[warehouseId]) {
        if (newWarehouseCart === null) {
          // return fetch result
          finalCart = allWarehouseCart[warehouseId];
        } else {
          // console.log("update cart action ",newWarehouseCart);
          // newWarehouseCart = cleanShoppingCart(newWarehouseCart);
          const updateCart = cleanShoppingCart(newWarehouseCart);
          // update cart
          dispatch({
            type: actionTypes.CART_UPDATE_ALL_WAREHOUSE_CART,
            updateCart,
            updateWarehouseId: warehouseId,
          });
        }
      } else if (newWarehouseCart) {
        dispatch({
          type: actionTypes.CART_UPDATE_ALL_WAREHOUSE_CART,
          updateCart: cleanShoppingCart(newWarehouseCart),
          updateWarehouseId: warehouseId,
        });
      }
    }
    // console.log('all cart', allWarehouseCart);
    // console.log('finalCart', finalCart);
    dispatch({
      type: actionTypes.CART_FETCH_CURRENT_WAREHOUSE_CART,
      warehouseCart: cleanShoppingCart(finalCart),
    });
  };

export const removeItem = (item) => async (dispatch, getState) => {
  const { items } = getState().cart;
  _.remove(items, (n) => n.id === item.id);
  dispatch({
    type: actionTypes.CART_UPDATE_ITEMS,
    items,
    cartLastUpdate: new Date().getTime(),
  });
};
