/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Col, Row, message, Button, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import cardPicture from '../../../../assets/images/cards/icons8-credit-card-filled-100.png';
import visa from '../../../../assets/images/cards/visa.png';
import americanExpress from '../../../../assets/images/cards/americanExpress.png';
import jcb from '../../../../assets/images/cards/jcb.png';
import dinersClub from '../../../../assets/images/cards/dinersClub.png';
import discover from '../../../../assets/images/cards/discover.png';
import unionPay from '../../../../assets/images/cards/unionPay.png';
import masterCard from '../../../../assets/images/cards/masterCard.png';
import { apolloClient } from '../../../../util';
import { DELETE_CARD } from '../../../../apiCanary/user';
import { FETCH_USER_CARD } from '../../../../api/payment';

class PaymentManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCards: [],
    };
  }

  async componentDidMount() {
    this.fetchUserCards();
  }

  getBrandImg = (brand) => {
    switch (brand) {
      case 'American Express':
        return americanExpress;
      case 'Diners Club':
        return dinersClub;
      case 'Discover':
        return discover;
      case 'JCB':
        return jcb;
      case 'MasterCard':
        return masterCard;
      case 'UnionPay':
        return unionPay;
      case 'Visa':
        return visa;
      default:
        return visa;
    }
  };

  fetchUserCards = async () => {
    // return ;
    const { data } = await apolloClient.query({
      query: FETCH_USER_CARD,
      variables: {},
      fetchPolicy: 'network-only',
    });
    if (data && data.userCurrentUserCard) {
      const temp = data.userCurrentUserCard;
      if (temp.length > 0) {
        this.setState({ listCards: temp });
      } else {
        this.setState({ listCards: [] });
      }
    }
  };

  handleDeleteCard = async (cardId) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_CARD,
        variables: { input: cardId },
      });
      console.log(data.cardDelete);
      if (data.paymentDeleteCard === true) {
        message.success('Success!');
        await this.fetchUserCards();
      } else {
        message.error('Error Happened!');
      }
    } catch {
      message.error('Error Happened!');
    }
  };

  render() {
    const { listCards } = this.state;
    return (
      <div css={{ minHeight: '100vh' }}>
        <h4 style={{ fontWeight: 700, textAlign: 'center' }}>
          <img
            src={cardPicture}
            style={{
              width: '30px',
              height: '30px',
              marginBottom: '5px',
              marginTop: '5px',
              marginRight: '6px',
            }}
            alt="info icon"
          />
          <FormattedMessage id="payment" />
        </h4>
        <Row gutter={13}>
          {listCards.map((card) => (
            <Col key={card.id} xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <span className="creditCard">
                  <img
                    src={this.getBrandImg(card.brand)}
                    alt="card logo"
                    style={{ height: '50px' }}
                  />
                  {` **** **** **** ${card.last4}`}
                </span>
                <Popconfirm
                  title={<FormattedMessage id="makeSureDeleteCard" />}
                  onConfirm={() => {
                    this.handleDeleteCard(card.id);
                  }}
                  okText={<FormattedMessage id="yes" />}
                  cancelText={<FormattedMessage id="no" />}
                >
                  <Button style={{ marginLeft: '10px' }}>
                    <FormattedMessage id="delete" />
                  </Button>
                </Popconfirm>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

PaymentManagement.propTypes = {};

const mapStateToProps = (state) => ({
  saleorId: state.auth.saleorId,
});
export default connect(mapStateToProps, null)(PaymentManagement);
