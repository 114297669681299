/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import convert from 'htmr';
import * as QueryString from 'query-string';
import { convertRawToHTML } from 'braft-convert';
import ReactRouterPropTypes from 'react-router-prop-types';
import lodash from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import BraftEditor from 'braft-editor';
import { Pagination } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { apolloClient, getCustomerPath } from '../../../util';
import { ARTICLE_PAGINATION, ARTICLE_FIND_MANY } from '../../../api/article';
import { CATEGORY_QUERY } from '../../../api/category';
import { BUCKET_CDN_URL } from '../../../config';

export class Stories extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      topStories: [],
      stories: [],
      categories: [],
      pageSize: 5,
      pageIndex: 1,
      articleCount: 0,
      categoryId: null,
    };
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const {
      location: { search },
    } = this.props;
    const { categoryId, pageIndex, pageSize } = QueryString.parse(search);
    this.handleCategories();
    if (pageIndex) {
      await this.setState({ pageIndex: Number(pageIndex) });
    }
    if (pageSize) {
      await this.setState({ pageSize: Number(pageSize) });
    }
    if (categoryId) {
      this.setState({ categoryId });
      this.handleFetchStories(categoryId);
    } else {
      this.setState({ categoryId: null });
      this.handleFetchStories();
    }
  };

  componentDidUpdate = async (prevProps) => {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: oldSearch },
    } = prevProps;
    const { categoryId, pageIndex, pageSize } = QueryString.parse(search);
    const {
      categoryId: oldCategoryId,
      pageIndex: oldPageIndex,
      pageSize: oldPageSize,
    } = QueryString.parse(oldSearch);
    if (
      categoryId !== oldCategoryId ||
      pageIndex !== oldPageIndex ||
      pageSize !== oldPageSize
    ) {
      this.handleFetchStories(categoryId, pageIndex, pageSize);
    }
  };

  handleFetchStories = async (categoryId, newPageIndex, newPageSize) => {
    const { pageSize, pageIndex } = this.state;
    const filter = {
      type: 'blog',
      deleted: false,
      _operators: { isTop: { in: [false, null] } },
    };
    const topFilter = {
      type: 'blog',
      deleted: false,
      isTop: true,
    };
    if (categoryId) {
      filter._operators = {
        ...filter._operators,
        categoryIds: { in: categoryId },
      };
      topFilter._operators = {
        ...topFilter._operators,
        categoryIds: { in: categoryId },
      };
      await this.setState({ categoryId });
    } else {
      await this.setState({ categoryId: null });
    }
    if (newPageIndex && pageIndex !== newPageIndex) {
      await this.setState({ pageIndex: Number(newPageIndex) });
    }
    if (newPageSize && pageSize !== newPageSize) {
      await this.setState({ pageSize: Number(newPageSize) });
    }
    const { data } = await apolloClient.query({
      query: ARTICLE_PAGINATION,
      variables: {
        filter,
        page: pageIndex,
        perPage: pageSize,
        sort: 'CREATEDAT_DESC',
      },
      fetchPolicy: 'network-only',
    });
    const { data: topData } = await apolloClient.query({
      query: ARTICLE_FIND_MANY,
      variables: {
        filter: topFilter,
        sort: 'CREATEDAT_DESC',
      },
      fetchPolicy: 'network-only',
    });
    const { articlePagination } = data;
    const { articleMany } = topData;
    console.log(articlePagination);
    // const categoriesMap = new Map();
    if (articlePagination && articlePagination.count > 0) {
      // articlePagination.items.forEach((article) => {
      //   categoriesMap.set(article.category.name, {
      //     _id: article.category._id,
      //     name: article.category.name,
      //     title: article.category.title,
      //     ranking: article.category.ranking,
      //   });
      // });
      // const categoriesArray = lodash.orderBy(
      //   Array.from(categoriesMap.values()),
      //   ['ranking'],
      //   ['desc']
      // );
      this.setState({
        // categories: categoriesArray,
        topStories: articleMany,
        stories: articlePagination.items,
        articleCount: articlePagination.count,
      });
    } else {
      this.setState({ stories: [], articleCount: 0 });
    }
  };

  handleCategories = async () => {
    const response = await apolloClient.query({
      query: CATEGORY_QUERY,
      variables: {
        filter: { type: 'article' },
      },
    });
    const { data } = response;
    // remove faq
    const { categoryMany } = data;
    const categories = [];
    categoryMany.forEach((category) => {
      if (category.name.search('faq ') === -1) {
        categories.push(category);
      }
    });
    const categoriesArray = lodash.orderBy(categories, ['ranking'], ['desc']);
    this.setState({ categories: categoriesArray });
    console.log(categoriesArray);
  };

  onPageIndexAndSizeChangeChange = async (page, pageSize) => {
    const { history } = this.props;
    history.push(
      getCustomerPath(`stories?pageSize=${pageSize}&pageIndex=${page}`)
    );
    await this.setState({ pageIndex: page, pageSize });
    await this.handleFetchStories();
  };

  render() {
    const { language, history } = this.props;
    const {
      topStories,
      stories,
      articleCount,
      pageSize,
      pageIndex,
      categories,
      categoryId,
    } = this.state;

    const categoryRender = (category) => (
      <div
        key={category._id}
        css={{
          height: '140px',
          width: '100px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          margin: '5px',
          cursor: 'pointer',
        }}
      >
        <Link to={getCustomerPath(`stories?categoryId=${category._id}`)}>
          <div>
            <img
              src={
                category.picture
                  ? BUCKET_CDN_URL + category.picture.key
                  : `${BUCKET_CDN_URL}images/logo/BunBaoLogo.png`
              }
              alt={category.name}
              css={{
                height: '90px',
                width: '90px',
                objectFit: 'cover',
                margin: 'auto',
                display: 'block',
              }}
            />
          </div>
          <div css={{ textAlign: 'center', fontWeight: '500' }}>
            {language === 'zh' ? category.title.zh : category.title.en}
          </div>
        </Link>
      </div>
    );

    const articlesRender = (articles) => (
      <div>
        {articles.map((article) => {
          return (
            <div key={article._id} css={{ margin: '10px 0' }}>
              <Link to={getCustomerPath(`story/${article._id}`)}>
                <div
                  css={
                    isMobile
                      ? {
                          fontSize: '22px',
                          fontWeight: 'bold',
                          color: '#000000a6',
                          padding: '0px 10px 0px 10px',
                        }
                      : {
                          fontSize: '25px',
                          fontWeight: 'bold',
                          color: '#000000a6',
                          padding: '0px 10px 0px 10px',
                        }
                  }
                >
                  {language === 'zh' ? article.title.zh : article.title.en}
                </div>
              </Link>
              <div css={{ padding: '5px 10px 0px 10px' }}>
                Published {moment(article.createdAt).format('ll')} on{' '}
                {article.categories.map((category) => (
                  <span key={category._id}>
                    <Link
                      to={getCustomerPath(`stories?categoryId=${category._id}`)}
                      css={{ textDecoration: 'underline' }}
                    >
                      {language === 'zh'
                        ? category.title.zh
                        : category.title.en}
                    </Link>{' '}
                  </span>
                ))}
              </div>
              <div
                key={article._id}
                css={{
                  borderBottom: '1px solid #ccc',
                  padding: '10px 10px 20px 10px',
                }}
              >
                {language === 'zh' ? (
                  <div className="braft-output-content">
                    {BraftEditor.createEditorState(
                      convertRawToHTML(JSON.parse(article.content.zh))
                    )
                      .toText()
                      .substring(0, 140)}
                    ...
                  </div>
                ) : (
                  <div className="braft-output-content">
                    {BraftEditor.createEditorState(
                      convertRawToHTML(JSON.parse(article.content.en))
                    )
                      .toText()
                      .substring(0, 140)}
                    ...
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );

    return (
      <div css={{ minHeight: '100vh' }}>
        <div
          css={
            isMobile
              ? {
                  margin: '10px auto',
                  textAlign: 'center',
                  fontSize: '22px',
                  fontWeight: 'bold',
                }
              : {
                  margin: '19px auto',
                  textAlign: 'center',
                  fontSize: '36px',
                  fontWeight: 'bold',
                }
          }
        >
          {language === 'zh' ? '棒!包的故事' : 'BunBao Stories'}
        </div>
        <div css={{ maxWidth: '800px', padding: '10px', margin: '0px auto' }}>
          <div
            css={{
              userSelect: 'none',
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {categoryId &&
              categories.map((category) =>
                category._id === categoryId ? categoryRender(category) : null
              )}
            {categoryId && (
              <div
                css={{
                  height: '140px',
                  width: '100px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  margin: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.setState({ categoryId: null });
                  history.push(getCustomerPath('stories'));
                }}
                role="presentation"
              >
                <div css={{ textAlign: 'center', padding: '40px 10px' }}>
                  <RollbackOutlined style={{ fontSize: '50px' }} />
                </div>
              </div>
            )}
            {categoryId === null &&
              categories.map((category) => categoryRender(category))}
          </div>
          {articlesRender(topStories)}
          {articlesRender(stories)}
          <div css={{ margin: '20px auto', textAlign: 'center' }}>
            <Pagination
              current={pageIndex}
              pageSize={pageSize}
              total={articleCount}
              showSizeChanger
              onShowSizeChange={this.onPageIndexAndSizeChangeChange}
              onChange={this.onPageIndexAndSizeChangeChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

Stories.propTypes = {
  language: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.language.lang,
});

export default connect(mapStateToProps, null)(Stories);
