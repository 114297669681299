/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { AlipayOutlined, CreditCardOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { Input, Tabs, Modal, Button, message, Checkbox, Space } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import OrderPaymentStripeForm from './OrderPaymentStripeForm/OrderPaymentStripeForm';
import { STRIPE_KEY, HOTJAR_TAGS } from '../../../config';
import AlipayPayment from './AlipayPayment/AlipayPayment';
import {
  getCustomerPath,
  apolloClient,
  checkAlipayUserAgent,
  checkWeChatUserAgent,
} from '../../../util';
import { BALANCE_BY_USER } from '../../../api/balance';
import { CUSTOMER_ORDER_BY_ID } from '../../../api/customerOrder';
import poweredByStripe from '../../../assets/images/icons/poweredByStripe.svg';
import './OrderPaymentForm.less';

const { TabPane } = Tabs;

// import { injectIntl, intlShape } from 'react-intl';

export class OrderPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      customerNote: '',
      validOrder: true,
      stripe: null,
      // disableButtons: false,
      disableTabs: false,
      showCreditCard: true,
      showAlipay: false,
      defaultPaymentTabKey: 'creditCard',
      showAlipayModal: false,
      orderId: null,
      disableAlipayPayButton: false,
      balance: null,
      doesUseBalance: false,
    };
  }

  componentDidMount = async () => {
    // check if browser is alipay
    const { currentShoppingMode, group } = this.props;
    // if (window.Stripe) {
    //   this.setState({
    //     stripe: window.Stripe(STRIPE_KEY()),
    //   });
    // } else {
    //   document.querySelector('#stripe-js').addEventListener('load', () => {
    //     // Create Stripe instance once Stripe.js loads
    //     this.setState({
    //       stripe: window.Stripe(STRIPE_KEY()),
    //     });
    //   });
    // }
    // check user agent
    if (group && group.name !== 'guest') {
      this.fetchCurrentUser();
    }
    const stripe = await loadStripe(STRIPE_KEY());
    this.setState({ stripe });

    console.log(window.navigator.userAgent);
    if (checkWeChatUserAgent()) {
      this.setState({
        showAlipay: false,
        showCreditCard: true,
        defaultPaymentTabKey: 'creditCard',
      });
    } else if (checkAlipayUserAgent()) {
      this.setState({
        showAlipay: true,
        showCreditCard: false,
        defaultPaymentTabKey: 'alipay',
      });
    } else {
      this.setState({
        showAlipay: true,
        showCreditCard: true,
        defaultPaymentTabKey: 'creditCard',
      });
    }
    if (currentShoppingMode === 'donation' || currentShoppingMode === 'eShop') {
      this.setState({ showAlipay: false });
    }
  };

  fetchCurrentUser = async () => {
    const { userId } = this.props;
    const {
      data: { BalanceOne },
    } = await apolloClient.query({
      query: BALANCE_BY_USER,
      variables: {
        filter: { userId },
      },
      fetchPolicy: 'network-only',
    });
    console.log(BalanceOne);
    this.setState({
      balance: BalanceOne.total || 0,
    });
  };

  handleDisableAlipayPayButton = () => {
    this.setState({ disableAlipayPayButton: true });
  };

  handleEnableAlipayPayButton = () => {
    this.setState({ disableAlipayPayButton: false });
  };

  handleDisableTabs = () => {
    this.setState({ disableTabs: true });
  };

  handleEnableTabs = () => {
    this.setState({ disableTabs: false });
  };

  handleShowAlipayModal = () => {
    this.setState({ showAlipayModal: true });
  };

  handleHideAlipayModal = () => {
    this.setState({ showAlipayModal: false });
  };

  handleSetOrderId = (orderId) => {
    this.setState({ orderId });
  };

  handleCheckAlipayOrderStatus = async () => {
    const { orderId } = this.state;
    const { history } = this.props;
    if (orderId) {
      // checkout order status
      const orderResponse = await apolloClient.query({
        query: CUSTOMER_ORDER_BY_ID,
        variables: { id: orderId },
      });
      console.log(orderResponse);
      if (
        orderResponse.data.customerOrderById &&
        orderResponse.data.customerOrderById.paymentStatus === 'paid'
      ) {
        // hotjar record
        if (window.hj) {
          window.hj('tagRecording', [HOTJAR_TAGS.PURCHASE]);
        }
        // should clean up next page
        history.replace(
          getCustomerPath(
            `checkout/order?paymentType=alipay&orderId=${orderId}`
          ),
          { orderId }
        );
      } else {
        // do nothing
        message.warn('Payment did not successfully, please try again');
      }
    }
  };

  // handleSwitchOnChangePayment = (e) => {
  //   if (e.target.value === 'paypal') {
  //     this.setState({
  //       paymentSwitch: 'paypal',
  //     });
  //   } else if (e.target.value === 'alipay') {
  //     this.setState({
  //       paymentSwitch: 'alipay',
  //     });
  //   } else if (e.target.value === 'creditCard') {
  //     this.setState({
  //       paymentSwitch: 'creditCard',
  //     });
  //   }
  // };

  handlePaymentSuccess = async () => {
    // const { handlePaymentSuccess } = this.props;
    // handlePaymentSuccess();
    //  the success function just credit card payment
    const { history, group } = this.props;
    const { orderId } = this.state;
    window.sessionStorage.removeItem('referralCode');
    window.sessionStorage.removeItem('referralOrderId');
    // hotjar record
    if (window.hj) {
      window.hj('tagRecording', [HOTJAR_TAGS.PURCHASE]);
    }
    if (group && group.name === 'guest') {
      history.push(
        getCustomerPath(
          `checkout/order?paymentType=creditCard&orderId=${orderId}`
        ),
        { orderId }
      );
    } else {
      history.push(getCustomerPath('checkout/order?paymentType=creditCard'), {
        orderId,
      });
    }
  };

  render() {
    const {
      intl,
      selectedAddress,
      saleorOrderInput,
      currentShoppingMode,
      checkPriceUpdate,
      payDisabled,
      userEmail,
      group,
      disabledAlipay,
    } = this.props;
    console.log('selectedAddress in payment', selectedAddress);
    const {
      defaultPaymentTabKey,
      customerNote,
      validOrder,
      stripe: loadedStripe,
      disableTabs,
      showCreditCard,
      showAlipay,
      showAlipayModal,
      disableAlipayPayButton,
      balance,
      doesUseBalance,
    } = this.state;

    const renderNote = () => {
      // if (currentShoppingMode === 'donation') {
      //   return null;
      // }
      return (
        <div
          className={isMobile ? 'common-section-card' : null}
          style={isMobile ? {} : { margin: '10px 0' }}
        >
          <div>
            {!isMobile && (
              <div
                css={{
                  backgroundColor: '#DF242F',
                  display: 'inline-block',
                  width: '5px',
                  height: '20px',
                  marginRight: '5px',
                }}
              />
            )}
            <span className={isMobile ? 'common-label' : null}>
              {currentShoppingMode === 'donation' ? (
                <FormattedMessage id="donation.note" />
              ) : (
                <FormattedMessage id="note" />
              )}
            </span>
          </div>
          {isMobile ? <hr className="common-divider-line" /> : null}
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 5 }}
            name="note"
            className="form-control"
            placeholder={
              currentShoppingMode === 'donation'
                ? intl.formatMessage({ id: 'donation.noteForDonation' })
                : intl.formatMessage({ id: 'noteForOrder' })
            }
            value={customerNote !== undefined ? `${customerNote}` : ''}
            style={{ margin: '15px auto', width: '100%' }}
            onChange={(e) => {
              this.setState({ customerNote: e.target.value });
            }}
          />
        </div>
      );
    };
    // saleorOrderInput.input.customerNote = customerNote;
    return (
      <div className="order-payment-form-space">
        <Modal visible={showAlipayModal} footer={null} closable={false}>
          <div css={{ textAlign: 'center' }}>
            <div>
              <AlipayOutlined css={{ fontSize: '30px' }} />
            </div>
            <div
              css={{
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '20px auto',
              }}
            >
              <FormattedMessage id="payment.alipayProcessing" />
            </div>
            <div css={{ margin: '10px auto' }}>
              <div css={{ margin: '10px auto' }}>
                <Button
                  css={{ marginRight: '10px' }}
                  onClick={() => {
                    this.handleCheckAlipayOrderStatus();
                  }}
                >
                  <FormattedMessage id="payment.alipayComplete" />
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={async () => {
                    // first check order status
                    this.handleCheckAlipayOrderStatus();
                    this.setState({
                      showAlipayModal: false,
                      disableTabs: false,
                      disableAlipayPayButton: false,
                    });
                  }}
                >
                  <FormattedMessage id="payment.alipayFailure" />
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {renderNote()}
        <div className={isMobile ? 'common-section-card' : null}>
          <div style={isMobile ? {} : { margin: '10px 0' }}>
            {!isMobile && (
              <div
                css={{
                  backgroundColor: '#DF242F',
                  display: 'inline-block',
                  width: '5px',
                  height: '20px',
                  marginRight: '5px',
                }}
              />
            )}
            <span className={isMobile ? 'common-label' : null}>
              <FormattedMessage id="payment.payment" />{' '}
            </span>

            <img
              src={poweredByStripe}
              alt="Powered by Stripe"
              css={{ height: '25px' }}
            />
          </div>
          {isMobile ? <hr className="common-divider-line" /> : null}
          {group &&
            group.name !== 'guest' &&
            balance >= 0 &&
            currentShoppingMode !== 'eShop' &&
            checkAlipayUserAgent() === false && (
              <div>
                <div>
                  <Space>
                    <FormattedMessage id="payment.doesUseBalance" />
                    <Checkbox
                      onChange={(value) => {
                        this.setState({
                          doesUseBalance: value.target.checked,
                        });
                      }}
                      disabled={balance === 0 || balance === null}
                    />
                    <span>
                      (<FormattedMessage id="payment.currentBalance" />
                      <span>{` $${Number(balance).toFixed(2)}`}</span>)
                    </span>
                  </Space>
                </div>
              </div>
            )}
          <Tabs defaultActiveKey={defaultPaymentTabKey}>
            {showCreditCard && (
              <TabPane
                tab={
                  <span>
                    <CreditCardOutlined /> Credit Card
                  </span>
                }
                key="creditCard"
                disabled={disableTabs}
              >
                {loadedStripe && validOrder ? (
                  <div className="stripe">
                    <Elements stripe={loadedStripe}>
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <OrderPaymentStripeForm
                            stripe={stripe}
                            elements={elements}
                            selectedAddress={selectedAddress}
                            saleorOrderInput={saleorOrderInput}
                            handlePaymentSuccess={this.handlePaymentSuccess}
                            customerNote={customerNote}
                            handleDisableTabs={this.handleDisableTabs}
                            handleEnableTabs={this.handleEnableTabs}
                            checkPriceUpdate={checkPriceUpdate}
                            payDisabled={payDisabled}
                            userEmail={userEmail}
                            handleSetOrderId={this.handleSetOrderId}
                            doesUseBalance={doesUseBalance}
                            balanceTotal={balance || 0}
                          />
                        )}
                      </ElementsConsumer>
                    </Elements>
                  </div>
                ) : null}
              </TabPane>
            )}
            {showAlipay && doesUseBalance !== true && (
              <TabPane
                tab={
                  <span>
                    <AlipayOutlined /> Alipay
                  </span>
                }
                key="alipay"
                disabled={disableTabs || doesUseBalance}
              >
                {loadedStripe && validOrder ? (
                  <AlipayPayment
                    stripe={loadedStripe}
                    selectedAddress={selectedAddress}
                    customerNote={customerNote}
                    handleDisableTabs={this.handleDisableTabs}
                    handleShowAlipayModal={this.handleShowAlipayModal}
                    handleHideAlipayModal={this.handleHideAlipayModal}
                    handleEnableTabs={this.handleEnableTabs}
                    handleSetOrderId={this.handleSetOrderId}
                    disableAlipayPayButton={
                      disableAlipayPayButton || payDisabled || disabledAlipay
                    }
                    handleDisableAlipayPayButton={
                      this.handleDisableAlipayPayButton
                    }
                    handleEnableAlipayPayButton={
                      this.handleEnableAlipayPayButton
                    }
                    userEmail={userEmail}
                    doesUseBalance={doesUseBalance}
                  />
                ) : null}
              </TabPane>
            )}
          </Tabs>
        </div>
        {/* {paymentSwitch === 'creditCard' && validOrder ? (
          <div className="stripe">
          </div>
        ) : null} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.cart.items,
  currentShoppingMode: state.user.currentShoppingMode,
  group: state.user.group,
  userId: state.user.userId,
});

OrderPaymentForm.propTypes = {
  currentShoppingMode: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  saleorOrderInput: PropTypes.shape({
    input: PropTypes.shape({
      customerNote: PropTypes.string,
    }),
  }),
  history: ReactRouterPropTypes.history.isRequired,
  // handlePaymentSuccess: PropTypes.func.isRequired,
  selectedAddress: PropTypes.string,
  checkPriceUpdate: PropTypes.func,
  payDisabled: PropTypes.bool,
  userEmail: PropTypes.string,
  // groupBuyShippingTime: PropTypes.number,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  userId: PropTypes.string.isRequired,
  disabledAlipay: PropTypes.bool,
};
OrderPaymentForm.defaultProps = {
  saleorOrderInput: {},
  selectedAddress: null,
  checkPriceUpdate: () => {},
  payDisabled: false,
  userEmail: null,
  group: null,
  disabledAlipay: false,
  // groupBuyShippingTime: null,
};

// const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, null)(injectIntl(OrderPaymentForm))
);
