/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { getCustomerPath } from '../../../util';

export class CustomerServiceChooseModal extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const { onCloseServiceModal, serviceMode, language } = this.props;
    return (
      <div>
        <div
          css={{
            textAlign: 'center',
            fontWeight: '700',
            fontSize: '20px',
            marginTop: '10px',
          }}
        >
          {serviceMode !== 'map' ? (
            <FormattedMessage id="deliveryQuestion" />
          ) : null}
          {serviceMode === 'map' ? (
            <FormattedMessage id="pickupQuestion" />
          ) : null}
        </div>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: '20px',
            '& .service-block': {
              minWidth: '100px',
              width: isMobile ? '150px' : '200px',
              fontWeight: '700',
              padding: '0px 15px',
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#DF242F',
              textAlign: 'center',
              fontSize: isMobile ? '20px' : '30px',
              fontFamily:
                language === 'zh'
                  ? 'li-xia'
                  : 'Kalam, Futura, "Georgia", "Xin Gothic", "PingFang SC", "Hiragino Sans GB", "Droid Sans Fallback", "Microsoft YaHei", sans-serif',
            },
            '& .service-details': {
              marginTop: '10px',
              textAlign: 'center',
              minWidth: '100px',
              width: isMobile ? '150px' : '190px',
            },
            '& .break': {
              flexBasis: '100%',
              height: '0',
            },
          }}
        >
          <Link to={getCustomerPath('delivery-menu')}>
            <div
              className="service-block"
              role="presentation"
              css={{
                borderRadius: '15px 0 0 15px',
                borderRight: '3px solid white',
              }}
              onClick={() => {
                onCloseServiceModal();
              }}
            >
              24 <FormattedMessage id="hours" />
            </div>
          </Link>
          {serviceMode === 'delivery' && (
            <Link to={getCustomerPath('map')}>
              <div
                className="service-block"
                css={{
                  borderRadius: '0 15px 15px 0',
                  borderLeft: '3px solid white',
                }}
                onClick={() => {
                  onCloseServiceModal();
                }}
                role="presentation"
              >
                30 <FormattedMessage id="minutes" />
              </div>
            </Link>
          )}
          {serviceMode === 'map' && (
            <Tooltip
              placement="bottom"
              title={<FormattedMessage id="comingSoon" />}
            >
              <div
                className="service-block"
                css={{
                  borderRadius: '0 15px 15px 0',
                  borderLeft: '3px solid white',
                  backgroundColor: '#bfbfbf !important',
                }}
                // onClick={() => {
                //   onCloseServiceModal();
                // }}
                role="presentation"
              >
                30 <FormattedMessage id="minutes" />
              </div>
            </Tooltip>
          )}

          <div className="break" />
          <div className="service-details">
            {serviceMode !== 'map' ? (
              <div css={{ textAlign: 'left', paddingRight: '10px' }}>
                <FormattedMessage id="deliveryAnswerFor24Hours" />
              </div>
            ) : null}
            {serviceMode === 'map' ? (
              <div css={{ textAlign: 'left', paddingRight: '10px' }}>
                <FormattedMessage id="pickupAnswerFor24Hours" />
              </div>
            ) : null}
          </div>
          <div className="service-details">
            {serviceMode !== 'map' ? (
              <div css={{ textAlign: 'right', paddingLeft: '10px' }}>
                <FormattedMessage id="deliveryAnswerFor30Minutes" />
              </div>
            ) : null}
            {serviceMode === 'map' ? (
              <div css={{ textAlign: 'right', paddingLeft: '10px' }}>
                <FormattedMessage id="pickupAnswerFor30Minutes" />
              </div>
            ) : null}
          </div>
        </div>
        {/* <div
          css={{
            textAlign: 'center',
            marginTop: '20px',
            fontSize: isMobile ? '20px' : '30px',
          }}
        >
          <Button
            size={isMobile ? 'small' : 'large'}
            type="primary"
            onClick={() => {
              onCloseServiceModal();
            }}
          >
            Continue With Delivery Menu
          </Button>
        </div> */}
      </div>
    );
  }
}

CustomerServiceChooseModal.defaultProps = {
  serviceMode: null,
};

CustomerServiceChooseModal.propTypes = {
  serviceMode: PropTypes.string,
  onCloseServiceModal: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({ language: state.language.lang });

export default connect(mapStateToProps, null)(CustomerServiceChooseModal);
