/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  CheckOutlined,
  LoadingOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Button, Form } from 'antd';
import FormBuilder from 'antd-form-builder';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useState } from 'react';
import { USER_RESET_PASS_CODE_AND_SEND_EMAIL } from '../../../api/user';
import { apolloClient } from '../../../util';
import './SendResetPasswordEmailForm.less';

function SendResetPasswordEmailForm(props) {
  const { intl } = props;

  const [form] = Form.useForm();

  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    const { email } = values;
    setSent(true);
    try {
      const sentMailResponse = await apolloClient.mutate({
        mutation: USER_RESET_PASS_CODE_AND_SEND_EMAIL,
        variables: { email },
      });
      console.log(sentMailResponse);
      setSuccess(true);
    } catch (error) {
      setSent(false);
    }
    // console.log('Submit: ', form.getFieldsValue());
  };

  const meta = {
    columns: 1,
    fields: [
      {
        key: 'email',
        required: true,
        placeholder: intl.formatMessage({ id: 'plsEmail' }),
        rules: [{ type: 'email', message: 'Invalid email address' }],
        widget: 'input',
        disabled: sent,
        widgetProps: {
          prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
        },
      },
    ],
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="desktop-reset-password-form"
      >
        <FormBuilder form={form} meta={meta} />
        <Form.Item>
          <Button
            disabled={sent}
            type="primary"
            htmlType="submit"
            className="custom-button"
          >
            {sent && success === false ? (
              <React.Fragment>
                <LoadingOutlined />{' '}
              </React.Fragment>
            ) : null}
            {sent && success ? (
              <React.Fragment>
                <CheckOutlined />{' '}
              </React.Fragment>
            ) : null}
            {success ? (
              <FormattedMessage id="successSendEmail" />
            ) : (
              <FormattedMessage id="sendEmail" />
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

SendResetPasswordEmailForm.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(SendResetPasswordEmailForm);
