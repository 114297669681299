import gql from 'graphql-tag';

export const SUBSCRIBER_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManySubscriberInput
    $sort: SortFindManySubscriberInput
  ) {
    subscriberPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        zip
        ip
        email
      }
    }
  }
`;

export const SUBSCRIBER_BY_ID = gql`
  query ($id: String!) {
    subscriberById {
      _id
    }
  }
`;

export const SUBSCRIBER_CREATE = gql`
  mutation ($email: String!, $zip: String) {
    subscriberCreate(email: $email, zip: $zip) {
      _id
    }
  }
`;

export const SUBSCRIBER_CREATE_NOTIFICATION = gql`
  mutation ($subscription: JSON!) {
    subscriberCreateNotification(subscription: $subscription) {
      _id
    }
  }
`;

export const SUBSCRIBER_TAG = gql`
  mutation ($email: String!) {
    subscriberTag(email: $email) {
      email
    }
  }
`;
