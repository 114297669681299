/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Typography } from 'antd';
import { isMobile } from 'react-device-detect';

const { Title, Paragraph } = Typography;

export default function Terms() {
  return (
    <div css={isMobile ? { padding: '10px' } : { padding: '30px 50px' }}>
      <Typography>
        <Title css={{ textAlign: 'center' }}>免责说明</Title>
        <Paragraph>
          在浏览这个网页的过程中，意味着您同意并且理解关于这个网页以及网页上内容的使用条款以及相关法律信息。如果您接受并同意不对相关的条款，条件以及公告进行修改，在接受所有条款，条件以及公告的情况下您才可浏览网站。您对这个网页的使用就说明了您对所有条款，条件以及公告的认可同意。BunBao.com
          保留一切对本网页条款，条件以及公告修改的权利。
        </Paragraph>
        <Paragraph>
          请您认真阅读网页中的所有材料，包括所有脚注，法律免责声明，限制或者披露，以及任何版权或者所有权声明。任何应用在部分文件或者材料中的免责声明，限制，披露或者对冲条款与应用在整体材料中的相同，并且将被视作您咨询或者下载中的一部分。
        </Paragraph>
        <Title level={2}>网页所有权与版权</Title>
        <Paragraph>
          BunBao.com的网页是由BunBao.com, INC.
          所拥有。BunBao.com或其授权人对BunBao.com网页中所有附带的截图，信息，材料，用户文档，用户界面，图片，信息安排，相关的软件以及BunBao.com或其授权人通过网页访问的其他专有财产视情况保留所有权。网页的所有权利都归BunBao.com或其授权人所有。该网页仅供您个人非商业使用。您不可以修改，传播，散播，展示，演示，再版，出版，许可，销售，或者模仿网页中的任何信息，软件，产品或者服务。
        </Paragraph>
        <Title level={2}>未经授权访问</Title>
        <Paragraph>
          本网页并没有完全防止未授权的第三方的侵入。在阅读本网页的同时您需意识到在本网页上的任何信息都有可能被未授权的第三方通过网络访问。尽管BunBao.com已经做出了最大的努力来保护网页用户的隐私，但不能保证未授权的第三方无法访问该网页中的信息。在浏览本网页时，您
          认可BunBao.com将不会承担任何通知您未经授权第三方的访问及由于计算机网络传输或通信服务（不局限于互联网）过程中的数据泄露而引起责任。
        </Paragraph>
        <Title level={2}>密码与安全</Title>
        <Paragraph>
          您需要对自己密码的保密以及使用而负责。您的密码是保障自身信息安全的重要途径。若您发现或者相信有未经授权的人获取了您的密码，请您立即与本公司联系。
        </Paragraph>
        <Title level={2}>链接</Title>
        <Paragraph>
          BunBao.com并没有对本网页中无关联的链接进行审查，若存在，BunBao.com将不对这些外部网页或者这些外部网页内的链接所链接到的其他网页的内容所负责。因此您链接到的任何外部网页或者其他网页而引起的风险将由您自行承担。BunBao.com不对其链接中的第三方网页上发表的任何言论产生任何评论或观点。对于第三方网页上的所有内容，BunBao.com也不进行监管、控制和为其背书。BunBao.com对此类网站和言论不承担任何责任。
        </Paragraph>
        <Title level={2}>担保免责声明</Title>
        <Paragraph>
          本公司对本网页以及本网页包含的信息和材料，如文字，图片，软件，链接以及其他由包含“如是“，”可用“字样的其他信息，无论是明示还是默示，不进行任何形式的担保。在法律允许的最大程度下，BunBao.com不对网页以及网页中的任何信息的准确性，妥善性，完整性，可信性，时效性或者可用性承担任何责任，并且不对网页中的错误或者遗漏负责。对适销性，特殊意图的适合程度，非侵权，以及网页中其他任何形式的，明示或暗示的，法定的信息不存在任何形式的担保。本公司对网页中的任何信息修改都无需进行通知。BunBao.com对网页中功能的中断或错误，缺陷的修复以及可能对网页或者服务器造成损害的病毒或者其他有害物质不负任何责任。请注意，某些司法辖区不允许对某些担保的免责，因此上述部分或者全部内容可能不适用于您的情况。
        </Paragraph>
        <Title level={2}>有限责任</Title>
        <Paragraph>
          在任何情况下，BunBao.com都不需要对您的计算机、个人或其他资产的任何形式上的伤害损失进行负责、维修或者更正。这些损失包括但不限于：对网页的连接使用，任何一方无法使用此网页，连接过程中的失败、错误、遗漏、中断、缺失、延迟操作或传输、计算机病毒或线路及系统的问题等等所造成的直接或间接，特殊、偶然或必然的损害或损失。
          即便BunBao.com或它的代表方都意识到服务过程中存在这类型伤害和损失发生的可能性，BunBao.com也不承担责任。
        </Paragraph>
        <Title level={2}>帮助</Title>
        <Paragraph>
          如果您需要关于网页的帮助或者您需要与BunBao.com联系，您可以通过support@bunbao.com与BunBao.com联系。
        </Paragraph>
        <Title level={2}>终止服务</Title>
        <Paragraph>
          BunBao.com可以以任何理由无需提前通知随时终止或者暂停对您的服务并无需承担任何责任，其情况包括但不限于您违反了条款。
          所有根据其性质仍然在终止服务后有效的条款应该在终止服务后仍然有效，包括但不限于，所有权规定，担保免责声明，有限责任赔偿。
          BunBao.com服务中包含链接可能会链接到第三方的网页或者服务，而这些网页或服务不由BunBao.com有限责任公司所拥有或者控制。
          对于第三方网页的内容、隐私权政策或相关服务，BunBao.com既不享有控制权，也不对以上任何一部分负责。您承认并且同意我公司，BunBao.com对任何由于使用网页或者服务中提供的内容，商品或服务所造成的伤害或则损失不负有任何直接或间接的责任。
          BunBao.com强烈的建议您阅读您将访问的任何第三方网页中的条款与条件以及隐私政策。
        </Paragraph>
        <Title level={2}>法律规定</Title>
        <Paragraph>
          这些条款应基于美利坚合众国法律进行管理和解释，而不考虑其法律条款的冲突。
        </Paragraph>
        <Paragraph>
          BunBao.com没有执行任何关于这些条款的权利将不会被视作对这些权利的放弃。如果这些条款当中存在部分被法院判定无效或者不可执行的条款，这些条款中其余的条款仍然有效。这些条款构成了双方之间的完整协议并且取代任何之前的任何双方协议
        </Paragraph>
        <Title level={2}>联系方式</Title>
        <Paragraph>BunBao.com, Inc.</Paragraph>
        <Paragraph>地址：40839 Fremont Blvd, Fremont, CA 94538</Paragraph>
        <Paragraph>邮箱: support@Bunbao.com</Paragraph>
        <Paragraph>如果您对这些条款存在疑问，请与本公司联系。</Paragraph>
        <Paragraph>此版本更新于：2018年2月23日。</Paragraph>
      </Typography>
    </div>
  );
}
