/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import { isMobile } from 'react-device-detect';
import {
  // CalendarOutlined,
  NumberOutlined,
  PhoneOutlined,
  UserOutlined,
  FieldTimeOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, message, Button, Popconfirm, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';
// import { Calendar } from 'antd-mobile';
import * as actions from '../../../store/actions';
// import calendar from '../../assets/images/icons/calendar.svg';
import AddressEditCreateForm from '../../Forms/AddressEditCreateForm/AddressEditCreateForm';
import { apolloClient } from '../../../util';
import {
  UPDATE_ADDRESS,
  CREATE_ADDRESS,
  USER_SET_ADDRESS,
} from '../../../api/address';
// import { FETCH_CAMPAIGN_CONTENT } from '../../api/campaign';
import {
  successIcon,
  questionIcon,
} from '../../SmallComponents/SmallComponents';

const shouldHideSelectButton = (warehouse) => {
  const { region } = warehouse;
  if (region === 'Sacramento') {
    return true;
  }

  return false;
};

export class AddressCard extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      pickAddressSelected: false,
      infoSelected: false,
      // selectedShippingDate: null,
      scheduledPickupNote: null,
      createAddressMode: false,
      selectedContactId: null,
      props: {
        selectedPickupAddress: null,
      },
      // mobileCalendarVisibility: false,
    };
  }

  componentDidMount = async () => {
    await this.checkPreSelectForPickUp();
    // if (node && currentAddressType === 'pickup') {
    //   this.fetchWarehousePickupTime(node._id);
    // }
    // const {
    //   node: { pickupWindow, pickupTimeSlotFormatted },
    // } = this.props;
    // console.log('pickupWindow', pickupWindow);
    // console.log('pickupTimeSlot', pickupTimeSlotFormatted);

    // check node and selected automatically
    const { currentAddressType, addresses } = this.props;
    if (currentAddressType === 'pickup' && addresses.length > 0) {
      this.handleSelectUserInfo(null, true);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { props } = prevState;
    if (nextProps.selectedPickupAddress !== props.selectedPickupAddress) {
      return {
        props: {
          selectedPickupAddress: nextProps.selectedPickupAddress,
        },
        pickAddressSelected: false,
        createAddressMode: false,
      };
    }
    return null;
  }

  // fetchWarehousePickupTime = async (_id) => {
  //   console.log('fire', _id);
  //   try {
  //     const {
  //       data: { campaignContentFindMany },
  //     } = await apolloClient.query({
  //       query: FETCH_CAMPAIGN_CONTENT,
  //       variables: { filter: { warehouseId: _id } },
  //       fetchPolicy: 'network-only',
  //     });
  //     console.log(campaignContentFindMany);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  checkPreSelectForPickUp = async () => {
    const { node, currentCampaignContentId } = this.props;
    if (
      node.campaignContentId &&
      currentCampaignContentId &&
      currentCampaignContentId === node.campaignContentId
    ) {
      await this.setState({
        pickAddressSelected: true,
        scheduledPickupNote: true,
      });
    }
  };

  checkAddressForDelivery = (address) => {
    const { currentAddressType } = this.props;
    if (currentAddressType !== 'delivery') {
      return true;
    }
    if (address.addressOne && address.city && address.zipCode) {
      return true;
    }
    return false;
  };

  // handleDateChange = (date, dateString) => {
  //   this.setState({
  //     selectedShippingDate: dateString,
  //   });
  //   const { node } = this.props;
  //   if (date) {
  //     node.selectedDate = date.valueOf();
  //   }
  // };

  handleClickNextButton = async () => {
    const {
      currentAddressType,
      locationInfo,
      node,
      handleSelectEditAddress,
      handleSelectAddress,
      handleSelectPickupAddress,
      addresses,
    } = this.props;
    const { pickAddressSelected } = this.state;
    // for pickup address
    if (locationInfo && currentAddressType === 'pickup') {
      if (pickAddressSelected === false) {
        if (handleSelectPickupAddress) {
          await handleSelectPickupAddress(node._id);
        }
        await this.setState({ pickAddressSelected: true });
        if (addresses.length === 0) {
          this.setState({ createAddressMode: true });
        }
      } else {
        handleSelectAddress(node);
      }
    } else if (this.checkAddressForDelivery(node)) {
      handleSelectAddress(node);
    } else {
      handleSelectEditAddress(node._id);
    }
  };

  handleSubmitForm = async () => {
    const {
      form,
      editedAddressId,
      fetchMyAddress,
      handleSelectEditAddress,
      // handleCheckZipCode,
      group,
      handleGuestEditAddress,
      handleGuestCreateAddress,
    } = this.props;
    const { createAddressMode } = this.state;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const { newAddress } = values;
      let newAddressId;
      try {
        if (createAddressMode === true) {
          const copyNewAddress = JSON.parse(JSON.stringify(newAddress));
          const { data } = await apolloClient.mutate({
            mutation: CREATE_ADDRESS,
            variables: { input: copyNewAddress },
          });
          const { _id } = data.addressCreateOne.record;

          if (_id && !(group && group.name === 'guest')) {
            newAddressId = _id;
            const response = await apolloClient.mutate({
              mutation: USER_SET_ADDRESS,
              variables: { id: newAddressId },
            });
            if (response.data) {
              // await this.setState({
              //   createdAddressObject: data.addressCreateOne.record,
              // });
              await fetchMyAddress();
              await this.handleSelectUserInfo(data.addressCreateOne.record);
              await this.setState({ createAddressMode: false });
            }
          } else if (_id && group && group.name === 'guest') {
            handleGuestCreateAddress(data.addressCreateOne.record);
            await this.handleSelectUserInfo(data.addressCreateOne.record);
            await this.setState({ createAddressMode: false });
          } else {
            message.error('error2 creating address');
            return;
          }
        } else {
          const { data } = await apolloClient.mutate({
            mutation: UPDATE_ADDRESS,
            variables: { input: newAddress, _id: editedAddressId },
          });
          if (data.addressUpdateOne) {
            if (!(group && group.name === 'guest')) {
              handleSelectEditAddress();
              await fetchMyAddress();
            } else {
              handleSelectEditAddress();
              handleGuestEditAddress(data.addressUpdateOne.record);
            }
            message.success('success');
          }
        }
      } catch (error) {
        console.error(error);
        message.success('error');
      }
    });
  };

  handleSelectInfo = (event) => {
    // const _id = event.target.value;
    const _id = event;
    this.setState({ selectedContactId: _id });
    const { node, addresses } = this.props;
    const find = _.find(
      _.uniqBy(addresses, 'firstName', 'lastName', 'phoneNumber'),
      (address) => {
        return address._id === _id;
      }
    );
    node.lastName = find.lastName;
    node.firstName = find.firstName;
    node.phoneNumber = find.phoneNumber;
    // console.log('new node', node);
    this.setState({ infoSelected: true });
  };

  handleSelectUserInfo = (addressObject, chooseFirstOne = false) => {
    const { node, addresses } = this.props;
    let find;
    if (chooseFirstOne === false) {
      find = _.find(
        _.uniqBy(addresses, 'firstName', 'lastName', 'phoneNumber'),
        (address) => {
          return (
            address.firstName === addressObject.firstName &&
            address.lastName === addressObject.lastName &&
            address.phoneNumber === addressObject.phoneNumber
          );
        }
      );
    }
    if (addresses.length > 0 && chooseFirstOne) {
      [find] = _.uniqBy(addresses, 'firstName', 'lastName', 'phoneNumber');
    }
    if (chooseFirstOne === false) {
      this.setState({ selectedContactId: addressObject._id });
    } else {
      this.setState({ selectedContactId: find._id });
    }

    node.lastName = find.lastName;
    node.firstName = find.firstName;
    node.phoneNumber = find.phoneNumber;
    // console.log('new node', node);
    this.setState({ infoSelected: true });
  };

  handleDeleteAddress = async (_id) => {
    const { fetchMyAddress } = this.props;
    try {
      const input = { deleted: true };
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_ADDRESS,
        variables: { input, _id },
      });

      if (data.addressUpdateOne) {
        await fetchMyAddress();
        message.success('success');
      }
    } catch (error) {
      console.error(error);
      message.warn('error');
    }
  };

  getButtonDisabled = () => {
    const {
      pickAddressSelected,
      // selectedShippingDate,
      infoSelected,
    } = this.state;
    const { locationInfo, currentAddressType } = this.props;
    if (
      locationInfo &&
      currentAddressType === 'pickup' &&
      pickAddressSelected === true &&
      infoSelected === false
      // (!selectedShippingDate || infoSelected === false)
    ) {
      return true;
    }
    return false;
  };

  getDisableDate = (current) => {
    const {
      node: { pickupWindow, prepareDay, endDate },
      onlyAllowedDate,
    } = this.props;
    // console.log('onlyAllowedDate in card', onlyAllowedDate);
    if (current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      if (
        window.location.host === 'test.bunbao.com' ||
        process.env.NODE_ENV === 'development'
      ) {
        return false;
      }
      return true;
    }
    if (onlyAllowedDate !== null) {
      // console.log('onlyAllowedDate',onlyAllowedDate);
      const findDate = _.find(onlyAllowedDate, (date) => {
        return date === moment(current).format('YYYY-MM-DD');
      });
      if (!findDate) {
        return true;
      }
    }
    let disable = true;
    const actualPrepareDay = prepareDay || 0;

    pickupWindow.forEach((element) => {
      if (element.day === current.weekday()) {
        disable = false;
      }
    });
    // default two weeks or end date
    if (
      current.isAfter(moment().add(28, 'days')) ||
      (endDate && current.isAfter(moment(endDate)))
    ) {
      disable = true;
    }
    if (moment().add(actualPrepareDay, 'days').isAfter(current)) {
      disable = true;
    }
    return disable;
  };

  render() {
    const {
      currentAddressType,
      node,
      editedAddressId,
      intl,
      form,
      handleSelectEditAddress,
      locationInfo,
      addresses,
    } = this.props;
    const {
      pickAddressSelected,
      // selectedShippingDate,
      infoSelected,
      scheduledPickupNote,
      createAddressMode,
      selectedContactId,
      // mobileCalendarVisibility,
    } = this.state;
    const addressContentCss = {
      minHeight: '200px',
      width: '100%',
      border: '1px solid #f2f2f2',
      padding: '0px 15px 15px 15px',
      position: 'relative',
      background: 'white',
      backgroundClip: 'padding-box',
    };
    const pickUpAddressCss = {
      minHeight: '150px',
      width: '100%',
      border: '1px solid #f2f2f2',
      padding: '0px 15px 15px 15px',
      position: 'relative',
      background: 'white',
      backgroundClip: 'padding-box',
    };
    const buttonDisable = this.getButtonDisabled();
    // edit address mode and add address mode
    if (editedAddressId === node._id) {
      return (
        <div>
          <Form
            layout="inline"
            css={{
              '& .ant-legacy-form-item': {
                marginRight: '0',
              },
              '& .has-feedback': { width: '100%' },
            }}
          >
            <AddressEditCreateForm
              form={form}
              editAddress={node}
              handleSubmitForm={this.handleSubmitForm}
              handleCancelForm={handleSelectEditAddress}
            />
          </Form>
        </div>
      );
    }
    if (createAddressMode === true) {
      return (
        <div>
          <Form
            layout="inline"
            css={{
              '& .ant-legacy-form-item': {
                marginRight: '0',
              },
              '& .has-feedback': { width: '100%' },
            }}
          >
            <AddressEditCreateForm
              form={form}
              handleSubmitForm={this.handleSubmitForm}
              handleCancelForm={() => {
                this.setState({ createAddressMode: false });
              }}
            />
          </Form>
        </div>
      );
    }

    // =====render function part
    const renderCssObject = () => {
      if (
        locationInfo &&
        currentAddressType === 'pickup' &&
        pickAddressSelected === false
      ) {
        return pickUpAddressCss;
      }
      return addressContentCss;
    };

    const renderPickupTimeAndAddressSelection = () => {
      if (locationInfo === true && currentAddressType === 'pickup') {
        if (pickAddressSelected === false) {
          return null;
        }
        return (
          <React.Fragment>
            <div
              css={{
                marginTop: '5px',
                marginBottom: '10px',
                fontWeight: '600',
              }}
            >
              <FormattedMessage id="selectYouInformation" />{' '}
              {infoSelected ? successIcon : questionIcon}
            </div>
            <div>
              <Select
                onChange={this.handleSelectInfo}
                value={selectedContactId}
              >
                {_.uniqBy(
                  addresses,
                  'firstName',
                  'lastName',
                  'phoneNumber'
                ).map((address) => (
                  <Select.Option value={address._id} key={address._id}>
                    {`${address.firstName} 
                   ${address.lastName} `}
                    {` ${address.phoneNumber.replace('+1', '')}`}
                  </Select.Option>
                ))}
              </Select>
              {/* <Radio.Group
                onChange={this.handleSelectInfo}
                value={selectedContactId}
              >
                {_.uniqBy(
                  addresses,
                  'firstName',
                  'lastName',
                  'phoneNumber'
                ).map((address) => (
                  <Radio value={address._id} key={address._id}>
                    <UserOutlined />
                    {`${address.firstName}  
                   ${address.lastName} `}
                    <PhoneOutlined />
                    {` ${address.phoneNumber.replace('+1', '')}`}
                  </Radio>
                ))}
              </Radio.Group> */}
              <div>
                {/* <div>
                  <FormattedMessage id="noConInfo" />:
                </div> */}
                <div css={{ marginTop: '10px' }}>
                  <Button
                    type={addresses.length === 0 ? 'primary' : 'default'}
                    size={addresses.length === 0 ? 'default' : 'small'}
                    onClick={() => {
                      this.setState({ createAddressMode: true });
                    }}
                  >
                    {' '}
                    <FormattedMessage id="addConPickup" />
                  </Button>
                </div>
              </div>
            </div>
            {/* selected Date function */}
            {/* <Row style={{ marginTop: '10px' }}>
              <Divider>
                <img
                  alt="11"
                  src={calendar}
                  css={{ width: '30px', marginRight: '2px' }}
                />
                <FormattedMessage id="selectYouPickupDate" />
                {selectedShippingDate ? successIcon : questionIcon}
              </Divider>
            </Row>
            {node.pickupTimeSlotFormatted.map((element) => (
              <div
                css={{ fontFamily: 'monospace', textAlign: 'center' }}
                key={element}
              >
                {element}
              </div>
            ))}
            <div css={{ marginTop: '8px', textAlign: 'center' }}>
              {isMobile ? (
                <div
                  onClick={() => {
                    this.setState({ mobileCalendarVisibility: true });
                  }}
                  role="presentation"
                >
                  {node.selectedDate ? (
                    <Button type="primary">
                      <CalendarOutlined />{' '}
                      {moment(node.selectedDate).format('YYYY-MM-DD')}
                    </Button>
                  ) : (
                    <Button type="primary">
                      <CalendarOutlined />{' '}
                      <FormattedMessage id="pleaseChooseDate" />
                    </Button>
                  )}
                </div>
              ) : (
                <DatePicker
                  onChange={this.handleDateChange}
                  disabledDate={this.getDisableDate}
                  placeholder={intl.formatMessage({
                    id: 'selectYouPickupDate',
                  })}
                  showToday={false}
                />
              )}
            </div> */}
          </React.Fragment>
        );
      }
      return null;
    };

    const renderName = () => {
      if (locationInfo === true && currentAddressType === 'pickup') {
        if (pickAddressSelected === false) {
          return null;
        }
        return null;
      }
      return (
        <div>
          <div css={{ marginTop: '5px', fontWeight: '600' }}>
            <FormattedMessage id="contactInfo" />
          </div>
          <div css={{ display: 'flex' }}>
            <div css={{ margin: '10px 0', padding: '0px 5px' }}>
              <UserOutlined /> {`${node.firstName} ${node.lastName}`}
            </div>
            <div css={{ margin: '10px 0', padding: '0px 5px' }}>
              <PhoneOutlined /> {`${node.phoneNumber.replace('+1', '')}`}
            </div>
          </div>
        </div>
      );
    };
    const renderAddress = () => {
      const { language } = this.props;
      if (locationInfo === false && currentAddressType === 'pickup') {
        return null;
      }
      return (
        <div>
          <div>
            {node.addressOne ? (
              <React.Fragment>
                <div>
                  <div
                    css={{
                      marginTop: '5px',
                      marginBottom: '10px',
                      fontWeight: '600',
                    }}
                  >
                    {currentAddressType === 'delivery' ? (
                      <FormattedMessage id="deliveryAddress" />
                    ) : (
                      <span>
                        {node.title && node.title.en === 'BunBao' ? (
                          node.title[language]
                        ) : (
                          <span>
                            {node.type === 'company' || node.type === undefined
                              ? `${intl.formatMessage({ id: 'bunbaoBooth' })}@${
                                  node.title[language] || ''
                                } `
                              : `${
                                  language === 'zh'
                                    ? '团长自取点: '
                                    : '"Group Leader" Pickup: '
                                }${node.title[language]}`}
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                  <div css={{ display: 'grid', grid: '1fr / 1fr auto' }}>
                    <div>
                      {node.addressOne} {node.addressTwo}, {node.city},{' '}
                      {node.zipCode}
                      {node.gateCode ? (
                        <span>
                          <NumberOutlined /> Gate Code: {node.gateCode}
                        </span>
                      ) : null}
                    </div>
                    <div style={{ color: '#adadad' }}>
                      {node.distance
                        ? `${Number(node.distance).toFixed(1)}mi`
                        : null}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      );
    };

    const renderButtonSet = () => {
      if (locationInfo === true && currentAddressType === 'pickup') {
        return null;
      }
      return (
        <React.Fragment>
          <div
            style={{
              color: '#337ab7',
              position: 'absolute',
              bottom: '10px',
              left: '10px',
            }}
          >
            <Row type="flex" justify="start">
              <Col>
                <Button
                  style={{ marginRight: '5px' }}
                  size="small"
                  onClick={() => {
                    handleSelectEditAddress(node._id);
                  }}
                >
                  {intl.formatMessage({ id: 'edit' })}
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  title={intl.formatMessage({ id: 'deleteConfirm' })}
                  onConfirm={() => {
                    this.handleDeleteAddress(node._id);
                  }}
                  okText={intl.formatMessage({ id: 'yes' })}
                  cancelText={intl.formatMessage({ id: 'no' })}
                >
                  <Button size="small">
                    {intl.formatMessage({ id: 'delete' })}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
    };
    return (
      <div css={renderCssObject()}>
        {/* <Calendar
          type="one"
          visible={mobileCalendarVisibility}
          onCancel={() => {
            this.setState({ mobileCalendarVisibility: false });
          }}
          onConfirm={(date) => {
            this.handleDateChange(
              moment(date),
              moment(date).format('YYYY-MM-DD')
            );
            this.setState({ mobileCalendarVisibility: false });
          }}
          getDateExtra={(current) => {
            // console.log(current);
            const res = this.getDisableDate(moment(current));
            if (res === true) {
              return { info: 'Disable', disable: true };
            }
            return false;
          }}
        /> */}
        <div
          style={{
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          {scheduledPickupNote ? (
            <span
              style={{
                border: '1px dashed #dfdfdf',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'red',
              }}
            >
              <FormattedMessage id="yourPreSelectedAddress" />
            </span>
          ) : null}
        </div>
        {/* <div css={{ fontWeight: '700', margin: '10px 0' }}>
          {renderTitles()}
        </div> */}
        <div>
          <div>{renderName()}</div>
          <div>{renderAddress()}</div>
        </div>

        {/* {currentAddressType === 'delivery' ? (
          <div css={{ margin: '10px 0', fontSize: '12px', color: 'red' }}>
            <FormattedMessage id="inPerson" />
          </div>
        ) : null} */}

        {currentAddressType === 'pickup' && node.pickupTimeSlotFormatted && (
          <div>
            <div
              css={{
                marginTop: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
              }}
            >
              <FieldTimeOutlined css={{ marginRight: '2px' }} />
              <FormattedMessage id="pickupTimeWindow" />
            </div>
            {node.pickupTimeSlotFormatted.map((element) => (
              <div css={{ fontFamily: 'monospace' }} key={element}>
                {element}
              </div>
            ))}
          </div>
        )}
        {renderPickupTimeAndAddressSelection()}
        {renderButtonSet()}
        {pickAddressSelected === true ? (
          <div
            style={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <Button
              onClick={() => {
                this.setState({ pickAddressSelected: false });
              }}
            >
              {/* <FormattedMessage id="collapse" />{' '} */}
              <CloseOutlined />
            </Button>
          </div>
        ) : null}

        {!shouldHideSelectButton(node) && (
          <div
            style={{
              color: '#337ab7',
              textAlign: 'center',
              marginTop: '8px',
              position: 'absolute',
              bottom: '10px',
              right: '15px',
            }}
          >
            <Button
              type="primary"
              disabled={buttonDisable}
              onClick={this.handleClickNextButton}
            >
              {currentAddressType !== 'pickup' ? (
                <span>
                  {this.checkAddressForDelivery(node)
                    ? intl.formatMessage({ id: 'nextStep' })
                    : intl.formatMessage({ id: 'completeIt' })}
                </span>
              ) : (
                <span>
                  {pickAddressSelected === true ? (
                    <FormattedMessage id="nextStep" />
                  ) : (
                    <FormattedMessage id="choose" />
                  )}
                </span>
              )}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

AddressCard.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired })
    .isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  node: PropTypes.shape({
    pickupWindow: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string,
    selectedDate: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    gateCode: PropTypes.string,
    pickupTimeSlotFormatted: PropTypes.arrayOf(PropTypes.string),
    prepareDay: PropTypes.number,
    endDate: PropTypes.number,
    campaignContentId: PropTypes.string,
    title: PropTypes.shape({
      zh: PropTypes.string,
      en: PropTypes.string,
    }),
    type: PropTypes.string,
    distance: PropTypes.number,
  }).isRequired,
  currentAddressType: PropTypes.string.isRequired,
  locationInfo: PropTypes.bool.isRequired,
  handleSelectEditAddress: PropTypes.func.isRequired,
  handleSelectAddress: PropTypes.func.isRequired,
  editedAddressId: PropTypes.string,
  fetchMyAddress: PropTypes.func.isRequired,
  // handleCheckZipCode: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape({})),
  onlyAllowedDate: PropTypes.arrayOf(PropTypes.string),
  currentCampaignContentId: PropTypes.string,
  language: PropTypes.string.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  handleGuestEditAddress: PropTypes.func,
  handleGuestCreateAddress: PropTypes.func,
  selectedPickupAddress: PropTypes.string,
  handleSelectPickupAddress: PropTypes.func.isRequired,
};
AddressCard.defaultProps = {
  editedAddressId: null,
  addresses: [],
  onlyAllowedDate: null,
  currentCampaignContentId: null,
  handleGuestEditAddress: () => {},
  handleGuestCreateAddress: () => {},
  selectedPickupAddress: null,
  group: null,
};
const mapStateToProps = (state) => ({
  currentCampaignContentId: state.user.currentCampaignContentId,
  currentAddressType: state.user.currentAddressType,
  language: state.language.lang,
  group: state.user.group,
});
const mapDispatchToProps = (dispatch) => ({
  setUserAddressType: (input) => dispatch(actions.setUserAddressType(input)),
});
export default Form.create({ name: 'customer_address_from' })(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressCard))
);
