import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row, Table, Tag } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { colorGenerator, apolloClient } from '../../../../util';
import { ORDER_CONFIRM, ORDER_FINALIZE } from '../../../../api/order';
// import CateringOrderBasicForm from '../../../../components/Forms/CateringOrderBasicForm/CateringOrderBasicForm';

const { Column } = Table;

export class CateringOrderCompany extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loadingButton: false,
    };
  }

  handlePayOrder = async (orderId) => {
    this.setState({ loadingButton: true });
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_CONFIRM,
        variables: { orderId },
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await apolloClient.mutate({
        mutation: ORDER_FINALIZE,
        variables: { orderId },
      });
      console.log(response);
      // directly open new tab for payment
      window.open(response.data.orderFinalize.invoiceUrl, '_blank');
    } catch (error) {
      console.error(error);
    }
    this.setState({ loadingButton: false });
  };

  render() {
    const { order } = this.props;
    const { loadingButton } = this.state;
    const deliveryFee = (record) =>
      record.deliveryFee ? record.deliveryFee.fee : 0;
    return (
      <div>
        {order !== null ? (
          <>
            <Row>
              <Col>
                Order Detail{' '}
                <Tag color={colorGenerator('company')}>Company</Tag>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col>
                {order !== null ? (
                  <>
                    <Descriptions bordered column={3}>
                      <Descriptions.Item label="Name" span={1}>
                        {order.contactName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Phone Number" span={2}>
                        {order.contactPhoneNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Delivery Date" span={2}>
                        {`${moment(order.scheduleDate).format(
                          'YYYY-MM-DD HH:mm'
                        )}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Headcount" span={1}>
                        {order.headcount}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address" span={3}>
                        <div>{order.addressOne}</div>
                        <div>{order.addressTwo}</div>
                        <div>{order.city}</div>
                        <div>{order.zip}</div>
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col span={24}>
                {order !== null ? (
                  <Table
                    dataSource={order.products}
                    rowKey={(record) => record._id}
                    footer={() => (
                      <Row
                        type="flex"
                        justify="end"
                        style={{ textAlign: 'right' }}
                      >
                        <Col span={24} style={{ margin: '3px 0px' }}>
                          {`Subtotal: $${Number(order.subtotal).toFixed(2)}`}
                        </Col>
                        <Col span={24} style={{ margin: '3px 0px' }}>
                          {`Tax: $${(
                            Number(order.subtotal) * Number(order.taxRate / 100)
                          ).toFixed(2)}`}
                        </Col>
                        <Col span={24} style={{ margin: '3px 0px' }}>
                          {`Delivery Fee: $${Number(deliveryFee(order)).toFixed(
                            2
                          )}`}
                        </Col>
                        <Col span={24} style={{ margin: '3px 0px' }}>
                          {`Total: $${(
                            Number(order.subtotal) +
                            Number(order.subtotal) *
                              Number(order.taxRate / 100) +
                            Number(deliveryFee(order))
                          ).toFixed(2)}`}
                        </Col>
                      </Row>
                    )}
                    pagination={false}
                  >
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column
                      title="Quantity"
                      dataIndex="quantity"
                      key="quantity"
                    />
                    <Column title="Price" dataIndex="price" key="price" />
                    <Column
                      title="Total"
                      key="total"
                      render={(text, record) => (
                        <div>{(record.quantity * record.price).toFixed(2)}</div>
                      )}
                    />
                  </Table>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                {order.invoiceUrl ? (
                  <Button
                    type="primary"
                    disabled={order.paid}
                    onClick={() => {
                      window.open(order.invoiceUrl, '_blank');
                    }}
                  >
                    {order.paid ? 'Paid' : 'Pay'}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    disabled={order.paid || loadingButton}
                    onClick={() => {
                      this.handlePayOrder(order._id);
                    }}
                  >
                    {loadingButton ? <LoadingOutlined /> : null}
                    {order.paid ? 'Paid' : 'Pay'}
                  </Button>
                )}
              </Col>
              <Col>
                {order.invoicePDF ? (
                  <Button
                    onClick={() => {
                      window.open(order.invoicePDF, '_blank');
                    }}
                  >
                    Open DPF
                  </Button>
                ) : null}
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    );
  }
}

CateringOrderCompany.defaultProps = {
  order: null,
};

CateringOrderCompany.propTypes = {
  order: PropTypes.shape({
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    zip: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    paid: PropTypes.bool,
    invoicePDF: PropTypes.string,
    invoiceUrl: PropTypes.string,
    contactName: PropTypes.string,
    scheduleDate: PropTypes.number,
    city: PropTypes.string,
    taxRate: PropTypes.number,
    _id: PropTypes.string,
    subtotal: PropTypes.number,
    headcount: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default CateringOrderCompany;
