/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import moment from 'moment';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Empty, Alert, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
// import * as actions from '../../../../store/actions';
import {
  FETCH_MY_CAMPAIGN_CONTENT,
  FETCH_CAMPAIGN_RULE,
} from '../../../../api/campaign';
import { apolloClient } from '../../../../util';
// import { groupInfoBox } from '../../../../components/SmallComponents/SmallComponents';
import GroupInfoBox from '../../../../components/Others/GroupInfoBox/GroupInfoBox';

export class MyGroupBuy extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      data: [],
      campaignOpening: [],
      campaignNotOpening: [],
      discountRule: null,
      loading: true,
    };
  }

  componentDidMount = async () => {
    const { token } = this.props;
    if (token) {
      await this.fetchCampaign();
    }
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchCampaign = async () => {
    await this.setState({ loading: true });
    try {
      const {
        data: { campaignFindMany },
      } = await apolloClient.query({
        query: FETCH_CAMPAIGN_RULE,
        variables: { filter: { type: 'companyGroupBuy' } },
        fetchPolicy: 'network-only',
      });
      const sortRank = _.sortBy(campaignFindMany[0].discountRank, [
        'participatorAmount',
      ]);
      console.log('sortRank', sortRank);
      await this.setState({
        discountRule: sortRank,
      });
    } catch (error) {
      console.error(error);
      message.error('error fetching my campaign');
    }
    try {
      const { data } = await apolloClient.query({
        query: FETCH_MY_CAMPAIGN_CONTENT,
        variables: {
          sort: 'CREATEDAT_DESC',
          filter: { campaignType: 'companyGroupBuy' },
        },
        fetchPolicy: 'network-only',
      });
      const campaignContents = data.campaignContentFindMine;
      const activeCampaigns = campaignContents.filter((campaign) => {
        return campaign.status === 'active';
      });
      const inactiveCampaigns = campaignContents.filter((campaign) => {
        return campaign.status !== 'active';
      });
      console.log('activeCampaigns', activeCampaigns);
      console.log('inactiveCampaigns', inactiveCampaigns);
      await this.setState({
        data: data.campaignContentFindMine,
        campaignOpening: activeCampaigns,
        campaignNotOpening: inactiveCampaigns,
      });
    } catch (error) {
      console.error(error);
      message.error('error fetching my campaign content');
    }
    await this.setState({ loading: false });
  };

  render() {
    const { data, discountRule, loading, campaignOpening, campaignNotOpening } =
      this.state;
    const { language } = this.props;
    // const { userId, saveLinkForLogin } = this.props;
    return (
      <React.Fragment>
        {loading && (
          <div css={{ textAlign: 'center', marginTop: '50px' }}>
            <Spin indicator={<LoadingOutlined css={{ fontSize: '60px' }} />} />
          </div>
        )}
        {((loading === false && data.length === 0) ||
          discountRule === null) && (
          <React.Fragment>
            <div css={{ margin: '10px 10px 20px 10px' }}>
              <Alert
                message={
                  language === 'zh'
                    ? '还没有用过团购？'
                    : "Haven't tried bulk shopping before?"
                }
                description={
                  language === 'zh'
                    ? '点击上方“开新团”，创建你的团购吧！'
                    : ' Start your first group buy order now. More participants, more discounts.'
                }
                type="info"
              />
            </div>
            <Empty />
          </React.Fragment>
        )}
        {loading === false && data.length > 0 && (
          <React.Fragment>
            <div css={{ margin: '10px 10px' }}>
              <Alert
                message={
                  language === 'zh'
                    ? '想解锁下一级折扣吗？'
                    : 'To unlock next discount'
                }
                description={
                  language === 'zh'
                    ? '点击下方的“复制”按钮，将团购链接分享给你的朋友吧！'
                    : 'Click "Share" to invite your friends!'
                }
                type="info"
              />
            </div>
            {campaignOpening.length > 0 && (
              <React.Fragment>
                <div
                  css={{
                    fontSize: '20px',
                    width: '90%',
                    margin: 'auto',
                    padding: '20px 10px',
                    fontWeight: 'bold',
                  }}
                >
                  <FormattedMessage id="myOpeningGroupBuying" />
                </div>
                <div
                  css={{
                    display: 'flex',
                    padding: '10px',
                    flexDirection: isMobile ? 'column' : 'row',
                    height: '100%',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {campaignOpening.map((element) => {
                    return (
                      <GroupInfoBox
                        key={element._id}
                        element={element}
                        discountRule={discountRule}
                        // userId={userId}
                        // saveLinkForLogin={saveLinkForLogin}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            )}
            <React.Fragment>
              <div
                css={{
                  fontSize: '20px',
                  width: '90%',
                  margin: 'auto',
                  padding: '20px 10px',
                  fontWeight: 'bold',
                }}
              >
                <FormattedMessage id="myEndedGroupBuying" />
              </div>
              <div
                css={{
                  display: 'flex',
                  padding: '10px',
                  flexDirection: isMobile ? 'column' : 'row',
                  height: '100%',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {campaignNotOpening.map((element) => {
                  return (
                    <GroupInfoBox
                      key={element._id}
                      element={element}
                      discountRule={discountRule}
                      // userId={userId}
                      // saveLinkForLogin={saveLinkForLogin}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

MyGroupBuy.propTypes = {
  token: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

MyGroupBuy.defaultProps = {};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  token: state.auth.token,
  language: state.language.lang,
});

// const mapDispatchToProps = (dispatch) => ({
//   saveLinkForLogin: (link) => dispatch(actions.saveLinkForLogin(link)),
// });

export default connect(mapStateToProps, null)(injectIntl(MyGroupBuy));
// export default MyGroupBuy;
