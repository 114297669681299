import gql from 'graphql-tag';

export const CUSTOMER_ORDER_FRAGMENT = gql`
  fragment CUSTOMER_ORDER_FRAGMENT on CustomerOrder {
    id
    _id
    addressId
    taxRate
    balance
    customerNotes
    displayId
    bunbaoType
    paymentStatus
    isIncludedGroupBuyVariants
    payment {
      _id
      id
      paymentType
    }
    discount {
      _id
      name {
        _id
        zh
        en
      }
    }
    appliedCode
    createdAt
    clientSecret
    commissionFee
    warehouseEarning
    refundVariants {
      _id
      variantId
      quantity
    }
    user {
      id
      _id
      email
    }
    userEmail
    warehouseStatus
    warehouseNotes
    warehouseId
    warehouse {
      id
      _id
      name
      marketplaceCommissionPercentage
      commissionPercentage
      addressOne
      addressTwo
      zip
      city
      phoneNumber
      fullAddress
      location {
        coordinates
      }
    }
    subtotal
    tax
    deliveryFee
    total
    variants {
      _id
      commissionFee
      warehouseEarning
      name {
        _id
        zh
        en
      }
      variantId
      price
      quantity
      variant {
        _id
        product {
          _id
          enAbbreviation
        }
        warehouseId
      }
      warehouseEarning
      commissionFee
      subVariants {
        commissionFee
        warehouseEarning
        _id
        name {
          _id
          zh
          en
        }
        price
        quantity
        variant {
          id
          _id
          product {
            id
            _id
            enAbbreviation
          }
        }
      }
    }
    totalPartiallyRefundedFee
    discountAmount
    deliveryTime
    deliveryStartTime
    address {
      id
      _id
      addressOne
      addressTwo
      city
      gateCode
      phoneNumber
      lastName
      firstName
      zipCode
    }
    campaignContent {
      defaultAddress {
        _id
        id
        addressOne
      }
      displayId
      name
      _id
      id
      prepareDay
      pickupTimeSlotFormatted
    }
    messageSent
    proofPictures {
      _id
      key
    }
    shareStartDate
  }
`;

export const CUSTOMER_ORDER_FRAGMENT_ORDER_DETAIL = gql`
  fragment CUSTOMER_ORDER_FRAGMENT_ORDER_DETAIL on CustomerOrder {
    id
    _id
    taxRate
    customerNotes
    displayId
    bunbaoType
    paymentStatus
    appliedCode
    createdAt
    user {
      id
      _id
      email
    }
    userEmail
    warehouseStatus
    warehouseNotes
    warehouseId
    warehouse {
      id
      _id
      name
      addressOne
      addressTwo
      zip
      city
      phoneNumber
      fullAddress
      location {
        coordinates
      }
    }
    subtotal
    tax
    deliveryFee
    total
    isLocked
    variants {
      _id
      name {
        _id
        zh
        en
      }
      price
      quantity
      subVariants {
        _id
        name {
          _id
          zh
          en
        }
        price
        quantity
      }
    }
    discountAmount
    deliveryTime
    deliveryStartTime
    address {
      id
      _id
      addressOne
      addressTwo
      city
      gateCode
      phoneNumber
      lastName
      firstName
      zipCode
    }
    campaignContent {
      defaultAddress {
        _id
        id
        addressOne
      }
      displayId
      name
      _id
      id
      prepareDay
      pickupTimeSlotFormatted
    }
    shareStartDate
  }
`;

export const CUSTOMER_ORDER_DELIVERY_FRAGMENT = gql`
  fragment CUSTOMER_ORDER_DELIVERY_FRAGMENT on CustomerOrder {
    id
    _id
    taxRate
    addressId
    customerNotes
    displayId
    bunbaoType
    paymentStatus
    appliedCode
    createdAt
    user {
      _id
      id
      email
      guestUserEmail
    }
    userEmail
    subtotal
    tax
    deliveryFee
    total
    refundVariants {
      _id
      quantity
      variantId
    }
    variants {
      variantId
      _id
      commissionFee
      warehouseEarning
      name {
        _id
        zh
        en
      }
      price
      quantity
      subVariants {
        _id
        variantId
        commissionFee
        warehouseEarning
        name {
          _id
          zh
          en
        }
        price
        quantity
      }
    }
    discountAmount
    deliveryTime
    deliveryStartTime
    address {
      _id
      id
      addressOne
      addressTwo
      city
      gateCode
      phoneNumber
      lastName
      firstName
      zipCode
    }
    campaignContent {
      defaultAddress {
        id
        _id
        addressOne
        phoneNumber
        lastName
        firstName
      }
      location {
        coordinates
      }
      displayId
      name
      _id
      id
      prepareDay
      pickupTimeSlotFormatted
    }
    proofPictures {
      _id
      key
    }
    warehouseStatus
    messageSent
    balance
  }
`;

export const CUSTOMER_ORDER_DASHBOARD_FRAGMENT = gql`
  fragment CUSTOMER_ORDER_DASHBOARD_FRAGMENT on CustomerOrder {
    _id
    taxRate
    customerNotes
    displayId
    bunbaoType
    paymentStatus
    appliedCode
    createdAt
    user {
      _id
      email
    }
    userEmail
    warehouseStatus
    warehouseNotes
    warehouseId
    warehouse {
      _id
      name
      addressOne
      addressTwo
      zip
      city
      phoneNumber
      fullAddress
      location {
        coordinates
      }
    }
    subtotal
    tax
    deliveryFee
    total
    variants {
      _id
      variantId
      name {
        _id
        zh
        en
      }
      price
      quantity
      variant {
        _id
        product {
          _id
          enAbbreviation
          category {
            _id
            name
            title {
              _id
              zh
              en
            }
          }
          subcategory {
            _id
            name
            title {
              _id
              zh
              en
            }
          }
        }
      }
      subVariants {
        _id
        variantId
        name {
          _id
          zh
          en
        }
        price
        quantity
        variant {
          _id
          product {
            _id
            enAbbreviation
            category {
              _id
              name
              title {
                _id
                zh
                en
              }
            }
            subcategory {
              _id
              name
              title {
                _id
                zh
                en
              }
            }
          }
        }
      }
    }
    discountAmount
    deliveryTime
    deliveryStartTime
    address {
      _id
      addressOne
      addressTwo
      city
      gateCode
      phoneNumber
      lastName
      firstName
      zipCode
    }
    campaignContent {
      defaultAddress {
        _id
        addressOne
        city
        zipCode
        firstName
        lastName
      }
      pickupWindow {
        _id
        start
        end
        day
      }
      displayId
      name
      _id
      prepareDay
      pickupTimeSlotFormatted
    }
  }
`;

export const CAMPAIGN_CUSTOMER_ORDER_FRAGMENT = gql`
  fragment CAMPAIGN_CUSTOMER_ORDER_FRAGMENT on CustomerOrder {
    _id
    user {
      _id
      email
    }
    userEmail
    subtotal
    tax
    deliveryFee
    total
    discountAmount
    deliveryTime
    deliveryStartTime
    address {
      _id
      addressOne
      addressTwo
      city
      gateCode
      phoneNumber
      lastName
      firstName
      zipCode
    }
  }
`;

export const CUSTOMER_ORDER_FILTER_BY_DELIVERY_TIME = gql`
  query ($start: Float, $end: Float) {
    customerOrderSearchByTimeRange(start: $start, end: $end) {
      ...CUSTOMER_ORDER_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT}
`;

export const CUSTOMER_ORDER_CREATE = gql`
  mutation (
    $variantInput: [CustomerOrderVariantInputInput]
    $address: MongoID
    $warehouseId: MongoID
    $notes: String
    $deliveryTime: Float
    $realMode: Boolean
    $discountCode: String
    $overWriteAddress: CustomerOrderOverWriteAddressInput
    $pseudoOrderType: String
    $pickupDate: Float
    $guestUserEmail: String
    $referralCode: String
    $referralOrderId: MongoID
    $doesUseBalance: Boolean
  ) {
    orderCreateCustomerOrder(
      variants: $variantInput
      addressId: $address
      warehouseId: $warehouseId
      notes: $notes
      deliveryTime: $deliveryTime
      realMode: $realMode
      discountCode: $discountCode
      overWriteAddress: $overWriteAddress
      pseudoOrderType: $pseudoOrderType
      pickupDate: $pickupDate
      guestUserEmail: $guestUserEmail
      referralCode: $referralCode
      referralOrderId: $referralOrderId
      doesUseBalance: $doesUseBalance
    ) {
      _id
      subtotal
      tax
      deliveryFee
      total
      taxRate
      discountAmount
      appliedCode
      discount {
        _id
        name {
          _id
          zh
          en
        }
        code
      }
    }
  }
`;

export const CUSTOMER_ORDER_BY_ID = gql`
  query ($id: MongoID!) {
    customerOrderById(_id: $id) {
      ...CUSTOMER_ORDER_FRAGMENT_ORDER_DETAIL
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT_ORDER_DETAIL}
`;

export const CUSTOMER_ORDER_CURRENT_USER_BY_ID = gql`
  query ($id: MongoID!) {
    customerOrderById(_id: $id) {
      ...CUSTOMER_ORDER_DELIVERY_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_DELIVERY_FRAGMENT}
`;

export const CUSTOMER_ORDER_CURRENT_USER_PAGINATION = gql`
  query (
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyCustomerOrderInput
    $filter: FilterFindManyCustomerOrderInput
  ) {
    customerOrderCurrentUserPagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        id
        campaignContent {
          _id
          pickupTimeSlotFormatted
          warehouse {
            _id
            title {
              _id
              en
              zh
            }
          }
        }
        warehouseStatus
        warehouseNotes
        warehouseId
        warehouse {
          id
          name
          addressOne
          addressTwo
          zip
          city
          phoneNumber
          fullAddress
          location {
            coordinates
          }
        }
        _id
        displayId
        paymentStatus
        createdAt
        deliveryTime
        subtotal
        tax
        deliveryFee
        refundVariants {
          _id
          quantity
          variantId
        }
        total
        address {
          id
          _id
          lastName
          firstName
          phoneNumber
        }
        bunbaoType
        variants {
          _id
          variantId
          commissionFee
          warehouseEarning
          quantity
          name {
            _id
            en
            zh
          }
          price
          subVariants {
            _id
            variantId
            commissionFee
            warehouseEarning
            name {
              _id
              zh
              en
            }
            price
            quantity
          }
        }
      }
    }
  }
`;

export const CUSTOMER_ORDER_CREATE_STAFF_ORDER = gql`
  mutation (
    $variants: [CustomerOrderVariantInputInput]
    $addressId: MongoID
    $notes: String
    $deliveryTime: Float
    $overWriteAddress: AddressInput
    $bunbaoType: String
  ) {
    orderCreateStaffOrder(
      variants: $variants
      addressId: $addressId
      notes: $notes
      deliveryTime: $deliveryTime
      overWriteAddress: $overWriteAddress
      bunbaoType: $bunbaoType
    ) {
      _id
      subtotal
      tax
      deliveryFee
      total
      taxRate
      discountAmount
      appliedCode
      discount {
        _id
        name {
          _id
          zh
          en
        }
        code
      }
    }
  }
`;

export const CUSTOMER_ORDER_BY_DISPLAY_ID = gql`
  query ($filter: FilterFindOneCustomerOrderInput) {
    customerOrderFindOne(filter: $filter) {
      ...CUSTOMER_ORDER_DELIVERY_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_DELIVERY_FRAGMENT}
`;

export const CUSTOMER_ORDER_QUERY = gql`
  query ($filter: FilterFindManyCustomerOrderInput, $limit: Int) {
    customerOrderMany(sort: CREATEDAT_DESC, filter: $filter, limit: $limit) {
      ...CUSTOMER_ORDER_DELIVERY_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_DELIVERY_FRAGMENT}
`;

export const CUSTOMER_ORDER_DELIVERY_ABBREVIATION_QUERY = gql`
  query ($filter: FilterFindManyCustomerOrderInput) {
    customerOrderMany(sort: CREATEDAT_DESC, filter: $filter) {
      _id
      bunbaoType
      paymentStatus
      createdAt
      subtotal
      tax
      deliveryFee
      total
      variants {
        _id
        quantity
      }

      deliveryTime
    }
  }
`;

export const CUSTOMER_ADMIN_QUERY = gql`
  query ($filter: FilterFindManyCustomerOrderInput) {
    customerOrderMany(sort: CREATEDAT_DESC, filter: $filter) {
      ...CUSTOMER_ORDER_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT}
`;

export const CUSTOMER_ORDER_DASHBOARD_CSV = gql`
  query ($filter: FilterFindManyCustomerOrderInput, $limit: Int = 100000) {
    customerOrderMany(sort: CREATEDAT_ASC, filter: $filter, limit: $limit) {
      subtotal
      tax
      deliveryFee
      total
      discountAmount
      deliveryTime
      createdAt
      bunbaoType
      paymentStatus
      user {
        _id
        email
      }
      userEmail
      _id
    }
  }
`;

export const CUSTOMER_ORDER_DONATION = gql`
  query ($page: Int, $perPage: Int, $filter: FilterFindManyCustomerOrderInput) {
    customerOrderPagination(
      sort: CREATEDAT_DESC
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
      items {
        addressId
        createdAt
        subtotal
        tax
        deliveryFee
        total
        discountAmount
        customerNotes
        user {
          _id
          email
        }
        userEmail
        _id
      }
    }
  }
`;

export const CUSTOMER_ORDER_ALL_DONATIONS = gql`
  query ($filter: FilterFindManyCustomerOrderInput) {
    customerOrderManyAdmin(sort: CREATEDAT_ASC, filter: $filter) {
      createdAt
      subtotal
      tax
      deliveryFee
      total
      discountAmount
      customerNotes
      user {
        _id
        email
      }
      userEmail
      _id
    }
  }
`;

export const CUSTOMER_PRODUCTION_QUERY = gql`
  query ($filter: FilterFindManyCustomerOrderInput) {
    customerOrderManyAdmin(sort: CREATEDAT_DESC, filter: $filter) {
      ...CUSTOMER_ORDER_DASHBOARD_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_DASHBOARD_FRAGMENT}
`;

export const CUSTOMER_ORDER_MANUFACTURE_QUERY = gql`
  query ($startDate: Date, $endDate: Date, $type: [String!]) {
    customerOrderManufacture(
      startDate: $startDate
      endDate: $endDate
      type: $type
    ) {
      _id
      name {
        _id
        en
        zh
      }
      quantity
      category {
        _id
        title {
          _id
          en
          zh
        }
      }
      subcategory {
        _id
        title {
          _id
          en
          zh
        }
      }
      manufactureCategory {
        _id
        title {
          _id
          en
          zh
        }
      }
    }
  }
`;

export const CUSTOMER_ORDER_PAGINATION = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCustomerOrderInput
    $sort: SortFindManyCustomerOrderInput
  ) {
    customerOrderPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        ...CUSTOMER_ORDER_FRAGMENT
      }
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT}
`;

export const CUSTOMER_ORDER_MANY = gql`
  query (
    $filter: FilterFindManyCustomerOrderInput
    $sort: SortFindManyCustomerOrderInput
    $limit: Int
  ) {
    customerOrderMany(filter: $filter, sort: $sort, limit: $limit) {
      ...CUSTOMER_ORDER_FRAGMENT
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT}
`;

export const CUSTOMER_ORDER_REPURCHASE_ = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCustomerOrderInput
    $sort: SortFindManyCustomerOrderInput
  ) {
    customerOrderPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        _id
        userId
        userEmail
        bunbaoType
        paymentStatus
        createdAt
        address {
          _id
          city
        }
      }
    }
  }
`;

export const CUSTOMER_ORDER_STATISTIC = gql`
  query ($startTime: Date!, $endTime: Date!) {
    customerOrderStatistic(startTime: $startTime, endTime: $endTime) {
      totalSales
      receivedAmountTotal
      amountPerOrder
      rePurchaseCoefficient
      frequencyPerUser
      oldUserRatio
      newUserTotal
      customerOrderCreatedTotal
      customerOrderDeliveredTotal
      cateringOrderTotal
      giftCardSaleTotal
      refundTotal
      partiallyRefundTotal
      discountRate
      giftCardActualTotal
      giftCardDiscountRate
    }
  }
`;

export const DASHBOARD_OVERVIEW_GRAPHIC_STATISTIC = gql`
  query ($startTime: Date!, $endTime: Date!) {
    customerOrderGraphicStatistic(startTime: $startTime, endTime: $endTime) {
      name
      date
      value
    }
  }
`;

export const CUSTOMER_ORDER_SYNC_OLD = gql`
  query {
    syncLegacyPlatformOrders {
      total
      endDate
    }
  }
`;

export const CUSTOMER_ORDER_MANAGEMENT = gql`
  query ($page: Int, $perPage: Int, $filter: FilterFindManyCustomerOrderInput) {
    customerOrderManagement(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...CUSTOMER_ORDER_FRAGMENT
      }
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT}
`;

export const UPDATE_CUSTOMER_ORDER = gql`
  mutation ($ID: MongoID!, $status: EnumCustomerOrderWarehouseStatus) {
    customerOrderUpdateById(
      input: { record: { _id: $ID, warehouseStatus: $status } }
    ) {
      record {
        _id
      }
    }
  }
`;

export const REFUND_VARIANTS = gql`
  mutation (
    $variants: [variantsRefundInput]
    $orderID: MongoID!
    $doesRefundToBalance: Boolean
  ) {
    refundVariants(
      variants: $variants
      orderID: $orderID
      doesRefundToBalance: $doesRefundToBalance
    )
  }
`;

export const UPDATE_CUSTOMER_ORDER_BY_ID = gql`
  mutation ($input: UpdateByIdCustomerOrderInput!) {
    customerOrderUpdateById(input: { record: $input }) {
      record {
        ...CUSTOMER_ORDER_FRAGMENT
      }
    }
  }
  ${CUSTOMER_ORDER_FRAGMENT}
`;

export const FETCH_BOX_LABEL = gql`
  query ($date: String!) {
    fetBoxLabelByDate(date: $date)
  }
`;

export const CUSTOMER_ORDER_COUNT = gql`
  query ($filter: FilterCustomerOrderInput) {
    customerOrderCount(filter: $filter)
  }
`;

export const CUSTOMER_ORDER_FETCH_DELIVERY_FEE = gql`
  query ($originString: String, $destinationString: String) {
    customerOrderFetchDeliveryFee(
      originString: $originString
      destinationString: $destinationString
    ) {
      duration
      distance
      fee
    }
  }
`;

export const CUSTOMER_ORDER_SHARE_START_TIME = gql`
  mutation ($orderId: MongoID!) {
    createShareOrderStartTime(_id: $orderId)
  }
`;
