const constants = {
  en: {
    cateringError: 'This coupon cannot be applied to catering order.',
    deletedError: 'This coupon is expired or has been deleted.',
    invalidError: 'Invalid Coupon Code!',
    addCoupon: 'Add Coupon',
    apply: 'Apply',
    cancel: 'Cancel',
  },
  zh: {
    cateringError: '抱歉，这个折扣码不能用于公司餐.',
    deletedError: '这个折扣码已过期或已被删除.',
    invalidError: '无效的折扣码!',
    addCoupon: '添加折扣码',
    apply: '确认',
    cancel: '取消',
  },
};

export { constants as default };
