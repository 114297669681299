import * as actionTypes from './actionTypes';
import { apolloClient } from '../../util';
import { TAX_RATE_FETCH } from '../../api/taxRate';
import { WAREHOUSE_QUERY_ID } from '../../api/warehouse';

export const setWarehouse =
  (id = null) =>
  async (dispatch, getState) => {
    const { warehouseId } = getState().warehouse;
    // const { currentShoppingMode } = getState().user;
    // // if (currentShoppingMode === 'delivery') {
    // //   console.log('setWarehouse to delivery');
    // //   return dispatch({
    // //     type: actionTypes.WAREHOUSE_UPDATE,
    // //     warehouseId: null,
    // //     taxRate: 0,
    // //   });
    // // }
    const oldId = warehouseId;
    let taxRate = 0;
    let returnId = oldId;
    if (id) {
      returnId = id;
    }
    if (returnId && returnId.length > 5) {
      try {
        const { data } = await apolloClient.query({
          query: WAREHOUSE_QUERY_ID,
          variables: { input: returnId },
          fetchPolicy: 'network-only',
        });
        if (
          data &&
          data.warehouseById &&
          data.warehouseById.warehouseType !== 'website' &&
          data.warehouseById.warehouseType !== 'donation' &&
          data.warehouseById.zip
        ) {
          const response = await apolloClient.mutate({
            mutation: TAX_RATE_FETCH,
            variables: { zipCode: data.warehouseById.zip },
          });

          if (response && response.data && response.data.taxRateFetch) {
            taxRate = response.data.taxRateFetch.percentage;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return dispatch({
      type: actionTypes.WAREHOUSE_UPDATE,
      warehouseId: returnId,
      taxRate,
    });
  };

// only overwrite not fetch
export const updateOneWarehouseAttribute =
  (input = null, attributeName) =>
  async (dispatch) => {
    let final = null;
    if (input !== null) {
      final = input;
    }
    return dispatch({
      type: actionTypes.WAREHOUSE_ATTRIBUTE_UPDATE,
      attributeName,
      attributeValue: final,
    });
  };

export const updateSelectedWarehouse = () => {};
