export default {
  en: {
    message: 'Message',
    messages: 'Messages',
    browserNotification: 'Browser Notification',
    smsPusher: 'SMS Pusher',
  },
  zh: {
    message: '消息',
    messages: '消息',
    browserNotification: '浏览器推送',
    smsPusher: '短信推送',
  },
};
